import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { CaretIcon } from '../../assets/icons';
import ScrollbarContainer from '../ScrollbarContainer';

type RenderItemProps = {
  value: string;
};

type ItemListProps = {
  selectedItem: string;
  arr: string[];
  RenderItem: (props: RenderItemProps) => JSX.Element;
  className?: string;
};

const ItemList: FC<ItemListProps> = ({ selectedItem, arr, RenderItem, className }) => {
  const [isListVisible, setListVisible] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);

  const handleClickOnList = () => {
    setListVisible((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (listRef?.current && !listRef?.current.contains(event.target as Node)) {
      setListVisible(false);
    }
  };

  /**
   * The useOnClickOutside hook doesn't work correct here. When the user chooses some value,
   * the list should close
   */
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Container className={className} ref={listRef}>
      <ListButton onClick={handleClickOnList}>
        <span>{selectedItem}</span>
        <StyledCaretIcon $isHidden={!isListVisible} width={18} />
      </ListButton>

      {isListVisible && (
        <StyledScrollbarContainer>
          {arr.map((value) => (
            <RenderItem value={value} key={value} />
          ))}
        </StyledScrollbarContainer>
      )}
    </Container>
  );
};

export default ItemList;

const Container = styled.div`
  position: relative;
`;

const ListButton = styled.button`
  display: flex;
  align-items: center;
  width: max-content;

  & > span {
    ${({ theme: { colors, typography } }) => css`
      color: ${colors.neutrals[1]};
      ${typography.body_basic_semibold_14};
    `};
  }
`;

const StyledCaretIcon = styled(CaretIcon)<{ $isHidden: boolean }>`
  position: relative;
  top: 1px;
  transition: transform 0.3s ease;
  transform: ${({ $isHidden }) => `${$isHidden ? 'rotate(90deg) scale(1, -1)' : 'rotate(90deg) scale(-1, 1)'}`};
`;

const StyledScrollbarContainer = styled(ScrollbarContainer)`
  ${({ theme: { colors } }) => css`
    position: absolute;
    top: 100%;
    left: -8px;
    display: flex;
    flex-direction: column;
    max-height: 216px;
    height: 216px;
    text-align: left;
    overflow-x: hidden;
    border-radius: 4px;
    box-shadow: ${colors.shadow[1]};
    background-color: ${colors.neutrals[11]};

    :hover,
    :focus {
      visibility: visible;
    }

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 40px;
    }
  `};
`;
