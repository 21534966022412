import React, { ForwardRefRenderFunction, InputHTMLAttributes, forwardRef } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';

type ToggleSize = 'big' | 'small';

type ToggleButtonProps = {
  variant?: ToggleSize;
} & InputHTMLAttributes<HTMLInputElement>;

const ToggleButton: ForwardRefRenderFunction<HTMLInputElement, ToggleButtonProps> = (
  { variant = 'big', ...props },
  ref,
) => {
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  return <Input type="checkbox" $variant={variant} {...props} ref={ref} $isDarkMode={isDarkMode} />;
};

const Input = styled.input<{ $variant: ToggleSize; $isDarkMode?: boolean }>`
  ${({ theme: { colors }, $variant, $isDarkMode }) => css`
    position: relative;
    display: inline-block;
    margin: 0;
    vertical-align: top;
    outline: none;
    cursor: pointer;
    border: none;
    z-index: 0;
    appearance: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    background: ${colors.neutrals[9]};

    ${() => getToggleProperty($variant)}

    :hover {
      :checked {
        background: ${colors.primary[2]};
      }
    }

    :hover::after {
      box-shadow: -1px 0 8px 0 rgba(48, 50, 54, 0.2);
    }

    ::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      transform: translateX(0);
      transition: all 0.3s;
      background: ${$isDarkMode ? colors.neutrals[1] : colors.neutrals[11]};

      ${() => getCircleProperty($variant)}
    }

    :checked::after {
      transform: translateX(100%);
      background-color: ${$isDarkMode ? colors.neutrals[1] : colors.neutrals[11]};
    }

    :checked {
      background-color: ${colors.primary[1]};
    }

    :not(:checked) {
      :active {
        background-color: ${colors.neutrals[7]};
      }
    }

    :disabled {
      opacity: 50%;
      cursor: not-allowed;
      background-color: ${colors.neutrals[9]};
    }

    :disabled::after {
      background-color: ${colors.neutrals[6]};
    }

    :focus {
      ::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border-radius: 20px;
        background: transparent;
        border: 2px solid ${colors.primary[4]};
      }
    }
  `};
`;

export default forwardRef<HTMLInputElement, ToggleButtonProps>(ToggleButton);

const getToggleProperty = (variant: ToggleSize) => {
  switch (variant) {
    case 'big':
      return css`
        height: 24px;
        width: 44px;
        border-radius: 20px;
      `;

    case 'small':
      return css`
        height: 24px;
        width: 44px;
        border-radius: 20px;
      `;
  }
};

const getCircleProperty = (variant: ToggleSize) => {
  switch (variant) {
    case 'big':
      return css`
        height: 20px;
        width: 20px;
      `;

    case 'small':
      return css`
        height: 20px;
        width: 20px;
      `;
  }
};
