import { Label } from 'UI/Input/styled';
import {
  AmExIcon,
  CartesIcon,
  DefaultIcon,
  DinersIcon,
  DiscoverIcon,
  Jcbicon,
  LoaderIcon,
  MasterCardV2Icon,
  UnionpayIcon,
  VisaV2Icon,
} from 'assets/icons';
import { PaymentsSystemsEnums } from 'constant';
import React, { FC, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';

const getPaymentsSystemsLogo = (paymentSystem?: PaymentsSystemsEnums) => {
  switch (paymentSystem) {
    case PaymentsSystemsEnums.AMEX:
      return AmExIcon;
    case PaymentsSystemsEnums.JCB:
      return Jcbicon;
    case PaymentsSystemsEnums.DISCOVER:
      return DiscoverIcon;
    case PaymentsSystemsEnums.MASTERCARD:
      return MasterCardV2Icon;
    case PaymentsSystemsEnums.VISA:
      return VisaV2Icon;
    case PaymentsSystemsEnums.UNIONPAY:
      return UnionpayIcon;
    case PaymentsSystemsEnums.DINERS:
      return DinersIcon;
    case PaymentsSystemsEnums.CARTES_BANCAIRES:
      return CartesIcon;
    default:
      return DefaultIcon;
  }
};

type BankCardProps = {
  card: string;
  paymentSystem?: PaymentsSystemsEnums;
  className?: string;
  label?: string;
  isLoading?: boolean;
  onClick?: () => void;
};

const BankCard: FC<BankCardProps> = ({ card, isLoading = false, label, paymentSystem, className, onClick }) => {
  const PaymentSystemLogo = useMemo(() => getPaymentsSystemsLogo(paymentSystem), [paymentSystem]);
  return (
    <Root>
      {label && <Label>{label}</Label>}
      <Card
        type="button"
        $isLoading={isLoading}
        disabled={isLoading}
        className={className}
        tabIndex={0}
        onClick={onClick}>
        {isLoading ? (
          <StyledLoader size={24} />
        ) : (
          <>
            **** **** **** {card}
            <PaymentSystemLogo />
          </>
        )}
      </Card>
    </Root>
  );
};

export default BankCard;

const Root = styled.div``;

const Card = styled.button<{ $isLoading: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 12px;
  ${({ theme: { typography } }) => typography.body_basic_regular_14};
  cursor: pointer;

  &:hover {
    ${({ theme: { colors } }) => css`
      border-color: ${colors.neutrals[10]};
      background-color: ${colors.neutrals[10]};
    `}
  }

  ${({ $isLoading, theme: { colors } }) =>
    $isLoading
      ? css`
          height: 46px;
          padding: 0;
          justify-content: center;
        `
      : css`
          &:focus {
            border-color: ${colors.neutrals[4]};
            background-color: ${colors.neutrals[10]};
          }
        `}
`;

const rotate = keyframes`
from {
    transform: rotate(0deg);
  }
to {
    transform: rotate(360deg);
  }
`;

export const StyledLoader = styled(LoaderIcon)`
  animation: ${rotate} 2s linear infinite;
`;
