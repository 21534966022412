import api from 'api';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import useNotifications from '../useNotifications/useNotifications';

export const MAX_LENGTH = 600;
export const MIN_LENGTH = 10;

export type SuggestionFormState = {
  noteText: string;
};

const useSuggestion = (onClose: () => void) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<SuggestionFormState>({
    defaultValues: { noteText: '' },
    mode: 'onChange',
  });
  const [isLoading, setLoading] = useState(false);
  const isError = Boolean(errors?.noteText) || !isDirty;
  const { errorToast, successToast } = useNotifications();

  const modalClose = () => {
    successToast('Your suggestion has been successfully sent');
    reset();
    onClose();
  };

  const handleFormSubmit = async (formData: SuggestionFormState) => {
    setLoading(true);

    if (formData.noteText) {
      try {
        const { data, status } = await api.Suggestions.suggestionControllerAddSuggestion({
          content: formData.noteText,
        });

        if (status > 399) {
          throw data;
        } else {
          modalClose();
        }
      } catch {
        errorToast('Something went wrong');
      } finally {
        setLoading(false);
      }
    }
  };

  return {
    formMethods: {
      control,
      handleSubmit,
    },
    isLoading,
    isError,
    MAX_LENGTH,
    MIN_LENGTH,
    handleFormSubmit,
  };
};

export default useSuggestion;
