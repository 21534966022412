import { PriceLabel, StyledText } from 'UI';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import { SubscribeDtoTypeEnum } from 'api/generated';
import { LockSecondIcon } from 'assets/icons';
import { levelMapping } from 'constant';
import { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';

type TitleProps = {
  subscribeType: SubscribeDtoTypeEnum;
  term: TermEnum;
  amount: number;
  oldAmount?: number;
  isUpdate: boolean;
};

const Title: FC<TitleProps> = ({ amount, subscribeType, term, oldAmount }) => {
  const subscribeTypeString = levelMapping[subscribeType];
  const { colors } = useTheme();

  return (
    <Root>
      <MainTitle as="h3">
        <TitleSpan>{subscribeTypeString}</TitleSpan>
        <TextWrapper>
          <PriceLabel term={term} currentPrice={amount} oldPrice={oldAmount} />
        </TextWrapper>
      </MainTitle>
      {/* {isUpdate && (
        <Subtitle font="subhead_medium_13">
          We will reduce the amount of payment for a new subscription until the balance from the last one burns out
        </Subtitle>
      )} */}
      <SecureTextContainer>
        <LockSecondIcon size={18} color={colors.neutrals[4]} />
        <SecureText font="subhead_medium_13">All transactions are secure and encrypted.</SecureText>
      </SecureTextContainer>
    </Root>
  );
};

export default Title;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MainTitle = styled(StyledText)`
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 4px;
  white-space: nowrap;
  flex-wrap: wrap;
  user-select: none;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_large_medium_16}
  `};
`;

const TitleSpan = styled(MainTitle)`
  color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

// const Subtitle = styled(StyledText)`
//   width: 100%;
//   text-align: center;
//   color: ${({ theme: { colors } }) => colors.neutrals[4]};
// `;

const SecureText = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  margin-left: 6px;
`;

const SecureTextContainer = styled.div`
  display: flex;
  justify-content: center;
`;
