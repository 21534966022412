import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Answer } from '../../../../../UI';
import { CourseShortInfoResponseDto } from '../../../../../api/generated';
import { useAsyncAction } from '../../../../../hooks';
import { actions, selectors, useAppSelector } from '../../../../../store';
import { Loader } from '../../../../index';

interface CoursesGroupProps {
  onSelectedAnswerChange: (selectedAnswer: CourseShortInfoResponseDto) => void;
  searchTerm: string;
}

const CoursesGroup = ({ onSelectedAnswerChange, searchTerm }: CoursesGroupProps) => {
  const direction = useAppSelector(selectors.direction.selectDirection);
  const [getCoursesList, isCoursesListLoading] = useAsyncAction(actions.direction.getCoursesByDirection);
  const [courses, setCourses] = useState<CourseShortInfoResponseDto[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);

  useEffect(() => {
    if (direction?.id) {
      getCoursesList({ id: direction.id })
        .then((data: CourseShortInfoResponseDto[]) => {
          setCourses(data);
        })
        .catch((error) => {
          console.error('Error fetching courses:', error);
        });
    }
  }, [getCoursesList, direction?.id]);

  const handleAnswerSelect = ({ id, name }: CourseShortInfoResponseDto) => {
    const newSelectedAnswer = id === selectedAnswer ? null : id;
    setSelectedAnswer(newSelectedAnswer);
    onSelectedAnswerChange({ id, name });
  };

  const filteredCourses = courses.filter((course: CourseShortInfoResponseDto) =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  if (isCoursesListLoading) {
    return <Loader />;
  }
  return (
    <CoursesWrapper>
      {filteredCourses && filteredCourses.length > 0 ? (
        filteredCourses.map((course: CourseShortInfoResponseDto) => (
          <Answer
            labelCSS={LabelCSS}
            key={course.id}
            id={course.id}
            label={course.name}
            value={course.id}
            type="radio"
            checked={selectedAnswer === course.id}
            onChange={() => handleAnswerSelect({ id: course.id, name: course.name })}
          />
        ))
      ) : (
        <p>Nothing found. Try to use other key words.</p>
      )}
    </CoursesWrapper>
  );
};

export default CoursesGroup;
const CoursesWrapper = styled.div``;
const LabelCSS = css`
  width: 100%;
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 8px;
`;
