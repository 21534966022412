import React, { FC } from 'react';
import { Oval } from 'react-loader-spinner';
import styled, { useTheme } from 'styled-components';

const Loader: FC<OvalProps> = ({ ...props }) => {
  const { colors } = useTheme();
  return (
    <LoaderContainer>
      <Oval color={colors.primary[1]} secondaryColor="transparent" strokeWidth={4} {...props} />
    </LoaderContainer>
  );
};

export default Loader;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

declare type Style = {
  [key: string]: string;
};

interface BaseProps {
  height?: string | number;
  width?: string | number;
  color?: string;
  ariaLabel?: string;
  wrapperStyle?: Style;
  wrapperClass?: string;
  visible?: boolean;
}

interface OvalProps extends BaseProps {
  strokeWidth?: string | number;
  strokeWidthSecondary?: string | number;
  secondaryColor?: string;
}
