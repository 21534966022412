import { Button, Input, StyledText } from 'UI';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { SuccessCoupon } from './components';

interface CouponProps {
  subscribeType: string;
  isDefaultCoupon: boolean;
  defaultCouponCode: string | undefined;
  isSuccessCoupon: boolean;
  courseName: string;
  amount: number;
  promoCode: string | undefined;
  oldAmount: number | undefined;
  discountAmount: number;
  discountValue: number;
  couponErrorMessage: string | null;
  onCouponSubmit: (data: { coupon?: string }) => Promise<void>;
  removeCoupon: () => void;
  invalidCoupon: boolean;
  setClearCoupon: () => void;
  handleChangePromoCode: any;
  disable: boolean;
}

const CouponComponent: FC<CouponProps> = ({
  subscribeType,
  isDefaultCoupon,
  isSuccessCoupon,
  couponErrorMessage,
  courseName,
  oldAmount,
  amount,
  promoCode,
  discountAmount,
  discountValue,
  onCouponSubmit,
  removeCoupon,
  invalidCoupon,
  setClearCoupon,
  defaultCouponCode,
  handleChangePromoCode,
  disable,
}) => {
  const [isEnterCoupon, setIsEnterCoupon] = useState(false);
  const [couponValue, setCouponValue] = useState(defaultCouponCode ?? '');
  const [isInvalidSum, setIsInvalidSum] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const currentPrice = oldAmount ? oldAmount : amount;
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      coupon: couponValue,
    },
  });

  const changePromoCode = async (data: any) => {
    handleChangePromoCode(data);
  };

  useEffect(() => {
    if (defaultCouponCode) {
      onCouponSubmit({ coupon: couponValue });
    }
  }, []);

  useEffect(() => {
    if (currentPrice - discountValue < 1) {
      setIsInvalidSum(true);
    }
  }, [currentPrice - discountValue]);

  return (
    <>
      {isEnterCoupon || isDefaultCoupon ? (
        <CouponContainer>
          {isSuccessCoupon && !isInvalidSum && !isClear ? (
            <SuccessCoupon
              subscribeType={subscribeType}
              courseName={courseName}
              oldAmount={oldAmount}
              amount={amount}
              promoCode={promoCode}
              discountAmount={discountAmount}
              discountValue={discountValue}
              removeCoupon={removeCoupon}
              setIsEnterCoupon={setIsEnterCoupon}
              reset={reset}
            />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <CouponForm onClick={handleSubmit(changePromoCode)}>
                <Controller
                  control={control}
                  name="coupon"
                  render={({ field }) => (
                    <Input
                      {...field}
                      $paddingButton="74px"
                      placeholder="Coupon code"
                      value={field.value}
                      showIcon={invalidCoupon || isInvalidSum}
                      // @ts-ignore
                      onIconClick={(e: React.FormEvent<HTMLFormElement>) => {
                        e.stopPropagation();
                        setClearCoupon();
                        setCouponValue('');
                        setIsInvalidSum(false);
                        setIsClear(true);
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSubmit(onCouponSubmit)();
                        }
                      }}
                    />
                  )}
                />
                {!invalidCoupon && !isInvalidSum && (
                  <CouponButton
                    disabled={disable}
                    isSuccessCoupon={isSuccessCoupon}
                    size="small"
                    variant="primary"
                    type="button"
                    onClick={() => setIsClear(false)}>
                    Apply
                  </CouponButton>
                )}
              </CouponForm>

              {invalidCoupon && (
                <ErrorCouponContainer>
                  <ErrorText>{couponErrorMessage || 'Coupon code is not valid'}</ErrorText>
                </ErrorCouponContainer>
              )}

              {isInvalidSum && (
                <ErrorNegativePriceContainer>
                  <ErrorText>{'Negative prices are not allowed.'}</ErrorText>
                </ErrorNegativePriceContainer>
              )}
            </div>
          )}
        </CouponContainer>
      ) : (
        <CouponText onClick={() => setIsEnterCoupon(true)}>Add coupon +</CouponText>
      )}
    </>
  );
};

export default CouponComponent;

const CouponContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CouponText = styled.span`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 35px;
  cursor: pointer;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[11]};
    ${typography.body_basic_semibold_14}
  `};
  ${respondToWidth.s`
  margin-bottom: 10px;
`}
  font-size: 16px;
`;

const CouponForm = styled.form`
  display: flex;
  gap: 16px;
  padding-bottom: 10px;
  position: relative;
  width: 131%;
  margin-left: -20%;

  ${respondToWidth.sm`
  margin-bottom: unset;
`}

  ${respondToWidth.s`
    margin-bottom: 0px;
    width: 138%;
    margin-left: -17%;
`}
`;

const CouponButton = styled(Button)<{ isSuccessCoupon: boolean }>`
  top: 4px;
  right: 4px;
  width: 40px;
  position: absolute;
  border-radius: 6px;
`;

const ErrorCouponContainer = styled.div`
  padding: 4px 12px 4px 12px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  display: flex;
  margin-left: 15px;
  margin-bottom: 10px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  position: absolute;
  padding: 12px;
  right: 12px;

  ${respondToWidth.sm`
  position: unset;
  margin-left: -20%;
  width: 100%;
  padding: 4px 4px 4px 10px;
`}

  ${respondToWidth.s`
    position: unset;
    padding: 5px;
    margin-left: -17%;
    width: 85%;
    text-align: center;
`}
`;

const ErrorNegativePriceContainer = styled.div`
  width: 200px;
  padding: 2px 12px 2px 12px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  display: flex;
  margin-left: 15px;
  margin-bottom: 10px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  position: absolute;
  right: 12px;

  ${respondToWidth.sm`
  position: unset;
  margin-left: -20%;
  width: 100%;
  padding: 4px 4px 4px 10px;
`}

  ${respondToWidth.s`
    position: unset;
    padding: 5px;
    margin-left: -17%;
    width: 85%;
    text-align: center;
`}
`;

const ErrorText = styled(StyledText)`
  text-align: start;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.system.red};
    ${typography.body_basic_medium_14}

    ${respondToWidth.s`
      ${typography.footnote_semibold_12}
  `}
  `};
`;
