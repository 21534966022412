import { UncompletedTestModal as UncompletedTestModalUI } from 'UI';
import { QuizTestPassageResponseDtoTypeEnum } from 'api/generated';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage } from 'utils';

const UncompletedTestModal = () => {
  const { errorToast } = useNotifications();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const [uncompletedTestType, setUncompletedTestType] = useState<null | QuizTestPassageResponseDtoTypeEnum>(null);

  const dispatch = useAppDispatch();

  const uncompletedTutorTest = useAppSelector(selectors.tests.selectTutorTest);
  const uncompletedTimedTest = useAppSelector(selectors.tests.selectTimedTest);
  const [getUncompletedTestAction, isStarting] = useAsyncAction(actions.tests.getUncompletedTest);
  const [finishTestAction, isFinishing] = useAsyncAction(actions.tests.finishTest);

  const getUncompletedTest = async () => {
    try {
      const response = await getUncompletedTestAction();

      setUncompletedTestType(response.type);
      openModal();
    } catch (error) {
      errorToast(getErrorMessage(error));
    }
  };

  const finishTest = async (passageId: string) => {
    try {
      await finishTestAction({ passageId });
    } catch (error) {
      errorToast(getErrorMessage(error));
    } finally {
      closeModal();
    }
  };

  const startTimedTest = async (questionIndex: number) => {
    setQuestionIndex(questionIndex);

    navigate(routes.timedTest);
    closeModal();
  };

  const startTutorTest = async (questionIndex: number) => {
    setQuestionIndex(questionIndex);

    navigate(routes.tutorTest);
    closeModal();
  };

  const setQuestionIndex = (questionIndex: number) => {
    dispatch(actions.tests.setCurrentQuestionIndex(questionIndex));
  };

  useEffect(() => {
    getUncompletedTest();
  }, []);

  const questionsLeftForTimed =
    uncompletedTimedTest?.questions.filter((question) => !question.answer.optionId).length || 0;
  const questionsLeftForTutor =
    uncompletedTutorTest?.questions.filter((question) => !question.answer.optionId).length || 0;

  if (uncompletedTestType === 'timed_test') {
    return uncompletedTimedTest ? (
      <UncompletedTestModalUI
        willCloseAt={uncompletedTimedTest.willCloseAt}
        questionsCount={questionsLeftForTimed}
        startTest={() => startTimedTest(uncompletedTimedTest.questions.length - questionsLeftForTimed)}
        cancelTest={() => finishTest(uncompletedTimedTest.id)}
        isStarting={isStarting}
        isFinishing={isFinishing}
        isOpen={isOpen}
      />
    ) : null;
  }
  if (uncompletedTestType === 'quiz_test' || uncompletedTestType === 'tutor_test') {
    return uncompletedTutorTest ? (
      <UncompletedTestModalUI
        questionsCount={questionsLeftForTutor}
        startTest={() => startTutorTest(uncompletedTutorTest.questions.length - questionsLeftForTutor)}
        cancelTest={() => finishTest(uncompletedTutorTest.id)}
        isStarting={isStarting}
        isFinishing={isFinishing}
        isOpen={isOpen}
      />
    ) : null;
  }

  return null;
};

export default UncompletedTestModal;
