import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { MnemonicCardsPacksDto } from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType, PaginationType } from 'types';

export const getMnemonicCardsPacksThunk = createAsyncThunk<
  { data: MnemonicCardsPacksDto; isFirstFetch: boolean },
  PaginationType | undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('mnemonicCards/packs', async (body, { rejectWithValue }) => {
  try {
    const limit = body?.limit || 20;
    const isFirstFetch = !body?.page || body?.page === 1;

    const { data, status } = await api.MnemonicCards.mnemonicCardsControllerGetMnemonicCardsPacks(body?.page, limit);
    if (status > 399) {
      throw data;
    }
    return { data, isFirstFetch };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
