import { useGetColorForTimer, useTimer } from 'hooks';
import { FC } from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type TimerProps = {
  onTimerEndFunc: () => void;
  expiredDate: string;
};

const Timer: FC<TimerProps> = ({ onTimerEndFunc, expiredDate }) => {
  const { hoursString, minutesString, secondsString } = useTimer(expiredDate, onTimerEndFunc);
  const timer = `${hoursString}:${minutesString}:${secondsString}`;
  const currentSecondToEnd = Number(minutesString) * 60 + Number(secondsString);
  const colorCSS = useGetColorForTimer(currentSecondToEnd);

  return <Container $color={colorCSS}>{timer} </Container>;
};

export default Timer;

const Container = styled.div<{ $color?: FlattenSimpleInterpolation }>`
  padding: 6px 0;
  border-radius: 10px;
  min-width: 158px;
  width: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme: { typography }, $color }) => css`
    ${$color};
    ${typography.body_large_semibold_16};
  `}

  ${respondToWidth.sm`
    width:108px;
    min-width: 108px;
  `}
`;
