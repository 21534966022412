import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgBulbIcon: FC<SVGIconProps> = ({ color: propsColor, size = 16, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[3];

  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.56869 0V2.53897H8.43107V0H7.56869ZM4.04722 0.763L3.34066 1.25771L4.7967 3.33699L5.50287 2.84227L4.04722 0.763ZM11.9524 0.763L10.4968 2.84228L11.203 3.337L12.659 1.25771L11.9524 0.763ZM7.9997 3.23365C5.89001 3.23365 4.17979 4.51476 4.17979 6.09532C4.17979 7.67588 6.35009 11.668 6.35009 11.668H9.64969C9.64969 11.668 11.8198 7.67585 11.8198 6.09532C11.8198 4.51479 10.1095 3.23365 7.9997 3.23365ZM1.13938 3.99564L0.844727 4.80649L3.22938 5.67468L3.52513 4.86421L1.13938 3.99564ZM14.8602 3.99564L12.4744 4.86428L12.7691 5.67484L15.155 4.80651L14.8602 3.99564ZM3.40993 7.70241L0.957927 8.36009L1.18041 9.19251L3.63241 8.53577L3.40993 7.70241ZM12.5897 7.70241L12.3673 8.53576L14.8193 9.19239L15.0417 8.35996L12.5897 7.70241ZM6.30198 12.1471V13.2896H9.69781V12.1473L6.30198 12.1471ZM6.30198 13.8575V15H9.69781V13.8577L6.30198 13.8575Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgBulbIcon);
export default Memo;
