import { FC } from 'react';
import styled from 'styled-components';

import { Option } from '../../types';

interface Props {
  item: Option;
}

export const Item: FC<Props> = ({ item }) => {
  const { isShow, title, onClick, icon } = item;
  return isShow ? (
    <Root onClick={onClick} key={title}>
      {icon}
      {title}
    </Root>
  ) : null;
};

const Root = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-height: 48px;
  ${({ theme: { typography } }) => typography.body_basic_bold_14};
  cursor: pointer;

  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 4px;
  }
`;
