import { ScrollbarContainer } from 'UI';
import { AxiosError } from 'axios';
import { accessLevels } from 'constant';
import { queryConstants, routes } from 'constant/routes';
import { useAsyncAction, useNotifications, useQuery } from 'hooks';
import React, { FC, useCallback, useDeferredValue, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { Body, FooterAudio, Header, NavigationFooter, TestFooter } from './components';
import { BODY_ID } from './components/Body/Body';

type ChapterProps = {
  toggleContentModal: () => void;
  openUpgradeModal: () => void;
  openAudioUpgradeModal: () => void;
  isPause: boolean | undefined;
};

const Chapter: FC<ChapterProps> = ({ toggleContentModal, openUpgradeModal, openAudioUpgradeModal, isPause }) => {
  const [isShowNavigation, setIsShowNavigation] = useState(false);
  const { errorToast } = useNotifications();
  const navigate = useNavigate();
  const query = useQuery();

  const chapterIndex = Number(query.get(queryConstants.chapterIndex)) || 0;
  const deferredChapterIndex = useDeferredValue(chapterIndex);

  const [getChapterByIdAction, isChapterFetching] = useAsyncAction(actions.studyGuide.getChapterById);
  const tableOfGuideContent = useAppSelector(selectors.studyGuide.selectTableOfGuideContent);
  const studyGuide = useAppSelector(selectors.studyGuide.selectStudyGuide);
  const currentChapter = useAppSelector(selectors.studyGuide.selectCurrentChapter);
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);

  const { hasAllChaptersInStudyGuide } = isPause
    ? accessLevels.null
    : activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  const hasAudio = Boolean(currentChapter?.audioUrl);
  const chaptersLength = tableOfGuideContent?.chapters?.length || 0;
  const numberOfChapters = chaptersLength - 1;

  const isChapterNotAvailable =
    chapterIndex > numberOfChapters || chapterIndex < 0 || (!hasAllChaptersInStudyGuide && chapterIndex > 0);

  const navigateToChapter = useCallback((nextChapterIndex: number) => {
    if (!hasAllChaptersInStudyGuide && nextChapterIndex > 0) {
      openUpgradeModal();
    }
    navigate(`${routes.studyGuide}?${queryConstants.chapterIndex}=${nextChapterIndex}`);
  }, []);

  const getChapter = (chapterIndex: number) => {
    try {
      const chapter = tableOfGuideContent?.chapters[chapterIndex];
      const isCurrentChapter = chapter?.id === currentChapter?.id;
      const canFetchChapter = chapter && studyGuide && !isCurrentChapter;

      if (canFetchChapter) {
        getChapterByIdAction({ guideId: studyGuide.id, chapterId: chapter.id });
      } else if (chapterIndex !== 0 && !isCurrentChapter) {
        navigateToChapter(0);
      }
    } catch (error) {
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    if (isChapterNotAvailable) {
      navigateToChapter(0);
      openUpgradeModal();
    } else {
      getChapter(chapterIndex);
    }
  }, [deferredChapterIndex, isChapterNotAvailable, studyGuide?.id]);

  useEffect(() => {
    setIsShowNavigation(Boolean(!currentChapter?.quizId));
  }, [currentChapter?.quizId]);

  return (
    <>
      <Root>
        <Header chapterIndex={chapterIndex} toggleContentModal={toggleContentModal} />
        <StyledScrollbar $hasAudio={hasAudio} id={BODY_ID}>
          <Body isLoading={isChapterFetching} />
          <StyledFooterAudio openUpgradeModal={openAudioUpgradeModal} />
          <Footer>
            {!isChapterFetching &&
              (isShowNavigation ? (
                <NavigationFooter
                  hasTest={Boolean(currentChapter?.quizId)}
                  navigateToChapter={navigateToChapter}
                  numberOfChapters={numberOfChapters}
                />
              ) : (
                <TestFooter chapterIndex={chapterIndex} showNavigation={() => setIsShowNavigation(true)} />
              ))}
          </Footer>
        </StyledScrollbar>
      </Root>
    </>
  );
};

export default Chapter;

const Root = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: auto 1fr;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;

  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.sm`
       border-radius: 0;
  `};
`;

const StyledScrollbar = styled(ScrollbarContainer)<{ $hasAudio: boolean }>`
  height: 100%;

  display: grid;
  grid-template-rows: 1fr auto;

  ${({ $hasAudio }) =>
    $hasAudio &&
    css`
      ${respondToWidth.sm`
          padding-bottom: 56px;
      `}
    `}
`;

const Footer = styled.div`
  height: fit-content;
`;

const StyledFooterAudio = styled(FooterAudio)`
  display: none !important;

  ${respondToWidth.sm`
      display: flex !important;
  `}
`;
