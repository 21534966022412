import { MnemonicCardDto } from 'api/generated';
import React, { FC, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';

import MnemonicImage from '../MnemonicImage';
import MnemonicQuestion from '../MnemonicQuestion';

type MainContentProps = {
  cards: MnemonicCardDto[];
  onNextClick: () => void;
  onBackClick: () => void;
  onReportClick: () => void;
  activeIndex: number;
  className?: string;
  isFlipped: boolean;
  onChangeClick: () => void;
  isScale: boolean;
  setControlState: (data: boolean) => void;
};

const MainContent: FC<MainContentProps> = ({
  cards,
  onNextClick,
  onBackClick,
  onReportClick,
  activeIndex,
  className,
  isFlipped,
  onChangeClick,
  isScale,
  setControlState,
}) => {
  const { opacity, transform } = useSpring({
    opacity: +isFlipped,
    transform: `perspective(600px) rotateY(${isFlipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  useEffect(() => setControlState(true), []);

  const isFirstSLide = activeIndex === 0;
  const isLastSlide = activeIndex === cards.length - 1;

  return (
    <MainWrapper className={className} $isShow={isScale}>
      <Animated
        style={{
          opacity: opacity.to((o) => 1 - o),
          transform,
        }}
        $isFlipped={isFlipped}>
        <MnemonicQuestion
          onClick={onChangeClick}
          cards={cards}
          onNextClick={onNextClick}
          onPrevClick={onBackClick}
          onReportClick={onReportClick}
          isFirstSlide={isFirstSLide}
          isLastSlide={isLastSlide}
          isFlipped={isFlipped}
          activeIndex={activeIndex}
        />
      </Animated>
      <Animated
        style={{
          opacity,
          transform,
          rotateY: '180deg',
        }}
        $isFlipped={!isFlipped}>
        <MnemonicImage
          url={cards[activeIndex].imageUrl}
          alt={cards[activeIndex].title}
          onClick={onChangeClick}
          onNextClick={onNextClick}
          onPrevClick={onBackClick}
          onReportClick={onReportClick}
          isFirstSlide={isFirstSLide}
          isLastSlide={isLastSlide}
          isFlipped={isFlipped}
        />
      </Animated>
    </MainWrapper>
  );
};

export default MainContent;

const MainWrapper = styled.div<{ $isShow: boolean }>`
  height: 100%;
  width: 100%;
  ${({ $isShow }) =>
    $isShow
      ? css`
          opacity: 1;
          transform: scale(1);
        `
      : css`
          opacity: 0;
          transform: scale(0);
        `};

  transition: transform 1s ease;
`;

const Animated = styled(animated.div)<{
  $isFlipped: boolean;
}>`
  height: 100%;
  display: ${({ $isFlipped }) => ($isFlipped ? 'none' : 'flex')};
  flex-direction: column;
`;
