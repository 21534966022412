import { TestPassageStatsDto } from 'api/generated';
import LegendItem from 'pages/Performance/components/LegendItem';
import React, { FC } from 'react';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { StatisticColumnType } from 'types';

type LegendProps = {
  data: Omit<TestPassageStatsDto, 'id'> | null;
};

const Legend: FC<LegendProps> = ({ data }) => {
  return (
    <Container>
      <LegendItem
        name={StatisticColumnType.CORRECT}
        percent={data?.correct.percent || 0}
        amount={data?.correct.count || 0}
      />
      <LegendItem
        name={StatisticColumnType.INCORRECT}
        percent={data?.incorrect.percent || 0}
        amount={data?.incorrect.count || 0}
      />
    </Container>
  );
};

export default Legend;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 60px;
  position: sticky;

  ${respondToWidth.lg`
    gap:20px;
  `}

  ${respondToWidth.ls`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${respondToWidth.sm`
    display:flex;
    flex-direction:column;
    align-items: start;
    gap:20px;
  `}
`;
