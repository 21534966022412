import { Button, Input } from 'UI';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useIsLandscapeOrientation } from 'hooks/useIsLandscapeOrientation';
import { LayoutStyledProps } from 'pages/MnemonicCardPractice/MnemonicCardPractice';
import { FC, FocusEvent, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';

import { FieldName } from '../../MnemonicQuestion';

type InputsListProps = {
  amountInputs: number;
  setActiveState: (value: FieldName) => void;
  clearInputs: () => void;
};

const InputsList: FC<InputsListProps> = ({ amountInputs, setActiveState, clearInputs }) => {
  const inputsList = useMemo(() => new Array(amountInputs).fill(null).map((_, index) => index), [amountInputs]);
  const { control } = useFormContext();

  const getAnswerActive = () => {
    setActiveState(FieldName.Answer);
  };

  const getWordActive = () => {
    setActiveState(FieldName.Word);
  };

  const resetActive = () => {
    setActiveState(FieldName.None);
  };

  const { isLandscapeOrientation } = useIsLandscapeOrientation();
  const { isMobile } = useGetIsMobile();
  const isMobileLandscape = isLandscapeOrientation && isMobile;

  const handleBlur = (e: FocusEvent<HTMLInputElement, Element>, onChange: (value: string) => void) => {
    const onChangeValue = e.target.value?.trim() || '';

    resetActive();
    onChange(onChangeValue);
  };

  return (
    <Container $isMobileLandscape={isMobileLandscape}>
      {inputsList.map((_, index) => (
        <Wrapper key={index} $isMobileLandscape={isMobileLandscape}>
          <Controller
            control={control}
            defaultValue=""
            name={`Answer ${index}`}
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                showIcon={field.value !== ''}
                onIconClick={() => field.onChange('')}
                placeholder={`Answer ${index + 1}`}
                onFocus={getAnswerActive}
                onBlur={(e) => handleBlur(e, field.onChange)}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue=""
            name={`Mnemonic ${index}`}
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                showIcon={String(field.value).length > 0}
                onIconClick={() => field.onChange('')}
                placeholder={`Mnemonic word ${index + 1}`}
                onFocus={getWordActive}
                onBlur={(e) => handleBlur(e, field.onChange)}
                error={error?.message}
              />
            )}
          />
        </Wrapper>
      ))}
      <ClearButtonMobile variant="tertiary" size="small" onClick={clearInputs} $isMobileLandscape={isMobileLandscape}>
        Clear inputs
      </ClearButtonMobile>
    </Container>
  );
};

export default InputsList;

const Container = styled.div<LayoutStyledProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;

  ${respondToHeight.lg`
    gap:24px;
    padding-bottom: 30px;
  `}

  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      padding-bottom: 0;
      gap: 20px;
    `}

  ${respondToWidth.sm`
    padding-right: 8px;
    padding-bottom: 0;
    gap:20px;
  `}
`;

const ClearButtonMobile = styled(Button)<LayoutStyledProps>`
  width: 100%;
  margin-top: 20px;
  display: ${({ $isMobileLandscape }) => ($isMobileLandscape ? 'block' : 'none')};

  ${respondToWidth.sm`
    display:block;
  `}
`;

const Wrapper = styled.div<LayoutStyledProps>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ $isMobileLandscape }) => ($isMobileLandscape ? '10px' : '24px')};

  ${respondToWidth.sm`
    gap:10px;
  `}
`;

const StyledInput = styled(Input)`
  flex: 1;
  transition: flex 0.4s ease;

  &:focus-within {
    ${respondToWidth.sm`
      flex: 4;
    `}
  }
`;
