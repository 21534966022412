import { ChartLegendItem } from 'UI';
import React, { FC } from 'react';
import { AnswerEnum } from 'store/ducks/performance/types';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { StatisticValue } from 'types';
import { getUpFirstLetter } from 'utils/textHelpers';

type LegendProps = {
  data: StatisticValue[];
  isTest: boolean;
  getColor: (name: AnswerEnum) => string;
};

const Legend: FC<LegendProps> = ({ data, isTest, getColor }) => {
  return (
    <LegendContainer $isTest={isTest}>
      {data.map((item, index) => (
        <LegendItem
          key={index}
          color={getColor(item.name as AnswerEnum)}
          title={getUpFirstLetter(item.name)}
          percent={item.percent}
          amount={item.count}
        />
      ))}
    </LegendContainer>
  );
};

export default Legend;

const LegendContainer = styled.div<{ $isTest: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $isTest }) => `repeat(${$isTest ? 3 : 4}, 1fr)`};
  padding: 24px;
  padding-top: 23px;
  gap: 20px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};

  ${({ $isTest }) => respondToWidth.s`
    padding:16px;
    padding-bottom:20px;
    padding-top:${!$isTest && '19px'};
    grid-template-columns:${!$isTest && 'repeat(2, 1fr)'}
  `}
`;

const LegendItem = styled(ChartLegendItem)`
  width: fit-content;
`;
