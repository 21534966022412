import { OutputData } from '@editorjs/editorjs';
import { Button, DefaultModal, ScrollbarContainer, StyledText } from 'UI';
import { ManualTypeEnum } from 'api/generated';
import Loader from 'components/Loader';
import { useGetManual } from 'hooks';
import { Dispatch, FC, SetStateAction, useRef } from 'react';
import { Editor } from 'services';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

type UpgradeSubscriptionModalProps = {
  title?: string;
  setIsShowBonusModal?: Dispatch<SetStateAction<boolean>>;
  manualType: ManualTypeEnum;
  isBonusModal?: boolean;
} & BaseModalProps;

const UpgradeSubscriptionModal: FC<UpgradeSubscriptionModalProps> = ({
  onClose,
  manualType,
  isBonusModal,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const editorRef = useRef(null);

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const { content, isManualLoading } = useGetManual(manualType, activeCourse?.id);

  const openChooseProductModalOpen = () => {
    onClose();
    dispatch(actions.courses.openChooseProductModalOpen());
  };

  return (
    <DefaultModal
      onRequestClose={onClose}
      title={content?.title}
      containerCSS={ContainerCSS}
      titleCSS={TitleCSS}
      modalCSS={ModalCSS}
      closeButtonCSS={CloseCSS}
      headerContainerCSS={HeaderContainerCSS}
      {...props}>
      <StyledScrollBar>
        {isManualLoading ? (
          <Loader />
        ) : content ? (
          <StyledEditor defaultData={content.content as OutputData} holder="manual" editorRef={editorRef} />
        ) : (
          <Text>Sorry... An error has occurred, please try again.</Text>
        )}
      </StyledScrollBar>

      {!isBonusModal && (
        <StyledUpgradeButton onClick={openChooseProductModalOpen} variant="primary" size="middle">
          {content?.buttonText || 'Upgrade'}
        </StyledUpgradeButton>
      )}
      <StyledCloseButton variant="secondary" onClick={onClose}>
        Close
      </StyledCloseButton>
    </DefaultModal>
  );
};

export default UpgradeSubscriptionModal;

const ContainerCSS = css`
  width: 800px;

  ${respondToWidth.ls`
    width:668px;
    padding: 40px 34px 30px;
  `}

  ${respondToWidth.sm`
    width:100%;
    padding: 28px 32px 28px;
  `}

  ${respondToWidth.s`
    width:100%;
    padding: 28px 16px 16px;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  `}
`;
const CloseCSS = css`
  ${respondToWidth.sm`
  display:none;
`}
`;

const HeaderContainerCSS = css`
  ${respondToWidth.sm`
  padding-right: 16px;
`}

  ${respondToWidth.s`
  padding-right: 8px;
  justify-content: center;
`}
`;

const TitleCSS = css`
  ${({ theme: { typography } }) => typography.title_5_bold_20};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.title_5_bold_20};
  `};
`;

const Text = styled(StyledText)`
  width: 100%;
  white-space: pre-wrap;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.subhead_regular_13};  
  `}
`;

const StyledUpgradeButton = styled(Button)`
  margin-top: 16px;
`;

const StyledCloseButton = styled(Button)`
  margin-top: 16px;
  display: none;

  ${respondToWidth.sm`
  display:block;
`}
`;

const StyledScrollBar = styled(ScrollbarContainer)`
  max-height: calc(100vh - 200px);
`;

const StyledEditor = styled(Editor)`
  margin-bottom: 40px;
  div div {
    margin: 0 auto !important;
  }
`;
