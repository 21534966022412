import Input from 'UI/Input';
import { InputProps } from 'UI/Input/Input';
import { EyeCloseIcon, EyeIcon } from 'assets/icons';
import React, { FC, useState } from 'react';
import { useTheme } from 'styled-components';

const PasswordInput: FC<InputProps> = (props) => {
  const { colors } = useTheme();
  const [isShowPassword, setIsShowPassword] = useState(false);

  return (
    <Input
      {...props}
      type={isShowPassword ? 'text' : 'password'}
      showIcon
      iconComponent={
        isShowPassword ? <EyeCloseIcon color={colors.neutrals[4]} /> : <EyeIcon color={colors.neutrals[4]} />
      }
      onIconClick={() => setIsShowPassword((prev) => !prev)}
    />
  );
};

export default PasswordInput;
