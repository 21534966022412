import StyledText from 'UI/StyledText';
import { CouponResponseDtoDiscountTypeEnum } from 'api/generated';
import { ArrowBackIcon } from 'assets/icons';
import React, { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

const DiscountedPrice: FC<{ price: number; discount: number | undefined; discountType: string | undefined }> = ({
  price,
  discount,
  discountType,
}) => {
  const { colors } = useTheme();
  const priceString = ` $${price}`;

  const isDiscountTypePercentage = discountType === CouponResponseDtoDiscountTypeEnum.Percentage;

  const finalPrice = isDiscountTypePercentage ? price - (price * Number(discount)) / 100 : price - Number(discount);

  const formatPrice = (price: number) => {
    return Number.isInteger(price) ? price.toString() : price.toFixed(2);
  };

  return (
    <Container>
      <Text>{priceString}</Text>
      <RotatedArrowBackIcon color={colors.neutrals[4]} />
      <MonthPriceLabel>${formatPrice(finalPrice)}</MonthPriceLabel>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 30px;
  margin-bottom: -20px;

  ${respondToWidth.s`
    margin-top: 20px;
  	 margin-bottom: 0px;
  `}
`;

const Text = styled(StyledText)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme: { colors, typography } }) => css`
    margin-bottom: 8px;

    color: ${colors.neutrals[4]};
    ${typography.title_3_bold_28};
  `}
`;

const MonthPriceLabel = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    margin-bottom: 8px;

    color: ${colors.primary[1]};
    ${typography.title_3_bold_28};
  `}
`;

const RotatedArrowBackIcon = styled(ArrowBackIcon)`
  transform: rotate(180deg);
`;

export default DiscountedPrice;
