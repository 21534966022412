import useAsyncAction from 'hooks/useAsyncAction';
import { useEffect } from 'react';
import { actions, selectors, useAppSelector } from 'store';

const useGetMe = () => {
  const accessToken = useAppSelector(selectors.auth.selectAccessToken);
  const [getMe] = useAsyncAction(actions.user.getMe);

  useEffect(() => {
    if (accessToken) {
      getMe();
    }
  }, [accessToken]);
};

export default useGetMe;
