import { ChangeFullNameModal as ChangeFullNameModalUI } from 'UI';
import { FormState } from 'UI/Modals/ChangeFullNameModal/ChangeFullNameModal';
import { useAsyncAction } from 'hooks';
import React, { FC } from 'react';
import { actions } from 'store';
import { BaseModalProps } from 'types';

type ChangeFullNameModalProps = {
  name?: string;
} & BaseModalProps;

const ChangeFullNameModal: FC<ChangeFullNameModalProps> = ({ ...props }) => {
  const [userPatchProfile, isLoading] = useAsyncAction(actions.user.patchProfile);

  const patchProfile = async (values: FormState) => {
    await userPatchProfile({ name: values.newName.trim() });
  };

  return <ChangeFullNameModalUI {...props} patchProfile={patchProfile} isLoading={isLoading} />;
};

export default ChangeFullNameModal;
