import { CourseDtoLeastKnownCategoryEnum, LeastKnownCategoryResponseDto } from 'api/generated';
import { selectors, useAppSelector } from 'store';

const useGetCategory = () => {
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const leastKnowCategory = activeCourse?.leastKnownCategory;
  const leastKnowDomains = useAppSelector(selectors.tests.selectLeastKnowDomains);
  const leastKnowChapters = useAppSelector(selectors.tests.selectLeastKnowChapters);

  const categoryAdapter: Record<CourseDtoLeastKnownCategoryEnum, LeastKnownCategoryResponseDto[]> = {
    [CourseDtoLeastKnownCategoryEnum.Domain]: leastKnowDomains,
    [CourseDtoLeastKnownCategoryEnum.Chapter]: leastKnowChapters,
  };

  const currentCategory = leastKnowCategory ? categoryAdapter[leastKnowCategory] : [];

  return {
    leastKnowCategory,
    currentCategory,
    activeCourse,
  };
};

export default useGetCategory;
