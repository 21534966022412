import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgLoaderIcon: FC<SVGIconProps> = ({ color: propsColor, size = 32, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M24.485 7.515 22.6 9.4a9.333 9.333 0 1 0 2.733 6.6H28a12 12 0 1 1-3.515-8.485Z" fill={color} />
    </svg>
  );
};

const Memo = memo(SvgLoaderIcon);
export default Memo;
