import SubscriptionItem from 'UI/SubscriptionItem';
import { CapabilityType } from 'UI/SubscriptionItem/SubscriptionItem';
import {
  CouponResponseDto,
  CoursePricesDtoMonthlySubscriptions,
  PaymentDto,
  PaymentDtoTypeEnum,
  PriceDto,
  SubscribeDtoTypeEnum,
} from 'api/generated';
import arrowDow2 from 'assets/images/arrow-down2.png';
import arrowDown from 'assets/images/arrow-down.png';
import shape from 'assets/images/shape.png';
import gift from 'assets/json/gift_white_3d.json';
import raiseСup from 'assets/json/pricing-animate-1.json';
import throwInBasket from 'assets/json/pricing-animate-2.json';
import dribbling from 'assets/json/pricing-animate-3.json';
import throwInBasketDark from 'assets/json/pricing_icon_1_V1(square).json';
import dribblingDark from 'assets/json/pricing_icon_2_V1(square).json';
import raiseСupDark from 'assets/json/pricing_icon_3_V1(square).json';
import {
  bronzeCapabilities,
  bronzeCapabilitiesResponsive,
  commonCapabilities,
  goldCapabilities,
  goldCapabilitiesResponsive,
  levelGradeMapping,
  silverCapabilities,
  silverCapabilitiesResponsive,
} from 'constant';
import Lottie from 'lottie-react';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { dayjs } from 'services';
import { selectors, useAppSelector } from 'store';
import { CourseOptionsForPayment } from 'store/ducks/courses/types';
import styled, { css } from 'styled-components';
import { ManualPaymentsDescriptions } from 'types';

import { TrialText } from './components';

type ProductsProps = {
  isPurchase?: boolean;
  description?: string;
  courseId: string;
  currentPayment?: PaymentDto;
  nextPayment?: PaymentDto;
  prices: Partial<CoursePricesDtoMonthlySubscriptions>;
  monthlyPrices?: Partial<CoursePricesDtoMonthlySubscriptions> | null;
  annualPrices?: Partial<CoursePricesDtoMonthlySubscriptions> | null;
  purchasePrice?: object | null;
  setIsShowBonusModal: Dispatch<SetStateAction<boolean>>;
  toggleSubscription: (options: CourseOptionsForPayment, isCurrent: boolean) => void;
  startTrial: (courseId: string) => void;
  startUnsubscribeProcess: () => void;
  cancelSubscriptionDowngrade: (courseId: string, isCancelTrial?: boolean) => Promise<void>;
  isTrialStarting: boolean;
  isSubscriptionUpdating: boolean;
  paymentsDescriptions?: ManualPaymentsDescriptions;
  // TODO change to a necessary type after the Dto will be updated
  mostEffectiveLabel?: {
    phrase?: string;
    color?: string;
  };
};

export type Plan = Partial<PriceDto> & {
  type: SubscribeDtoTypeEnum;
  priceMonthly: any;
  priceAnnual: any;
  capabilities: CapabilityType[];
  capabilitiesResponsive: CapabilityType[];
  defaultCoupon?: CouponResponseDto;
  animationData: any;
  darkAnimationData: any;
};

export type ActivePlan = {
  planType: string;
  term: string;
};

const Products: FC<ProductsProps> = ({
  monthlyPrices,
  annualPrices,
  purchasePrice,
  currentPayment,
  nextPayment,
  setIsShowBonusModal,
  courseId,
  isTrialStarting,
  isSubscriptionUpdating,
  toggleSubscription,
  startUnsubscribeProcess,
  cancelSubscriptionDowngrade,
  startTrial,
  mostEffectiveLabel,
}) => {
  let isMaxHeight = false;
  const isTrialText = currentPayment?.level === 'none' || nextPayment?.type === 'Free';
  const [activeIndex, setActiveIndex] = useState(0);
  const [activePeriod, setActivePeriod] = useState('gold');
  const [planType, setPlanType] = useState<ActivePlan>();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const today = dayjs();
  const day = today.date();
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  const isGoldPurchase = currentPayment?.type === PaymentDtoTypeEnum.Purchase;
  const hasSubscription = currentPayment?.type === PaymentDtoTypeEnum.Subscription;

  const isResponsive = screenWidth <= 1180;

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const getOrdinal = (day: number) => {
    if (day % 10 === 1 && day % 100 !== 11) return 'st';
    if (day % 10 === 2 && day % 100 !== 12) return 'nd';
    if (day % 10 === 3 && day % 100 !== 13) return 'rd';
    return 'th';
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const plans: Array<Plan> = [
    {
      priceMonthly: monthlyPrices?.gold,
      priceAnnual: annualPrices?.gold,
      type: SubscribeDtoTypeEnum.Gold,
      capabilities: goldCapabilities,
      capabilitiesResponsive: goldCapabilitiesResponsive,
      animationData: raiseСup,
      darkAnimationData: throwInBasketDark,
    },

    {
      priceMonthly: monthlyPrices?.silver,
      priceAnnual: annualPrices?.silver,
      type: SubscribeDtoTypeEnum.Silver,
      capabilities: silverCapabilities,
      capabilitiesResponsive: silverCapabilitiesResponsive,
      animationData: throwInBasket,
      darkAnimationData: dribblingDark,
    },
    {
      priceMonthly: monthlyPrices?.bronze,
      priceAnnual: annualPrices?.bronze,
      type: SubscribeDtoTypeEnum.Bronze,
      capabilities: bronzeCapabilities,
      capabilitiesResponsive: bronzeCapabilitiesResponsive,
      animationData: dribbling,
      darkAnimationData: raiseСupDark,
    },
  ];
  let countPlans: number;

  const goldPlan = plans.filter((plan) => plan.type === SubscribeDtoTypeEnum.Gold)[0];
  const silverPlan = plans.filter((plan) => plan.type === SubscribeDtoTypeEnum.Silver)[0];
  const bronzePlan = plans.filter((plan) => plan.type === SubscribeDtoTypeEnum.Bronze)[0];
  const notGoldPlan = plans.filter((plan) => plan.type !== SubscribeDtoTypeEnum.Gold)[0];

  const goldCounts = (goldPlan.priceAnnual ? 1 : 0) + (goldPlan.priceMonthly ? 1 : 0) + (purchasePrice ? 1 : 0);
  const silverCounts = (silverPlan.priceAnnual ? 1 : 0) + (silverPlan.priceMonthly ? 1 : 0);
  const bronzeCounts = (bronzePlan.priceAnnual ? 1 : 0) + (bronzePlan.priceMonthly ? 1 : 0);
  const notGoldCounts = (notGoldPlan.priceAnnual ? 1 : 0) + (notGoldPlan.priceMonthly ? 1 : 0);

  const getCapabilitiesMargin = () => {
    let actualPlanPricesMargin = 0;

    if (goldCounts === 2 && notGoldCounts === 1) {
      isMaxHeight = true;
    }

    plans.map((plan) => {
      const countPlanPrices =
        (plan.priceAnnual ? 1 : 0) +
        (plan.priceMonthly ? 1 : 0) +
        (purchasePrice && plan.type === SubscribeDtoTypeEnum.Gold ? 1 : 0);

      if (countPlanPrices === 1 && actualPlanPricesMargin <= 473) {
        actualPlanPricesMargin = 416;
        if (isMaxHeight) {
          actualPlanPricesMargin = 465;
        }
      } else if (countPlanPrices === 2 && actualPlanPricesMargin < 530) {
        actualPlanPricesMargin = 473;
      } else if (countPlanPrices === 3 && actualPlanPricesMargin < 580) {
        actualPlanPricesMargin = 580;
      }
    });

    return `${actualPlanPricesMargin}px`;
  };

  return (
    <Root style={{ marginTop: isResponsive ? '-557px' : '15px' }}>
      <Wrapper>
        <Container $isShowPurchase={false} isResponsive={isResponsive}>
          {!isResponsive && (
            <div style={{ width: '70px', height: '70px', position: 'absolute', top: '260px', left: '100px' }}>
              <img src={shape} alt="shape" style={{ filter: isDarkMode ? 'invert(1)' : 'unset' }} />
            </div>
          )}

          <ImageDivArrowRight>
            <img src={arrowDow2} alt="arrow" style={{ filter: isDarkMode ? 'invert(1)' : 'unset' }} />
          </ImageDivArrowRight>

          <ImageDivArrowLeft>
            <img src={arrowDown} alt="arrow" style={{ filter: isDarkMode ? 'invert(1)' : 'unset' }} />
          </ImageDivArrowLeft>

          <BonusContainer onClick={() => setIsShowBonusModal(true)}>
            <Lottie animationData={gift} />
            <div>
              <BonusTitle>Click to see MVP bonuses</BonusTitle>
              <BonusTitle style={{ minWidth: '70px', maxWidth: '125px' }}>{`for ${today.format(
                'MMMM ',
              )}${day}${getOrdinal(day)} `}</BonusTitle>
            </div>
          </BonusContainer>

          {plans && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: getCapabilitiesMargin(),
              }}>
              {!isResponsive &&
                commonCapabilities.map((cap, index) => (
                  <div style={{ alignItems: 'center', width: '230px' }} key={index}>
                    <CapabilitiesContainer key={index}>{cap.title}</CapabilitiesContainer>
                  </div>
                ))}
            </div>
          )}
          {plans.map((plan, index) => {
            const termsCount =
              (plan.priceAnnual ? 1 : 0) +
              (plan.priceMonthly ? 1 : 0) +
              (purchasePrice && plan.type === SubscribeDtoTypeEnum.Gold ? 1 : 0);

            const isLowest = currentPayment?.level
              ? levelGradeMapping[currentPayment.level] > levelGradeMapping[plan.type]
              : false;

            return (
              <SubscriptionItem
                key={index}
                isResponsive={isResponsive}
                plan={plan}
                termsCount={termsCount}
                setIsShowBonusModal={setIsShowBonusModal}
                indexPlan={index}
                goldCount={goldCounts}
                silverCounts={silverCounts}
                bronzeCounts={bronzeCounts}
                animationData={isDarkMode ? plan.darkAnimationData : plan.animationData}
                setPlanType={setPlanType}
                planType={planType}
                countPlans={countPlans}
                isMaxHeight={isMaxHeight}
                isShow={activeIndex === index}
                activePeriod={activePeriod}
                setActivePeriod={setActivePeriod}
                setActiveIndex={setActiveIndex}
                monthlyPrices={plan.priceMonthly}
                annualPrices={plan.priceAnnual}
                purchasePrice={purchasePrice}
                cancelSubscriptionDowngrade={cancelSubscriptionDowngrade}
                toggleSubscription={toggleSubscription}
                isLowest={isLowest}
                courseId={courseId}
                isCurrentSubscription={currentPayment?.level === plan.type}
                isSubscriptionUpdating={isSubscriptionUpdating}
                currentPaymentPeriod={currentPayment?.period}
                currentPaymentLevel={currentPayment?.level}
                currentPaymentActiveTo={currentPayment?.activeTo as string}
                hasSubscriptionDowngrade={Boolean(nextPayment)}
                nextPayment={nextPayment?.level === plan.type ? nextPayment : undefined}
                isShowButton={!isGoldPurchase}
                hasSubscription={hasSubscription}
                mostEffectiveLabel={mostEffectiveLabel}
              />
            );
          })}
        </Container>
        {isTrialText && (
          <TrialText
            isDowngrade={nextPayment?.type === 'Free'}
            cancelSubscriptionDowngrade={() => cancelSubscriptionDowngrade(courseId, true)}
            currentPayment={currentPayment}
            startTrial={() => startTrial(courseId)}
            isTrialStarting={isTrialStarting}
            startUnsubscribeProcess={startUnsubscribeProcess}
          />
        )}
      </Wrapper>
    </Root>
  );
};

export default Products;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  grid-gap: 32px;

  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const BonusContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 450px;
  left: 35px;
  cursor: pointer;
`;

const ImageDivArrowRight = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 40px;
  right: 185px;
  z-index: 1;

  @media (max-width: 1180px) {
    top: 190px;
    right: 30px;
  }
`;

const ImageDivArrowLeft = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 40px;
  left: 185px;
  z-index: 1;

  @media (max-width: 1180px) {
    top: 140px;
    left: 90px;
  }

  @media (max-width: 570px) {
    top: 130px;
    left: 30px;
  }
`;

const BonusTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  border-bottom: 1px dashed ${({ theme: { colors } }) => colors.primary[1]};
`;

const CapabilitiesContainer = styled.div`
  ${({ theme: { typography } }) => typography.body_large_semibold_16}
  margin-bottom: 21px;
`;

const Container = styled.div<{ $isShowPurchase: boolean; isResponsive: boolean }>`
  display: ${({ isResponsive }) => (isResponsive ? 'unset' : 'flex')};
  height: max-content;
  gap: 20px;

  ${({ $isShowPurchase }) =>
    $isShowPurchase
      ? css`
          margin: 0 auto auto;
          display: grid;
          grid-template-columns: 352px;
        `
      : css``}

  @media (max-width: 1180px) {
    width: 500px;
  }

  @media (max-width: 570px) {
    width: 370px;
  }

  @media (max-width: 370px) {
    width: 320px;
  }
`;
