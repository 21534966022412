import { Button, ConfirmModal, StyledText } from 'UI';
import { CloseIcon } from 'assets/icons';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications } from 'hooks';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import Timer from './components/Timer';

type HeaderProps = {
  activeIndex: number;
  totalCount: number;
  isShowTimer?: boolean;
  endTest: () => void;
};

const TestHeader: FC<HeaderProps> = ({ activeIndex, totalCount, endTest, isShowTimer }) => {
  const [isShowTimeOverModal, setIsShowTimeOverModal] = useState(false);
  const [isTryAgainFetching, setIsTryAgainFetching] = useState(false);
  const [isResultsFetching, setIsResultsFetching] = useState(false);

  const { errorToast } = useNotifications();
  const { colors } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const timedTest = useAppSelector(selectors.tests.selectTimedTest);
  const [restartTestAction] = useAsyncAction(actions.tests.restartTest);
  const [finishTestAction] = useAsyncAction(actions.tests.finishTest);
  const currentTimedTest = useAppSelector(selectors.tests.selectTimedTest);

  const getTimedTest = async () => {
    await restartTestAction({ passageId: currentTimedTest?.id || '' });
    navigate(routes.timedTest);
  };

  const getClosedTest = async () => {
    await finishTestAction({ passageId: currentTimedTest?.id || '' });
  };

  const onTimerEndFunc = () => {
    if (!isTryAgainFetching || !isResultsFetching) {
      setIsShowTimeOverModal(true);
    }
  };

  const tryAgain = async () => {
    try {
      setIsTryAgainFetching(true);

      dispatch(actions.tests.clearSlice());

      await getClosedTest();
      await getTimedTest();

      setIsShowTimeOverModal(false);
      setIsTryAgainFetching(false);
    } catch {
      setIsShowTimeOverModal(false);
      setIsTryAgainFetching(false);

      errorToast('Something went wrong');

      navigate(routes.testList);
    }
  };

  const seeResult = async () => {
    setIsResultsFetching(true);
    await getClosedTest();
    navigate(routes.testResult);
    setIsResultsFetching(false);
  };

  const value = (activeIndex / totalCount) * 100;

  const willClosedTets = timedTest?.willCloseAt || '';
  const isShowSkeleton = !willClosedTets;

  return (
    <Container>
      <Wrapper>
        <CounterContainer>
          <CountItem>{activeIndex}</CountItem>
          <CountItem $isTotal>/{totalCount}</CountItem>
        </CounterContainer>
        <Cover>
          {isShowTimer &&
            (isResultsFetching || isShowSkeleton ? (
              <TimerSkeleton />
            ) : (
              <Timer expiredDate={willClosedTets} onTimerEndFunc={onTimerEndFunc} />
            ))}
          <StyledButton
            iconComponent={<CloseIcon size={24} color={colors.system.red} />}
            variant="secondary"
            size="small"
            onClick={endTest}>
            End
          </StyledButton>
        </Cover>
      </Wrapper>
      <IndicatorContainer>
        <Indicator $value={value} />
      </IndicatorContainer>
      <ConfirmModal
        title="The exams time has run out"
        text="You can take the exam again or see the current results"
        textColor={colors.neutrals[4]}
        isOpen={isShowTimeOverModal}
        onClose={() => setIsShowTimeOverModal(false)}
        leftButtonTitle="See results"
        onLeftButtonClick={seeResult}
        onRightButtonClick={tryAgain}
        rightButtonTitle="Try again"
        isRightButtonLoading={isTryAgainFetching}
        isLeftButtonLoading={isResultsFetching}
        shouldCloseOnOverlayClick={false}
      />
    </Container>
  );
};

export default TestHeader;

const Container = styled.header`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  padding-inline: 40px;

  ${respondToWidth.sm`
    padding-inline: 16px;
  `}
`;

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CountItem = styled(StyledText)<{ $isTotal?: boolean }>`
  ${({ theme: { typography, colors }, $isTotal }) => css`
    color: ${$isTotal ? colors.neutrals[1] : colors.primary[1]};
    ${typography.title_3_bold_28};

    ${respondToWidth.sm`
        ${typography.title_4_bold_24};
    `}
  `};
`;

const IndicatorContainer = styled.div`
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: ${({ theme: { colors } }) => colors.primary[4]};
  overflow: hidden;
`;

const Indicator = styled.div<{ $value?: number }>`
  width: ${({ $value }) => `${$value}%`};
  transition: width 0.5s linear;
  height: 100%;
  background-color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const Cover = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledButton = styled(Button)`
  padding-inline: 23px 41px;
  ${respondToWidth.sm`
    display: none;
  `};
`;

const TimerSkeleton = styled.div`
  width: 158px;
  min-width: 158px;
  height: 40px;
  border-radius: 10px;
  ${({ theme: { colors } }) => css`
    background-color: ${colors.system.lightGreen};
  `}
`;
