import { Breadcrumbs } from 'UI';
import { Button, StyledText } from 'UI';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications } from 'hooks';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import React, { FC, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import 'swiper/css';

import MobileSlider from './components/MobileSlider';
import Sliders from './components/Sliders';

export type CardType = { id: string; url: string; alt: string };

type MnemonicCardProps = {
  isLoading?: boolean;
};

const MnemonicCards: FC<MnemonicCardProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useGetIsMobile();
  const { errorToast } = useNotifications();

  const [getMnemonicCardsAction, isLoading] = useAsyncAction(actions.mnemonicCards.getMnemonicCardsPack);

  const { id } = useParams();
  // const currentMnemonicCardsPackId = useAppSelector(selectors.mnemonicCards.selectCurrentMnemonicCardsPackId);
  const clearMnemonicCardsStore = () => dispatch(actions.mnemonicCards.clearMnemonicCardsPracticeStore());

  const getMnemonicCards = async () => {
    // const isCurrentId = id === currentMnemonicCardsPackId;

    try {
      if (id) {
        clearMnemonicCardsStore();
        await getMnemonicCardsAction({ id });
      }
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  useLayoutEffect(() => {
    getMnemonicCards();
  }, []);

  const packName = useAppSelector(selectors.mnemonicCards.selectPackName) || '';
  const mnemonicCardsMeta = useAppSelector(selectors.mnemonicCards.selectMnemonicCardsPackMeta);

  return (
    <Container>
      <Header $isMobile={isMobile}>
        <Breadcrumbs title={packName} pageName="Mnemonic cards" routeName={routes.mnemonicCardList} isMnemonic />
        <HideCount>{mnemonicCardsMeta?.totalItems || 0}</HideCount>
      </Header>
      <MobileSlider />
      <Sliders isLoading={isLoading} />
      <ButtonContainer $isMobile={isMobile}>
        <StyledButton
          variant="primary"
          size="small"
          onClick={() => navigate(`${routes.mnemonicCardPractice}/${id}`)}
          $isMobile={isMobile}>
          Start practice
        </StyledButton>
      </ButtonContainer>
    </Container>
  );
};

export default MnemonicCards;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  position: relative;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
`;

const Header = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-inline: 40px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  position: relative;
  height: 92px;
  min-height: 92px;

  ${respondToWidth.sm`
    padding-inline: 0;
    height: 76px;
    min-height: 76px;
  `};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding-inline: 0;
      height: 76px;
      min-height: 76px;
    `}
`;

const ButtonContainer = styled.div<{ $isMobile: boolean }>`
  position: absolute;
  width: 159px;
  top: 28px;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  ${({ theme: { colors } }) => respondToWidth.sm`
    background-color: ${colors.neutrals[11]};
    width:100%;
    padding: 16px;
    position: initial;
  `}

  ${({ theme: { colors } }) =>
    ({ $isMobile }) =>
      $isMobile &&
      css`
        background-color: ${colors.neutrals[11]};
        width: 100%;
        padding: 16px;
        position: initial;
      `}
`;

const StyledButton = styled(Button)<{ $isMobile: boolean }>`
  ${({ theme: { typography } }) => respondToWidth.sm`
    padding-inline: 0;
    padding-block: 12px;
    ${typography.body_large_bold_16};
  `}

  ${({ theme: { typography } }) =>
    ({ $isMobile }) =>
      $isMobile &&
      css`
        padding-inline: 0;
        padding-block: 12px;
        ${typography.body_large_bold_16};
      `}
`;

const HideCount = styled(StyledText)`
  display: none;
  right: 16px;
  top: 22px;
  position: absolute;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${({ theme: { typography } }) => typography.title_4_bold_24}

  ${respondToWidth.sm`
    display:block;
  `}
`;
