import { RootState } from 'store';

export const selectors = {
  selectFlashcards: (state: RootState) => state.flashcards.flashcards,
  selectCurrentFlashcardIndex: (state: RootState) => state.flashcards.currentFlashcardIndex,
  selectCurrentFlashcardsPackId: (state: RootState) => state.flashcards.currentFlashcardsPackId,
  selectFlashcardsMeta: (state: RootState) => state.flashcards.meta,
  selectPackFlashcardsResult: (state: RootState) => state.flashcards.packFlashcardsResult,
  selectFlashcardsPackName: (state: RootState) => state.flashcards.flashcardsPackName,
  selectTimeToNextAvailableCard: (state: RootState) => state.flashcards.timeToNextAvailableCard,
  selectCurrentFlashcardsPack: (state: RootState) =>
    state.flashcardsPacks.flashcardsPacks?.find((pack) => pack.id === state.flashcards.currentFlashcardsPackId),
  selectCompletePackResult: (state: RootState) => state.flashcards.packCompleteResult,
  selectFlashcardsPack: (state: RootState) => state.flashcards.flashcardsPack,
  selectFlashcardsPackMeta: (state: RootState) => state.flashcards.flashcardsPackMeta,
};
