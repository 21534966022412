import { CoursePreview, ScrollbarContainer, StyledText } from 'UI';
import { CourseDto } from 'api/generated';
import { Loader } from 'components';
import { routes } from 'constant/routes';
import { useAsyncAction, useInfinityScroll, useNotifications } from 'hooks';
import LightLayout from 'layouts/LightLayout';
import React, { useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { widthBreakpoints } from 'styles/general/variables';

const AddNewCourse = () => {
  const { colors } = useTheme();
  const { errorToast } = useNotifications();
  const dispatch = useAppDispatch();

  const [getCoursesAction, isCoursesFetching] = useAsyncAction(actions.courses.getCourses);

  const selectCourse = (course: CourseDto) => dispatch(actions.courses.selectCourse(course));
  const openChooseProductModalAction = () => dispatch(actions.courses.openChooseProductModalOpen());

  const openChooseProductModal = async (course: CourseDto) => {
    selectCourse(course);
    openChooseProductModalAction();
  };

  const getCourses = async (page = 1) => {
    try {
      await getCoursesAction({ page, withoutActivated: true });
    } catch {
      errorToast('Something went wrong');
    }
  };

  const courses = useAppSelector(selectors.courses.selectCourses);
  const coursesMeta = useAppSelector(selectors.courses.selectDirectionCoursesMeta);

  const isCourses = Boolean(courses?.length);

  const nextPage = (coursesMeta?.currentPage || 0) + 1;
  const hasCoursesMore = (coursesMeta?.currentPage || 0) < (coursesMeta?.totalPages || 0);

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getCourses(nextPage),
    hasAfterMore: hasCoursesMore,
    isLoading: isCoursesFetching,
  });

  useEffect(() => {
    getCourses(1);
  }, []);

  return (
    <StyledLightLayout
      breadcrumbsProps={{
        routeName: routes.profile,
        pageName: 'Profile',
        title: 'Add new course',
      }}>
      {isCourses && (
        <List $isLoading={isCoursesFetching} ref={listRef} onScroll={handleScroll}>
          {courses?.map((course) => (
            <CoursePreview openChooseProductModal={openChooseProductModal} key={course.id} course={course} />
          ))}
        </List>
      )}
      {isCourses && isCoursesFetching && <ThreeDots color={colors.primary[3]} />}
      {!isCourses && isCoursesFetching && (
        <Wrapper>
          <Loader />
        </Wrapper>
      )}
      {!isCourses && !isCoursesFetching && (
        <Wrapper>
          <StyledText>There are no courses in this career</StyledText>
        </Wrapper>
      )}
    </StyledLightLayout>
  );
};

export default AddNewCourse;

const StyledLightLayout = styled(LightLayout)`
  ${({ theme: { colors } }) => css`
    padding: 0;
    padding-top: 32px;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ${respondToWidth.sm`
        padding-top: 0;
        background-color: ${colors.neutrals[11]}
    `}
  `}
`;

const List = styled(ScrollbarContainer)<{ $isLoading: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0 40px;

  ${({ $isLoading }) =>
    !$isLoading &&
    css`
      padding-bottom: 32px;
    `}

  justify-content: center;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @media (min-width: ${widthBreakpoints.sm}px) {
    grid-template-rows: repeat(auto-fill, 280px);
  }

  ${respondToWidth.sm`
     grid-gap: 16px;
     padding: 0 16px;
    `}
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
  display: flex;
`;
