import { OutputData } from '@editorjs/editorjs';
import { Button, ScrollbarContainer, StyledText } from 'UI';
import { ManualTypeEnum } from 'api/generated';
import { Loader } from 'components';
import { useGetManual } from 'hooks';
import { FC, useRef } from 'react';
import { Editor } from 'services';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

const HowToProgressCalcModal: FC<BaseModalProps> = ({ onClose, ...props }) => {
  const editorRef = useRef(null);

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const { content, isManualLoading } = useGetManual(ManualTypeEnum.HowProgressIsCalculated, activeCourse?.id);

  return (
    <DefaultModal
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      titleCSS={TitleCSS}
      // title={content?.title || ''} //todo
      modalCSS={ModalCSS}
      closeButtonCSS={CloseCSS}
      headerContainerCSS={HeaderContainerCSS}
      {...props}>
      <StyledScrollbar>
        {isManualLoading ? (
          <Loader />
        ) : content ? (
          <StyledEditor defaultData={content.content as OutputData} holder="manual" editorRef={editorRef} />
        ) : (
          <Text>Sorry... An error has occurred, please try again.</Text>
        )}
        <StyledButton variant="secondary" onClick={onClose}>
          Close
        </StyledButton>
      </StyledScrollbar>
    </DefaultModal>
  );
};

export default HowToProgressCalcModal;

const ContainerCSS = css`
  width: 727px;
  padding: 40px 10px 0px;
  overflow: hidden;

  ${respondToWidth.sm`
    width:100%;
    padding: 28px 10px 0px;
  `}

  ${respondToWidth.s`
    width:100%;
    padding: 28px 6px 0px;
  `}
`;

const StyledScrollbar = styled(ScrollbarContainer)`
  width: 100%;
  max-height: calc(100vh - 200px);
  padding-inline: 50px;
  padding-bottom: 60px;

  ${respondToWidth.sm`
    padding-inline: 22px;
    padding-bottom: 28px;
  `}

  ${respondToWidth.s`
    width:100%;
    padding-inline: 10px;
    padding-bottom: 16px;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  `}
`;

const TitleCSS = css`
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.title_3_bold_28};
`;

const CloseCSS = css`
  ${respondToWidth.sm`
  display:none;
`}
`;

const HeaderContainerCSS = css`
  ${respondToWidth.s`
  justify-content: center;
`}
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
  display: none;

  ${respondToWidth.sm`
  display:block;
`}
`;

const Text = styled(StyledText)`
  width: 100%;
  white-space: pre-wrap;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.subhead_regular_13};  
  `}
`;

const StyledEditor = styled(Editor)`
  div div {
    margin: 0 auto !important;
  }
`;
