export type SplitType = 'domain' | 'chapter';

export enum SplitTypeEnum {
  CHAPTER = 'chapter',
  DOMAIN = 'domain',
}
export type FlashcardsStatisticValuesType = 'NO' | 'KINDA' | 'YES' | 'UNANSWERED';

// TODO: replace enum from api (27.07.2023 - does not exist)
export type TestProgressType = 'correct' | 'incorrect' | 'unused';

export enum StatisticsVariants {
  TESTS = 'tests',
  FLASHCARDS = 'flashcards',
}

export enum AnswerEnum {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  UNUSED = 'unused',
  NO = 'no',
  KINDA = 'kinda',
  YES = 'yes',
  INACTIVE = 'inactive',
}

export type StatisticsArrayType = {
  name: AnswerEnum;
  count: number;
  percent: number;
};
