import { Breadcrumbs } from 'UI';
import { BreadcrumbsProps } from 'UI/Breadcrumbs/Breadcrumbs';
import { useClientSize } from 'hooks';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type HeaderProps = {
  breadcrumbsProps: BreadcrumbsProps;
};

const Header: FC<HeaderProps> = ({ breadcrumbsProps }) => {
  const { getIsBreakpoint } = useClientSize();
  const isWidthSm = getIsBreakpoint('sm');

  return (
    <Root>
      <StyledBreadcrumbs {...breadcrumbsProps} isAlwaysBigIcon pageName={isWidthSm ? '' : breadcrumbsProps.pageName} />
    </Root>
  );
};

export default Header;

const Root = styled.div`
  height: 92px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.sm`
    height: fit-content;
    padding: 18px 16px;
  `}
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  ${({ theme: { typography } }) => css`
    padding: 0 !important;
    border: none !important;

    ${respondToWidth.ls`
    span {
      ${typography.title_5_bold_20};
    }
   `}
  `}
`;
