import { COLORS } from 'constant';
import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgDiscoverIcon: FC<SVGIconProps> = ({ color = COLORS.grayscaleBlack, ...props }) => {
  return (
    <svg width={32} height={20} viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_9025_215858)">
        <path
          d="M16.944 7.013c1.505 0 2.724 1.146 2.724 2.563v.002c0 1.416-1.22 2.565-2.724 2.565-1.505 0-2.724-1.149-2.724-2.565v-.002c0-1.417 1.22-2.563 2.724-2.563Z"
          fill="#F47216"
        />
        <path
          d="M12.665 7.046c.43 0 .79.087 1.23.296v1.106c-.416-.382-.777-.542-1.253-.542a1.65 1.65 0 0 0-1.674 1.656c0 .976.714 1.663 1.72 1.663.453 0 .807-.152 1.206-.528v1.107c-.454.201-.822.28-1.252.28-1.521 0-2.703-1.098-2.703-2.515 0-1.402 1.213-2.523 2.726-2.523Zm-4.723.03c.562 0 1.076.181 1.505.535l-.522.644c-.26-.274-.507-.39-.806-.39-.43 0-.744.23-.744.534 0 .26.176.398.775.607 1.137.391 1.473.738 1.473 1.504 0 .934-.728 1.583-1.766 1.583-.76 0-1.313-.282-1.773-.918l.645-.585c.23.42.614.643 1.09.643.446 0 .776-.289.776-.68 0-.202-.1-.376-.3-.498-.1-.058-.299-.145-.69-.275-.938-.318-1.26-.658-1.26-1.322 0-.79.691-1.382 1.597-1.382Zm11.415.084h1.091l1.366 3.239 1.384-3.239h1.083l-2.213 4.946h-.537L19.357 7.16ZM4.71 7.168h.999v4.821h-1V7.168Zm20.023 0h2.833v.817H25.73v1.07h1.768v.816H25.73v1.302h1.835v.816h-2.833V7.168Zm5.622 2.79c.752-.144 1.166-.629 1.166-1.367 0-.903-.66-1.423-1.812-1.423h-1.481v4.821h.997v-1.937h.13l1.383 1.937h1.229l-1.612-2.031Zm-.838-.57h-.292v-1.46h.308c.622 0 .96.245.96.714 0 .485-.338.745-.976.745ZM1.5 7.167H.034v4.821h1.458c.776 0 1.336-.172 1.827-.556.585-.456.93-1.142.93-1.851 0-1.423-1.13-2.414-2.75-2.414Zm1.167 3.622c-.314.266-.722.383-1.367.383h-.268V7.985H1.3c.645 0 1.037.108 1.367.39.345.29.553.738.553 1.2 0 .463-.208.925-.553 1.215Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9025_215858">
          <path fill="#fff" transform="translate(0 7)" d="M0 0h32v5.155H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgDiscoverIcon);
export default Memo;
