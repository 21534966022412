import { Button } from 'UI';
import { TestMenuButton } from 'components/TestFooter/TestFooter';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import styled from 'styled-components';

type RecheckButtonProps = {
  iconMapping: Record<TestMenuButton, React.ReactNode>;
  isMarkedInitialValue: boolean;
  questionId: string;
};

const FooterRecheckButton: FC<RecheckButtonProps> = ({ iconMapping, isMarkedInitialValue, questionId }) => {
  const [isMarked, setIsMarked] = useState(false);
  const { errorToast } = useNotifications();

  const [markedQuestionAction, isCreateMarkedLoading] = useAsyncAction(actions.tests.markedQuestion);
  const [unmarkedQuestionAction, isRemoveLoading] = useAsyncAction(actions.tests.unmarkedQuestion);
  const activeIndex = useAppSelector(selectors.tests.selectCurrentQuestionIndex);

  useEffect(() => {
    setIsMarked(isMarkedInitialValue);
  }, [activeIndex, isMarkedInitialValue]);

  const isLoading = isCreateMarkedLoading || isRemoveLoading;

  const markedQuestion = async () => {
    try {
      await markedQuestionAction({ questionId });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const unmarkedQuestion = async () => {
    try {
      await unmarkedQuestionAction({ questionId });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  return (
    <>
      {isMarked ? (
        <StyledButton
          variant="tertiary"
          size="small"
          onClick={unmarkedQuestion}
          isLoading={isLoading}
          $isMarked={isMarked}>
          {TestMenuButton.REMOVE_RECHECKING}
        </StyledButton>
      ) : (
        <StyledButton
          iconComponent={iconMapping[TestMenuButton.RECHECKING]}
          variant="secondary"
          size="small"
          onClick={markedQuestion}
          isLoading={isLoading}
          $isMarked={isMarked}>
          {TestMenuButton.RECHECKING}
        </StyledButton>
      )}
    </>
  );
};

export default FooterRecheckButton;

const StyledButton = styled(Button)<{ $isMarked?: boolean }>`
  white-space: nowrap;
  width: ${({ $isMarked }) => ($isMarked ? '238px' : '226px')};
`;
