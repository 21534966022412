import { ScrollbarContainer, StyledText } from 'UI';
import { SearchTextGuideChapterResponseDto, SearchTextResultFoundTextResponseDto } from 'api/generated';
import { AxiosError } from 'axios';
import { queryConstants, routes } from 'constant/routes';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { FC } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { hideText } from 'utils';

type ChaptersProps = {
  onClose?: () => void;
  data?: Array<SearchTextGuideChapterResponseDto>;
  isLoading: boolean;
};

const SearchContent: FC<ChaptersProps> = ({ onClose, data, isLoading }) => {
  const navigate = useNavigate();
  const { errorToast } = useNotifications();

  const [getChapterByIdAction] = useAsyncAction(actions.studyGuide.getChapterById);
  const currentChapter = useAppSelector(selectors.studyGuide.selectCurrentChapter);
  const tableOfGuideContent = useAppSelector(selectors.studyGuide.selectTableOfGuideContent);
  const studyGuide = useAppSelector(selectors.studyGuide.selectStudyGuide);

  const { colors } = useTheme();

  const handleUnitClick = async (chapterId: string, unitId: string) => {
    onClose?.();

    if (currentChapter?.id !== chapterId) {
      const index = tableOfGuideContent?.chapters.findIndex((chapter) => chapter.id === chapterId);
      navigate(`${routes.studyGuide}?${queryConstants.chapterIndex}=${index}`);

      await getChapterById(chapterId);

      setTimeout(() => scrollToUnit(unitId), 2000);
    } else {
      setTimeout(() => scrollToUnit(unitId), 0);
    }
  };

  const scrollToUnit = async (unitId: string) => {
    const unit = document.getElementById(unitId);
    unit?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const getChapterById = async (chapterId: string) => {
    try {
      if (studyGuide) {
        await getChapterByIdAction({ chapterId, guideId: studyGuide?.id });
      }
    } catch (error) {
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    }
  };

  if (data?.length === 0) {
    return <NotFoundState>Nothing found. Try to use other key words</NotFoundState>;
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <ThreeDots color={colors.primary[3]} />
      </LoaderWrapper>
    );
  }

  return (
    <Root>
      {data?.map(
        (chapter) =>
          chapter && (
            <Content key={chapter.id}>
              <Chapter
                $isActive={chapter.id === currentChapter?.id}
                onClick={() => handleUnitClick(chapter.id, chapter.id)}>
                <TitleWrapper>
                  <ChapterTitle font="body_basic_bold_14">{chapter.name}</ChapterTitle>
                  <Counter>{chapter.units.length}</Counter>
                </TitleWrapper>
              </Chapter>

              <Content>
                {chapter.units?.map((unit) => (
                  <React.Fragment key={unit.id}>
                    <ResultsWrapper>
                      <TitleWrapper onClick={() => handleUnitClick(chapter.id, unit.id)}>
                        <UnitTitle font="body_basic_medium_14">{unit.name}</UnitTitle>
                        <Counter>{unit.content.blocks.length}</Counter>
                      </TitleWrapper>
                      {unit.content.blocks.map((block) => {
                        const data = block.data as SearchTextResultFoundTextResponseDto;
                        return (
                          <ResultButton onClick={() => handleUnitClick(chapter.id, unit.id)} key={unit.id}>
                            {data.foundText}
                          </ResultButton>
                        );
                      })}
                    </ResultsWrapper>
                  </React.Fragment>
                ))}
              </Content>
            </Content>
          ),
      )}
    </Root>
  );
};

export default SearchContent;

const Root = styled(ScrollbarContainer)`
  display: grid;
  padding: 16px 0;
  grid-gap: 24px;
  align-content: flex-start;
`;

const Content = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const Chapter = styled.button<{ $isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  ${({ $isActive, theme: { colors } }) =>
    $isActive &&
    css`
      * {
        color: ${colors.primary[1]} !important;
      }

      ::before {
        content: '';
        position: absolute;
        left: 10px;

        border: 4px solid transparent;
        border-left: 4px solid ${colors.primary[1]};
      }

      ${respondToWidth.sm`
         ::before {
          display: none;
         }
      `}
    `}
`;

const TitleWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px 0 24px;
`;

const ChapterTitle = styled(StyledText)`
  ${({ theme: { colors } }) => css`
    color: ${colors.neutrals[1]};
  `};

  ${hideText(1)};
`;

const UnitTitle = styled(StyledText)`
  text-align: left;
  position: relative;
  padding-left: 14px;
  ${hideText(1)};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #d9d9d9;
  }
`;

const Counter = styled.p`
  ${({ theme: { colors, typography } }) => css`
    margin-left: auto;
    color: ${colors.neutrals[4]};

    ${typography.body_basic_medium_14};
  `}
`;

const ResultsWrapper = styled.div`
  gap: 0;
`;

const ResultButton = styled.button`
  ${({ theme: { colors, typography } }) => css`
    position: relative;
    width: 100%;
    padding: 9px 16px 9px 24px;
    text-align: left;
    color: ${colors.neutrals[3]};

    ${typography.subhead_regular_13};
    ${hideText(1)};

    :first-of-type {
      padding-top: 0;
      margin-top: -8px;
    }

    :after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -24px;
      width: calc(100% + 48px);
      height: 1px;
      background-color: ${colors.neutrals[9]};
      z-index: 1;
    }
  `}
`;

const NotFoundState = styled.div`
  ${({ theme: { colors, typography } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.neutrals[4]};
    ${typography.footnote_regular_12};
  `}
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
