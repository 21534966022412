import { TimedTestStartModal as TimedTestStartModalUI } from 'UI';
import React, { FC } from 'react';
import { selectors, useAppSelector } from 'store';
import { BaseModalProps } from 'types';

type TimedTestStartModalProps = { startTimedTest: () => void; isLoading: boolean } & BaseModalProps;

const TimedTestStartModal: FC<TimedTestStartModalProps> = ({ onClose, startTimedTest, isLoading, ...props }) => {
  const nextTimedTestPreview = useAppSelector(selectors.tests.selectNextTimedTestPreview);

  if (!nextTimedTestPreview) {
    return null;
  }

  return (
    <TimedTestStartModalUI
      durationSec={nextTimedTestPreview.durationSec}
      questionsCount={nextTimedTestPreview.questionsCount}
      startTest={startTimedTest}
      onClose={onClose}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default TimedTestStartModal;
