import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

const TestModeModal: FC<BaseModalProps> = ({ onClose, ...props }) => {
  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      showCloseButton={false}
      modalCSS={ModalCSS}
      headerContainerCSS={HeaderContainerCSS}>
      <Wrapper>
        <Title>Test mode</Title>
        <Text>
          Tutor — you choose the subject and the question mode, the time is unlimited. We recommend it for practice
        </Text>
        <Text>Timed — questions are selected automatically. Use it to simulate the real test mode</Text>
      </Wrapper>
      <StyledButton variant="secondary" size="middle" onClick={onClose}>
        Close
      </StyledButton>
    </DefaultModal>
  );
};

export default TestModeModal;

const ContainerCSS = css`
  width: 628px;
  padding: 28px 16px;
  display: flex;
  flex-direction: column;
  ${respondToWidth.sm`
    border-radius:28px 28px 0 0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const HeaderContainerCSS = css`
  display: none;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const Title = styled(StyledText)`
  text-align: center;
  margin-top: 0;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.title_5_bold_20};
`;

const Text = styled(StyledText)`
  margin-top: 24px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

const StyledButton = styled(Button)`
  width: 144px;

  ${respondToWidth.s`
    width:100%;
  `}
`;
