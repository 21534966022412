import { ScrollbarContainer, StyledText } from 'UI';
import {
  useAsyncAction,
  useClientSize,
  useGetIsMobile,
  useInfinityScroll,
  useIsShowEmptyColumn,
  useNotifications,
  useOnClickOutside,
} from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { actions, selectors, useAppSelector } from 'store';
import { SplitType, SplitTypeEnum } from 'store/ducks/performance/types';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import EmptyColumn from '../EmptyColumn';
import { Legend, StatisticColumns } from './components';

const limit = 20;

const TestContent = () => {
  const { getIsBreakpoint } = useClientSize();
  const { errorToast } = useNotifications();

  const [isShowNameTooltip, setIsShowNameTooltip] = useState(false);
  const [columnName, setIsColumnName] = useState('');
  const columnRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useGetIsMobile();

  const isWidthSm = getIsBreakpoint('sm');
  const testsStatistics = useAppSelector(selectors.performance.selectTestsStatistics);
  const testStatistic = useAppSelector(selectors.performance.selectTestStatistic);
  const testStatisticMeta = useAppSelector(selectors.performance.selectTestStatisticMeta);
  const allTestsStatistic = useAppSelector(selectors.performance.selectAllTestsStatistic);
  const allTestsStatisticMeta = useAppSelector(selectors.performance.selectAllTestsStatisticMeta);
  const isShowAllTestsStatistic = useAppSelector(selectors.performance.selectIsShowAllTestsStatistic);

  const id = useAppSelector(selectors.performance.selectCurrentTestId);
  const split = useAppSelector(selectors.performance.selectCurrentTestSplitType);
  const legendStatistic = useAppSelector(selectors.performance.selectTestLegendStatistic);

  const [getTestStatisticAction, isTestStatisticLoading] = useAsyncAction(actions.performance.getStatisticTest);
  const [getLegendStatisticAction] = useAsyncAction(actions.performance.getTestsLegendStatistic);
  const [getTestsColumnsStatisticAction, isAllTestsStatisticLoading] = useAsyncAction(
    actions.performance.getAllTestsStatistic,
  );

  const nextPage =
    (isShowAllTestsStatistic ? allTestsStatisticMeta?.currentPage || 0 : testStatisticMeta?.currentPage || 0) + 1;
  const hasTestStatisticMore = (testStatisticMeta?.currentPage || 0) < (testStatisticMeta?.totalPages || 0);
  const hasAllTestsStatisticMore = (allTestsStatisticMeta?.currentPage || 0) < (allTestsStatisticMeta?.totalPages || 0);

  const isLegendTotalEmpty = Boolean(!legendStatistic?.total);

  const getTestStatistic = async (id: string, split: SplitType, page: number, limit?: number) => {
    try {
      await getTestStatisticAction({ id, split, page, limit });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const getLegendStatistic = async (id: string | null) => {
    if (id) {
      try {
        await getLegendStatisticAction({ id });
      } catch (error) {
        errorToast('Something went wrong');
      }
    }
  };

  const getTestsColumnsStatistic = async (split: SplitType, page: number, limit?: number) => {
    try {
      await getTestsColumnsStatisticAction({ split, page, limit });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    !isShowAllTestsStatistic && getLegendStatistic(id || '');
  }, [id]);

  useEffect(() => {
    getTestsColumnsStatistic(split, 1);
  }, [split]);

  const getAfterData = async () => {
    if (isShowAllTestsStatistic) {
      await getTestsColumnsStatistic(split, nextPage, limit);
    } else {
      await getTestStatistic(id || '', split, nextPage, limit);
    }
  };

  const hasAfterMore = isShowAllTestsStatistic ? hasAllTestsStatisticMore : hasTestStatisticMore;

  const isLoading = isShowAllTestsStatistic ? isAllTestsStatisticLoading : isTestStatisticLoading;

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData,
    hasAfterMore,
    isLoading,
    isScrollToInitialPosition: false,
  });

  const columnWidth = isWidthSm ? 85 : 112;
  const gapSize = isWidthSm ? 16 : 28;

  const { containerRef, isShowEmptyColumn } = useIsShowEmptyColumn(
    isShowAllTestsStatistic ? allTestsStatistic || [] : testStatistic || [],
    columnWidth,
    gapSize,
  );

  const { colors } = useTheme();

  const emptyColumnText = `There are no more ${split === SplitTypeEnum.CHAPTER ? 'chapters' : 'domains'} in this test`;
  const emptyLegendText = 'You have not answered any questions on this test';

  useOnClickOutside(columnRef, () => setIsShowNameTooltip(false));

  const isShowFullName = isShowNameTooltip && isMobile;

  const onColumnPress = (name: string) => {
    setIsColumnName(name);
    setIsShowNameTooltip(true);
  };

  return (
    <Container ref={containerRef}>
      <Wrapper ref={listRef} onScroll={handleScroll}>
        <Cover $gap={gapSize}>
          {isShowAllTestsStatistic ? (
            <>
              {allTestsStatistic?.map((column) => (
                <StatisticColumns
                  ref={columnRef}
                  onPress={onColumnPress}
                  key={column.id}
                  data={column}
                  $width={columnWidth}
                />
              ))}
              {isShowEmptyColumn && <EmptyColumn text={isLegendTotalEmpty ? emptyLegendText : emptyColumnText} />}
              {isAllTestsStatisticLoading && (
                <LoaderContainer $width={columnWidth}>
                  <ThreeDots color={colors.primary[3]} />
                </LoaderContainer>
              )}
            </>
          ) : (
            <>
              {testStatistic?.map((column) => (
                <StatisticColumns
                  ref={columnRef}
                  onPress={onColumnPress}
                  key={column.id}
                  data={column}
                  $width={columnWidth}
                />
              ))}
              {isShowEmptyColumn && <EmptyColumn text={isLegendTotalEmpty ? emptyLegendText : emptyColumnText} />}
              {isTestStatisticLoading && (
                <LoaderContainer $width={columnWidth}>
                  <ThreeDots color={colors.primary[3]} />
                </LoaderContainer>
              )}
            </>
          )}
        </Cover>
      </Wrapper>
      <LegendWrapper>
        <Legend data={isShowAllTestsStatistic ? testsStatistics : legendStatistic} />
        {isShowFullName && <FullTitle font="footnote_semibold_12">{columnName}</FullTitle>}
      </LegendWrapper>
    </Container>
  );
};

export default TestContent;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 20px;
  gap: 24px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  padding: 8px 24px 32px;
  border-radius: 12px;
  overflow-x: hidden;

  ${respondToWidth.sm`
    width:100%;
    max-height: 391px;
    grid-template-rows: 1fr 60px;
    padding:8px 16px 24px;
  `}
`;

const Cover = styled.div<{ $gap: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  gap: ${({ $gap }) => `${$gap}px`};
  transform: rotateX(180deg);
  padding-top: 12px;
`;

const Wrapper = styled(ScrollbarContainer)`
  width: 100%;
  height: 100%;
  overflow-y: initial;
  overflow-x: scroll;
  transform: rotateX(180deg);

  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    height: 4px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: ${({ theme: { colors } }) => colors.primary[1]};
  }

  ::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: ${({ theme: { colors } }) => colors.primary[1]};
  }

  ::-webkit-scrollbar-thumb:horizontal:active {
    background-color: ${({ theme: { colors } }) => colors.primary[1]};
  }
`;

const LoaderContainer = styled.div<{ $width: number }>`
  width: ${({ $width }) => `${$width}px`};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullTitle = styled(StyledText)`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.neutrals[1]};
  color: ${({ theme: { colors } }) => colors.neutrals[11]};
  padding: 12px 16px;
  border-radius: 12px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;

const LegendWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
