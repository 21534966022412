export const routes = {
  home: '/home',
  signIn: '/sign-in',
  studyGuide: '/study-guide',
  testList: '/test-list',
  timedTest: '/test-timed',
  tutorTest: '/test-tutor',
  testResult: '/test-result',
  flashcardPacks: '/flashcard-packs',
  flashcards: '/flashcards',
  mnemonicCardList: '/mnemoniccard-list',
  mnemonicCards: '/mnemonic-cards',
  mnemonicCardPractice: '/mnemonic-card-practice',
  performance: '/performance',
  profile: '/profile',
  addNewCourse: '/add-new-course',
  notFound: '*',
  error: '/error',
  createUser: '/create-user',
  subscribeToCourse: '/subscribe-to-course',
  confirmEmail: '/confirm-email',
  invite: '/invite',
  restorePassword: '/restore-password',
  technicalWorks: '/technical-works',
  billing: '/billing',
};

export const queryConstants = {
  chapterIndex: 'chapterIndex',
};
