type DefaultColors = {
  neutrals: {
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    8: string;
    9: string;
    10: string;
    11: string;
    12: string;
    13: string;
  };
  system: {
    green: string;
    lightGreen: string;
    red: string;
    lightRed: string;
    blue: string;
    lightBlue: string;
    violet: string;
    lightViolet: string;
    yellow: string;
    lightYellow: string;
    orange: string;
  };
  shadow: {
    1: string;
    2: string;
    3: string;
  };
};

type ModeColors = {
  default: DefaultColors;
  dark: DefaultColors;
};

export const appDefaultColors: ModeColors = {
  default: {
    neutrals: {
      1: '#333333',
      2: '#666666',
      3: '#848484',
      4: '#9B9B9B',
      5: '#B0B0B0',
      6: '#C7C7C7',
      7: '#D9D9D9',
      8: '#EAEAEA',
      9: '#F0F0F0',
      10: '#F6F6F6',
      11: '#FFFFFF',
      12: '#ECECEC',
      13: '#FFF7F4',
    },
    system: {
      green: '#55B47D',
      lightGreen: '#EEFCF3',
      red: '#EB4040',
      lightRed: '#FFF5F7',
      blue: '#56A3EA',
      lightBlue: '#F1FAFE',
      violet: '#9763EC',
      lightViolet: '#F9F5FF',
      yellow: '#F1B928',
      lightYellow: '#FDFBF5',
      orange: '#F1804F',
    },
    shadow: {
      1: '0px 4px 40px rgba(0, 0, 0, 0.1)',
      2: '0px 4px 10px rgba(0, 0, 0, 0.08)',
      3: '0px 10px 40px rgba(0, 0, 0, 0.1)',
    },
  },
  dark: {
    neutrals: {
      1: '#FFFFFF',
      2: '#D9D9D9',
      3: '#ADADAD',
      4: '#9B9B9B',
      5: '#848484',
      6: '#434343',
      7: '#333333',
      8: '#2F2F2F',
      9: '#282828',
      10: '#0D0D0D',
      11: '#161616',
      12: '#000000',
      13: '#212020',
    },
    system: {
      green: '#55B47D',
      lightGreen: '#55B47D33',
      red: '#EB4040',
      lightRed: '#EB404033',
      blue: '#56A3EA',
      lightBlue: '#56A3EA33',
      violet: '#9763EC',
      lightViolet: '#9763EC33',
      yellow: '#F1B928',
      lightYellow: '#F1B92833',
      orange: '#F1804F',
    },
    shadow: {
      1: '0px 4px 40px rgba(0, 0, 0, 0.1)',
      2: '0px 4px 10px rgba(0, 0, 0, 0.08)',
      3: '0px 10px 40px rgba(0, 0, 0, 0.1)',
    },
  },
};
