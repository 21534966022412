import styled, { CSSProp } from 'styled-components';

export type IconPlacementType = 'left' | 'right';
export type TextButtonVariant = 'textButton' | 'textWithIcon';

export const StyledButton = styled.button<{ $variant: TextButtonVariant; $customTextCSS?: CSSProp }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: min-content;
  padding: 0;
  background-color: inherit;
  color: ${({ theme: { colors }, $variant }) => ($variant === 'textButton' ? colors.primary[1] : colors.neutrals[1])};
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  ${({ theme: { typography }, $variant }) =>
    $variant === 'textButton' ? typography.body_large_semibold_16 : typography.body_basic_semibold_14};
  ${({ $customTextCSS }) => $customTextCSS};
`;

export const IconContainer = styled.div<{
  $iconPlacement: IconPlacementType;
  $variant: TextButtonVariant;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ $iconPlacement, $variant }) =>
    $iconPlacement === 'right' && $variant === 'textButton' ? '16px' : '6px'};
  margin-right: ${({ $iconPlacement, $variant }) =>
    $iconPlacement === 'left' && $variant === 'textButton' ? '16px' : '6px'};

  svg path {
    fill: ${({ theme: { colors }, $variant }) => $variant === 'textButton' && colors.primary[1]};
  }
`;
