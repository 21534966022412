import { EventPageViewGA, PrivateRoute, SignInRoute, ToastsContainer } from 'components';
import { routes } from 'constant/routes';
import {
  AddNewCourse,
  Billing,
  ConfirmEmail,
  CreateUser,
  Error,
  Flashcards,
  FlashcardsPacks,
  Home,
  Invite,
  MnemonicCardList,
  MnemonicCardPractice,
  MnemonicCards,
  NotFoundPage,
  Performance,
  Profile,
  RestorePassword,
  SignIn,
  StudyGuide,
  SubscribeToCourse,
  TestList,
  TestResult,
  TimedTest,
  TutorTest,
} from 'pages';
import { FC, useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const RootRoutes: FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <EventPageViewGA />
      <ToastsContainer />
      <Routes>
        <Route path="/" element={<Navigate to={routes.home} replace />} />
        <Route path={routes.signIn} element={<SignInRoute component={SignIn} />} />
        <Route path={routes.home} element={<PrivateRoute component={Home} />} />
        <Route path={routes.studyGuide} element={<PrivateRoute component={StudyGuide} />} />
        <Route path={routes.testList} element={<PrivateRoute component={TestList} />} />
        <Route path={routes.timedTest} element={<PrivateRoute component={TimedTest} />} />
        <Route path={routes.tutorTest} element={<PrivateRoute component={TutorTest} />} />
        <Route path={routes.testResult} element={<PrivateRoute component={TestResult} />} />
        <Route path={routes.flashcardPacks} element={<PrivateRoute component={FlashcardsPacks} />} />
        <Route path={routes.flashcards} element={<PrivateRoute component={Flashcards} />} />
        <Route path={routes.mnemonicCardList} element={<PrivateRoute component={MnemonicCardList} />} />
        <Route path={routes.mnemonicCards}>
          <Route path=":id" element={<PrivateRoute component={MnemonicCards} />} />
        </Route>
        <Route path={routes.mnemonicCardPractice}>
          <Route path=":id" element={<PrivateRoute component={MnemonicCardPractice} />} />
        </Route>
        <Route path={routes.performance} element={<PrivateRoute component={Performance} />} />
        <Route path={routes.profile} element={<PrivateRoute component={Profile} />} />
        <Route path={routes.addNewCourse} element={<PrivateRoute component={AddNewCourse} />} />
        <Route path={routes.createUser} element={<CreateUser />} />
        <Route path={routes.subscribeToCourse} element={<SubscribeToCourse />} />
        <Route path={routes.error} element={<Error />} />
        <Route path={routes.notFound} element={<NotFoundPage />} />
        <Route path={routes.confirmEmail} element={<ConfirmEmail />} />
        <Route path={routes.invite} element={<Invite />} />
        <Route path={routes.restorePassword} element={<RestorePassword />} />
        <Route path={routes.billing} element={<Billing />} />
      </Routes>
    </Router>
  );
};

export default RootRoutes;
