import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  CreateNoteRequestDto,
  CustomerChapterNoteResponseDto,
  CustomerChapterResponseDto,
  CustomerGuideResponseDto,
  CustomerTableOfGuideContentResponseDto,
  GetChaptersWithNotesResponseDto,
  SearchGuideTextResponseDto,
  UpdateNoteRequestDto,
} from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType } from 'types';

export const getGuideByCourseIdThunk = createAsyncThunk<
  CustomerGuideResponseDto,
  string,
  ExtraParamsThunkType<DefaultRejectValue>
>('studyGuide/getGuideByCourseId', async (courseId, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Guides.guidesControllerGetGuideByCourseId(courseId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getChapterByIdThunk = createAsyncThunk<
  CustomerChapterResponseDto,
  { guideId: string; chapterId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('studyGuide/getChapterById', async ({ guideId, chapterId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Guides.guidesControllerGetChapterOfGuide(guideId, chapterId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getSearchGuideTextThunk = createAsyncThunk<
  SearchGuideTextResponseDto,
  { guideId: string; text: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('/guides/{guideId}/search', async ({ guideId, text }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Guides.guidesControllerSearchText(guideId, { text });

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getTableOfContentForGuideThunk = createAsyncThunk<
  CustomerTableOfGuideContentResponseDto,
  string,
  ExtraParamsThunkType<DefaultRejectValue>
>('studyGuide/getTableOfContentForGuide', async (guideId, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Guides.guidesControllerGetTableOfContentForGuide(guideId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getNotesThunk = createAsyncThunk<
  Array<GetChaptersWithNotesResponseDto>,
  string,
  ExtraParamsThunkType<DefaultRejectValue>
>('studyGuide/getNotes', async (guideId, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Guides.guidesControllerGetNotes(guideId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const createNoteThunk = createAsyncThunk<
  CustomerChapterNoteResponseDto,
  CreateNoteRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('studyGuide/createNote', async (params, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Guides.guidesControllerCreateNote(params);

    if (status > 399) {
      throw data;
    }

    return data as CustomerChapterNoteResponseDto;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const updateNoteThunk = createAsyncThunk<
  CustomerChapterNoteResponseDto,
  { noteId: string; body: UpdateNoteRequestDto },
  ExtraParamsThunkType<DefaultRejectValue>
>('studyGuide/updateNote', async ({ noteId, body }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Guides.guidesControllerUpdateNote(noteId, body);

    if (status > 399) {
      throw data;
    }

    return data as CustomerChapterNoteResponseDto;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const deleteNoteThunk = createAsyncThunk<void, { noteId: string }, ExtraParamsThunkType<DefaultRejectValue>>(
  'studyGuide/deleteNote',
  async ({ noteId }, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Guides.guidesControllerRemoveNote(noteId);

      if (status > 399) {
        throw data;
      }

      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);
