import { ExpandImage, StyledText } from 'UI';
import { Loader } from 'components';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { ImageType } from 'types';

type ExplanationProps = {
  image?: ImageType;
  description: string;
};

const Explanation: FC<ExplanationProps> = ({ image, description }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <Container>
      <Title as="h3">Explanation</Title>
      {image?.url ? (
        <ImagesContainer>
          <ImageContainer image={image} setIsImageLoaded={setIsImageLoaded} />
          {!isImageLoaded && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
        </ImagesContainer>
      ) : null}
      <Description>{description}</Description>
    </Container>
  );
};

export default Explanation;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 12px;
`;

const Title = styled(StyledText)`
  text-align: start;
  ${({ theme: { typography } }) => typography.body_basic_bold_14}
`;

const Description = styled(StyledText)`
  text-align: start;
  white-space: pre-wrap;
`;

const ImagesContainer = styled.div`
  position: relative;
  width: 100%;
  height: 112px;

  ${respondToWidth.sm`
    height:58px;
    width: 101px;
  `}
`;

const ImageContainer = styled(ExpandImage)`
  width: 200px;
  height: 112px;
  border-radius: 16px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;

    ${respondToWidth.sm`
    border-radius: 8px;
  `}
  }

  ${respondToWidth.sm`
    height:58px;
    width: 101px;
    border-radius: 8px;
  `}
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 112px;

  ${respondToWidth.sm`
    height:58px;
  `}
`;
