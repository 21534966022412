import { PaymentElement } from '@stripe/react-stripe-js';
import { DefaultModal, StyledText } from 'UI';
import Button from 'UI/Button';
import { useNotifications, useStripeSetupPaymentMethodForm } from 'hooks';
import { StripeProvider } from 'providers';
import { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

type AddPaymentMethodModalProps = BaseModalProps;

const AddPaymentMethodModal: FC<AddPaymentMethodModalProps> = ({ onClose, ...props }) => {
  const { successToast, errorToast } = useNotifications();

  const { setElements, setStripe, clientSecret, handleSubmit, errorMessage, isLoading, isSuccess, isDisabled } =
    useStripeSetupPaymentMethodForm();

  const onSuccess = async () => {
    onClose();
    if (errorMessage) {
      errorToast(errorMessage);
    } else {
      successToast('Card added successfully');
    }
  };

  const onCloseModal = () => {
    if (!isLoading) {
      onClose();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  return (
    <DefaultModal
      {...props}
      onRequestClose={onCloseModal}
      title="Add a payment method"
      containerCSS={ContainerCSS}
      changeCloseButton
      modalCSS={ModalCSS}
      isMobileOverlayStyles>
      <Form onSubmit={handleSubmit}>
        <FormWrapper>
          <StripeProvider setElements={setElements} setStripe={setStripe} clientSecret={clientSecret}>
            <PaymentElement
              options={{
                terms: { card: 'never' },
              }}
            />
          </StripeProvider>
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        </FormWrapper>
        <StyledButton isLoading={isLoading} variant="primary" size="middle" type="submit" disabled={isDisabled}>
          Confirm
        </StyledButton>
      </Form>
    </DefaultModal>
  );
};

export default AddPaymentMethodModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  gap: 40px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ErrorText = styled(StyledText)`
  text-align: start;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.system.red};
    ${typography.body_basic_medium_14}

    ${respondToWidth.s`
      ${typography.footnote_semibold_12}
  `}
  `};
`;

const StyledButton = styled(Button)`
  ${respondToWidth.s`
    margin-top: auto;
  `}
`;
