import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const LockIcon: FC<SVGIconProps> = ({ color: propsColor, size = 24, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path
          d="M19 10h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 0 1 14 0v1ZM5 12v8h14v-8H5Zm6 2h2v4h-2v-4Zm6-4V9A5 5 0 0 0 7 9v1h10Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(LockIcon);
export default Memo;
