import { PauseIcon, PlayCircleIcon } from 'assets/icons';
import React, { FC } from 'react';
import ReactH5AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import styled, { css, useTheme } from 'styled-components';

type AudioPlayerProps = {
  src: string;
  onEnded?: () => void;
};

const AudioPlayer: FC<AudioPlayerProps> = (props) => {
  const { colors } = useTheme();

  return (
    <Root
      {...props}
      autoPlay={false}
      autoPlayAfterSrcChange={false}
      customIcons={{
        play: <PlayCircleIcon color={colors.primary[1]} />,
        pause: <PauseIcon color={colors.primary[1]} />,
      }}
      preload="metadata"
      customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
      layout="horizontal-reverse"
    />
  );
};

export default AudioPlayer;

const Root = styled(ReactH5AudioPlayer)`
  ${({ theme: { colors, typography } }) => css`
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    * {
      font-variant: tabular-nums;
    }

    .rhap_main {
      align-items: center;
      gap: 16px;
    }
    .rhap_controls-section {
      flex: none;
    }
    .rhap_main-controls {
      flex: none;
    }
    .rhap_controls-section {
      margin: 0;
    }
    .rhap_main-controls-button {
      margin: 0;
    }
    .rhap_play-pause-button {
      display: flex;
      width: fit-content;
      height: fit-content;
    }
    .rhap_forward-button,
    .rhap_rewind-button {
      display: none;
    }
    .rhap_progress-bar {
      display: flex;
      align-items: center;
      height: 2px;
      border-radius: 2px;
    }
    .rhap_progress-container {
      margin: 0;
    }
    .rhap_progress-section {
      gap: 8px;
    }
    .rhap_progress-indicator {
      width: 12px;
      height: 12px;

      top: auto;
      box-shadow: none;
      border-radius: 50%;
      margin-left: -6px;
      background-color: ${colors.neutrals[11]};
      border: 2px solid ${colors.primary[1]};
    }
    .rhap_progress-bar-show-download {
      background-color: ${colors.neutrals[4]};
    }
    .rhap_download-progress {
      background-color: ${colors.primary[4]};
    }
    .rhap_progress-filled {
      background-color: ${colors.primary[1]};
    }
    .rhap_time {
      color: ${colors.neutrals[4]};
      ${typography.footnote_semibold_12}
    }
  `}
`;
