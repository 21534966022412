import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AnswerQuestionResponseDto,
  FinishedPassageResponseDto,
  GetPassageResultsResponseItemDto,
  GetQuestionChaptersResponseItem,
  GetQuestionDomainsResponseItem,
  LeastKnownCategoryResponseDto,
  PaginationMetaDto,
  StartTutorTestPassageRequestDto,
  TimedTestPassageResponseDto,
  TimedTestResponseDto,
  TutorTestPassageResponseDto,
} from 'api/generated';
import { TestListTabs } from 'types';

import { addToFlashcardsThunk, removeFromFlashcardsThunk } from '../flashcardsPacks/actions';
import {
  finishTestThunk,
  getChaptersQuestionsThunk,
  getDomainsQuestionsThunk,
  getLeastKnowChaptersThunk,
  getLeastKnowDomainsThunk,
  getNextTimedTestThunk,
  getQuizTestThunk,
  getTestStatisticThunk,
  getUncompletedTestThunk,
  markedQuestionThunk,
  restartTestThunk,
  saveAnswerThunk,
  startTimedTestThunk,
  startTutorTestThunk,
  unmarkedQuestionThunk,
} from './actions';

interface TestsSlice {
  tutorTest: (Omit<TutorTestPassageResponseDto, 'courseId'> & { quizId?: string; courseId?: string }) | null;
  timedTest: TimedTestPassageResponseDto | null;
  nextTimedTestPreview: TimedTestResponseDto | null;
  testClosedResult: FinishedPassageResponseDto | null;
  currentQuestionIndex: number | null;
  currentTestStatisticMeta: PaginationMetaDto | null;
  currentTestStatisticQuestions: Array<GetPassageResultsResponseItemDto> | null;
  testResultsStatistics: { rightAnswered: number; totalQuestions: number } | null;
  tutorTestAnswers: Array<AnswerQuestionResponseDto> | [];
  timedTestAnswers: Array<AnswerQuestionResponseDto> | [];
  isTestResultsFetching: boolean;
  activeTab: TestListTabs;
  domainsQuestions: Array<GetQuestionDomainsResponseItem>;
  chaptersQuestions: Array<GetQuestionChaptersResponseItem>;
  startTutorTestParams: StartTutorTestPassageRequestDto | null;
  leastKnowDomains: LeastKnownCategoryResponseDto[];
  leastKnowChapters: LeastKnownCategoryResponseDto[];
}

const initialState: TestsSlice = {
  tutorTest: null,
  timedTest: null,
  nextTimedTestPreview: null,
  isTestResultsFetching: false,
  testClosedResult: null,
  currentQuestionIndex: null,
  testResultsStatistics: null,
  activeTab: TestListTabs.DOMAINS,
  tutorTestAnswers: [],
  timedTestAnswers: [],
  currentTestStatisticMeta: null,
  currentTestStatisticQuestions: null,
  chaptersQuestions: [],
  domainsQuestions: [],
  startTutorTestParams: null,
  leastKnowDomains: [],
  leastKnowChapters: [],
};

export const testsSlice = createSlice({
  name: 'tests',
  initialState,
  reducers: {
    setCurrentQuestionIndex(state, action: PayloadAction<number | null>) {
      state.currentQuestionIndex = action.payload;
    },
    changeTab(state, action: PayloadAction<TestListTabs>) {
      state.activeTab = action.payload;
    },
    setTutorTestParams(state, action: PayloadAction<StartTutorTestPassageRequestDto | null>) {
      state.startTutorTestParams = action.payload;
    },
    clearSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(startTutorTestThunk.fulfilled, (_state, { payload }) => ({
        ...initialState,
        startTutorTestParams: _state.startTutorTestParams,
        tutorTest: payload,
      }))
      .addCase(startTimedTestThunk.fulfilled, (_state, { payload }) => ({ ...initialState, timedTest: payload }))
      .addCase(getQuizTestThunk.fulfilled, (_state, { payload }) => ({
        ...initialState,
        tutorTest: payload,
      }))

      .addCase(getUncompletedTestThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case 'quiz_test':
          case 'tutor_test': {
            state.tutorTest = payload as TutorTestPassageResponseDto;
            return;
          }
          case 'timed_test': {
            state.timedTest = payload as TimedTestPassageResponseDto;
          }
        }
      })

      .addCase(finishTestThunk.fulfilled, (state, { payload }) => {
        state.testClosedResult = payload;
      })
      .addCase(restartTestThunk.fulfilled, (state, { payload }) => {
        // TODO need switch by type in the future
        state.timedTest = payload as TimedTestPassageResponseDto;
      })
      .addCase(getNextTimedTestThunk.fulfilled, (state, { payload }) => {
        state.nextTimedTestPreview = payload;
      })

      .addCase(saveAnswerThunk.fulfilled, (state, { payload: { data, isTutor } }) => {
        const currentIndex = state.currentQuestionIndex || 0;
        if (isTutor) {
          state.tutorTestAnswers[currentIndex] = data;
        } else {
          state.timedTestAnswers[currentIndex] = data;
        }
      })
      .addCase(markedQuestionThunk.fulfilled, (state, { payload }) => {
        if (state.timedTest?.questions) {
          const questions = state.timedTest?.questions.map((question) =>
            question.id === payload.questionId ? { ...question, isMarked: true } : question,
          );
          state.timedTest.questions = questions;
        } else {
          if (state.tutorTest?.questions) {
            const questions = state.tutorTest?.questions.map((question) =>
              question.id === payload.questionId ? { ...question, isMarked: true } : question,
            );
            state.tutorTest.questions = questions;
          }
        }
      })
      .addCase(unmarkedQuestionThunk.fulfilled, (state, { payload }) => {
        if (state.timedTest?.questions) {
          const questions = state.timedTest?.questions.map((question) =>
            question.id === payload.questionId ? { ...question, isMarked: false } : question,
          );
          state.timedTest.questions = questions;
        } else {
          if (state.tutorTest?.questions) {
            const questions = state.tutorTest?.questions.map((question) =>
              question.id === payload.questionId ? { ...question, isMarked: false } : question,
            );
            state.tutorTest.questions = questions;
          }
        }
      })
      .addCase(addToFlashcardsThunk.fulfilled, (state, { payload }) => {
        if (state.timedTest?.questions) {
          const questions = state.timedTest?.questions.map((question) =>
            question.id === payload.questionId ? { ...question, isInFlashcard: true } : question,
          );
          state.timedTest.questions = questions;
        } else {
          if (state.tutorTest?.questions) {
            const questions = state.tutorTest?.questions.map((question) =>
              question.id === payload.questionId ? { ...question, isInFlashcard: true } : question,
            );
            state.tutorTest.questions = questions;
          }
        }
      })
      .addCase(removeFromFlashcardsThunk.fulfilled, (state, { payload }) => {
        if (state.timedTest?.questions) {
          const questions = state.timedTest?.questions.map((question) =>
            question.id === payload.questionId ? { ...question, isInFlashcard: false } : question,
          );
          state.timedTest.questions = questions;
        } else {
          if (state.tutorTest?.questions) {
            const questions = state.tutorTest?.questions.map((question) =>
              question.id === payload.questionId ? { ...question, isInFlashcard: false } : question,
            );
            state.tutorTest.questions = questions;
          }
        }
      })
      .addCase(getTestStatisticThunk.pending, (state) => {
        state.isTestResultsFetching = true;
      })
      .addCase(getTestStatisticThunk.rejected, (state) => {
        state.isTestResultsFetching = false;
      })

      .addCase(getDomainsQuestionsThunk.fulfilled, (state, { payload }) => {
        state.domainsQuestions = payload;
      })
      .addCase(getChaptersQuestionsThunk.fulfilled, (state, { payload }) => {
        state.chaptersQuestions = payload;
      })

      .addCase(getTestStatisticThunk.fulfilled, (state, { payload, meta: { arg } }) => {
        const { onlyIncorrect } = arg;

        state.timedTest = null;
        state.tutorTest = null;
        state.currentQuestionIndex = null;
        state.timedTestAnswers = [];
        state.tutorTestAnswers = [];

        if (!onlyIncorrect) {
          state.testResultsStatistics = {
            rightAnswered: payload.data.correctAnswers,
            totalQuestions: payload.data.meta?.totalItems || 0,
          };
        }

        const tetsStatistic = state.currentTestStatisticQuestions || [];

        state.currentTestStatisticMeta = payload.data.meta || {};
        state.currentTestStatisticQuestions = payload.isFirstFetch
          ? payload.data.items
          : [...tetsStatistic, ...payload.data.items];

        state.isTestResultsFetching = false;
      })

      .addCase(getLeastKnowDomainsThunk.fulfilled, (state, { payload }) => {
        state.leastKnowDomains = payload;
      })

      .addCase(getLeastKnowChaptersThunk.fulfilled, (state, { payload }) => {
        state.leastKnowChapters = payload;
      });
  },
});

export const actions = {
  ...testsSlice.actions,
  startTutorTest: startTutorTestThunk,
  getQuizTest: getQuizTestThunk,
  startTimedTest: startTimedTestThunk,
  finishTest: finishTestThunk,
  restartTest: restartTestThunk,
  getNextTimedTest: getNextTimedTestThunk,
  markedQuestion: markedQuestionThunk,
  getDomainsQuestions: getDomainsQuestionsThunk,
  getChaptersQuestions: getChaptersQuestionsThunk,
  unmarkedQuestion: unmarkedQuestionThunk,
  saveAnswer: saveAnswerThunk,
  getStatistic: getTestStatisticThunk,
  getUncompletedTest: getUncompletedTestThunk,
  getLeastKnowDomains: getLeastKnowDomainsThunk,
  getLeastKnowChapters: getLeastKnowChaptersThunk,
};

export const { reducer } = testsSlice;
