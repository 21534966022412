import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FlashcardDto, GetCurrentResultsResponseDto, PaginationMetaDto } from 'api/generated';
import { WEIGHT_OF_ONE_PERCENT } from 'constant/maximumPoints';

import {
  answerFlashcardThunk,
  completeFlashcardsPackThunk,
  getFlashcardsPackResultThunk,
  getFlashcardsPackThunk,
  getFlashcardsThunk,
  resetFlashcardsResultThunk,
} from './actions';

interface flashcardsSlice {
  meta: PaginationMetaDto | null;
  flashcards: Array<FlashcardDto> | null;
  flashcardsPackName: string | null;
  currentFlashcardIndex: number | null;
  currentFlashcardsPackId: string | null;
  packFlashcardsResult: GetCurrentResultsResponseDto | null;
  timeToNextAvailableCard: number | null;
  packCompleteResult: number | null;
  flashcardsPack: Array<FlashcardDto> | null;
  flashcardsPackMeta: PaginationMetaDto | null;
}

const initialState: flashcardsSlice = {
  flashcards: null,
  currentFlashcardIndex: null,
  currentFlashcardsPackId: null,
  meta: null,
  packFlashcardsResult: null,
  flashcardsPackName: null,
  timeToNextAvailableCard: null,
  packCompleteResult: null,
  flashcardsPack: null,
  flashcardsPackMeta: null,
};

export const flashcardsSlice = createSlice({
  name: 'flashcards',
  initialState,
  reducers: {
    setCurrentFlashcardIndex(state, action: PayloadAction<number | null>) {
      state.currentFlashcardIndex = action.payload;
    },
    setFlashcardsPackId(state, action: PayloadAction<string | null>) {
      state.currentFlashcardsPackId = action.payload;
    },
    clearPackFlashcards: (state) => ({ ...state, packFlashcards: null, packFlashcardsMeta: null }),
    clearSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFlashcardsThunk.fulfilled, (state, { payload }) => {
        const flashcards = state.flashcards || [];
        state.flashcards = payload.isFirstFetch ? payload.data.items : [...flashcards, ...payload.data.items];
        state.meta = payload.data.meta;
        state.flashcardsPackName = payload.data.packName;
        state.timeToNextAvailableCard = payload.data.timeToNextAvailableCard;
      })
      .addCase(getFlashcardsPackResultThunk.fulfilled, (state, { payload }) => {
        state.packFlashcardsResult = payload;
      })
      .addCase(resetFlashcardsResultThunk.fulfilled, (state) => {
        if (state.packFlashcardsResult) {
          state.packFlashcardsResult = { ...state.packFlashcardsResult, no: 0, yes: 0, kinda: 0 };
        }
      })
      .addCase(completeFlashcardsPackThunk.fulfilled, (state, { payload }) => {
        state.packCompleteResult = payload.total * WEIGHT_OF_ONE_PERCENT;
      })
      .addCase(getFlashcardsPackThunk.pending, (state, { meta }) => {
        const isFirstFetch = !meta.arg.page || meta.arg.page === 1;

        if (isFirstFetch) {
          state.flashcardsPack = null;
          state.flashcardsPackMeta = null;
        }
      })
      .addCase(getFlashcardsPackThunk.fulfilled, (state, { payload }) => {
        const flashcardsPack = state.flashcardsPack || [];
        state.flashcardsPack = payload.isFirstFetch ? payload.data.items : [...flashcardsPack, ...payload.data.items];
        state.flashcardsPackMeta = payload.data.meta;
      });
  },
});

export const actions = {
  ...flashcardsSlice.actions,
  answerFlashcard: answerFlashcardThunk,
  getFlashcards: getFlashcardsThunk,
  getFlashcardsPack: getFlashcardsPackThunk,
  getFlashcardsPackResult: getFlashcardsPackResultThunk,
  resetFlashcardsPackResult: resetFlashcardsResultThunk,
  completeFlashcardsPack: completeFlashcardsPackThunk,
};

export const { reducer } = flashcardsSlice;
