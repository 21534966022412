import '@reduxjs/toolkit';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Button, ScrollbarContainer } from 'UI';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import StyledText from 'UI/StyledText';
import { SubscribeDtoTypeEnum, SubscriptionPeriod } from 'api/generated';
import { AxiosError } from 'axios';
import Loader from 'components/Loader';
import { levelMapping, newSubscribeTypes } from 'constant';
import { useAsyncAction, useGoogleAnalytics, useStripeUnauthorizedForm } from 'hooks';
import { useCoupon } from 'hooks';
import { CreateUserModalsEnum, IframeEvents } from 'pages/CreateUser/CreateUser';
import { UnauthorizedStripeProvider } from 'providers';
import React, { FC, useEffect, useState } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import DefaultModal from '../../../UI/Modals/DefaultModal';
import { DefaultModalProps } from '../../../UI/Modals/DefaultModal/DefaultModal';
import { Coupon, Price, Secure } from '../PaymentModal/components';
import { AuthorizedInput } from './components';

declare const dataLayer: any;

type SignUpModalProps = {
  email: string;
  openSuccessModal?: () => void;
  courseName: string;
  subscribeType: SubscribeDtoTypeEnum;
  currentPrice: number;
  showCloseButton?: boolean;
  oldPrice: number;
  term: TermEnum;
  courseId: string;
  defaultCoupon: any;
  currentModal: CreateUserModalsEnum;
} & DefaultModalProps;

const SignUpModal: FC<SignUpModalProps> = ({
  email,
  openSuccessModal,
  courseName,
  showCloseButton,
  subscribeType,
  currentPrice,
  oldPrice,
  term,
  courseId,
  defaultCoupon,
  currentModal,
  ...props
}) => {
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [invoiceId, setInvoiceId] = useState('');
  const { getEventSubscriptionPayPressedGA } = useGoogleAnalytics();
  const {
    couponState,
    isInvalidCoupon,
    removeCoupon,
    onCouponSubmit,
    setIsInvalidCoupon,
    isDefaultCoupon,
    onChangePromoCode,
    isLoading: isDeleteLoading,
    isChangePromoCodeLoading,
  } = useCoupon({
    courseId,
    subscribeType,
    term,
    amount: currentPrice,
    defaultCouponCode: defaultCoupon,
    setClientSecret,
    setInvoiceId,
    invoiceId,
    isUpdate: false,
  });

  const { couponErrorMessage, isSuccessCoupon, discountAmount, discountValue, promoCode } = couponState;

  const [error, setError] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);

  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const direction = useAppSelector(selectors.direction.selectDirection);
  const logo = isDarkMode ? direction?.darkCheckoutLogoUrl : direction?.checkoutLogoUrl;
  const [purchaseCourseAction] = useAsyncAction(actions.payments.purchaseCourse);
  const [subscribeCourseAction] = useAsyncAction(actions.payments.subscribeCourse);
  const { getEventSuccessfulPurchaseForLandingGA, getEventSuccessfulSubscribeForLandingGA } = useGoogleAnalytics();

  const postMessage = (event: string, data?: any) => {
    parent.postMessage({ event, data }, direction?.landingUrl || '');
  };

  const handlePurchaseCourse = async () => {
    const { clientSecret, invoiceId } = await purchaseCourseAction({
      courseId,
      promocode: promoCode,
      source: 'landing page',
      useToken: false,
    });
    setInvoiceId(invoiceId);
    return clientSecret;
  };

  const handleSubscribeCourse = async () => {
    const { clientSecret, invoiceId } = await subscribeCourseAction({
      courseId,
      level: subscribeType,
      period: term.toLowerCase() as SubscriptionPeriod,
      source: 'landing page',
      promocode: promoCode,
      useToken: false,
    });
    setInvoiceId(invoiceId);
    return clientSecret;
  };

  const isLifetimeTerm = term === TermEnum.LIFE_TIME;

  const getSecret = async () => {
    try {
      const clientSecret = isLifetimeTerm ? await handlePurchaseCourse() : await handleSubscribeCourse();

      if (clientSecret) {
        setClientSecret(clientSecret);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingCustomer(false);
    }
  };

  useEffect(() => {
    getSecret();
  }, []);

  const {
    handleSubmit: handleSubmitStripe,
    setElements,
    setStripe,
    stripe,
    errorMessage,
    isLoading: isLoadingStripe,
    disabled,
    isSucceeded,
  } = useStripeUnauthorizedForm(clientSecret, invoiceId, courseId, term);

  const isLoading = isLoadingStripe;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError('');
    getEventSubscriptionPayPressedGA();
    await handleSubmitStripe(e);
  };

  const pushDataLayer = async () => {
    if (!clientSecret) {
      return;
    }
    const paymentIntent = (await stripe?.retrievePaymentIntent(clientSecret))?.paymentIntent;
    dataLayer.push({
      event: 'successful_purchase',
      transactionId: paymentIntent?.id,
      course_name: courseName,
      promo_code: promoCode,
      item_name: subscribeType,
      item_id: courseId,
      price: finalPrice,
      term: term,
      enhanced_conversion_data: {
        email: email,
      },
    });
  };

  useEffect(() => {
    if (isSucceeded) {
      const onPaymentConfirm = async () => {
        try {
          setError('');
          openSuccessModal?.();
          pushDataLayer();
          term === TermEnum.LIFE_TIME
            ? getEventSuccessfulPurchaseForLandingGA()
            : getEventSuccessfulSubscribeForLandingGA();
        } catch (error) {
          setError((error as AxiosError).message);
        }
      };

      onPaymentConfirm();
    }
  }, [isSucceeded]);

  useEffect(() => {
    const handleStripeOperation = async () => {
      if (currentModal === CreateUserModalsEnum.SUCCESS_CREATE_ACCOUNT && clientSecret) {
        try {
          const paymentIntent = (await stripe?.retrievePaymentIntent(clientSecret))?.paymentIntent;

          const data = {
            term,
            productType: newSubscribeTypes[subscribeType],
            courseName,
            courseId,
            currentPrice: finalPrice,
            promoCode,
            oldPrice,
            currency: paymentIntent?.currency,
            transactionId: paymentIntent?.id,
            created_date: paymentIntent?.created ? new Date(paymentIntent?.created * 1000).toString() : undefined,
          };

          postMessage(IframeEvents.SUCCESSFUL_PURCHASE, data);
        } catch (error) {
          console.error(error);
        }
      }
    };

    handleStripeOperation();
  }, [currentModal, clientSecret]);

  const finalPrice = discountValue && currentPrice ? currentPrice - discountValue : currentPrice;

  return (
    <DefaultModal
      {...props}
      headerContainerCSS={headerContainerCSS}
      containerCSS={containerCSS}
      showCloseButton={showCloseButton}
      titleCSS={titleCSS}
      modalCSS={modalCSS}
      shouldCloseOnOverlayClick={false}
      closeButtonCSS={closeButtonCSS}>
      <DescriptionContainer>
        <LogoContainer>
          <LogoImg src={logo} />
        </LogoContainer>
        <Price amount={currentPrice} term={term} isDefaultCoupon={isDefaultCoupon} discountValue={discountValue} />
        <NameContainer>
          <CourseNameText>{courseName}</CourseNameText>
          <CourseSubNameText>{levelMapping[subscribeType]}</CourseSubNameText>
        </NameContainer>

        <Coupon
          subscribeType={levelMapping[subscribeType]}
          isDefaultCoupon={isDefaultCoupon}
          defaultCouponCode={defaultCoupon}
          isSuccessCoupon={isSuccessCoupon}
          courseName={courseName || ''}
          onCouponSubmit={onCouponSubmit}
          removeCoupon={removeCoupon}
          oldAmount={oldPrice}
          promoCode={promoCode}
          amount={currentPrice}
          discountAmount={discountAmount}
          discountValue={discountValue}
          couponErrorMessage={couponErrorMessage}
          invalidCoupon={isInvalidCoupon}
          setClearCoupon={() => setIsInvalidCoupon(false)}
          handleChangePromoCode={onChangePromoCode}
          disable={isDeleteLoading || isChangePromoCodeLoading}
        />
      </DescriptionContainer>
      <Content>
        <Form onSubmit={handleSubmit}>
          <div>
            <StripeFormWrapper>
              {isLoadingCustomer && (
                <LoaderWrapper>
                  <Loader width={54} height={54} />
                </LoaderWrapper>
              )}
              <UnauthorizedStripeProvider
                showEmptyState={true}
                setElements={setElements}
                setStripe={setStripe}
                onConfirm={handleSubmitStripe}
                clientSecret={clientSecret}>
                <>
                  <Text>Or with Card/Cash App</Text>
                  <AuthorizedInput setIsAuthorized={setIsAuthorized} courseId={courseId} />
                  <PaymentElement
                    options={{
                      readOnly: !isAuthorized || !clientSecret || finalPrice <= 0,
                      terms: { card: 'never', usBankAccount: 'never', bancontact: 'never' },
                    }}
                  />
                </>
              </UnauthorizedStripeProvider>
              <ErrorText>{error || errorMessage}</ErrorText>
              {/* {isPurchased && (
                <ErrorText>
                  This course already purchased! Please{' '}
                  <Link as={RouterLink} to="/sign-in" target="_blank">
                    sign in
                  </Link>{' '}
                  to access courses.
                </ErrorText>
              )} */}
            </StripeFormWrapper>
          </div>
          <Secure />
          <Footer>
            <Button
              isLoading={isLoading || !clientSecret || isDeleteLoading || isChangePromoCodeLoading}
              disabled={disabled || !clientSecret || !isAuthorized}
              type="submit"
              variant="primary">
              {`Enroll Now: $${finalPrice} USD`}
            </Button>
            <LinkContainer>
              <Link as="a" href="https://traineracademy.org/privacy-policy/" target="_blank" rel="noopener noreferrer">
                Privacy
              </Link>
              <Link
                as="a"
                href="https://traineracademy.org/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer">
                Terms
              </Link>
            </LinkContainer>
            <SecureTextContainer>
              {term.toLowerCase() !== TermEnum.LIFE_TIME && (
                <SecureText font="subhead_medium_13">Renews automatically. Cancel anytime.</SecureText>
              )}
            </SecureTextContainer>
          </Footer>
        </Form>
      </Content>
    </DefaultModal>
  );
};

export default SignUpModal;

const modalCSS = css`
  max-height: 100%;
  height: 100%;
  max-width: 668px;

  width: 100%;

  padding: 37px 0;

  ${respondToWidth.sm`
    padding: 0;
  `};
`;

const containerCSS = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 0;
  padding-block: 40px;
  padding-right: 4px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${respondToWidth.s`
    display: flex;
    flex-direction: column;
    height: 100vh;
    border-radius: 0;
  `};
`;

const headerContainerCSS = css`
  ${respondToWidth.s`
    margin-bottom: 28px;
  `};
`;

const closeButtonCSS = css`
  border: 1px solid;
  border-color: #ffffff;
  background-color: ${({ theme: { colors } }) => colors.primary[1]};
  ${respondToWidth.sm`
    top: 40px;
    right: 16px;
  `};
`;

const titleCSS = css`
  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.title_3_bold_28};
  `};

  ${({ theme: { typography, colors } }) => respondToWidth.s`
    width: 100%;
    text-align: center;
    color: ${colors.primary[1]};
    ${typography.title_3_bold_28};
  `};
`;

const Content = styled(ScrollbarContainer)`
  width: 100%;
  padding-inline: 100px 96px;
  height: 100%;
  overflow: unset;

  ${respondToWidth.sm`
    padding-inline: 40px 36px;

  `};
  ${respondToWidth.s`
    padding-inline: 16px 12px;
  `};
`;

const ErrorText = styled(StyledText)`
  text-align: start;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.system.red};
    ${typography.body_basic_medium_14}

    ${respondToWidth.s`
      ${typography.footnote_semibold_12}
  `}
  `};
`;

const DescriptionContainer = styled.div`
  margin-bottom: 48px;
  margin-right: -4px;
  margin-top: -73px;
  background-color: ${({ theme: { colors } }) => colors.primary[1]};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.title_3_bold_28};
    margin-bottom: 20px;
  `};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
`;

const Footer = styled.div`
  display: grid;
  grid-gap: 28px;
  height: fit-content;

  ${respondToWidth.s`
      grid-gap: 16px;
  `}
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Link = styled(StyledText)`
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;

  color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const Text = styled(StyledText)`
  text-align: center;
  margin-top: 20px;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

const StripeFormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: stretch;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: calc(50% - 31px);
  top: calc(50% - 21px);
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 35px;
  cursor: pointer;
  padding-right: 8px;

  ${respondToWidth.s`
  margin-top: 20px;
  margin-bottom: 23px;
`}
`;

const LogoImg = styled.img`
  max-width: 100%;
  max-height: 40px;
`;

const NameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const CourseNameText = styled.span`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
  cursor: pointer;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[11]};
    ${typography.title_5_bold_20}
  `};
`;

const CourseSubNameText = styled.span`
  display: flex;
  height: 28px;
  padding: 0px 5px;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: -10px;
  text-align: center;
  padding-bottom: 10px;
  cursor: default;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.primary[1]};
    background-color: ${colors.neutrals[11]};
    ${typography.title_5_bold_20}
  `};
`;

const SecureText = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  margin-left: 6px;
`;

const SecureTextContainer = styled.div`
  display: flex;
  justify-content: center;
`;
