import StyledText from 'UI/StyledText';
import { CaretIcon } from 'assets/icons';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type TitleProps = {
  $isHideText?: boolean;
  $disabled?: boolean;
};

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: inherit;
  border: none;
  outline: none;
  height: min-content;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.primary[1]};

  svg path {
    fill: ${({ theme: { colors } }) => colors.primary[1]};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme: { colors } }) => colors.neutrals[5]};

    svg path {
      fill: ${({ theme: { colors } }) => colors.neutrals[5]};
    }
  }
`;

export const LeftCaret = styled(CaretIcon)`
  transform: scale(-1, 1);
`;

export const RightCaret = styled(CaretIcon)`
  transform: scale(1, -1);
`;

export const Title = styled(StyledText)<TitleProps>`
  color: ${({ theme: { colors }, $disabled }) => ($disabled ? colors.neutrals[5] : colors.primary[1])};
  ${({ theme: { typography } }) => typography.headline_semibold_18};

  ${({ $isHideText }) => respondToWidth.sm`
    display: ${$isHideText && 'none'}
  `}

  &:disabled {
    color: ${({ theme: { colors } }) => colors.neutrals[5]};
  }
`;
