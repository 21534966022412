import ScrollbarContainer from 'UI/ScrollbarContainer';
import React, { FC, ReactNode, useDeferredValue, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

type TabsProps = {
  tabs: Array<TabType>;
};

type TabType = {
  component: ReactNode;
  title: string;
};

const DEFAULT_STYLES = {
  offsetLeft: 0,
  width: 0,
};

const Tabs: FC<TabsProps> = ({ tabs }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const deferredCurrentTabIndex = useDeferredValue(currentTabIndex);
  const [currentTabStyles, setCurrentTabStyles] = useState(DEFAULT_STYLES);
  const currentTabRef = useRef<HTMLButtonElement>(null);

  const updateCurrentTabStyles = () => {
    setCurrentTabStyles(
      currentTabRef.current
        ? {
            offsetLeft: currentTabRef.current.offsetLeft,
            width: currentTabRef.current.offsetWidth,
          }
        : DEFAULT_STYLES,
    );
  };

  useEffect(() => {
    updateCurrentTabStyles();
  }, [deferredCurrentTabIndex, currentTabRef]);

  if (!tabs.length) {
    return null;
  }

  return (
    <Root>
      <TabsContainer>
        <TabsWrapper>
          {tabs.map((tab, index) => (
            <Tab
              ref={index === deferredCurrentTabIndex ? currentTabRef : null}
              $isActive={index === deferredCurrentTabIndex}
              onClick={() => setCurrentTabIndex(index)}
              key={index}>
              {tab.title}
            </Tab>
          ))}
          <ActiveIndicator
            style={{
              transform: `translateX(${currentTabStyles.offsetLeft}px)`,
              width: currentTabStyles.width,
            }}
          />
        </TabsWrapper>
      </TabsContainer>
      <Body>{tabs[deferredCurrentTabIndex]?.component}</Body>
    </Root>
  );
};

export default Tabs;

const Root = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  overflow: hidden;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr;
  grid-gap: 16px;
`;

const TabsContainer = styled.div`
  width: fit-content;
  height: 100%;

  justify-self: center;

  padding: 4px;
  border-radius: 16px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
`;

const TabsWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: 100%;

  display: flex;
  gap: 8px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
`;

const Tab = styled.button<{ $isActive: boolean }>`
  ${({ theme: { colors, typography }, $isActive }) => css`
    position: relative;
    z-index: 1;

    padding: 6px 24px;
    cursor: pointer;
    color: ${$isActive ? colors.neutrals[11] : colors.neutrals[1]};
    white-space: nowrap;
    transition: color 0.3s ease-in-out;

    ${typography.body_basic_semibold_14}
  `};
`;

const ActiveIndicator = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;

  border-radius: 12px;
  background-color: ${({ theme: { colors } }) => colors.primary[1]};

  transition: transform 0.3s ease-in-out;
`;

const Body = styled(ScrollbarContainer)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 4px;
`;
