import { ChangeEmailModal as ChangeEmailModalUI } from 'UI';
import { ChangeEmailFormState } from 'UI/Modals/ChangeEmailModal/ChangeEmailModal';
import { useAsyncAction } from 'hooks';
import React, { FC } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import { BaseModalProps } from 'types';

type ChangeEmailModalProps = {
  showConfirmModal: () => void;
} & BaseModalProps;

const ChangeEmailModal: FC<ChangeEmailModalProps> = ({ showConfirmModal, ...props }) => {
  const [userChangeEmail, isLoading] = useAsyncAction(actions.user.changeEmail);
  const user = useAppSelector(selectors.user.selectUser);

  const changeEmail = async (value: ChangeEmailFormState) => {
    await userChangeEmail({ email: value.newEmail.trim() });
    showConfirmModal();
  };

  return <ChangeEmailModalUI {...props} isLoading={isLoading} changeEmail={changeEmail} email={user?.email} />;
};

export default ChangeEmailModal;
