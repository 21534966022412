import { RootState } from 'store';

export const selectors = {
  selectCourseStatistic: (state: RootState) => state.performance.courseStatistic,
  selectTestsStatistics: (state: RootState) => state.performance.testsStatistics,
  selectTestsStatisticsByAnswer: (state: RootState) => state.performance.testsStatisticsByAnswer,
  selectTestsStatisticsByAnswerMeta: (state: RootState) => state.performance.testsStatisticsByAnswerMeta,
  selectFlashcardsStatistics: (state: RootState) => state.performance.flashcardsStatistics,
  selectFlashcardsStatisticsByAnswer: (state: RootState) => state.performance.flashcardsStatisticsByAnswer,
  selectFlashcardsStatisticsByAnswerMeta: (state: RootState) => state.performance.flashcardsStatisticsByAnswerMeta,
  selectFlashcardsPacksStatistic: (state: RootState) => state.performance.flashcardsPacksStatistic,
  selectFlashcardsPacksStatisticMeta: (state: RootState) => state.performance.flashcardsPacksStatisticMeta,
  selectTestStatistic: (state: RootState) => state.performance.testStatistic,
  selectTestStatisticMeta: (state: RootState) => state.performance.testStatisticMeta,
  selectTestsList: (state: RootState) => state.performance.testsList,
  selectTestsListMeta: (state: RootState) => state.performance.testsListMeta,
  selectIsPointsIncreased: (state: RootState) => state.performance.isPointsIncreased,
  selectCurrentTestId: (state: RootState) => state.performance.currentTestId,
  selectCurrentTestSplitType: (state: RootState) => state.performance.currentTestSplitType,
  selectTestLegendStatistic: (state: RootState) => state.performance.testsLegendStatistic,
  selectAllTestsStatistic: (state: RootState) => state.performance.allTestsStatistic,
  selectAllTestsStatisticMeta: (state: RootState) => state.performance.allTestsStatisticMeta,
  selectIsShowAllTestsStatistic: (state: RootState) => state.performance.isShowAllTestsStatistic,
  selectAddedStreakToday: (state: RootState) => state.performance.courseStatistic?.addedStreakToday,
};
