import Button from 'UI/Button';
import PasswordInput from 'UI/PasswordInput';
import TextButton from 'UI/TextButton';
import { useNotifications } from 'hooks';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps, DefaultRejectValue } from 'types';
import { validations } from 'utils';

import DefaultModal from '../DefaultModal';

type FormState = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

type ChangePasswordModalProps = {
  changePassword?: (values: FormState) => Promise<void>;
  openForgotPasswordModal?: () => void;
  isLoading?: boolean;
} & BaseModalProps;

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  onClose,
  openForgotPasswordModal,
  changePassword,
  isLoading,
  ...props
}) => {
  const { successToast } = useNotifications();
  const { handleSubmit, control, getValues, setError, trigger, reset } = useForm<FormState>();

  const onSubmit = async (values: FormState) => {
    try {
      await changePassword?.(values);

      successToast('Password changed successfully');

      closeModal();
    } catch (error) {
      const isNotMatch = (error as DefaultRejectValue).message === 'Old password does not match';

      if (isNotMatch) {
        setError('currentPassword', { message: 'Wrong password' });
      } else {
        setError('newPassword', {
          message: (error as DefaultRejectValue).message as string,
        });
      }
    }
  };

  const handleClick = () => {
    onClose();
    openForgotPasswordModal?.();
  };

  const closeModal = () => {
    onClose();
    reset();
  };

  const validateConfirmPassword = (confirmPassword: string) => {
    const newPassword = getValues('newPassword');

    if (!confirmPassword) {
      return 'Required field';
    }

    if (confirmPassword !== newPassword) {
      return "Passwords don't match";
    }
  };

  return (
    <DefaultModal
      {...props}
      onRequestClose={closeModal}
      title="Change your password"
      containerCSS={ContainerCSS}
      changeCloseButton
      modalCSS={ModalCSS}
      isMobileOverlayStyles>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="currentPassword"
          rules={{
            required: 'Required field',
          }}
          render={({ field, fieldState }) => (
            <StyledInput label="Current password" error={fieldState.error?.message} {...field} />
          )}
        />
        <Controller
          control={control}
          name="newPassword"
          rules={{
            required: 'Required field',
            validate: validations.password,
            onChange() {
              trigger('confirmPassword');
            },
          }}
          render={({ field, fieldState }) => (
            <StyledInput label="New password" error={fieldState.error?.message} {...field} />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            // required: 'Required field',
            validate: validateConfirmPassword,
          }}
          render={({ field, fieldState }) => (
            <StyledInput label="Confirm new password" error={fieldState.error?.message} {...field} />
          )}
        />
        <StyledTextButton onClick={handleClick} text="Forgot password?" />
        <StyledButton isLoading={isLoading} variant="primary" size="middle" type="submit">
          Save
        </StyledButton>
      </Form>
    </DefaultModal>
  );
};

export default ChangePasswordModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
`;

const StyledTextButton = styled(TextButton)`
  position: absolute;
  top: 0;
  right: 12px;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  text-decoration: none;
  cursor: pointer;
  ${({ theme: { typography } }) => typography.footnote_semibold_12};

  ${({ theme: { typography } }) => respondToWidth.s`
    position: initial;
    ${typography.body_basic_bold_14};
  `}
`;

const StyledInput = styled(PasswordInput)`
  margin-bottom: 32px;

  ${respondToWidth.s`
    margin-bottom: 16px;
  `}

  &:nth-child(3) {
    margin-bottom: 40px;

    ${respondToWidth.s`
    margin-bottom: 24px;
  `}
  }
`;

const StyledButton = styled(Button)`
  ${respondToWidth.s`
    margin-top: auto;
  `}
`;
