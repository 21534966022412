import { Button, ConfirmModal, DefaultModal, ScrollbarContainer, StyledText } from 'UI';
import { AxiosError } from 'axios';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { Dispatch, FC, useRef, useState } from 'react';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { pauseOptions } from './constants';

type PauseModalProps = {
  isModalVisible: boolean;
  setModalVisible: Dispatch<React.SetStateAction<boolean>>;
  className?: string;
};

const PauseModal: FC<PauseModalProps> = ({ isModalVisible, setModalVisible, className }) => {
  const { successToast, errorToast } = useNotifications();
  const dispatch = useAppDispatch();
  const [isShowPauseConfirmModal, setIsShowPauseConfirmModal] = useState(false);
  const courseOptionsForPayment = useAppSelector(selectors.courses.selectCourseOptionsForPayment);
  const [pauseSubscription, isLoading] = useAsyncAction(actions.payments.pauseSubscription);
  const rootRef = useRef<HTMLDivElement>(null);
  const [pausePlan, setPausePlan] = useState(pauseOptions);
  const [planId, setPlanId] = useState(0);

  const handlePausePlan = (planId: string) => {
    const updatedPausePlan = pausePlan.map((item) => {
      if (item.id === planId) {
        return { ...item, active: true };
      }
      return { ...item, active: false };
    });
    setPlanId(Number(planId));
    setPausePlan(updatedPausePlan);
  };

  const handlePause = async () => {
    try {
      if (!courseOptionsForPayment) {
        return;
      }
      await pauseSubscription({ courseId: courseOptionsForPayment?.courseId, months: planId });
      setModalVisible(false);
      successToast('Subscription paused successfully');
      dispatch(actions.courses.setPauseSubscription(true));
    } catch (error) {
      setModalVisible(false);
      errorToast((error as AxiosError).message || 'Something went wrong');
    }
  };

  const onClose = () => {
    if (!isShowPauseConfirmModal) {
      setModalVisible(false);
    }
  };

  return (
    <>
      <DefaultModal
        isOpen={isModalVisible}
        onRequestClose={onClose}
        title="Pause subscription"
        containerCSS={ContainerCSS}
        titleCSS={TitleCSS}
        modalCSS={ModalCSS}
        showBackButton={false}>
        <Container>
          <Text>Your subscription will be paused from the next billing period. All your progress will be saved.</Text>
          <List>
            {pausePlan.map((item) => {
              return (
                <Root key={item.id} ref={rootRef} onClick={() => handlePausePlan(item.id)}>
                  <Wrapper>
                    <Indicator $isActive={item.active} />
                    <Title font="body_large_bold_16">{item.title}</Title>
                  </Wrapper>
                </Root>
              );
            })}
          </List>

          <ButtonsContainer>
            <WeakestAreasButton
              onClick={() => setIsShowPauseConfirmModal(true)}
              className={className}
              variant="primary"
              size="middle">
              Pause
            </WeakestAreasButton>
          </ButtonsContainer>
        </Container>
      </DefaultModal>
      <ConfirmModal
        title="Are you sure you want to pause your subscription?"
        text="You will be able to restart subscription"
        isOpen={isShowPauseConfirmModal}
        isLeftButtonLoading={isLoading}
        onRightButtonClick={() => setIsShowPauseConfirmModal(false)}
        onLeftButtonClick={() => handlePause()}
        onClose={() => setIsShowPauseConfirmModal(false)}
      />
    </>
  );
};

export default PauseModal;

const ModalCSS = css`
  ${respondToWidth.sm`
    top: unset;
    bottom: 0;
    width: 100%;
    transform: translate(-50%, 0);
  `}
`;

const Root = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100%;

    padding: 16px 12px 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    cursor: pointer;

    background-color: ${colors.neutrals[11]};
    border: 1px solid ${colors.neutrals[9]};
    border-radius: 12px;

    position: relative;
  `}
`;

const Wrapper = styled.div`
  width: fit-content;
  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr;
  grid-gap: 12px;
`;

const ContainerCSS = css`
  padding: 40px 80px;
  width: 628px;
  height: 542px;

  ${respondToWidth.sm`
    height: 548px;
    width: 100%;
    padding: 32px 16px;
  `}
`;

const TitleCSS = css`
  ${({ theme: { colors } }) => css`
    margin-bottom: 4px;
    color: ${colors.primary[1]} !important;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);

  ${respondToWidth.sm`
    height: calc(100% - 38px);
  `}
`;

const Indicator = styled.span<{ $isActive?: boolean; $isDarkMode?: boolean }>`
  ${({ theme: { colors }, $isActive, $isDarkMode }) => css`
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${$isDarkMode ? colors.neutrals[8] : colors.neutrals[10]};
    border-radius: 50%;

    ${$isActive &&
    css`
      ::after {
        content: '';
        width: 10px;
        height: 10px;

        background-color: ${colors.primary[1]};
        border-radius: 50%;
      }
    `}
  `}
`;

const Title = styled(StyledText)`
  max-width: 658px;
  width: 100%;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: '20px';
`;

const WeakestAreasButton = styled(Button)`
  display: flex !important;
  width: 100% !important;
  flex-direction: row-reverse;
  padding-right: 23px;
  white-space: nowrap;

  & svg {
    margin-left: 8px;
  }
`;

const Text = styled(StyledText)<{ $color?: string; description?: string }>`
  text-align: center;
  margin-bottom: 20px;
  ${({ theme: { typography, colors }, $color, description }) => css`
    color: ${$color ? $color : colors.neutrals[4]};
    ${typography.body_large_semibold_16}

    ${respondToWidth.sm`
    margin-bottom:${description ? '40px' : '20px'};
    ${typography.body_basic_medium_14};
  `}
  `};
`;

const List = styled(ScrollbarContainer)`
  height: 100%;

  padding-right: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  ${respondToWidth.sm`
    overflow-y: hidden;
    padding-right: 0;
    gap: 16px;
  `}
`;
