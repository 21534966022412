import { useState } from 'react';

type SelectedTextIndex = { startIndex: number; endIndex: number } | null;

const useTextSelecting = () => {
  const [selectedTextIndex, setSelectedTextIndex] = useState<SelectedTextIndex>(null);
  const [selectedText, setSelectedText] = useState('');
  const [blockIndex, setBlockIndex] = useState<null | number>(null);

  const clearSelectedTextMeta = () => {
    window?.getSelection?.()?.removeAllRanges();
    setSelectedTextIndex(null);
    setBlockIndex(null);
    setSelectedText('');

    setTimeout(deleteSelectedText, 100);
  };

  const deleteSelectedText = () => {
    const selectedTexts = [...document.getElementsByClassName('selectedText')];

    for (let i = 0; i <= selectedTexts.length; i++) {
      selectedTexts[i]?.classList.remove('selectedText');
    }
  };

  const wrapSelectedText = () => {
    const selection = window?.getSelection?.();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      if (!range.collapsed) {
        const span = document.createElement('span');
        span.className = 'selectedText inheritText';

        const startContainer = range.startContainer;
        const endContainer = range.endContainer;

        if (startContainer.nodeType === Node.TEXT_NODE && endContainer.nodeType === Node.TEXT_NODE) {
          const documentFragment = range.extractContents();
          span.appendChild(documentFragment);
          range.insertNode(span);

          selection.removeAllRanges();
          const newRange = document.createRange();
          newRange.selectNode(span);
          selection.addRange(newRange);
        } else {
          return;
        }
      }
    }
  };

  const onSelectText = (containerId: string) => {
    const selection = window?.getSelection?.();
    const text = selection?.toString() || '';
    const textLength = text.length;
    const { childNodes = [], blockIndex } = getCurrentContentBlock(containerId) || {};

    setBlockIndex(blockIndex || null);

    const results = getSelectedTextIndex(childNodes);
    const isCollapsed = getIsCollapsed();

    setSelectedText(text);

    if (results && text && textLength > 0) {
      wrapSelectedText();
    }

    if (results && isCollapsed) {
      const transformedResults = transformCollapsedSelectedTextIndex(results.startIndex, textLength);
      setSelectedTextIndex(transformedResults);
      return { isCollapsed, results: transformedResults };
    } else {
      setSelectedTextIndex(results);
      return { isCollapsed, results };
    }
  };

  const getCurrentContentBlock = (containerId: string) => {
    const selection = window?.getSelection?.();
    const container = document.getElementById(containerId);
    const contentBlocks = container?.getElementsByClassName('ce-block');

    const startNode = selection?.anchorNode || null;

    if (contentBlocks?.length) {
      for (let i = 0; i < contentBlocks.length; i++) {
        const block = contentBlocks.item(i);

        if (block?.contains(startNode)) {
          return { childNodes: [...(block.childNodes || [])], blockIndex: i };
        }
      }
    }
    contentBlocks;
  };

  const selectedListText = (selection: Selection | null, textLength: number) => {
    const parentContainerTagName = (selection?.getRangeAt?.(0).commonAncestorContainer as Element).tagName;
    if (parentContainerTagName === 'OL' || parentContainerTagName === 'UL') {
      textLength = selection?.getRangeAt(0).cloneContents().firstElementChild?.textContent?.length || 0;
      selection?.anchorNode && selection?.getRangeAt(0).selectNode(selection?.anchorNode);
    }
    return textLength;
  };

  const getSelectedTextIndex = (nodes: Array<Node | ChildNode>, startIndex = 0) => {
    const selection = window?.getSelection?.();
    const startNode = selection?.anchorNode || null;
    const start = selection?.anchorOffset || 0;
    const text = selection?.toString() || '';
    let textLength = text.length;

    if (!textLength) {
      return null;
    }

    textLength = selectedListText(selection, textLength);

    nodes.every((node) => {
      if (node.isEqualNode(startNode)) {
        startIndex += start;

        return false;
      }

      if (node.contains(startNode)) {
        const results = getSelectedTextIndex([...node.childNodes], startIndex);
        startIndex = results?.startIndex || 0;

        return false;
      }

      const textContentIndexLength = node.textContent?.length ? node.textContent?.length : 0;
      startIndex += textContentIndexLength;

      return true;
    });

    const endIndex = startIndex + (textLength - 1);

    return { startIndex, endIndex };
  };

  const getIsCollapsed = () => {
    const selection = window?.getSelection?.();

    if (!selection?.anchorNode || !selection?.focusNode) {
      return false;
    }

    const range = document.createRange();
    range.setStart(selection.anchorNode, selection.anchorOffset);
    range.setEnd(selection.focusNode, selection.focusOffset);

    return range.collapsed;
  };

  const transformCollapsedSelectedTextIndex = (startIndex: number, textLength: number) => {
    const endIndex = startIndex - 1;
    startIndex = startIndex - textLength;

    return { startIndex, endIndex };
  };

  return { selectedTextIndex, selectedText, blockIndex, onSelectText, clearSelectedTextMeta };
};

export default useTextSelecting;
