import { Button } from 'UI';
import { RefreshIcon } from 'assets/icons';
import { TestMenuButton } from 'components/TestFooter/TestFooter';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { actions } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type RecheckButtonProps = {
  isMarkedInitialValue?: boolean;
  questionId: string;
};

const QuestionRecheckButton: FC<RecheckButtonProps> = ({ isMarkedInitialValue, questionId }) => {
  const [isMarked, setIsMarked] = useState(isMarkedInitialValue);
  const { errorToast } = useNotifications();

  const [markedQuestionAction, isCreateMarkedLoading] = useAsyncAction(actions.tests.markedQuestion);
  const [unmarkedQuestionAction, isRemoveLoading] = useAsyncAction(actions.tests.unmarkedQuestion);

  useEffect(() => {
    setIsMarked(isMarkedInitialValue);
  }, [isMarkedInitialValue]);

  const isLoading = isCreateMarkedLoading || isRemoveLoading;

  const markedQuestion = async () => {
    try {
      await markedQuestionAction({ questionId });
      setIsMarked(true);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const unmarkedQuestion = async () => {
    try {
      await unmarkedQuestionAction({ questionId });
      setIsMarked(false);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const { colors } = useTheme();

  return (
    <>
      {isMarked ? (
        <StyledButton
          variant="tertiary"
          size="small"
          onClick={unmarkedQuestion}
          isLoading={isLoading}
          $isMarked={isMarked}>
          {TestMenuButton.REMOVE_RECHECKING}
        </StyledButton>
      ) : (
        <StyledButton
          iconComponent={<RefreshIcon width={24} height={24} color={colors.primary[1]} />}
          variant="secondary"
          size="small"
          onClick={markedQuestion}
          isLoading={isLoading}
          $isMarked={isMarked}>
          {TestMenuButton.RECHECKING}
        </StyledButton>
      )}
    </>
  );
};

export default QuestionRecheckButton;

const StyledButton = styled(Button)<{ $isMarked?: boolean }>`
  white-space: nowrap;
  width: ${({ $isMarked }) => ($isMarked ? '238px' : '236px')};

  ${respondToWidth.sm`
    width:100%;
  `}
`;
