import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type ConfirmTimedTestModalProps = {
  onConfirmClick: () => Promise<void> | void;
  isLoading: boolean;
  title: string;
} & BaseModalProps;

const ConfirmTimedTestModal: FC<ConfirmTimedTestModalProps> = ({
  onClose,
  onConfirmClick,
  isLoading,
  title,
  ...props
}) => {
  return (
    <DefaultModal
      onRequestClose={onClose}
      title={title}
      titleCSS={TitleCSS}
      containerCSS={ContainerCSS}
      withTitleMargin={false}
      modalCSS={ModalCSS}
      {...props}>
      <Text>
        By ending the exam, all unanswered questions will be marked as used and incorrect which may impact your
        confidence score
      </Text>
      <Buttons>
        <StyledButton variant="primary" size="middle" onClick={onConfirmClick} isLoading={isLoading}>
          Start test
        </StyledButton>
        <StyledButton variant="secondary" size="middle" onClick={onClose} disabled={isLoading}>
          Cancel test
        </StyledButton>
      </Buttons>
    </DefaultModal>
  );
};

export default ConfirmTimedTestModal;

const ContainerCSS = css`
  width: 657px;
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    padding:28px 16px; 
    border-radius: 20px 20px 0 0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const TitleCSS = css`
  margin-bottom: 0;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.title_4_bold_24};
  `}

  ${respondToWidth.s`
    text-align: center;
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  margin-bottom: 40px;
  margin-top: 24px;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_large_semibold_16}

    ${respondToWidth.sm`
    margin-bottom:0;
    ${typography.body_basic_medium_14};
  `}
  `};
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${respondToWidth.s`
    margin-top: 32px;
    justify-content: space-between;
    gap: 15px
  `}
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  width: 150px;
  padding-left: 31px;
  padding-right: 31px;

  &:nth-child(1) {
    margin-right: 24px;
  }

  ${respondToWidth.s`
    width: 100%;
    &:nth-child(1) {
    margin-right: 0;
  }
  `}
`;
