import { GallerySwiper, ScrollbarContainer } from 'UI';
import { MnemonicCardDto } from 'api/generated';
import { Loader } from 'components';
import { useInfinityScroll } from 'hooks';
import { Dispatch, FC, RefObject, SetStateAction } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { Swiper, SwiperOptions } from 'swiper';
import { PaginationType } from 'types';

type VerticalSliderProps = {
  sliderRef?: RefObject<HTMLDivElement>;
  slideRef?: RefObject<HTMLDivElement>;
  setSwiper: Dispatch<SetStateAction<Swiper | undefined>>;
  activeIndex: number;
  onClickSlide: (index?: number) => void;
  cards: MnemonicCardDto[];
  getPackMnemonicCard: (actionPayload: PaginationType) => void;
  isLoading: boolean;
  isLoadingAfter: boolean;
};

const VerticalSlider: FC<VerticalSliderProps> = ({
  slideRef,
  sliderRef,
  setSwiper,
  activeIndex,
  onClickSlide,
  cards,
  getPackMnemonicCard,
  isLoading,
  isLoadingAfter,
}) => {
  const secondSwiperOptions: SwiperOptions = {
    slidesPerView: cards.length,
    direction: 'vertical',
  };

  const mnemonicCardsMeta = useAppSelector(selectors.mnemonicCards.selectMnemonicCardsPackMeta);
  const currentPage = mnemonicCardsMeta?.currentPage || 0;
  const totalPages = mnemonicCardsMeta?.totalPages || 0;
  const nextPage = currentPage + 1;

  const hasMnemonicCardPackMore = currentPage < totalPages;

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getPackMnemonicCard({ page: nextPage }),
    hasAfterMore: hasMnemonicCardPackMore,
    isLoading: isLoadingAfter,
    outerRef: sliderRef,
    isScrollToInitialPosition: false,
  });

  const { colors } = useTheme();

  return (
    <Background $isLoading={isLoadingAfter}>
      <Gallery ref={listRef} onScroll={handleScroll}>
        {isLoading ? (
          <Loader />
        ) : (
          <GallerySwiper
            data={cards}
            setSwiper={setSwiper}
            swiperOptions={secondSwiperOptions}
            containerCSS={GallerySliderContainer}
            renderElement={(item, index) => (
              <ActiveBorder ref={slideRef} $isSelect={index === activeIndex}>
                <GalleryImageContainer onClick={() => onClickSlide(index)} $isSelect={index === activeIndex}>
                  <img src={item.imageUrl} alt={item.title} />
                </GalleryImageContainer>
              </ActiveBorder>
            )}
          />
        )}
      </Gallery>
      {isLoadingAfter && (
        <LoaderContainer>
          <ThreeDots color={colors.primary[3]} />
        </LoaderContainer>
      )}
    </Background>
  );
};

export default VerticalSlider;

const GallerySliderContainer = css`
  width: 100%;
  position: absolute;

  .scroll-wrapper {
    height: auto;
  }
  img {
    min-height: 160px;
    height: 160px;
  }
`;

const Background = styled.div<{ $isLoading: boolean }>`
  padding-left: 12px;
  padding-right: 4px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
  grid-area: container;
  padding-bottom: ${({ $isLoading }) => $isLoading && '80px'};
  ${respondToWidth.sm`
    display: none;
  `};
`;

const Gallery = styled(ScrollbarContainer)`
  padding: 24px 10px;
  height: 100%;
  max-height: calc(100vh - 164px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;

const ActiveBorder = styled.div<{ $isSelect?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0;
  border: ${({ theme: { colors }, $isSelect }) => $isSelect && `2px solid ${colors.primary[1]}`};
  border-radius: 12px;
  margin-bottom: 16px;
`;

const GalleryImageContainer = styled.div<{ $isSelect?: boolean }>`
  max-width: 282px;
  width: 100%;
  height: 160px;
  max-height: 160px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    height: 160px;
    min-height: 160px;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
    top: 0;
    bottom: 0;
    object-fit: cover;
    position: absolute;
    overflow: hidden;
    display: block;
  }
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
