import { Breadcrumbs, IconButton, StyledText } from 'UI';
import { CloseIcon, InformationIcon } from 'assets/icons';
import { routes } from 'constant/routes';
import React, { FC, useMemo } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type HeaderProps = {
  isStartedTest: boolean;
  showConfirmModal: () => void;
  onInfoIconClick: () => void;
};

const Header: FC<HeaderProps> = ({ isStartedTest, showConfirmModal, onInfoIconClick }) => {
  const packName = useAppSelector(selectors.flashcards.selectFlashcardsPackName);
  const flashcardPackResult = useAppSelector(selectors.flashcards.selectPackFlashcardsResult);

  const { colors } = useTheme();
  const estimatesList = useMemo(
    () => [
      { component: <Indicator />, count: flashcardPackResult?.total || 0, text: 'Total' },
      { component: <NoIndicator />, count: flashcardPackResult?.no || 0, text: 'No' },
      { component: <KindaIndicator />, count: flashcardPackResult?.kinda || 0, text: 'Kinda' },
      { component: <YesIndicator />, count: flashcardPackResult?.yes || 0, text: 'Yes' },
    ],
    [flashcardPackResult],
  );

  return (
    <Root>
      <Breadcrumbs
        title={packName || ''}
        pageName="Flashcards"
        routeName={routes.flashcardPacks}
        onClick={showConfirmModal}
      />
      <EstimatesContainer>
        <InfoIconButtonContainer onClick={onInfoIconClick}>
          <InformationIcon color={colors.neutrals[4]} />
        </InfoIconButtonContainer>
        {estimatesList.map((item, index) => (
          <Estimate key={index}>
            {item.component}
            <EstimateText>
              {item.count} {item.text}
            </EstimateText>
          </Estimate>
        ))}
        {isStartedTest && (
          <StyledIconButton
            onClick={showConfirmModal}
            iconComponent={<CloseIcon size={24} color={colors.primary[1]} />}
            text="End"
          />
        )}
      </EstimatesContainer>
    </Root>
  );
};

export default Header;

const StyledIconButton = styled(IconButton)`
  display: flex;
  width: 122px;
  gap: 6px;

  ${respondToWidth.ls`
     display: none;
  `}
`;

const EstimateText = styled(StyledText)`
  white-space: nowrap;
  ${({ theme: { typography } }) => typography.body_large_regular_16};
`;

const Indicator = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.neutrals[4]};
`;

const YesIndicator = styled(Indicator)`
  background-color: ${({ theme: { colors } }) => colors.system.green};
`;

const KindaIndicator = styled(Indicator)`
  background-color: ${({ theme: { colors } }) => colors.system.blue};
`;

const NoIndicator = styled(Indicator)`
  background-color: ${({ theme: { colors } }) => colors.system.red};
`;

const Estimate = styled.div`
  display: flex;
  align-items: center;
`;

const EstimatesContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  ${respondToWidth.sm`
    overflow-x: scroll;
    overflow-y: hidden;
    max-width:100%;
    padding: 21px 16px;
    width: 100%;
  `}

  ${respondToWidth.s`
    gap:10px;
  `}
`;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.ls`
    padding: 0 16px;
  `}

  ${respondToWidth.sm`
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100vw;
  `}
`;

const InfoIconButtonContainer = styled.button`
  padding: 0;
  margin: 0;
  height: 20px;
  border: none;
  outline: none;
  background-color: inherit;
`;
