import { OutputData } from '@editorjs/editorjs';
import { CustomerChapterNoteResponseDto } from 'api/generated';
import { useCallback, useMemo } from 'react';
import { replaceHtmlEntities } from 'utils';

enum BlockEnum {
  PARAGRAPH = 'paragraph',
  IMAGE = 'image',
  LIST = 'list',
}

const NOTE_TAG = 'div';

const openHTMLNoteTag = (id: string, text: string) =>
  `<${NOTE_TAG} id="${id}" class="note${text ? ' note-has-text' : ''}">`;
const closeHTMLNoteTag = () => `</${NOTE_TAG}>`;

const pastSubstring = (string: string, substring: string, index: number): string =>
  string.slice(0, index) + substring + string.slice(index, string.length);

const useContentWithNote = (content: OutputData | null, notes: CustomerChapterNoteResponseDto[] | null) => {
  const parseContent = useCallback((content: OutputData, notes: CustomerChapterNoteResponseDto[]): OutputData => {
    const parsedBlocks = [...content.blocks];
    let unusedNotes = [...notes];

    let isParsed = !unusedNotes.length;

    if (content.blocks)
      for (let i = 0; i < content.blocks.length; i++) {
        if (isParsed) {
          break;
        }

        let parsedData;
        const currentBlock = content.blocks[i];
        const sortedBlockNotes = [...notes]
          .filter((note) => note.blockId === currentBlock.id)
          .sort((a, b) => a.startIndex - b.startIndex);

        switch (currentBlock.type) {
          case BlockEnum.PARAGRAPH: {
            const currentText = currentBlock.data?.text;

            if (currentText) {
              const { parsedText } = parseText(currentText, sortedBlockNotes);

              parsedData = {
                ...parsedBlocks[i].data,
                text: parsedText,
              };
            }
            break;
          }
          case BlockEnum.IMAGE: {
            const currentText = currentBlock.data?.caption;

            if (currentText) {
              const { parsedText } = parseText(currentText, sortedBlockNotes);

              parsedData = {
                ...parsedBlocks[i].data,
                caption: parsedText,
              };
            } else {
              parsedData = parsedBlocks[i].data;
            }

            break;
          }
          case BlockEnum.LIST: {
            const items = currentBlock.data.items as Array<string>;
            const listText = items.join('<LIST_ITEM>');
            const { parsedText } = parseText(listText, sortedBlockNotes);

            const parsedItems = parsedText.split('<LIST_ITEM>');

            parsedData = {
              ...parsedBlocks[i].data,
              items: parsedItems,
            };
            break;
          }
          default: {
            break;
          }
        }

        const parsedBlock = { ...parsedBlocks[i], data: parsedData };

        unusedNotes = unusedNotes.filter((note) => note.id !== currentBlock.id);
        isParsed = !unusedNotes.length;
        parsedBlocks[i] = parsedBlock;
      }

    return { ...content, blocks: parsedBlocks };
  }, []);

  const parseText = useCallback((text: string, sortedBlockNotes: CustomerChapterNoteResponseDto[]) => {
    let symbolsCount = 0;
    let currentNoteIndex = 0;
    let currentNote = sortedBlockNotes[currentNoteIndex];

    let parsedText = replaceHtmlEntities(text);
    let textArray = parsedText.split('');
    let tagIsOpen = false;

    for (let i = 0; i < textArray.length; i++) {
      if (!currentNote) {
        return { parsedText };
      }

      const currentSymbol = textArray[i];

      if (currentSymbol === '<') {
        tagIsOpen = true;
        continue;
      }
      if (currentSymbol === '>') {
        tagIsOpen = false;
        continue;
      }
      if (tagIsOpen) {
        continue;
      }

      if (symbolsCount === currentNote.startIndex) {
        parsedText = pastSubstring(parsedText, openHTMLNoteTag(currentNote.id, currentNote.noteText || ''), i);
        textArray = parsedText.split('');

        i -= 1;
      }
      if (symbolsCount === currentNote.endIndex + 1) {
        parsedText = pastSubstring(parsedText, closeHTMLNoteTag(), i + 1);
        textArray = parsedText.split('');

        symbolsCount -= 1;
        currentNoteIndex += 1;
        currentNote = sortedBlockNotes[currentNoteIndex];
      }
      symbolsCount += 1;
    }

    return { parsedText };
  }, []);

  const parsedContent = useMemo(
    () => (notes?.length && content ? parseContent(content, notes) : content),
    [notes, content],
  );

  return parsedContent;
};

export default useContentWithNote;
