import { CloseNotificationIcon } from 'assets/icons';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

interface CloseButtonProps {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
}

const CloseButton = ({ closeToast }: CloseButtonProps) => (
  <i onClick={closeToast}>
    <CloseNotificationIcon />
  </i>
);

const ToastsContainer = () => {
  return (
    <StyledContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={CloseButton}
      style={{ minHeight: 24 }}
    />
  );
};

export default ToastsContainer;

const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    min-height: 44px;
    padding: 10px 12px;
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0;
    ${({ theme: { typography } }) => typography.body_basic_semibold_14};
    color: ${({ theme: { colors } }) => colors.neutrals[1]};
  }
  .Toastify__progress-bar {
    height: 2px;
  }
`;
