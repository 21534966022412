import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgErrorBigIcon: FC<SVGIconProps> = ({ size = 132, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m66 59.52 22.687-22.688 6.481 6.48L72.481 66l22.687 22.687-6.48 6.481L66 72.481 43.312 95.168l-6.48-6.48L59.519 66 36.832 43.312l6.48-6.48L66 59.519Z"
        fill="#EB4040"
      />
    </svg>
  );
};

const Memo = memo(SvgErrorBigIcon);
export default Memo;
