import { DirectionDto } from 'api/generated';
import { useMemo } from 'react';
import { selectors, useAppSelector } from 'store';
import { Theme } from 'styles/themes';
import { appDefaultColors } from 'styles/themes/appDefaultColors';

const useThemeMode = (direction: DirectionDto | null) => {
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  const primaryColor = direction?.mainColorHex || '#DF6438';
  const theme = useMemo(
    () =>
      new Theme(primaryColor, isDarkMode ? appDefaultColors.dark : appDefaultColors.default, {
        old: direction?.oldColorHex || '',
      }),
    [primaryColor, isDarkMode],
  );

  return theme;
};

export default useThemeMode;
