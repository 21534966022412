import { ConfirmModal, PointsResultModal } from 'UI';
import MaximumNumberPointsModal from 'UI/Modals/MaximumNumberPointsModal';
import { PaymentDtoLevelEnum } from 'api/generated';
import TestFooter from 'components/TestFooter';
import TestHeader from 'components/TestHeader';
import { accessLevels } from 'constant';
import { MAXIMUM_COURSE_POINTS } from 'constant/maximumPoints';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications, useQuery } from 'hooks';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import TutorTestContent from './components/TutorTestContent';

type FormType = {
  id: string;
};

enum TestModalEnum {
  END_TEST,
  RESULT,
  MAXIMUM_POINTS,
}

const TutorTest = () => {
  const dispatch = useAppDispatch();
  const { errorToast } = useNotifications();
  const navigate = useNavigate();
  const { colors } = useTheme();
  const query = useQuery();

  const chapterIndex = query.get('chapterIndex');
  const quizId = query.get('quizId');

  const tutorTest = useAppSelector(selectors.tests.selectTutorTest);
  const tutorTestAnswers = useAppSelector(selectors.tests.selectTutorTestAnswers);
  const activeIndex = useAppSelector(selectors.tests.selectCurrentQuestionIndex) || 0;
  const testCompleteResult = useAppSelector(selectors.tests.selectCurrentTestPointsEarned);
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);

  const [currentModal, setCurrentModal] = useState<TestModalEnum | null>(null);
  const [isShowExplanation, setIsShowExplanation] = useState(false);
  const [select, setSelect] = useState<string>();
  const [isNextQuestion, setIsNextQuestion] = useState(false);
  const [isDisabled, setIsDisabled] = useState(Boolean(tutorTestAnswers[activeIndex]?.id) || false);
  const [saveAnswerAction, isSaveLoading] = useAsyncAction(actions.tests.saveAnswer);
  const [finishTestAction, isClosedTestLoading] = useAsyncAction(actions.tests.finishTest);
  const [getCourseStatisticAction, isStatisticLoading] = useAsyncAction(actions.performance.getCourseStatistic);
  const [isResultLoading, setIsResultLoading] = useState(false);
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const paymentLevel = useAppSelector(selectors.courses.selectCurrentPaymentLevel);
  const isGoldPayment = paymentLevel === PaymentDtoLevelEnum.Gold;
  const isShowStreakEnabled = useAppSelector(selectors.settings.showStreaks);

  const points = testCompleteResult || 0;
  const { hasPerformance } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  const methods = useForm<FormType>({
    mode: 'onChange',
    defaultValues: { id: '' },
  });

  const setInitialSelect = () => {
    setSelect(tutorTestAnswers[activeIndex]?.id || '');
    if (tutorTestAnswers[activeIndex]?.id) {
      setIsShowExplanation(true);
    }
  };

  useEffect(setInitialSelect, [tutorTestAnswers, activeIndex]);

  const saveAnswer = async (questionId: string, optionId: string) => {
    try {
      if (!tutorTest) {
        throw '';
      }

      await saveAnswerAction({ body: { optionId, questionId }, isTutor: true, passageId: tutorTest.id });
    } catch {
      errorToast('Something went wrong');
    }
  };

  const getClosedTest = async () => {
    try {
      if (!tutorTest) {
        throw '';
      }

      const response = await finishTestAction({ passageId: tutorTest.id });
      return response;
    } catch {
      setIsResultLoading(false);
      errorToast('Something went wrong');
    }
  };

  const getCourseStatistic = async () => {
    if (isPause) {
      return;
    }
    try {
      const response = await getCourseStatisticAction();
      return response;
    } catch (error) {
      errorToast('Something went wrong');
    } finally {
      setIsResultLoading(false);
    }
  };

  const endTest = async () => {
    setIsResultLoading(true);

    await getClosedTest();

    if (isGoldPayment && isShowStreakEnabled) {
      if (hasPerformance) {
        await openFinishModalWithGoldSubscription();
      } else {
        setCurrentModal(TestModalEnum.RESULT);
      }
    } else {
      closeFinishModal();
    }
  };

  const nextQuestion = () => {
    const canSwitchToNext = tutorTest && activeIndex < tutorTest?.questions.length - 1;

    if (canSwitchToNext) {
      dispatch(actions.tests.setCurrentQuestionIndex(activeIndex + 1));
      setIsShowExplanation(false);
      setIsDisabled(false);
      setIsNextQuestion(true);
      methods.reset();
    } else {
      endTest();
    }
  };

  const prevQuestion = () => {
    const isNotFirst = activeIndex > 0;

    if (isNotFirst) {
      dispatch(actions.tests.setCurrentQuestionIndex(activeIndex - 1));
      setIsShowExplanation(true);
      setIsNextQuestion(false);
      setIsDisabled(true);
    }
  };

  const openFinishModalWithGoldSubscription = async () => {
    const { totalInPoints } = (await getCourseStatistic()) || {};

    if (totalInPoints === MAXIMUM_COURSE_POINTS) {
      setCurrentModal(TestModalEnum.MAXIMUM_POINTS);
    } else {
      setCurrentModal(TestModalEnum.RESULT);
    }
  };

  const closeFinishModal = () => {
    setCurrentModal(null);

    const query = chapterIndex ? `?chapterIndex=${chapterIndex}&quizId=${quizId}` : '';

    navigate(`${routes.testResult}${query}`);
  };

  const showEndTestModal = () => {
    setCurrentModal(TestModalEnum.END_TEST);
  };

  const onSubmit = (data: FormType) => {
    const currentQuestion = tutorTest?.questions[activeIndex];

    saveAnswer(currentQuestion?.id || '', data.id);
    setSelect(data.id);
    setIsDisabled(true);
    setIsNextQuestion(false);
    setIsShowExplanation(true);
  };

  const isLastQuestion = activeIndex + 1 === tutorTest?.questions.length || 0;

  const buttonText =
    isLastQuestion && isShowExplanation ? 'Finish test' : isShowExplanation ? 'Next question' : 'Confirm';
  const isEarned = (testCompleteResult || 0) > 0;

  return (
    <Container>
      <TestHeader
        activeIndex={activeIndex + 1}
        totalCount={tutorTest?.questions.length || 0}
        endTest={showEndTestModal}
      />
      <FormProvider {...methods}>
        <Main onSubmit={methods.handleSubmit(onSubmit)}>
          <TutorTestContent
            question={tutorTest?.questions[activeIndex]}
            onNextClick={nextQuestion}
            isShowExplanation={isShowExplanation}
            isDisabled={isDisabled}
            select={select}
            isLoading={isSaveLoading || isResultLoading}
            isNextQuestion={isNextQuestion}
            correctId={tutorTestAnswers[activeIndex]?.correctOptionId || ''}
          />
          <TestFooter
            onEndClick={endTest}
            isLoading={isSaveLoading}
            onButtonClick={() => isShowExplanation && nextQuestion()}
            buttonText={buttonText}
            type={isShowExplanation ? 'button' : 'submit'}
            isDisabledButton={!methods.formState.isValid && !isShowExplanation}
            onPrevClick={prevQuestion}
            hintText={tutorTest?.questions[activeIndex].hint}
          />
        </Main>
      </FormProvider>
      <ConfirmModal
        title="Do you want to end the test?"
        text="You won't be able to resume it"
        textColor={colors.system.red}
        isOpen={currentModal === TestModalEnum.END_TEST}
        onClose={() => setCurrentModal(null)}
        onRightButtonClick={() => setCurrentModal(null)}
        onLeftButtonClick={endTest}
        isLeftButtonLoading={isClosedTestLoading || isStatisticLoading}
      />
      <PointsResultModal
        isOpen={currentModal === TestModalEnum.RESULT}
        onClose={closeFinishModal}
        title={isEarned ? 'Congratulation!' : 'Oops...'}
        subtitle={isEarned ? undefined : ' You have lost'}
        isEarned={isEarned}
        onLinkClick={() => navigate(routes.performance)}
        points={Math.abs(points)}
      />
      <MaximumNumberPointsModal
        isOpen={currentModal === TestModalEnum.MAXIMUM_POINTS}
        onClose={closeFinishModal}
        points={Math.abs(points)}
      />
    </Container>
  );
};

export default TutorTest;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 92px 1fr;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};

  ${respondToWidth.sm`
    grid-template-rows: 47px 1fr;
  `};
`;

const Main = styled.form`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  grid-template-rows: 1fr 92px;

  ${respondToWidth.sm`
    grid-template-rows: 1fr 68px;
  `}
`;
