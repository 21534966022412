import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectTableOfGuideContent = (state: RootState) => state.studyGuide.tableOfGuideContent;
export const selectCurrentChapterId = (state: RootState) => state.studyGuide.currentChapter?.id;

export const selectors = {
  selectStudyGuide: (state: RootState) => state.studyGuide.studyGuide,
  selectCurrentChapterIndex: createSelector(
    [selectTableOfGuideContent, selectCurrentChapterId],
    (tableOfGuideContent, chapterId) => tableOfGuideContent?.chapters.findIndex((chapter) => chapter.id === chapterId),
  ),
  selectTableOfGuideContent: (state: RootState) => state.studyGuide.tableOfGuideContent,
  selectCurrentChapter: (state: RootState) => state.studyGuide.currentChapter,
  selectNotes: (state: RootState) => state.studyGuide.notes,
  selectIsSelectingMode: (state: RootState) => state.studyGuide.isSelectingMode,
  selectSelectedNoteId: (state: RootState) => state.studyGuide.selectedNoteId,
  foundGuideText: (state: RootState) => state.studyGuide.foundText,
};
