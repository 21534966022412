import { useOnClickOutside } from 'hooks';
import React, { ReactNode, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Position } from '../../types';

type Props = {
  onClose: () => void;
  children: ReactNode | ReactNode[];
  position: Position;
};

export const Menu: React.FC<Props> = ({ onClose, children, position }) => {
  const ref = useRef(null);

  useOnClickOutside(ref, onClose);

  return (
    <Root ref={ref} $position={position}>
      {children}
    </Root>
  );
};

const Root = styled.div<{ $position: Position }>`
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[11]};
  justify-content: start;
  align-items: start;
  position: absolute;
  z-index: 1000;
  overflow-y: scroll;

  ${({ $position }) => {
    switch ($position) {
      case 'right-top':
        return css`
          right: 0;
          bottom: 60px;
        `;
    }
  }}
`;
