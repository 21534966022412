import Button from 'UI/Button';
import Input from 'UI/Input';
import PriceLabel from 'UI/PriceLabel';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import StyledText from 'UI/StyledText';
import { SubscribeDtoTypeEnum } from 'api/generated';
import { levelMapping } from 'constant';
import React, { FC } from 'react';
import { Controller, ErrorOption, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { validations } from 'utils';

import DefaultModal from '../DefaultModal';
import { DefaultModalProps } from '../DefaultModal/DefaultModal';

export type CreateAccountModalProps = {
  subscribeType: SubscribeDtoTypeEnum;
  siteUrl: string;
  currentPrice: number;
  oldPrice: number;
  term: TermEnum;
  isLoading?: boolean;
  openSupport?: () => void;
  signUpByEmail?: (email: string) => Promise<void>;
} & DefaultModalProps;

type FormValuesType = {
  email: string;
};

const CreateAccountModal: FC<CreateAccountModalProps> = ({
  subscribeType,
  term,
  siteUrl,
  signUpByEmail,
  openSupport,
  currentPrice,
  oldPrice,
  isLoading,
  ...props
}) => {
  const subscribeTypeString = levelMapping[subscribeType];
  const { handleSubmit, control, setError } = useForm<FormValuesType>({ defaultValues: { email: '' } });

  const onSubmit = async (data: FormValuesType) => {
    try {
      await signUpByEmail?.(data.email);
    } catch (error) {
      setError('email', error as ErrorOption);
    }
  };

  return (
    <DefaultModal
      {...props}
      title="Create an account to purchase"
      headerContainerCSS={headerContainerCSS}
      containerCSS={containerCSS}
      titleCSS={titleCSS}
      modalCSS={modalCSS}
      closeButtonCSS={closeButtonCSS}>
      <Wrapper>
        <Title as="h3">
          Purchase of a <TitleSpan>{subscribeTypeString}</TitleSpan>
          subscription
          <TextWrapper>
            for
            <PriceLabel term={term} currentPrice={currentPrice} oldPrice={oldPrice} />
          </TextWrapper>
        </Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: { value: true, message: 'Required field' },
              validate: validations.email,
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                value={field.value}
                onChange={field.onChange}
                label="Email"
                error={error?.message}
                maxLength={155}
              />
            )}
          />
          <Footer>
            <ButtonsContainer>
              <Button isLoading={isLoading} type="submit" variant="primary">
                Create an account
              </Button>
              <a target="_blank" href={siteUrl} rel="noreferrer">
                <Button disabled={isLoading} type="button" variant="secondary">
                  I already have an account. Sign in
                </Button>
              </a>
            </ButtonsContainer>
            <Text as="p">
              By continuing, you agree to the{' '}
              <button disabled={isLoading} onClick={openSupport}>
                <Link font="body_basic_medium_14"> terms of service</Link>
              </button>
            </Text>
          </Footer>
        </Form>
      </Wrapper>
    </DefaultModal>
  );
};

export default CreateAccountModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${respondToWidth.s`
    gap: 32px;
  `};
`;

const modalCSS = css`
  width: 100%;
  max-height: 100%;
  max-width: 668px;
  overflow-y: auto;
`;

const containerCSS = css`
  ${respondToWidth.sm`
    padding: 40px 40px;

  `};
  ${respondToWidth.s`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 16px;
    height: 100vh;
    border-radius: 0;
  `};
`;

const headerContainerCSS = css`
  ${respondToWidth.s`
    margin-bottom: 28px;
  `};
`;

const closeButtonCSS = css`
  ${respondToWidth.sm`
    top: 40px;
    right: 16px;
  `};
`;

const titleCSS = css`
  padding-inline: 93px 95px;

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.title_2_bold_32};
  `};

  ${({ theme: { typography, colors } }) => respondToWidth.s`
    width: 100%;
    text-align: center;
    color: ${colors.primary[1]};
    ${typography.title_2_bold_32};
  `};

  ${respondToWidth.sm`
    padding-inline: 0 14px;
  `};

  ${respondToWidth.s`
    padding-right: 0;
  `};
`;

const Text = styled(StyledText)`
  text-align: center;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[4]};
    ${typography.body_basic_medium_14}

    ${respondToWidth.s`
        ${typography.footnote_semibold_12}
    `}
  `};
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

const Title = styled(StyledText)`
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 4px;
  white-space: nowrap;
  flex-wrap: wrap;
  user-select: none;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_large_medium_16}
  `};
`;

const TitleSpan = styled(Title)`
  color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 20px;

  ${respondToWidth.s`
      grid-gap: 12px;
  `}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 52px;

  ${respondToWidth.s`
      gap: 56px;
  `}
`;

const Footer = styled.div`
  display: grid;
  grid-gap: 28px;

  ${respondToWidth.s`
      grid-gap: 16px;
  `}
`;

const Link = styled(StyledText)`
  cursor: pointer;
  text-decoration: underline !important;
  text-underline-offset: 4px;

  color: ${({ theme: { colors } }) => colors.primary[1]};
`;
