export const pauseOptions = [
  {
    id: '1',
    title: 'Pause for 1 month',
    active: false,
  },
  {
    id: '2',
    title: 'Pause for 2 months',
    active: false,
  },
  {
    id: '3',
    title: 'Pause for 3 months',
    active: false,
  },
];
