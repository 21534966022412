import { createSlice } from '@reduxjs/toolkit';
import { PaginationMetaDto, PlanPhaseDto, PlansDto } from 'api/generated';

import { changeCurrentPlanThunk, getPlanPhasesThunk, getPlansThunk, markTaskThunk, unMarkTaskThunk } from './actions';

interface UserSlice {
  plans: PlansDto | null;
  phases: Array<PlanPhaseDto> | null;
  meta: PaginationMetaDto | null;
}

const initialState: UserSlice = {
  plans: null,
  phases: null,
  meta: null,
};

export const studyPlansSlice = createSlice({
  name: 'studyPlans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlansThunk.fulfilled, (state, { payload }) => {
        state.plans = payload;
      })
      .addCase(changeCurrentPlanThunk.pending, (state) => {
        state.phases = [];
      })
      .addCase(changeCurrentPlanThunk.fulfilled, (state, { payload }) => {
        if (state.plans) {
          state.plans.currentPlanId = payload.newPlanId;
        }
      })
      .addCase(getPlanPhasesThunk.fulfilled, (state, { payload }) => {
        const phases = state.phases || [];
        state.phases = payload.isFirstFetch ? payload.data.items : [...phases, ...payload.data.items];
        state.meta = payload.data.meta;
      })
      .addCase(markTaskThunk.fulfilled, (state, { payload }) => {
        const phase = state.phases?.find((phase) => phase.id === payload.phaseId);

        if (state.phases && phase) {
          const updatedPhase = {
            ...phase,
            tasks: phase.tasks.map((task) => (task.id === payload.taskId ? { ...task, isMarked: true } : task)),
          };

          state.phases = state.phases.map((phase) => (phase.id === payload.phaseId ? updatedPhase : phase));
        }
      })
      .addCase(unMarkTaskThunk.fulfilled, (state, { payload }) => {
        const phase = state.phases?.find((phase) => phase.id === payload.phaseId);

        if (state.phases && phase) {
          const updatedPhase = {
            ...phase,
            tasks: phase.tasks.map((task) => (task.id === payload.taskId ? { ...task, isMarked: false } : task)),
          };

          state.phases = state.phases.map((phase) => (phase.id === payload.phaseId ? updatedPhase : phase));
        }
      });
  },
});

export const actions = {
  ...studyPlansSlice.actions,
  getPlans: getPlansThunk,
  changeCurrentPlan: changeCurrentPlanThunk,
  getPlanPhases: getPlanPhasesThunk,
  markTask: markTaskThunk,
  unMarkTask: unMarkTaskThunk,
};

export const { reducer } = studyPlansSlice;
