import { MvpModal } from 'components';
import React from 'react';
import { selectors, useAppSelector } from 'store';
import styled from 'styled-components';

enum IframeEvents {
  HIDE = 'hide',
}

const SubscribeToCourse = () => {
  const direction = useAppSelector(selectors.direction.selectDirection);
  const postMessage = (event: IframeEvents) => {
    parent.postMessage(event, direction?.landingUrl || '');
  };

  const closeModals = () => {
    postMessage(IframeEvents.HIDE);
  };
  return (
    <Root>
      <MvpModal onClose={closeModals} />
    </Root>
  );
};

export default SubscribeToCourse;

const Root = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;
