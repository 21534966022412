import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgSuccessIconWhite: FC<SVGIconProps> = ({ size = 132, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.125 7.25L5.5 11.625L15.5 1.625"
        stroke="white"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Memo = memo(SvgSuccessIconWhite);
export default Memo;
