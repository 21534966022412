import React from 'react';
import { selectors, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

const Header = () => {
  const direction = useAppSelector(selectors.direction.selectDirection);

  return (
    <Root>
      <LogoContainer>
        <LogoImg src={direction?.logoUrl} />
      </LogoContainer>
    </Root>
  );
};

export default Header;

export const Root = styled.header`
  width: 100%;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
`;

export const LogoContainer = styled.div`
  display: grid;
  place-items: center;
  height: 40px;

  svg {
    width: 52px;
    height: 25px;
  }

  ${respondToWidth.s`
    height: 32px;

    svg {
      height: 32px;
    }
  `}
`;

const LogoImg = styled.img`
  overflow: hidden;
  height: 100%;
  max-height: 65px;
`;
