import styled from 'styled-components';

export type VariantBarControl = 'show' | 'hide';

export const StyledButton = styled.button<{ $variant: VariantBarControl }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  background-color: inherit;
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[8]};
  border-radius: 50%;
  cursor: pointer;
  transform: ${({ $variant }) => $variant === 'hide' && 'scale(-1,1)'};
`;
