import { StyledText } from 'UI';
import { AppTextProps } from 'UI/StyledText/StyledText';
import { PaymentDto, PaymentDtoTypeEnum } from 'api/generated';
import React, { FC } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type TrialTextProps = {
  startTrial: () => void;
  startUnsubscribeProcess: () => void;
  cancelSubscriptionDowngrade: () => Promise<void>;
  currentPayment?: PaymentDto;
  isTrialStarting: boolean;
  isDowngrade: boolean;
};

const TrialText: FC<TrialTextProps> = ({
  startTrial,
  startUnsubscribeProcess,
  cancelSubscriptionDowngrade,
  currentPayment,
  isTrialStarting,
  isDowngrade,
}) => {
  const disabled = useAppSelector(selectors.courses.selectDisabledStatus);
  const isGoldPurchase = currentPayment?.type === PaymentDtoTypeEnum.Purchase;
  const isSubscription = currentPayment?.type === PaymentDtoTypeEnum.Subscription;
  const isTrialVersion = currentPayment?.type === PaymentDtoTypeEnum.Free;

  const handleStartClick = () => {
    if (isSubscription) {
      startUnsubscribeProcess();
    } else {
      startTrial();
    }
  };

  const handleCancelDowngradeClick = () => {
    cancelSubscriptionDowngrade();
  };

  if (isGoldPurchase) {
    return null;
  }

  if (isDowngrade) {
    return (
      <Button disabled={isTrialStarting || (!isDowngrade && disabled)} onClick={handleCancelDowngradeClick}>
        <Caption as="p" font="title_4_medium_24">
          Cancel downgrade for trial version
        </Caption>
      </Button>
    );
  }

  if (isTrialVersion) {
    return (
      <Caption as="p" font="title_4_medium_24">
        You are using the trial version
      </Caption>
    );
  }
  return (
    <Button disabled={isTrialStarting || disabled} onClick={handleStartClick}>
      <Caption as="p" font="title_4_medium_24">
        Start trial
      </Caption>
    </Button>
  );
};

export default TrialText;

const Caption = styled(StyledText)<AppTextProps>`
  ${({ theme: { typography, colors } }) => css`
    text-align: center;
    width: 100%;

    color: ${colors.neutrals[5]};
    ${typography.title_4_medium_24};

    ${respondToWidth.sm`
      ${typography.body_large_semibold_16}
    `}
  `};
`;

const Button = styled.button`
  ${({ theme: { colors } }) => css`
    justify-self: center;
    width: fit-content;
    margin: 0 auto;

    p {
      color: ${colors.primary[1]} !important;
    }

    ${respondToWidth.sm`
        width: 100%;
    `}
  `};
`;
