import { StyledText } from 'UI';
import { useGetPerformanceModalColor } from 'hooks';
import React, { FC } from 'react';
import styled from 'styled-components';

type StatisticItemProps = {
  title: string;
  percent: number;
  count: number;
  isColorReverse?: boolean;
};

const StatisticItem: FC<StatisticItemProps> = ({ title, percent, count, isColorReverse }) => {
  const color = useGetPerformanceModalColor(percent, isColorReverse);
  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <TextWrapper>
          <Percent>{percent}%</Percent>
          <Count>{count}</Count>
        </TextWrapper>
      </TextContainer>
      <IndicatorContainer>
        <ProgressIndicator $color={color} $value={percent} />
      </IndicatorContainer>
    </Container>
  );
};

export default StatisticItem;

type IndicatorProps = {
  $value: number;
  $color: string;
};

const Container = styled.div`
  width: 100%;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => typography.subhead_medium_13}
`;
const Percent = styled(StyledText)`
  ${({ theme: { typography } }) => typography.subhead_semibold_13}
`;
const Count = styled(StyledText)`
  ${({ theme: { typography } }) => typography.subhead_regular_13}
`;

const IndicatorContainer = styled.div`
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[9]};
  overflow: hidden;
`;

const ProgressIndicator = styled.div<IndicatorProps>`
  width: ${({ $value }) => `${$value}%`};
  transition: width 0.8s linear;
  height: 100%;
  border-radius: 6px;
  background-color: ${({ $color }) => $color};
`;
