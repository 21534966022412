import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { CreateReportDto } from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType } from 'types';

export const setReportThunk = createAsyncThunk<undefined, CreateReportDto, ExtraParamsThunkType<DefaultRejectValue>>(
  'report/setReport',
  async (body, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Report.reportsControllerCreateReport(body);
      if (status > 399) {
        throw data;
      }
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const actions = {
  setReport: setReportThunk,
};
