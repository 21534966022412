import { TextButton } from 'UI';
import { HowToProgressCalcModal } from 'UI/Modals';
import StyledText from 'UI/StyledText';
import loseAudio from 'assets/audio/loseAudio.mp3';
import triumphAudio from 'assets/audio/triumphAudio.mp3';
import exam from 'assets/images/exam.png';
import progressGreen from 'assets/json//progress_green.json';
import { MAXIMUM_COURSE_POINTS } from 'constant/maximumPoints';
import { useGetProgressColor } from 'hooks';
import { useSoundController } from 'hooks';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import React, { FC, memo, useDeferredValue, useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { getPoints } from 'utils';

type ProgressProps = {
  className?: string;
};

const Progress: FC<ProgressProps> = ({ className }) => {
  const { colors } = useTheme();
  const dispatch = useAppDispatch();

  const [isIncreased, setIsIncreased] = useState<boolean | null>(null);
  const isIncreasedState = useAppSelector(selectors.performance.selectIsPointsIncreased);
  const [count, setCount] = useState(0);

  const { playSound } = useSoundController(isIncreased ? triumphAudio : loseAudio);
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  const coursePoints = useAppSelector(selectors.performance.selectCourseStatistic);

  const [isShowModal, setIsShowModal] = useState(false);

  const points = coursePoints?.totalInPoints || 0;
  const deferredPoints = useDeferredValue(points);

  const value = (deferredPoints / MAXIMUM_COURSE_POINTS) * 100;

  const color = useGetProgressColor(value);

  const progressText = {
    [colors.system.red]: "Embark, learn, and grow; each step today shapes tomorrow's success!",
    [colors.system.yellow]:
      'Approaching the finish line, embrace your growth and you will ace that exam with conviction!',
    [colors.system.green]:
      'Congratulations on your readiness! Confidently stride into the exam, knowing success awaits you!',
  };

  const animateCount = () => {
    const roundCount = Math.round(count);
    const roundDeferredPoints = Math.round(deferredPoints);

    if (roundCount >= roundDeferredPoints) {
      setCount(deferredPoints);
    } else {
      setTimeout(() => {
        setCount((prev) => prev + deferredPoints / 60);
      }, 1000 / 60);
    }
  };

  const animateFlush = () => {
    if (typeof isIncreased === 'boolean') {
      playSound();
      if (isIncreased) {
        lottieRef.current?.goToAndPlay(0);
      } else {
        lottieRef.current?.goToAndStop(0);
      }
    }
  };

  useEffect(animateCount, [count, deferredPoints]);
  useEffect(animateFlush, [deferredPoints]);

  useEffect(() => {
    setIsIncreased(isIncreasedState);
  }, [isIncreasedState]);

  const clearPointIncreased = () => {
    dispatch(actions.performance.clearPointIncreased());
  };

  useEffect(() => {
    return clearPointIncreased;
  }, []);

  return (
    <Container>
      <Wrapper className={className}>
        <AnimationContainer>
          <Lottie animationData={progressGreen} lottieRef={lottieRef} loop={false} />
        </AnimationContainer>
        <ProgressContainer>
          <Scale src={exam} alt="Exam scale" />
          <CircularProgressbar
            value={value}
            circleRatio={0.5}
            strokeWidth={10}
            styles={buildStyles({
              trailColor: colors.neutrals[9],
              strokeLinecap: 'round',
              pathColor: color,
              rotation: 0.75,
              pathTransitionDuration: 0.8,
            })}
          />
          <Legends>
            <Count $color={color}>{getPoints(count)}</Count>
            <Text>points out of {getPoints(MAXIMUM_COURSE_POINTS)}</Text>
          </Legends>
        </ProgressContainer>
      </Wrapper>
      <Title>Exam Confidence</Title>
      <Description>{progressText[color]}</Description>
      <StyledTextButton text="How is progress calculated?" onClick={() => setIsShowModal(true)} />
      <HowToProgressCalcModal isOpen={isShowModal} onClose={() => setIsShowModal(false)} />
    </Container>
  );
};

export default memo(Progress);

const Scale = styled.img`
  position: absolute;
  top: 37px;
  left: 38px;
  width: 194px;
`;

const Legends = styled.div`
  position: absolute;
  top: auto;
  bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  min-width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  padding-bottom: 40px;
  padding-top: 2px;

  ${respondToWidth.s`
    padding-bottom: 32px;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

const AnimationContainer = styled.div`
  position: absolute;
  top: 0;
  left: -15%;
  width: 130%;
`;

const ProgressContainer = styled.div`
  width: 90%;
  height: 170px;
  margin-top: 80px;
  position: relative;
  z-index: 1;
  svg {
    width: 100%;
  }
`;

const Count = styled(StyledText)<{ $color: string }>`
  z-index: 1;
  color: ${({ $color }) => $color};
  ${({ theme: { typography } }) => typography.title_2_bold_32};
`;

const Text = styled(StyledText)`
  padding-top: 10px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};
`;

const Title = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  text-align: center;
  margin-bottom: 20px;

  ${({ theme: { typography } }) => css`
    ${typography.title_4_bold_24};

    ${respondToWidth.sm`
      margin-bottom:8px;
      ${typography.headline_semibold_18}`}
  `}
`;

const Description = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[5]};
  text-align: center;
  margin-bottom: 36px;
  max-width: 240px;
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${({ theme: { typography } }) => respondToWidth.s` 
    margin-bottom:20px;
    ${typography.footnote_semibold_12}`};
`;

const StyledTextButton = styled(TextButton)`
  z-index: 2;
`;
