import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import TextButton from 'UI/TextButton';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type EmailConfirmationModalProps = {
  onSendClick?: () => void;
  email?: string;
  isLoading?: boolean;
} & BaseModalProps;

const EmailConfirmationModal: FC<EmailConfirmationModalProps> = ({
  onClose,
  onSendClick,
  email,
  isLoading,
  ...props
}) => {
  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      title="Email confirmation"
      containerCSS={ContainerCSS}
      showCloseButton={false}
      modalCSS={ModalCSS}
      isMobileOverlayStyles>
      <Container>
        <Wrapper>
          <Text>
            To confirm the change of email, follow the link that we sent to your new specified mail{' '}
            <ExampleText>{email}</ExampleText>
          </Text>
          <StyledTextButton text="Send again" onClick={onSendClick} />
        </Wrapper>
        <Button variant="primary" size="middle" isLoading={isLoading} onClick={onClose}>
          Close
        </Button>
      </Container>
    </DefaultModal>
  );
};

export default EmailConfirmationModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;
  flex-grow: 1;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Text = styled(StyledText)`
  margin-bottom: 32px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${respondToWidth.s`
    text-align: start;
  margin-bottom: 24px;

  `}
`;

const ExampleText = styled(Text)`
  display: inline;
  color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;

  ${respondToWidth.s`
    margin-bottom: auto;
  `}
`;

const StyledTextButton = styled(TextButton)`
  ${respondToWidth.s`
  justify-content: start;
  `}
`;
