import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgFlashlightIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.333 13.333h7.807a.8.8 0 0 1 .657 1.256L15.213 29.877a.3.3 0 0 1-.546-.17v-11.04H6.86a.8.8 0 0 1-.658-1.256L16.787 2.123a.3.3 0 0 1 .546.17v11.04Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgFlashlightIcon);
export default Memo;
