import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getCustomerSettingsThunk, toggleCustomerSoundThunk, toggleCustomerStreakThunk } from './actions';

export interface SettingsSlice {
  darkMode: boolean;
  turnOnSoundsInApp: boolean;
  showStreaks: boolean;
  inviteCourseId: string | null;
  prevStreakCount: number;
}

const initialState: SettingsSlice = {
  darkMode: false,
  turnOnSoundsInApp: true,
  showStreaks: true,
  inviteCourseId: null,
  prevStreakCount: 0,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setPrevStreakCount(state, action: PayloadAction<number>) {
      state.prevStreakCount = action.payload;
    },
    setInviteCourseId: (state, { payload }: PayloadAction<string | null>) => {
      state.inviteCourseId = payload;
    },
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerSettingsThunk.fulfilled, (state, { payload }) => {
      state.showStreaks = payload.showStreaks;
      state.turnOnSoundsInApp = payload.turnOnSoundsInApp;
    });
    builder.addCase(toggleCustomerStreakThunk.fulfilled, (state) => {
      state.showStreaks = !state.showStreaks;
    });
    builder.addCase(toggleCustomerSoundThunk.fulfilled, (state) => {
      state.turnOnSoundsInApp = !state.turnOnSoundsInApp;
    });
  },
});

export const actions = {
  ...settingsSlice.actions,
  getCustomerSettings: getCustomerSettingsThunk,
  toggleCustomerStreak: toggleCustomerStreakThunk,
  toggleCustomerSound: toggleCustomerSoundThunk,
};

export const { reducer } = settingsSlice;
