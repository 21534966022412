import { StyledText } from 'UI';
import { ErrorIcon, SuccessNotificationIcon } from 'assets/icons';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { selectors, useAppSelector } from 'store';
import styled from 'styled-components';

interface UndoProps {
  onUndo: () => void;
  closeToast?: () => void;
  text?: string;
}

const Undo = ({ onUndo, closeToast, text }: UndoProps) => {
  const [timer, setTimer] = useState(5);
  const handleClick = () => {
    onUndo();
    closeToast?.();
  };

  useEffect(() => {
    let timeoutId: number;
    if (timer) {
      timeoutId = window.setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
    return () => clearTimeout(timeoutId);
  }, [timer]);

  return (
    <UndoRoot>
      <UndoText>{text || 'Note deleted'}</UndoText>
      <StyledButton onClick={handleClick}>Undo({timer})</StyledButton>
    </UndoRoot>
  );
};

const useNotifications = () => {
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  const undoToast = ({ onUndo, text, timeout = 5000 }: { onUndo: () => void; text?: string; timeout?: number }) => {
    toast.success(<Undo onUndo={onUndo} text={text} />, {
      icon: <SuccessNotificationIcon />,
      closeButton: false,
      autoClose: timeout,
      pauseOnHover: false,
      theme: isDarkMode ? 'dark' : 'light',
    });
  };

  const successToast = (text: string) => {
    toast.success(text, {
      icon: <SuccessNotificationIcon />,
      hideProgressBar: true,
      theme: isDarkMode ? 'dark' : 'light',
    });
  };

  const errorToast = (text: string) => {
    toast.error(text, {
      icon: <ErrorIcon />,
      hideProgressBar: true,
      theme: isDarkMode ? 'dark' : 'light',
    });
  };

  return { undoToast, successToast, errorToast };
};

export default useNotifications;

const UndoRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const UndoText = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_semibold_14};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
`;

const StyledButton = styled.button`
  background-color: inherit;
  outline: none;
  border: none;
  padding: 0;
  color: ${({ theme: { colors } }) => colors.system.green};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};
`;
