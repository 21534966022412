import { ScrollbarContainer, StyledText } from 'UI';
import { PaymentDtoLevelEnum, PaymentDtoTypeEnum, PlanPhaseTaskDto } from 'api/generated';
import { accessLevels } from 'constant';
import { useAsyncAction, useClientSize, useInfinityScroll, useNotifications } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { Oval as Loader, ThreeDots } from 'react-loader-spinner';
import { actions, selectors, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { Phase } from './components';

type PhasesProps = {
  isPlanSelecting: boolean;
};

const Phases: FC<PhasesProps> = ({ isPlanSelecting }) => {
  const { colors } = useTheme();
  const { errorToast, successToast } = useNotifications();
  const { getIsBreakpoint } = useClientSize();
  const isWidthSm = getIsBreakpoint('sm');
  const [getCourseStatisticAction] = useAsyncAction(actions.performance.getCourseStatistic);
  const [getPlanPhasesAction, isPhasesLoading] = useAsyncAction(actions.studyPlans.getPlanPhases);
  const [markTaskAction, isMarking] = useAsyncAction(actions.studyPlans.markTask);
  const [unMarkTaskAction, isUnMarking] = useAsyncAction(actions.studyPlans.unMarkTask);
  const { currentPlanId } = useAppSelector(selectors.studyPlans.selectPlans) || {};
  const phases = useAppSelector(selectors.studyPlans.selectPhases) || [];
  const phasesMeta = useAppSelector(selectors.studyPlans.selectPhasesMeta);
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const streakCount = useAppSelector((store) => store.performance.courseStatistic?.streak) || 0;
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const addedStreakToday = useAppSelector(selectors.performance.selectAddedStreakToday);
  const paymentLevel = useAppSelector(selectors.courses.selectCurrentPaymentLevel);
  const paymentType = useAppSelector(selectors.courses.selectCurrentPaymentType);
  const isShowStreakEnabled = useAppSelector(selectors.settings.showStreaks);
  const isGoldPaymentOrTrial = paymentLevel === PaymentDtoLevelEnum.Gold || paymentType === PaymentDtoTypeEnum.Free;
  const isShowStreak = isGoldPaymentOrTrial && isShowStreakEnabled && !addedStreakToday;
  /**
   * this state provides immediately toggle action for the clicked checkbox
   */
  const [checkedTask, setCheckedTask] = useState<PlanPhaseTaskDto | undefined>(undefined);

  const getPlanPhases = async (planId: string, nextPage?: number) => {
    try {
      await getPlanPhasesAction({ planId, page: nextPage });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    currentPlanId && getPlanPhases(currentPlanId);
  }, [currentPlanId]);

  const { hasPerformance } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  const getToastPoint = (newPoints: number, isMarkTask?: boolean) => {
    const roundedPoints = Math.round(newPoints);
    if (isMarkTask) {
      successToast(`You have earned ${roundedPoints} points`);
    } else {
      errorToast(`You have lost ${Math.abs(roundedPoints)} points`);
    }
  };

  const getCourseStatistic = async (isMarkTask?: boolean) => {
    if (hasPerformance && !isPause) {
      try {
        const response = await getCourseStatisticAction();
        isWidthSm && getToastPoint(response.tasksPoints.taskWeightInPoints, isMarkTask);
      } catch (error) {
        errorToast('Something went wrong');
      }
    }
  };

  useEffect(() => {
    getCourseStatistic();
  }, []);

  const hasPhasesMetaMore = (phasesMeta?.currentPage || 0) < (phasesMeta?.totalPages || 0);
  const nextPage = (phasesMeta?.currentPage || 0) + 1;

  const isPhases = Boolean(phases.length);
  const isShowLoader = (!isPhases && isPhasesLoading) || isPlanSelecting;
  const canPhaseSelect = !isPlanSelecting && !isMarking && !isUnMarking;

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getPlanPhases(currentPlanId || '', nextPage),
    hasAfterMore: hasPhasesMetaMore,
    isLoading: isPhasesLoading,
  });

  const toggleMarkTask = (task: PlanPhaseTaskDto, phaseId: string) => {
    const { isMarked, id } = task;
    setCheckedTask({ ...task, isMarked: !isMarked });

    if (isMarking || isUnMarking) {
      return;
    }

    if (isMarked) {
      unMarkTask(id, phaseId);
    } else {
      if (isShowStreak) {
        successToast(`You are on a ${Number(streakCount) + 1} day study streak`);
      }
      markTask(id, phaseId);
    }
  };

  const unMarkTask = async (taskId: string, phaseId: string) => {
    try {
      await unMarkTaskAction({ taskId, phaseId });
      await getCourseStatistic(false);
    } catch (error) {
      setCheckedTask(undefined);
      errorToast('Something went wrong');
    }
  };

  const markTask = async (taskId: string, phaseId: string) => {
    try {
      await markTaskAction({ taskId, phaseId });
      await getCourseStatistic(true);
    } catch (error) {
      setCheckedTask(undefined);
      errorToast('Something went wrong');
    }
  };

  if (isShowLoader) {
    return (
      <Container>
        <Loader color={colors.primary[1]} secondaryColor="transparent" strokeWidth={4} />
      </Container>
    );
  }

  return (
    <StyledScrollContainer ref={listRef} onScroll={handleScroll}>
      {isPhases ? (
        <Wrapper>
          {phases.map((phase, index) => (
            <Phase
              key={phase.id}
              phase={phase}
              index={index}
              canPhaseSelect={canPhaseSelect}
              onClick={(task) => toggleMarkTask(task, phase.id)}
              checkedTask={checkedTask}
            />
          ))}
          {isPhasesLoading && (
            <LoaderContainer>
              <ThreeDots color={colors.primary[3]} />
            </LoaderContainer>
          )}
        </Wrapper>
      ) : (
        <Container>
          <StyledText>There are no tasks in the plan</StyledText>
        </Container>
      )}
    </StyledScrollContainer>
  );
};

export default Phases;

const StyledScrollContainer = styled(ScrollbarContainer)`
  height: 100%;
  position: relative;

  ${respondToWidth.sm`
    height: 300px;
  `}
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 100%;

  ${respondToWidth.sm`
    gap:16px;
  `}
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
