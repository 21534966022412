import Button from 'UI/Button';
import Input from 'UI/Input';
import StyledText from 'UI/StyledText';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type FormState = {
  email: string;
};

const ChangeInitPasswordModal: FC<BaseModalProps> = ({ onClose, ...props }) => {
  const { handleSubmit, control } = useForm<FormState>();

  const onSubmit = (data: FormState) => {
    console.log(data);
  };

  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      title="Change password"
      containerCSS={ContainerCSS}
      changeCloseButton
      modalCSS={ModalCSS}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Text>Enter your email, we will send a password to it</Text>
        <Controller control={control} name="email" render={({ field }) => <Input label="Email" {...field} />} />
        <StyledButton variant="primary" size="middle" type="submit">
          Confirm
        </StyledButton>
      </Form>
    </DefaultModal>
  );
};

export default ChangeInitPasswordModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  margin-bottom: 44px;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${respondToWidth.s`
    text-align: start;
    margin-bottom: 24px;
  `}
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledButton = styled(Button)`
  margin-top: 40px;

  ${respondToWidth.s`
    margin-top: auto;
  `}
`;
