import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgUnlockIcon: FC<SVGIconProps> = ({
  fill = '#848484',
  width = 16,
  height = 17,
  viewBox = '0 0 16 17',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox={viewBox} {...props}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M6.095 3.81a2.286 2.286 0 0 0-4.571 0A.762.762 0 0 1 0 3.81a3.809 3.809 0 1 1 7.619 0v2.285h6.095A2.286 2.286 0 0 1 16 8.381v6.095a2.286 2.286 0 0 1-2.286 2.286H3.048a2.286 2.286 0 0 1-2.286-2.286V8.381a2.286 2.286 0 0 1 2.286-2.286h3.047V3.81Zm3.81 6.857c0 .564-.307 1.056-.762 1.32v.965a.762.762 0 1 1-1.524 0v-.965a1.523 1.523 0 1 1 2.286-1.32Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgUnlockIcon);
export default Memo;
