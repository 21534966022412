import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { SubscribeToMarketingEmailsDto, SuccessResponseDto } from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType } from 'types';

export const subscribeToMarketingEmailsThunk = createAsyncThunk<
  SuccessResponseDto,
  SubscribeToMarketingEmailsDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('active-campaign/subscribe', async ({ courseId, email }, { rejectWithValue }) => {
  try {
    const { data } = await api.ActiveCampaign.activeCampaignControllerSubscribe({ courseId, email });

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
