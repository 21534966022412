import { GallerySwiper } from 'UI';
import { Loader } from 'components';
import { useAsyncAction, useNotifications } from 'hooks';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useSwiperControl } from 'hooks/useSwiperControl';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { Swiper as SwiperCommon, SwiperOptions } from 'swiper';
import { PaginationType } from 'types';

import ControlBar from '../ControlBar';
import VerticalSlider from '../VerticalSlider/VerticalSlider';

const firstSwiperOptions: SwiperOptions = {
  spaceBetween: 20,
  slidesPerView: 1,
};

const MIN_VALUE_FOR_NEXT_FETCH = 5;

const Sliders: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const [firstSwiper, setFirstSwiper] = useState<SwiperCommon>();
  const [secondSwiper, setSecondSwiper] = useState<SwiperCommon>();

  const sliderRef = useRef<HTMLDivElement>(null);
  const slideRef = useRef<HTMLDivElement>(null);

  const { getNextSlide, getPrevSlide, onClickSlide, activeIndex } = useSwiperControl({
    swipers: [firstSwiper, secondSwiper],
    slideRef,
    sliderRef,
  });
  const { isMobile } = useGetIsMobile();
  const { errorToast } = useNotifications();
  const { id } = useParams();

  const mnemonicCardsMeta = useAppSelector(selectors.mnemonicCards.selectMnemonicCardsPackMeta);
  const mnemonicCardPacks = useAppSelector(selectors.mnemonicCards.selectMnemonicCardsPack);

  const [getMnemonicCardsAction, isLoadingAfter] = useAsyncAction(actions.mnemonicCards.getMnemonicCardsPack);

  const getPackMnemonicCards = async (actionPayload?: PaginationType) => {
    try {
      if (id) {
        await getMnemonicCardsAction({ id, ...actionPayload });
      }
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const currentPage = mnemonicCardsMeta?.currentPage || 0;
  const nextPage = currentPage + 1;
  const canFetchAfter = mnemonicCardPacks.length - activeIndex === MIN_VALUE_FOR_NEXT_FETCH;

  const getAfterPackMnemonicCards = () => {
    if (canFetchAfter) {
      getPackMnemonicCards({ page: nextPage });
    }
  };

  useEffect(() => {
    getAfterPackMnemonicCards();
  }, [activeIndex]);

  const isCardsLoading = !mnemonicCardPacks.length && isLoading;

  return (
    <Wrapper $isMobile={isMobile}>
      <Main>
        {isCardsLoading ? (
          <Loader />
        ) : (
          <>
            <GallerySwiper
              data={mnemonicCardPacks}
              setSwiper={setFirstSwiper}
              swiperOptions={firstSwiperOptions}
              renderElement={(item) => (
                <MainImageContainer>
                  <img src={item.imageUrl} alt={item.title} />
                </MainImageContainer>
              )}
            />
            <ControlBar
              activeIndex={activeIndex}
              onNextClick={getNextSlide}
              onBackClick={getPrevSlide}
              amount={mnemonicCardsMeta?.totalItems || 0}
            />
          </>
        )}
      </Main>
      <VerticalSlider
        slideRef={slideRef}
        sliderRef={sliderRef}
        setSwiper={setSecondSwiper}
        onClickSlide={onClickSlide}
        activeIndex={activeIndex}
        cards={mnemonicCardPacks}
        getPackMnemonicCard={getPackMnemonicCards}
        isLoading={isLoading}
        isLoadingAfter={isLoadingAfter}
      />
    </Wrapper>
  );
};

export default Sliders;

const Wrapper = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  max-width: 1440px;
  height: calc(var(--vh, 1vh) * 100 - 92px);
  padding: 32px 40px 40px;
  display: ${({ $isMobile }) => ($isMobile ? 'none' : 'grid')};
  grid-template-columns: calc(100% - 332px) 322px;
  grid-template-areas: 'main container';
  margin-inline: auto;
  gap: 24px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};

  ${respondToWidth.xl`
    min-width: 100%;
  `}

  ${respondToWidth.ls`
    grid-template-columns: calc(100% - 250px) 250px;
    
  `}

  ${respondToWidth.sm`
      display: none;
  `};
`;

const Main = styled.div`
  width: 100%;
  max-width: 1014px;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  grid-area: main;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
`;

const MainImageContainer = styled.div`
  width: 100%;
  height: calc(100vh - 302px);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    height: 100%;
    border-radius: 12px;

    ${respondToWidth.ls`
      height:auto;
    `}
  }
`;
