import { ScrollbarContainer, StyledText, Tag } from 'UI';
import { useAsyncAction, useInfinityScroll, useNotifications, useQuery } from 'hooks';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { AnswerEnum, AnswerType, FlashcardsPackType } from 'types';

import { Card } from './components';

type AllCardsProps = {
  packType: FlashcardsPackType;
};

const AllCards: FC<AllCardsProps> = ({ packType }) => {
  const [selectedType, setSelectedType] = useState(AnswerEnum.ALL);
  const query = useQuery();
  const packId = query.get('packId');

  const { colors } = useTheme();
  const { errorToast } = useNotifications();

  const dispatch = useAppDispatch();
  const packFlashcards = useAppSelector(selectors.flashcards.selectFlashcardsPack);
  const packFlashcardsMeta = useAppSelector(selectors.flashcards.selectFlashcardsPackMeta);
  const [getPackFlashcardsActions, isLoading] = useAsyncAction(actions.flashcards.getFlashcardsPack);

  const initialGetPackFlashcards = () => {
    dispatch(actions.flashcards.clearPackFlashcards());
    getPackFlashcards();
  };

  useEffect(() => {
    initialGetPackFlashcards();
  }, [selectedType]);

  const getPackFlashcards = async (page = 1, limit?: number) => {
    try {
      const answer = (selectedType === AnswerEnum.ALL ? undefined : AnswerEnum[selectedType]) as AnswerType;

      await getPackFlashcardsActions({ packId: packId || undefined, answer, page, packType, limit });
    } catch {
      errorToast('Something went wrong');
    }
  };

  const nextPage = (packFlashcardsMeta?.currentPage || 0) + 1;
  const hasPackFlashcardsMore = (packFlashcardsMeta?.currentPage || 0) < (packFlashcardsMeta?.totalPages || 0);

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getPackFlashcards(nextPage, 20),
    hasAfterMore: hasPackFlashcardsMore,
    isLoading,
    isScrollToInitialPosition: false,
  });

  const textMapping = {
    [AnswerEnum.ALL]: 'All',
    [AnswerEnum.YES]: 'Yes',
    [AnswerEnum.NO]: 'No',
    [AnswerEnum.KINDA]: 'Kinda',
    [AnswerEnum.UNANSWERED]: 'Unanswered',
  };

  const answerVariants = useMemo(
    () => [
      {
        text: 'All',
        type: AnswerEnum.ALL,
        color: colors.primary[1],
      },
      {
        text: 'No',
        type: AnswerEnum.NO,
        color: colors.system.red,
      },
      {
        text: 'Kinda',
        type: AnswerEnum.KINDA,
        color: colors.system.blue,
      },
      {
        text: 'Yes',
        type: AnswerEnum.YES,
        color: colors.system.green,
      },
      {
        text: 'Unanswered',
        type: AnswerEnum.UNANSWERED,
        color: colors.neutrals[3],
      },
    ],
    [],
  );

  const isCards = Boolean(!!packFlashcards?.length || isLoading);
  const isAll = selectedType === AnswerEnum.ALL;

  const text = isAll
    ? 'There are no flashcards'
    : `There are no flashcards that you marked as "${textMapping[selectedType]}"`;

  return (
    <Root>
      <TagsContainer isHorisontal>
        {answerVariants.map((answerVariant, index) => (
          <StyledTag
            key={index}
            text={answerVariant.text}
            size="small"
            selected={selectedType === answerVariant.type}
            onClick={() => setSelectedType(answerVariant.type)}
            color={answerVariant.color}
            isAllCards
          />
        ))}
      </TagsContainer>
      <CardsContainer ref={listRef} onScroll={handleScroll} $isEmpty={!packFlashcards?.length}>
        {isCards ? (
          <>
            {packFlashcards?.map((card, index) => (
              <Card
                key={card.id}
                index={index}
                lastAnswer={card.lastAnswer}
                imageQuestion={card.questionImageUrl ? { url: card.questionImageUrl, alt: card.question } : undefined}
                textQuestion={card.question}
                imageAnswer={card.answerImageUrl ? { url: card.answerImageUrl, alt: card.answer } : undefined}
                textAnswer={card.answer}
              />
            ))}
            {isLoading && (
              <LoaderContainer>
                <ThreeDots color={colors.primary[3]} />
              </LoaderContainer>
            )}
          </>
        ) : (
          <NoCardsText>{text}</NoCardsText>
        )}
      </CardsContainer>
    </Root>
  );
};

export default AllCards;

const Root = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  padding-bottom: 20px;

  ${respondToWidth.s`
    padding-bottom: 0;
  `}
`;

const TagsContainer = styled(ScrollbarContainer)`
  width: fit-content;

  display: grid;
  grid-auto-flow: column;
  justify-self: center;

  padding-bottom: 16px;
  grid-gap: 8px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.sm`
   padding-bottom: 20px;
  `}

  &::-webkit-scrollbar {
    height: 4px;
  }
`;

const CardsContainer = styled(ScrollbarContainer)<{ $isEmpty: boolean }>`
  width: 100%;
  height: auto;
  display: grid;

  justify-content: center;
  align-content: flex-start;
  grid-gap: 16px;

  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      height: 100%;
      align-content: center;
    `}

  ${respondToWidth.sm`
    grid-gap: 20px;
  `}
`;

const NoCardsText = styled(StyledText)`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_semibold_14};

  ${respondToWidth.s`
    width: 240px;
    margin-bottom: 75px;
  `}
`;

const StyledTag = styled(Tag)`
  min-width: 60px;
  margin-top: 1px;
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
