import React, { FC } from 'react';
import styled from 'styled-components';

type LabelSize = 'medium' | 'small';
type LabelColor = 'green' | 'gray' | 'yellow';

type LabelProps = {
  size?: LabelSize;
  color: LabelColor | string;
  text: string;
  className?: string;
};

const Label: FC<LabelProps> = ({ size = 'medium', color, text, className }) => {
  return (
    <Container $color={color} $size={size} className={className}>
      {text}
    </Container>
  );
};

export default Label;

const Container = styled.div<{ $size: LabelSize; $color: LabelColor | string }>`
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  border-radius: 8px;
  color: ${({ theme: { colors } }) => colors.neutrals[11]};
  background-color: ${({ theme: { colors }, $color }) => {
    if ($color === 'gray') {
      return colors.neutrals[5];
    }
    if ($color === 'green') {
      return colors.system.green;
    }
    if ($color === 'yellow') {
      return colors.system.yellow;
    }

    return $color;
  }};
  ${({ theme: { typography }, $size }) =>
    $size === 'medium' ? typography.body_basic_bold_14 : typography.footnote_semibold_12};
`;
