import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgTag: FC<SVGIconProps> = ({ size = 132, ...props }) => {
  return (
    <svg
      version="1.0"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M374 2881 c-95 -23 -195 -107 -237 -199 -22 -46 -22 -56 -25 -598
l-3 -551 75 -70 c70 -65 75 -72 71 -104 -3 -19 -9 -95 -15 -169 -5 -74 -21
-279 -35 -456 -23 -291 -24 -325 -10 -370 18 -61 62 -116 122 -150 41 -25 62
-28 251 -44 173 -14 215 -21 257 -40 78 -37 143 -31 399 38 l220 59 49 -31
c44 -27 61 -31 133 -35 144 -6 124 -21 692 536 273 268 507 502 520 518 40 52
55 97 56 170 1 108 -26 154 -181 305 -146 142 -529 518 -939 923 l-282 277
-543 -1 c-299 -1 -558 -4 -575 -8z m1708 -776 c506 -497 659 -654 669 -682 25
-77 30 -70 -519 -611 -555 -546 -546 -539 -628 -512 -38 13 -145 116 -1201
1154 l-153 150 0 501 c0 464 2 503 19 540 20 44 41 66 86 89 26 14 98 16 550
16 l520 0 657 -645z m-1535 -1333 c69 -254 124 -464 122 -466 -8 -7 -245 15
-273 26 -39 16 -75 56 -82 94 -5 26 33 596 52 793 l7 63 23 -24 c20 -19 49
-114 151 -486z m511 -165 c150 -148 271 -271 269 -274 -12 -12 -349 -95 -388
-96 -50 -1 -104 26 -127 63 -7 11 -55 177 -108 368 -52 191 -97 356 -100 367
-7 23 70 -50 454 -428z"
        />
        <path
          d="M781 2439 c-99 -19 -177 -94 -207 -197 -37 -126 34 -264 163 -318 49
-21 156 -22 205 -1 51 22 127 94 149 142 27 58 25 165 -2 223 -55 113 -183
176 -308 151z m105 -165 c55 -26 80 -95 53 -147 -20 -39 -51 -57 -102 -57 -40
0 -53 5 -78 29 -53 54 -35 146 35 177 41 17 51 17 92 -2z"
        />
      </g>
    </svg>
  );
};

const Memo = memo(SvgTag);
export default Memo;
