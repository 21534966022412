import { combineReducers } from 'redux';

import * as activeCampaignDuck from './activeCampaign';
import * as authDuck from './auth';
import * as coursesSlice from './courses';
import * as directionSlice from './direction';
import * as flashcardsSlice from './flashcards';
import * as flashcardsPacksSlice from './flashcardsPacks';
import * as mnemonicCardsDuck from './mnemonicCards';
import * as mnemonicCardsPacksDuck from './mnemonicCardsPacks';
import * as payments from './payments';
import * as performanceDuck from './performance';
import * as report from './report';
import * as settingsDuck from './settings';
import * as stripeDuck from './stripe';
import * as studyGuideDuck from './studyGuide';
import * as studyPlansDuck from './studyPlans';
import * as testsDuck from './tests';
import * as userDuck from './user';

export const rootReducer = combineReducers({
  auth: authDuck.reducer,
  user: userDuck.reducer,
  tests: testsDuck.reducer,
  studyGuide: studyGuideDuck.reducer,
  performance: performanceDuck.reducer,
  flashcards: flashcardsSlice.reducer,
  flashcardsPacks: flashcardsPacksSlice.reducer,
  direction: directionSlice.reducer,
  courses: coursesSlice.reducer,
  mnemonicCardsPacks: mnemonicCardsPacksDuck.reducer,
  mnemonicCards: mnemonicCardsDuck.reducer,
  studyPlans: studyPlansDuck.reducer,
  settings: settingsDuck.reducer,
  stripe: stripeDuck.reducer,
});

export const actions = {
  auth: authDuck.actions,
  activeCampaign: activeCampaignDuck.actions,
  user: userDuck.actions,
  tests: testsDuck.actions,
  studyGuide: studyGuideDuck.actions,
  performance: performanceDuck.actions,
  flashcards: flashcardsSlice.actions,
  flashcardsPacks: flashcardsPacksSlice.actions,
  direction: directionSlice.actions,
  courses: coursesSlice.actions,
  mnemonicCards: mnemonicCardsDuck.actions,
  mnemonicCardsPacks: mnemonicCardsPacksDuck.actions,
  studyPlans: studyPlansDuck.actions,
  report: report.actions,
  payments: payments.actions,
  settings: settingsDuck.actions,
  stripe: stripeDuck.actions,
};

export const selectors = {
  user: userDuck.selectors,
  auth: authDuck.selectors,
  activeCampaign: activeCampaignDuck.selectors,
  tests: testsDuck.selectors,
  studyGuide: studyGuideDuck.selectors,
  performance: performanceDuck.selectors,
  flashcards: flashcardsSlice.selectors,
  flashcardsPacks: flashcardsPacksSlice.selectors,
  direction: directionSlice.selectors,
  courses: coursesSlice.selectors,
  mnemonicCards: mnemonicCardsDuck.selectors,
  mnemonicCardsPacks: mnemonicCardsPacksDuck.selectors,
  studyPlans: studyPlansDuck.selectors,
  settings: settingsDuck.selectors,
  stripe: stripeDuck.selectors,
};
