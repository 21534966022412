import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgTimerFillIcon: FC<SVGIconProps> = (props) => {
  return (
    <svg width={40} height={41} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_12014_250685)">
        <path
          d="m29.363 10.176 2.422-2.422 2.357 2.357-2.422 2.422a15 15 0 1 1-2.357-2.357Zm-11.03 3.387v10h3.334v-10h-3.334Zm-5-11.667h13.334V5.23H13.333V1.896Z"
          fill={props.color}
        />
      </g>
      <defs>
        <clipPath id="clip0_12014_250685">
          <path fill="#fff" transform="translate(0 .23)" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgTimerFillIcon);
export default Memo;
