import { ConfirmModal } from 'UI';
import { ConfirmModalProps } from 'UI/Modals/ConfirmModal/ConfirmModal';
import { AxiosError } from 'axios';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { FC } from 'react';
import { actions } from 'store';

const DeletePaymentMethodModal: FC<ConfirmModalProps> = ({ onClose, ...props }) => {
  const { successToast, errorToast } = useNotifications();
  const [deletePaymentMethodAction, isLoading] = useAsyncAction(actions.user.deletePaymentMethod);

  const deletePaymentMethod = async () => {
    try {
      await deletePaymentMethodAction();

      successToast('Card deleted successfully');
    } catch (error) {
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    } finally {
      onClose();
    }
  };

  return (
    <ConfirmModal
      {...props}
      title="Are you sure you want to delete card?"
      onClose={onClose}
      onLeftButtonClick={deletePaymentMethod}
      isLeftButtonLoading={isLoading}
    />
  );
};

export default DeletePaymentMethodModal;
