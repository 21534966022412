import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MnemonicCardsPackDto, PaginationMetaDto } from 'api/generated';

import { getMnemonicCardsPacksThunk } from './actions';

interface MnemonicCardSlice {
  mnemonicCardsPacks: MnemonicCardsPackDto[] | null;
  currentPackId: string | null;
  meta: PaginationMetaDto | null;
}

const initialState: MnemonicCardSlice = {
  mnemonicCardsPacks: null,
  currentPackId: null,
  meta: null,
};

export const mnemonicCardsPacksSlice = createSlice({
  name: 'mnemonicCardsPacks',
  initialState,
  reducers: {
    setCurrentPackId(state, action: PayloadAction<string>) {
      state.currentPackId = action.payload;
    },
    clearSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getMnemonicCardsPacksThunk.fulfilled, (state, { payload }) => {
      const mnemonicCardsPack = state.mnemonicCardsPacks || [];
      state.mnemonicCardsPacks = payload.isFirstFetch
        ? payload.data.items
        : [...mnemonicCardsPack, ...payload.data.items];
      state.meta = payload.data.meta;
    });
  },
});

export const actions = {
  ...mnemonicCardsPacksSlice.actions,
  getMnemonicCardsPacks: getMnemonicCardsPacksThunk,
};

export const { reducer } = mnemonicCardsPacksSlice;
