import { pluralForms } from 'constant';
import { FC } from 'react';
import styled from 'styled-components';
import { handleWordsDeclination } from 'utils';

type DaysToExamTextProps = {
  daysToExam: number;
};

const DaysToExamText: FC<DaysToExamTextProps> = ({ daysToExam }) => {
  return (
    <Container>
      <DaysRemain>
        {daysToExam} {handleWordsDeclination(daysToExam, pluralForms.days)}
      </DaysRemain>
      <Text>until your exam</Text>
    </Container>
  );
};

export default DaysToExamText;

const Container = styled.div`
  display: flex;
  gap: 3px;
`;

const DaysRemain = styled.p`
  color: ${({ theme: { colors } }) => colors.primary[1]};

  ${({ theme: { typography } }) => typography.body_basic_bold_14};
`;

const Text = styled.p`
  color: ${({ theme: { colors } }) => colors.neutrals[1]};

  ${({ theme: { typography } }) => typography.body_basic_bold_14};
`;
