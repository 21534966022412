import { CreatePasswordModal as CreatePasswordModalUI } from 'UI';
import useAsyncAction from 'hooks/useAsyncAction';
import React, { FC } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import { BaseModalProps } from 'types';

type CreatePasswordModalProps = {
  onBackIconClick?: () => void;
} & BaseModalProps;

const CreatePasswordModal: FC<CreatePasswordModalProps> = (props) => {
  const [signIn, isLoading] = useAsyncAction(actions.auth.signIn);
  const [restorePassword] = useAsyncAction(actions.auth.restorePassword);

  const email = useAppSelector(selectors.auth.selectEmail);
  const direction = useAppSelector(selectors.direction.selectDirection);
  const token = useAppSelector(selectors.auth.selectAccessToken);

  const fetchSignIn = async (password: string) => {
    const isFetch = direction && !token;

    if (isFetch) {
      await signIn({
        email,
        password,
        directionId: direction?.id || '',
      });
    }
  };

  const fetchRestorePassword = async () => {
    await restorePassword({ email: email, directionId: direction?.id || '' });
  };

  return (
    <CreatePasswordModalUI
      {...props}
      email={email}
      isLoading={isLoading}
      restorePassword={fetchRestorePassword}
      signIn={fetchSignIn}
    />
  );
};

export default CreatePasswordModal;
