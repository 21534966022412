import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const PerformanceEllipse: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[10];

  return (
    <svg {...props} width={480} height={480} viewBox="0 0 480 480" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M480 240c0 132.548-107.452 240-240 240S0 372.548 0 240 107.452 0 240 0s240 107.452 240 240Zm-376.009 0c0 75.116 60.893 136.009 136.009 136.009 75.116 0 136.009-60.893 136.009-136.009 0-75.116-60.893-136.009-136.009-136.009-75.116 0-136.009 60.893-136.009 136.009Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(PerformanceEllipse);
export default Memo;
