import { routes } from 'constant/routes';
import { useQuery } from 'hooks';
import React, { ComponentType, FC } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { StorageKeys } from 'services';
import { selectors, useAppSelector } from 'store';

type SignInRouteType = FC<{ component: ComponentType } & RouteProps>;

const SignInRoute: SignInRouteType = ({ component: Component }) => {
  const isAuth = useAppSelector(selectors.auth.selectAccessToken);
  const query = useQuery();

  const courseId = query.get(StorageKeys.COURSE_ID);
  const subscribeType = query.get(StorageKeys.SUBSCRIBE_TYPE);
  const term = query.get(StorageKeys.TERM_PERIOD);

  const isQuery = courseId && subscribeType && term;

  const paymentQuery = isQuery
    ? `?${StorageKeys.COURSE_ID}=${courseId}&${StorageKeys.SUBSCRIBE_TYPE}=${subscribeType}&${StorageKeys.TERM_PERIOD}=${term}`
    : '';

  return isAuth ? <Navigate to={{ pathname: routes.home, search: paymentQuery }} replace /> : <Component />;
};

export default SignInRoute;
