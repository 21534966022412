import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgCross: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.4999 3.42645C9.92927 3.42645 3.91162 9.4441 3.91162 17.0147C3.91162 24.5853 9.92927 30.6029 17.4999 30.6029C25.0704 30.6029 31.0881 24.5853 31.0881 17.0147C31.0881 9.4441 25.0704 3.42645 17.4999 3.42645ZM22.741 23.8088L17.4999 18.5676L12.2587 23.8088L10.7057 22.2559L15.9469 17.0147L10.7057 11.7735L12.2587 10.2206L17.4999 15.4617L22.741 10.2206L24.294 11.7735L19.0528 17.0147L24.294 22.2559L22.741 23.8088Z"
        fill="#B0B0B0"
      />
    </svg>
  );
};

const Memo = memo(SvgCross);
export default Memo;
