import { useEffect } from 'react';

const useToggleScroll = (isDisable: boolean) => {
  useEffect(() => {
    if (isDisable) {
      disableScroll();

      return enableScroll;
    } else {
      enableScroll();
    }
  }, [isDisable]);

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };
};

export default useToggleScroll;
