import React from 'react';
import { AnswerEnum } from 'store/ducks/performance/types';
import styled from 'styled-components';

type CustomLabelType = {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  colorMapping: { [key: string]: string };
  name: AnswerEnum;
  inactive: boolean;
  onClick: (name: AnswerEnum) => void;
  isInactiveAll: boolean;
  isOpenStatisticsModal: boolean;
  answerType: AnswerEnum;
  textFill?: string;
  circleFill?: string;
};

const RADIAN = Math.PI / 180;
const SYMBOL_WIDTH = 7;
const PERCENT_SYMBOL_WIDTH = 12.61;

const Label = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  colorMapping,
  name,
  isInactiveAll,
  isOpenStatisticsModal,
  onClick,
  answerType,
  textFill = 'black',
  circleFill = 'white',
}: CustomLabelType) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const r = 26;

  const getColor = (name: AnswerEnum) => {
    if (answerType === name) {
      return colorMapping[name];
    }
    if (isInactiveAll) {
      return colorMapping[AnswerEnum.INACTIVE];
    }
    return colorMapping[name];
  };

  const handleMouseDown = (e: unknown) => {
    if (isOpenStatisticsModal) {
      (e as Event).stopPropagation();
    }
    onClick(name);
  };

  const value = `${parseFloat(percent.toFixed(1)).toString()}%`;
  const stringWidth = (value.length - 1) * SYMBOL_WIDTH + PERCENT_SYMBOL_WIDTH;

  if (!percent) {
    return null;
  }

  return (
    <>
      <Circle
        cx={x}
        cy={y}
        r={r}
        stroke={getColor(name)}
        strokeWidth={4}
        fill={circleFill}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
      />
      <Text
        x={x - stringWidth / 2}
        y={y}
        fill={textFill}
        dominantBaseline="middle"
        alignment-baseline="middle"
        fontWeight={600}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}>
        {value}
      </Text>
    </>
  );
};

export default Label;

const Circle = styled.circle`
  cursor: pointer;
`;

const Text = styled.text`
  cursor: pointer;
  font-variant: tabular-nums;
`;
