import { CaretIcon } from 'assets/icons';
import React, { ButtonHTMLAttributes, FC } from 'react';

import { LeftCaret, StyledButton, Title } from './styled';

type NavButtonVariant = 'next' | 'back';

type NavButtonProps = {
  variant: NavButtonVariant;
  isHideText?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const NavButton: FC<NavButtonProps> = ({ variant, isHideText, disabled, type = 'button', ...props }) => {
  const isBack = variant === 'back';
  const isNext = variant === 'next';

  return (
    <>
      {isBack && (
        <StyledButton {...props} disabled={disabled} type={type}>
          <LeftCaret />
          <Title $disabled={disabled} $isHideText={isHideText}>
            Back
          </Title>
        </StyledButton>
      )}
      {isNext && (
        <StyledButton {...props} disabled={disabled} type={type}>
          <Title $disabled={disabled} $isHideText={isHideText}>
            Next
          </Title>
          <CaretIcon />
        </StyledButton>
      )}
    </>
  );
};

export default NavButton;
