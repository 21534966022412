import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgMenuIcon: FC<SVGIconProps> = ({ color: propsColor, size = 32, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4 5.333h24V8H4V5.333Zm0 9.334h24v2.666H4v-2.666ZM4 24h24v2.667H4V24Z" fill={color} />
    </svg>
  );
};

const Memo = memo(SvgMenuIcon);
export default Memo;
