import { useEffect } from 'react';
import { FieldValues, UseFormWatch } from 'react-hook-form';

const useFormWatch = <T extends FieldValues>(watch: UseFormWatch<T>) => {
  useEffect(() => {
    const subscription = watch(() => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  return watch;
};

export default useFormWatch;
