import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgCloseIcon: FC<SVGIconProps> = ({ color: propsColor, size = 32, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m16 14.115 6.6-6.6L24.485 9.4l-6.6 6.6 6.6 6.6-1.885 1.885-6.6-6.6-6.6 6.6L7.515 22.6l6.6-6.6-6.6-6.6L9.4 7.515l6.6 6.6Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgCloseIcon);
export default Memo;
