import { FC, ReactNode } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';

type ColorTegProps = {
  count?: number;
  total?: number;
  text?: string;
  className?: string;
  showIcon?: boolean;
  icon?: ReactNode;
};

const ColorTeg: FC<ColorTegProps> = ({ text, count, total, className, showIcon, icon }) => {
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const result = count && total && count / total;

  return (
    <Container $result={result} $showIcon={showIcon} $isDarkMode={isDarkMode} className={className}>
      {showIcon && icon}
      {getValue(count, total, text)}
    </Container>
  );
};

export default ColorTeg;

const Container = styled.div<{ $result?: number; $showIcon?: boolean; $isDarkMode?: boolean }>`
  ${({ theme: { colors, typography }, $result, $showIcon, $isDarkMode }) => css`
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 24px;
    user-select: none;
    border-radius: 10px;
    gap: ${$showIcon && ' 8px'};

    color: ${() => {
      if ($result === 0) {
        return colors.neutrals[4];
      }
      if ($result && $result > 0 && $result < 1) {
        return colors.system.blue;
      }
      if ($result === 1) {
        return colors.system.green;
      } else {
        return colors.primary[1];
      }
    }};

    background-color: ${() => {
      if ($result === 0) {
        if ($isDarkMode) {
          return colors.neutrals[8];
        } else {
          return colors.neutrals[10];
        }
      }
      if ($result && $result > 0 && $result < 1) {
        return colors.system.lightBlue;
      }
      if ($result && $result === 1) {
        return colors.system.lightGreen;
      } else {
        return colors.primary[6];
      }
    }};

    ${typography.body_large_semibold_16};
  `};
`;

const getValue = (count?: number, total?: number, text?: string) => {
  if (total) {
    return `${count || 0}/${total} Learned`;
  }
  if (text) {
    return `${count} ${text}`;
  } else {
    return count;
  }
};
