import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { DefaultRejectValue, ExtraParamsThunkType, PaginationType } from 'types';

import { GetMnemonicCardsPackThunkRequest } from './types';

export const getMnemonicCardsPackThunk = createAsyncThunk<
  GetMnemonicCardsPackThunkRequest,
  { id: string } & PaginationType,
  ExtraParamsThunkType<DefaultRejectValue>
>('mnemonicCards/packs/{id}/cards', async (body, { rejectWithValue }) => {
  try {
    const isFirstFetch = !body?.page || body?.page === 1;
    const limit = body.limit || 20;

    const { data, status } = await api.MnemonicCards.mnemonicCardsControllerGetMnemonicCards(body.id, body.page, limit);

    if (status > 399) {
      throw data;
    }

    return { data, isFirstFetch, packId: body.id };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
