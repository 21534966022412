/* eslint-disable @typescript-eslint/no-var-requires */
import EditorJS, { OutputData, ToolConstructable, ToolSettings } from '@editorjs/editorjs';
import { FC, HTMLAttributes, MutableRefObject, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { getLastChildOfTree } from 'utils/getLastChildOfTree';

const AlignmentTuneTool = require('editorjs-text-alignment-blocktune');
const Paragraph = require('@editorjs/paragraph');
const List = require('@editorjs/list');
const ImageTool = require('@editorjs/image');

export type EditorProps = {
  defaultData?: OutputData;
  readOnly?: boolean;
  className?: string;
  holder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorRef: MutableRefObject<any>;
  isRtl?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Editor: FC<EditorProps> = ({ defaultData, className, editorRef, holder, ...props }) => {
  useEffect(() => {
    initEditor(defaultData);
    return destroyEditor;
  }, [holder, defaultData]);

  const setFontSize = () => {
    //setting font-size for number of list element
    if (editorRef.current) {
      const list = document.querySelectorAll<HTMLElement>('.cdx-list__item');
      list.forEach((li) => {
        const lastChildOfTree = getLastChildOfTree(li);
        li.style.fontSize = lastChildOfTree.style.fontSize;
      });
    }
  };

  const setLinkAttributes = () => {
    if (editorRef.current) {
      const links = document.getElementsByTagName('a');

      for (const link of links) {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener');
      }
    }
  };

  const initEditor = (editorData?: OutputData) => {
    const tools: {
      [toolName: string]: ToolConstructable | ToolSettings;
    } = {
      paragraph: {
        class: Paragraph,
        tunes: ['anyTuneName'],
      },
      anyTuneName: {
        class: AlignmentTuneTool,
      },
      list: {
        class: List,
        tunes: ['anyTuneName'],
      },
      image: {
        class: ImageTool,
        config: { buttonContent: '' },
      },
    };

    const editor = new EditorJS({
      holder,
      data: editorData,
      autofocus: false,
      readOnly: true,
      tools,
      onReady() {
        editorRef.current = editor;
        setFontSize();
        setLinkAttributes();
      },
    });
  };

  const destroyEditor = () => {
    editorRef.current?.destroy();
    editorRef.current = null;
  };

  return (
    <Root>
      <EditorContainer {...props} className={className} id={holder} />
    </Root>
  );
};

export default Editor;

const EditorContentCSS = css`
  li {
    list-style: inherit;
  }

  .codex-editor {
    display: none;
  }

  .ce-block__content,
  .ce-toolbar__content {
    max-width: none;
  }

  .cdx-block {
    word-break: break-word;
    padding: 0;
  }

  .cdx-list {
    padding: 5px 0 5px 40px;
  }

  .cdx-list__item {
    padding: 2px 0 2px 3px;
  }

  .codex-editor__loader {
    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: center;
    padding: 19.5px 0;
  }

  > :first-child {
    display: block;
  }

  .codex-editor__redactor {
    padding-bottom: 0 !important;
  }

  .ce-inline-toolbar .ce-inline-tool svg {
    width: 24px;
    height: 24px;
  }

  span {
    line-height: normal;
    color: ${({ theme: { colors } }) => `${colors.neutrals[1]} !important`};
  }

  .image-tool__caption:empty {
    display: none;
  }

  .ce-block--selected,
  .ce-block__content {
    opacity: 1;
    background: none;

    img {
      opacity: 1;
    }
  }

  .cdx-underline {
    text-decoration: underline;
  }

  *:not(font, .note) {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
  }

  .image-tool--empty {
    display: none;
  }

  .image-tool {
    --bg-color: ${({ theme: { colors } }) => colors.primary[5]};
    --front-color: ${({ theme: { colors } }) => colors.primary[1]};
    --border-color: ${({ theme: { colors } }) => colors.primary[6]};
  }
`;

const Root = styled.div``;

const EditorContainer = styled.div`
  height: fit-content;

  ${EditorContentCSS}
`;
