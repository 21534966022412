import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgCheckMark: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.9999 31.1526C13.396 31.1526 9.93981 29.721 7.39152 27.1727C4.84323 24.6244 3.41162 21.1682 3.41162 17.5643C3.41162 13.9605 4.84323 10.5043 7.39152 7.95601C9.93981 5.40772 13.396 3.9761 16.9999 3.9761C20.6037 3.9761 24.0599 5.40772 26.6082 7.95601C29.1565 10.5043 30.5881 13.9605 30.5881 17.5643C30.5881 21.1682 29.1565 24.6244 26.6082 27.1727C24.0599 29.721 20.6037 31.1526 16.9999 31.1526Z"
        fill="#DF6438"
      />
      <path
        d="M14.2822 24.3585L26.5116 12.8085L24.4734 10.7702L14.2822 20.282L9.52633 15.5261L7.48809 17.5643L14.2822 24.3585Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

const Memo = memo(SvgCheckMark);
export default Memo;
