import { Button, DropdownMenu, StyledText, Tag } from 'UI';
import { TagSize } from 'UI/Tag/Tag';
import { PaymentDtoTypeEnum, SearchTestPassagesRequestDto } from 'api/generated';
import { useAsyncAction, useClientSize, useNotifications } from 'hooks';
import React, { FC, KeyboardEvent, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { dayjs } from 'services';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { SplitType, SplitTypeEnum } from 'store/ducks/performance/types';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

export enum SelectedMenu {
  OVERALL = 'Overall',
  TESTS = 'Tests',
  FLASHCARDS = 'All flashcards',
}

export enum SelectedTestTag {
  BY_CHAPTER = 'By chapters',
  BY_DOMAINS = 'By domains',
}

type TagVariant = {
  value: SelectedTestTag;
  text: string;
};

const tagNameValueAdapter: Record<SelectedTestTag, SplitType> = {
  [SelectedTestTag.BY_DOMAINS]: 'domain',
  [SelectedTestTag.BY_CHAPTER]: 'chapter',
};

type HeaderProps = {
  selectedMenu: SelectedMenu;
  setSelectedMenu: (value: SelectedMenu) => void;
  setIsLoading: (value: boolean) => void;
  setIsShowUpgradeModal: () => void;
};

export type TestsListFormType = {
  startDate?: string;
  endDate?: string;
};

const Header: FC<HeaderProps> = ({ selectedMenu, setSelectedMenu, setIsLoading, setIsShowUpgradeModal }) => {
  const selectedTag = useAppSelector(selectors.performance.selectCurrentTestSplitType);
  const course = useAppSelector((store) => store.courses.activeCourse);
  const courseId = course?.id;
  const [dateFilters, setDateFilters] = useState<TestsListFormType | null>(null);
  const { getIsBreakpoint } = useClientSize();
  const { errorToast } = useNotifications();
  const isWidthSm = getIsBreakpoint('sm');
  const dispatch = useAppDispatch();

  const methods = useForm<TestsListFormType>({
    defaultValues: {
      startDate: '',
      endDate: '',
    },
    mode: 'onBlur',
  });

  const size = isWidthSm ? 'small' : 'medium';
  const currentTestId = useAppSelector(selectors.performance.selectCurrentTestId);
  const [getTestsListAction, isTestListLoading] = useAsyncAction(actions.performance.getTestsList);
  const [getTestStatisticAction, isTestStatisticLoading] = useAsyncAction(actions.performance.getStatisticTest);
  const [tagNames, setTagNames] = useState<TagVariant[]>([
    {
      value: SelectedTestTag.BY_CHAPTER,
      text: SelectedTestTag.BY_CHAPTER,
    },
    {
      value: SelectedTestTag.BY_DOMAINS,
      text: SelectedTestTag.BY_DOMAINS,
    },
  ]);

  const getTestStatistic = async (id: string, split: SplitType) => {
    try {
      await getTestStatisticAction({ id, split });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const getTestsList = async (props: SearchTestPassagesRequestDto) => {
    try {
      await getTestsListAction(props);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    setIsLoading(isTestStatisticLoading);
  }, [isTestStatisticLoading]);

  const splitMapping = {
    [SelectedTestTag.BY_CHAPTER]: SplitTypeEnum.CHAPTER,
    [SelectedTestTag.BY_DOMAINS]: SplitTypeEnum.DOMAIN,
  };

  const getTest = () => {
    if (currentTestId) {
      getTestStatistic(currentTestId, selectedTag);
    }
  };

  useEffect(() => {
    getTest();
  }, [selectedTag, currentTestId]);

  const onSubmit = (data: TestsListFormType) => {
    dispatch(actions.performance.clearTestList());
    const startDate = data?.startDate ? dayjs(data?.startDate, 'MM.DD.YYYY').toISOString() : undefined;
    const endDate = data?.endDate
      ? dayjs(data?.endDate, 'MM.DD.YYYY')
          .set('hours', 23)
          .set('minute', 59)
          .set('second', 59)
          .set('millisecond', 59)
          .toISOString()
      : undefined;
    setDateFilters({ startDate, endDate });
    getTestsList({ startDate, endDate, courseId: courseId || '' });
  };

  const selectTag = (tag: SelectedTestTag) => {
    dispatch(actions.performance.setCurrentTestSplit(splitMapping[tag] as SplitType));
  };

  const selectTest = (id: string) => {
    dispatch(actions.performance.setCurrentTestId(id));
  };

  const tagVariants = tagNames.map((tagName) => ({
    text: tagName.text,
    selected: tagNameValueAdapter[tagName.value] === selectedTag,
    onClick: () => selectTag(tagName.value),
    size,
  }));

  const paymentType = useAppSelector(selectors.courses.selectCurrentPaymentType);
  const isTrial = paymentType === PaymentDtoTypeEnum.Free;

  const HeaderComponentsMapping = useMemo(
    () => ({
      [SelectedMenu.OVERALL]: isTrial && (
        <>
          <StyledUpgradeButton size="small" onClick={setIsShowUpgradeModal}>
            Upgrade
          </StyledUpgradeButton>
          <Text>To see detailed statistics, click on the needed part of the chart</Text>
        </>
      ),
      [SelectedMenu.TESTS]: (
        <TagsContainer>
          {tagVariants.map((tag) => (
            <Tag
              key={tag.text}
              text={tag.text}
              selected={tag.selected}
              onClick={tag.onClick}
              size={tag.size as TagSize}
            />
          ))}
        </TagsContainer>
      ),
      [SelectedMenu.FLASHCARDS]: <Text>To see detailed statistics, hover over the needed part of the chart</Text>,
    }),
    [selectedMenu, selectedTag, course?.domainStatsButtonName, course?.chapterStatsButtonName, isTrial],
  );

  const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      onSubmit(methods.getValues());
      e.preventDefault();
    }
  };

  useLayoutEffect(() => {
    if (course?.domainStatsButtonName && course?.chapterStatsButtonName) {
      setTagNames([
        {
          value: SelectedTestTag.BY_CHAPTER,
          text: course?.chapterStatsButtonName,
        },
        {
          value: SelectedTestTag.BY_DOMAINS,
          text: course?.domainStatsButtonName,
        },
      ]);
    }
  }, [course?.domainStatsButtonName, course?.chapterStatsButtonName]);

  return (
    <>
      <Container>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
            <DropdownMenu
              selected={selectedMenu}
              setSelected={setSelectedMenu}
              selectTest={(id) => selectTest(id)}
              onSubmit={onSubmit}
              dateFilters={dateFilters}
              isLoading={isTestListLoading}
            />
          </Form>
        </FormProvider>
        {HeaderComponentsMapping[selectedMenu]}
      </Container>
    </>
  );
};

export default Header;

const Container = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 24px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
  gap: 20px;

  ${respondToWidth.sm`
    flex-direction:column;
    justify-content: start;
    align-items: start;
    padding:16px;
    gap:12px;
  `}
`;

const Text = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_medium_14}

  ${respondToWidth.s`
    max-width:243px;
  `}
`;

const TagsContainer = styled.div`
  display: flex;
  gap: 8px;

  ${respondToWidth.sl`
    flex-direction: column;
  `}

  ${respondToWidth.sm`
    flex-direction: row;
  `}
`;

const Form = styled.form`
  width: 100%;
  max-width: 392px;
`;

const StyledUpgradeButton = styled(Button)`
  max-width: 110px;
  margin-left: auto;
  ${respondToWidth.sm`
    margin-left: 0;
  `}
`;
