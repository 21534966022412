/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from 'styled-components';

import { heightBreakpoints, widthBreakpoints } from './variables';

type WidthBreakpointKeys = keyof typeof widthBreakpoints;
type HeightBreakpointKeys = keyof typeof heightBreakpoints;

export const respondToWidth = (Object.keys(widthBreakpoints) as WidthBreakpointKeys[]).reduce<
  Record<WidthBreakpointKeys, any>
>((accumulator, label) => {
  accumulator[label] = (...args: Parameters<typeof css>) => css`
    @media (max-width: ${widthBreakpoints[label] + 'px'}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {} as Record<WidthBreakpointKeys, any>);

export const respondToHeight = (Object.keys(heightBreakpoints) as HeightBreakpointKeys[]).reduce<
  Record<HeightBreakpointKeys, any>
>((accumulator, label) => {
  accumulator[label] = (...args: Parameters<typeof css>) => css`
    @media screen and (max-height: ${heightBreakpoints[label] + 'px'}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {} as Record<HeightBreakpointKeys, any>);
