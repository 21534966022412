import { HideIcon } from 'assets/icons';
import React, { ButtonHTMLAttributes, FC } from 'react';
import { useTheme } from 'styled-components';

import { StyledButton, VariantBarControl } from './styled';

type BarControlButtonProps = {
  variant: VariantBarControl;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const BarControlButton: FC<BarControlButtonProps> = ({ variant, ...props }) => {
  const { colors } = useTheme();

  return (
    <StyledButton $variant={variant} {...props}>
      <HideIcon color={colors.neutrals[4]} />
    </StyledButton>
  );
};

export default BarControlButton;
