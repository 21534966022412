import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  AddExamDateDto,
  CheatSheetUrlDto,
  CheckPromocodeRequestDto,
  CheckPromocodeRequestDtoPaymentPeriodEnum,
  CheckPromocodeRequestDtoSubscriptionLevelEnum,
  CheckPromocodeResponseDto,
  CheckPurchaseResponseDto,
  CourseDto,
  PaginatedCoursesDto,
  PublicCourseDto,
  StatsButtonNamesDto,
  SubscribeDto,
  SubscribeToCourseRequestDto,
  TextBookUrlDto,
} from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType, PaginationType } from 'types';

export const getMyCoursesThunk = createAsyncThunk<CourseDto[], undefined, ExtraParamsThunkType<DefaultRejectValue>>(
  'courses/getMyCourses',
  async (_, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Courses.coursesControllerGetCustomerCourses();

      if (status > 399) {
        throw data;
      }

      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const getCoursesThunk = createAsyncThunk<
  { isFirstFetch: boolean; data: PaginatedCoursesDto },
  { withoutActivated?: boolean } & PaginationType,
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/getCourses', async (params, { rejectWithValue }) => {
  try {
    const isFirstFetch = !params.page || params.page === 1;

    const { data, status } = await api.Courses.coursesControllerFind(
      params.page,
      params.limit || 20,
      params.withoutActivated,
    );

    if (status > 399) {
      throw data;
    }

    return { isFirstFetch, data };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getCourseByIdThunk = createAsyncThunk<CourseDto, string, ExtraParamsThunkType<DefaultRejectValue>>(
  'courses/getCourseById',
  async (courseId, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Courses.coursesControllerGet(courseId);

      if (status > 399) {
        throw data;
      }

      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const getCourseByIdPublicThunk = createAsyncThunk<
  PublicCourseDto,
  string,
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/getCourseByIdPublic', async (courseId, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerGetPublic(courseId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getActiveCourseThunk = createAsyncThunk<CourseDto, null, ExtraParamsThunkType<DefaultRejectValue>>(
  'courses/getActiveCourse',
  async (_, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Courses.coursesControllerGetActive();

      if (status > 399) {
        throw data;
      }

      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const setActiveCourseThunk = createAsyncThunk<
  CourseDto,
  { courseId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/setActiveCourse', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerSetActiveCourse(courseId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

type SubscribeCourseThunkRequest = { courseId: string; promocode: string; subscribeDto: SubscribeDto };

export const checkPromoCodeThunk = createAsyncThunk<
  CheckPromocodeResponseDto,
  {
    courseId: string;
    promocode: string;
    paymentPeriod: CheckPromocodeRequestDtoPaymentPeriodEnum;
    subscriptionLevel: CheckPromocodeRequestDtoSubscriptionLevelEnum;
  },
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/checkPromoCode', async ({ courseId, paymentPeriod, subscriptionLevel, promocode }, { rejectWithValue }) => {
  try {
    const checkPromocodeRequestDto: CheckPromocodeRequestDto = {
      paymentPeriod,
      subscriptionLevel,
      promocode,
    };
    const { data, status } = await api.Courses.coursesControllerCheckPromocode(courseId, checkPromocodeRequestDto);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const subscribeCourseThunk = createAsyncThunk<
  CourseDto,
  SubscribeCourseThunkRequest,
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/subscribeCourse', async ({ courseId, promocode = '', subscribeDto }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerSubscribe(courseId, promocode, subscribeDto);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const newSubscribeCourseThunk = createAsyncThunk<{ clientSecret: string }, SubscribeToCourseRequestDto>(
  'courses/subscribeToCourse',
  async ({ courseId, level, period, source, promocode }, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Payments.paymentsControllerSubscribeToCourse({
        courseId,
        level,
        period,
        source,
        promocode: promocode || '',
      });

      if (status > 399) {
        throw data;
      }

      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const subscribeCourseFromTrialThunk = createAsyncThunk<
  CourseDto,
  SubscribeCourseThunkRequest,
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/subscribeCourseFromTrial', async ({ courseId, promocode = '', subscribeDto }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerSubscribeFromTrialCourse(
      courseId,
      promocode,
      subscribeDto,
    );

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const changeCourseSubscribeThunk = createAsyncThunk<
  CourseDto,
  { courseId: string; subscribeDto: SubscribeDto },
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/changeCourseSubscribe', async ({ courseId, subscribeDto }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerChangeSubscription(courseId, subscribeDto);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const unsubscribeCourseThunk = createAsyncThunk<
  CourseDto,
  { courseId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/unsubscribeCourse', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerUnsubscribe(courseId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const purchaseCourseThunk = createAsyncThunk<
  CourseDto,
  { courseId: string; promoCode?: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/purchaseCourse', async ({ courseId, promoCode }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerPurchase(courseId, promoCode || '');

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const purchaseCourseFromTrialThunk = createAsyncThunk<
  CourseDto,
  { courseId: string; promoCode?: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/purchaseCourseFromTrial', async ({ courseId, promoCode }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerPurchaseFromTrialCourse(courseId, promoCode || '');

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const cancelSubscriptionDowngradeThunk = createAsyncThunk<
  CourseDto,
  { courseId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/cancelSubscriptionDowngrade', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerCancelDowngrade(courseId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const cancelSubscriptionTrialDowngradeThunk = createAsyncThunk<
  CourseDto,
  { courseId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/cancelSubscriptionTrialDowngrade', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerCancelTrialDowngrade(courseId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const resetCourseResultsThunk = createAsyncThunk<undefined, undefined, ExtraParamsThunkType<DefaultRejectValue>>(
  'courses/resetCourseResults',
  async (_, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Courses.coursesControllerResetCourseResults();

      if (status > 399) {
        throw data;
      }
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const getCheatSheetThunk = createAsyncThunk<
  CheatSheetUrlDto,
  undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/getCheatSheet', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerGetCheatSheetUrl();
    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getTextBookThunk = createAsyncThunk<TextBookUrlDto, undefined, ExtraParamsThunkType<DefaultRejectValue>>(
  'courses/getTextBook',
  async (body, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Courses.coursesControllerGetTextBookUrl();
      if (status > 399) {
        throw data;
      }
      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const getStatisticButtonsTitlesThunk = createAsyncThunk<
  StatsButtonNamesDto,
  undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('performance/getButtonsTitles', async (_, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerGetStatsButtonNames();
    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getActiveCourseExamDateThunk = createAsyncThunk<any, null, ExtraParamsThunkType<DefaultRejectValue>>(
  'courses/getActiveCourseExamDate',
  async (_, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Courses.coursesControllerGetExamDate();

      if (status > 399) {
        throw data;
      }

      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const setActiveCourseExamDateThunk = createAsyncThunk<
  AddExamDateDto,
  AddExamDateDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/setActiveCourseExamDate', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerAddExamDate(body);

    if (status > 399) {
      throw data;
    }

    return body;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getCheckPurchaseThunk = createAsyncThunk<
  CheckPurchaseResponseDto,
  { customerId: string; courseId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('courses/{courseId}/checkPurchase', async ({ customerId, courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Courses.coursesControllerCheckPurchase(customerId, courseId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
