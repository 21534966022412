import { regexp } from 'constant/regexp';
import { dayjs } from 'services';

export const validations = {
  password: (value: string) => {
    if (value.length > 20 || value.length < 6) {
      return 'Password must contain from 6 to 20 symbols.';
    }
    if (!regexp.password.test(value)) {
      return 'Use latin letters, numbers and special symbols';
    }
  },
  email: (value: string) => {
    if (value.length > 155 || value.length < 1) {
      return 'Email must contain from 1 to 155 symbols.';
    }
    if (!regexp.emailRegExp.test(value)) {
      return 'Incorrect email format. Example: namesurname@email.com';
    }
  },
  fullName: (value: string) => {
    if (value.length > 155 || value.length < 1) {
      return 'Full name must contain from 1 to 40 characters.';
    }
    if (!regexp.fullName.test(value.trim())) {
      return 'Use only latin letters, spaces, hyphen';
    }
  },
  deleteAccount: (value: string) => {
    if (value !== 'Delete') {
      return 'Wrong word';
    }
  },
  text: (value: string) => {
    if (value && !regexp.text.test(value)) {
      return 'Use only latin letters, spaces, hyphen';
    }
  },
  textWithSymbols: (value: string) => {
    if (!value) return;
    if (value && !regexp.textWithSymbols.test(value)) {
      return 'Use only latin letters, spaces, numbers, special characters';
    }
  },
  numbers: (value: string) => {
    if (!value) return;
    if (value && !regexp.numbers.test(value)) {
      return 'Use only numbers';
    }
  },
  date: (value?: string) => {
    if (!value) {
      return undefined;
    }
    if (!dayjs(value, 'MM.DD.YYYY', true).isValid()) {
      return 'Invalid date';
    }
  },
};
