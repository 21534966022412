export const regexp = {
  // only xxx@xxx.xxx
  emailRegExp:
    /^(([^А-Яа-яЕеЁё\\|/?*#~`<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password:
    /^(\S)(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?\/_₹])[a-zA-Z0-9~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?\/_₹]{6,20}$/,
  numbers: /^\d+$/,
  mobile: /Android|webOS|iPhone|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i,
  android: /Android/i,
  fullName: /^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/,
  text: /^[\s-\w]+(?:[\s-][\w]+)*$/,
  textWithSymbols: /^[\s\w\d!@?#$%*(),\/\\|'"^£;:[\]{}+=&.<>`~±§\-]*$/,
};
