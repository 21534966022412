import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import { CourseDto } from 'api/generated';
import { pluralForms } from 'constant';
import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { widthBreakpoints } from 'styles/general/variables';
import { hideText } from 'styles/helpers';
import { getBreadcrumbsString, handleWordsDeclination } from 'utils';

type CoursePreviewProps = {
  course: CourseDto;
  openChooseProductModal?: (course: CourseDto) => void;
};

const CoursePreview: FC<CoursePreviewProps> = ({ course, openChooseProductModal }) => {
  const statisticsOptions = useMemo(
    () => [
      {
        label: handleWordsDeclination(course.amountChaptersAtStudyGuide, pluralForms.chapter),
        value: course.amountChaptersAtStudyGuide,
      },
      {
        label: handleWordsDeclination(course.amountQuestions, pluralForms.question),
        value: course.amountQuestions,
      },
      {
        label: handleWordsDeclination(course.amountFlashcardsPacks, pluralForms.flashcardsDeck),
        value: course.amountFlashcardsPacks,
      },
      {
        label: handleWordsDeclination(course.amountMnemonicCardsPacks, pluralForms.mnemonicCardsDeck),
        value: course.amountMnemonicCardsPacks,
      },
    ],
    [course],
  );

  const statisticString = getBreadcrumbsString(statisticsOptions, '\n');

  const selectCourse = async () => {
    openChooseProductModal?.(course);
  };

  return (
    <Root>
      <Wrapper>
        <Container>
          <StyledText font="body_large_bold_16" as="h4">
            {course.name}
          </StyledText>
          <Statistic font="body_basic_regular_14">{statisticString}</Statistic>
          <StyledText font="body_basic_regular_14" as="p">
            {course.description}
          </StyledText>
        </Container>
        <StyledButton onClick={selectCourse} size="small">
          Get
        </StyledButton>
      </Wrapper>
    </Root>
  );
};

export default CoursePreview;

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100%;
    padding: 20px;
    display: grid;
    grid-gap: 16px;
    align-content: space-between;
    grid-template-rows: auto auto;

    @media (min-width: ${widthBreakpoints.sm}px) {
      height: 280px;
      grid-template-rows: 188px auto;
    }

    border-radius: 16px;
    background-color: ${colors.neutrals[11]};

    h4 {
      word-break: break-all;

      @media (min-width: ${widthBreakpoints.sm}px) {
        ${hideText(1)}
      }
    }

    p {
      ${hideText(3)}
    }

    @media (min-width: ${widthBreakpoints.sm}px) {
      p {
        ${hideText(3)}
      }

      :hover {
        position: absolute;
        left: 0;
        top: 0;
        grid-template-rows: 1fr auto;
        width: calc(200% + 24px);
        min-height: 280px;
        height: auto;
        z-index: 1;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);

        ${StyledButton} {
          width: 250px;
          margin: auto;
        }

        h4 {
          ${hideText(10)}
        }

        p {
          ${hideText(10)}
        }
      }
    }

    ${respondToWidth.sm`
        padding-inline: 30.5px; 

        border: 1px solid ${colors.neutrals[9]}
    `}
  `}
`;

const Root = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  display: grid;

  @media (min-width: 648px) {
    &:nth-child(2n) {
      ${Wrapper} {
        :hover {
          left: auto;
          right: 0;
        }
      }
    }
  }

  @media (min-width: 1032px) {
    &:nth-child(2n) {
      ${Wrapper} {
        :hover {
          left: 0;
          right: auto;
        }
      }
    }
    &:nth-child(3n) {
      ${Wrapper} {
        :hover {
          left: auto;
          right: 0;
        }
      }
    }
  }

  @media (min-width: 1356px) {
    &:nth-child(2n),
    &:nth-child(3n) {
      ${Wrapper} {
        :hover {
          left: 0;
          right: auto;
        }
      }
    }
    &:nth-child(4n) {
      ${Wrapper} {
        :hover {
          left: auto;
          right: 0;
        }
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Statistic = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  white-space: pre-line;
`;

const StyledButton = styled(Button)`
  width: auto;
`;
