import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications, useQuery } from 'hooks';
import React, { useEffect } from 'react';
import { Oval as Loader } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { actions } from 'store';
import styled, { useTheme } from 'styled-components';

const ConfirmEmail = () => {
  const { colors } = useTheme();
  const { errorToast, successToast } = useNotifications();

  const navigate = useNavigate();

  const query = useQuery();
  const hash = query.get('hash') || '';

  const [confirmEmailAction] = useAsyncAction(actions.user.confirmEmail);

  const confirmEmail = async () => {
    try {
      await confirmEmailAction({ confirmationEmailHash: hash });
      navigate(routes.profile);
      successToast('Email changed successfully');
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    if (hash) {
      confirmEmail();
    }
  }, [hash]);

  return (
    <Container>
      <Loader color={colors.primary[1]} secondaryColor="transparent" strokeWidth={4} />
    </Container>
  );
};

export default ConfirmEmail;

const Container = styled.div`
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
`;
