import { typography } from '../general/typography';
import { appDefaultColors } from './appDefaultColors';

const opacityMapping = {
  90: 'E6',
  70: 'B3',
  40: '66',
  20: '33',
  5: '0D',
};

type OpacityType = keyof typeof opacityMapping;

type DirectionColors = {
  old: string;
};

export class Theme {
  primaryColor = '';
  directionColors?: DirectionColors;
  default = {
    typography,
    colors: {
      primary: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
      },
      neutrals: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
        10: '',
        11: '',
        12: '',
        13: '',
      },
      system: {
        green: '',
        lightGreen: '',
        red: '',
        lightRed: '',
        blue: '',
        lightBlue: '',
        violet: '',
        lightViolet: '',
        yellow: '',
        lightYellow: '',
        orange: '',
      },
      shadow: {
        1: '',
        2: '',
        3: '',
      },
      direction: undefined as undefined | DirectionColors,
    },
  };

  constructor(primaryColor: string, modeColors: typeof appDefaultColors['default'], directionColors?: DirectionColors) {
    this.primaryColor = primaryColor;
    this.default.colors.neutrals = modeColors.neutrals;
    this.default.colors.system = modeColors.system;
    this.default.colors.shadow = modeColors.shadow;
    this.directionColors = directionColors;
    this.updateColors();
  }

  updateColors() {
    this.default = {
      ...this.default,
      colors: {
        ...this.default.colors,
        primary: {
          1: this.primaryColor,
          2: this.getOpacityPrimaryColor(90),
          3: this.getOpacityPrimaryColor(70),
          4: this.getOpacityPrimaryColor(40),
          5: this.getOpacityPrimaryColor(20),
          6: this.getOpacityPrimaryColor(5),
        },
        direction: this.directionColors,
      },
    };
  }

  getOpacityPrimaryColor(opacityType: OpacityType) {
    return `${this.primaryColor}${opacityMapping[opacityType]}`;
  }
}

const theme = new Theme('', appDefaultColors.default);
export type ThemeType = typeof theme.default;
