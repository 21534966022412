import { useForm } from 'react-hook-form';

export type StudyGuideFormType = {
  search: string;
};

const useGuideSearch = () => {
  const formMethods = useForm<StudyGuideFormType>({
    defaultValues: {
      search: '',
    },
  });

  return {
    formMethods,
  };
};

export default useGuideSearch;
