import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgDinersIcon: FC<SVGIconProps> = (props) => {
  return (
    <svg width={32} height={20} viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_9025_215843)">
        <path
          d="M28.099 10.051c0-5.931-4.95-10.03-10.375-10.029h-4.668C7.567.02 3.049 4.122 3.049 10.051c0 5.425 4.518 9.882 10.007 9.856h4.668c5.424.026 10.375-4.432 10.375-9.856Z"
          fill="#0079BE"
        />
        <path
          d="M13.085.862C8.07.864 4.005 4.938 4.004 9.965c.001 5.027 4.065 9.1 9.081 9.102 5.017-.002 9.082-4.075 9.083-9.102 0-5.028-4.066-9.1-9.083-9.103Z"
          fill="#fff"
        />
        <path
          d="M7.345 9.94a5.778 5.778 0 0 1 3.696-5.384v10.768A5.776 5.776 0 0 1 7.345 9.94Zm7.816 5.386V4.556a5.775 5.775 0 0 1 3.697 5.385 5.777 5.777 0 0 1-3.697 5.386Z"
          fill="#0079BE"
        />
      </g>
      <defs>
        <clipPath id="clip0_9025_215843">
          <path fill="#fff" transform="translate(3)" d="M0 0h25.148v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgDinersIcon);
export default Memo;
