import { ConfigureStoreOptions, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { actions, rootReducer, selectors } from './ducks';
import { actions as authActions } from './ducks/auth';

export { actions, selectors };

type State = ReturnType<typeof rootReducer>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducerWithSignOut = (state: State, action: any) => {
  let nextState = state as State | undefined;

  if (action.type === authActions.signOut.type) {
    // How to reset store https://twitter.com/dan_abramov/status/703035591831773184
    // @ts-ignore
    nextState = { auth: { accessToken: null } };
  }

  return rootReducer(nextState, action);
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'mnemonicCards', 'flashcards', 'tests', 'settings'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createStore = (options?: ConfigureStoreOptions['preloadedState']) =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ...options,
  });

export const store = createStore();

export const persistor = persistStore(store);

// persistor.purge();

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
