import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgThreeDotsIcon: FC<SVGIconProps> = (props) => {
  const { colors } = useTheme();
  const color = props.color || colors.neutrals[1];

  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm14 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm-7 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
        fill={color}
      />
    </svg>
  );
};
const Memo = memo(SvgThreeDotsIcon);
export default Memo;
