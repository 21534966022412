/* eslint-disable camelcase */
export const typography = {
  title_1_bold_70: {
    fontWeight: 700,
    fontSize: '70px',
    lineHeight: '84px',
    letterSpacing: '-0.01em',
  },
  title_1_bold_42: {
    fontWeight: 700,
    fontSize: '42px',
    lineHeight: '42px',
    letterSpacing: '-0.01em',
  },
  title_2_bold_32: {
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '42px',
    letterSpacing: '-0.01em',
  },
  title_2_regular_32: {
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '42px',
    letterSpacing: '-0.01em',
  },
  title_3_bold_28: {
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '36px',
    letterSpacing: '-0.01em',
  },
  title_3_regular_28: {
    fontWeight: 400,
    fontSize: '28px',
    lineHeight: '36px',
    letterSpacing: '-0.01em',
  },
  title_4_bold_24: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.01em',
  },
  title_4_medium_24: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.01em',
  },
  title_5_bold_20: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '-0.01em',
  },
  title_5_medium_20: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '-0.01em',
  },
  headline_semibold_18: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '-0.01em',
  },
  body_large_extrabold_16: {
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '28px',
  },
  body_large_bold_16: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '28px',
  },
  body_large_semibold_16: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '28px',
  },
  body_large_medium_16: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
  },
  body_large_regular_16: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
  },
  body_basic_extrabold_14: {
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: '20px',
  },
  body_basic_bold_14: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  body_basic_semibold_14: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
  body_basic_medium_14: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  body_basic_link_14: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    textDecoration: 'underline',
  },
  body_basic_regular_14: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  subhead_extrabold_13: {
    fontWeight: 800,
    fontSize: '13px',
    lineHeight: '18px',
  },
  subhead_semibold_13: {
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '18px',
  },
  subhead_medium_13: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '18px',
  },
  subhead_regular_13: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
  },
  footnote_bold_12: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
  },
  footnote_semibold_12: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  footnote_regular_12: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  link_semibold_12: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textDecoration: 'underline',
  },
};
