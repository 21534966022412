import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgVisaV2Icon: FC<SVGIconProps> = (props) => {
  return (
    <svg width={32} height={20} viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.873 15.317h-2.611L12.893 5.18h2.612l-1.632 10.136ZM9.065 5.18l-2.49 6.972-.294-1.502-.878-4.557s-.107-.913-1.239-.913H.05L0 5.353s1.259.264 2.732 1.158L5 15.317h2.72l4.155-10.136h-2.81Zm20.537 10.136H32L29.91 5.18h-2.1c-.969 0-1.205.755-1.205.755l-3.894 9.38h2.722l.544-1.505h3.32l.305 1.506Zm-2.873-3.585 1.372-3.794.772 3.794h-2.144Zm-3.814-4.113.373-2.177S22.138 5 20.939 5c-1.295 0-4.372.572-4.372 3.355 0 2.619 3.611 2.652 3.611 4.027s-3.24 1.129-4.308.262l-.389 2.275s1.166.573 2.948.573 4.47-.933 4.47-3.47c0-2.636-3.644-2.881-3.644-4.027 0-1.146 2.543-.999 3.66-.376Z"
        fill="#2566AF"
      />
      <path
        d="m6.281 10.652-.879-4.559s-.106-.912-1.238-.912H.049L0 5.352s1.978.415 3.875 1.967c1.814 1.484 2.406 3.333 2.406 3.333Z"
        fill="#E6A540"
      />
    </svg>
  );
};

const Memo = memo(SvgVisaV2Icon);
export default Memo;
