import StyledText from 'UI/StyledText';
import React, { ChangeEvent, ForwardRefRenderFunction, InputHTMLAttributes, forwardRef } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';

type TagSize = 'small' | 'medium';

type TagProps = {
  size?: TagSize;
  text: string;
  count?: string | number;
  disabled?: boolean;
  className?: string;
  onSelectTag?: (e: ChangeEvent<HTMLInputElement>) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

const ModeTag: ForwardRefRenderFunction<HTMLInputElement, TagProps> = (props, ref) => {
  const {
    size = 'medium',
    text,
    count,
    disabled = false,
    checked,
    className,
    onSelectTag,
    onChange,
    ...inputProps
  } = props;
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  return (
    <Container
      $size={size}
      $checked={Boolean(checked)}
      className={className}
      $disabled={disabled}
      $isDarkMode={isDarkMode}>
      {text} {count && <Count $checked={Boolean(checked)}>{count}</Count>}
      <HiddenCheckbox
        type="checkbox"
        disabled={disabled}
        ref={ref}
        checked={checked}
        {...inputProps}
        onChange={(e) => {
          onChange?.(e);
          onSelectTag?.(e);
        }}
      />
    </Container>
  );
};

export default forwardRef<HTMLInputElement, TagProps>(ModeTag);

type TagContainerType = {
  $size: TagSize;
  $checked: boolean;
  $disabled: boolean;
  $isDarkMode?: boolean;
};

const Container = styled.label<TagContainerType>`
  ${({ theme: { colors, typography }, $size, $checked, $disabled, $isDarkMode }) => css`
    width: fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    padding: 5px 7px;
    background-color: ${$isDarkMode ? colors.neutrals[7] : colors.neutrals[10]};
    color: ${colors.neutrals[1]};

    ${typography.body_basic_semibold_14};

    ${$disabled &&
    css`
      cursor: not-allowed;
      color: ${colors.neutrals[4]};
    `}
    ${$checked &&
    css`
      color: ${colors.primary[1]};
      background-color: ${colors.primary[6]};
    `}
    ${$size === 'medium' &&
    css`
      padding: 7px 11px;
      ${typography.body_large_semibold_16};
    `}
    &:hover {
      border-color: ${!$disabled && !$checked && colors.neutrals[1]};
    }
  `};
`;

const Count = styled(StyledText)<{ $checked: boolean }>`
  display: inline;
  margin-left: 8px;
  ${({ theme: { colors, typography }, $checked }) => css`
    color: ${$checked ? colors.primary[1] : colors.neutrals[4]};
    ${typography.body_basic_regular_14}
  `}
`;

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
