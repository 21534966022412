import { StyledText } from 'UI';
import { FlashcardDtoLastAnswerEnum } from 'api/generated';
import useClientSize from 'hooks/useClientSize';
import React, { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { Content } from '../Content';

type CardType = {
  lastAnswer: FlashcardDtoLastAnswerEnum | null;
  index: number;
  imageQuestion?: { url: string; alt: string };
  textQuestion?: string;
  imageAnswer?: { url: string; alt: string };
  textAnswer?: string;
};

const Card: FC<CardType> = ({ lastAnswer, index, imageQuestion, textQuestion, imageAnswer, textAnswer }) => {
  const { getIsBreakpoint } = useClientSize();
  const { colors } = useTheme();

  const indicatorColorMapping = {
    [FlashcardDtoLastAnswerEnum.Yes]: colors.system.green,
    [FlashcardDtoLastAnswerEnum.No]: colors.system.red,
    [FlashcardDtoLastAnswerEnum.Kinda]: colors.system.blue,
  };

  const isWidthSm = getIsBreakpoint('sm');
  const cardNumber = index + 1;

  const indicatorColor = lastAnswer ? indicatorColorMapping[lastAnswer] : colors.neutrals[4];
  return (
    <Root>
      <SerialNumber>{cardNumber}</SerialNumber>
      <Container>
        <Question>
          {!isWidthSm && <Indicator $color={indicatorColor} />}
          <InnerContainer>
            <TitleContainer>
              <Title>Question</Title>
              {isWidthSm && <Indicator $color={indicatorColor} />}
            </TitleContainer>
            <Content image={imageQuestion} text={textQuestion} />
          </InnerContainer>
        </Question>
        <Answer>
          <InnerContainer>
            <Title>Answer</Title>
            <Content image={imageAnswer} text={textAnswer} />
          </InnerContainer>
        </Answer>
      </Container>
    </Root>
  );
};

export default Card;

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;

  ${respondToWidth.sm`
    justify-content: space-between;
  `}
`;

const Title = styled(StyledText)`
  text-align: center;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[4]};
    ${typography.footnote_semibold_12};
  `}

  ${respondToWidth.sm`
    text-align: left;
  `}
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${respondToWidth.sm`
    gap: 12px;
  `}
`;

const Indicator = styled.div<{ $color: string }>`
  min-height: 114px;
  height: 100%;
  width: 4px;
  border-radius: 2px;
  background-color: ${({ $color }) => $color};

  ${respondToWidth.sm`
    width: 8px;
    min-height: auto;
    height: 8px;
    border-radius: 4px;
  `}
`;

const Answer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding-left: 24px;

  ${respondToWidth.ls`
   padding-left: 12px;
  `}

  ${respondToWidth.sm`
    padding-left: 0;
  `}
`;

const Question = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding-right: 24px;
  border-right: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};

  ${respondToWidth.ls`
   padding-right: 12px;
  `}

  ${respondToWidth.sm`
    padding-right: 0;
    border-right: none;
  `}
`;

const Container = styled.div`
  display: flex;
  padding: 24px 20px 24px 12px;
  border-radius: 16px;
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};

  ${respondToWidth.ls`
   padding-right: 12px;
  `}

  ${respondToWidth.sm`
    flex-direction: column;
    padding: 12px;
    gap: 16px;
  `}
`;

const SerialNumber = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  ${respondToWidth.ls`
   display: none;
  `}
`;
