import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CustomerChapterResponseDto,
  CustomerGuideResponseDto,
  CustomerTableOfGuideContentResponseDto,
  GetChaptersWithNotesResponseDto,
  NoteResponseDto,
  SearchGuideTextResponseDto,
} from 'api/generated';

import {
  createNoteThunk,
  deleteNoteThunk,
  getChapterByIdThunk,
  getGuideByCourseIdThunk,
  getNotesThunk,
  getSearchGuideTextThunk,
  getTableOfContentForGuideThunk,
  updateNoteThunk,
} from './actions';

interface StudyGuideSlice {
  studyGuide: CustomerGuideResponseDto | null;
  currentChapter: CustomerChapterResponseDto | null;
  tableOfGuideContent: CustomerTableOfGuideContentResponseDto | null;
  notes: Array<GetChaptersWithNotesResponseDto> | null;
  isSelectingMode: boolean;
  selectedNoteId: string;
  foundText: SearchGuideTextResponseDto | null;
}

const initialState: StudyGuideSlice = {
  studyGuide: null,
  currentChapter: null,
  tableOfGuideContent: null,
  notes: null,
  isSelectingMode: true,
  selectedNoteId: '',
  foundText: null,
};

export const studyGuideSlice = createSlice({
  name: 'studyGuide',
  initialState,
  reducers: {
    setSelectedNoteId(state, action: PayloadAction<string>) {
      state.selectedNoteId = action.payload;
    },
    setStudyGuide(state, action: PayloadAction<CustomerGuideResponseDto>) {
      state.studyGuide = action.payload;
    },
    setIsSelectingMode(state, action: PayloadAction<boolean>) {
      state.isSelectingMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGuideByCourseIdThunk.fulfilled, (state, { payload }) => {
        state.studyGuide = payload;
      })
      .addCase(getTableOfContentForGuideThunk.fulfilled, (state, { payload }) => {
        state.tableOfGuideContent = payload;
      })
      .addCase(getNotesThunk.fulfilled, (state, { payload }) => {
        state.notes = payload;
      })
      .addCase(createNoteThunk.fulfilled, (state, { payload, meta: { arg } }) => {
        const { chapterId, unitId } = arg;
        const chapter = state.tableOfGuideContent?.chapters.find((chapter) => chapter.id === chapterId);
        const newChapter = { id: chapterId, name: chapter?.name || '', notes: [payload as NoteResponseDto] };

        if (state.notes?.length) {
          const isNewChapter = state.notes.every((chapter) => chapter.id !== chapterId);

          state.notes = isNewChapter
            ? [...state.notes, newChapter]
            : state.notes.map((chapter) =>
                chapter.id === chapterId
                  ? { ...chapter, notes: [...chapter.notes, payload as NoteResponseDto] }
                  : chapter,
              );
        } else {
          state.notes = [newChapter];
        }

        if (state.currentChapter) {
          if (unitId) {
            state.currentChapter.units = state.currentChapter.units.map((unit) =>
              unit.id === unitId ? { ...unit, notes: [...(unit.notes || []), payload] } : unit,
            );
          } else {
            state.currentChapter.notes = [...(state.currentChapter?.notes || []), payload];
          }
        }
      })
      .addCase(updateNoteThunk.fulfilled, (state, { payload }) => {
        state.notes =
          state.notes?.map((chapter) => ({
            ...chapter,
            notes: chapter.notes.map((note) => (note.id === payload.id ? (payload as NoteResponseDto) : note)),
          })) || null;

        if (state.currentChapter) {
          state.currentChapter.units =
            state.currentChapter?.units?.map((unit) => ({
              ...unit,
              notes: unit?.notes?.map((note) => (note.id === payload.id ? payload : note)) || null,
            })) || null;

          state.currentChapter.notes =
            state.currentChapter?.notes?.map((note) => (note.id === payload.id ? payload : note)) || null;
        }
      })
      .addCase(deleteNoteThunk.fulfilled, (state, { meta: { arg } }) => {
        const { noteId } = arg;

        state.notes =
          state.notes
            ?.map((chapter) => ({
              ...chapter,
              notes: chapter.notes.filter((note) => note.id !== noteId),
            }))
            .filter((chapter) => !!chapter.notes.length) || null;

        if (state.currentChapter) {
          state.currentChapter.units =
            state.currentChapter?.units?.map((unit) => ({
              ...unit,
              notes: unit?.notes?.filter((note) => note.id !== noteId) || null,
            })) || null;

          state.currentChapter.notes = state.currentChapter?.notes?.filter((note) => note.id !== noteId) || null;
        }
      })
      .addCase(getChapterByIdThunk.pending, (state) => {
        state.currentChapter = null;
      })
      .addCase(getChapterByIdThunk.fulfilled, (state, { payload }) => {
        state.currentChapter = payload;
      })
      .addCase(getSearchGuideTextThunk.pending, (state) => {
        state.foundText = null;
      })
      .addCase(getSearchGuideTextThunk.fulfilled, (state, { payload }) => {
        state.foundText = payload;
      });
  },
});

export const actions = {
  ...studyGuideSlice.actions,
  getGuideByCourseId: getGuideByCourseIdThunk,
  getTableOfContentForGuide: getTableOfContentForGuideThunk,
  getNotes: getNotesThunk,
  createNote: createNoteThunk,
  updateNote: updateNoteThunk,
  deleteNote: deleteNoteThunk,
  getChapterById: getChapterByIdThunk,
  getGuideSearchText: getSearchGuideTextThunk,
};

export const { reducer } = studyGuideSlice;
