import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import { selectors, useAppSelector } from 'store';
import { CSSProp, useTheme } from 'styled-components';

import { HiddenCheckbox, LabelInput, Wrapper } from './styled';

export type RadioButtonProps = {
  className?: string;
  isCorrect?: boolean;
  label?: string;
  color?: string;
  labelCSS?: CSSProp;
} & InputHTMLAttributes<HTMLInputElement>;

const RadioButton: ForwardRefRenderFunction<HTMLInputElement, RadioButtonProps> = (props, ref) => {
  const { colors } = useTheme();
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const {
    checked,
    disabled,
    label,
    className,
    color = colors.primary[1],
    labelCSS = {},
    isCorrect = false,
    ...inputProps
  } = props;

  return (
    <Wrapper className={className}>
      <HiddenCheckbox
        type="radio"
        ref={ref}
        checked={checked}
        disabled={!!disabled}
        id={props.id}
        $color={color}
        $isCorrect={isCorrect}
        {...inputProps}
      />
      <LabelInput $CSS={labelCSS} htmlFor={props.id} $isDisabled={Boolean(disabled)} $isDarkMode={isDarkMode}>
        {label}
      </LabelInput>
    </Wrapper>
  );
};

export default React.forwardRef<HTMLInputElement, RadioButtonProps>(RadioButton);
