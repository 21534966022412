import { CloseIcon, SearchIcon } from 'assets/icons';
import React, { ForwardRefRenderFunction, InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import { selectors, useAppSelector } from 'store';
import { CSSProp, useTheme } from 'styled-components';
import { VariantTypography } from 'types';

import {
  ErrorField,
  IconContainer,
  InputWrapper,
  Label,
  SearchIconContainer,
  StyledInput,
  VariantInput,
  Wrapper,
} from './styled';

export type InputProps = {
  $paddingButton?: string;
  label?: string;
  error?: string;
  variant?: VariantInput;
  isCorrect?: boolean;
  $variantTypography?: VariantTypography;
  className?: string;
  $CSS?: CSSProp;
  showIcon?: boolean;
  iconComponent?: ReactNode;
  onIconClick?: () => void;
  iconButtonType?: 'submit' | 'reset' | 'button';
} & InputHTMLAttributes<HTMLInputElement>;

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    $paddingButton,
    label,
    error,
    variant = 'default',
    isCorrect,
    $variantTypography,
    showIcon = false,
    iconComponent,
    onIconClick,
    className,
    $CSS,
    iconButtonType = 'button',
    ...props
  },
  ref,
) => {
  const { colors } = useTheme();
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  //TODO: по хорошему надо рефакторить инпут полностью, там кринж сделан. Поэтому для быстроты, я просто прокинул пропс с паддингом

  return (
    <Wrapper className={className} $CSS={$CSS}>
      {label && <Label $disabled={props.disabled}>{label}</Label>}
      <InputWrapper>
        {variant === 'search' && (
          <SearchIconContainer>
            <SearchIcon size={24} color={colors.neutrals[4]} />
          </SearchIconContainer>
        )}
        <StyledInput
          $paddingButton={$paddingButton}
          $isCorrect={isCorrect}
          $isError={Boolean(error)}
          $variant={variant}
          $variantTypography={$variantTypography}
          $withIcon={showIcon}
          $isDarkMode={isDarkMode}
          ref={ref}
          placeholder={variant === 'search' ? 'Search' : props.placeholder}
          {...props}
        />
        {showIcon && (
          <IconContainer onClick={onIconClick} type={iconButtonType}>
            {iconComponent ? iconComponent : <CloseIcon size={24} color={colors.neutrals[4]} />}
          </IconContainer>
        )}
      </InputWrapper>
      {error && <ErrorField>{error}</ErrorField>}
    </Wrapper>
  );
};

export default forwardRef<HTMLInputElement, InputProps>(Input);
