import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  CompleteFlashcardsPackResponseDto,
  CreateFlashcardAnswerDto,
  FlashcardsDto,
  GetCurrentResultsResponseDto,
} from 'api/generated';
import { AnswerType, DefaultRejectValue, ExtraParamsThunkType, FlashcardsPackType, PaginationType } from 'types';

type AnswerFlashcardThunkRequest = {
  flashcardId: string;
  answer: CreateFlashcardAnswerDto;
};

export const answerFlashcardThunk = createAsyncThunk<
  undefined,
  AnswerFlashcardThunkRequest,
  ExtraParamsThunkType<DefaultRejectValue>
>('flashcards/answerFlashcard', async ({ flashcardId, answer }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Flashcards.flashcardControllerSaveFlashcardAnswer(flashcardId, answer);

    if (status > 399) {
      throw data;
    }
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

type GetFlashcardsBody = {
  onlyLearned?: boolean;
  answer?: AnswerType;
  onlyAvailable?: boolean;
  packType: FlashcardsPackType;
  packId?: string;
} & PaginationType;

// for page content
export const getFlashcardsThunk = createAsyncThunk<
  { data: FlashcardsDto; isFirstFetch: boolean },
  GetFlashcardsBody,
  ExtraParamsThunkType<DefaultRejectValue>
>(
  'flashcards/getFlashcards',
  async ({ packId, page, limit = 20, onlyLearned, answer, onlyAvailable = true, packType }, { rejectWithValue }) => {
    try {
      const isFirstFetch = !page || page === 1;

      const { data, status } = await api.Flashcards.flashcardControllerGetFlashcards(
        packType,
        page,
        limit,
        packId,
        onlyLearned,
        answer,
        onlyAvailable,
      );

      if (status > 399) {
        throw data;
      }

      return { data: data as FlashcardsDto, isFirstFetch };
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

// for modal all flashcards
export const getFlashcardsPackThunk = createAsyncThunk<
  { data: FlashcardsDto; isFirstFetch: boolean },
  GetFlashcardsBody,
  ExtraParamsThunkType<DefaultRejectValue>
>(
  'flashcards/getFlashcardsPack',
  async ({ packId, page, limit = 20, onlyLearned, answer, onlyAvailable = false, packType }, { rejectWithValue }) => {
    try {
      const isFirstFetch = !page || page === 1;

      const { data, status } = await api.Flashcards.flashcardControllerGetFlashcards(
        packType,
        page,
        limit,
        packId,
        onlyLearned,
        answer,
        onlyAvailable,
      );

      if (status > 399) {
        throw data;
      }

      return { data: data as FlashcardsDto, isFirstFetch };
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const getFlashcardsPackResultThunk = createAsyncThunk<
  GetCurrentResultsResponseDto,
  { packType: FlashcardsPackType; packId?: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('flashcards/getResultPack', async ({ packType, packId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Flashcards.flashcardControllerGetCurrentResults(packType, packId);

    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

type ResetType = {
  packType: FlashcardsPackType;
  packId?: string;
};

export const resetFlashcardsResultThunk = createAsyncThunk<
  undefined,
  ResetType,
  ExtraParamsThunkType<DefaultRejectValue>
>('flashcards/resetResult', async ({ packType, packId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Flashcards.flashcardControllerResetFlashcardResultsByPack(packType, packId);

    if (status > 399) {
      throw data;
    }
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const completeFlashcardsPackThunk = createAsyncThunk<
  CompleteFlashcardsPackResponseDto,
  { packId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('flashcards/completePack', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Flashcards.flashcardControllerCompleteFlashcardsPack(body.packId);

    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
