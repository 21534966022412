import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgSuccessIcon: FC<SVGIconProps> = ({ size = 132, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m56 81.86 45.96-45.965 7.075 7.07L56 96 24.18 64.18l7.07-7.07L56 81.86Z" fill="#55B47D" />
    </svg>
  );
};

const Memo = memo(SvgSuccessIcon);
export default Memo;
