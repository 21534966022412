import { FullScreenModal, IconButton, StyledText } from 'UI';
import { FullscreenIcon } from 'assets/icons';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useIsLandscapeOrientation } from 'hooks/useIsLandscapeOrientation';
import { LayoutStyledProps } from 'pages/MnemonicCardPractice/MnemonicCardPractice';
import React, { FC, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import ControlBar from '../ControlBar';
import { Container, Main, Wrapper } from '../commonStyles';

type MnemonicImageProps = {
  onClick: () => void;
  url: string;
  alt: string;
  onNextClick: () => void;
  onPrevClick: () => void;
  onReportClick: () => void;
  isFirstSlide: boolean;
  isLastSlide: boolean;
  isFlipped: boolean;
};

const MnemonicImage: FC<MnemonicImageProps> = ({
  onClick,
  url,
  alt,
  onNextClick,
  onPrevClick,
  onReportClick,
  isFirstSlide,
  isLastSlide,
  isFlipped,
}) => {
  const [isShowFullscreenModal, setIsShowFullscreenModal] = useState(false);
  const { colors } = useTheme();
  const { isLandscapeOrientation } = useIsLandscapeOrientation();
  const { isMobile } = useGetIsMobile();
  const isMobileLandscape = isLandscapeOrientation && isMobile;

  return (
    <Container $isMobileLandscape={isMobileLandscape}>
      <Cover $isMobileLandscape={isMobileLandscape}>
        <Main $isCard $isLastSlide={isLastSlide} $isMobileLandscape={isMobileLandscape}>
          <Wrapper $isCard $isMobileLandscape={isMobileLandscape}>
            <ImageContainer $isMobileLandscape={isMobileLandscape}>
              <Image src={url} alt={alt} />
            </ImageContainer>
            <ButtonContainer $isMobileLandscape={isMobileLandscape}>
              <Text>Mnemonic card</Text>
              <IconButton
                iconComponent={
                  <FullscreenIcon color={colors.neutrals[6]} onClick={() => setIsShowFullscreenModal(true)} />
                }
              />
            </ButtonContainer>
          </Wrapper>
        </Main>
        <ControlBar
          onShowClick={onClick}
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
          onReportClick={onReportClick}
          isFirstSlide={isFirstSlide}
          isLastSlide={isLastSlide}
          isFlipped={isFlipped}
        />
      </Cover>
      <FullScreenModal
        isOpen={isShowFullscreenModal}
        onRequestClose={() => setIsShowFullscreenModal(false)}
        containerCSS={FullscreenContainerCSS}>
        <FullScreenImage $isMobileLandscape={isMobileLandscape} src={url} alt={alt} />
      </FullScreenModal>
    </Container>
  );
};

export default MnemonicImage;

const ImageContainer = styled.div<LayoutStyledProps>`
  width: 100%;
  max-width: 816px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ $isMobileLandscape }) => ($isMobileLandscape ? 0 : '32px')};
  position: relative;
  flex-grow: 1;

  ${respondToWidth.sm`
    margin-bottom: 0;
  `}
`;

const Image = styled.img`
  border-radius: 12px;
  top: 0;
  bottom: 0;
  object-fit: contain;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: block;
`;

const Cover = styled.div<LayoutStyledProps>`
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      background-color: inherit;
      flex-grow: 1;
      justify-content: space-between;
    `}

  ${respondToWidth.sm`
    background-color: inherit;
    flex-grow:1;
    justify-content: space-between;
  `}
`;

const ButtonContainer = styled.div<LayoutStyledProps>`
  display: ${({ $isMobileLandscape }) => ($isMobileLandscape ? 'flex' : 'none')};
  width: 100%;
  padding: 0 6px;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  ${respondToWidth.sm`
    display: flex;
  `}
`;

const Text = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_large_bold_16}
`;

const FullScreenImage = styled(Image)<LayoutStyledProps>`
  top: 50%;
  bottom: auto;
  width: 100%;
  height: auto;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      bottom: 0;
      width: auto;
      height: 100%;
    `}
`;

const FullscreenContainerCSS = css`
  @media (orientation: landscape) {
    height: calc(100% - 40px);
  }
`;
