import { selectors, useAppSelector } from 'store';
import useSound from 'use-sound';

const useSoundController = (src: string | string[]) => {
  const turnOnSoundsInApp = useAppSelector(selectors.settings.turnOnSoundsInApp);

  const [play] = useSound(src);
  const playSound = () => {
    if (turnOnSoundsInApp) {
      play();
    }
  };
  return { playSound };
};
export default useSoundController;
