import StyledText from 'UI/StyledText';
import { LoaderIcon } from 'assets/icons';
import styled, { keyframes } from 'styled-components';

type StyledIconButtonProps = {
  $isLoading: boolean;
  $isMobile: boolean;
};

export const StyledButton = styled.button<StyledIconButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: min-content;
  min-height: 36px;
  min-width: 36px;
  padding: 5px;
  cursor: ${({ $isLoading }) => ($isLoading ? 'wait' : 'pointer')};
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 8px;
  background-color: inherit;

  &:hover {
    border-color: ${({ theme: { colors }, $isLoading, $isMobile }) =>
      !$isMobile && ($isLoading ? colors.neutrals[9] : colors.primary[4])};
  }

  &:disabled {
    cursor: not-allowed;
    border-color: ${({ theme: { colors } }) => colors.neutrals[9]};
    svg > path {
      fill: ${({ theme: { colors } }) => colors.neutrals[6]};
    }
  }

  &:active:enabled {
    border-color: ${({ theme: { colors }, $isLoading }) => ($isLoading ? colors.neutrals[9] : colors.primary[1])};
  }
`;

const rotate = keyframes`
from {
    transform: rotate(0deg);
  }

to {
    transform: rotate(360deg);
  }
`;

export const StyledLoader = styled(LoaderIcon)`
  animation: ${rotate} 2s linear infinite;
`;

export const IconText = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_bold_14};
  padding-right: 7px;
`;
