import { ModeTag, StyledText } from 'UI';
import { GetQuestionDomainsResponseItem, PaymentDtoLevelEnum } from 'api/generated';
import { useFormWatch } from 'hooks';
import { TestFormType } from 'pages/TestList/TestList';
import { ChangeEvent, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { selectors, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { ModeType } from 'types';

export enum ModeTypeEnum {
  ALL = 'ALL',
  MARKED = 'MARKED',
  UNUSED = 'UNUSED',
  INCORRECT = 'INCORRECT',
}

const QuestionMode = () => {
  const subjects = useAppSelector(selectors.tests.selectDomainsAndBanks);
  const paymentLevel = useAppSelector(selectors.courses.selectCurrentPaymentLevel);
  const isPaidPaymentLevel = paymentLevel !== PaymentDtoLevelEnum.None;

  const calculateQuestionsCount = (subjects: GetQuestionDomainsResponseItem[]) => {
    const questionsCount = {
      all: 0,
      marked: 0,
      unused: 0,
      incorrect: 0,
    };

    subjects?.forEach((subject) => {
      questionsCount.all += isPaidPaymentLevel ? subject.all.total : subject.all.free;
      questionsCount.marked += isPaidPaymentLevel ? subject.marked.total : subject.marked.free;
      questionsCount.unused += isPaidPaymentLevel ? subject.unused.total : subject.unused.free;
      questionsCount.incorrect += isPaidPaymentLevel ? subject.incorrect.total : subject.incorrect.free;
    });

    return questionsCount;
  };

  const { all, incorrect, marked, unused } = useMemo(() => calculateQuestionsCount(subjects), [subjects]);

  const tags = [
    { text: 'All', count: all, modeType: ModeTypeEnum.ALL },
    { text: 'Marked', count: marked, modeType: ModeTypeEnum.MARKED },
    { text: 'Unused', count: unused, modeType: ModeTypeEnum.UNUSED },
    { text: 'Incorrect', count: incorrect, modeType: ModeTypeEnum.INCORRECT },
  ];

  const { register, watch, setValue } = useFormContext<TestFormType>();
  const watchField = useFormWatch(watch);
  const mode = watchField('mode');

  const customChecked = (value: ModeTypeEnum) => Boolean(mode?.find((mode) => mode === value));

  /**
   * This function lets pick Incorrect and Unused modes together
   * @param e
   * @return void
   */
  const reduceMode = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as ModeType;
    const isValueIncorrectOrUnused = value === ModeTypeEnum.INCORRECT || value === ModeTypeEnum.UNUSED;

    const modes = mode.reduce((init, next: ModeType) => {
      const isEmpty = !init.length;
      const isModeIncorrectOrUnused =
        (next === ModeTypeEnum.INCORRECT || next === ModeTypeEnum.UNUSED) && value !== next;

      if (isEmpty) {
        init.push(value);
      }

      if (isValueIncorrectOrUnused && isModeIncorrectOrUnused && mode.length !== 2) {
        init = [ModeTypeEnum.INCORRECT, ModeTypeEnum.UNUSED];
      } else {
        init = [value];
      }

      return init;
    }, [] as ModeType[]);

    setValue('mode', modes);
  };

  return (
    <Root>
      <TitleContainer>
        <Title>Question mode</Title>
      </TitleContainer>
      <ButtonsContainer>
        {tags.map((tag) => (
          <StyledTag
            key={tag.modeType}
            text={tag.text}
            count={subjects?.length ? tag.count.toString() : '...'}
            value={tag.modeType}
            checked={customChecked(tag.modeType)}
            onSelectTag={(e) => reduceMode(e)}
            {...register('mode')}
          />
        ))}
      </ButtonsContainer>
    </Root>
  );
};

export default QuestionMode;

const StyledTag = styled(ModeTag)`
  padding-block: 7px;
  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.body_basic_semibold_14};
    padding: 5px 11px;
  `};
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_large_bold_16};
`;

const ButtonsContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  position: relative;
`;

const TitleContainer = styled.div``;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 24px 20px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
  position: relative;

  ${respondToWidth.sm`
    border-radius: 0;
    padding: 0;
    gap: 16px;
  `}
`;
