import { StyledText } from 'UI';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type EmptyColumnType = {
  text: string;
};

const EmptyColumn: FC<EmptyColumnType> = ({ text }) => {
  return (
    <Container>
      <Text>{text}</Text>
    </Container>
  );
};

export default EmptyColumn;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  display: grid;
  place-items: center;
  padding: 16px;
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 12px;

  ${respondToWidth.sm`
    height: calc(100% - 32px);
    padding:5px;
    max-height:227px;
    border-radius: 8px;
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[4]};
    ${respondToWidth.sm`
        ${typography.footnote_regular_12};
    `}
  `}
`;
