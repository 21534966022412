import { LeftCaret, RightCaret } from 'UI/NavButton/styled';
import { monthsAdapter } from 'constant';
import React, { FC } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import styled from 'styled-components';

import ItemList from '../../../ItemList';
import ListRenderItem from '../ListRenderItem';
import { useCustomHeader } from './hooks';

type CustomHeaderProps = {
  years: string[];
} & ReactDatePickerCustomHeaderProps;

const CustomHeader: FC<CustomHeaderProps> = (props) => {
  const { months, handleMonthChange, handlePrevMonth, handleYearChange, handleNextMonth, selectedMonth, selectedYear } =
    useCustomHeader(props);

  return (
    <Container>
      <Wrapper>
        <StyledLeftCaret width={20} onClick={handlePrevMonth} />
        <ItemList
          arr={months}
          selectedItem={monthsAdapter[selectedMonth]}
          RenderItem={({ value: monthValue }) => (
            <ListRenderItem
              value={monthValue}
              isActive={monthValue === monthsAdapter[selectedMonth]}
              onItemClick={handleMonthChange}
            />
          )}
        />
      </Wrapper>
      <Wrapper>
        <ItemList
          arr={props.years}
          selectedItem={selectedYear}
          RenderItem={({ value: monthValue }) => (
            <ListRenderItem value={monthValue} isActive={monthValue === selectedYear} onItemClick={handleYearChange} />
          )}
        />
        <StyledRightCaret width={20} onClick={handleNextMonth} />
      </Wrapper>
    </Container>
  );
};

export default CustomHeader;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const StyledLeftCaret = styled(LeftCaret)`
  cursor: pointer;
`;

const StyledRightCaret = styled(RightCaret)`
  cursor: pointer;
`;
