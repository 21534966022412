import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  CustomerChangeEmailDto,
  CustomerConfirmEmailDto,
  CustomerDto,
  CustomerSignUpResponseDto,
  ForgottenPasswordDto,
  PatchPasswordDto,
  PatchProfileDto,
} from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType } from 'types';

export const getMeThunk = createAsyncThunk<CustomerDto, undefined, ExtraParamsThunkType<DefaultRejectValue>>(
  'user/getMe',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.Customer.customersControllerMe();
      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const patchProfileThunk = createAsyncThunk<
  CustomerDto,
  PatchProfileDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('user/changeFullName', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Customer.customersControllerPatchProfile(body);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const changePasswordThunk = createAsyncThunk<
  undefined,
  PatchPasswordDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('user/changePassword', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Customer.customersControllerPatchPassword(body);

    if (status > 399) {
      throw data;
    }
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const deleteAccountThunk = createAsyncThunk<undefined, undefined, ExtraParamsThunkType<DefaultRejectValue>>(
  'user/deleteAccount',
  async (body, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Customer.customersControllerDeleteAccount();

      if (status > 399) {
        throw data;
      }
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const changeEmailThunk = createAsyncThunk<
  CustomerChangeEmailDto,
  CustomerChangeEmailDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('user/changeEmail', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Auth.customerAuthControllerChangeEmail(body);

    if (status > 399) {
      throw data;
    }
    return body;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const confirmEmailThunk = createAsyncThunk<
  CustomerDto,
  CustomerConfirmEmailDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('user/confirm-email', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Auth.customerAuthControllerConfirmEmail(body);
    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const deletePaymentMethodThunk = createAsyncThunk<
  undefined,
  undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('user/delete-payment-method', async (_, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Customer.customersControllerDeletePaymentMethod();
    if (status > 399) {
      throw data;
    }
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const updateForgottenPasswordThunk = createAsyncThunk<
  CustomerSignUpResponseDto,
  ForgottenPasswordDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('user/update-forgotten-password', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Auth.customerAuthControllerUpdateForgottenPassword(body);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
