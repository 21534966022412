import { ScrollbarContainer, StyledText } from 'UI';
import { PaginationMetaDto } from 'api/generated';
import { useInfinityScroll } from 'hooks';
import { FC } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import styled, { useTheme } from 'styled-components';

import { DataType } from '../../StatisticModal';
import StatisticItem from '../StatisticItem';

type ModalContentProps = {
  title: string;
  data: DataType[] | null;
  meta: PaginationMetaDto | null;
  isLoading: boolean;
  isColorReverse?: boolean;
  getMoreStatistics: (page: number) => Promise<void>;
  subtitlePath: string | null;
};

const ModalContent: FC<ModalContentProps> = ({
  title,
  data,
  meta,
  isLoading,
  isColorReverse,
  getMoreStatistics,
  subtitlePath,
}) => {
  const { colors } = useTheme();
  const nextPage = (meta?.currentPage || 0) + 1;
  const hasStatisticsMore = (meta?.currentPage || 0) < (meta?.totalPages || 0);

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getMoreStatistics(nextPage),
    hasAfterMore: hasStatisticsMore,
    isLoading,
  });
  return (
    <>
      <TitleContainer>
        <Title as="h1">{title}</Title>
        <Subtitle>
          Pay attention to the <RedText>red</RedText> progress bar, here you have {subtitlePath}
        </Subtitle>
      </TitleContainer>
      <Wrapper ref={listRef} onScroll={handleScroll}>
        {data?.map((item) => (
          <StatisticItem
            key={item.id || item.packId}
            title={item.name || item.packName || ''}
            percent={parseFloat(item.percent.toFixed(1))}
            count={item.count || 0}
            isColorReverse={isColorReverse}
          />
        ))}
        {isLoading && <ThreeDots width={48} height={48} color={colors.primary[3]} />}
      </Wrapper>
    </>
  );
};

export default ModalContent;

const TitleContainer = styled.div`
  margin-bottom: 24px;
`;

const Title = styled(StyledText)`
  text-align: start;
  ${({ theme: { typography } }) => typography.headline_semibold_18};
`;

const Subtitle = styled(Title)`
  margin-top: 16px;
  ${({ theme: { typography } }) => typography.subhead_medium_13};
`;

const RedText = styled(Subtitle)`
  color: ${({ theme: { colors } }) => colors.system.red};
  display: inline;
`;

const Wrapper = styled(ScrollbarContainer)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
  padding-right: 16px;
  align-items: center;
`;
