import { StyledText } from 'UI';
import { FlashcardPackStatsDto } from 'api/generated';
import StatisticColumn from 'pages/Performance/components/StatisticColumn';
import { ForwardRefRenderFunction, forwardRef } from 'react';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { StatisticColumnType } from 'types';
import { hideText } from 'utils';

type StatisticColumnProps = {
  data?: FlashcardPackStatsDto;
  $width: number;
  onPress: (name: string) => void;
};

const StatisticColumns: ForwardRefRenderFunction<HTMLDivElement, StatisticColumnProps> = (
  { data, $width, onPress },
  ref,
) => {
  const handlePress = () => {
    onPress(data?.packName || '');
  };

  return (
    <Container $width={$width} onClick={handlePress} ref={ref}>
      <Wrapper>
        <StatisticColumn type={StatisticColumnType.UNUSED} percent={data?.unanswered.percent || 0} />
        <StatisticColumn type={StatisticColumnType.NO} percent={data?.no.percent || 0} />
        <StatisticColumn type={StatisticColumnType.KINDA} percent={data?.kinda.percent || 0} />
        <StatisticColumn type={StatisticColumnType.YES} percent={data?.yes.percent || 0} />
      </Wrapper>
      <Title>{data?.packName}</Title>
    </Container>
  );
};

export default forwardRef<HTMLDivElement, StatisticColumnProps>(StatisticColumns);

const Container = styled.div<{ $width: number }>`
  height: 100%;
  width: ${({ $width }) => `${$width}px`};
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const Wrapper = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 16px;
  flex-grow: 1;

  ${respondToWidth.sm`
    max-height:227px;
    padding:8px;
    border-radius: 8px;
  `}
`;

const Title = styled(StyledText)`
  height: 40px;
  ${({ theme: { typography } }) => respondToWidth.sm`
    height: auto;
    ${hideText(1)};
    ${typography.footnote_regular_12};
  `}
`;
