import { StyledText } from 'UI';
import ScrollbarContainer from 'UI/ScrollbarContainer';
import { SearchTestPassagesRequestDto } from 'api/generated';
import { MarkIcon } from 'assets/icons';
import { useAsyncAction, useInfinityScroll, useNotifications } from 'hooks';
import { TestsListFormType } from 'pages/Performance/components/Header/Header';
import { FC, useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';

type TestListProps = {
  dateFilters: TestsListFormType | null;
  setTitle: (value: string) => void;
  selectTest?: (id: string) => void;
  isLoading: boolean;
};

type TestItemType = {
  title: string;
  id: string;
};

const allTest = {
  title: 'All tests',
  id: 'all',
};

const TestList: FC<TestListProps> = ({ dateFilters, setTitle, selectTest, isLoading }) => {
  const [selectedTest, setSelectedTest] = useState<string>('all');
  const { errorToast } = useNotifications();
  const dispatch = useAppDispatch();

  const { colors } = useTheme();

  const tests = useAppSelector(selectors.performance.selectTestsList);
  const [getTestsListAction, isTestsListLoading] = useAsyncAction(actions.performance.getTestsList);
  const testsListMeta = useAppSelector(selectors.performance.selectTestsListMeta);
  const selectedTestId = useAppSelector(selectors.performance.selectCurrentTestId);
  const isShowAllTestsStatistic = useAppSelector(selectors.performance.selectIsShowAllTestsStatistic);
  const course = useAppSelector((store) => store.courses.activeCourse);
  const courseId = course?.id;

  const nextPage = (testsListMeta?.currentPage || 0) + 1;
  const hasMore = (testsListMeta?.currentPage || 0) < (testsListMeta?.totalPages || 0);

  const getTestsList = async (props: SearchTestPassagesRequestDto) => {
    try {
      await getTestsListAction(props);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    getTestsList({
      page: 1,
      limit: 20,
      courseId: courseId || '',
    });
  }, []);

  const getAfterData = () => {
    getTestsList({
      page: nextPage,
      limit: 20,
      startDate: dateFilters?.startDate,
      endDate: dateFilters?.endDate,
      courseId: courseId || '',
    });
  };

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: getAfterData,
    hasAfterMore: hasMore,
    isLoading: isTestsListLoading,
  });

  const handleSelectTest = (test: TestItemType) => {
    dispatch(actions.performance.setIsShowAllTestsStatistic(false));
    setTitle(test.title);
    setSelectedTest(test.id);
    selectTest?.(test.id);
  };

  const onAllTestsClick = () => {
    dispatch(actions.performance.setIsShowAllTestsStatistic(true));
    setTitle(allTest.title);
    setSelectedTest(allTest.id);
  };

  const isInitialLoadingTest = (isTestsListLoading && !tests?.length) || isLoading;

  useEffect(() => {
    if (isShowAllTestsStatistic) {
      setSelectedTest(allTest.id);
    } else if (selectedTestId) {
      setSelectedTest(selectedTestId);
    }
  }, [isShowAllTestsStatistic, selectedTestId]);

  if (isInitialLoadingTest) {
    return (
      <LoaderContainer>
        <ThreeDots color={colors.primary[3]} width={48} height={48} />
      </LoaderContainer>
    );
  }

  if (!tests?.length) {
    return <EmptyTests>You haven’t taken the tests yet</EmptyTests>;
  }

  return (
    <TestsList ref={listRef} onScroll={handleScroll}>
      <TestItem onClick={onAllTestsClick}>
        <TestText>{allTest.title}</TestText>
        {selectedTest === allTest.id && <MarkIcon size={24} color={colors.primary[1]} style={{ minWidth: 24 }} />}
      </TestItem>
      {tests?.map((item) => (
        <TestItem onClick={() => handleSelectTest(item)} key={item.id}>
          <TestText>{item.title}</TestText>
          {selectedTest === item.id && <MarkIcon size={24} color={colors.primary[1]} style={{ minWidth: 24 }} />}
        </TestItem>
      ))}
      {isTestsListLoading && (
        <LoaderContainer>
          <ThreeDots color={colors.primary[3]} width={48} height={48} />
        </LoaderContainer>
      )}
    </TestsList>
  );
};

export default TestList;

const TestsList = styled(ScrollbarContainer)`
  width: calc(100% + 12px);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;
`;

const TestItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;
`;

const TestText = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_large_medium_16};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  padding-right: 10px;
`;

const EmptyTests = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  color: ${({ theme: { colors } }) => colors.neutrals[5]};
  ${({ theme: { typography } }) => typography.body_large_medium_16};
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;
