import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  FlashcardsBaseStatsDto,
  PaginatedFlashcardPacksStatsDto,
  PaginatedSingleAnswerFlashcardPacksStatsDto,
  PaginatedTestAnswerSplitStatsDto,
  PaginatedTestSplitStatsDto,
  SearchTestPassagesRequestDto,
  SearchTestPassagesResponseDto,
  TestPassageStatsDto,
  TestsBaseStatsDto,
  TotalPointsDto,
} from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType, PaginationType } from 'types';

import { FlashcardsStatisticValuesType, SplitType, TestProgressType } from './types';

export const getCourseStatisticThunk = createAsyncThunk<TotalPointsDto, null, ExtraParamsThunkType<DefaultRejectValue>>(
  'performance/statisticPoints',
  async (_, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Statistics.pointsControllerGetPoints();
      if (status > 399) {
        throw data;
      }
      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const getCourseStatisticByCourseIdThunk = createAsyncThunk<
  TotalPointsDto,
  { courseId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('performance/statisticPoints', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Statistics.pointsControllerGetCoursePoints(courseId);
    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getStatisticByTestsThunk = createAsyncThunk<
  TestsBaseStatsDto,
  undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('performance/getStatisticByTests', async (_, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Statistics.testStatisticsControllerGetTestsStatisticsTotal();

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getStatisticByTestsLegendThunk = createAsyncThunk<
  TestPassageStatsDto,
  { id: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('performance/getStatisticByTestsLegend', async ({ id }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Statistics.testStatisticsControllerGetTestPassageStatistics(id);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getTestStatisticByAnswerThunk = createAsyncThunk<
  { data: PaginatedTestAnswerSplitStatsDto; isFirstFetch: boolean },
  { type: TestProgressType; split: SplitType } & PaginationType,
  ExtraParamsThunkType<DefaultRejectValue>
>('performance/getTestStatisticByAnswer', async ({ type, split, page, limit = 20 }, { rejectWithValue }) => {
  try {
    const isFirstFetch = !page || page === 1;

    const { data, status } = await api.Statistics.testStatisticsControllerGetTestsStatisticsSplit(
      split,
      type,
      page,
      limit,
    );

    if (status > 399) {
      throw data;
    }

    return { data, isFirstFetch };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getStatisticByFlashcardsThunk = createAsyncThunk<
  FlashcardsBaseStatsDto,
  undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('performance/getStatisticByFlashcards', async (_, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Statistics.flashcardStatisticsControllerGetFlashcardsStatisticsTotal();

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getFlashcardsStatisticByAnswerThunk = createAsyncThunk<
  { data: PaginatedSingleAnswerFlashcardPacksStatsDto; isFirstFetch: boolean },
  { type: FlashcardsStatisticValuesType } & PaginationType,
  ExtraParamsThunkType<DefaultRejectValue>
>('performance/getFlashcardsStatisticByAnswer', async ({ type, page, limit = 20 }, { rejectWithValue }) => {
  try {
    const isFirstFetch = !page || page === 1;

    const { data, status } = await api.Statistics.flashcardStatisticsControllerGetFlashcardStatisticsByAnswerType(
      type,
      page,
      limit,
    );

    if (status > 399) {
      throw data;
    }

    return { data, isFirstFetch };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getStatisticByFlashcardsPacksThunk = createAsyncThunk<
  { data: PaginatedFlashcardPacksStatsDto; isFirstFetch: boolean },
  PaginationType,
  ExtraParamsThunkType<DefaultRejectValue>
>('performance/getFlashcardsPacksStatistic', async ({ page, limit = 20 }, { rejectWithValue }) => {
  try {
    const isFirstFetch = !page || page === 1;

    const { data, status } = await api.Statistics.flashcardStatisticsControllerGetFlashcardStatisticsPaginated(
      page,
      limit,
    );

    if (status > 399) {
      throw data;
    }
    return { data, isFirstFetch };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getStatisticTestByIdThunk = createAsyncThunk<
  { data: PaginatedTestSplitStatsDto; isFirstFetch: boolean },
  { id: string; split: SplitType } & PaginationType,
  ExtraParamsThunkType<DefaultRejectValue>
>('performance/getStatisticTest', async ({ id, split, page, limit = 20 }, { rejectWithValue }) => {
  try {
    const isFirstFetch = !page || page === 1;
    const { data, status } = await api.Statistics.testStatisticsControllerGetTestPassageStatisticsSplit(
      id,
      split,
      page,
      limit,
    );

    if (status > 399) {
      throw data;
    }

    return { data, isFirstFetch };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getTestsListStatisticThunk = createAsyncThunk<
  { data: SearchTestPassagesResponseDto; isFirstFetch: boolean },
  SearchTestPassagesRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>(
  'performance/getTestsListsStatistic',
  async ({ page, limit = 20, courseId = '', startDate, endDate, title }, { rejectWithValue }) => {
    try {
      const isFirstFetch = !page || page === 1;

      const { data, status } = await api.Tests.testPassagesControllerSearchPassages({
        page,
        limit,
        courseId,
        startDate,
        endDate,
        title,
      });

      if (status > 399) {
        throw data;
      }
      return { data, isFirstFetch };
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const getAllTestsStatisticThunk = createAsyncThunk<
  { data: PaginatedTestSplitStatsDto; isFirstFetch: boolean },
  { split: SplitType } & PaginationType,
  ExtraParamsThunkType<DefaultRejectValue>
>('performance/getAllTestsStatistic', async ({ page, limit = 20, split }, { rejectWithValue }) => {
  try {
    const isFirstFetch = !page || page === 1;

    const { data, status } = await api.Statistics.testStatisticsControllerGetTestStatisticsTotalSplit(
      split,
      page,
      limit,
    );

    if (status > 399) {
      throw data;
    }
    return { data, isFirstFetch };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
