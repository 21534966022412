import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { CreateQuestionFlashcardDto, FlashcardsPacksDto } from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType, PaginationType } from 'types';

export const getPacksFlashcardsThunk = createAsyncThunk<
  { data: FlashcardsPacksDto; isFirstFetch: boolean },
  PaginationType | undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('flashcardsPacks/getPacksFlashcards', async (body, { rejectWithValue }) => {
  try {
    const isFirstFetch = !body?.page || body?.page === 1;
    const limit = body?.limit || 20;

    const { data, status } = await api.Flashcards.flashcardControllerGetFlashcardsPacks(body?.page, limit);

    if (status > 399) {
      throw data;
    }

    return { data, isFirstFetch };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getMarkedForReCheckingCountThunk = createAsyncThunk<
  { count: number },
  undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('flashcardsPacks/getMarkedForReCheckingCount', async (_, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Flashcards.flashcardControllerGetQuestionFlashcardsPack();

    if (status > 399) {
      throw data;
    }

    return { count: data.total };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const addToFlashcardsThunk = createAsyncThunk<
  CreateQuestionFlashcardDto,
  CreateQuestionFlashcardDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('flashcardsPacks/addToFlashcards', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Flashcards.flashcardControllerAddToQuestionFlashcards(body);

    if (status > 399) {
      throw data;
    }
    return body;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const removeFromFlashcardsThunk = createAsyncThunk<
  CreateQuestionFlashcardDto,
  CreateQuestionFlashcardDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('flashcardsPacks/removeFromFlashcards', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Flashcards.flashcardControllerDeleteQuestionFlashcard(body.questionId);

    if (status > 399) {
      throw data;
    }
    return body;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
