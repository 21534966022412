import { createGlobalStyle, css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

export const DatePickerWrapperStyles = createGlobalStyle`
  .react-datepicker {
    ${({ theme: { colors } }) => css`
      width: 312px;
      max-width: 312px;
      border: none;
      border-radius: 12px;
      box-shadow: ${colors.shadow[1]};
    `}
  }

  .react-datepicker__month-container {
    width: 312px;
    max-width: 312px;
    padding-bottom: 6px;
  }

  .date_picker_wrapper {
    height: 0;
  }

  // Overriding styles with !important that are hard to overwrite
  .react-datepicker-popper {
    inset: 0 0 0 auto !important;
    transform: translate3d(0, 40px, 0) !important;
    z-index: 10;

    ${respondToWidth.sm`
            inset: 0 0 auto 0 !important;
        `}
  }

  .react-datepicker-popper[data-placement^=bottom] {
    padding-top: 6px;
  }

  .react-datepicker__header {
    ${({ theme: { colors } }) => css`
      border-bottom: none;
      border-radius: 12px;
      background-color: ${colors.neutrals[11]};
    `}
  }

  .react-datepicker__day-name {
    ${({ theme: { colors, typography } }) => css`
      width: 36px;
      cursor: default;
      color: ${colors.neutrals[4]};

      ${typography.footnote_regular_12};
    `}
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-radius: 12px;
  }

  .react-datepicker__month {

    .react-datepicker__day--keyboard-selected {
      ${({ theme: { colors } }) => css`
        background-color: ${colors.neutrals[11]};
      `}
    }

    .react-datepicker__day {
      ${({ theme: { colors, typography } }) => css`
        width: 36px;
        padding: 6px 0;
        border-radius: 10px;
        color: ${colors.neutrals[1]};

        ${typography.body_basic_regular_14};
      `}
    }

    .react-datepicker__day--selected {
      ${({ theme: { colors } }) => css`
        background-color: ${colors.primary[1]};
        color: ${colors.neutrals[11]};
      `}
    }

    .react-datepicker__day--highlighted {
      ${({ theme: { colors } }) => css`
        color: ${colors.neutrals[1]};
        background-color: ${colors.primary[5]};
      `}
    }

    .react-datepicker__day--outside-month {
      ${({ theme: { colors } }) => css`
        pointer-events: none;
        background-color: ${colors.neutrals[11]};
        color: ${colors.neutrals[7]};
      `}
    }
  }
`;

export const DatePickerWrapperDarkStyles = createGlobalStyle`
  .react-datepicker {
    ${({ theme: { colors } }) => css`
      width: 312px;
      max-width: 312px;
      border: none;
      border-radius: 12px;
      background-color: ${colors.neutrals[9]};
      box-shadow: ${colors.shadow[1]};
    `}
  }

  .react-datepicker__month-container {
    width: 312px;
    max-width: 312px;
    padding-bottom: 6px;
  }

  .date_picker_wrapper {
    height: 0;
  }

  // Overriding styles with !important that are hard to overwrite
  .react-datepicker-popper {
    inset: 0 0 0 auto !important;
    transform: translate3d(0, 40px, 0) !important;
    z-index: 10;

    ${respondToWidth.sm`
            inset: 0 0 auto 0 !important;
        `}
  }

  .react-datepicker-popper[data-placement^=bottom] {
    padding-top: 6px;
  }

  .react-datepicker__header {
    ${({ theme: { colors } }) => css`
      border-bottom: none;
      border-radius: 12px;
      background-color: ${colors.neutrals[9]};
    `}
  }

  .react-datepicker__day-name {
    ${({ theme: { colors, typography } }) => css`
      width: 36px;
      cursor: default;
      color: ${colors.neutrals[4]};

      ${typography.footnote_regular_12};
    `}
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-radius: 12px;
  }

  .react-datepicker__month {

    .react-datepicker__day--keyboard-selected {
      ${({ theme: { colors } }) => css`
        background-color: ${colors.neutrals[9]};
      `}
    }

    .react-datepicker__day {
      ${({ theme: { colors, typography } }) => css`
        width: 36px;
        padding: 6px 0;
        border-radius: 10px;
        color: ${colors.neutrals[1]};

        ${typography.body_basic_regular_14};

        :hover {
          background-color: ${colors.primary[5]};
        }
      `}
    }

    .react-datepicker__day--selected {
      ${({ theme: { colors } }) => css`
        background-color: ${colors.primary[1]};
        color: ${colors.neutrals[1]};

        :hover {
          background-color: ${colors.primary[1]};
        }
      `}
    }

    .react-datepicker__day--highlighted {
      ${({ theme: { colors } }) => css`
        color: ${colors.neutrals[1]};
        background-color: ${colors.primary[5]};

        :hover {
          background-color: ${colors.primary[5]};
        }
      `}
    }

    .react-datepicker__day--outside-month {
      ${({ theme: { colors } }) => css`
        pointer-events: none;
        background-color: ${colors.neutrals[9]};
        color: ${colors.neutrals[6]};
      `}
    }
  }
`;
