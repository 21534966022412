import { BODY_ID, MODAL_HEIGHT_WITH_PADDING } from 'pages/StudyGuide/components/Chapter/components/Body/Body';
import { useEffect } from 'react';

type UseNoteOnClickProps = {
  handleNoteClick: (id: string, isModalAbove: boolean, modalPositionY: number) => void;
};

const useNoteOnClick = ({ handleNoteClick }: UseNoteOnClickProps) => {
  const body = document.getElementById(BODY_ID);

  const calculateOffsetTop = (element?: Element | null, offsetTop = 0): number => {
    if (!element || body?.isEqualNode(element)) return offsetTop;
    const offsetParent = (element as HTMLElement).offsetParent as HTMLElement;

    return calculateOffsetTop(offsetParent, offsetTop + (offsetParent?.offsetTop || 0));
  };

  const onNoteClick = (note: HTMLElement) => {
    const noteId = note.id || '';

    const offsetTop = calculateOffsetTop(note, note.offsetTop);

    const isModalAbove = offsetTop > MODAL_HEIGHT_WITH_PADDING;
    const modalPositionY = offsetTop > MODAL_HEIGHT_WITH_PADDING ? offsetTop : offsetTop + note.offsetHeight;

    handleNoteClick(noteId, isModalAbove, modalPositionY);
  };

  useEffect(() => {
    const notes = [...document.getElementsByClassName('note')];

    for (let i = 0; i < notes.length; i++) {
      notes[i].addEventListener('click', () => onNoteClick(notes[i] as HTMLElement));
    }

    return () => {
      for (let i = 0; i < notes.length; i++) {
        notes[i].removeEventListener('click', () => onNoteClick(notes[i] as HTMLElement));
      }
    };
  });
};

export default useNoteOnClick;
