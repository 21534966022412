import { FireIcon } from 'assets/icons';
import { FC } from 'react';
import { useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type StreakFireButtonProps = {
  onClick: () => void;
  className?: string;
};

const StreakFireButton: FC<StreakFireButtonProps> = ({ onClick, className }) => {
  const streakCount = useAppSelector((store) => store.performance.courseStatistic?.streak) || 0;

  return (
    <Root className={className} onClick={onClick}>
      <FireIcon />
      <Counter>{streakCount}</Counter>
    </Root>
  );
};

export default StreakFireButton;

const Root = styled.button`
  ${({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 61px;
    max-width: 61px;
    height: 36px;
    border-radius: 12px;
    background: ${colors.neutrals[8]};

    &:hover {
      background: ${colors.neutrals[9]};
    }

    &:active {
      background: ${colors.neutrals[7]};
    }
  `}

  ${respondToWidth.sm`
    display: none;
  `}
`;

const Counter = styled.p`
  ${({ theme: { typography, colors } }) => css`
    margin-left: 4px;
    color: ${colors.primary[1]};

    ${typography.body_basic_bold_14};
  `}
`;
