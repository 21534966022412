import { CustomerDto } from 'api/generated';
import { AppConfig } from 'config';
import { useState } from 'react';
import io from 'socket.io-client';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';

interface Props {
  errorMessage?: string | null;
}

export const useStripeSocket = ({ errorMessage }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const dispatch = useAppDispatch();
  const token = useAppSelector(selectors.auth.selectAccessToken);

  const onPaymentMethodChange = () => {
    const socket = io(`${AppConfig.REACT_APP_WS_API_URL}/customer`, {
      transports: ['websocket', 'polling'],
      transportOptions: {
        websocket: {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
        },
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
        },
      },
      auth: {
        token: `Bearer ${token}`,
      },
      query: {
        token: `Bearer ${token}`,
      },
    });

    socket.connect();

    socket.on('me', (user: CustomerDto) => {
      dispatch(actions.user.setUser(user));

      dispatch(actions.user.getMe());

      setIsSuccess(Boolean(user.paymentMethod && !errorMessage));
      setIsLoading(false);

      setIsCompleted(true);
    });

    return () => {
      socket.off('me');
      socket.disconnect();
    };
  };

  return {
    onPaymentMethodChange,
    isLoadingSocket: isLoading,
    isSuccessSocket: isSuccess,
    isCompletedSocket: isCompleted,
  };
};
