import { ScrollbarContainer, StyledText } from 'UI';
import { CustomerTableOfGuideContentChapterResponseDto } from 'api/generated';
import { queryConstants, routes } from 'constant/routes';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { hideText } from 'utils';

type ChaptersProps = {
  chapters: Array<CustomerTableOfGuideContentChapterResponseDto> | null;
  isShowOnlyChapters: boolean;
  onClose?: () => void;
};

const Chapters: FC<ChaptersProps> = ({ chapters, isShowOnlyChapters, onClose }) => {
  const navigate = useNavigate();

  const currentChapter = useAppSelector(selectors.studyGuide.selectCurrentChapter);
  const tableOfGuideContent = useAppSelector(selectors.studyGuide.selectTableOfGuideContent);

  const handleUnitClick = async (chapterId: string, unitId: string) => {
    onClose?.();

    if (currentChapter?.id !== chapterId) {
      const index = tableOfGuideContent?.chapters.findIndex((chapter) => chapter.id === chapterId);
      navigate(`${routes.studyGuide}?${queryConstants.chapterIndex}=${index}`);

      setTimeout(() => scrollToUnit(unitId), 2000);
    } else {
      setTimeout(() => scrollToUnit(unitId), 0);
    }
  };

  const scrollToUnit = async (unitId: string) => {
    const unit = document.getElementById(unitId);
    unit?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Root>
      {chapters?.map(
        (chapter) =>
          chapter && (
            <Content key={chapter.id}>
              <Chapter
                $isActive={chapter.id === currentChapter?.id}
                onClick={() => handleUnitClick(chapter.id, chapter.id)}>
                <ChapterTitle>{chapter.name}</ChapterTitle>
              </Chapter>
              {!isShowOnlyChapters && (
                <Content>
                  {chapter.units?.map((unit) => (
                    <button onClick={() => handleUnitClick(chapter.id, unit.id)} key={unit.id}>
                      <UnitTitle font="body_basic_medium_14">{unit.name}</UnitTitle>
                    </button>
                  ))}
                </Content>
              )}
            </Content>
          ),
      )}
    </Root>
  );
};

export default Chapters;

const Root = styled(ScrollbarContainer)`
  display: grid;
  padding: 16px;
  padding-left: 24px;
  grid-gap: 24px;
  align-content: flex-start;
`;

const Content = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const Chapter = styled.button<{ $isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  ${({ $isActive, theme: { colors } }) =>
    $isActive &&
    css`
      * {
        color: ${colors.primary[1]} !important;
      }

      ::before {
        content: '';
        position: absolute;
        left: -14px;

        border: 4px solid transparent;
        border-left: 4px solid ${colors.primary[1]};
      }
    `}
`;

const ChapterTitle = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    text-align: left;

    color: ${colors.neutrals[1]};
    ${typography.body_basic_bold_14}
  `};

  ${hideText(1)}
`;

const UnitTitle = styled(StyledText)`
  position: relative;
  text-align: left;
  padding-left: 14px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${({ theme: { colors } }) => colors.neutrals[7]};
  }

  ${hideText(1)}
`;
