import { Button, Checkbox, DefaultModal } from 'UI';
import { CheckboxVariant } from 'UI/Checkbox/Checkbox';
import { BookIcon } from 'assets/icons';
import React, { Dispatch, FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { useCategory, useGetCategory } from '../../hooks';

const SubtitleText = ({ isBreakpointSm }: { isBreakpointSm: boolean }) => {
  return isBreakpointSm ? (
    <>
      We always recommend studying the sections
      <br /> that you have not yet learned. This will be the best
      <br /> use of your study time.
      <br /> After that, focus on your weakest areas
    </>
  ) : (
    <>
      We always recommend studying the sections that you have
      <br /> not yet learned. This will be the best use of your study time.
      <br /> After that, focus on your weakest areas
    </>
  );
};

type CategoryModalProps = {
  isModalVisible: boolean;
  setModalVisible: Dispatch<React.SetStateAction<boolean>>;
  className?: string;
};

const CategoryModal: FC<CategoryModalProps> = ({ isModalVisible, setModalVisible, className }) => {
  const { currentCategory } = useGetCategory();

  const {
    isBreakpointSm,
    handleCategoryModalClose,
    watch,
    register,
    handleSubmit,
    isLoading,
    handleStartTest,
    isTutorTestLoading,
  } = useCategory({
    setModalVisible,
  });
  const ids = watch('id');

  const isChecked = Object.values(ids || {})?.some((value) => Boolean(value));

  const customChecked = (value: string) => {
    if (value && ids) {
      return Boolean(ids[value]);
    }

    return false;
  };

  return (
    <DefaultModal
      isOpen={isModalVisible}
      onRequestClose={handleCategoryModalClose}
      title="Weakest Exam Areas"
      containerCSS={ContainerCSS}
      titleCSS={TitleCSS}
      modalCSS={ModalCSS}
      showBackButton={false}
      showCloseButton={!isBreakpointSm}
      withTitleMargin={!isBreakpointSm}>
      <Container>
        <Subtitle>
          <SubtitleText isBreakpointSm={isBreakpointSm} />
        </Subtitle>

        <ContentContainer>
          {currentCategory?.map((item) => (
            <Item key={item.id}>
              <ItemTitle>
                <ItemTitleText>{item.title}</ItemTitleText>
                <ItemPercentage>{Math.ceil(Number(item.percentage))}%</ItemPercentage>
              </ItemTitle>

              <Checkbox
                variant={CheckboxVariant.white}
                value={item.id}
                {...register(`id.${item.id}`)}
                checked={customChecked(item.id)}
              />
            </Item>
          ))}
        </ContentContainer>

        <ButtonsContainer>
          <WeakestAreasButton
            key="weakest"
            onClick={handleSubmit(handleStartTest)}
            className={className}
            variant="primary"
            size="middle"
            disabled={!isChecked}
            isLoading={isLoading || isTutorTestLoading}
            iconComponent={<BookIcon />}>
            Study Weakest Areas
          </WeakestAreasButton>
          <CloseButton size="middle" variant="secondary" onClick={handleCategoryModalClose}>
            Close
          </CloseButton>
        </ButtonsContainer>
      </Container>
    </DefaultModal>
  );
};

export default CategoryModal;

const ModalCSS = css`
  ${respondToWidth.sm`
    top: unset;
    bottom: 0;
    width: 100%;
    transform: translate(-50%, 0);
  `}
`;

const ContainerCSS = css`
  padding: 40px 80px;
  width: 628px;
  height: 542px;

  ${respondToWidth.sm`
    height: 548px;
    width: 100%;
    padding: 32px 16px;
  `}
`;

const TitleCSS = css`
  ${({ theme: { colors } }) => css`
    margin-bottom: 4px;
    color: ${colors.primary[1]} !important;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);

  ${respondToWidth.sm`
    height: calc(100% - 38px);
  `}
`;

const Subtitle = styled.div`
  ${({ theme: { typography, colors } }) => css`
    margin-bottom: 40px;
    color: ${colors.neutrals[1]};

    ${typography.body_basic_medium_14}
  `}

  ${respondToWidth.sm`
    margin-bottom: 24px;
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 220px;
  overflow: scroll;
`;

const Item = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    gap: 4px;
    width: 100%;
    padding: 18px 16px;
    margin-bottom: 8px;
    border-radius: 16px;
    background: ${colors.neutrals[10]};
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

const ItemTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 390px;

  ${respondToWidth.sm`
    width: 87%;
  `}
`;

const ItemTitleText = styled.p`
  ${({ theme: { colors, typography } }) => css`
    max-width: 350px;
    width: max-content;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${colors.neutrals[1]};

    ${typography.body_large_medium_16};
  `}
`;

const ItemPercentage = styled.p`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.system.red};

    ${typography.body_large_medium_16};
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const WeakestAreasButton = styled(Button)`
  display: flex !important;
  width: 100% !important;
  flex-direction: row-reverse;
  padding-right: 23px;
  white-space: nowrap;

  & svg {
    margin-left: 8px;
  }
`;

const CloseButton = styled(Button)`
  display: none;
  margin-top: 8px;

  ${respondToWidth.sm`
    display: flex;
  `}
`;
