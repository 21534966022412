import { Checkbox, StyledText } from 'UI';
import { PlanPhaseDto, PlanPhaseTaskDto } from 'api/generated';
import { FC, Fragment, memo } from 'react';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type PhaseProps = {
  phase: PlanPhaseDto;
  index: number;
  canPhaseSelect: boolean;
  onClick: (task: PlanPhaseTaskDto) => void;
  checkedTask?: PlanPhaseTaskDto;
};

const Phase: FC<PhaseProps> = ({ checkedTask, phase, index, canPhaseSelect, onClick }) => {
  return (
    <Fragment key={phase.id}>
      <WeekTitle>{phase.name || `Week ${index + 1}`}</WeekTitle>
      {phase.tasks?.map((task) => {
        const checked = checkedTask?.id === task.id ? checkedTask?.isMarked : task.isMarked;

        return (
          <StyledCheckbox
            key={task.id}
            isLoading={!canPhaseSelect}
            onChange={() => onClick(task)}
            checked={checked}
            label={task.name}
          />
        );
      })}
    </Fragment>
  );
};

export default memo(Phase);

const WeekTitle = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_large_bold_16};
`;

const StyledCheckbox = styled(Checkbox)`
  &:last-of-type {
    padding-bottom: 15px;

    ${respondToWidth.sm`
      padding-bottom: 0px;
    `}
  }
`;
