import { Input, StyledText } from 'UI';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { validations } from 'utils';

const NumberQuestions = () => {
  const { control } = useFormContext();

  return (
    <Root>
      <Title>Number of questions</Title>
      <div>
        <Controller
          control={control}
          name="numberOfQuestions"
          rules={{
            required: { value: true, message: 'Please indicate the number of questions' },
            validate: validations.numbers,
          }}
          render={({ field, fieldState: { error } }) => (
            <StyledInput {...field} error={error?.message} value={field.value} />
          )}
        />
      </div>
    </Root>
  );
};

export default NumberQuestions;

const StyledInput = styled(Input)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_large_bold_16};
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 24px 20px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;

  ${respondToWidth.sm`
    border-radius: 0;
    padding:0;
    gap: 16px;
  `}
`;
