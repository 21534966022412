import { StyledText } from 'UI';
import { FC, ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type TooltipProps = {
  children: ReactNode;
};

const Tooltip: FC<TooltipProps> = ({ children }) => {
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);

  const handleTooltipClose = () => {
    setIsVisibleTooltip(false);
  };

  const handleTooltipOpen = () => {
    setIsVisibleTooltip(true);
  };

  return (
    <Root onMouseLeave={handleTooltipClose}>
      <Wrapper onMouseEnter={handleTooltipOpen}>{children}</Wrapper>
      <TooltipWindow $isVisibleTooltip={isVisibleTooltip}>
        <Text>
          Tutor - You choose the subject/s and the question mode. The time is unlimited. We recommend this for practice.
        </Text>
        <Text>
          Timed - Questions are selected automatically with the same weighing as the official exam. You can use it to
          simulate the real test.
        </Text>
      </TooltipWindow>
    </Root>
  );
};

export default Tooltip;

type TooltipWindowProps = {
  $isVisibleTooltip: boolean;
};

const Text = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[11]};
  ${({ theme: { typography } }) => typography.footnote_semibold_12};
`;

const Root = styled.div`
  position: relative;
  display: flex;
  height: fit-content;

  &::after {
    content: ' ';
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    top: -12px;
    left: -12px;
    z-index: 1;

    position: absolute;
  }
`;

const Wrapper = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const TooltipWindow = styled.div<TooltipWindowProps>`
  ${({ $isVisibleTooltip }) => css`
    position: absolute;
    bottom: -108px;
    left: -450px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    padding: 12px 16px;
    width: 0;
    z-index: 1;
    border-radius: 10px;
    background-color: ${({ theme: { colors } }) => colors.neutrals[1]};
    opacity: 0;
    pointer-events: none;

    ${$isVisibleTooltip &&
    css`
      z-index: 1000;
      opacity: 1;
      width: 468px;
      pointer-events: all;
    `}
  `};

  ${($isVisibleTooltip) => respondToWidth.sm`
    ${
      $isVisibleTooltip &&
      `z-index: 1000;
      opacity: 0;
      pointer-events: all;`
    }`}
`;
