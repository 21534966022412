import { ThreeDots } from 'assets/icons';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Item, Menu } from './components';
import { Option, Position } from './types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  options: Option[];
  position: Position;
}

const ThreeDotsMenu: FC<Props> = ({ isOpen, onClose, onOpen, options, position }) => {
  return (
    <Root $isOpen={isOpen}>
      <Button type="button" onClick={isOpen ? onClose : onOpen} $isOpen={isOpen}>
        <ThreeDots />
      </Button>
      {isOpen && (
        <Menu onClose={onClose} position={position}>
          {options.map((item: Option) => (
            <Item item={item} key={item.title} />
          ))}
        </Menu>
      )}
    </Root>
  );
};

export default ThreeDotsMenu;

const Button = styled.button<{ $isOpen: boolean }>`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 6px;
  border: 1px solid transparent;
  border-color: ${({ $isOpen, theme: { colors } }) => ($isOpen ? colors.system.red : colors.neutrals[9])};
`;

const Root = styled.div<{ $isOpen: boolean }>`
  position: relative;
`;
