import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  BillingPortalDto,
  ClientSecretDto,
  GetCustomerPaymentIntentSecretRequestDto,
  StripeCustomerInfoRequestDto,
  StripeCustomerInfoResponseDto,
} from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType } from 'types';

export const getCustomerByEmailThunk = createAsyncThunk<
  StripeCustomerInfoResponseDto,
  StripeCustomerInfoRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('stripe/customer', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Stripe.stripeControllerGetCustomer(body);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getPaymentIntentSecretThunk = createAsyncThunk<
  ClientSecretDto,
  GetCustomerPaymentIntentSecretRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('stripe/payment-intent', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Stripe.stripeControllerCreateCustomerPaymentIntent(body);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getBillingPortalThunk = createAsyncThunk<
  BillingPortalDto,
  undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('stripe/billing-portal', async (_, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Stripe.stripeControllerCreateBillingPortalSession();

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
