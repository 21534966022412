import { StyledText } from 'UI';
import { LoaderIcon } from 'assets/icons';
import { TestMenuButton } from 'components/TestFooter/TestFooter';
import React, { FC } from 'react';
import styled, { keyframes, useTheme } from 'styled-components';

type MenuItemProps = {
  iconMapping: Record<TestMenuButton, React.ReactNode>;
  isLoading: boolean;
  type: TestMenuButton;
  onClick: () => void;
};

const MenuItem: FC<MenuItemProps> = ({ iconMapping, isLoading, type, onClick }) => {
  const { colors } = useTheme();

  return (
    <Container onClick={onClick} $isLoading={isLoading}>
      {isLoading ? (
        <StyledLoader size={24} color={colors.primary[1]} />
      ) : (
        <>
          {iconMapping[type]}
          <TextMenu>{type}</TextMenu>
        </>
      )}
    </Container>
  );
};

export default MenuItem;

const Container = styled.button<{ $isLoading: boolean }>`
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: ${({ $isLoading }) => ($isLoading ? 'center' : 'start')};
  padding-block: 12px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};

  &:last-of-type {
    border-bottom: transparent;
  }
`;

const TextMenu = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_bold_14}
`;

const rotate = keyframes`
from {
    transform: rotate(0deg);
  }
to {
    transform: rotate(360deg);
  }
`;

export const StyledLoader = styled(LoaderIcon)`
  animation: ${rotate} 2s linear infinite;
`;
