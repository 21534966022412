import { StyledText } from 'UI';
import { LockSecondIcon } from 'assets/icons';
import { PoweredByStripe } from 'assets/icons';
import React from 'react';
import styled from 'styled-components';

const Secure = () => {
  return (
    <Container>
      <SecureContainer>
        <LockSecondIcon size={18} />
        <SecureText font="body_basic_medium_14">Secure SSL-Encrypted Payments</SecureText>
      </SecureContainer>
      <StripeLogoContainer>
        <PoweredByStripe height={32} width={160} />
      </StripeLogoContainer>
    </Container>
  );
};

export default Secure;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const SecureContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SecureText = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  margin-left: 6px;
`;

const StripeLogoContainer = styled.div`
  margin-left: auto;
`;
