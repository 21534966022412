import { TestMenuButton } from 'components/TestFooter/TestFooter';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { actions, selectors, useAppSelector } from 'store';

import MenuItem from '../MenuItem';

type RecheckItemProps = {
  iconMapping: Record<TestMenuButton, React.ReactNode>;
  isMarkedInitialValue: boolean;
  questionId: string;
};

const RecheckItem: FC<RecheckItemProps> = ({ iconMapping, isMarkedInitialValue, questionId }) => {
  const [isMarked, setIsMarked] = useState(false);

  const { errorToast } = useNotifications();

  const [createMarkedQuestionAction, isCreateMarkedLoading] = useAsyncAction(actions.tests.markedQuestion);
  const [removeMarkedQuestionAction, isRemoveLoading] = useAsyncAction(actions.tests.unmarkedQuestion);
  const activeIndex = useAppSelector(selectors.tests.selectCurrentQuestionIndex);

  useEffect(() => {
    setIsMarked(isMarkedInitialValue);
  }, [activeIndex]);

  const isLoading = isCreateMarkedLoading || isRemoveLoading;

  const createMarkedQuestion = async () => {
    try {
      await createMarkedQuestionAction({ questionId });
      setIsMarked(true);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const removeMarkedQuestion = async () => {
    try {
      await removeMarkedQuestionAction({ questionId });
      setIsMarked(false);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  return (
    <>
      {isMarked ? (
        <MenuItem
          onClick={removeMarkedQuestion}
          isLoading={isLoading}
          type={TestMenuButton.REMOVE_RECHECKING}
          iconMapping={iconMapping}
        />
      ) : (
        <MenuItem
          onClick={createMarkedQuestion}
          isLoading={isLoading}
          type={TestMenuButton.RECHECKING}
          iconMapping={iconMapping}
        />
      )}
    </>
  );
};

export default RecheckItem;
