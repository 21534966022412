import { FC, useMemo } from 'react';
import styled, { CSSProp, css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { StatisticColumnType } from 'types';

type StatisticColumnProps = {
  type: StatisticColumnType;
  percent?: number;
};

const StatisticColumn: FC<StatisticColumnProps> = ({ type, percent }) => {
  const { colors } = useTheme();

  const colorMapping = useMemo(() => {
    return {
      [StatisticColumnType.CORRECT]: css`
        color: ${colors.system.green};
        background-color: ${colors.system.lightGreen};
      `,
      [StatisticColumnType.INCORRECT]: css`
        color: ${colors.system.red};
        background-color: ${colors.system.lightRed};
      `,
      [StatisticColumnType.UNUSED]: css`
        color: ${colors.neutrals[2]};
        background-color: ${colors.neutrals[10]};
      `,
      [StatisticColumnType.YES]: css`
        color: ${colors.system.green};
        background-color: ${colors.system.lightGreen};
      `,
      [StatisticColumnType.NO]: css`
        color: ${colors.system.red};
        background-color: ${colors.system.lightRed};
      `,
      [StatisticColumnType.KINDA]: css`
        color: ${colors.system.blue};
        background-color: ${colors.system.lightBlue};
      `,
    };
  }, [colors]);
  const roundedPercent = parseFloat((percent || 0).toFixed(1));

  return (
    <Column $colorCSS={colorMapping[type]} $height={roundedPercent}>
      {roundedPercent}
      <Transfer>{'\n'}</Transfer>%
    </Column>
  );
};

export default StatisticColumn;

type ColumnProps = {
  $colorCSS: CSSProp;
  $height?: number;
};

const Column = styled.div<ColumnProps>`
  ${({ theme: { typography }, $colorCSS, $height }) => css`
    width: 100%;
    height: ${`${$height || 0}%`};
    display: ${`${$height ? 'flex' : 'none'}`};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 12px;
    min-height: 44px;
    user-select: none;
    ${$colorCSS};
    ${typography.title_5_bold_20};

    ${respondToWidth.sm`
      border-radius: 4px;
      ${typography.footnote_bold_12};
    `}
  `};
`;

const Transfer = styled.span`
  display: none;

  ${respondToWidth.sm`
    display: block
  `};
`;
