import { ForgotPasswordModal as ForgotPasswordModalUI } from 'UI';
import { FormState } from 'UI/Modals/ForgotPasswordModal/ForgotPasswordModal';
import { useAsyncAction } from 'hooks';
import React, { FC } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import { BaseModalProps } from 'types';

type ForgotPasswordModalProps = {
  openCreatePasswordModal?: () => void;
  goBack?: () => void;
} & BaseModalProps;

const ForgotPasswordModal: FC<ForgotPasswordModalProps> = ({ onClose, openCreatePasswordModal, ...props }) => {
  const [authRestorePassword, isLoading] = useAsyncAction(actions.auth.restorePassword);
  const direction = useAppSelector(selectors.direction.selectDirection);

  const restorePassword = async (values: FormState) => {
    if (direction) {
      await authRestorePassword({
        email: values.email,
        directionId: direction?.id,
      });

      onClose();

      openCreatePasswordModal?.();
    }
  };

  return <ForgotPasswordModalUI {...props} isLoading={isLoading} restorePassword={restorePassword} onClose={onClose} />;
};

export default ForgotPasswordModal;
