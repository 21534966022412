import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgMarkIcon: FC<SVGIconProps> = ({ color: propsColor, size = 20, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m8.333 12.643 7.66-7.66 1.18 1.178L8.332 15 3.03 9.697l1.178-1.179 4.125 4.125Z" fill={color} />
    </svg>
  );
};

const Memo = memo(SvgMarkIcon);
export default Memo;
