import { useEffect, useState } from 'react';

const getOrientation = () => window.screen?.orientation?.type;

export const useIsLandscapeOrientation = () => {
  const [orientation, setOrientation] = useState(getOrientation());

  const updateOrientation = () => {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    window.addEventListener('orientationchange', updateOrientation);
    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, [window.screen?.orientation]);

  const isLandscapeOrientation = orientation === 'landscape-primary' || orientation === 'landscape-secondary';

  return { isLandscapeOrientation };
};
