import { Button, ConfirmTimedTestModal, StyledText } from 'UI';
import { StartTutorTestPassageRequestDtoModesEnum } from 'api/generated';
import { ArrowDown } from 'assets/icons';
import { TimedTestStartModal, UncompletedTestModal } from 'components';
import { routes } from 'constant/routes';
import { useAsyncAction, useClientSize, useFormWatch, useNotifications } from 'hooks';
import MainLayout from 'layouts/MainLayout';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';
import { ModeType, TestListTabs } from 'types';
import { getErrorMessage } from 'utils';

import { NumberQuestions, QuestionMode, Subjects, TestMode } from './components';

export type SubjectFieldType = 'chaptersQuestions' | 'domainsQuestions';

export type TestFormType = {
  numberOfQuestions: string;
  chaptersQuestions: Array<string>;
  domainsQuestions: Array<string>;
  mode: Array<ModeType>;
};

const TestList = () => {
  const dispatch = useAppDispatch();
  const [isShowModeTab, setIsShowModeTab] = useState(false);
  const [isTutorMode, setIsTutorMode] = useState(true);
  const [isShowConfirmTimedTestModal, setIsShowConfirmTimedTestModal] = useState(false);
  const [isTimedTestPreviewModalOpen, setIsTimedTestPreviewModalOpen] = useState(false);

  const { errorToast } = useNotifications();
  const { getIsBreakpoint } = useClientSize();

  const isWidthSm = getIsBreakpoint('sm');

  const isShowSettingsContainer = !isWidthSm || (isWidthSm && isShowModeTab);

  const navigate = useNavigate();

  const [getDomainsQuestionsAction, isDomainsQuestionsLoading] = useAsyncAction(actions.tests.getDomainsQuestions);
  const [getChaptersQuestionsAction] = useAsyncAction(actions.tests.getChaptersQuestions);
  const [getNextTimedTestAction, isNextTimedTestLoading] = useAsyncAction(actions.tests.getNextTimedTest);
  const [startTutorTestAction, isTutorTestLoading] = useAsyncAction(actions.tests.startTutorTest);
  const [startTimedTestAction, isTimedTestLoading] = useAsyncAction(actions.tests.startTimedTest);

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const nextTimedTest = useAppSelector(selectors.tests.selectNextTimedTestPreview);
  const activeTab = useAppSelector(selectors.tests.selectActiveTab);
  const isDomainTab = activeTab === TestListTabs.DOMAINS;

  const methods = useForm<TestFormType>({
    defaultValues: { numberOfQuestions: '', chaptersQuestions: [], domainsQuestions: [], mode: ['ALL'] },
    mode: 'onChange',
  });

  const { handleSubmit, watch, setError } = methods;
  const watchField = useFormWatch(watch);

  const modes = watchField('mode');

  const openTimedTestPreviewModal = async () => {
    try {
      await getNextTimedTestAction();
      closeTimedTestConfirmModal();

      setIsTimedTestPreviewModalOpen(true);
    } catch (error) {
      closeTimedTestConfirmModal();

      errorToast(getErrorMessage(error));
    }
  };

  const closeTimedTestPreviewModal = async () => setIsTimedTestPreviewModalOpen(false);

  const getDomainsQuestions = async (courseId: string) => {
    try {
      await getDomainsQuestionsAction({
        courseId,
      });
    } catch {
      errorToast('Something went wrong');
    }
  };

  const getChaptersQuestions = async (courseId: string) => {
    try {
      await getChaptersQuestionsAction({
        courseId,
      });
    } catch {
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    if (activeCourse?.id) {
      getDomainsQuestions(activeCourse.id);
      getChaptersQuestions(activeCourse.id);
    }
  }, [activeCourse?.id]);

  const closeTimedTestConfirmModal = () => {
    setIsShowConfirmTimedTestModal(false);
  };

  const openTimedTestConfirmModal = () => {
    setIsShowConfirmTimedTestModal(true);
  };

  const startTimedTest = async () => {
    try {
      if (!nextTimedTest) {
        errorToast('Something went wrong');
        return null;
      }

      await startTimedTestAction({ timedTestId: nextTimedTest.id });

      navigate(routes.timedTest);
    } catch (error) {
      errorToast(getErrorMessage(error));
    } finally {
      closeTimedTestPreviewModal();
    }
  };

  const startTutorTest = async (data: TestFormType) => {
    try {
      const isValid = validateFields(data);
      if (!isValid) {
        return;
      }

      const modesWithoutAll = modes.filter((mode) => mode !== 'ALL');
      const currentModes = modesWithoutAll.length ? modesWithoutAll : undefined;

      const questions = {
        domainIds: isDomainTab ? data.domainsQuestions : undefined,
        chapterIds: !isDomainTab ? data.chaptersQuestions : undefined,
      };

      const startTutorTestParams = {
        questionsAmount: Number(data.numberOfQuestions),
        modes: currentModes as StartTutorTestPassageRequestDtoModesEnum[],
        ...questions,
      };

      await startTutorTestAction(startTutorTestParams);
      dispatch(actions.tests.setTutorTestParams(startTutorTestParams));
      navigate(routes.tutorTest);
    } catch (error) {
      errorToast(getErrorMessage(error));
    }
  };

  const validateFields = (data: TestFormType) => {
    if (isDomainTab && !data.domainsQuestions.length) {
      setError('domainsQuestions', { message: 'Please select subjects' });
      setIsShowModeTab(false);
      return false;
    }
    if (!isDomainTab && !data.chaptersQuestions.length) {
      setError('chaptersQuestions', { message: 'Please select subjects' });
      setIsShowModeTab(false);
      return false;
    }
    if (!Number(data.numberOfQuestions)) {
      setIsShowModeTab(true);
      setError('numberOfQuestions', { message: 'Please indicate the number of questions' });
      return false;
    }
    return true;
  };

  return (
    <MainLayout>
      <FormProvider {...methods}>
        <MainContainer onSubmit={handleSubmit(startTutorTest)}>
          <Subjects
            isShowContainer={isShowModeTab}
            setIsShowContainer={setIsShowModeTab}
            isTutorMode={isTutorMode}
            isDomainsQuestionsLoading={isDomainsQuestionsLoading}
          />

          <InnerContainer>
            {isWidthSm && (
              <ResponsiveTitle $isShowContainer={isShowModeTab}>
                <Title>Mode</Title>
                <StyledArrowDown $isShowContainer={isShowModeTab} onClick={() => setIsShowModeTab((prev) => !prev)} />
              </ResponsiveTitle>
            )}

            {isShowSettingsContainer && (
              <SettingsContainer>
                <TestMode setIsTutorMode={setIsTutorMode} isTutorMode={isTutorMode} />

                {isTutorMode && (
                  <>
                    <QuestionMode />

                    <NumberQuestions />
                  </>
                )}
              </SettingsContainer>
            )}

            <ButtonContainer>
              <StyledButton
                variant="primary"
                type={'button'}
                onClick={openTimedTestConfirmModal}
                isLoading={isTutorTestLoading}>
                Generate & Start
              </StyledButton>
            </ButtonContainer>
          </InnerContainer>

          <UncompletedTestModal />

          <TimedTestStartModal
            startTimedTest={startTimedTest}
            isLoading={isTimedTestLoading}
            isOpen={isTimedTestPreviewModalOpen}
            onClose={closeTimedTestPreviewModal}
          />

          <ConfirmTimedTestModal
            title={isTutorMode ? 'Tutor Test' : 'Timed Test'}
            isOpen={isShowConfirmTimedTestModal}
            onConfirmClick={isTutorMode ? handleSubmit(startTutorTest) : openTimedTestPreviewModal}
            isLoading={isNextTimedTestLoading || isTutorTestLoading}
            onClose={closeTimedTestConfirmModal}
          />
        </MainContainer>
      </FormProvider>
    </MainLayout>
  );
};

export default TestList;

const MainContainer = styled.form`
  display: flex;
  flex-shrink: 1;
  gap: 24px;
  padding: 28px 40px 40px 0;
  width: 100%;
  max-width: 1224px;
  margin-inline: auto;
  height: 100%;

  ${respondToWidth.xl`
    padding: 28px 14px 40px 0;
    gap: 14px;
  `}

  ${respondToWidth.sm`
    flex-direction: column;
    padding: 0;
    gap: 0;
    height: calc(100vh - 47px);
    width: calc(100vw - 4px);
    overflow-x: hidden;
  `}
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => typography.title_4_bold_24};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.title_5_bold_20};
  `};
`;

const ButtonContainer = styled.div`
  ${({ theme: { colors } }) => respondToWidth.sm`
    width: 100%;
    padding: 16px;
    background: ${colors.neutrals[11]};
    border-top: 1px solid ${colors.neutrals[10]};
  `}
`;

const StyledArrowDown = styled(ArrowDown)<{ $isShowContainer: boolean }>`
  transition: all 0.2s linear;
  transform: rotate(${({ $isShowContainer }) => ($isShowContainer ? 180 : 0)}deg);
`;

const ResponsiveTitle = styled.div<{ $isShowContainer: boolean }>`
  display: flex;
  padding: 8px 12px 8px 16px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${({ theme: { colors }, $isShowContainer }) => respondToWidth.sm`
    ${!$isShowContainer && `border-top: 1px solid ${colors.neutrals[10]};`}
  `}
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${respondToHeight.xs`
      gap: 8px;
  `}

  ${({ theme: { colors } }) => respondToWidth.sm`
    height: calc(100vh - 277px);
    background-color: ${colors.neutrals[11]};
    padding-inline: 16px 12px;
    padding-top: 11px;

    ${respondToHeight.xs`
      gap: 24px;
    `}
  `}
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  gap: 24px;
  width: 288px;

  ${respondToWidth.sl`
    width: 200px;
  `}

  ${respondToHeight.xs`
      gap: 8px;
  `}

  ${respondToWidth.sm`
    justify-content: flex-start;
    width: 100%;
    gap: 0;
  `};
`;
