import { Button, FullScreenModal, ScrollbarContainer, StyledText } from 'UI';
import { routes } from 'constant/routes';
import { useAsyncAction, useInfinityScroll, useNotifications } from 'hooks';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useIsLandscapeOrientation } from 'hooks/useIsLandscapeOrientation';
import { useSwiperControl } from 'hooks/useSwiperControl';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { Swiper as SwiperCommon } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationType } from 'types';

const MIN_COUNT_FOR_NEXT_FETCH = 5;

const MobileSlider = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [firstSwiper, setFirstSwiper] = useState<SwiperCommon>();

  const { getNextSlide, getPrevSlide, onClickSlide, activeIndex } = useSwiperControl({
    swipers: [firstSwiper],
    isScroll: false,
  });
  const [currentIndex, setCurrentIndex] = useState(activeIndex);
  const mnemonicCardsMeta = useAppSelector(selectors.mnemonicCards.selectMnemonicCardsPackMeta);
  const mnemonicCardPacks = useAppSelector(selectors.mnemonicCards.selectMnemonicCardsPack);

  const { isLandscapeOrientation } = useIsLandscapeOrientation();

  const onClickImage = (index: number) => {
    setIsShowModal(true);
    onClickSlide(index);
    setCurrentIndex(index);
  };

  const isLastSlide = currentIndex === (mnemonicCardsMeta?.totalItems || 0) - 1;
  const slidesPerView = isLandscapeOrientation ? 1 : 3;
  const { isMobile } = useGetIsMobile();

  const navigate = useNavigate();
  const { errorToast } = useNotifications();

  const [getMnemonicCardsAction, isCardsLoading] = useAsyncAction(actions.mnemonicCards.getMnemonicCardsPack);

  const { id } = useParams();

  const getPackMnemonicCard = async (actionPayload?: PaginationType) => {
    try {
      if (id) {
        await getMnemonicCardsAction({ id, ...actionPayload });
      }
    } catch (error) {
      errorToast('Something went wrong');
    }
  };
  const currentPage = mnemonicCardsMeta?.currentPage || 0;
  const totalPages = mnemonicCardsMeta?.totalPages || 0;

  const nextPage = currentPage + 1;

  const hasMnemonicCardsMore = currentPage < totalPages;
  const isLoading = mnemonicCardPacks.length === 0 && isCardsLoading;
  const isFetchAfter = mnemonicCardPacks.length - activeIndex === MIN_COUNT_FOR_NEXT_FETCH;

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getPackMnemonicCard({ page: nextPage }),
    hasAfterMore: hasMnemonicCardsMore,
    isLoading,
  });

  const getAfterPackMnemonicCards = () => {
    if (isFetchAfter) {
      getPackMnemonicCard({ page: nextPage });
    }
  };

  useEffect(() => {
    getAfterPackMnemonicCards();
  }, [activeIndex]);

  return (
    <Container $isMobile={isMobile}>
      <Wrapper onScroll={handleScroll} ref={listRef}>
        {!isShowModal && (
          <Fragment>
            {mnemonicCardPacks.map((card, index) => (
              <ImageContainer key={card.id} onClick={() => onClickImage(index)}>
                <img src={card.imageUrl} alt={card.title} />
              </ImageContainer>
            ))}
          </Fragment>
        )}
        <FullScreenModal
          isOpen={isShowModal}
          onRequestClose={() => setIsShowModal(false)}
          containerCSS={ModalContainerCSS}
          controlBarProps={{
            onBackClick: getPrevSlide,
            onNextClick: getNextSlide,
            amount: mnemonicCardsMeta?.totalItems || 0,
            activeIndex: currentIndex,
          }}>
          <StyledSwiper
            slidesPerView={slidesPerView}
            direction="vertical"
            centeredSlides
            spaceBetween={16}
            initialSlide={activeIndex}
            onSwiper={setFirstSwiper}
            $isModal={isShowModal}
            onSlideChange={(e) => setCurrentIndex(e.activeIndex)}>
            {mnemonicCardPacks.map((card) => (
              <SwiperSlide key={card.id}>
                <ImageContainer $isModal>
                  <img src={card.imageUrl} alt={card.imageUrl} />
                </ImageContainer>
              </SwiperSlide>
            ))}
          </StyledSwiper>
          {isLastSlide && (
            <Label>
              <Text>This mnemonic is the last one in the chapter</Text>
              <StyledButton
                variant="tertiary"
                size="small"
                onClick={() => navigate(`${routes.mnemonicCardPractice}/${id}`)}>
                Start practice
              </StyledButton>
            </Label>
          )}
        </FullScreenModal>
      </Wrapper>
    </Container>
  );
};

export default MobileSlider;

const ModalContainerCSS = css`
  padding-inline: 16px;

  @media (orientation: landscape) {
    height: calc(100% - 40px);
  }
`;

const Container = styled.div<{ $isMobile: boolean }>`
  height: calc(var(--vh, 1vh) * 100 - 162px);
  display: ${({ $isMobile }) => ($isMobile ? 'block' : 'none')};
  padding-right: 6px;

  ${respondToWidth.sm`
    display: block;
  `}
`;

const ImageContainer = styled.div<{ $isModal?: boolean }>`
  width: 100%;
  height: 100%;
  max-height: 250px;
  min-height: 250px;
  display: grid;
  place-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[1]};

  ${({ $isModal }) => respondToWidth.s`
     max-height: ${$isModal ? '100%' : '195px'};
     min-height: 195px;
  `}

  @media (orientation: landscape) {
    border-radius: ${({ $isModal }) => $isModal && 0};
    max-height: 100%;
  }

  img {
    border-radius: 12px;
    object-fit: ${({ $isModal }) => ($isModal ? 'contain' : 'cover')};
    overflow: hidden;
    display: block;

    @media (orientation: landscape) {
      border-radius: ${({ $isModal }) => $isModal && 0};
      height: ${({ $isModal }) => $isModal && '100%'};
    }
  }
`;

const Wrapper = styled(ScrollbarContainer)`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 16px;
  padding-left: 16px;
  padding-right: 10px;
`;

const StyledSwiper = styled(Swiper)<{ $isModal?: boolean }>`
  width: 100%;
  height: 100%;

  .swiper-slide {
    border-radius: 12px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    opacity: 0.3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
    @media (orientation: landscape) {
      border-radius: ${({ $isModal }) => $isModal && 0};
    }
  }

  .swiper-slide-active {
    opacity: 1;
  }
`;

const Label = styled.div`
  position: absolute;
  width: calc(100% - 32px);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 84px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
  padding: 10px 12px 10px 20px;

  @media (orientation: landscape) {
    max-width: 183px;
    right: 16px;
    bottom: 56px;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
  }
`;

const Text = styled(StyledText)`
  max-width: 127px;
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

const StyledButton = styled(Button)`
  width: 159px;
  min-width: 159px;
`;
