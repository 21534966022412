import Tag from 'UI/Tag';
import { SelectedMenu } from 'pages/Performance/components/Header/Header';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

type HeaderTagsProps = {
  selected: SelectedMenu;
  onSelect: (value: SelectedMenu) => void;
};

const HeaderTags: FC<HeaderTagsProps> = ({ selected, onSelect }) => {
  const tagVariants = useMemo(
    () => [{ value: SelectedMenu.OVERALL }, { value: SelectedMenu.TESTS }, { value: SelectedMenu.FLASHCARDS }],
    [selected],
  );

  return (
    <TagsContainer>
      {tagVariants.map((tag) => (
        <Tag
          key={tag.value}
          text={tag.value}
          selected={tag.value === selected}
          onClick={() => onSelect(tag.value)}
          size="small"
        />
      ))}
    </TagsContainer>
  );
};

export default HeaderTags;

const TagsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  padding-bottom: 20px;
`;
