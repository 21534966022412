import { StyledText } from 'UI';
import { NoteResponseDto } from 'api/generated';
import { FC, memo, useMemo } from 'react';
import { selectors, useAppDispatch, useAppSelector } from 'store';
import { actions } from 'store/ducks/studyGuide';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { hideText } from 'utils';

type NotesListProps = {
  notes: NoteResponseDto[];
  onNoteClick: (noteId: string) => void;
};

const NotesList: FC<NotesListProps> = ({ notes, onNoteClick }) => {
  const dispatch = useAppDispatch();
  const currentChapter = useAppSelector(selectors.studyGuide.selectCurrentChapter);
  const selectedNoteId = useAppSelector(selectors.studyGuide.selectSelectedNoteId);

  const unitBlocksIds = useMemo(
    () => currentChapter?.units.flatMap((item) => item.content.blocks.map((block) => block.id)) || [],
    [currentChapter?.id],
  );

  const getBlockIndex = (id: string) => {
    const index = unitBlocksIds.findIndex((item) => item === id);
    return index;
  };

  const sortedNotes = useMemo(
    () =>
      [...notes].sort((a, b) =>
        sortNotes({
          a: { note: a, blockIndex: getBlockIndex(a.blockId) },
          b: { note: b, blockIndex: getBlockIndex(b.blockId) },
        }),
      ),
    [notes],
  );

  const handleNoteClick = (noteId: string) => {
    dispatch(actions.setSelectedNoteId(noteId));
    onNoteClick(noteId);
  };

  return (
    <Content>
      {sortedNotes?.map(
        (note) =>
          note && (
            <button key={note.id} onClick={() => handleNoteClick(note.id)}>
              <NoteTitle $selected={selectedNoteId === note.id} font="body_basic_medium_14">
                {note.selectedText}
              </NoteTitle>
            </button>
          ),
      )}
    </Content>
  );
};

export default memo(NotesList);

type SortNoteType = { note: NoteResponseDto; blockIndex: number };

const sortNotes = ({ a, b }: { a: SortNoteType; b: SortNoteType }): number => {
  if (a.blockIndex > b.blockIndex) {
    return 1;
  }

  if (a.blockIndex < b.blockIndex) {
    return -1;
  }

  if (a.note.startIndex > b.note.startIndex) {
    return 1;
  }

  if (a.note.startIndex < b.note.startIndex) {
    return -1;
  }

  if (a.note.endIndex < b.note.endIndex) {
    return 1;
  }

  if (a.note.endIndex > b.note.endIndex) {
    return -1;
  }

  return 0;
};

const Content = styled.div`
  display: grid;
  grid-gap: 24px;

  ${respondToWidth.sm`
      grid-gap: 28px;
  `}
`;

const NoteTitle = styled(StyledText)<{ $selected?: boolean }>`
  ${({ theme: { colors }, $selected }) => css`
    position: relative;
    padding-left: 14px;
    white-space: pre-line;
    overflow: hidden;
    cursor: pointer;
    text-align: left;
    transform: translateX(${$selected ? '-14px' : '0'});
    color: ${$selected ? colors.primary[1] : colors.neutrals[1]};

    &:before {
      display: ${$selected ? 'none' : 'block'};
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${colors.neutrals[7]};
    }

    &:after {
      display: ${$selected ? 'block' : 'none'};
      content: '';
      position: absolute;
      top: 7px;
      left: 0;

      border: 4px solid transparent;
      border-left: 4px solid ${colors.primary[1]};
    }

    ${respondToWidth.sm`
      transform: translateX(0);
  `}

    ${hideText(1)}
  `}
`;
