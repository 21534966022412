import { Breadcrumbs, SecondButton } from 'UI';
import { SecondButtonVariant } from 'UI/SecondButton/SecondButton';
import { AxiosError } from 'axios';
import { DeleteAccountModal } from 'components';
import { routes } from 'constant/routes';
import { useAsyncAction, useClientSize, useNotifications, useToggle } from 'hooks';
import MainLayout from 'layouts/MainLayout';
import { Courses } from 'pages';
import { PaymentInfoCard } from 'pages/Profile/components';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { actions } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

const Billing = () => {
  const { errorToast } = useNotifications();
  const { getIsBreakpoint } = useClientSize();
  const isWidthLs = getIsBreakpoint('ls');

  const {
    isOpen: isDeleteAccountModalOpen,
    open: onOpenDeleteAccountModal,
    close: onCloseDeleteAccountModal,
  } = useToggle();

  const { shutdown } = useIntercom();

  const [getMyCourses, isCoursesLoading] = useAsyncAction(actions.courses.getMyCourses);
  const [getBillingPortal] = useAsyncAction(actions.stripe.getBillingPortal);

  const handleOpenBillingHistory = async () => {
    try {
      const { checkout_url } = await getBillingPortal();
      const newWindow = window.open(checkout_url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        window.location.href = checkout_url;
      }
    } catch (error) {
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    getMyCourses();
  }, []);

  return (
    <StyledMainLayout>
      <Root>
        <StyledBreadcrumbs title="Billing" routeName={routes.profile} pageName="Profile" />

        <Container>
          <InfoWrapper>
            <InfoContent>
              <PaymentInfoCard />
              {isWidthLs && <Courses isLoading={isCoursesLoading} isShowChangePlanButton={true} />}
              <SecondButton
                variant={SecondButtonVariant.SECONDARY}
                text="Billing history"
                onClick={handleOpenBillingHistory}
              />
            </InfoContent>

            <ButtonWrapper>
              <StyledSecondButton onClick={onOpenDeleteAccountModal} text="Delete account" />
            </ButtonWrapper>
          </InfoWrapper>

          {!isWidthLs && <Courses isLoading={isCoursesLoading} isShowChangePlanButton={true} />}

          <DeleteAccountModal
            isOpen={isDeleteAccountModalOpen}
            onDelete={shutdown}
            onClose={onCloseDeleteAccountModal}
          />
        </Container>
      </Root>
    </StyledMainLayout>
  );
};

export default Billing;

const StyledMainLayout = styled(MainLayout)`
  ${respondToWidth.sm`
    width: 100%;
  `}
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 22px;
  ${respondToWidth.sm`
  display: none;
`}
`;

const Root = styled.div`
  ${({ theme: { colors } }) => css`
    height: 100%;
    padding: 28px 40px 40px 0;
    display: flex;
    flex-direction: column;

    ${respondToWidth.sm`
      display: block;
      padding: 16px;
      padding-bottom: 125px;
      overflow: hidden;
      background-color: ${colors.neutrals[11]};
    `}
  `}
`;

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    display: grid;
    grid-template-columns: 288px 1fr;
    grid-gap: 24px;
    height: 100%;

    ${respondToWidth.ls`
    grid-template-columns: 1fr;
  `}

    ${respondToWidth.sm`
      display: block;
      padding: 16px;
      padding-bottom: 70px;
      overflow: hidden;
      background-color: ${colors.neutrals[11]};

    `}
  `}
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InfoContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${respondToWidth.sm`
    margin-bottom: 8px
`}
`;

const ButtonWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    ${respondToWidth.sm`
    padding-top: 32px;

    border-top: 1px solid ${colors.neutrals[9]};
    `}
  `}
`;

const StyledSecondButton = styled(SecondButton)`
  flex-shrink: 0;
`;
