import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgHomeIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.105 3.774a3.25 3.25 0 0 0-4.21 0l-9.75 8.287A3.25 3.25 0 0 0 3 14.537v12.96a2.5 2.5 0 0 0 2.5 2.5h5a2.5 2.5 0 0 0 2.5-2.5v-7a.5.5 0 0 1 .5-.5H18a.5.5 0 0 1 .5.5v7a2.5 2.5 0 0 0 2.5 2.5h5.5a2.5 2.5 0 0 0 2.5-2.5v-12.96a3.25 3.25 0 0 0-1.145-2.476l-9.75-8.287Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgHomeIcon);
export default Memo;
