import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgFireIcon: FC<SVGIconProps> = ({ width = 18, height = 21, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 18 21" {...props}>
      <path fill="#FF9601" d="M8.563 0c-9.037 5.885-13.575 21 0 21 13.574 0 9.036-15.115 0-21Z" />
      <path fill="#FFC803" d="M8.563 19.6c-7.14 0-2.1-8.96 0-10.08 2.1 1.12 7.14 10.08 0 10.08Z" />
      <path
        fill="#FFC803"
        d="M8.563 19.6c-4.06.14-5.235-3.847-4.06-6.16.833 2.177 4.597 4.107 4.06 6.16ZM10.017 19.34c3.942-.98 2.962-4.578 1.886-7.454-1.114 2.033-2.966 5.627-1.886 7.454Z"
      />
    </svg>
  );
};
const Memo = memo(SvgFireIcon);
export default Memo;
