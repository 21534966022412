import { TextButton } from 'UI';
import { BulbIcon } from 'assets/icons';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import { SuggestionModal } from './components';

const SuggestionButton = () => {
  const [isShowModal, setIsShowModal] = useState(false);

  const handleOpenModal = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  return (
    <Container>
      <TextButton
        customTextCSS={CustomTextCSS}
        text="Have a suggestion?"
        variant="textWithIcon"
        onClick={handleOpenModal}
        iconComponent={<BulbIcon />}
      />

      <SuggestionModal isOpen={isShowModal} onClose={handleCloseModal} />
    </Container>
  );
};

export default SuggestionButton;

const Container = styled.div`
  margin-top: 4px;
  transform: translateX(-4px);
`;

const CustomTextCSS = css`
  ${({ theme: { colors } }) => `
    color: ${colors.neutrals[3]};
    
    :hover {
      color: ${colors.primary[1]};
      
      & > div > svg > path {
        fill: ${colors.primary[1]}};
      }
    }
  `}
`;
