import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgLockedIcon: FC<SVGIconProps> = ({
  fill = '#B0B0B0',
  width = 14,
  height = 18,
  viewBox = '0 0 16 18',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox={viewBox} {...props}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 6.4V4a4 4 0 0 0-8 0v2.4H2.4A2.4 2.4 0 0 0 0 8.8v6.4a2.4 2.4 0 0 0 2.4 2.4h11.2a2.4 2.4 0 0 0 2.4-2.4V8.8a2.4 2.4 0 0 0-2.4-2.4H12ZM5.6 4a2.4 2.4 0 1 1 4.8 0v2.4H5.6V4Zm4 7.2a1.6 1.6 0 0 1-.8 1.386V13.6a.8.8 0 0 1-1.6 0v-1.014A1.6 1.6 0 1 1 9.6 11.2Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgLockedIcon);
export default Memo;
