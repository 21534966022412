import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const formateSecondsToTime = (time: number) => {
  const days = Math.floor(time / 60 / 60 / 24);
  const hours = Math.floor((time / 60 / 60) % 24);
  const minutes = Math.floor((time / 60) % 60);
  const seconds = Math.floor(time % 60);

  const daysString = days > 0 ? days + ' days ' : '';
  const hoursString = hours < 10 ? '0' + hours : hours;
  const minutesString = minutes < 10 ? '0' + minutes : minutes;
  const secondsString = seconds < 10 ? '0' + seconds : seconds;
  const formattedDateString = `${daysString} ${hoursString}:${minutesString}:${secondsString}`;

  return {
    daysString,
    hoursString,
    minutesString,
    secondsString,
    formattedDateString,
  };
};

export default formateSecondsToTime;

export const renderIntervalText = (minutes = 0) => {
  if (minutes < 60) {
    return `${minutes} min`;
  }
  if (minutes === 60) {
    return '1 hour';
  }
  if (minutes > 60 && minutes < 1440) {
    return `${Math.ceil(minutes / 60)} hours`;
  }
  if (minutes === 1440) {
    return '1 day';
  }
  if (minutes > 1440) {
    return `${Math.ceil(minutes / 1440)} days`;
  }
};

export const renderTimedTestTime = (seconds = 0) => {
  const dur = dayjs.duration(seconds, 'seconds');

  const days = dur.days();
  const hours = dur.hours();
  const minutes = dur.minutes();

  if (days > 0) {
    if (hours > 0) {
      return `${days} day${days > 1 ? 's' : ''} ${hours} hour${hours > 1 ? 's' : ''}`;
    }
    return `${days} day${days > 1 ? 's' : ''}`;
  }
  if (hours > 0) {
    if (minutes > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return `${hours === 1 ? '1 hour' : `${hours} hours`}`;
  }
  if (minutes > 0) {
    return `${minutes === 1 ? 'one minute' : `${minutes} minutes`}`;
  }
  return 'less than a minute';
};
