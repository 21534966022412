import { BarControlButton, Button, ScrollbarContainer, StyledText } from 'UI';
import { GetPassageResultsResponseItemDto } from 'api/generated';
import { useAsyncAction, useInfinityScroll, useNotifications } from 'hooks';
import React, { FC, useEffect, useRef } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { actions, selectors, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import SidebarItem from './components/SidebarItem';

type TestResultSidebarProps = {
  questions: Array<GetPassageResultsResponseItemDto>;
  onHideClick: () => void;
  closeTest: () => void;
  tryAgain: () => void;
  onAnswerClick: (index: number) => void;
  selectedIndex: number;
  isShowCorrect: boolean;
  isTryAgainFetching: boolean;
};

const TestResultSidebar: FC<TestResultSidebarProps> = ({
  questions,
  onHideClick,
  closeTest,
  tryAgain,
  onAnswerClick,
  selectedIndex,
  isShowCorrect,
  isTryAgainFetching,
}) => {
  const { errorToast } = useNotifications();
  const { colors } = useTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const statisticTestMeta = useAppSelector(selectors.tests.selectCurrentTestStatisticMeta);
  const currentTestId = useAppSelector(selectors.tests.selectCurrentTestId);
  const isTestResultsFetching = useAppSelector(selectors.tests.selectIsTestResultsFetching);

  const [getTestStatisticAction] = useAsyncAction(actions.tests.getStatistic);

  const nextPage = (statisticTestMeta?.currentPage || 0) + 1;
  const hasAfterMore = (statisticTestMeta?.currentPage || 0) < (statisticTestMeta?.totalPages || 0);

  const getTestStatistic = async (id: string, onlyIncorrect?: boolean, page?: number) => {
    try {
      await getTestStatisticAction({ id, onlyIncorrect, page });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const scrollToChapter = (index: number) => {
    listRef.current?.scrollTo({
      top: (listRef.current.childNodes[index] as HTMLElement)?.offsetTop,
      behavior: 'auto',
    });
  };

  const handleClick = (index: number) => {
    onAnswerClick(index);
  };

  useEffect(() => {
    scrollToChapter(selectedIndex);
  }, [selectedIndex]);

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getTestStatistic(currentTestId || '', isShowCorrect, nextPage),
    hasAfterMore: hasAfterMore,
    isLoading: isTestResultsFetching,
    outerRef: containerRef,
  });

  return (
    <>
      <Container>
        <Title>Results</Title>
        <Wrapper>
          <QuestionsList ref={listRef} onScroll={handleScroll}>
            {questions.map((question, index) => (
              <SidebarItem
                key={index}
                index={index}
                title={question.subject}
                isCorrect={question.customerAnswerId === question.correctAnswerId}
                isSelect={index === selectedIndex}
                onClick={() => handleClick(index)}
              />
            ))}
            {isTestResultsFetching && (
              <LoaderContainer>
                <ThreeDots width={48} height={48} color={colors.primary[3]} />
              </LoaderContainer>
            )}
          </QuestionsList>
          <HideButton variant="hide" onClick={onHideClick} />
        </Wrapper>
        <ButtonsContainer>
          <StyledButton variant="primary" size="small" onClick={closeTest}>
            Close the test
          </StyledButton>
          <StyledButton variant="secondary" size="small" isLoading={isTryAgainFetching} onClick={tryAgain}>
            Try again
          </StyledButton>
        </ButtonsContainer>
      </Container>
      <Cover />
    </>
  );
};

export default TestResultSidebar;

const Cover = styled.div`
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme: { colors } }) => colors.neutrals[1]};
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  ${respondToWidth.ls`
    display:block;
  `}
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 68px 1fr 80px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.ls`
    position: absolute;
    max-width: 314px;
    right:0;
    top:0;
    grid-template-rows: 68px 1fr;
    z-index:2;
  `}
`;

const Wrapper = styled.div`
  padding-right: 8px;
  position: relative;

  ${respondToWidth.ls`
    padding-right: 4px;
  `}
`;

const Title = styled(StyledText)`
  margin-top: 28px;
  padding-left: 24px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  ${({ theme: { typography } }) => typography.title_5_bold_20};
`;

const QuestionsList = styled(ScrollbarContainer)`
  width: 100%;
  height: calc(100vh - 148px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 6px 26px 6px 24px;

  ${respondToWidth.ls`
    height:calc(100vh - 68px);
    padding: 10px 8px 110px 24px;
  `}
`;

const ButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  padding-inline: 24px 32px;

  ${respondToWidth.ls`
    display:none;
  `}
`;

const StyledButton = styled(Button)`
  width: fit-content;
  min-width: 126px;
`;

const HideButton = styled(BarControlButton)`
  display: none;
  position: absolute;
  top: -10px;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.ls`
    display: flex;
  `};
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
`;
