import { ConfirmModal, PointsResultModal } from 'UI';
import MaximumNumberPointsModal from 'UI/Modals/MaximumNumberPointsModal';
import TestFooter from 'components/TestFooter';
import TestHeader from 'components/TestHeader';
import { accessLevels } from 'constant';
import { MAXIMUM_COURSE_POINTS } from 'constant/maximumPoints';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications } from 'hooks';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import TimedTestContent from './components/TimedTestContent';

type FormType = {
  id: string;
};

enum TestModalEnum {
  END_TEST,
  RESULT,
  MAXIMUM_POINTS,
}

const TimedTest = () => {
  const [currentModal, setCurrentModal] = useState<TestModalEnum | null>(null);
  const [select, setSelect] = useState<string>();

  const navigate = useNavigate();
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const { errorToast } = useNotifications();

  const timedTest = useAppSelector(selectors.tests.selectTimedTest);
  const timedTestAnswers = useAppSelector(selectors.tests.selectTimedTestAnswers);
  const currentIndex = useAppSelector(selectors.tests.selectCurrentQuestionIndex);
  const testCompleteResult = useAppSelector(selectors.tests.selectCurrentTestPointsEarned);
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const isShowStreakEnabled = useAppSelector(selectors.settings.showStreaks);
  const [saveAnswerAction, isSaveAnswerLoading] = useAsyncAction(actions.tests.saveAnswer);
  const [finishTestAction, isClosedTestLoading] = useAsyncAction(actions.tests.finishTest);
  const [getCourseStatisticAction, isStatisticLoading] = useAsyncAction(actions.performance.getCourseStatistic);

  const activeIndex = currentIndex || 0;
  const currentAnswerId = timedTestAnswers[activeIndex]?.id;
  const points = testCompleteResult || 0;
  const { hasPerformance } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  const methods = useForm<FormType>({
    mode: 'onChange',
    defaultValues: { id: '' },
  });

  const setInitialSelect = () => {
    setSelect(currentAnswerId || '');
    methods.setValue('id', currentAnswerId || '', { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };

  useEffect(setInitialSelect, [timedTestAnswers, activeIndex]);

  const saveAnswer = async (questionId: string, optionId: string) => {
    try {
      if (!timedTest) {
        throw '';
      }

      await saveAnswerAction({ body: { optionId, questionId }, isTutor: false, passageId: timedTest?.id });
      nextQuestion();
    } catch {
      errorToast('Something went wrong');
    }
  };

  const getClosedTest = async () => {
    try {
      if (!timedTest) {
        throw '';
      }

      const response = await finishTestAction({ passageId: timedTest?.id });
      return response;
    } catch {
      errorToast('Something went wrong');
    }
  };

  const getCourseStatistic = async () => {
    try {
      const response = await getCourseStatisticAction();
      return response;
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const nextQuestion = () => {
    const canSwitchToNext = timedTest && activeIndex < timedTest.questions.length - 1;
    if (canSwitchToNext) {
      dispatch(actions.tests.setCurrentQuestionIndex(activeIndex + 1));
    } else {
      showEndTestModal();
    }
  };

  const prevQuestion = () => {
    const isNotFirst = activeIndex > 0;
    if (isNotFirst) {
      dispatch(actions.tests.setCurrentQuestionIndex(activeIndex - 1));
    }
  };

  const endTest = async () => {
    await getClosedTest();

    if (hasPerformance && isShowStreakEnabled) {
      await openFinishModalWithGoldSubscription();
    } else {
      setCurrentModal(TestModalEnum.RESULT);
    }
  };

  const openFinishModalWithGoldSubscription = async () => {
    const { totalInPoints } = (await getCourseStatistic()) || {};

    if (totalInPoints === MAXIMUM_COURSE_POINTS) {
      setCurrentModal(TestModalEnum.MAXIMUM_POINTS);
    } else {
      setCurrentModal(TestModalEnum.RESULT);
    }
  };

  const showEndTestModal = () => {
    setCurrentModal(TestModalEnum.END_TEST);
  };

  const onSubmit = (data: FormType) => {
    const currentQuestion = timedTest?.questions[activeIndex];

    saveAnswer(currentQuestion?.id || '', data.id);
    setSelect(data.id);
    methods.reset();
  };

  const isModalButtonLoading = isClosedTestLoading || isSaveAnswerLoading;
  const isLastQuestion = activeIndex + 1 === timedTest?.questions?.length || 0;
  const buttonText = isLastQuestion ? 'Finish test' : 'Confirm';
  const isEarned = (testCompleteResult || 0) > 0;

  return (
    <Container>
      <TestHeader
        activeIndex={activeIndex + 1}
        totalCount={timedTest?.questions.length || 0}
        endTest={showEndTestModal}
        isShowTimer
      />
      <FormProvider {...methods}>
        <Main onSubmit={methods.handleSubmit(onSubmit)}>
          <TimedTestContent
            question={timedTest?.questions[activeIndex]}
            select={select}
            buttonText={buttonText}
            isLoading={isSaveAnswerLoading}
          />
          <TestFooter
            onEndClick={showEndTestModal}
            buttonText={buttonText}
            type="submit"
            isDisabledButton={!methods.formState.isValid}
            onPrevClick={prevQuestion}
            isLoading={isSaveAnswerLoading}
          />
        </Main>
      </FormProvider>
      <ConfirmModal
        title="Do you want to end the test?"
        text="You won't be able to resume it"
        textColor={colors.system.red}
        isOpen={currentModal === TestModalEnum.END_TEST}
        onClose={() => setCurrentModal(null)}
        onRightButtonClick={() => setCurrentModal(null)}
        onLeftButtonClick={endTest}
        isLeftButtonLoading={isModalButtonLoading || isStatisticLoading}
      />
      <PointsResultModal
        isOpen={currentModal === TestModalEnum.RESULT}
        onClose={() => navigate(routes.testResult)}
        title={isEarned ? 'Congratulation!' : 'Oops...'}
        subtitle={isEarned ? undefined : ' You have lost'}
        isEarned={isEarned}
        onLinkClick={() => navigate(routes.performance)}
        points={Math.abs(points)}
      />
      <MaximumNumberPointsModal
        isOpen={currentModal === TestModalEnum.MAXIMUM_POINTS}
        onClose={() => navigate(routes.testResult)}
        points={Math.abs(points)}
      />
    </Container>
  );
};

export default TimedTest;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 92px 1fr;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};

  ${respondToWidth.sm`
    grid-template-rows: 47px 1fr;
  `};
`;

const Main = styled.form`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  grid-template-rows: 1fr 92px;

  ${respondToWidth.sm`
    grid-template-rows: 1fr 68px;
  `}
`;
