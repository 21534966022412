import { Checkbox, Input, StyledText, Tag } from 'UI';
import { StudyGuideFormType } from 'hooks/useGuideSearch/useGuideSearch';
import { debounce } from 'lodash';
import React, { FC, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';

import { SidebarTabs } from '../Body/Body';

const MAX_LENGTH = 100;

type HeaderProps = {
  isShowOnlyChapters: boolean;
  activeTab: SidebarTabs;
  toggleShowOnlyChapters: () => void;
  setActiveTab: (tab: SidebarTabs) => void;
  formMethods: UseFormReturn<StudyGuideFormType, any>;
};

const Header: FC<HeaderProps> = ({
  isShowOnlyChapters,
  activeTab,
  toggleShowOnlyChapters,
  setActiveTab,
  formMethods,
}) => {
  const isContentActiveTab = activeTab === SidebarTabs.CONTENT;
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const { reset, setValue, register, getValues } = formMethods;
  const searchValue = getValues('search');
  const hasSearchValue = Boolean(searchValue);

  const tabs = useMemo(
    () => [
      {
        title: 'Content',
        type: SidebarTabs.CONTENT,
      },
      {
        title: 'Highlights and notes',
        type: SidebarTabs.NOTES,
      },
    ],
    [],
  );

  const handleFormReset = () => {
    reset();
  };

  const handleInputClear = () => {
    reset();
  };

  const debounceRequest = debounce((value: string) => {
    setValue('search', value);
  }, 500);

  const onChange = (value: string) => {
    debounceRequest(value);
  };

  return (
    <Root>
      {hasSearchValue && (
        <CancelWrapper>
          <button onClick={handleFormReset}>Cancel</button>
        </CancelWrapper>
      )}
      {!hasSearchValue && <Title>{activeCourse?.name || ''}</Title>}

      <Input
        {...register('search')}
        $CSS={$InputCSS}
        variant="search"
        maxLength={MAX_LENGTH}
        onIconClick={handleInputClear}
        showIcon={hasSearchValue}
        onChange={(e) => onChange(e.target.value.trim())}
      />

      {!hasSearchValue && (
        <ButtonsContainer>
          {tabs.map((tab, index) => (
            <Tag
              key={index}
              onClick={() => setActiveTab(tab.type)}
              text={tab.title}
              size="small"
              selected={tab.type === activeTab}
            />
          ))}
        </ButtonsContainer>
      )}
      {isContentActiveTab && !hasSearchValue && (
        <StyledCheckbox checked={isShowOnlyChapters} onClick={toggleShowOnlyChapters} label="Show only chapters" />
      )}
    </Root>
  );
};

export default Header;

const Root = styled.div`
  ${({ theme: { colors } }) => css`
    padding: 28px 24px 7px;

    border-bottom: 1px solid ${colors.neutrals[9]};
  `}
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => css`
    margin-bottom: 16px;
    word-break: break-all;

    ${typography.title_5_bold_20}
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;

  button {
    padding-inline: 11px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ theme: { typography } }) => css`
    margin-top: 20px;

    ${typography.body_basic_semibold_14}
  `}
`;

const CancelWrapper = styled.div`
  ${({ theme: { colors, typography } }) => css`
    margin-bottom: 16px;

    & button {
      width: 100%;
      text-align: right;
      color: ${colors.primary[1]};

      ${typography.body_large_semibold_16};
    }
  `}
`;

const $InputCSS = css`
  margin-bottom: 16px;
`;
