import { Answer } from 'UI';
import { Button, ExpandImage, ScrollbarContainer, StyledText } from 'UI';
import { CreateReportDtoResourceEnum, QuestionResponseDto } from 'api/generated';
import { SpamIcon } from 'assets/icons';
import { Loader, ReportModal } from 'components';
import { sortBy } from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { selectors, useAppSelector } from 'store';
import { useTheme } from 'styled-components';
import styled, { css } from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';
import { getTestAnswerLetter } from 'utils';

import Explanation from './components/Explanation';

type TestContentProps = {
  question?: QuestionResponseDto;
  correctId?: string;
  onNextClick: () => void;
  isShowExplanation: boolean;
  isDisabled: boolean;
  select?: string;
  isLoading: boolean;
  isNextQuestion: boolean;
};

const TutorTestContent: FC<TestContentProps> = ({
  question,
  onNextClick,
  isShowExplanation,
  isDisabled,
  select,
  correctId,
  isLoading,
  isNextQuestion,
}) => {
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const [isSomeImageLoaded, setSomeImageLoaded] = useState(false);
  const {
    control,
    formState: { isValid },
  } = useFormContext();
  const questionRef = useRef<HTMLDivElement | null>(null);
  const explanationRef = useRef<HTMLDivElement | null>(null);
  const activeIndex = useAppSelector(selectors.tests.selectCurrentQuestionIndex) || 0;

  const scrollToQuestion = () => {
    questionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const scrollToExplanation = () => {
    explanationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    if (isShowExplanation && !isNextQuestion) {
      scrollToExplanation();
    }
  }, [isShowExplanation]);

  useEffect(scrollToQuestion, [activeIndex]);

  const { colors } = useTheme();

  const hasImages = Boolean(question?.imagesUrls.length);
  const hasShowExplanation = isShowExplanation && !isLoading;

  return (
    <MainContainer>
      <Container>
        <Wrapper $withImage={hasImages} ref={questionRef}>
          <Title $withImage={hasImages}>{question?.subject || ''}</Title>
          {hasImages ? (
            <ImagesContainer>
              {question?.imagesUrls.map((url, index) => (
                <ImageContainer
                  key={index}
                  image={{ url, alt: question?.subject }}
                  setIsImageLoaded={setSomeImageLoaded}
                />
              ))}
              {!isSomeImageLoaded && (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )}
            </ImagesContainer>
          ) : null}
          <Cover $isShowExplanation={hasShowExplanation}>
            <AnswersContainer $withImage={hasImages}>
              {sortBy(question?.options, ['orderIndex']).map((option, index) => {
                const isCorrectAnswer = hasShowExplanation && option.id === correctId;
                const isSelectedAnswer = isCorrectAnswer || option.id === select;

                return (
                  <Controller
                    key={option.id}
                    control={control}
                    name="id"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Answer
                        {...field}
                        id={option.id}
                        onChange={() => field.onChange(option.id)}
                        disabled={isDisabled || Boolean(select)}
                        customChecked={isSelectedAnswer}
                        correct={option.id === correctId}
                        changeColor={isSelectedAnswer}
                        label={`${getTestAnswerLetter(index)}. ${option.title}`}
                        showIcon={option.id === select}
                        type={isCorrectAnswer ? 'checkbox' : 'radio'}
                        isLoading={isLoading}
                      />
                    )}
                  />
                );
              })}
            </AnswersContainer>
            <ReportButton
              iconComponent={<SpamIcon width={24} height={24} color={colors.system.red} />}
              variant="secondary"
              size="small"
              onClick={() => setIsShowReportModal(true)}>
              Report a problem
            </ReportButton>
            {hasShowExplanation ? (
              <Explanation
                onNextClick={onNextClick}
                ref={explanationRef}
                isLoading={isLoading}
                correctAnswerId={correctId}
              />
            ) : (
              <StyledButton
                type={hasShowExplanation ? 'button' : 'submit'}
                variant="primary"
                size="middle"
                disabled={!isValid}
                isLoading={isLoading}>
                Confirm
              </StyledButton>
            )}
          </Cover>
        </Wrapper>
        <ReportModal
          resource={CreateReportDtoResourceEnum.TestQuestion}
          resourceId={question?.id || ''}
          isOpen={isShowReportModal}
          onClose={() => setIsShowReportModal(false)}
        />
      </Container>
    </MainContainer>
  );
};

export default TutorTestContent;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 8px;

  ${respondToWidth.sm`
    padding-right: 4px;
  `}
`;

const Container = styled(ScrollbarContainer)`
  height: calc(100vh - 184px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${respondToWidth.sm`
    padding-inline: 16px 8px;
    height: calc(100vh - 152px);
  `}
`;

const Wrapper = styled.div<{ $withImage: boolean }>`
  height: 100%;
  width: 100%;
  max-width: 668px;
  display: flex;
  flex-direction: column;
  padding-block: 60px;

  ${({ $withImage }) => respondToHeight.ls`
    padding-block: ${$withImage && '30px'};
  `}

  ${respondToWidth.ls`
    padding-block: 24px 20px;
  `}
`;

const Title = styled(StyledText)<{ $withImage: boolean }>`
  margin-bottom: 40px;
  white-space: pre-wrap;
  ${({ theme: { typography } }) => css`
    ${typography.title_3_bold_28};

    ${respondToWidth.sm`
        ${typography.title_5_bold_20};
    `}
  `}
  ${({ $withImage }) => respondToHeight.ls`
    margin-bottom: ${$withImage && '20px'};
  `}
`;

const AnswersContainer = styled.div<{ $withImage: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 20px;
  margin-bottom: 28px;

  ${({ $withImage }) => respondToHeight.ls`
    gap:${$withImage && '10px'};
  `}

  ${respondToWidth.sm`
    gap:16px;
  `}
`;

const Cover = styled.div<{ $isShowExplanation: boolean }>`
  padding-bottom: 28px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $isShowExplanation }) => !$isShowExplanation && 'space-between'};
  ${respondToWidth.sm`
    justify-content: start;
  `};
`;

const StyledButton = styled(Button)`
  width: 144px;
  height: 52px;

  ${respondToWidth.sm`
    display:none;
  `}
`;

const ReportButton = styled(Button)`
  display: none;
  margin-top: 32px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.sm`
    display: flex;
  `}
`;

const ImagesContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  gap: 14px;
  align-items: center;

  ${respondToHeight.ls`
    margin-bottom: 20px;
    gap:7px;
  `}
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 161px;

  ${respondToWidth.sm`
    height:82px;
  `}
`;

const ImageContainer = styled(ExpandImage)`
  width: 100%;
  max-width: 32%;
  height: 121px;
  border-radius: 16px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;

    ${respondToWidth.sm`
        border-radius: 8px;
    `}
  }

  ${respondToWidth.sm`
    height:62px;
  `}
`;
