import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgJcbIcon: FC<SVGIconProps> = (props) => {
  return (
    <svg width={32} height={22} viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28.96 16.953a4.031 4.031 0 0 1-4.032 4.031H3.04V5.048a4.032 4.032 0 0 1 4.032-4.032H28.96v15.937Z"
        fill="#fff"
      />
      <path
        d="M21.824 11.35c.625.014 1.254-.028 1.876.021.63.118.783 1.072.223 1.385-.383.206-.837.077-1.251.113h-.848v-1.52Zm2.238-1.72c.139.49-.334.93-.806.863h-1.432c.01-.462-.02-.964.014-1.402.574.016 1.153-.033 1.723.026.245.061.45.263.5.513Zm3.446-7.27c.027.936.004 1.922.012 2.877-.002 3.884.004 7.768-.003 11.651-.025 1.456-1.315 2.72-2.76 2.75-1.448.006-2.895 0-4.342.002V13.77c1.577-.008 3.154.016 4.73-.012.732-.046 1.532-.529 1.566-1.333.086-.808-.675-1.367-1.399-1.455-.278-.008-.27-.082 0-.114.69-.149 1.232-.863 1.029-1.578-.173-.752-1.004-1.043-1.696-1.042-1.41-.01-2.82-.001-4.23-.004.01-1.096-.018-2.193.016-3.288.111-1.43 1.434-2.608 2.859-2.583h4.218Z"
        fill="url(#paint0_linear_9025_215876)"
      />
      <path
        d="M4.484 5.09c.036-1.453 1.331-2.707 2.775-2.728 1.442-.005 2.883 0 4.325-.002-.004 4.862.008 9.725-.006 14.587-.056 1.435-1.337 2.666-2.765 2.691-1.444.005-2.888 0-4.333.002v-6.07c1.403.332 2.874.473 4.305.254.856-.138 1.792-.558 2.082-1.446.213-.759.093-1.558.124-2.337V8.23H8.515c-.012 1.197.022 2.396-.018 3.591-.067.735-.795 1.202-1.488 1.177-.859.01-2.562-.623-2.562-.623-.004-2.242.025-5.05.037-7.285Z"
        fill="url(#paint1_linear_9025_215876)"
      />
      <path
        d="M12.508 8.961c-.13.028-.027-.444-.06-.623.009-1.131-.018-2.264.015-3.395.112-1.435 1.444-2.617 2.875-2.583h4.214c-.004 4.862.008 9.725-.006 14.587-.055 1.435-1.337 2.666-2.765 2.691-1.444.006-2.889.001-4.333.002v-6.65c.986.81 2.327.936 3.556.938.927 0 1.848-.143 2.747-.357v-1.218c-1.014.505-2.206.826-3.33.536-.784-.195-1.353-.953-1.34-1.762-.09-.841.402-1.73 1.23-1.98 1.026-.321 2.145-.076 3.107.342.207.108.416.242.333-.103V8.43c-1.61-.383-3.322-.524-4.94-.107-.467.132-.923.332-1.303.64Z"
        fill="url(#paint2_linear_9025_215876)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9025_215876"
          x1={20.367}
          y1={10.63}
          x2={27.475}
          y2={10.63}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#007B40" />
          <stop offset={1} stopColor="#55B330" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9025_215876"
          x1={4.329}
          y1={11.165}
          x2={11.467}
          y2={11.165}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#1D2970" />
          <stop offset={1} stopColor="#006DBA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9025_215876"
          x1={12.438}
          y1={10.798}
          x2={19.551}
          y2={10.798}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#6E2B2F" />
          <stop offset={1} stopColor="#E30138" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgJcbIcon);
export default Memo;
