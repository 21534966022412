import { Stripe, StripeElements, StripeError } from '@stripe/stripe-js';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import { ValidateConfirmationTokenResponseDtoPaymentTypeEnum } from 'api/generated';
import { FormEvent, useState } from 'react';
import { selectors, useAppSelector } from 'store';

import { actions } from '../../store/ducks/payments';
import { useGoogleAnalytics } from '../index';
import { useAsyncAction } from '../index';

interface CustomFormEvent<T = Element> extends FormEvent<T> {
  elementType?: string;
}

const returnUrl = new URL('https://traineracademy.org/'); // TODO: вынести в константы

const useStripeUnauthorizedForm = (
  clientSecret: string | undefined,
  invoiceId: string,
  courseId: string,
  term?: TermEnum,
) => {
  const [validateToken] = useAsyncAction(actions.validateToken);

  const [elements, setElements] = useState<StripeElements | null>(null);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSucceeded, setIsSucceeded] = useState(false);

  const email = useAppSelector(selectors.auth.selectEmail);
  const disabled = !stripe;

  const { getEventSubscriptionFromTrialVersionLandingGA, getEventPurchaseFromTrialVersionLandingGA } =
    useGoogleAnalytics();

  const confirmPayment = async (stripe: Stripe, elements: StripeElements) => {
    try {
      if (!clientSecret) {
        return;
      }
      setIsLoading(true);

      await elements.submit();

      const { confirmationToken } = await stripe.createConfirmationToken({
        elements,
        params: {
          return_url: returnUrl.href,
          payment_method_data: {
            billing_details: { email },
          },
        },
      });

      if (confirmationToken) {
        const {
          isAccountExists,
          isCoursePurchased,
          clientSecret: newClientSecret,
          paymentType,
        } = await validateToken({
          invoiceId,
          courseId,
          confirmationToken: confirmationToken.id,
          useToken: false,
        });

        console.log('paymentType => ', paymentType);

        if (paymentType === ValidateConfirmationTokenResponseDtoPaymentTypeEnum.Free && term === TermEnum.LIFE_TIME) {
          getEventPurchaseFromTrialVersionLandingGA();
        }

        if (paymentType === ValidateConfirmationTokenResponseDtoPaymentTypeEnum.Free && term !== TermEnum.LIFE_TIME) {
          getEventSubscriptionFromTrialVersionLandingGA();
        }

        if (isAccountExists) {
          if (isCoursePurchased) {
            setErrorMessage('Course is already purchased.');
            return;
          }

          if (newClientSecret && typeof newClientSecret === 'string') {
            clientSecret = newClientSecret;
          }
        }
      }

      const res =
        (await stripe.confirmPayment({
          elements,
          clientSecret,
          redirect: 'if_required',
          confirmParams: {
            return_url: returnUrl.href,
            payment_method_data: {
              billing_details: {
                email: email.toLowerCase(),
              },
            },
          },
        })) || {};

      if (res.error?.setup_intent?.last_setup_error?.message || res?.error) {
        setError(res.error);
        setCashAppError(res.error.setup_intent?.last_setup_error?.message);
      } else {
        setIsSucceeded(true);
      }
    } catch (error: any) {
      setErrorMessage('Something went wrong');
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setError = (error?: StripeError) => {
    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setErrorMessage(error?.message || '');
    } else if (error) {
      setErrorMessage('An unexpected error occurred.');
    }
    setIsLoading(false);
  };

  const setCashAppError = (error?: string) => {
    setErrorMessage(error || 'An unexpected error occurred.');
    setIsLoading(false);
  };

  const handleSubmit = async (e: CustomFormEvent<HTMLFormElement>) => {
    if (e?.elementType !== 'expressCheckout') {
      e?.preventDefault();
    }

    if (!stripe || !elements) {
      return;
    }

    await confirmPayment(stripe, elements);
  };

  return {
    handleSubmit,
    errorMessage,
    isLoading,
    disabled,
    isSucceeded,
    setStripe,
    setElements,
    stripe,
    elements,
  };
};

export default useStripeUnauthorizedForm;
