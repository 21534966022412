import { css, useTheme } from 'styled-components';

const useGetColorForTimer = (seconds: number) => {
  const { colors } = useTheme();

  if (seconds <= 180) {
    return css`
      color: ${colors.system.red};
      background-color: ${colors.system.lightRed};
    `;
  }
  if (seconds > 180 && seconds <= 600) {
    return css`
      color: ${colors.system.yellow};
      background-color: ${colors.system.lightYellow};
    `;
  }
  if (seconds >= 600) {
    return css`
      color: ${colors.system.green};
      background-color: ${colors.system.lightGreen};
    `;
  }
};

export default useGetColorForTimer;
