import { ErrorField } from 'UI/Input/styled';
import React, { ForwardRefRenderFunction, TextareaHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';

type TextareaProps = {
  error?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea: ForwardRefRenderFunction<HTMLTextAreaElement, TextareaProps> = (
  { error, className, ...props },
  ref,
) => {
  return (
    <Container className={className}>
      <StyledTextarea ref={ref} {...props} />
      {error && <ErrorField>{error}</ErrorField>}
    </Container>
  );
};

export default forwardRef<HTMLTextAreaElement, TextareaProps>(Textarea);

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 12px;
  resize: none;
  outline: none;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[8]};
  border-radius: 8px;
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

const Container = styled.div`
  max-width: 468px;
  height: 124px;
  width: 100%;
  position: relative;
`;
