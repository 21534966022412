import { TestMenuButton } from 'components/TestFooter/TestFooter';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { actions, selectors, useAppSelector } from 'store';

import MenuItem from '../MenuItem';

type AddToFlashcardItemProps = {
  iconMapping: Record<TestMenuButton, React.ReactNode>;
  isAddedToFlashcardInitialValue: boolean;
  questionId: string;
};

const AddToFlashcardItem: FC<AddToFlashcardItemProps> = ({
  iconMapping,
  isAddedToFlashcardInitialValue,
  questionId,
}) => {
  const [isAddedToFlashcard, setIsAddedToFlashcard] = useState(false);

  const { errorToast } = useNotifications();

  const [addQuestionToFlashcardAction, isAddLoading] = useAsyncAction(actions.flashcardsPacks.addToFlashcards);
  const [removeQuestionFromFlashcardAction, isRemoveLoading] = useAsyncAction(
    actions.flashcardsPacks.removeFromFlashcards,
  );
  const activeIndex = useAppSelector(selectors.tests.selectCurrentQuestionIndex);

  useEffect(() => {
    setIsAddedToFlashcard(isAddedToFlashcardInitialValue);
  }, [activeIndex]);

  const isLoading = isAddLoading || isRemoveLoading;

  const addQuestionToFlashcard = async () => {
    try {
      await addQuestionToFlashcardAction({ questionId });
      setIsAddedToFlashcard(true);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const removeQuestionFromFlashcard = async () => {
    try {
      await removeQuestionFromFlashcardAction({ questionId });
      setIsAddedToFlashcard(false);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  return (
    <>
      {isAddedToFlashcard ? (
        <MenuItem
          onClick={removeQuestionFromFlashcard}
          isLoading={isLoading}
          iconMapping={iconMapping}
          type={TestMenuButton.REMOVE_FLASHCARD}
        />
      ) : (
        <MenuItem
          onClick={addQuestionToFlashcard}
          isLoading={isLoading}
          iconMapping={iconMapping}
          type={TestMenuButton.ADD_FLASHCARD}
        />
      )}
    </>
  );
};

export default AddToFlashcardItem;
