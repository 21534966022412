import { Answer, ExpandImage, StyledText } from 'UI';
import { GetPassageResultsResponseItemDto } from 'api/generated';
import { Loader } from 'components';
import { sortBy } from 'lodash';
import React, { ForwardRefRenderFunction, forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { getTestAnswerLetter } from 'utils';

import Explanation from './components/Explanation';
import QuestionAddToFlashcardButton from './components/QuestionAddToFlashcardButton';
import QuestionRecheckButton from './components/QuestionRecheckButton';

type QuestionProp = {
  question: GetPassageResultsResponseItemDto;
  index: number;
};

const Question: ForwardRefRenderFunction<HTMLDivElement, QuestionProp> = ({ question, index }, ref) => {
  const title = `${index + 1}. ${question.subject}`;
  const [isSomeImageLoaded, setSomeImageLoaded] = useState(false);

  return (
    <Container ref={ref}>
      <Title as="h2">{title}</Title>
      {question?.imagesUrls?.length ? (
        <ImagesContainer>
          {question.imagesUrls.map((img, index) => (
            <ImageContainer
              key={img + index}
              image={{ url: img, alt: question.subject }}
              setIsImageLoaded={setSomeImageLoaded}
            />
          ))}
          {!isSomeImageLoaded && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
        </ImagesContainer>
      ) : null}
      <AnswersContainer>
        {sortBy(question?.options, ['orderIndex']).map((answer, index) => (
          <AnswerContainer key={answer.id}>
            <Answer
              disabled
              label={`${getTestAnswerLetter(index)}. ${answer.title}`}
              customChecked={answer.id === question.customerAnswerId}
              checked={answer.id === question.correctAnswerId}
              correct={answer.id === question.correctAnswerId}
              changeColor
              showIcon={answer.id === question.customerAnswerId}
              type="checkbox"
            />
          </AnswerContainer>
        ))}
      </AnswersContainer>
      <Explanation
        image={{ url: question.explanationImageUrl || '', alt: question.explanationText || '' }}
        description={question.explanationText || ''}
      />
      <ButtonsContainer>
        <QuestionRecheckButton isMarkedInitialValue={Boolean(question.isMarked)} questionId={question.id} />
        <QuestionAddToFlashcardButton
          isAddedToFlashcardInitialValue={Boolean(question.isInFlashcard)}
          questionId={question.id}
        />
      </ButtonsContainer>
    </Container>
  );
};

export default forwardRef<HTMLDivElement, QuestionProp>(Question);

const Container = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
  display: grid;
  gap: 24px;

  ${respondToWidth.sm`
    padding:12px;
    padding-bottom:20px;
    gap:16px;
  `}
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_5_bold_20};

    ${respondToWidth.sm`
        ${typography.body_large_bold_16};
    `}
  `};
`;

const ImagesContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: start;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 121px;
`;

const ImageContainer = styled(ExpandImage)`
  width: 100%;
  max-width: 200px;
  height: 121px;
  border-radius: 16px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    object-fit: contain;

    ${respondToWidth.sm`
      border-radius: 8px;
    `}
  }

  ${respondToWidth.sm`
    height:62px;
  `}
`;

const AnswersContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  gap: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 24px;

  ${respondToWidth.sm`
    flex-direction:column;
    gap:16px;
  `}
`;

const AnswerContainer = styled.li`
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 12px;
  width: 100%;
`;
