import { Button, Input, ScrollbarContainer, StyledText, TextButton } from 'UI';
import styled from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';

export const Container = styled(ScrollbarContainer)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
  display: grid;
  grid-template-rows: 60px 1fr;

  ${respondToWidth.s`
    grid-template-rows: 47px 1fr;
  `}
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: grid;
  place-items: center;
  padding-top: 48px;
  padding-bottom: 84px;

  ${respondToWidth.s`
    padding:0;
  `}
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 668px;
  height: 100%;
  max-height: 708px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 60px 100px;
  margin: 20px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 28px;
  text-align: center;

  ${respondToWidth.sm`
    padding: 30px;
    height:100%;
    max-height:100%;
    flex-grow:0;
    justify-content: center;
    border-radius:0;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 0 16px;
    justify-content: start;
  `}
`;

export const Title = styled(StyledText)<{ $hasError: boolean }>`
  color: ${({ theme: { colors } }) => colors.primary[1]};
  ${({ theme: { typography } }) => typography.title_2_bold_32};
  margin-bottom: ${({ $hasError }) => !$hasError && '120px'};

  ${({ $hasError }) => respondToWidth.s`
    margin-bottom: 40px;
    margin-top: ${!$hasError && '92px'}
  `}
  ${({ $hasError }) => respondToHeight.sm`
    margin-bottom: ${!$hasError && '60px'}
  `}

${({ $hasError }) => respondToHeight.s`
    margin-bottom: ${!$hasError && '20px'}
  `}
`;

export const TextContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  ${respondToWidth.s`
    flex-direction: column-reverse;
  `}
`;

export const ErrorField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: start;
  margin: 32px 0;
  padding: 12px 16px;
  border-radius: 12px;
  background-color: ${({ theme: { colors } }) => colors.system.lightRed};

  ${respondToWidth.s`
    margin: 4px 0 16px;
  `}
`;

export const Text = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.footnote_semibold_12};
`;

export const StyledInput = styled(Input)`
  margin-bottom: 44px;

  ${respondToWidth.s`
    margin-bottom: 16px;
  `}
`;

export const Cover = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;

  ${respondToWidth.s`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 52px;
  ${respondToWidth.s`
    margin-top: 52px;
  `}
`;

export const StyledTextButton = styled(TextButton)`
  position: absolute;
  top: 0;
  right: 12px;
  ${({ theme: { typography } }) => typography.footnote_semibold_12};

  ${({ theme: { typography } }) => respondToWidth.s`
    position: initial;
    margin-top: 24px;
    ${typography.body_basic_bold_14};
  `}
`;

export const Description = styled(StyledText)`
  max-width: 321px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

export const Link = styled.a`
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  ${({ theme: { typography } }) => typography.body_basic_link_14};
`;

export const Transfer = styled.span`
  margin: 0;
  padding: 0;
  display: block;

  ${respondToWidth.sm`
    display: none
    `};
`;

export const IconContainer = styled.div`
  margin-right: 11px;
  width: 24px;
  height: 24px;
`;

export const Form = styled.form`
  height: calc(100vh - 530px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 270px;
  input {
    -webkit-text-fill-color: ${({ theme: { colors } }) => colors.neutrals[1]};
  }

  ${respondToWidth.sm`
    height: auto;
  `}
`;
