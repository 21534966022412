import React, { ButtonHTMLAttributes, FC } from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { typography } from 'styles/general/typography';

type SecondButtonProps = {
  variant?: SecondButtonVariant;
  text: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export enum SecondButtonVariant {
  PRIMARY,
  SECONDARY,
}

const SecondButton: FC<SecondButtonProps> = ({ variant = SecondButtonVariant.PRIMARY, text, ...props }) => {
  return (
    <Root $variant={variant} {...props}>
      {text}
    </Root>
  );
};

export default SecondButton;

const getSecondButtonVariant = (colors: DefaultTheme['colors']) => ({
  [SecondButtonVariant.PRIMARY]: css`
    color: ${colors.system.red};
    background-color: ${colors.neutrals[11]};

    :hover {
      color: ${colors.primary[1]};
      border-color: ${colors.primary[1]};
    }

    :active:enabled {
      color: ${colors.neutrals[11]};
      background-color: ${colors.primary[1]};
    }

    ${respondToWidth.sm`
        background-color: ${colors.primary[6]};
    `}
  `,
  [SecondButtonVariant.SECONDARY]: css`
    color: ${colors.neutrals[1]};
    background-color: ${colors.neutrals[11]};

    :hover {
      background-color: ${colors.neutrals[8]};
    }

    :active:enabled {
      border-color: ${colors.neutrals[1]};
    }

    ${respondToWidth.sm`
        background-color: ${colors.neutrals[10]};

        :active:enabled {
            background-color: ${colors.neutrals[8]};
            border-color: ${colors.neutrals[1]};
        }
    `}
  `,
});

const Root = styled.button<{ $variant: SecondButtonVariant }>`
  ${({ theme: { colors }, $variant }) => css`
    width: 100%;
    height: 40px;

    padding: 0 20px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    border: 1px solid transparent;

    user-select: none;

    :disabled {
      cursor: not-allowed;
    }

    ${typography.body_basic_medium_14}
    ${getSecondButtonVariant(colors)[$variant]}

    ${respondToWidth.sm`
        height: 52px;
        ${typography.body_large_medium_16}
    `}
  `}
`;
