import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgTodoIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.667 2.667H25a3 3 0 0 1 3 3v20.666a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V5.667a3 3 0 0 1 3-3h2.333V1a1 1 0 0 1 1-1H11a1 1 0 0 1 1 1v1.667h8V1a1 1 0 0 1 1-1h.667a1 1 0 0 1 1 1v1.667Zm-12.334 8a1 1 0 0 0-1 1v.666a1 1 0 0 0 1 1h11.334a1 1 0 0 0 1-1v-.666a1 1 0 0 0-1-1H10.333Zm0 5.333a1 1 0 0 0-1 1v.667a1 1 0 0 0 1 1h11.334a1 1 0 0 0 1-1V17a1 1 0 0 0-1-1H10.333Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgTodoIcon);
export default Memo;
