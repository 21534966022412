import { Button } from 'UI';
import { CreateReportDtoResourceEnum } from 'api/generated';
import { SpamIcon } from 'assets/icons';
import { ReportModal } from 'components';
import { useAsyncAction, useNotifications } from 'hooks';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useIsLandscapeOrientation } from 'hooks/useIsLandscapeOrientation';
import React, { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { PaginationType } from 'types';

import { CompleteButton, Header, MobileControlBar } from './components';
import { MainContent } from './components';

export type MnemonicCardPracticeFormType = {
  [key: string]: string;
};

const MIN_COUNT_FOR_NEXT_FETCH = 5;

const MnemonicCardPractice = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isChangePosition, setIsChangePosition] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isScale, setIsScale] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [isShowReportModal, setIsShowReportModal] = useState(false);

  const toggleFlipped = () => setIsFlipped((prev) => !prev);

  const startNextSlideAnim = () => {
    setIsShow(false);
    setIsChangePosition(false);
    setActiveIndex((prev) => prev + 1);
  };

  const endNextSlideAnim = () => {
    setIsShow(true);
    setIsDisabledButton(false);
  };

  const startPrevSlideAnim = () => {
    setIsShow(false);
    setIsChangePosition(true);
  };

  const changeSlideContentAnim = () => {
    setActiveIndex((prev) => prev - 1);
    setIsShow(true);
  };

  const endPrevAnim = () => {
    setIsChangePosition(false);
    setIsShow(true);
    setIsDisabledButton(false);
  };

  const goToNextSlide = () => {
    setIsDisabledButton(true);
    setIsScale(false);
    setIsChangePosition(true);
    setTimeout(startNextSlideAnim, 800);
    setTimeout(endNextSlideAnim, 1000);
  };

  const goToPrevSlide = () => {
    setIsDisabledButton(true);
    setIsScale(false);
    setTimeout(startPrevSlideAnim, 800);
    setTimeout(changeSlideContentAnim, 850);
    setTimeout(endPrevAnim, 900);
  };

  const [getMnemonicCardsAction] = useAsyncAction(actions.mnemonicCards.getMnemonicCardsPack);

  const mnemonicCards = useAppSelector(selectors.mnemonicCards.selectMnemonicCardsPack);
  const packName = useAppSelector(selectors.mnemonicCards.selectPackName) || '';

  const mnemonicCardsMeta = useAppSelector(selectors.mnemonicCards.selectMnemonicCardsPackMeta);

  const { id } = useParams();
  const { errorToast } = useNotifications();

  const getMnemonicCards = () => {
    try {
      if (id) {
        getMnemonicCardsAction({ id });
      }
    } catch {
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    getMnemonicCards();
  }, []);

  const isFirstSLide = activeIndex === 0;
  const isLastSlide = activeIndex === (mnemonicCardsMeta?.totalItems || 0) - 1;
  const isNextButtonDisabled = isLastSlide || isDisabledButton;
  const isBackButtonDisabled = isFirstSLide || isDisabledButton;
  const { isLandscapeOrientation } = useIsLandscapeOrientation();
  const { isMobile } = useGetIsMobile();
  const isMobileLandscape = isLandscapeOrientation && isMobile;

  const { colors } = useTheme();

  const nextPage = (mnemonicCardsMeta?.currentPage || 0) + 1;
  const currentPage = mnemonicCardsMeta?.currentPage || 0;
  const totalPages = mnemonicCardsMeta?.totalPages || 0;

  const getPackMnemonicCards = async (actionPayload?: PaginationType) => {
    try {
      if (id) {
        await getMnemonicCardsAction({ id, ...actionPayload });
      }
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const isFetchAfter = mnemonicCards.length - 1 - activeIndex < MIN_COUNT_FOR_NEXT_FETCH && currentPage < totalPages;

  const getAfterPackMnemonicCards = () => {
    if (isFetchAfter) {
      getPackMnemonicCards({ page: nextPage, limit: 20 });
    }
  };

  useEffect(() => {
    getAfterPackMnemonicCards();
  }, [activeIndex, mnemonicCards]);

  if (!mnemonicCards.length) {
    return (
      <LoaderContainer>
        <ThreeDots color={colors.primary[3]} />
      </LoaderContainer>
    );
  }

  return (
    <Container $isMobileLandscape={isMobileLandscape}>
      <Header name={packName} total={mnemonicCardsMeta?.totalItems || 0} activeIndex={activeIndex} />
      <MainContainer $isLastSlide={isLastSlide} $isMobileLandscape={isMobileLandscape}>
        <Cover $isLastSlide={isLastSlide} $isMobileLandscape={isMobileLandscape}>
          {isShow && (
            <Content
              $isHidden={isChangePosition}
              cards={mnemonicCards}
              onNextClick={goToNextSlide}
              onBackClick={goToPrevSlide}
              activeIndex={activeIndex}
              isFlipped={isFlipped}
              onChangeClick={toggleFlipped}
              isScale={isScale}
              setControlState={setIsScale}
              onReportClick={() => setIsShowReportModal(true)}
            />
          )}
        </Cover>
        {isLastSlide && <CompleteButton />}
        <ReportButton>
          <Button
            iconComponent={<SpamIcon width={24} height={24} color={colors.system.red} />}
            variant="secondary"
            size="small"
            onClick={() => setIsShowReportModal(true)}>
            Report a problem
          </Button>
        </ReportButton>
        <ReportModal
          resource={CreateReportDtoResourceEnum.MnemonicCard}
          resourceId={mnemonicCards[activeIndex].id || ''}
          isOpen={isShowReportModal}
          onClose={() => setIsShowReportModal(false)}
        />
        <MobileControlBar
          isFlipped={isFlipped}
          isBackButtonDisabled={isBackButtonDisabled}
          isNextButtonDisabled={isNextButtonDisabled}
          onNextClick={goToNextSlide}
          onPrevClick={goToPrevSlide}
          onShowButtonClick={toggleFlipped}
        />
      </MainContainer>
    </Container>
  );
};

export default MnemonicCardPractice;

export type LayoutStyledProps = {
  $isLastSlide?: boolean;
  $isActive?: boolean;
  $isMobileLandscape?: boolean;
  $isCard?: boolean;
};

const Container = styled.div<LayoutStyledProps>`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 92px 1fr;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
  position: relative;

  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      grid-template-rows: 76px calc(100% - 76px);
      padding-bottom: 0px;
    `}

  ${respondToWidth.sm`
    grid-template-rows: 76px calc(100% - 76px);
    padding-bottom: 0px;
  `}
`;

const MainContainer = styled.div<LayoutStyledProps>`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 16px 20px;
  position: relative;
  overflow: hidden;

  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      padding: 16px 8px 0 12px;
    `}

  ${respondToWidth.sm`
    padding: 16px 8px 0 12px;
  `}
`;

const Content = styled(MainContent)<{ $isHidden: boolean }>`
  margin-left: ${({ $isHidden }) => ($isHidden ? '120%' : '0')};
  transform: ${({ $isHidden }) => ($isHidden ? 'rotate(45deg)' : '0')};
  transition: all 1s ease;

  ${({ $isHidden }) => respondToWidth.s`
    margin-left: ${$isHidden ? '200%' : '0'};
  `}
`;

const Cover = styled.div<LayoutStyledProps>`
  max-width: 880px;
  height: 100%;
  width: 100%;
  max-height: 744px;
  border-radius: 20px;

  ${({ $isMobileLandscape, $isLastSlide }) =>
    $isMobileLandscape &&
    css`
      flex-grow: 1;
      margin-bottom: ${$isLastSlide ? '12px' : '24px'};
      max-height: 100%;
      margin-top: 0;
    `}

  ${({ $isLastSlide }) => respondToWidth.sm`
    flex-grow:1;
    margin-bottom: ${$isLastSlide ? '12px' : '24px'};
    max-height: 100%;
    margin-top: 0;
  `}
`;

const ReportButton = styled.div`
  display: none;
  width: 100%;
  margin-bottom: 36px;
  padding-right: 4px;

  button {
    background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  }

  ${respondToWidth.sm`
    display: flex;
  `}
`;

const LoaderContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
`;
