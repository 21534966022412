import CardWithIcon from 'UI/CardWithIcon/CardWithIcon';
import { TimerFillIcon } from 'assets/icons';
import { useTimer } from 'hooks';
import { FC } from 'react';
import { useTheme } from 'styled-components';

type CardWithTimerProps = {
  willCloseAt?: string;
  cancelTest: () => void;
};

const CardWithTimer: FC<CardWithTimerProps> = ({ willCloseAt, cancelTest }) => {
  const { colors } = useTheme();
  const { hoursString, minutesString, secondsString } = useTimer(willCloseAt || '', cancelTest);
  const timer = `${hoursString}:${minutesString}:${secondsString}`;

  return <CardWithIcon label="Time left" Icon={<TimerFillIcon color={colors.primary[1]} />} text={timer} />;
};

export default CardWithTimer;
