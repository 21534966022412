import { Button, ExpandImage, StyledText } from 'UI';
import { Loader } from 'components';
import { sortBy } from 'lodash';
import React, { ForwardRefRenderFunction, forwardRef, useMemo, useState } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { getTestAnswerLetter } from 'utils';

type ExplanationProps = {
  onNextClick: () => void;
  isLoading?: boolean;
  correctAnswerId?: string;
};

const Explanation: ForwardRefRenderFunction<HTMLDivElement, ExplanationProps> = (
  { onNextClick, isLoading, correctAnswerId },
  ref,
) => {
  const activeIndex = useAppSelector(selectors.tests.selectCurrentQuestionIndex) || 0;
  const tutorTest = useAppSelector(selectors.tests.selectTutorTest);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const isLastQuestion = activeIndex + 1 === tutorTest?.questions.length || 0;
  const buttonText = isLastQuestion ? 'Finish test' : 'Next question';
  const currentQuestion = tutorTest?.questions[activeIndex];

  const correctAnswerLetter = useMemo(() => {
    const correctAnswerIndex = sortBy(currentQuestion?.options, ['orderIndex']).findIndex(
      (option) => option.id === correctAnswerId,
    );

    if (correctAnswerIndex !== -1) {
      return getTestAnswerLetter(correctAnswerIndex);
    }
  }, [currentQuestion, correctAnswerId]);

  return (
    <Container ref={ref}>
      {correctAnswerLetter && <CorrectAnswer as="h3">Correct Answer: {correctAnswerLetter}</CorrectAnswer>}
      {(currentQuestion?.explanationText || currentQuestion?.explanationImageUrl) && (
        <>
          <Title as="h3">Explanation</Title>
          <Wrapper>
            {currentQuestion?.explanationText && (
              <StyledText customStyles={{ whiteSpace: 'pre-wrap' }}>{currentQuestion?.explanationText}</StyledText>
            )}
            {currentQuestion?.explanationImageUrl ? (
              <ImagesContainer>
                <ImageContainer
                  setIsImageLoaded={setIsImageLoaded}
                  image={{
                    url: currentQuestion?.explanationImageUrl || '',
                    alt: currentQuestion?.explanationText || '',
                  }}
                />
                {!isImageLoaded && (
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                )}
              </ImagesContainer>
            ) : null}
          </Wrapper>
        </>
      )}
      <StyledButton variant="primary" size="middle" onClick={onNextClick} isLoading={isLoading} disabled={false}>
        {buttonText}
      </StyledButton>
    </Container>
  );
};

export default forwardRef<HTMLDivElement, ExplanationProps>(Explanation);

const Container = styled.div`
  width: 100%;
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.neutrals[7]};
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  ${respondToWidth.sm`
    padding-block: 20px;  
    margin-top: 20px;
  `}
`;

const Title = styled(StyledText)`
  margin-bottom: 8px;
  ${({ theme: { typography } }) => typography.body_basic_bold_14};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${respondToWidth.sm`
    flex-direction:column-reverse;
    gap:10px;
`}
`;

const CorrectAnswer = styled.div`
  margin-bottom: 20px;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.system.green};
    ${typography.body_basic_bold_14};
  `};
`;

const ImagesContainer = styled.div`
  position: relative;
  width: 100%;
  height: 181px;

  ${respondToWidth.sm`
    height:62px;
    width: 110px;
  `}
`;

const ImageContainer = styled(ExpandImage)`
  width: 100%;
  height: 181px;
  border-radius: 16px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;
  }

  ${respondToWidth.sm`
    height:62px;
    width: 110px;
  `}
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 181px;

  ${respondToWidth.sm`
    height:62px;
  `}
`;

const StyledButton = styled(Button)`
  width: fit-content;
  margin-block: 32px;

  ${respondToWidth.sm`
    display:none;
  `}
`;
