import { ScrollbarContainer } from 'UI';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';

import { Footer, Header } from './components';

type FlashcardsLayoutProps = {
  children?: ReactNode;
  className?: string;
  showModal: () => void;
  showConfirmModal: () => void;
  isStartedTest: boolean;
  onInfoIconClick: () => void;
  onResetClick: () => void;
  onReportClick: () => void;
};

const FlashcardsLayout: FC<FlashcardsLayoutProps> = ({
  children,
  className,
  showModal,
  showConfirmModal,
  isStartedTest,
  onInfoIconClick,
  onResetClick,
  onReportClick,
}) => {
  return (
    <Root>
      <Header isStartedTest={isStartedTest} showConfirmModal={showConfirmModal} onInfoIconClick={onInfoIconClick} />
      <Cover>
        <Main className={className} backgroundType="gray">
          {children}
        </Main>
      </Cover>
      <Footer
        showModal={showModal}
        isStartedTest={isStartedTest}
        showConfirmModal={showConfirmModal}
        onResetClick={onResetClick}
        onReportClick={onReportClick}
      />
    </Root>
  );
};

export default FlashcardsLayout;

const Main = styled(ScrollbarContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 224px);
  overflow-x: hidden;
  overflow-y: hidden;

  ${respondToHeight.xs`
    overflow-y: auto;
  `}

  ${respondToWidth.ls`
    overflow-y: auto;
  `}

  ${respondToWidth.sm`
    height: calc(100vh - 166px - 92px);
    padding-inline:16px 8px;
    margin-top: 20px;
    display: grid;
    place-items: center;
    grid-template-rows: 1fr 36px;
    gap: 16px;
  `};
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-rows: 92px 1fr 92px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};

  ${respondToWidth.sm`
    grid-template-rows: 146px 1fr 88px;
  `}
`;

const Cover = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-right: 8px;

  ${respondToWidth.sm`
    padding-right: 4px;
  `}
`;
