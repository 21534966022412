import { BankCard, Button, StyledText } from 'UI';
import { PaymentsSystemsEnums } from 'constant';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type SelectPaymentModalProps = {
  openChangePaymentMethodModal: () => void;
  paymentSystem: PaymentsSystemsEnums;
  card: string;
  isLoading: boolean;
  payPlan?: () => Promise<void>;
} & BaseModalProps;

const SelectPaymentModal: FC<SelectPaymentModalProps> = ({
  onClose,
  openChangePaymentMethodModal,
  payPlan,
  card,
  isLoading,
  paymentSystem,
  ...props
}) => {
  return (
    <DefaultModal
      onRequestClose={onClose}
      {...props}
      title="Select payment method"
      containerCSS={ContainerCSS}
      changeCloseButton
      modalCSS={ModalCSS}>
      <Button disabled={isLoading} onClick={openChangePaymentMethodModal} size="small" variant="secondary">
        Change payment method
      </Button>
      <Text>Or with bank card</Text>
      <BankCard onClick={isLoading ? undefined : payPlan} card={card} paymentSystem={paymentSystem} />
    </DefaultModal>
  );
};

export default SelectPaymentModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  margin: 32px 0;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${respondToWidth.s`
    margin: 24px 0;
  `}
`;
