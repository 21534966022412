import { EmailConfirmationModal } from 'UI';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { FC } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import { BaseModalProps } from 'types';

const ConfirmEmailModal: FC<BaseModalProps> = ({ ...props }) => {
  const newEmail = useAppSelector(selectors.user.selectNewEmail) || '';
  const { errorToast } = useNotifications();

  const [userChangeEmail, isLoading] = useAsyncAction(actions.user.changeEmail);

  const sendAgain = async () => {
    try {
      await userChangeEmail({ email: newEmail });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  return <EmailConfirmationModal {...props} onSendClick={sendAgain} isLoading={isLoading} email={newEmail} />;
};

export default ConfirmEmailModal;
