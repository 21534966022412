import { Button, NavButton } from 'UI';
import { useGetIsMobile, useIsLandscapeOrientation } from 'hooks';
import { LayoutStyledProps } from 'pages/MnemonicCardPractice/MnemonicCardPractice';
import React, { FC } from 'react';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type MobileControlBarProps = {
  isNextButtonDisabled: boolean;
  isBackButtonDisabled: boolean;
  onShowButtonClick: () => void;
  onNextClick: () => void;
  onPrevClick: () => void;
  isFlipped: boolean;
};

const MobileControlBar: FC<MobileControlBarProps> = ({
  isBackButtonDisabled,
  isFlipped,
  isNextButtonDisabled,
  onNextClick,
  onPrevClick,
  onShowButtonClick,
}) => {
  const { isLandscapeOrientation } = useIsLandscapeOrientation();
  const { isMobile } = useGetIsMobile();
  const isMobileLandscape = isLandscapeOrientation && isMobile;

  return (
    <Container $isMobileLandscape={isMobileLandscape}>
      <NavButton isHideText variant="back" disabled={isBackButtonDisabled} onClick={onPrevClick} />
      <ShowCardButton variant="primary" size="small" onClick={onShowButtonClick}>
        {isFlipped ? 'Show question' : 'Show mnemonic card'}
      </ShowCardButton>
      <NavButton isHideText variant="next" disabled={isNextButtonDisabled} onClick={onNextClick} />
    </Container>
  );
};

export default MobileControlBar;

const Container = styled.div<LayoutStyledProps>`
  display: none;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-top: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 0;
  position: relative;
  height: 68px;
  min-height: 68px;
  padding: 0;
  left: -2px;
  width: calc(100% + 20px);

  ${respondToWidth.sm`
    display: flex;
  `}
`;

const ShowCardButton = styled(Button)`
  width: 200px;
  padding-inline: 0;
`;
