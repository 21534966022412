import { Button } from 'UI';
import StyledText from 'UI/StyledText';
import { ManualTypeEnum } from 'api/generated';
import { Loader } from 'components';
import { useGetManual } from 'hooks';
import { FC } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type FlashcardInitModalProps = {
  startTest?: () => void;
  isDisableStartButton?: boolean;
  isFlashcardsLoading?: boolean;
  isInitModal?: boolean;
};

const FlashcardInitModal: FC<BaseModalProps & FlashcardInitModalProps> = ({
  onClose,
  startTest,
  isDisableStartButton,
  isFlashcardsLoading,
  isInitModal,
  ...props
}) => {
  const { colors } = useTheme();

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const { content, isManualLoading } = useGetManual(ManualTypeEnum.FlashcardDescriptions, activeCourse?.id);

  const onRequestClose = () => {
    if (!isInitModal) {
      onClose();
    }
  };

  return (
    <DefaultModal
      onRequestClose={onRequestClose}
      containerCSS={ContainerCSS}
      titleCSS={TitleCSS}
      title="Flashcards"
      modalCSS={ModalCSS}
      showCloseButton={!isInitModal}
      headerContainerCSS={HeaderCSS}
      {...props}>
      <Wrapper>
        {isManualLoading ? (
          <Loader />
        ) : content ? (
          <>
            <SubTitle>{content.title}</SubTitle>
            <Container>
              <Label $color={colors.system.red}>No</Label>
              <Text>{content.no as string}</Text>
            </Container>
            <Container>
              <Label $color={colors.system.blue}>Kinda</Label>
              <Text>{content.kinda as string}</Text>
            </Container>
            <Container>
              <Label $color={colors.system.green}>Yes</Label>
              <Text>{content.yes as string}</Text>
            </Container>
            <ButtonsContainer>
              {isInitModal && (
                <>
                  <StyledButton
                    isLoading={isFlashcardsLoading}
                    disabled={isDisableStartButton}
                    variant="primary"
                    size="middle"
                    onClick={startTest}>
                    Got it
                  </StyledButton>
                  <StyledButton
                    isLoading={isFlashcardsLoading}
                    disabled={isDisableStartButton}
                    variant="secondary"
                    size="middle"
                    onClick={onClose}>
                    Back
                  </StyledButton>
                </>
              )}
            </ButtonsContainer>
          </>
        ) : (
          <Text>Sorry... An error has occurred, please try again.</Text>
        )}
      </Wrapper>
    </DefaultModal>
  );
};

export default FlashcardInitModal;

const ContainerCSS = css`
  width: 814px;
  padding: 40px 125px 60px;

  ${respondToWidth.ls`
  width:100%;
  padding: 40px 52px 60px;
  `}

  ${respondToWidth.sm`
   padding: 27px 16px;
   overflow: scroll;
   `}

  ${respondToWidth.s`
   border-radius: 20px 20px 0 0;
   `}

  ${respondToHeight.sm`
    padding-block:25px 35px;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.ls`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  `}
`;

const TitleCSS = css`
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.title_4_bold_24};
`;

const HeaderCSS = css`
  ${respondToWidth.s`
   justify-content: center;
   `}
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SubTitle = styled(StyledText)`
  margin-bottom: 40px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  ${({ theme: { typography, colors } }) => respondToWidth.s`
    ${typography.body_basic_medium_14};  
    color: ${colors.neutrals[4]};
    text-align:center;
    margin-top: -4px;
    margin-bottom: 32px;
  `}

  ${respondToHeight.sm`
    margin-bottom: 25px;
  `}
`;

const Text = styled(StyledText)`
  width: 100%;
  white-space: pre-wrap;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.subhead_regular_13};  
  `}
`;

const Label = styled(StyledText)<{ $color: string }>`
  color: ${({ $color }) => $color};
  margin-bottom: 12px;
  ${({ theme: { typography } }) => typography.title_5_bold_20}

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.headline_semibold_18};  
  `}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 90px;
  margin-bottom: 34px;
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 20px;

  &:last-of-type {
    margin-bottom: 60px;
  }

  ${respondToWidth.s`
    padding: 24px;
    margin-bottom: 16px;
  `}

  ${respondToHeight.sm`
      margin-bottom: 20px;
  `}
`;

const StyledButton = styled(Button)`
  width: 144px;
  ${respondToWidth.s`
    width: 100%;
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;

  ${respondToWidth.s`
    margin-top: 28px;
    width:100%;
    gap:15px;
  `}
`;
