import { CaretIcon } from 'assets/icons';
import { useOnClickOutside } from 'hooks';
import { SelectedMenu, TestsListFormType } from 'pages/Performance/components/Header/Header';
import { FC, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { HeaderTags, SearchBar, TestList } from './components';

type DropdownMenuProps = {
  selected: SelectedMenu;
  setSelected: (value: SelectedMenu) => void;
  dateFilters: TestsListFormType | null;
  selectTest?: (id: string) => void;
  onSubmit: (value: TestsListFormType) => void;
  isLoading: boolean;
};

const DropdownMenu: FC<DropdownMenuProps> = ({
  selected,
  selectTest,
  setSelected,
  onSubmit,
  dateFilters,
  isLoading,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [title, setTitle] = useState<string>(SelectedMenu.OVERALL);

  const containerRef = useRef<HTMLDivElement>(null);

  const closeMenu = () => {
    setIsShow(false);
  };

  useOnClickOutside(containerRef, closeMenu);

  const changeTab = (name: SelectedMenu) => {
    if (name === SelectedMenu.TESTS) {
      setTitle('All tests');
    } else {
      setTitle(name);
    }
    setSelected(name);
  };

  const isTests = selected === SelectedMenu.TESTS;

  return (
    <Container ref={containerRef}>
      <Header>
        <Button onClick={() => setIsShow((prev) => !prev)}>
          <TitleWrapper>{title}</TitleWrapper>
          <IconContainer>{isShow ? <CloseIcon size={24} /> : <OpenIcon size={24} />}</IconContainer>
        </Button>
      </Header>
      {isShow && (
        <Wrapper $isHight={isTests}>
          <HeaderTags selected={selected} onSelect={changeTab} />
          {isTests && (
            <>
              <SearchBar onSubmit={onSubmit} />
              <TestList dateFilters={dateFilters} setTitle={setTitle} selectTest={selectTest} isLoading={isLoading} />
            </>
          )}
        </Wrapper>
      )}
    </Container>
  );
};

export default DropdownMenu;

const Container = styled.div`
  width: 100%;
  max-width: 392px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${respondToWidth.sm`
    max-width:100%;
  `}
`;

const Header = styled.div`
  width: 100%;
  display: flex;
`;

const Button = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
  padding: 20px 45px 20px 20px;
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  outline: none;
  border-radius: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => css`
    ${typography.title_5_medium_20}

    ${respondToWidth.sm`
      padding: 8px 20px;
      ${typography.body_large_medium_16};
  `}
  `};
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;

const OpenIcon = styled(CaretIcon)`
  transform: rotate(90deg);
`;

const CloseIcon = styled(CaretIcon)`
  transform: rotate(270deg);
`;

const Wrapper = styled.div<{ $isHight: boolean }>`
  width: 100%;
  height: ${({ $isHight }) => $isHight && 'calc(100vh - 190px)'};
  position: absolute;
  top: 76px;
  right: 50%;
  transform: translateX(50%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);

  ${({ $isHight }) => respondToWidth.sm`
    height: ${$isHight && 'calc(100vh - 196px)'};
    top:52px;
    max-width:100%;
  `}
`;

const TitleWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;
