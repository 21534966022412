import { PasswordInput } from 'UI';
import { ForgottenPasswordDto } from 'api/generated';
import { AxiosError } from 'axios';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications, useQuery } from 'hooks';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import { validations } from 'utils';

import { Header } from './components';
import { Container, Cover, Form, Main, StyledButton, TextContainer, Title, Wrapper } from './styled';

type FormType = {
  confirmPassword: string;
  newPassword: string;
};

const RestorePassword = () => {
  const [isShowError] = useState(false);
  const navigate = useNavigate();
  const { successToast, errorToast } = useNotifications();
  const direction = useAppSelector(selectors.direction.selectDirection);
  const query = useQuery();
  const verificationCode = query.get('verificationCode');
  const customerId = query.get('customerId');
  const [updateForgottenPassword] = useAsyncAction(actions.user.updateForgottenPassword);
  const { control, handleSubmit, getValues } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: { confirmPassword: '', newPassword: '' },
  });

  const validateConfirmPassword = (confirmPassword: string) => {
    const newPassword = getValues('newPassword');

    if (!confirmPassword) {
      return 'Required field';
    }

    if (confirmPassword !== newPassword) {
      return "Passwords don't match";
    }
  };

  const onSubmit = async (dataFields: FormType) => {
    const data: ForgottenPasswordDto = {
      password: dataFields.newPassword,
      directionId: direction?.id || '',
      verificationCode: verificationCode || '',
      customerId: customerId || '',
    };
    try {
      await updateForgottenPassword(data);
      successToast('The password has been successfully changed');
      navigate(routes.signIn);
    } catch (error) {
      errorToast((error as AxiosError).message || 'Something went wrong');
    }
  };

  return (
    <Container>
      <Header />
      <Main>
        <Wrapper>
          <TextContainer>
            <Title $hasError={isShowError}>Create new password</Title>
          </TextContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Cover>
                <Controller
                  control={control}
                  name="newPassword"
                  rules={{
                    required: true,
                    validate: validations.password,
                  }}
                  render={({ field, fieldState }) => (
                    <div style={{ marginBottom: '30px' }}>
                      <PasswordInput
                        iconButtonType="button"
                        value={field.value}
                        onChange={field.onChange}
                        label="New password"
                        error={fieldState.error?.message}
                      />
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="confirmPassword"
                  rules={{
                    required: true,
                    validate: validateConfirmPassword,
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <PasswordInput
                        iconButtonType="button"
                        value={field.value}
                        onChange={field.onChange}
                        label="Confirm new password"
                        error={fieldState.error?.message}
                      />
                    </>
                  )}
                />
              </Cover>
            </div>
            <StyledButton variant="primary" type="submit">
              Confirm password
            </StyledButton>
          </Form>
        </Wrapper>
      </Main>
    </Container>
  );
};

export default RestorePassword;
