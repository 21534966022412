import { useGoogleAnalytics } from 'hooks';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const EventPageViewGA: FC = () => {
  const { pathname } = useLocation();
  const { getEventPageViewGA } = useGoogleAnalytics();

  useEffect(() => {
    getEventPageViewGA({ pathname });
  }, [pathname]);

  return null;
};

export default EventPageViewGA;
