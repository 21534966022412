import { Button, ScrollbarContainer } from 'UI';
import { AxiosError } from 'axios';
import { Loader } from 'components';
import { routes } from 'constant/routes';
import { useAsyncAction, useClientSize, useNotifications, useQuery } from 'hooks';
import React, { FC, ReactNode, UIEventHandler, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import Header from './components/Header';
import TestResultSidebar from './components/TestResultSidebar';

type TestResultLayoutProps = {
  children?: ReactNode;
  isShowCorrect: boolean;
  toggleIsShowCorrect: () => void;
  onAnswerClick: (index: number) => void;
  selectedIndex: number;
  onScroll?: UIEventHandler<HTMLDivElement>;
  isEmpty?: boolean;
};

const TestResultLayout: FC<TestResultLayoutProps> = ({
  children,
  isShowCorrect,
  toggleIsShowCorrect,
  onAnswerClick,
  selectedIndex,
  onScroll,
  isEmpty = false,
}) => {
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const { getIsBreakpoint } = useClientSize();
  const isWidthLs = getIsBreakpoint('ls');
  const navigate = useNavigate();
  const { errorToast } = useNotifications();
  const query = useQuery();
  const chapterIndex = query.get('chapterIndex');
  const quizId = query.get('quizId');

  const { rightAnswered, totalQuestions } = useAppSelector(selectors.tests.selectCurrentTestResultsStatistics) || {};
  const statisticTestQuestion = useAppSelector(selectors.tests.selectCurrentTestStatisticQuestions);
  const testType = useAppSelector(selectors.tests.selectCurrentTestType);
  const timedTestResult = useAppSelector(selectors.tests.selectTestResult);
  const startTutorTestParams = useAppSelector(selectors.tests.selectStartTutorTestParams);
  const [startTutorTestAction, isTutorTestLoading] = useAsyncAction(actions.tests.startTutorTest);
  const [startTimedTestAction, isLoadingTimedTest] = useAsyncAction(actions.tests.startTimedTest);
  const [getQuizTestAction, isLoadingQuizTest] = useAsyncAction(actions.tests.getQuizTest);

  const timedTestId = timedTestResult?.timedTestId;

  const navigateToParentChapter = () => navigate(`${routes.studyGuide}?chapterIndex=${chapterIndex}`);

  const createQuizTest = async () => {
    try {
      if (quizId) {
        await getQuizTestAction(quizId);
        navigate(`${routes.tutorTest}?chapterIndex=${chapterIndex}&quizId=${quizId}`);
      } else {
        navigateToParentChapter();
      }
    } catch (error) {
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    }
  };

  const closeTest = () => navigate(routes.testList);

  const getTimedTest = async () => {
    try {
      if (!timedTestId) {
        throw '';
      }

      await startTimedTestAction({ timedTestId });

      navigate(routes.timedTest);
    } catch {
      errorToast('Something went wrong');
    }
  };

  const tryAgain = async () => {
    if (testType === 'timed_test') {
      await getTimedTest();
    } else {
      if (startTutorTestParams) {
        try {
          await startTutorTestAction(startTutorTestParams);
          navigate(routes.tutorTest);
        } catch {
          closeTest();
        }
      }
    }
  };

  const correctValue = Math.ceil((100 / (totalQuestions || 0)) * (rightAnswered || 0));
  const isLoading = isLoadingTimedTest;

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <Wrapper>
        <Header
          correctPercent={correctValue}
          correctAmount={rightAnswered || null}
          total={totalQuestions || null}
          isShowCorrect={isShowCorrect}
          toggleIsShowCorrect={toggleIsShowCorrect}
          onIconClick={() => setIsShowSidebar(true)}
        />
        <Cover>
          <Main backgroundType="gray" onScroll={onScroll} $isEmpty={isEmpty}>
            {children}
          </Main>
        </Cover>
      </Wrapper>
      {(!isWidthLs || isShowSidebar) && (
        <TestResultSidebar
          questions={statisticTestQuestion || []}
          onHideClick={() => setIsShowSidebar(false)}
          tryAgain={chapterIndex ? createQuizTest : tryAgain}
          closeTest={chapterIndex ? navigateToParentChapter : closeTest}
          onAnswerClick={onAnswerClick}
          selectedIndex={selectedIndex}
          isShowCorrect={isShowCorrect}
          isTryAgainFetching={isLoadingQuizTest || isLoadingTimedTest || isTutorTestLoading}
        />
      )}
      <ButtonsContainer>
        <StyledButton variant="primary" size="middle" onClick={chapterIndex ? navigateToParentChapter : closeTest}>
          Close the test
        </StyledButton>
        <StyledButton
          variant="secondary"
          size="middle"
          onClick={chapterIndex ? createQuizTest : tryAgain}
          isLoading={isLoadingTimedTest || isLoadingQuizTest}>
          Try again
        </StyledButton>
      </ButtonsContainer>
    </Container>
  );
};

export default TestResultLayout;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 362px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
  gap: 24px;
  position: relative;

  ${respondToWidth.ls`
    grid-template-columns: 1fr;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Main = styled(ScrollbarContainer)<{ $isEmpty?: boolean }>`
  ${({ $isEmpty }) => css`
    height: calc(100vh - 148px);
    padding-left: 40px;
    position: relative;
    width: calc(100% + 16px);
    padding-right: 8px;
    padding-bottom: 20px;

    display: ${$isEmpty ? 'flex' : 'block'};
    place-items: ${$isEmpty ? 'center' : 'unset'};
  `}

  ${respondToWidth.xm`
    height: calc(100vh - 184px);
  `}

  ${respondToWidth.ls`
    height: calc(100vh - 144px);
    padding-top:20px;
    padding-right:40px;
  `}

  ${respondToWidth.sl`
    height: calc(100vh - 176px);
  `}

  ${respondToWidth.ls`
    height: calc(100vh - 136px);
    padding: 20px 8px 134px 16px;
    width: calc(100%);
  `}
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: none;
  align-items: center;
  gap: 16px;
  padding: 15px 16px 16px;
  position: absolute;
  bottom: 0;
  ${({ theme: { colors } }) => css`
    border-top: 1px solid ${colors.neutrals[9]};
    background-color: ${colors.neutrals[11]};
  `};

  ${respondToWidth.ls`
    display: flex;
  `}
`;

const StyledButton = styled(Button)`
  padding-inline: 0;
`;

const Cover = styled.div`
  ${respondToWidth.sm`
    padding-right: 4px;
  `}
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
`;
