import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { typography } from 'styles/general/typography';
import { VariantTypography } from 'types';

export type AppTextProps = {
  font?: VariantTypography;
  children?: React.ReactNode;
  customStyles?: CSSProp;
  as?: React.ElementType;
  className?: string;
  htmlFor?: string;
  unselectable?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const StyledText = (props: AppTextProps) => {
  const {
    font = typography.body_basic_regular_14,
    className,
    customStyles,
    children,
    as,
    htmlFor,
    unselectable = true,
    onClick,
  } = props;

  return (
    <Text
      className={className}
      $CSS={customStyles}
      $font={font}
      $unselectable={unselectable}
      as={as}
      htmlFor={htmlFor}
      onClick={onClick}>
      {children}
    </Text>
  );
};

export default StyledText;

type TextProps = {
  $font: VariantTypography;
  $CSS?: CSSProp;
  $unselectable: boolean;
};

const Text = styled.span<TextProps>`
  margin: 0;
  padding: 0;
  display: block;
  user-select: ${({ $unselectable }) => ($unselectable ? 'none' : 'auto')};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};

  ${({ theme: { typography }, $font }) => typography[$font]};
  ${({ $CSS }) => $CSS};
`;
