import { monthsAdapter, monthsAdapterRevert } from 'constant';
import { useState } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { dayjs } from 'services';

const firstMonthIndex = 0;
const lastMonthIndex = 11;

type UseCustomHeaderProps = {
  years: string[];
} & ReactDatePickerCustomHeaderProps;

export const useCustomHeader = (props: UseCustomHeaderProps) => {
  const currentYear = dayjs(props.date).year();
  const currentMonth = dayjs(props.date).month();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(String(currentYear));
  const months = Object.values(monthsAdapter);

  const handleMonthChange = (month: string) => {
    setSelectedMonth(monthsAdapterRevert[month]);
    props.changeMonth(monthsAdapterRevert[month]);
  };

  const handleYearChange = (year: string) => {
    setSelectedYear(year);
    props.changeYear(Number(year));
  };

  const handleNextMonth = () => {
    /**
     * if the user is switching the month and the year should appear that is not in the years prop, then this will not happen
     */
    if (selectedMonth === lastMonthIndex && !props.years?.includes(String(Number(selectedYear) + 1))) {
      return;
    } else {
      props.increaseMonth();
      setSelectedMonth((prevMonth) => {
        if (prevMonth === lastMonthIndex) {
          setSelectedYear((prevYear) => String(Number(prevYear) + 1));

          return firstMonthIndex;
        } else {
          return prevMonth + 1;
        }
      });
    }
  };

  const handlePrevMonth = () => {
    /**
     * if the user is switching the month and the year should appear that is not in the years prop, then this will not happen
     */
    if (selectedMonth === firstMonthIndex && !props.years?.includes(String(Number(selectedYear) - 1))) {
      return;
    } else {
      props.decreaseMonth();
      setSelectedMonth((prevMonth) => {
        if (prevMonth === firstMonthIndex) {
          setSelectedYear((prevYear) => String(Number(prevYear) - 1));

          return lastMonthIndex;
        } else {
          return prevMonth - 1;
        }
      });
    }
  };

  return {
    selectedMonth,
    selectedYear,
    months,
    handleMonthChange,
    handleYearChange,
    handleNextMonth,
    handlePrevMonth,
  };
};
