import StyledText from 'UI/StyledText';
import { useGetProgressColor } from 'hooks';
import React, { FC, memo } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { getPoints } from 'utils';

type ProgressPointBarProps = {
  total: number;
  points: number;
  className?: string;
  onClick?: () => void;
};

const ProgressPointBar: FC<ProgressPointBarProps> = ({ total, points, className, onClick }) => {
  const { colors } = useTheme();

  const value = (points / total) * 100;
  const color = useGetProgressColor(value);

  return (
    <Container className={className} onClick={onClick}>
      <Wrapper>
        <ProgressContainer>
          <CircularProgressbar
            value={value}
            circleRatio={0.44}
            strokeWidth={12}
            styles={buildStyles({
              trailColor: colors.neutrals[9],
              strokeLinecap: 'round',
              pathColor: color,
              rotation: 0.775,
              pathTransitionDuration: 0.8,
            })}></CircularProgressbar>
        </ProgressContainer>
        <Count $color={color}>{getPoints(Math.round(points))}</Count>
        <Text $color={color}>Points</Text>
      </Wrapper>
      <Label>Progress</Label>
      <ProgressBarContainer>
        <ProgressIndicator $value={value} $color={color} />
      </ProgressBarContainer>
    </Container>
  );
};

export default memo(ProgressPointBar);

type IndicatorProps = {
  $value: number;
  $color: string;
};

const Wrapper = styled.div`
  width: 100px;
  height: fit-content;
  padding: 6px 4px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  ${respondToWidth.sm`
    width:100%;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    margin-bottom: 8px;
    padding:0;
    border-radius: 0px;
  `}
`;

const Container = styled.div`
  position: relative;
  ${({ theme: { colors } }) => respondToWidth.sm`
  width: 100%;
  padding: 16px;
  border: 1px solid ${colors.neutrals[9]};
  border-radius: 12px;
`}
`;

const ProgressContainer = styled.div`
  width: 92px;
  height: 50px;
  margin-bottom: 10px;

  ${respondToWidth.sm`
    display: none;
  `}
`;

const Count = styled(StyledText)<{ $color: string }>`
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  color: ${({ $color }) => $color};
  ${({ theme: { typography } }) => typography.body_large_bold_16};

  ${({ theme: { typography } }) => respondToWidth.sm`
    position: initial;
    transform: translateX(0);
    margin-right: 8px;
    ${typography.title_5_bold_20};
  `}
`;

const Text = styled(StyledText)<{ $color: string }>`
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.subhead_semibold_13};

  ${({ theme: { typography }, $color }) => respondToWidth.sm`
    ${typography.body_basic_semibold_14};
    margin-bottom:1px;
    color: ${$color};
  `}
`;

const Label = styled(StyledText)`
  display: none;
  position: absolute;
  bottom: 38px;
  right: 16px;
  color: ${({ theme: { colors } }) => colors.neutrals[6]};
  ${({ theme: { typography } }) => typography.subhead_semibold_13};
  ${respondToWidth.sm`
    display:block;
  `}
`;

const ProgressBarContainer = styled.div`
  display: none;
  width: 100%;
  height: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 6px;
  overflow: hidden;

  ${respondToWidth.sm`
    display:block;
  `}
`;

const ProgressIndicator = styled.div<IndicatorProps>`
  width: ${({ $value }) => `${$value}%`};
  transition: width 0.8s linear;
  height: 100%;
  border-radius: 6px;
  background-color: ${({ $color }) => $color};
`;
