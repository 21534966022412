import { StyledText } from 'UI';
import React, { FC } from 'react';
import styled, { CSSProp, css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { StatisticColumnType } from 'types';
import { getUpFirstLetter } from 'utils/textHelpers';

export type LegendItemProps = {
  name: StatisticColumnType;
  percent: number;
  amount: number;
};

const LegendItem: FC<LegendItemProps> = ({ name, percent, amount }) => {
  const { colors } = useTheme();

  const colorMapping = {
    [StatisticColumnType.CORRECT]: css`
      background-color: ${colors.system.green};
      border-color: ${colors.system.lightGreen};
    `,
    [StatisticColumnType.INCORRECT]: css`
      background-color: ${colors.system.red};
      border-color: ${colors.system.lightRed};
    `,
    [StatisticColumnType.UNUSED]: css`
      background-color: ${colors.neutrals[2]};
      border-color: ${colors.neutrals[10]};
    `,
    [StatisticColumnType.YES]: css`
      background-color: ${colors.system.green};
      border-color: ${colors.system.lightGreen};
    `,
    [StatisticColumnType.NO]: css`
      background-color: ${colors.system.red};
      border-color: ${colors.system.lightRed};
    `,
    [StatisticColumnType.KINDA]: css`
      background-color: ${colors.system.blue};
      border-color: ${colors.system.lightBlue};
    `,
  };

  const label = getUpFirstLetter(name);
  const roundedPercent = parseFloat(percent.toFixed(1));

  return (
    <Container>
      <Wrapper>
        <Circle $color={colorMapping[name]} />
        <Name>{label}</Name>
      </Wrapper>
      <Wrapper>
        <Total>Total:</Total>
        <Cover>
          <Percent>{roundedPercent}%</Percent>
          <Amount>{amount}</Amount>
        </Cover>
      </Wrapper>
    </Container>
  );
};

export default LegendItem;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;

  ${respondToWidth.sm`
    width:100%;
    justify-content: space-between;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Circle = styled.div<{ $color: CSSProp }>`
  width: 20px;
  height: 20px;
  border: 6px solid;
  border-radius: 50%;
  ${({ $color }) => $color};
`;

const Name = styled(StyledText)`
  ${({ theme: { typography } }) => typography.subhead_medium_13};
`;

const Total = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

const Percent = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_extrabold_14};
`;

const Amount = styled(Total)`
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
`;

const Cover = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;
