import StyledText from 'UI/StyledText';
import React, { FC } from 'react';
import styled from 'styled-components';

type ChartLegendItemProps = {
  color: string;
  title: string;
  percent: number;
  amount: number;
  className?: string;
};

const ChartLegendItem: FC<ChartLegendItemProps> = ({ color, title, percent, amount, className }) => {
  const roundedPercent = parseFloat(percent.toFixed(1));

  return (
    <Container className={className}>
      <Line $color={color} />
      <div>
        <Title>{title}</Title>
        <Wrapper>
          <Percent>{roundedPercent}%</Percent>
          <Amount>{Math.round(amount)}</Amount>
        </Wrapper>
      </div>
    </Container>
  );
};

export default ChartLegendItem;

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: start;
`;

const Line = styled.div<{ $color: string }>`
  width: 8px;
  border-radius: 4px;
  background-color: ${({ $color }) => $color};
  margin-right: 12px;
`;

const Title = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[5]};
  margin-bottom: 8px;
  ${({ theme: { typography } }) => typography.subhead_medium_13};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Percent = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_extrabold_14};
  margin-right: 8px;
`;

const Amount = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;
