import { SecondButton } from 'UI';
import { SecondButtonVariant } from 'UI/SecondButton/SecondButton';
import { FC } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';

import { Option } from './components';

export type SettingOption = {
  title: string;
  isEnabled: boolean;
  action: any;
};

type SettingButtonsListProps = {
  isResetCourseResultsLoading: boolean;
  openConfirmResetCourseResultModalOpen: () => void;
};

const SettingButtonsList: FC<SettingButtonsListProps> = ({
  openConfirmResetCourseResultModalOpen,
  isResetCourseResultsLoading,
}) => {
  const showStreaks = useAppSelector(selectors.settings.showStreaks);
  const turnOnSoundsInApp = useAppSelector(selectors.settings.turnOnSoundsInApp);
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  const customerSettingOptions: SettingOption[] = [
    {
      title: 'Show points pop-ups',
      isEnabled: showStreaks,
      action: actions.settings.toggleCustomerStreak,
    },
    {
      title: 'In-app sounds',
      isEnabled: turnOnSoundsInApp,
      action: actions.settings.toggleCustomerSound,
    },
    {
      title: 'Dark mode',
      isEnabled: isDarkMode,
      action: actions.settings.toggleDarkMode,
    },
  ];

  return (
    <Wrapper>
      {customerSettingOptions.map((option) => (
        <Option {...option} key={option.title} />
      ))}
      <StyledSecondButton
        onClick={openConfirmResetCourseResultModalOpen}
        disabled={isResetCourseResultsLoading}
        variant={SecondButtonVariant.SECONDARY}
        text="Reset current course results"
      />
    </Wrapper>
  );
};

export default SettingButtonsList;

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    border-radius: 12px;
    background-color: ${colors.neutrals[11]};
  `};
`;

const StyledSecondButton = styled(SecondButton)`
  border-top-left-radius: unset;
  border-top-right-radius: unset;
`;
