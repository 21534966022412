import StyledText from 'UI/StyledText';
import styled, { css } from 'styled-components';

import { CheckboxVariant } from './Checkbox';

type StyledCheckBoxProps = {
  $checked: boolean;
  $isLoading: boolean;
  $isDarkMode?: boolean;
  $isDisabled?: boolean;
  $variant?: CheckboxVariant;
};

export const Wrapper = styled.label`
  display: flex;
`;

export const InputContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckBox = styled.div<StyledCheckBoxProps>`
  ${({ theme: { colors }, $isDisabled, $isLoading, $variant, $isDarkMode }) => css`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: ${$isDisabled ? 'not-allowed' : $isLoading ? 'wait' : 'pointer'};

    background: ${() => {
      if ($variant === CheckboxVariant.white) {
        const defaultColor = $isDisabled ? colors.neutrals[10] : colors.neutrals[11];
        const darkModeColor = $isDisabled ? colors.neutrals[6] : colors.neutrals[9];

        return $isDarkMode ? darkModeColor : defaultColor;
      } else {
        const defaultColor = $isDisabled ? colors.neutrals[9] : colors.neutrals[10];
        const darkModeColor = $isDisabled ? colors.neutrals[6] : colors.neutrals[9];

        return $isDarkMode ? darkModeColor : defaultColor;
      }
    }};
  `};
`;

type LabelInputProps = {
  $isDisabled?: boolean;
  $isLoading: boolean;
  $checked: boolean;
};

export const LabelInput = styled(StyledText)<LabelInputProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme: { colors }, $isDisabled, $checked }) =>
    $isDisabled && !$checked ? colors.neutrals[6] : colors.neutrals[1]};
  cursor: ${({ $isDisabled, $isLoading }) => ($isDisabled ? 'not-allowed' : $isLoading ? 'wait' : 'pointer')};
  user-select: none;
`;
