import { StyledText } from 'UI';
import { useOnClickOutside } from 'hooks';
import React, { FC, useRef } from 'react';
import { selectors, useAppSelector } from 'store';
import styled from 'styled-components';

import { TestMenuButton } from '../../TestFooter';
import AddToFlashcardItem from './components/AddToFlashcardItem';
import RecheckItem from './components/RecheckItem';

type MobileMenuProps = {
  endTest: () => void;
  iconMapping: Record<TestMenuButton, React.ReactNode>;
  closeMenu: () => void;
  isMarkedInitialValue: boolean;
  isAddedToFlashcardInitialValue: boolean;
  toggleHint?: () => void;
};

const MobileMenu: FC<MobileMenuProps> = ({
  endTest,
  iconMapping,
  closeMenu,
  isAddedToFlashcardInitialValue,
  isMarkedInitialValue,
  toggleHint,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tutorTest = useAppSelector(selectors.tests.selectTutorTest);
  const timedTest = useAppSelector(selectors.tests.selectTimedTest);
  const currentIndex = useAppSelector(selectors.tests.selectCurrentQuestionIndex) || 0;

  const questionId = tutorTest?.questions[currentIndex].id || timedTest?.questions[currentIndex].id || '';

  useOnClickOutside(containerRef, closeMenu);

  return (
    <Container ref={containerRef}>
      <RecheckItem iconMapping={iconMapping} isMarkedInitialValue={isMarkedInitialValue} questionId={questionId} />
      <AddToFlashcardItem
        iconMapping={iconMapping}
        isAddedToFlashcardInitialValue={isAddedToFlashcardInitialValue}
        questionId={questionId}
      />
      <MenuItem onClick={endTest}>
        {iconMapping[TestMenuButton.END]}
        <TextMenu>{TestMenuButton.END}</TextMenu>
      </MenuItem>
      {Boolean(toggleHint) && (
        <MenuItem onClick={toggleHint}>
          {iconMapping[TestMenuButton.HINT]}
          <TextMenu>{TestMenuButton.HINT}</TextMenu>
        </MenuItem>
      )}
    </Container>
  );
};

export default MobileMenu;

const Container = styled.div`
  width: 246px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  position: absolute;
  padding: 8px 20px;
  right: 16px;
  top: -18px;
  transform: translateY(-100%);
  border-radius: 12px;
`;

const MenuItem = styled.button`
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: start;
  padding-block: 12px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};

  &:last-of-type {
    border-bottom: transparent;
  }
`;

const TextMenu = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_bold_14}
`;
