import { ChangeEmailModal, ChangeFullNameModal, ConfirmEmailModal, ForgotPasswordModal } from 'components';
import { ChangePasswordModal, CreatePasswordModal } from 'components';
import { useToggle } from 'hooks';
import React, { useMemo, useState } from 'react';
import { selectors, useAppSelector } from 'store';

import InfoCard from '../InfoCard';
import { FieldType } from '../InfoCard/InfoCard';

const AccountInfoCard = () => {
  const [isChangeNameModalOpen, setIsChangeNameModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false);
  const [isEmailConfirmModalOpen, setIsEmailConfirmModalOpen] = useState(false);

  const {
    isOpen: isCreatePasswordModalOpen,
    close: closeCreatePasswordModal,
    open: openCreatePasswordModal,
  } = useToggle();

  const user = useAppSelector(selectors.user.selectUser);

  const fields: Array<FieldType> = useMemo(
    () => [
      {
        label: 'Full name',
        value: user?.name || 'No name',
        openEditModal: () => setIsChangeNameModalOpen(true),
      },
      {
        label: 'Email',
        value: user?.email || '',
        openEditModal: () => setIsChangeEmailModalOpen(true),
      },
      {
        label: 'Password',
        value: '************',
        openEditModal: () => setIsChangePasswordModalOpen(true),
      },
    ],
    [user],
  );

  const backFromForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(false);
    setIsChangePasswordModalOpen(true);
  };

  const backFromCreatePasswordModal = () => {
    setIsForgotPasswordModalOpen(true);
    closeCreatePasswordModal();
  };

  const showConfirmModal = () => {
    setIsChangeEmailModalOpen(false);
    setIsEmailConfirmModalOpen(true);
  };

  return (
    <>
      <InfoCard fields={fields} title="Account" isShowEditIcon />
      <ChangeFullNameModal
        name={user?.name}
        isOpen={isChangeNameModalOpen}
        onClose={() => setIsChangeNameModalOpen(false)}
      />
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
        openForgotPasswordModal={() => setIsForgotPasswordModalOpen(true)}
      />
      <ForgotPasswordModal
        isOpen={isForgotPasswordModalOpen}
        onClose={() => setIsForgotPasswordModalOpen(false)}
        goBack={backFromForgotPasswordModal}
        openCreatePasswordModal={openCreatePasswordModal}
      />
      <CreatePasswordModal
        isOpen={isCreatePasswordModalOpen}
        onClose={closeCreatePasswordModal}
        onBackIconClick={backFromCreatePasswordModal}
      />
      <ChangeEmailModal
        isOpen={isChangeEmailModalOpen}
        onClose={() => setIsChangeEmailModalOpen(false)}
        showConfirmModal={showConfirmModal}
      />
      <ConfirmEmailModal isOpen={isEmailConfirmModalOpen} onClose={() => setIsEmailConfirmModalOpen(false)} />
    </>
  );
};

export default AccountInfoCard;
