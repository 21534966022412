import { ErrorMessage } from '@hookform/error-message';
import { Button, StyledText } from 'UI';
import { ArrowDown } from 'assets/icons';
import { accessLevels } from 'constant';
import { useClientSize } from 'hooks';
import { TestFormType } from 'pages/TestList/TestList';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { TestListTabs } from 'types';

type HeaderProps = {
  isShowContainer: boolean;
  onIconClick: () => void;
  openUpgradeSubscriptionModal: () => void;
};

const Header: FC<HeaderProps> = ({ isShowContainer, onIconClick, openUpgradeSubscriptionModal }) => {
  const { getIsBreakpoint } = useClientSize();
  const isWidthSm = getIsBreakpoint('sm');

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const activeTab = useAppSelector(selectors.tests.selectActiveTab);

  const field = activeTab === TestListTabs.DOMAINS ? 'domainsQuestions' : 'chaptersQuestions';

  const { hasAllQuestionsInTest } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;
  const title = hasAllQuestionsInTest ? 'Subjects' : 'Subjects. Demo mode';

  const {
    formState: { errors },
  } = useFormContext<TestFormType>();

  return (
    <Container $isShowBorder={!isShowContainer}>
      <InnerContainer>
        <TitleContainer>
          <Title>{title}</Title>
          <ErrorMessage errors={errors} name={field} render={({ message }) => <Error>{message}</Error>} />
        </TitleContainer>
        <ButtonsContainer>
          {!hasAllQuestionsInTest && (
            <Button size="small" onClick={openUpgradeSubscriptionModal} type="button">
              Upgrade
            </Button>
          )}
        </ButtonsContainer>
      </InnerContainer>
      {isWidthSm && <StyledArrowDown $isShowContainer={!isShowContainer} onClick={onIconClick} />}
    </Container>
  );
};

export default Header;

const Container = styled.div<{ $isShowBorder: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-inline: 24px;

  ${({ theme: { colors }, $isShowBorder }) => respondToWidth.sm` 
    padding-bottom: 4px;
    padding-inline: 16px 12px;
    ${$isShowBorder && `border-bottom:1px solid ${colors.neutrals[9]};`}
  `}
`;

const Error = styled(StyledText)`
  white-space: nowrap;
  margin-bottom: 4px;

  ${({ theme: { typography, colors } }) => css`
    color: ${colors.system.red};
    ${typography.body_basic_semibold_14};
  `};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 16px;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  ${respondToWidth.sm`
    width: auto;
    display: block;
  `}
`;

const StyledArrowDown = styled(ArrowDown)<{ $isShowContainer: boolean }>`
  transition: all 0.2s linear;
  transform: rotate(${({ $isShowContainer }) => ($isShowContainer ? 180 : 0)}deg);
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_4_bold_24}
    ${respondToWidth.sm`
    ${typography.title_5_bold_20};
  `}
  `};
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;

  margin-right: -2px;
  margin-left: 2px;

  ${respondToWidth.sm`
      display: none;
  `}

  button {
    width: fit-content;
    white-space: nowrap;
  }
`;
