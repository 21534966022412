import React, { FC } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';

type ListRenderItemProps = {
  value: string;
  isActive: boolean;
  onItemClick: (value: string) => void;
};

const ListRenderItem: FC<ListRenderItemProps> = ({ value, isActive, onItemClick }) => {
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  return (
    <Container $isActive={isActive} onClick={() => onItemClick(value)} $isDarkMode={isDarkMode}>
      {value}
    </Container>
  );
};

export default ListRenderItem;

const Container = styled.div<{ $isActive: boolean; $isDarkMode: boolean }>`
  ${({ theme: { colors, typography }, $isActive, $isDarkMode }) => css`
    position: relative;
    min-width: 85px;
    padding: 8px 16px;
    margin-right: -8px;
    cursor: pointer;
    color: ${colors.neutrals[1]};
    background-color: ${$isActive ? colors.primary[5] : colors.neutrals[11]};
    border-bottom: 1px solid ${$isDarkMode ? colors.neutrals[9] : colors.neutrals[10]};

    ${typography.body_basic_regular_14};

    :hover {
      color: ${colors.neutrals[1]};
      background-color: ${$isActive ? colors.primary[5] : colors.neutrals[8]};
    }
  `}
`;
