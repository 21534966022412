import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgSpamIcon: FC<SVGIconProps> = ({ color: propsColor, size = 20, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.583 2.083 19.167 10l-4.584 7.917H5.417L.833 10l4.584-7.917h9.166Zm-.96 1.667H6.377L2.76 10l3.618 6.25h7.246L17.24 10l-3.618-6.25ZM9.166 12.5h1.666v1.667H9.167V12.5Zm0-6.667h1.666v5H9.167v-5Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgSpamIcon);
export default Memo;
