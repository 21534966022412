import { Button } from 'UI';
import StyledText from 'UI/StyledText';
import loseAudio from 'assets/audio/loseAudio.mp3';
import triumphAudio from 'assets/audio/triumphAudio.mp3';
import earnedPoint from 'assets/json/earnedPoint.json';
import { useSoundController } from 'hooks';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { CSSProp, css, keyframes } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';
import { StreakContent } from './components';

export enum Steps {
  fisrt,
  second,
}

type PointsResultModalProps = {
  isEarned: boolean;
  points: number;
  titlePoints?: number;
  isMaxPoints?: boolean;
  title: string;
  subtitle?: string;
  onLinkClick?: () => void;
  titleContainerCSS?: CSSProp;
  wrapperCSS?: CSSProp;
} & BaseModalProps;

const PointsResultModal: FC<PointsResultModalProps> = ({
  isEarned,
  isOpen,
  onClose,
  points,
  titlePoints,
  isMaxPoints,
  title,
  subtitle,
  onLinkClick,
  titleContainerCSS,
  wrapperCSS,
}) => {
  const [step, setStep] = useState<Steps>(Steps.fisrt);
  const [count, setCount] = useState(0);
  const { playSound } = useSoundController(isEarned ? triumphAudio : loseAudio);
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const isNullPoints = points === 0;
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const isFirstStep = step === Steps.fisrt;

  const handleClose = () => {
    onClose();
  };

  const handleChangeStep = (stepValue: Steps) => {
    setStep(stepValue);
  };

  const buttonTitles = useMemo(
    () => ({
      [Steps.fisrt]: {
        secondary: 'Skip',
        primary: 'Next',
      },
      [Steps.second]: {
        secondary: 'Back',
        primary: 'Ok',
      },
    }),
    [step],
  );

  const buttonsClick = {
    [Steps.fisrt]: {
      secondary: handleClose,
      primary: () => handleChangeStep(Steps.second),
    },
    [Steps.second]: {
      secondary: () => handleChangeStep(Steps.fisrt),
      primary: handleClose,
    },
  };

  const animateCount = () => {
    if (count >= points) {
      setCount(points);
    }
    if (isOpen && count < points) {
      setTimeout(() => {
        setCount((prev) => prev + points / 60);
      }, 1000 / 60);
    }
    if (!isOpen) {
      setCount(0);
    }
  };

  useEffect(animateCount, [count, isOpen, points]);

  useEffect(() => {
    lottieRef.current?.goToAndPlay(0);

    setTimeout(() => {
      !isNullPoints && isOpen && playSound();
    }, 500);
  }, [isOpen]);

  return (
    <DefaultModal
      isOpen={isOpen}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      showCloseButton={false}
      modalCSS={ModalCSS}
      withTitleMargin={false}>
      {isFirstStep && (
        <Wrapper $isEarned={isEarned} $CSS={wrapperCSS}>
          {isEarned && (
            <AnimationContainer>
              <StyledLottie animationData={earnedPoint} lottieRef={lottieRef} loop={false} />
            </AnimationContainer>
          )}

          <TitleContainer $CSS={titleContainerCSS}>
            {isNullPoints ? (
              <Title>You have not {'\n'} earned any points</Title>
            ) : isMaxPoints ? (
              <Title $isEarned={isEarned} $isDarkMode={isDarkMode}>
                {title}
                <EarnedPoints>{` ${titlePoints} `}</EarnedPoints>
                {subtitle}
              </Title>
            ) : (
              <>
                <Title $isEarned={isEarned} $isDarkMode={isDarkMode}>
                  {title}
                </Title>
                {subtitle && <Subtitle $isEarned={isEarned}>{subtitle}</Subtitle>}
              </>
            )}
          </TitleContainer>

          <TextContainer $isMaxPoint={isMaxPoints}>
            <Points $isEarned={isNullPoints ? undefined : isEarned}>{Math.round(count)}</Points>
            <Sub>Points</Sub>
          </TextContainer>
        </Wrapper>
      )}

      {isFirstStep && (
        <Text>
          Check out the{' '}
          <LinkButton type="button" onClick={onLinkClick}>
            performance page
          </LinkButton>{' '}
          to see areas for improvement
        </Text>
      )}

      {!isFirstStep && <StreakContent wrapperCSS={wrapperCSS} />}

      <ButtonsWrapper>
        <StyledButton variant="secondary" size="middle" onClick={buttonsClick[step].secondary}>
          {buttonTitles[step].secondary}
        </StyledButton>
        <StyledButton variant="primary" size="middle" onClick={buttonsClick[step].primary}>
          {buttonTitles[step].primary}
        </StyledButton>
      </ButtonsWrapper>
    </DefaultModal>
  );
};

export default memo(PointsResultModal);

const appearance = (color: string, secondaryColor: string) => keyframes`
  0% {
    transform: translateX(20%) rotate(10deg) scale(0.8);
    opacity: 0.3;
    color: ${color};
  }
  25% {
    transform: translateX(0) rotate(0deg) scale(1.1);
    opacity: 1;
    color: ${color};
  }

  50% {
    transform: translateX(0) rotate(0deg) scale(1.1);
    opacity: 1;
    color: ${color};
  }
  75% {
    transform: translateX(0) rotate(0deg) scale(1.1);
    opacity: 1;
    color: ${secondaryColor};
  }
  100% {
    transform: scale(1);

  }
`;

const appearanceLow = (color: string, secondaryColor: string) => keyframes`
  0% {
    transform: translateX(20%) rotate(10deg) scale(0.8);
    opacity: 0;
    color: ${color};
  }
  25% {
    opacity: 0;
  }
  45% {
    opacity: 0.6;
  }
  50% {
    transform: translateX(0) rotate(0deg) scale(1.1);
    opacity: 1;
    color: ${color};
  }
  75% {
    transform: translateX(0) rotate(0deg) scale(1.1);
    opacity: 1;
    color: ${secondaryColor};
  }
  100% {
    transform: scale(1);
  }
`;

const ContainerCSS = css`
  width: 614px;
  height: 493px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px 40px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:536px;
    padding:28px 10px; 
    justify-content: space-between;
    border-radius: 20px 20px 0 0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform:translateX(-50%);
  `}
`;

const Wrapper = styled.div<{ $isEarned: boolean; $CSS?: CSSProp }>`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 460px;
  max-height: 329px;
  ${({ $CSS }) => $CSS};
`;

const AnimationContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;

  ${respondToWidth.s`
    top:14%;
  `}
`;

const TitleContainer = styled.div<{ $CSS?: CSSProp }>`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 30%;

  ${respondToWidth.s`
    top:0;
  `}
  ${({ $CSS }) => $CSS};
`;

const Title = styled(StyledText)<{ $isEarned?: boolean; $isDarkMode?: boolean }>`
  text-align: center;
  white-space: pre-wrap;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.title_3_bold_28};
  animation: ${({ theme: { colors }, $isEarned }) =>
      typeof $isEarned === 'boolean'
        ? appearance($isEarned ? colors.system.green : colors.system.red, colors.neutrals[1])
        : appearance(colors.neutrals[1], colors.neutrals[1])}
    2s linear;
  text-shadow: ${({ theme: { colors }, $isDarkMode }) => $isDarkMode && `${colors.neutrals[10]} 1px 0 5px`};
`;

const Subtitle = styled(Title)<{ $isEarned: boolean }>`
  animation: ${({ theme: { colors }, $isEarned }) =>
      appearanceLow($isEarned ? colors.system.green : colors.system.red, colors.neutrals[1])}
    2s linear;
`;

const EarnedPoints = styled.span`
  color: ${({ theme: { colors } }) => colors.system.green};
  ${({ theme: { typography } }) => typography.title_3_bold_28};
`;

const Points = styled(StyledText)<{ $isEarned?: boolean }>`
  color: ${({ theme: { colors }, $isEarned }) =>
    typeof $isEarned === 'boolean' ? ($isEarned ? colors.system.green : colors.system.red) : colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.title_1_bold_70};

  ${respondToWidth.s`
    bottom:30px;
  `}
`;

const Sub = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.headline_semibold_18};
`;

const TextContainer = styled.div<{ $isMaxPoint?: boolean }>`
  position: absolute;
  top: ${({ $isMaxPoint }) => ($isMaxPoint ? '57%' : '52%')};
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled(StyledText)`
  margin-top: 24px;
  z-index: 2;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[5]};
  ${({ theme: { typography } }) => typography.footnote_semibold_12};

  ${respondToWidth.s`
    margin-top:32px;
    max-width:190px;
  `}
`;

const LinkButton = styled.button`
  display: inline;
  cursor: pointer;
  padding: 0;
  margin: 0 2px;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  ${({ theme: { typography } }) => typography.link_semibold_12};

  ${respondToWidth.s`
    margin-right:0px;
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 32px;
  width: 144px;

  ${respondToWidth.s`
    width: 164px;
  `}
`;

const StyledLottie = styled(Lottie)`
  height: 107%;
  width: 120%;

  svg {
    height: 110% !important;

    ${respondToWidth.sm`
      height:100% !important;
    `}
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 9px;
`;
