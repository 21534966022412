import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

export const useGetProgressColor = (value: number) => {
  const { colors } = useTheme();
  const [color, setColor] = useState('');

  const getColor = (value: number) => {
    if (value < 30) {
      setColor(colors.system.red);
    }
    if (value >= 30 && value < 80) {
      setColor(colors.system.yellow);
    }
    if (value >= 80) {
      setColor(colors.system.green);
    }
  };

  useEffect(() => getColor(value), [value]);

  return color;
};
