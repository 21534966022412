import { ConfirmModal } from 'UI';
import { PauseModal, SuccessfulUnsubscribeModal } from 'components/Modals';
import React, { FC, useState } from 'react';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';

import { useStepsContent } from './lib/use-steps-content';

interface UnsubscribeProps {
  isOpen: boolean;

  onClose: () => void;
  onUnsubscribe: () => void;
  isLoading: boolean;
  courseId: string | undefined;
  closeChooseProductModal: () => void;
}

const Unsubscribe: FC<UnsubscribeProps> = ({
  isOpen,
  onClose,
  onUnsubscribe,
  isLoading,
  courseId,
  closeChooseProductModal,
}) => {
  const dispatch = useAppDispatch();
  const isShowSuccessfulUnsubscribeModal = useAppSelector(selectors.courses.selectIsSuccessfulUnsubscribe);
  const [isPause, setIsPause] = useState(false);
  const stepContent = useStepsContent(onClose, onUnsubscribe, isLoading, setIsPause, courseId, closeChooseProductModal);

  const onCloseModalOnOutsideClick = () => {
    dispatch(actions.courses.setUnsubscribeStep(0));
    dispatch(actions.courses.selectCourseOptionsForPayment(null));
    onClose();
  };

  const closeSuccessfulUnsubscribeModal = () => {
    dispatch(actions.courses.setIsSuccessfulUnsubscribe(false));
  };

  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        onClose={onCloseModalOnOutsideClick}
        textColor={stepContent.textColor}
        titleCSS={stepContent.personalTitleCSS}
        onRightButtonClick={stepContent.onRightButtonClick}
        onLeftButtonClick={stepContent.onLeftButtonClick}
        title={stepContent.title}
        text={stepContent.text}
        isLeftButtonLoading={stepContent.isLeftButtonLoading}
        description={stepContent.description}
        leftButtonTitle={stepContent.leftButtonText}
        rightButtonTitle={stepContent.rightButtonText}
        JSXElem={stepContent.component}
        secondJSXElem={stepContent.secondComponent}
        alsoJSX={stepContent.alsoJSX}
      />
      {isShowSuccessfulUnsubscribeModal && (
        <SuccessfulUnsubscribeModal
          isOpen={isShowSuccessfulUnsubscribeModal}
          onClose={closeSuccessfulUnsubscribeModal}
        />
      )}
      {isPause && <PauseModal isModalVisible={isPause} setModalVisible={setIsPause} />}
    </>
  );
};

export default Unsubscribe;
