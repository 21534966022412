import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

export const useGetPerformanceModalColor = (value: number, isReverse = false) => {
  const { colors } = useTheme();
  const [color, setColor] = useState('');

  const firstColor = isReverse ? colors.system.green : colors.system.red;
  const lastColor = isReverse ? colors.system.red : colors.system.green;

  const getColor = (value: number) => {
    if (value < 30) {
      setColor(firstColor);
    }
    if (value >= 30 && value < 80) {
      setColor(colors.system.yellow);
    }
    if (value >= 80) {
      setColor(lastColor);
    }
  };

  useEffect(() => getColor(value), [value]);

  return color;
};
