import { Input, StyledText } from 'UI';
import Button from 'UI/Button';
import Info from 'UI/Info';
import { MasterCardCircusIcon } from 'assets/icons';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type FormState = {
  cardNumber: string;
  Date: string;
  CVV: string;
};

const CreatePasswordModal: FC<BaseModalProps> = ({ onClose, ...props }) => {
  const { handleSubmit, control } = useForm<FormState>();

  const onSubmit = (data: FormState) => {
    console.log(data);
  };

  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      title="Choose payment method"
      containerCSS={ContainerCSS}
      changeCloseButton
      modalCSS={ModalCSS}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Text>You can only save one card</Text>
        <Label>Current card</Label>
        <CurrentCard>
          **** **** **** 4567
          <MasterCardCircusIcon />
        </CurrentCard>
        <Controller
          control={control}
          name="cardNumber"
          render={({ field }) => <CardNumber label="Card number" {...field} />}
        />
        <InputContainer>
          <Controller control={control} name="Date" render={({ field }) => <StyledInput label="Date" {...field} />} />
          <Controller control={control} name="CVV" render={({ field }) => <StyledInput label="CVV2" {...field} />} />
        </InputContainer>
        <StyledInfo text="Card details are incorrect" />
        <StyledButton variant="primary" size="middle" type="submit">
          Change card
        </StyledButton>
      </Form>
    </DefaultModal>
  );
};

export default CreatePasswordModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const CardNumber = styled(Input)`
  margin-bottom: 32px;

  ${respondToWidth.s`
    margin-bottom: 36px;
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  margin-bottom: 32px;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${respondToWidth.s`
    text-align: start;
    margin-bottom: 24px;
  `}
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;

  ${respondToWidth.s`
    margin-bottom: 44px;
  `}
`;

const StyledInput = styled(Input)`
  &:nth-child(1) {
    margin-right: 20px;

    ${respondToWidth.s`
     margin-right: 16px;
  `}
  }
`;

const Label = styled(StyledText)`
  margin-left: 12px;
  margin-bottom: 4px;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.footnote_regular_12};
`;

const CurrentCard = styled(StyledText)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin-bottom: 32px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 12px;
  ${({ theme: { typography } }) => typography.body_basic_regular_14};

  ${respondToWidth.s`
    margin-bottom: 24px;
  `}
`;

const StyledInfo = styled(Info)`
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  ${respondToWidth.s`
    margin-top: auto;
  `}
`;
