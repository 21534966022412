import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Input } from '../../../../../../UI';

interface SendEmailFormProps {
  isLoading: boolean;
  isError: string;
}

const SendEmailForm = ({ isLoading, isError }: SendEmailFormProps) => {
  const { register } = useFormContext();
  const [email, setEmail] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const isEmailEmpty = email.trim() !== '';

  return (
    <>
      <StyledDescription>
        Try our study system with practice tests, study guide, flashcards, performance reporting, exam confidence
        measuring and much more.
      </StyledDescription>
      <StyledInput
        label={'Email'}
        error={isError}
        {...register('email', { required: true })}
        type="email"
        onChange={handleInputChange}
      />

      <StyledButton type={'submit'} disabled={!isEmailEmpty} isLoading={isLoading}>
        Try The MVP Study System
      </StyledButton>
    </>
  );
};

export default SendEmailForm;
const StyledDescription = styled.p`
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;
const StyledInput = styled(Input)`
  margin-top: 32px;
`;
const StyledButton = styled(Button)`
  margin-top: 40px;
`;
