import { Button, StyledText } from 'UI';
import { EditIcon, PlusIcon } from 'assets/icons';
import cashApp from 'assets/images/CashApp-logo.png';
import link from 'assets/images/Link-logo.png';
import { AddPaymentMethodModal, ChangePaymentMethodModal, DeletePaymentMethodModal } from 'components';
import { useToggle } from 'hooks';
import { useClientSize } from 'hooks';
import React, { useEffect } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { hideText } from 'utils';

const PaymentInfoCard = () => {
  const {
    isOpen: isOpenAddPaymentMethodModal,
    open: openAddPaymentMethodModal,
    close: closeAddPaymentMethodModal,
  } = useToggle();
  const {
    isOpen: isOpenChangePaymentMethodModal,
    open: openChangePaymentMethodModal,
    close: closeChangePaymentMethodModal,
  } = useToggle();
  const {
    isOpen: isOpenDeletePaymentMethodModal,
    open: openDeletePaymentMethodModal,
    close: closeDeletePaymentMethodModal,
  } = useToggle();

  const { colors } = useTheme();
  const { getIsBreakpoint } = useClientSize();
  const isWidthSm = getIsBreakpoint('sm');

  const user = useAppSelector(selectors.user.selectUser);

  useEffect(() => {
    paymentMethod();
  }, [user]);

  const paymentMethod = () => {
    if (user?.paymentMethod) {
      if (user.paymentMethod.type === 'card' && user.paymentMethod.card) {
        return `**** **** **** **${user.paymentMethod.card.last4.slice(-2)}`;
      }

      if (user.paymentMethod.type === 'link' && user.paymentMethod.link?.email) {
        return (
          <Wrapper>
            <img src={link} alt="link" />
            <Value>{user.paymentMethod?.link?.email}</Value>
          </Wrapper>
        );
      }

      if (user.paymentMethod.type === 'cashapp' && user.paymentMethod.cashapp) {
        return (
          <Wrapper>
            <img src={cashApp} alt="link" />
            <Value>{user.paymentMethod.cashapp.cashtag}</Value>
          </Wrapper>
        );
      }
    }
    return null;
  };

  const openEditModal = () => {
    if (paymentMethod()) {
      openChangePaymentMethodModal();
    } else {
      openAddPaymentMethodModal();
    }
  };

  return (
    <>
      <Root>
        <Wrapper>
          <Title as="h3">Payment method</Title>
          <StyledEditIcon onClick={openEditModal} />
        </Wrapper>
        <InfoField>
          <FieldHeader>
            {!paymentMethod && isWidthSm ? (
              <AddCardButton
                onClick={openEditModal}
                iconComponent={<PlusIcon width={14} height={14} color={colors.primary[1]} />}
                size="middle"
                variant="secondary">
                Add card
              </AddCardButton>
            ) : null}
          </FieldHeader>
          {<FieldValue>{paymentMethod()}</FieldValue>}
        </InfoField>
      </Root>
      {isOpenAddPaymentMethodModal && (
        <AddPaymentMethodModal isOpen={isOpenAddPaymentMethodModal} onClose={closeAddPaymentMethodModal} />
      )}
      {isOpenChangePaymentMethodModal && (
        <ChangePaymentMethodModal
          isOpen={isOpenChangePaymentMethodModal}
          onClose={closeChangePaymentMethodModal}
          openDeletePaymentMethodModal={openDeletePaymentMethodModal}
        />
      )}
      <DeletePaymentMethodModal isOpen={isOpenDeletePaymentMethodModal} onClose={closeDeletePaymentMethodModal} />
    </>
  );
};

export default PaymentInfoCard;

const Root = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 20px;

    border-radius: 12px;
    background-color: ${colors.neutrals[11]};

    ${respondToWidth.sm`
      gap: 16px;
      padding: 0;
      padding-bottom: 32px;

      border-radius: 0;
      border-bottom: 1px solid ${colors.neutrals[9]};
    `};
  `}
`;

const Title = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_large_bold_16}

    user-select: none;

    ${respondToWidth.sm`
      ${typography.title_5_bold_20}
    `};
  `};
`;

const InfoField = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FieldHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Value = styled.p`
  margin-left: 8px;
`;

// const FieldLabel = styled(StyledText)`
//   ${({ theme: { colors, typography } }) => css`
//     color: ${colors.neutrals[4]};
//     ${typography.footnote_regular_12}

//     ${respondToWidth.sm`
//       ${typography.body_basic_medium_14}
//     `};
//   `};
// `;

const StyledEditIcon = styled(EditIcon)`
  margin-left: auto;
  margin-top: 6px;
  ${({ theme: { colors } }) => css`
    width: 16px;
    height: 16px;

    cursor: pointer;

    path {
      fill: ${colors.neutrals[4]};
    }

    :hover {
      path {
        fill: ${colors.primary[1]};
      }
    }

    ${respondToWidth.sm`
      width: 20px;
      height: 20px;
      `};
  `};
`;

const AddCardButton = styled(Button)`
  width: 160px;
  border-color: ${({ theme: { colors } }) => colors.neutrals[9]};
  padding-inline: 0;
`;

const FieldValue = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_basic_medium_14}

    ${hideText(1)}

    ${respondToWidth.sm`
      ${typography.body_large_medium_16}
    `};
  `};
`;
