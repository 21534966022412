import api from 'api';
import { ManualContent, ManualResponseDto, ManualTypeEnum } from 'api/generated';
import useNotifications from 'hooks/useNotifications';
import { useEffect, useState } from 'react';

// TODO change to a ManualContent after the Dto will be updated
type ManualContentAdaptiveType = {
  mostEffective: {
    phrase?: string;
    color?: string;
  };
} & ManualContent;

export const useGetManual = (manualType: ManualTypeEnum, courseId?: string) => {
  const { errorToast } = useNotifications();

  const [manual, setManual] = useState<ManualResponseDto | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const content = manual && (manual.content as ManualContentAdaptiveType);
  const getManual = async (manualType: ManualTypeEnum, courseId: string) => {
    setIsLoading(true);
    try {
      const { data, status } = await api.Courses.coursesControllerGetManual(courseId, manualType);

      if (status > 399) {
        errorToast(`${data}`);
        throw data;
      }

      setManual(data);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (courseId) {
      getManual(manualType, courseId);
    }
  }, [courseId]);

  return {
    isManualLoading: isLoading,
    content,
  };
};
