import { DefaultModal } from 'UI';
import React, { FC } from 'react';
import { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import { Body } from '../ContentSidebar/components';

type ContentModalProps = { isLoading: boolean } & BaseModalProps;

const ContentModal: FC<ContentModalProps> = ({ onClose, isLoading, ...props }) => {
  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      showCloseButton={false}
      modalCSS={ModalCSS}
      headerContainerCSS={HeaderContainerCSS}>
      <Body onClose={onClose} isLoading={isLoading} />
    </DefaultModal>
  );
};

export default ContentModal;

const ContainerCSS = css`
  height: calc(100vh - 148px);
  width: 628px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;

  ${respondToWidth.sm`
    width: 100%;
    padding: 0;
    border-radius: 20px 20px 0 0;

  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:100%;
    bottom: 0;
    top: auto;
    z-index: 10;
    transform: translate(-50%, 0);
  `}
`;

const HeaderContainerCSS = css`
  display: none;
`;
