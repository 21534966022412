import { ExpandImage, StyledText } from 'UI';
import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type ContentProps = {
  image?: { url: string; alt: string } | null;
  text?: string;
  isQuestion?: boolean;
  isAnswer?: boolean;
};

const MAX_COUNT_FOR_SHORT_TEXT = 20;

const Content: FC<ContentProps> = ({ image, text, isAnswer, isQuestion }) => {
  const isShortText = text && text?.length < MAX_COUNT_FOR_SHORT_TEXT;
  const isBigVariant = !!(isAnswer && !text);

  const isShowQuestionText = isQuestion || isAnswer;

  const contentWithImage = useMemo(
    () => (
      <>
        <ImageContainer $isBigVariant={isBigVariant}>{image && <ExpandImage image={image} />}</ImageContainer>
        {isShowQuestionText ? (
          <QuestionTextWithImage>{text}</QuestionTextWithImage>
        ) : (
          <TextWithImage>{text}</TextWithImage>
        )}
      </>
    ),
    [isBigVariant, isShowQuestionText, text],
  );

  const shortTextContent = useMemo(
    () =>
      isQuestion ? (
        <QuestionShortTextWithoutImage>{text}</QuestionShortTextWithoutImage>
      ) : isAnswer ? (
        <AnswerShortTextWithoutImage>{text}</AnswerShortTextWithoutImage>
      ) : (
        <ShortTextWithoutImage>{text}</ShortTextWithoutImage>
      ),
    [isAnswer, isQuestion, text],
  );

  const longTextContent = useMemo(
    () =>
      isQuestion ? (
        <QuestionLongTextWithoutImage>{text}</QuestionLongTextWithoutImage>
      ) : isAnswer ? (
        <AnswerLongTextWithoutImage>{text}</AnswerLongTextWithoutImage>
      ) : (
        <LongTextWithoutImage>{text}</LongTextWithoutImage>
      ),
    [isAnswer, isQuestion, text],
  );

  const contentWithoutImage = useMemo(
    () => <>{isShortText ? shortTextContent : longTextContent}</>,
    [isShortText, longTextContent, shortTextContent],
  );

  return (
    <Root $isBigVariant={isBigVariant} $withImage={Boolean(image)}>
      {image ? contentWithImage : contentWithoutImage}
    </Root>
  );
};

export default Content;

const CenteredText = styled(StyledText)`
  text-align: center;
`;

const AnswerShortTextWithoutImage = styled(CenteredText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_1_bold_42}
    white-space: pre-line;
    text-align: left;

    ${respondToWidth.sm`
      ${typography.title_4_bold_24};
    `}
  `}
`;

const QuestionShortTextWithoutImage = styled(CenteredText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_4_medium_24}
    white-space: pre-line;

    ${respondToWidth.sm`
      ${typography.title_4_bold_24};
    `}
  `}
`;

const ShortTextWithoutImage = styled(CenteredText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_1_bold_42}
    white-space: pre-line;

    ${respondToWidth.sm`
      ${typography.title_4_medium_24};
    `}
  `}
`;

const AnswerLongTextWithoutImage = styled(CenteredText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_3_bold_28}
    white-space: pre-line;
    text-align: left;

    ${respondToWidth.sm`
      ${typography.body_basic_semibold_14};
    `}
  `}
`;

const QuestionLongTextWithoutImage = styled(CenteredText)`
  ${({ theme: { typography } }) => css`
    ${typography.headline_semibold_18}
    white-space: pre-line;
    text-align: left;

    ${respondToWidth.sm`
      ${typography.body_basic_semibold_14};
    `}
  `}
`;

const LongTextWithoutImage = styled(CenteredText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_4_medium_24}
    white-space: pre-line;
    text-align: left;

    ${respondToWidth.sm`
      ${typography.body_basic_semibold_14};
    `}
  `}
`;

const QuestionTextWithImage = styled(CenteredText)`
  ${({ theme: { typography } }) => css`
    ${typography.footnote_semibold_12}
    white-space: pre-line;
    text-align: left;s

    ${respondToWidth.sm`
      ${typography.body_basic_semibold_14};
    `}
  `}
`;

const TextWithImage = styled(CenteredText)`
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_medium_14}
    white-space: pre-line;
    text-align: left;

    ${respondToWidth.sm`
      ${typography.body_basic_semibold_14};
    `}
  `}
`;

const ImageContainer = styled.div<{
  $isBigVariant: boolean;
}>`
  width: 100%;
  height: ${({ $isBigVariant }) => ($isBigVariant ? '244px' : '165px')};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${respondToWidth.ls`
    height: 165px;
  `}

  img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 20px;
  }
`;

type RootStyleProps = {
  $isBigVariant: boolean;
  $withImage: boolean;
};

const Root = styled.div<RootStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $withImage }) => ($withImage ? 'start' : 'center')};
  height: 100%;
  gap: ${({ $isBigVariant }) => ($isBigVariant ? '0' : '16px')};
`;
