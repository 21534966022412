import { useElements } from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
import { FC, Fragment, ReactNode, useEffect } from 'react';

type StripeElementProps = {
  children: ReactNode;
  setElements?: (elements: StripeElements | null) => void;
};

const StripeElement: FC<StripeElementProps> = ({ children, setElements }) => {
  const elements = useElements();

  useEffect(() => {
    setElements?.(elements);
  }, [elements]);

  return <Fragment>{children}</Fragment>;
};

export default StripeElement;
