import { StyledText } from 'UI';
import { ArrowRightFillIcon, CloseIcon, SuccessIcon } from 'assets/icons';
import React, { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { hideText } from 'utils';

type SidebarItemProps = {
  index: number;
  title: string;
  isCorrect: boolean;
  isSelect: boolean;
  onClick: () => void;
};

const SidebarItem: FC<SidebarItemProps> = ({ index, title, isCorrect, isSelect, onClick }) => {
  const { colors } = useTheme();

  const text = `${index + 1}.${title}`;

  return (
    <Container onClick={onClick}>
      <Text $isSelect={isSelect}>{text}</Text>
      <IconContainer>
        {isCorrect ? (
          <SuccessIcon width={24} height={24} color={colors.system.green} />
        ) : (
          <CloseIcon width={24} height={24} color={colors.system.red} />
        )}
      </IconContainer>
      {isSelect && (
        <SelectIconContainer>
          <ArrowRightFillIcon width={16} height={16} color={colors.primary[1]} />
        </SelectIconContainer>
      )}
    </Container>
  );
};

export default SidebarItem;

const Container = styled.button`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 4px;
  position: relative;
`;

const Text = styled(StyledText)<{ $isSelect: boolean }>`
  text-align: start;
  ${hideText(1)};

  ${({ theme: { colors, typography }, $isSelect }) => css`
    color: ${$isSelect && colors.primary[1]};
    ${$isSelect ? typography.body_basic_bold_14 : typography.body_basic_medium_14}
  `};
`;

const SelectIconContainer = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: -4px;
  transform: translate(-100%, -50%);
  z-index: 1;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-left: auto;
`;
