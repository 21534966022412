import { BarControlButton } from 'UI';
import { useClientSize } from 'hooks';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { Body } from './components';

type ContentSidebarProps = {
  isOpen: boolean;
  isLoading: boolean;
  toggle: () => void;
  onClose: () => void;
  openAudioUpgradeModal?: () => void;
};

const ContentSidebar: FC<ContentSidebarProps> = ({ isOpen, isLoading, toggle, onClose, openAudioUpgradeModal }) => {
  const { getIsBreakpoint } = useClientSize();
  const isWidthLs = getIsBreakpoint('ls');

  return (
    <Root $isOpen={isOpen}>
      <StyledBarControlButton onClick={toggle} variant={isOpen ? 'show' : 'hide'} />
      {isOpen && (
        <Body
          openAudioUpgradeModal={openAudioUpgradeModal}
          onClose={isWidthLs ? onClose : undefined}
          isLoading={isLoading}
        />
      )}
    </Root>
  );
};

export default ContentSidebar;

const Root = styled.div<{ $isOpen: boolean }>`
  ${({ theme: { colors }, $isOpen }) => css`
    position: absolute;
    right: 0;
    z-index: 10;

    width: ${$isOpen ? 328 : 56}px;
    height: 100vh;

    background-color: ${colors.neutrals[11]};

    @media (max-width: 2190px) {
      position: relative;
      right: 0;
    }

    margin-left: 24px;

    ${respondToWidth.ls`
        margin-left: 0;
        position: absolute;
        right: 0;
        ${
          $isOpen &&
          css`
            border-left: 1px solid ${colors.neutrals[9]};
          `
        }
    `}
  `}
`;

const StyledBarControlButton = styled(BarControlButton)`
  position: absolute;
  top: 28px;
  left: -16px;
`;
