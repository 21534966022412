import { EditIcon } from 'assets/icons';
import React, { FC, HTMLAttributes } from 'react';
import styled, { css, useTheme } from 'styled-components';

type SelectButtonProps = HTMLAttributes<HTMLButtonElement>;

const SelectButton: FC<SelectButtonProps> = ({ className, ...props }) => {
  const { colors } = useTheme();

  return (
    <Root className={className}>
      <Button {...props}>
        <EditIcon width={24} height={24} color={colors.neutrals[11]} />
      </Button>
    </Root>
  );
};

export default SelectButton;

const Root = styled.div`
  ${({ theme: { colors } }) => css`
    width: 36px;
    height: 36px;

    z-index: 10;

    border-radius: 8px;
    overflow: hidden;
    background-color: ${colors.neutrals[11]};
    box-shadow: ${colors.shadow[1]};
  `}
`;
const Button = styled.button`
  ${({ theme: { colors } }) => css`
    width: 100%;
    height: 100%;

    background-color: ${colors.primary[2]};
  `}
`;
