import Button from 'UI/Button';
import PriceLabel from 'UI/PriceLabel';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import StyledText from 'UI/StyledText';
import { CoursePricesDtoMonthlySubscriptions, PaymentDto, SubscribeDtoTypeEnum } from 'api/generated';
import { CheckMark, Cross } from 'assets/icons';
import gift from 'assets/json/gift_white_3d.json';
import { ActivePlan, Plan } from 'components/Modals/ChooseProductModal/components/Products/Products';
import { tabs } from 'constant';
import { levelMapping } from 'constant';
import Lottie from 'lottie-react';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { dayjs } from 'services';
import { actions, useAppDispatch } from 'store';
import { CourseOptionsForPayment } from 'store/ducks/courses/types';
import styled, { css } from 'styled-components';

export type CapabilityType = {
  include: boolean;
  title?: string;
};

type PlanItemProps = {
  plan: Plan;
  // disabled?: boolean;
  hasSubscription?: boolean;
  isShowButton?: boolean;
  isLowest?: boolean;
  isCurrentSubscription?: boolean;
  buttonTitle?: string;
  className?: string;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  planType: ActivePlan | undefined;
  animationData: any;
  bronzeCounts: number;
  nextPayment?: PaymentDto;
  goldCount: number;
  termsCount: number;
  currentPaymentPeriod: string | undefined;
  currentPaymentLevel: string | undefined;
  currentPaymentActiveTo: string | null;
  isSubscriptionUpdating: boolean;
  isMaxHeight: boolean;
  countPlans: number;
  activePeriod: string;
  setActivePeriod: Dispatch<SetStateAction<string>>;
  setIsShowBonusModal: Dispatch<SetStateAction<boolean>>;
  indexPlan: number;
  isResponsive: boolean;
  silverCounts: number;
  isShow: boolean;
  hasSubscriptionDowngrade?: boolean;
  courseId: string;
  monthlyPrices?: Partial<CoursePricesDtoMonthlySubscriptions> | null;
  annualPrices?: Partial<CoursePricesDtoMonthlySubscriptions> | null;
  purchasePrice?: object | null;
  setPlanType: Dispatch<SetStateAction<ActivePlan | undefined>>;
  cancelSubscriptionDowngrade: (courseId: string, isCancelTrial?: boolean) => Promise<void>;
  toggleSubscription: (options: CourseOptionsForPayment, isCurrent: boolean) => void;
  // TODO change to a necessary type after the Dto will be updated
  mostEffectiveLabel?: {
    phrase?: string;
    color?: string;
  };
};

const SubscriptionItem: FC<PlanItemProps> = ({
  nextPayment,
  hasSubscriptionDowngrade = false,
  isCurrentSubscription = false,
  hasSubscription = false,
  isLowest = false,
  isShowButton = true,
  isResponsive,
  animationData,
  activePeriod,
  goldCount,
  silverCounts,
  bronzeCounts,
  setActivePeriod,
  setIsShowBonusModal,
  buttonTitle,
  className,
  planType,
  countPlans,
  setPlanType,
  currentPaymentPeriod,
  currentPaymentLevel,
  currentPaymentActiveTo,
  isSubscriptionUpdating,
  setActiveIndex,
  isMaxHeight,
  termsCount,
  isShow,
  monthlyPrices,
  courseId,
  purchasePrice,
  annualPrices,
  plan,
  cancelSubscriptionDowngrade,
  toggleSubscription,
}) => {
  const [options, setOptions] = useState<any>();
  const [isSelect, setIsSelect] = useState(false);
  const [term, setTerm] = useState<any>();
  const today = dayjs();
  const day = today.date();
  const [isCurrentSubs, setIsCurrentSubs] = useState(isCurrentSubscription);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const title = plan ? levelMapping[plan.type] : levelMapping.bronze;
  const currentPrice = plan?.priceMonthly?.amount || 0;
  const currentPriceAnnual = plan?.priceAnnual?.amount;
  // @ts-ignore
  const currentPriceLifetime = purchasePrice?.amount;
  const capabilities = plan?.capabilities;
  const capabilitiesResponsive = plan.capabilitiesResponsive;

  const isLifeTime = term === TermEnum.LIFE_TIME;
  const subscribeType = plan.type;
  const isGoldSubscribe = subscribeType === 'gold';
  const isCurrentTermPeriod = currentPaymentPeriod === term;

  const disabled =
    (isCurrentTermPeriod && currentPaymentLevel === plan.type && Boolean(!currentPaymentActiveTo)) ||
    isSubscriptionUpdating;

  const handleCancelSubscriptionDowngrade = async () => {
    setIsLoading(true);
    await cancelSubscriptionDowngrade?.(courseId);
    setIsLoading(false);
  };

  const getOrdinal = (day: number) => {
    if (day % 10 === 1 && day % 100 !== 11) return 'st';
    if (day % 10 === 2 && day % 100 !== 12) return 'nd';
    if (day % 10 === 3 && day % 100 !== 13) return 'rd';
    return 'th';
  };

  const onChoosePlan = (plan: Plan, term: TermEnum, subscribeType: SubscribeDtoTypeEnum) => {
    const options = {
      term,
      courseId,
      subscribeType,
      amount: plan?.amount,
      oldAmount: plan?.oldAmount,
      isUpdate: hasSubscription,
      isDowngrade: isLowest,
      couponCode: plan?.defaultCoupon?.promoCode,
      discount: plan?.cancellationPromotionTerms?.discount,
      discountType: plan?.cancellationPromotionTerms?.discountType,
      duration: plan?.cancellationPromotionTerms?.duration,
      durationInMonth: plan?.cancellationPromotionTerms?.durationInMonths,
    };
    setOptions(options);
    setIsCurrentSubs(currentPaymentPeriod === term && isCurrentSubscription);
    setTerm(term);
    setPlanType({ planType: subscribeType, term: term });
    setIsSelect(false);
  };

  const isDowngradeForCurrentTerm = nextPayment && nextPayment.period === term;
  const isNextPaymentForCurrentPlan = nextPayment && nextPayment.level === plan.type;
  const isCurrentSubscribe = currentPaymentLevel === subscribeType;

  const buttonText = isCurrentSubs ? 'Unsubscribe' : isLifeTime ? 'Purchase' : buttonTitle || 'Subscribe';
  const buttonDowngradeText = isDowngradeForCurrentTerm ? 'Cancel downgrade' : buttonText;

  const currentButtonText = hasSubscriptionDowngrade ? buttonDowngradeText : buttonText;

  const shouldDisableButton = disabled || (hasSubscriptionDowngrade && !isDowngradeForCurrentTerm);

  const isDisabled =
    disabled ||
    (isNextPaymentForCurrentPlan && !isDowngradeForCurrentTerm) ||
    (hasSubscriptionDowngrade && !isNextPaymentForCurrentPlan);

  dispatch(actions.courses.setDisabledStatus(isDisabled));

  const isButtonDisabled = isCurrentSubs || shouldDisableButton;
  const isPrimary = !isButtonDisabled;

  useEffect(() => {
    if (nextPayment && nextPayment.period === TermEnum.YEARLY) {
      onChoosePlan(plan.priceAnnual, TermEnum.YEARLY, nextPayment.level as SubscribeDtoTypeEnum);
    }

    if (isCurrentSubscribe && currentPaymentPeriod === TermEnum.YEARLY) {
      onChoosePlan(plan.priceAnnual, TermEnum.YEARLY, subscribeType);
    }
    if (!nextPayment && !isCurrentSubscribe) {
      onChoosePlan(plan.priceMonthly, TermEnum.MONTHLY, subscribeType);
      setIsSelect(true);
    }
  }, [isCurrentSubscribe, currentPaymentPeriod, plan.priceAnnual, subscribeType]);

  useEffect(() => {
    if (nextPayment && nextPayment.period === TermEnum.MONTHLY) {
      onChoosePlan(plan.priceMonthly, TermEnum.MONTHLY, nextPayment.level as SubscribeDtoTypeEnum);
    }

    if (isCurrentSubscribe && currentPaymentPeriod === TermEnum.MONTHLY) {
      onChoosePlan(plan.priceMonthly, TermEnum.MONTHLY, subscribeType);
    }
  }, [isCurrentSubscribe, currentPaymentPeriod, plan.priceMonthly, subscribeType]);

  const calculateCurrentPrice = (
    amount: number,
    defaultCouponDiscount?: number,
    defaultCouponDiscountType?: string,
  ) => {
    if (defaultCouponDiscount && amount - defaultCouponDiscount >= 1 && defaultCouponDiscountType === 'amount') {
      return amount - defaultCouponDiscount;
    }

    if (defaultCouponDiscount && defaultCouponDiscountType === 'percentage') {
      const discountAmount = amount * (defaultCouponDiscount / 100);

      if (currentPrice - discountAmount >= 1) {
        return amount - discountAmount;
      }
    }

    return amount;
  };

  const isOnePriceLabelView = () => {
    if (subscribeType === 'gold') {
      return goldCount === 1;
    }

    if (subscribeType === 'silver') {
      return silverCounts === 1;
    }

    if (subscribeType === 'bronze') {
      return bronzeCounts === 1;
    }

    return false;
  };

  return (
    <div style={{ display: 'flex' }}>
      <Container className={className} type={plan.type} isShow={isShow && isResponsive} isResponsive={isResponsive}>
        {isGoldSubscribe && !isResponsive && <MostEffectiveTable>99% PASS RATE</MostEffectiveTable>}
        {!isGoldSubscribe && <div style={{ height: '35px' }}></div>}
        <PlanInformation>
          <AnimatedContainer>
            <Lottie animationData={animationData} />
          </AnimatedContainer>
          <TitleWithLabel>
            <Title>{title}</Title>
          </TitleWithLabel>
          {/* <Description>By buying an MVP, you get all the advantages of our service</Description> */}
          {isGoldSubscribe && <Table isResponsive={isResponsive}>Flash Sale: Save 30%</Table>}

          {!isGoldSubscribe && !isResponsive && <div style={{ height: isMaxHeight ? '81px' : '25px' }}></div>}

          <CommonPriceContainer isResponsive={isResponsive} isMaxHeight={isMaxHeight}>
            {monthlyPrices && (
              <PriceWrapper isResponsive={isResponsive}>
                {isCurrentSubscribe && currentPaymentPeriod === TermEnum.MONTHLY && <YourPlan>Your plan</YourPlan>}
                <PriceContainer
                  termCount={termsCount}
                  activePlan={planType}
                  subscribeType={subscribeType}
                  term={TermEnum.MONTHLY}
                  isSelect={isSelect}
                  isResponsive={isResponsive}
                  onClick={() => onChoosePlan(plan.priceMonthly, TermEnum.MONTHLY, subscribeType)}>
                  <StyledPriceLabel
                    termCount={termsCount}
                    isOnePriceLabelView={isOnePriceLabelView()}
                    countPlans={countPlans}
                    isMaxHeight={isMaxHeight}
                    currentPrice={calculateCurrentPrice(
                      currentPrice,
                      plan.priceMonthly?.defaultCoupon?.discountAmount,
                      plan.priceMonthly?.defaultCoupon?.discountType,
                    )}
                    oldPrice={plan.priceMonthly?.defaultCoupon?.discountAmount ? currentPrice : undefined}
                    term={TermEnum.MONTHLY}
                  />
                </PriceContainer>
              </PriceWrapper>
            )}
            {annualPrices && (
              <PriceWrapper isResponsive={isResponsive}>
                {isCurrentSubscribe && currentPaymentPeriod === TermEnum.YEARLY && <YourPlan>Your plan</YourPlan>}
                <PriceContainer
                  termCount={termsCount}
                  activePlan={planType}
                  subscribeType={subscribeType}
                  term={TermEnum.YEARLY}
                  isResponsive={isResponsive}
                  onClick={() => onChoosePlan(plan.priceAnnual, TermEnum.YEARLY, subscribeType)}>
                  <StyledPriceLabel
                    termCount={termsCount}
                    isMaxHeight={isMaxHeight}
                    goldCount={goldCount}
                    isOnePriceLabelView={isOnePriceLabelView()}
                    currentPrice={calculateCurrentPrice(
                      currentPriceAnnual,
                      plan.priceAnnual?.defaultCoupon?.discountAmount,
                      plan.priceAnnual?.defaultCoupon?.discountType,
                    )}
                    oldPrice={plan.priceAnnual?.defaultCoupon?.discountAmount ? currentPriceAnnual : undefined}
                    term={TermEnum.YEARLY}
                  />
                </PriceContainer>
              </PriceWrapper>
            )}
            {isGoldSubscribe && purchasePrice && (
              <PriceWrapper isResponsive={isResponsive}>
                <PriceContainer
                  termCount={termsCount}
                  activePlan={planType}
                  subscribeType={subscribeType}
                  term={TermEnum.LIFE_TIME}
                  isResponsive={isResponsive}
                  onClick={() => onChoosePlan(purchasePrice as Plan, TermEnum.LIFE_TIME, subscribeType)}>
                  <StyledPriceLabel
                    isMaxHeight={isMaxHeight}
                    termCount={termsCount}
                    goldCount={goldCount}
                    countPlans={countPlans}
                    isOnePriceLabelView={isOnePriceLabelView()}
                    currentPrice={calculateCurrentPrice(
                      currentPriceLifetime,

                      //@ts-ignore
                      purchasePrice?.defaultCoupon?.discountAmount,
                      //@ts-ignore
                      purchasePrice?.defaultCoupon?.discountType,
                    )}
                    //@ts-ignore
                    oldPrice={purchasePrice?.defaultCoupon?.discountAmount ? currentPriceLifetime : undefined}
                    term={TermEnum.LIFE_TIME}
                  />
                </PriceContainer>
              </PriceWrapper>
            )}
          </CommonPriceContainer>

          {isShowButton && (
            <StyledButton
              disabled={isDisabled}
              isLoading={isLoading}
              onClick={() =>
                hasSubscriptionDowngrade
                  ? handleCancelSubscriptionDowngrade()
                  : toggleSubscription(options, isCurrentSubs)
              }
              variant={isPrimary ? 'primary' : 'secondary'}
              size="small">
              {currentButtonText}
            </StyledButton>
          )}
        </PlanInformation>
        {isResponsive && activePeriod === 'gold' && (
          <BonusContainer onClick={() => setIsShowBonusModal(true)}>
            <Lottie animationData={gift} />
            <div>
              <BonusTitle>Click to see MVP bonuses</BonusTitle>
              <BonusTitle style={{ minWidth: '70px', maxWidth: '115px' }}>{`for ${today.format(
                'MMMM ',
              )}${day}${getOrdinal(day)} `}</BonusTitle>
            </div>
          </BonusContainer>
        )}
        {isResponsive && (
          <TabsContainer>
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                isShow={isShow}
                isActive={activePeriod === tab.id}
                onClick={() => {
                  setActiveIndex(index);
                  setActivePeriod(tab.id);
                }}>
                {tab.title}
              </Tab>
            ))}
          </TabsContainer>
        )}
        {!isResponsive ? (
          <CapabilityList>
            {capabilities.map((item, index) => (
              <CapabilityItem $include={item.include} key={index}>
                <IconContainer>{item.include ? <CheckMark size={24} /> : <Cross size={24} />}</IconContainer>
              </CapabilityItem>
            ))}
          </CapabilityList>
        ) : (
          <ResponsiveCapabilityContainer>
            <ResponsiveTitle position={'left'}>Feature</ResponsiveTitle>
            <ResponsiveTitle position={'right'}>Included?</ResponsiveTitle>
            {capabilitiesResponsive.map((item, index) => (
              <CapabilityItemResponsive $include={item.include} key={item.title} index={index}>
                <div style={{ display: 'flex' }}>
                  <CapabilityResponsiveWrapper>{item.title}</CapabilityResponsiveWrapper>
                </div>
                <IconContainer>{item.include ? <CheckMark size={24} /> : <Cross size={24} />}</IconContainer>
              </CapabilityItemResponsive>
            ))}
          </ResponsiveCapabilityContainer>
        )}
      </Container>
    </div>
  );
};

export default SubscriptionItem;

const Container = styled.div<{ type: string; isShow: boolean; isResponsive: boolean }>`
  margin-top: ${({ isResponsive }) => (isResponsive ? '30px' : 'unset')};
  width: ${({ isShow }) => (!isShow ? '255px' : '100%')};
  display: ${({ isShow, isResponsive }) => (isShow ? 'flex' : !isResponsive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: start;
  height: 100%;
  text-align: start;
  position: relative;
  padding: 0px 18px 31px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  border-top: ${({ type }) => (type === 'gold' ? '35px' : '2px')} solid
    ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-right: 4px solid ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-bottom: 4px solid
    ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-left: 4px solid ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-radius: 16px;

  @media (max-width: 1180px) {
    border: 0;
    padding: 0px 0px 31px;
  }
`;

const YourPlan = styled.p`
  ${({ theme: { typography } }) => typography.footnote_semibold_12}
  background: ${({ theme: { colors } }) => colors.primary[1]};
  padding: 4px 8px;
  border-radius: 12px;
  width: 82px;
  color: ${({ theme: { colors } }) => colors.neutrals[11]};
  position: absolute;
  text-align: center;
  top: -15px;
  right: 5px;
`;

const CommonPriceContainer = styled.div<{ isResponsive: boolean; isMaxHeight: boolean }>`
  display: ${({ isResponsive }) => (isResponsive ? 'flex' : 'block')};
  gap: 7px;
  margin-top: ${({ isMaxHeight }) => (isMaxHeight ? '15px' : '20px')};
`;

const AnimatedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => typography.title_4_bold_24};
  margin-bottom: 20px;
`;

const PriceWrapper = styled.div<{ isResponsive: boolean }>`
  position: relative;
  width: ${({ isResponsive }) => (isResponsive ? '100%' : 'unset')};
`;

// const Description = styled.div`
//   ${({ theme: { typography } }) => typography.footnote_regular_12}
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   margin-bottom: 52px;
// `;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 8px;
`;

const MostEffectiveTable = styled.p`
  z-index: 1;
  position: absolute;
  top: -30px;
  left: 28%;
  ${({ theme: { typography } }) => typography.body_large_bold_16}

  color: #FFFFFF;
`;

const Tab = styled.div<{ isShow: boolean; isActive: boolean }>`
  padding: 6px 27px;
  border-radius: 8px;
  color: ${({ theme: { colors }, isActive }) => (isActive ? '#FFFFFF' : colors.neutrals[1])};
  background: ${({ theme: { colors }, isActive }) => (isActive ? '#DF6438' : colors.neutrals[9])};

  @media (max-width: 1180px) {
    padding: 6px 60px;
  }

  @media (max-width: 570px) {
    padding: 6px 36px;
  }

  @media (max-width: 370px) {
    padding: 6px 29px;
  }
`;

const Table = styled.p<{ isResponsive: boolean }>`
  text-align: center;
  background: ${({ isResponsive, theme: { colors } }) => (isResponsive ? colors.neutrals[9] : 'unset')};
  padding: ${({ isResponsive }) => (isResponsive ? '8px 12px' : 'unset')};
  border-radius: ${({ isResponsive }) => (isResponsive ? '12px' : 'unset')};
  ${({ theme: { typography } }) => typography.body_large_extrabold_16}
  color: ${({ theme: { colors } }) => colors.primary[1]};
  margin-bottom: 10px;
  /* position: absolute; */
  width: ${({ isResponsive }) => (isResponsive ? '100%' : 'unset')};
  top: ${({ isResponsive }) => (isResponsive ? '260px' : 'unset')};
  left: ${({ isResponsive }) => (isResponsive ? 'unset' : '16%')};
`;

const StyledPriceLabel = styled(PriceLabel)<{
  isMaxHeight: boolean;
  goldCount?: number;
  countPlans?: number;
  isOnePriceLabelView?: boolean;
}>`
  ${({ theme: { colors, typography } }) => css`
    gap: 8px;

    color: ${colors.neutrals[1]};
    ${typography.headline_semibold_18};
  `}

  display: ${({ isMaxHeight, goldCount }) => (isMaxHeight && goldCount === 1 ? 'flex' : 'flex')};
  flex-direction: ${({ isMaxHeight, goldCount }) => (isMaxHeight && goldCount === 1 ? 'column' : 'unset')};

  @media (max-width: 560px) {
    ${({ theme: { typography } }) => css`
      ${typography.body_large_bold_16}
      font-weight: 600;
      line-height: 14px;
    `}
  }
`;

const BonusContainer = styled.div`
  width: 250px;
  display: flex;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const BonusTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  border-bottom: 1px dashed ${({ theme: { colors } }) => colors.primary[1]};
`;

const PriceContainer = styled.div<{
  termCount: number;
  activePlan: { planType?: string; term: string } | undefined;
  subscribeType: string;
  term: string;
  isSelect?: boolean;
  isResponsive: boolean;
}>`
  padding: 8px 8px;
  background: ${({ theme: { colors } }) => colors.neutrals[9]};
  border: ${({ subscribeType, activePlan, isSelect, term, theme: { colors } }) =>
    (activePlan && subscribeType === activePlan.planType && term === activePlan.term) || isSelect
      ? '2px solid #DF6438'
      : `2px solid ${colors.neutrals[9]}`};
  border-radius: 12px;
  align-items: center;
  margin: 12px 0px;
  display: ${({ isResponsive }) => (isResponsive ? 'flex' : 'block')};
  justify-content: ${({ isResponsive }) => (isResponsive ? 'center' : 'unset')};

  @media (max-width: 550px) {
    padding: ${({ termCount }) => {
      return termCount !== 1 ? '12px 8px' : '8px 8px';
    }};
  }
`;

const CapabilityList = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: start;
  margin-bottom: 24px;
`;

const CapabilityResponsiveWrapper = styled.div`
  display: flex;
`;

const PlanInformation = styled.div`
  /* position: sticky; */
  top: 0;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  width: 100%;
  margin-bottom: 10px;
  padding-top: 15px;
`;

const CapabilityItem = styled.div<{ $include: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24.5px;
  color: ${({ theme: { colors }, $include }) => !$include && colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ResponsiveCapabilityContainer = styled.div`
  border-top: 30px solid #df6438;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
  width: 100%;
`;

const CapabilityItemResponsive = styled.div<{ $include: boolean; index: number }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  background-color: ${({ theme: { colors }, index }) => (index % 2 !== 0 ? colors.neutrals[11] : colors.neutrals[13])};
  color: ${({ theme: { colors }, $include }) => !$include && colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
`;

const ResponsiveTitle = styled.p<{ position: string }>`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  position: absolute;
  top: -22px;
  left: ${({ position }) => (position === 'left' ? '10px' : 'unset')};
  right: ${({ position }) => (position === 'right' ? '10px' : 'unset')};
  color: white;
`;

const TitleWithLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  padding: ${({ variant }) => (variant === 'secondary' ? '7px 32px' : '8px 32px')};
  margin-top: 16px;
  margin-bottom: 10px;

  @media (min-width: 1350px) {
    white-space: nowrap;
  }
`;
