import StyledText from 'UI/StyledText';
import { SubscribeDtoTypeEnum } from 'api/generated';
import exam from 'assets/images/exam.png';
import progressGreen from 'assets/json/progress_green.json';
import { MAXIMUM_COURSE_POINTS } from 'constant/maximumPoints';
import { useAsyncAction, useGetProgressColor } from 'hooks';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import React, { FC, useDeferredValue, useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { actions, selectors, useAppSelector } from 'store';
import styled, { CSSProp, css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { getPoints } from 'utils';

type StreakContentProps = {
  wrapperCSS?: CSSProp;
  isUnsubscribe?: boolean;
  courseId?: string | undefined;
  subscribeType?: string | undefined;
};

const StreakContent: FC<StreakContentProps> = ({ wrapperCSS, isUnsubscribe, courseId, subscribeType }) => {
  const { colors } = useTheme();
  const coursePoints = useAppSelector(selectors.performance.selectCourseStatistic);
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const [count, setCount] = useState(0);
  const streakCount = useAppSelector((store) => store.performance.courseStatistic?.streak);

  const points = coursePoints?.totalInPoints || 0;
  const pointsById = coursePoints?.totalInPoints || 0;
  const deferredPoints = useDeferredValue(pointsById || points);
  const value = (deferredPoints / MAXIMUM_COURSE_POINTS) * 100;
  const color = useGetProgressColor(value);

  const [getCourseStatisticAction] = useAsyncAction(actions.performance.getCourseStatisticByCourseId);

  const getCourseStatistic = async () => {
    if (!courseId) {
      return;
    }
    try {
      await getCourseStatisticAction({ courseId });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if ((isUnsubscribe && subscribeType === SubscribeDtoTypeEnum.Gold) || subscribeType === SubscribeDtoTypeEnum.None) {
      getCourseStatistic();
    }
  }, [isUnsubscribe]);

  const animateCount = () => {
    const roundCount = Math.round(count);
    const roundDeferredPoints = Math.round(deferredPoints);

    if (roundCount >= roundDeferredPoints) {
      setCount(deferredPoints);
    } else {
      setTimeout(() => {
        setCount((prev) => prev + deferredPoints / 60);
      }, 1000 / 60);
    }
  };

  const animateFlush = () => {
    lottieRef.current?.goToAndPlay(0);
  };

  useEffect(animateCount, [count, deferredPoints]);
  useEffect(animateFlush, [deferredPoints]);

  return (
    <Root>
      <Wrapper $wrapperCSS={wrapperCSS}>
        {!isUnsubscribe && (
          <AnimationContainer>
            <Lottie animationData={progressGreen} lottieRef={lottieRef} loop={false} />
          </AnimationContainer>
        )}
        <ProgressContainer>
          <Scale src={exam} alt="Exam scale" />
          <CircularProgressbar
            value={value}
            circleRatio={0.5}
            strokeWidth={10}
            styles={buildStyles({
              trailColor: colors.neutrals[9],
              strokeLinecap: 'round',
              pathColor: color,
              rotation: 0.75,
              pathTransitionDuration: 0.8,
            })}
          />
          <Legends>
            <Count $color={color}>{getPoints(count)}</Count>
            <Text>points out of {getPoints(MAXIMUM_COURSE_POINTS)}</Text>
          </Legends>
        </ProgressContainer>
      </Wrapper>
      {!isUnsubscribe && (
        <StreakText>
          You are on a {streakCount} day
          <br /> study streak
        </StreakText>
      )}
    </Root>
  );
};

export default StreakContent;

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled('div')<{ $wrapperCSS?: CSSProp }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  ${({ $wrapperCSS }) => $wrapperCSS}
`;

const Scale = styled.img`
  position: absolute;
  top: 37px;
  left: 38px;
  width: 194px;
`;

const Legends = styled.div`
  position: absolute;
  top: auto;
  bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AnimationContainer = styled.div`
  position: absolute;
  top: 0;
  left: -15%;
  width: 130%;
`;

const ProgressContainer = styled.div`
  width: 268px;
  height: 175px;
  margin-top: 128px;
  position: relative;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: -20px;

  svg {
    width: 100%;
  }

  ${respondToWidth.sm`
    width: 270px;
  `}
`;

const Count = styled(StyledText)<{ $color: string }>`
  ${({ theme: { typography }, $color }) => css`
    z-index: 1;
    color: ${$color};

    ${typography.title_2_bold_32};
  `};
`;

const Text = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    padding-top: 10px;
    color: ${colors.neutrals[1]};

    ${typography.body_large_semibold_16};
  `};
`;

const StreakText = styled.p`
  ${({ theme: { typography } }) => css`
    position: relative;
    text-align: center;
    color: #55b47d;

    ${typography.title_4_bold_24};
  `};
`;
