import StyledText from 'UI/StyledText';
import { SpamIcon } from 'assets/icons';
import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';

type InfoProps = {
  color?: string;
  text: string;
  className?: string;
};

const Info: FC<InfoProps> = ({ color, text, className }) => {
  const { colors } = useTheme();

  return (
    <Container className={className}>
      <IconContainer>
        <SpamIcon color={color || colors.system.red} size={20} />
      </IconContainer>
      <Text $color={color || colors.system.red}>{text}</Text>
    </Container>
  );
};

export default Info;

const Container = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const Text = styled(StyledText)<{ $color: string }>`
  color: ${({ $color }) => $color};
  ${({ theme: { typography } }) => typography.body_basic_medium_14}
`;
