import { Button, DefaultModal, StyledText } from 'UI';
import { useClientSize } from 'hooks';
import React, { FC, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

type TooltipModalProps = { text: string; title: string } & BaseModalProps;

const TooltipModal: FC<TooltipModalProps> = ({ onClose, text, title, ...props }) => {
  const { getIsBreakpoint } = useClientSize();
  const isWidthSm = getIsBreakpoint('sm');

  if (!isWidthSm) {
    return null;
  }

  const handleClick: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClose();
  };

  return (
    <DefaultModal
      {...props}
      onRequestClose={handleClick}
      containerCSS={ContainerCSS}
      showCloseButton={false}
      modalCSS={ModalCSS}
      headerContainerCSS={HeaderContainerCSS}>
      <Wrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Wrapper>
      <StyledButton variant="secondary" size="middle" onClick={handleClick}>
        Close
      </StyledButton>
    </DefaultModal>
  );
};

export default TooltipModal;

const ContainerCSS = css`
  width: 100%;
  padding: 28px 16px;
  display: flex;
  flex-direction: column;

  ${respondToWidth.sm`
    border-radius:28px 28px 0 0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const HeaderContainerCSS = css`
  display: none;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const Title = styled(StyledText)`
  text-align: center;
  margin-top: 0;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.title_5_bold_20};
`;

const Text = styled(StyledText)`
  width: 100%;
  margin-top: 24px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

const StyledButton = styled(Button)`
  width: 144px;

  ${respondToWidth.sm`
    width:100%;
  `}
`;
