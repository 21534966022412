import { AudioPlayer } from 'UI';
import { accessLevels } from 'constant';
import React, { FC } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type FooterAudioProps = {
  openUpgradeModal?: () => void;
};

const FooterAudio: FC<FooterAudioProps> = ({ openUpgradeModal, ...props }) => {
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const currentChapter = useAppSelector(selectors.studyGuide.selectCurrentChapter);
  const currentChapterIndex = useAppSelector(selectors.studyGuide.selectCurrentChapterIndex);
  const hasAudio = Boolean(currentChapter?.audioUrl);

  const { hasAllChaptersAudioInStudyGuide } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  const handleEnded = () => {
    if (!hasAllChaptersAudioInStudyGuide) {
      openUpgradeModal?.();
    }
  };

  const handleTouch = (e: unknown) => {
    if (!hasAllChaptersAudioInStudyGuide && currentChapterIndex !== 0) {
      (e as Event).preventDefault();
      (e as Event).preventDefault();
      openUpgradeModal?.();
    }
  };

  if (!hasAudio) {
    return null;
  }

  return (
    <Footer
      onClickCapture={handleTouch}
      onMouseDownCapture={handleTouch}
      onDragCapture={handleTouch}
      onDragStartCapture={handleTouch}
      onTouchStartCapture={handleTouch}
      onTouchEndCapture={handleTouch}
      {...props}>
      <AudioPlayer onEnded={handleEnded} src={currentChapter?.audioUrl || ''} />
    </Footer>
  );
};

export default FooterAudio;

const Footer = styled.div`
  ${({ theme: { colors } }) => css`
    height: 100%;
    padding-inline: 24px 40px;

    display: flex;
    align-items: center;

    border-top: 1px solid ${colors.neutrals[9]};
    background-color: ${colors.neutrals[11]};

    ${respondToWidth.sm`
        height: 56px;

        position: fixed;
        left: 0;
        bottom: 0;
        right:0;
        z-index: 10;


        padding-inline: 16px;
    `}
  `}
`;
