import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { ChangeCurrentPlanDto, PlanPhasesDto, PlansDto } from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType, PaginationType } from 'types';

export const getPlansThunk = createAsyncThunk<PlansDto, undefined, ExtraParamsThunkType<DefaultRejectValue>>(
  'studyPlans/getPlans',
  async (_, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Plans.planControllerGetPlans();

      if (status > 399) {
        throw data;
      }

      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const changeCurrentPlanThunk = createAsyncThunk<
  ChangeCurrentPlanDto,
  ChangeCurrentPlanDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('studyPlans/changeCurrentPlan', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Plans.planControllerChangeCurrentPlan(body);

    if (status > 399) {
      throw data;
    }

    return body;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

type getPlanPhasesThunkResponse = { isFirstFetch: boolean; data: PlanPhasesDto };

export const getPlanPhasesThunk = createAsyncThunk<
  getPlanPhasesThunkResponse,
  { planId: string } & PaginationType,
  ExtraParamsThunkType<DefaultRejectValue>
>('studyPlans/getPlanPhases', async ({ planId, limit = 20, page }, { rejectWithValue }) => {
  try {
    const isFirstFetch = !page || page === 1;

    const { data, status } = await api.Plans.planControllerGetPlanPhases(planId, page, limit);

    if (status > 399) {
      throw data;
    }

    return { data, isFirstFetch };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

type toggleMarkTaskThunkRequest = { taskId: string; phaseId: string };
type toggleMarkTaskThunkResponse = { taskId: string; phaseId: string };

export const markTaskThunk = createAsyncThunk<
  toggleMarkTaskThunkResponse,
  toggleMarkTaskThunkRequest,
  ExtraParamsThunkType<DefaultRejectValue>
>('studyPlans/markTask', async ({ taskId, phaseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Tasks.tasksControllerMark(taskId);

    if (status > 399) {
      throw data;
    }

    return { taskId, phaseId };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const unMarkTaskThunk = createAsyncThunk<
  toggleMarkTaskThunkResponse,
  toggleMarkTaskThunkRequest,
  ExtraParamsThunkType<DefaultRejectValue>
>('studyPlans/unMarkTask', async ({ taskId, phaseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Tasks.tasksControllerUnMark(taskId);

    if (status > 399) {
      throw data;
    }

    return { taskId, phaseId };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
