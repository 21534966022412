import { BODY_ID, MODAL_HEIGHT_WITH_PADDING } from 'pages/StudyGuide/components/Chapter/components/Body/Body';
import { MouseEvent, useState } from 'react';

const useNoteModalPosition = () => {
  const [modalPositionY, setModalPositionY] = useState(0);
  const [isModalAbove, setIsModalAbove] = useState(true);

  const onMouseDown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    const body = document.getElementById(BODY_ID);
    const scrollTop = body?.scrollTop || 0;
    const newModalPositionY = scrollTop + e.clientY;

    setModalPositionY(newModalPositionY);
  };

  const calculateModalPosition = (isCollapsed: boolean, e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    const body = document.getElementById(BODY_ID);
    const scrollTop = body?.scrollTop || 0;
    const newModalPositionY = scrollTop + e.clientY;
    const initialModalPosition = modalPositionY - scrollTop;

    setModalPosition(isCollapsed, e.clientY, newModalPositionY, initialModalPosition);
  };

  const setModalPosition = (
    isCollapsed: boolean,
    clientY: number,
    newModalPositionY: number,
    initialModalPosition: number,
  ) => {
    if (!isCollapsed && modalPositionY < MODAL_HEIGHT_WITH_PADDING) {
      setModalPositionY(newModalPositionY);
      setIsModalAbove(false);
    } else if (!isCollapsed && initialModalPosition > MODAL_HEIGHT_WITH_PADDING) {
      setIsModalAbove(true);
    } else if (isCollapsed && clientY > MODAL_HEIGHT_WITH_PADDING) {
      setModalPositionY(newModalPositionY);
      setIsModalAbove(true);
    } else if (isCollapsed || modalPositionY < MODAL_HEIGHT_WITH_PADDING) {
      setIsModalAbove(false);
    } else {
      setModalPositionY(newModalPositionY);
      setIsModalAbove(false);
    }
  };

  return { onMouseDown, calculateModalPosition, setIsModalAbove, isModalAbove, modalPositionY, setModalPositionY };
};

export default useNoteModalPosition;
