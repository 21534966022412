import { Button, ScrollbarContainer } from 'UI';
import { DefaultModal } from 'UI/Modals';
import useClientSize from 'hooks/useClientSize';
import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

type QuestionsModalProps = {
  children: ReactNode;
};

const QuestionsModal: FC<BaseModalProps & QuestionsModalProps> = ({ onClose, children, ...props }) => {
  const { getIsBreakpoint } = useClientSize();

  const isWidthS = getIsBreakpoint('s');
  return (
    <DefaultModal
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      titleCSS={TitleCSS}
      title="Flashcards"
      modalCSS={ModalCSS}
      showCloseButton={!isWidthS}
      headerContainerCSS={HeaderContainer}
      {...props}>
      <StyledScrollbar>{children}</StyledScrollbar>
      {isWidthS && (
        <ButtonContainer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </ButtonContainer>
      )}
    </DefaultModal>
  );
};

export default QuestionsModal;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  ${({ theme: { colors } }) => css`
    background-color: ${colors.neutrals[11]};
    border-top: 1px solid ${colors.neutrals[9]};
  `}
`;

const ContainerCSS = css`
  width: 1100px;
  padding: 40px 0 60px 8px;
  height: calc(100vh - 140px);
  overflow: hidden;

  ${respondToWidth.lg`
    width:100%;
  `}

  ${respondToWidth.s`
    padding-top: 28px;
    height: calc(100vh - 160px);
    border-radius: 20px 20px 0 0;
    bottom: 0;
    top: 75px;
  `}
`;

const HeaderContainer = css`
  margin-bottom: 23px;
  ${respondToWidth.s`
      justify-content: center;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.lg`
    width:90%;
  `};

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `};
`;

const StyledScrollbar = styled(ScrollbarContainer)`
  width: 100%;
  height: 100%;
`;

const TitleCSS = css`
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.title_3_bold_28};
  `}
`;
