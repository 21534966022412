import { Label } from 'UI';
import { DonutChartIcon } from 'assets/icons';
import React, { ButtonHTMLAttributes, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type PerformancePlugProps = ButtonHTMLAttributes<HTMLButtonElement>;

const PerformancePlug: FC<PerformancePlugProps> = (props) => {
  const { colors } = useTheme();

  return (
    <Root {...props}>
      <StyledLabel text="MVP" color="yellow" />
      <Wrapper>
        <IconContainer>
          <DonutChartIcon color={colors.system.yellow} />
        </IconContainer>
        Performance
      </Wrapper>
    </Root>
  );
};

export default PerformancePlug;

const Root = styled.button`
  position: relative;
  width: 100%;
  height: 116px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  margin-top: 16px;
  color: ${({ theme: { colors } }) => colors.system.yellow};
  background-color: ${({ theme: { colors } }) => colors.system.lightYellow};
  border-radius: 16px;
  ${({ theme: { typography } }) => typography.footnote_bold_12};

  ${({ theme: { typography } }) => respondToWidth.sm`
    height:60px;
    border-radius: 12px;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 18px 16px;
    ${typography.subhead_semibold_13};
  `}
`;

const StyledLabel = styled(Label)`
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, -50%);
  width: 60px;
  z-index: 1;

  ${respondToWidth.sm`
    position:initial;
    transform: translate(0);
  `}
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  margin-bottom: 8px;

  ${respondToWidth.sm`
    width:24px;
    height:24px;
    margin-bottom: 0px;
    margin-right: 12px;

    svg {
      width: 24px;
      height: 24px;
    }
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${respondToWidth.sm`
    flex-direction: row;
  `}
`;
