import { StyledText } from 'UI';
import { ConfirmModalProps } from 'UI/Modals/ConfirmModal/ConfirmModal';
import { StreakContent } from 'UI/Modals/PointsResultModal/components';
import { CouponDiscountType, SubscribeDtoTypeEnum } from 'api/generated';
import { AxiosError } from 'axios';
import { MAXIMUM_COURSE_POINTS } from 'constant/maximumPoints';
import { useAsyncAction, useGetProgressColor, useNotifications } from 'hooks';
import { Dispatch, ReactNode, SetStateAction, useDeferredValue, useEffect, useState } from 'react';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { UnsubscribeSteps } from 'store/ducks/courses/types';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import DiscountedPrice from '../components/DiscountedPrice';
import { ProgressColor } from './constants';

type StepContent = Pick<ConfirmModalProps, 'title' | 'text'> & {
  description?: ReactNode;
  leftButtonText: string;
  isButtonCSS?: boolean;
  rightButtonText: string;
  textColor?: string;
  personalTitleCSS?: any;
  component?: ReactNode;
  alsoJSX?: ReactNode;
  secondComponent?: ReactNode;
  onRightButtonClick: () => void;
  onLeftButtonClick: () => void;
  isLeftButtonLoading?: boolean;
};

export const useStepsContent = (
  onClose: () => void,
  onUnsubscribe: () => void,
  isLoading: boolean,
  setIsPause: Dispatch<SetStateAction<boolean>>,
  courseId: string | undefined,
  closeChooseProductModal: () => void,
): StepContent => {
  const dispatch = useAppDispatch();
  const { colors } = useTheme();
  const { errorToast, successToast } = useNotifications();
  const [promotion, setPromotion] = useState<number | undefined>();
  const [promotionType, setPromotionType] = useState<CouponDiscountType | undefined>();
  const unsubscribeStep = useAppSelector(selectors.courses.selectUnsubscribeStep);
  const courseOptionsForPayment = useAppSelector(selectors.courses.selectCourseOptionsForPayment);
  const subscribeType = courseOptionsForPayment?.subscribeType;
  const [applyCancellationPromotion] = useAsyncAction(actions.payments.applyCancellationPromotion);

  useEffect(() => {
    if (!courseOptionsForPayment?.courseId || subscribeType === undefined) {
      return;
    }

    setPromotion(courseOptionsForPayment.discount);
    setPromotionType(courseOptionsForPayment.discountType);
  }, [courseOptionsForPayment?.courseId]);

  const coursePoints = useAppSelector(selectors.performance.selectCourseStatistic);

  const points = coursePoints?.totalInPoints || 0;
  const deferredPoints = useDeferredValue(points);
  const value = (deferredPoints / MAXIMUM_COURSE_POINTS) * 100;

  const color = useGetProgressColor(value) as ProgressColor;

  useEffect(() => {
    if (
      color === '#55B47D' ||
      courseOptionsForPayment?.subscribeType === SubscribeDtoTypeEnum.Bronze ||
      courseOptionsForPayment?.subscribeType === SubscribeDtoTypeEnum.Silver
    ) {
      dispatch(actions.courses.setUnsubscribeStep(1));
    }
  }, [courseOptionsForPayment?.subscribeType]);

  const handleSubmit = () => {
    dispatch(actions.courses.setUnsubscribeStep(unsubscribeStep === 0 ? unsubscribeStep + 2 : unsubscribeStep + 1));
  };

  const handleCloseModal = () => {
    dispatch(actions.courses.setUnsubscribeStep(0));
    dispatch(actions.courses.selectCourseOptionsForPayment(null));
    onClose();
    if (unsubscribeStep === 2) {
      closeChooseProductModal();
    }
  };

  const handleUnsubscribe = async () => {
    try {
      await onUnsubscribe();
      dispatch(actions.courses.setUnsubscribeStep(0));
    } catch (error) {
      errorToast((error as AxiosError).message || 'Something went wrong');
    }
  };

  const handlePause = () => {
    setIsPause(true);
    closeChooseProductModal;
    dispatch(actions.courses.setUnsubscribeStep(0));
    onClose();
    if (unsubscribeStep === 2 || unsubscribeStep === 3) {
      closeChooseProductModal();
    }
  };

  const handlePromotion = async () => {
    if (!courseOptionsForPayment?.courseId) {
      return;
    }

    try {
      await applyCancellationPromotion({ courseId: courseOptionsForPayment?.courseId });
      handleCloseModal();
      successToast('Successful payment');
    } catch (error) {
      handleCloseModal();
      errorToast((error as AxiosError).message || 'Something went wrong');
    }
  };

  switch (unsubscribeStep) {
    default:
    case UnsubscribeSteps.STEP_1: {
      return {
        title: "Don't Lose Your Guarantees",
        personalTitleCSS: PersonalTitleCSS,
        textColor: colors.neutrals[1],
        isButtonCSS: true,
        description: (
          <Text>
            Please note that your progress is in {color === '#EB4040' ? 'red' : 'yellow'} zone. If you cancel, you will{' '}
            <BoldText>lose all progress</BoldText> and{' '}
            <BoldText>not qualify for the money back + retake fee pass guarantee.</BoldText>
          </Text>
        ),
        leftButtonText: 'I’ll stay',
        rightButtonText: 'Cancel anyway',
        onRightButtonClick: handleSubmit,
        onLeftButtonClick: handleCloseModal,
        component: <StreakContent isUnsubscribe={true} courseId={courseId} subscribeType={subscribeType} />,
      };
    }

    case UnsubscribeSteps.STEP_2: {
      return {
        title: 'Cancel subscription',
        textColor: colors.neutrals[1],
        personalTitleCSS: PersonalTitleCSS,
        isButtonCSS: true,
        description: (
          <Text>
            Please note that if you cancel your subscription,
            <br /> all your progress will be irretrievably lost.
          </Text>
        ),
        leftButtonText: 'I’ll stay',
        rightButtonText: 'Cancel anyway',
        onRightButtonClick: handleSubmit,
        onLeftButtonClick: handleCloseModal,
      };
    }

    case UnsubscribeSteps.STEP_3: {
      return {
        title: 'Pause Your Subscription',
        textColor: colors.neutrals[1],
        personalTitleCSS: PersonalTitleCSS,
        isButtonCSS: true,
        description: (
          <Text>
            Instead of cancelling and losing all your progress, we suggest<BoldText>pausing your subscription</BoldText>
            (1,2 or 3 months) until you are ready to study again.
          </Text>
        ),
        leftButtonText: 'Pause my subscription',
        rightButtonText: 'Cancel anyway',
        onRightButtonClick: handleSubmit,
        onLeftButtonClick: handlePause,
      };
    }

    case UnsubscribeSteps.STEP_4: {
      return {
        title: `Get ${promotionType === 'percentage' ? '%' : '$'}${promotion} off`,
        textColor: colors.neutrals[1],
        personalTitleCSS: PersonalTitleCSS,
        isButtonCSS: true,
        description: (
          <Text>
            We want to see you succeed!
            <BoldText>
              {promotionType === 'percentage' ? '%' : '$'}
              {promotion}
              off
            </BoldText>
            for the remainder of your subscription.
          </Text>
        ),
        leftButtonText: `I’ll stay for ${promotionType === 'percentage' ? '%' : '$'}${promotion} off`,
        rightButtonText: 'Cancel anyway',
        onRightButtonClick: handleSubmit,
        onLeftButtonClick: handlePromotion,
        secondComponent: (
          <DiscountedPrice
            discount={promotion}
            discountType={promotionType}
            price={courseOptionsForPayment?.amount ? courseOptionsForPayment?.amount : 0}
          />
        ),
        alsoJSX: (
          <Buttons onClickCapture={handlePause}>
            <StatusContainer>
              <Status>{'Pause subscription instead'}</Status>
            </StatusContainer>
          </Buttons>
        ),
      };
    }

    case UnsubscribeSteps.STEP_5: {
      return {
        title: 'Are you sure you want to cancel your subscription?',
        text: 'You will not be able to undo this action',
        leftButtonText: 'Yes',
        rightButtonText: 'No',
        description: '',
        isLeftButtonLoading: isLoading,
        onRightButtonClick: handleCloseModal,
        onLeftButtonClick: handleUnsubscribe,
      };
    }
  }
};

const PersonalTitleCSS = css`
  margin-bottom: 0;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.primary[1]};
    ${typography.title_2_bold_32};
  `}

  ${respondToWidth.s`
    text-align: center;
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  margin-top: 24px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};
  font-weight: 600 !important;

  ${({ theme: { typography } }) => respondToWidth.sm`
  ${typography.body_basic_semibold_14};
  margin-top: 30px;
  `};
`;

const BoldText = styled(Text)`
  display: inline;
  font-weight: bolder !important;
  margin-top: 0;
  margin-left: 4px;
  margin-right: 4px;
`;

const Buttons = styled.button`
  padding: 0 12px;
  margin-top: 20px;
  border: none;
  outline: none;
  background-color: inherit;
`;

const Status = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    white-space: nowrap;
    ${typography.body_large_semibold_16}
    color: ${colors.primary[1]};
  `}
`;

const StatusContainer = styled.div``;
