import { Appearance, StripeElementsOptions } from '@stripe/stripe-js';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useTheme } from 'styled-components';

const useStripeProviderOptions = (isFormActive: boolean) => {
  const { colors } = useTheme();
  const { isMobile } = useGetIsMobile();

  const appearance: Appearance = {
    variables: {
      borderRadius: '8px',
      spacingGridColumn: '16px',
      spacingGridRow: '16px',
      colorDanger: colors.system.red,
      colorText: isFormActive ? colors.neutrals[1] : colors.neutrals[6],
      colorTextPlaceholder: isFormActive ? colors.neutrals[4] : colors.neutrals[6],
      colorPrimary: colors.primary[1],
      colorIconTab: colors.neutrals[3],
      colorTextSecondary: colors.neutrals[3],
      focusOutline: 'none',
      fontSizeBase: '14px',
      fontLineHeight: '20px',
      focusBoxShadow: `0 0 0 1px ${isFormActive ? colors.primary[1] : colors.neutrals[10]}`,
      colorBackground: colors.neutrals[10],
    },
    rules: {
      '.Input--invalid': {
        boxShadow: `0 0 0 1px ${colors.system.red}`,
        backgroundColor: colors.system.lightRed,
        color: colors.neutrals[1],
      },
      '.Input': {
        boxShadow: `0 0 0 1px ${isFormActive ? colors.neutrals[8] : colors.neutrals[10]}`,
      },
      '.Input:focus': {
        fontSize: isMobile ? '16px' : '14px',
      },
      '.Label': {
        color: colors.neutrals[4],
        marginLeft: '12px',
        fontWeight: '400px',
        fontSize: '12px',
        lineHeight: '16px',
      },
      '.Error': {
        fontWeight: '400px',
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    theme: 'flat',
  };

  const options: StripeElementsOptions = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap',
        family: 'Manrope',
        src: 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap',
      },
    ],
    locale: 'en',
    appearance,
  };

  return { options };
};

export default useStripeProviderOptions;
