import { useGetIsMobile } from 'hooks/useGetIsMobile';
import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { IconText, StyledButton, StyledLoader } from './styled';

type IconButtonProps = {
  text?: string;
  isLoading?: boolean;
  iconComponent: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton: FC<IconButtonProps> = ({ isLoading = false, iconComponent, text, ...props }) => {
  const { colors } = useTheme();
  const { isMobile } = useGetIsMobile();
  return (
    <StyledButton $isLoading={isLoading} $isMobile={isMobile} type="button" {...props}>
      {isLoading ? (
        <StyledLoader size={18} color={colors.primary[1]} />
      ) : (
        <>
          {iconComponent}
          {text && <IconText>{text}</IconText>}
        </>
      )}
    </StyledButton>
  );
};

export default IconButton;
