import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type ResetResultCourseModalProps = {
  onYesButtonClick?: () => void;
  onNoButtonClick?: () => void;
  isLoading: boolean;
} & BaseModalProps;

const ResetResultFlashcardsModal: FC<ResetResultCourseModalProps> = ({
  onClose,
  onYesButtonClick,
  onNoButtonClick,
  isLoading,
  ...props
}) => {
  const handleNoClick = () => {
    onNoButtonClick?.();
    onClose();
  };

  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      showCloseButton={false}
      headerContainerCSS={HeaderContainerCSS}
      modalCSS={ModalCSS}>
      <Wrapper>
        <Title>Are you sure you want to reset all the results?</Title>
        <Text>You will lose confidence points</Text>
      </Wrapper>
      <Buttons>
        <StyledButton variant="primary" size="middle" onClick={onYesButtonClick} isLoading={isLoading}>
          Yes
        </StyledButton>
        <StyledButton variant="secondary" size="middle" onClick={handleNoClick} disabled={isLoading}>
          No
        </StyledButton>
      </Buttons>
    </DefaultModal>
  );
};

export default ResetResultFlashcardsModal;

const ContainerCSS = css`
  width: 614px;
  padding: 40px 49px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    padding:28px 16px; 
  `}
`;

const HeaderContainerCSS = css`
  display: none;
`;

const ModalCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;

  ${respondToWidth.ls`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const Title = styled(StyledText)`
  text-align: center;
  margin-bottom: 24px;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.title_4_bold_24}

    ${respondToWidth.s`
    ${typography.title_5_bold_20};
  `}
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.system.red};
    ${typography.body_large_semibold_16}

    ${respondToWidth.s`
    ${typography.body_basic_medium_14};
  `}
  `};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;

  ${respondToWidth.s`
    margin-bottom: 32px;
  `}
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  width: 144px;

  ${respondToWidth.s`
      width: 100%;
    `}

  &:nth-child(1) {
    margin-right: 24px;

    ${respondToWidth.s`
      margin-right: 15px; 
    `}
  }
`;
