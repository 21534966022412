import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgHideIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.5 2v13.5H2V2h1.5Zm9.879 7.5H5V8h8.379L8.825 3.447l1.06-1.06L16.25 8.75l-6.364 6.364-1.06-1.06L13.377 9.5h.002Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgHideIcon);
export default Memo;
