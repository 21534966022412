import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type ResetResultCourseModalProps = {
  courseName: string;
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
  isLoading: boolean;
} & BaseModalProps;

const ResetResultCourseModal: FC<ResetResultCourseModalProps> = ({
  onClose,
  courseName,
  onLeftButtonClick,
  onRightButtonClick,
  isLoading,
  ...props
}) => {
  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      showCloseButton={false}
      headerContainerCSS={HeaderContainerCSS}
      modalCSS={ModalCSS}>
      <Wrapper>
        <Title>
          Are you sure you want to reset the results of the <Course>{courseName}</Course> course?
        </Title>
        <Text>You won{"'"}t be able to undo it</Text>
      </Wrapper>
      <Buttons>
        <StyledButton variant="primary" size="middle" onClick={onLeftButtonClick} isLoading={isLoading}>
          Yes
        </StyledButton>
        <StyledButton variant="secondary" size="middle" onClick={onRightButtonClick}>
          No
        </StyledButton>
      </Buttons>
    </DefaultModal>
  );
};

export default ResetResultCourseModal;

const ContainerCSS = css`
  width: 628px;
  padding: 40px 80px;
  display: flex;
  flex-direction: column;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    padding:28px 16px; 
  `}
`;

const HeaderContainerCSS = css`
  display: none;
`;

const ModalCSS = css`
  ${respondToWidth.ls`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const Title = styled(StyledText)`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  margin-bottom: 24px;
  ${({ theme: { typography } }) => typography.title_4_bold_24}

  ${({ theme: { typography } }) => respondToWidth.s`
    ${typography.title_5_bold_20};
    margin-bottom: 20px;
  `}
`;

const Course = styled(Title)`
  display: inline;
  margin: 0;
  color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const Text = styled(StyledText)`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.system.red};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  ${({ theme: { typography } }) => respondToWidth.s`
    ${typography.body_basic_medium_14};
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;

  ${respondToWidth.s`
    margin-bottom: 32px;
  `}
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  width: 144px;

  ${respondToWidth.s`
      width: 100%;
    `}

  &:nth-child(1) {
    margin-right: 24px;

    ${respondToWidth.s`
      margin-right: 15px; 
    `}
  }
`;
