import { AppConfig } from 'config';

type GetEventPurchaseParams = {
  email?: string | '';
  term: string;
  amount: number;
  courseId: string;
  subscribeType: string;
  courseName?: string;
  promoCode?: string;
};

type GetEventUserData = {
  email: string;
};

type GetEventPageView = {
  pathname: string;
};
const TAG_ID = AppConfig.REACT_APP_TRACK_GOOGLE_ANALYTICS;

export const useGoogleAnalytics = () => {
  const getEventPurchaseGA = ({
    amount,
    courseId,
    subscribeType,
    courseName,
    promoCode,
    term,
  }: GetEventPurchaseParams) => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('event', 'purchase', {
      value: amount,
      currency: 'USD',
      send_to: 'ga',
      items: [
        {
          term: term,
          course_name: courseName,
          promo_code: promoCode,
          item_name: subscribeType,
          item_id: courseId,
          price: amount,
          quantity: 1,
        },
      ],
    });
  };

  const getEventSubscribeGA = ({
    amount,
    courseId,
    subscribeType,
    courseName = '',
    promoCode,
    term,
  }: GetEventPurchaseParams) => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('event', 'subscribe', {
      value: amount,
      currency: 'USD',
      send_to: 'ga',
      items: [
        {
          course_name: courseName,
          promo_code: promoCode,
          item_name: subscribeType,
          item_id: courseId,
          price: amount,
          term: term,
          quantity: 1,
        },
      ],
    });
  };

  const getEventUserDataGA = ({ email }: GetEventUserData) => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('set', 'user_data', {
      email,
      send_to: 'ga',
    });
  };

  const getEventSubscriptionPaymentModalOpenedGA = () => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('event', 'subscription_payment_modal_opened', { send_to: 'ga' });
  };

  const getEventSubscriptionPayPressedGA = () => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('event', 'subscription_pay_pressed', { send_to: 'ga' });
  };

  const getEventSubscriptionFromTrialVersionGA = () => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('event', 'subscription_from_trial_version', { send_to: 'ga' });
  };

  const getEventPurchaseFromTrialVersionGA = () => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('event', 'purchase_from_trial_version', { send_to: 'ga' });
  };

  const getEventSubscriptionFromTrialVersionLandingGA = () => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('event', 'subscription_from_trial_version_for_landing', { send_to: 'ga' });
  };

  const getEventPurchaseFromTrialVersionLandingGA = () => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('event', 'purchase_from_trial_version_for_landing', { send_to: 'ga' });
  };

  const getEventSuccessfulSubscribeForLandingGA = () => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('event', 'successful_subscribe_from_landing', { send_to: 'ga' });
  };

  const getEventSuccessfulPurchaseForLandingGA = () => {
    if (!window.gtag || !TAG_ID) return;

    window.gtag('event', 'successful_purchase_from_landing', { send_to: 'ga' });
  };

  const getEventPageViewGA = ({ pathname }: GetEventPageView) => {
    if (!window.gtag || !TAG_ID) return;
    window.gtag('event', 'page_view', {
      page_path: pathname,
      send_to: 'ga',
    });
  };

  const getTrialCertificationSelectedGA = (selectedCourse: string) => {
    if (!window.gtag || !TAG_ID) return;
    window.gtag('event', 'trial_certification_selected', {
      type: selectedCourse,
      send_to: 'ga',
    });
  };

  const trialRequestedGA = () => {
    if (!window.gtag || !TAG_ID) return;
    window.gtag('event', 'trial_requested', { send_to: 'ga' });
  };

  return {
    trialRequestedGA,
    getEventPurchaseGA,
    getEventSubscribeGA,
    getEventUserDataGA,
    getEventPageViewGA,
    getTrialCertificationSelectedGA,
    getEventSubscriptionPayPressedGA,
    getEventSubscriptionPaymentModalOpenedGA,
    getEventSubscriptionFromTrialVersionGA,
    getEventPurchaseFromTrialVersionGA,
    getEventSubscriptionFromTrialVersionLandingGA,
    getEventSuccessfulPurchaseForLandingGA,
    getEventSuccessfulSubscribeForLandingGA,
    getEventPurchaseFromTrialVersionLandingGA,
  };
};
