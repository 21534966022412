import { Button, DefaultModal, Textarea } from 'UI';
import { useSuggestion } from 'hooks';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type SuggestionModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const SuggestionModal: FC<SuggestionModalProps> = ({ onClose, isOpen }) => {
  const {
    formMethods: { control, handleSubmit },
    MAX_LENGTH,
    MIN_LENGTH,
    handleFormSubmit,
    isLoading,
    isError,
  } = useSuggestion(onClose);

  return (
    <DefaultModal
      isOpen={isOpen}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      modalCSS={ModalCSS}
      closeButtonCSS={CloseCSS}
      headerContainerCSS={HeaderContainerCSS}
      withTitleMargin={false}
      title="Your suggestion">
      <Text>
        {`Share your suggestions for improving our service. You'll help us make the service even better for your exam
        preparation success.`}
      </Text>
      <Controller
        control={control}
        name="noteText"
        rules={{
          maxLength: MAX_LENGTH,
          minLength: MIN_LENGTH,
        }}
        render={({ field, fieldState: { error } }) => (
          <StyledTextarea
            {...field}
            value={field.value || undefined}
            autoFocus
            maxLength={MAX_LENGTH}
            error={error?.message}
            placeholder="I suggest improving..."
          />
        )}
      />
      <StyledButton variant="primary" onClick={handleSubmit(handleFormSubmit)} isLoading={isLoading} disabled={isError}>
        Send
      </StyledButton>
    </DefaultModal>
  );
};

export default SuggestionModal;

const StyledTextarea = styled(Textarea)`
  width: 468px;
  min-height: 170px;

  ${respondToWidth.sl`
    width: 100%;
    max-width: 100%;
  `}
`;

const Text = styled.p`
  ${({ theme: { colors, typography } }) => css`
    width: 100%;
    max-width: 468px;
    margin: 20px 0;
    color: ${colors.neutrals[1]};
    ${typography.body_basic_medium_14};
  `};
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
`;

const ContainerCSS = css`
  padding: 40px 80px 40px;
  resize: vertical;

  ${respondToWidth.sl`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px 28px;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sl`
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  `}
`;

const CloseCSS = css`
  ${respondToWidth.sl`
    display:none;
  `}
`;

const HeaderContainerCSS = css`
  ${respondToWidth.sl`
    justify-content: center;
  `}
`;
