import { LoaderIcon } from 'assets/icons';
import styled, { css, keyframes } from 'styled-components';
import { typography } from 'styles/general/typography';

export type VariantButtonStyle = 'primary' | 'secondary' | 'tertiary';

export type ButtonSize = 'big' | 'middle' | 'small';

type StyledButtonProps = {
  $variant?: VariantButtonStyle;
  $size?: ButtonSize;
  $isLoading?: boolean;
  $withIcon?: boolean;
  $isDarkMode?: boolean;
  $isMobile: boolean;
};

type IconContainerProps = {
  $size: ButtonSize;
  $variant: VariantButtonStyle;
  $isDisabled?: boolean;
};

const PrimaryCSS = css<StyledButtonProps>`
  ${({ theme: { colors }, $isLoading, $isMobile, $isDarkMode }) => css`
    color: ${$isDarkMode ? colors.neutrals[1] : colors.neutrals[11]};
    background-color: ${colors.primary[1]};

    &:hover {
      background-color: ${() => !$isMobile && ($isLoading ? colors.primary[1] : colors.primary[3])};
    }

    &:disabled {
      cursor: not-allowed;
      color: ${colors.neutrals[6]};
      background-color: ${colors.neutrals[9]};
    }

    &:active:enabled {
      background-color: ${$isLoading ? colors.primary[1] : colors.primary[2]};
    }
  `};
`;

const SecondaryCSS = css<StyledButtonProps>`
  ${({ theme: { colors }, $size, $withIcon }) => css`
    color: ${colors.neutrals[1]};
    background-color: transparent;
    border: 1px solid ${$size === 'small' && $withIcon ? colors.neutrals[9] : colors.primary[1]};
  `}

  &:hover {
    border-color: ${({ theme: { colors }, $isLoading, $isMobile }) =>
      !$isMobile && ($isLoading ? colors.primary[1] : colors.primary[4])};
  }

  &:disabled {
    ${({ theme: { colors } }) => css`
      color: ${colors.neutrals[6]};
      border-color: ${colors.neutrals[9]};
    `}
    cursor: not-allowed;
  }

  &:active:enabled {
    background-color: ${({ theme: { colors }, $isLoading }) => ($isLoading ? colors.neutrals[11] : colors.primary[6])};
  }
`;

const TertiaryCSS = css<StyledButtonProps>`
  ${({ theme: { colors } }) => css`
    color: ${colors.primary[1]};
    background-color: ${colors.primary[6]};
    border: 1px solid transparent;
  `}

  &:hover {
    ${({ theme: { colors }, $isLoading, $isMobile }) => css`
      background-color: ${!$isMobile && ($isLoading ? colors.primary[6] : colors.primary[5])};
    `}
  }

  &:disabled {
    ${({ theme: { colors } }) => css`
      color: ${colors.neutrals[6]};
      background-color: ${colors.neutrals[10]};
    `}
    cursor: not-allowed;
  }

  &:active:enabled {
    ${({ theme: { colors }, $isLoading }) => css`
      border-color: ${!$isLoading && colors.primary[1]};
      background-color: ${colors.primary[6]};
    `}
  }
`;

const getStylesButton = (variant: VariantButtonStyle) => {
  switch (variant) {
    case 'primary':
      return PrimaryCSS;
    case 'secondary':
      return SecondaryCSS;
    case 'tertiary':
      return TertiaryCSS;
  }
};
const getStyledButtonHeight = (size: ButtonSize, withIcon?: boolean, isLoading?: boolean) => {
  switch (size) {
    case 'big':
      return css<StyledButtonProps>`
        padding: 20px 56px;
        padding-block: ${isLoading && '21px'};
        ${typography.body_large_bold_16};
      `;
    case 'middle':
      return css<StyledButtonProps>`
        padding: ${withIcon ? '11px 39px 11px 35px' : '11px 39px'};
        padding-block: ${isLoading && '13px'};
        ${typography.body_large_bold_16};
      `;
    case 'small':
      return css<StyledButtonProps>`
        padding: ${withIcon ? '5px 31px 5px 23px' : '7px 31px'};
        padding-block: ${isLoading && '5px'};
        ${typography.body_basic_bold_14};
      `;
  }
};

export const StyledBaseButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  outline: none;
  cursor: ${({ $isLoading }) => ($isLoading ? 'wait' : 'pointer')};
  text-align: center;
  border-radius: 12px;
  user-select: none;
  border: 1px solid transparent;
  ${({ $variant = 'primary' }) => getStylesButton($variant)};
  ${({ $size = 'middle', $withIcon, $isLoading }) => getStyledButtonHeight($size, $withIcon, $isLoading)};
`;

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ $variant, $size }) => ($variant === 'secondary' && $size === 'middle' ? '10px' : '6px')};

  svg path {
    fill: ${({ theme: { colors }, $isDisabled }) => $isDisabled && colors.neutrals[6]};
  }
`;

const rotate = keyframes`
from {
    transform: rotate(0deg);
  }
to {
    transform: rotate(360deg);
  }
`;

export const StyledLoader = styled(LoaderIcon)`
  animation: ${rotate} 2s linear infinite;
`;
