import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import TextButton from 'UI/TextButton';
import Textarea from 'UI/Textarea';
import { DeleteIcon } from 'assets/icons';
import { useOnClickOutside } from 'hooks';
import { MODAL_HEIGHT } from 'pages/StudyGuide/components/Chapter/components/Body/Body';
import React, { FC, HTMLAttributes, KeyboardEvent, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

export type FormState = {
  noteText: string;
};

type NoteModalProps = {
  className?: string;
  isModalAbove: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  onSubmit: (values: FormState) => void;
  close: () => void;
  defaultValue?: string;
  onDeleteButtonClick: () => void;
} & Omit<HTMLAttributes<HTMLDivElement>, 'onSubmit'>;

const MAX_LENGTH = 600;

const NoteModal: FC<NoteModalProps> = ({
  isModalAbove,
  defaultValue,
  isSaving,
  isDeleting,
  onSubmit,
  close,
  onDeleteButtonClick,
  ...props
}) => {
  const { colors } = useTheme();
  const modalRef = useRef<HTMLDivElement>(null);

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    reset,
    formState: { isValid },
  } = useForm<FormState>({
    defaultValues: { noteText: defaultValue },
    mode: 'onChange',
  });

  const textLength = watch('noteText')?.length || 0;
  const counter = `${MAX_LENGTH}/${textLength}`;

  const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      if (isValid) {
        onSubmit(getValues());
        reset();
      }

      e.preventDefault();
    }
  };

  const onClose = () => {
    close();
    reset();
  };

  useOnClickOutside(modalRef, onClose);

  return (
    <Root ref={modalRef} $isModalAbove={isModalAbove} {...props}>
      <Window onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <div>
          <Controller
            control={control}
            name="noteText"
            rules={{
              maxLength: MAX_LENGTH,
            }}
            render={({ field, fieldState: { error } }) => (
              <StyledTextarea
                {...field}
                value={field.value || undefined}
                autoFocus
                maxLength={MAX_LENGTH}
                error={error?.message}
              />
            )}
          />
        </div>
        <Footer>
          <ButtonsWrapper>
            <Button disabled={isDeleting} isLoading={isSaving} type="submit" size="small">
              Save
            </Button>
            <TextButton
              onClick={onDeleteButtonClick}
              text="Delete"
              disabled={isDeleting || isSaving}
              iconComponent={<DeleteIcon color={colors.system.red} size={24} />}
              variant="textWithIcon"
            />
          </ButtonsWrapper>

          <Counter>{counter}</Counter>
        </Footer>
      </Window>
    </Root>
  );
};

export default NoteModal;

const Root = styled.div<{ $isModalAbove: boolean }>`
  width: 100%;
  max-width: 500px;
  height: ${MODAL_HEIGHT}px;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;

  ${({ $isModalAbove }) =>
    $isModalAbove
      ? css`
          margin-top: -90px;
          transform: translateX(-50%) translateY(-100%);
        `
      : css`
          margin-top: -40px;
        `}

  ${respondToWidth.sm`
    max-width: none;
    position: fixed;


    top: 0 !important;
    right: 0;
    bottom: 0;
    left: 0;

    margin-top: 0;
    padding-top: 32px;
    padding-inline: 16px;



    transform: none !important;
`}
`;

const Window = styled.form`
  ${({ theme: { colors } }) => css`
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: 1fr auto;
    grid-gap: 16px;
    padding: 16px;

    border-radius: 16px;
    border: 1px solid ${colors.neutrals[9]};
    background-color: ${colors.neutrals[11]};
    box-shadow: ${colors.shadow[1]};
  `}
`;

const StyledTextarea = styled(Textarea)`
  height: 100%;
  max-width: none;

  span {
    margin-top: -4px;
  }
`;

const Footer = styled.div`
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Counter = styled(StyledText)`
  ${({ theme: { colors } }) => css`
    color: ${colors.neutrals[4]};
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 32px;
`;
