import { RootState } from 'store';

export const selectors = {
  selectCourses: (state: RootState) => state.courses.courses,
  selectCourse: (state: RootState) => state.courses.course,
  selectPublicCourse: (state: RootState) => state.courses.publicCourse,
  selectActiveCourse: (state: RootState) => state.courses.activeCourse,
  inviteCourse: (state: RootState) => state.courses.inviteCourse,
  selectCurrentPaymentLevel: (state: RootState) => state.courses.activeCourse?.currentPayment?.level,
  selectCurrentPaymentType: (state: RootState) => state.courses.activeCourse?.currentPayment?.type,
  selectCheatSheetUrl: (state: RootState) => state.courses.cheatSheetUrl,
  selectTextBookUrl: (state: RootState) => state.courses.textBookUrl,
  selectDirectionCoursesMeta: (state: RootState) => state.courses.meta,
  selectIsChooseProductModalOpen: (state: RootState) => state.courses.isChooseProductModalOpen,
  selectIsPaymentModalOpen: (state: RootState) => state.courses.isPaymentModalOpen,
  selectCourseOptionsForPayment: (state: RootState) => state.courses.courseOptionsForPayment,
  selectActiveCourseExamDate: (state: RootState) => state.courses.examDate,
  selectIsPurchased: (state: RootState) => state.courses.isPurchased,
  selectUnsubscribeStep: (state: RootState) => state.courses.unsubscribeStep,
  selectIsPauseSubscription: (state: RootState) => state.courses.isPauseSubscription,
  selectIsSuccessfulUnsubscribe: (state: RootState) => state.courses.setIsSuccessfulUnsubscribe,
  selectDisabledStatus: (state: RootState) => state.courses.isDisabledPaymentButton,
};
