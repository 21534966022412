import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import 'api/generated';
import {
  FlashcardPackStatsDto,
  FlashcardsBaseStatsDto,
  PaginationMetaDto,
  SingleAnswerFlashcardPackStatsDto,
  TestAnswerSplitStatsDto,
  TestPassageStatsDto,
  TestSplitStatsDto,
  TestsBaseStatsDto,
  TotalPointsDto,
} from 'api/generated';

import { markTaskThunk, unMarkTaskThunk } from '../studyPlans/actions';
import {
  getAllTestsStatisticThunk,
  getCourseStatisticByCourseIdThunk,
  getCourseStatisticThunk,
  getFlashcardsStatisticByAnswerThunk,
  getStatisticByFlashcardsPacksThunk,
  getStatisticByFlashcardsThunk,
  getStatisticByTestsLegendThunk,
  getStatisticByTestsThunk,
  getStatisticTestByIdThunk,
  getTestStatisticByAnswerThunk,
  getTestsListStatisticThunk,
} from './actions';
import { SplitType } from './types';

interface PerformanceSlice {
  courseStatistic: TotalPointsDto | null;
  testsStatistics: TestsBaseStatsDto | null;
  testsStatisticsByAnswer: Array<TestAnswerSplitStatsDto> | null;
  testsStatisticsByAnswerMeta: PaginationMetaDto | null;
  flashcardsStatistics: FlashcardsBaseStatsDto | null;
  flashcardsStatisticsByAnswer: Array<SingleAnswerFlashcardPackStatsDto> | null;
  flashcardsStatisticsByAnswerMeta: PaginationMetaDto | null;
  flashcardsPacksStatistic: Array<FlashcardPackStatsDto> | null;
  flashcardsPacksStatisticMeta: PaginationMetaDto | null;
  testStatistic: Array<TestSplitStatsDto> | null;
  testStatisticMeta: PaginationMetaDto | null;
  testsList: Array<any> | null;
  testsListMeta: PaginationMetaDto | null;
  isPointsIncreased: boolean | null;
  currentTestId: string | null;
  currentTestSplitType: SplitType;
  testsLegendStatistic: TestPassageStatsDto | null;
  allTestsStatistic: Array<TestSplitStatsDto> | null;
  allTestsStatisticMeta: PaginationMetaDto | null;
  isShowAllTestsStatistic: boolean;
}

const initialState: PerformanceSlice = {
  courseStatistic: null,
  testsStatistics: null,
  testsStatisticsByAnswer: null,
  testsStatisticsByAnswerMeta: null,
  flashcardsStatistics: null,
  flashcardsStatisticsByAnswer: null,
  flashcardsStatisticsByAnswerMeta: null,
  flashcardsPacksStatistic: null,
  flashcardsPacksStatisticMeta: null,
  testStatistic: null,
  testStatisticMeta: null,
  testsList: null,
  testsListMeta: null,
  isPointsIncreased: null,
  currentTestId: null,
  currentTestSplitType: 'chapter',
  testsLegendStatistic: null,
  allTestsStatistic: null,
  allTestsStatisticMeta: null,
  isShowAllTestsStatistic: true,
};

export const performanceSlice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    clearStatisticsByAnswer(state) {
      state.testsStatisticsByAnswer = null;
      state.flashcardsStatisticsByAnswer = null;
    },
    clearTestList(state) {
      state.testsList = null;
      state.testsListMeta = null;
    },
    clearPointIncreased(state) {
      state.isPointsIncreased = null;
    },
    setCurrentTestId(state, action: PayloadAction<string | null>) {
      state.currentTestId = action.payload;
    },
    setCurrentTestSplit(state, action: PayloadAction<SplitType>) {
      state.currentTestSplitType = action.payload;
    },
    setIsShowAllTestsStatistic(state, action: PayloadAction<boolean>) {
      state.isShowAllTestsStatistic = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseStatisticThunk.fulfilled, (state, { payload }) => {
        state.courseStatistic = payload;
      })
      .addCase(getStatisticByTestsThunk.fulfilled, (state, { payload }) => {
        state.testsStatistics = payload;
      })
      .addCase(getTestStatisticByAnswerThunk.fulfilled, (state, { payload }) => {
        const testsStatisticsByAnswer = state.testsStatisticsByAnswer || [];
        state.testsStatisticsByAnswer = payload.isFirstFetch
          ? payload.data.items
          : [...testsStatisticsByAnswer, ...payload.data.items];
        state.testsStatisticsByAnswerMeta = payload.data.meta;
      })
      .addCase(getStatisticByFlashcardsThunk.fulfilled, (state, { payload }) => {
        state.flashcardsStatistics = payload;
      })
      .addCase(getFlashcardsStatisticByAnswerThunk.fulfilled, (state, { payload }) => {
        const flashcardsStatisticsByAnswer = state.flashcardsStatisticsByAnswer || [];
        state.flashcardsStatisticsByAnswer = payload.isFirstFetch
          ? payload.data.items
          : [...flashcardsStatisticsByAnswer, ...payload.data.items];
        state.flashcardsStatisticsByAnswerMeta = payload.data.meta;
      })
      .addCase(getStatisticByFlashcardsPacksThunk.fulfilled, (state, { payload }) => {
        const flashcardsPacksStatistic = state.flashcardsPacksStatistic || [];
        state.flashcardsPacksStatistic = payload.isFirstFetch
          ? payload.data.items
          : [...flashcardsPacksStatistic, ...payload.data.items];
        state.flashcardsPacksStatisticMeta = payload.data.meta;
      })
      .addCase(getStatisticTestByIdThunk.fulfilled, (state, { payload }) => {
        const testStatistic = state.testStatistic || [];
        state.testStatistic = payload.isFirstFetch ? payload.data.items : [...testStatistic, ...payload.data.items];
        state.testStatisticMeta = payload.data.meta;
      })
      .addCase(getTestsListStatisticThunk.fulfilled, (state, { payload }) => {
        const testsList = state.testsList || [];
        state.testsList = payload.isFirstFetch ? payload.data.items : [...testsList, ...payload.data.items];
        state.testsListMeta = payload.data.meta;
      })
      .addCase(markTaskThunk.fulfilled, (state) => {
        state.isPointsIncreased = true;
      })
      .addCase(unMarkTaskThunk.fulfilled, (state) => {
        state.isPointsIncreased = false;
      })
      .addCase(getStatisticByTestsLegendThunk.fulfilled, (state, { payload }) => {
        state.testsLegendStatistic = payload;
      })
      .addCase(getAllTestsStatisticThunk.fulfilled, (state, { payload }) => {
        const allTestsStatics = state.allTestsStatistic || [];
        state.allTestsStatistic = payload.isFirstFetch
          ? payload.data.items
          : [...allTestsStatics, ...payload.data.items];
        state.allTestsStatisticMeta = payload.data.meta;
      });
  },
});

export const actions = {
  ...performanceSlice.actions,
  getCourseStatistic: getCourseStatisticThunk,
  getStatisticByTests: getStatisticByTestsThunk,
  getTestStatisticByAnswer: getTestStatisticByAnswerThunk,
  getStatisticByFlashcards: getStatisticByFlashcardsThunk,
  getFlashcardsStatisticByAnswer: getFlashcardsStatisticByAnswerThunk,
  getFlashcardsPacksStatistic: getStatisticByFlashcardsPacksThunk,
  getStatisticTest: getStatisticTestByIdThunk,
  getTestsList: getTestsListStatisticThunk,
  getTestsLegendStatistic: getStatisticByTestsLegendThunk,
  getAllTestsStatistic: getAllTestsStatisticThunk,
  getCourseStatisticByCourseId: getCourseStatisticByCourseIdThunk,
};

export const { reducer } = performanceSlice;
