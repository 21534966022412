import { CalendarIcon } from 'assets/icons';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

type ButtonContentProps = {
  children: ReactNode;
};

const ButtonContent: FC<ButtonContentProps> = ({ children }) => {
  return (
    <Container>
      <CalendarIcon width={18} />
      {children}
    </Container>
  );
};

export default ButtonContent;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;
