import { ColorTeg, StyledText } from 'UI';
import { LockIcon } from 'assets/icons';
import { ButtonHTMLAttributes, FC, memo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { stringCircumcision } from 'utils/textHelpers';

type FlashcardProps = {
  id?: string;
  name: string;
  learnedCount: number;
  total?: number;
  onClick: ((id: string) => void) | (() => void);
  showIcon?: boolean;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

const MAX_LENGTH_TITLE = 253;

const Flashcard: FC<FlashcardProps> = ({ name, learnedCount, total, disabled, onClick, id, showIcon, ...props }) => {
  const result = learnedCount && total && learnedCount / total;
  const formattedTitle = stringCircumcision(name, MAX_LENGTH_TITLE);

  const { colors } = useTheme();

  return (
    <Root $disabled={disabled} onClick={() => onClick(id || '')} {...props}>
      <InnerContainer>
        <Indicator $result={result} />
        <Title>{formattedTitle}</Title>
      </InnerContainer>
      <StyledColorTeg
        count={learnedCount}
        total={total}
        showIcon={showIcon}
        icon={<LockIcon size={18} color={colors.neutrals[4]} />}
      />
    </Root>
  );
};

export default memo(Flashcard);

const Root = styled.button<{ $disabled?: boolean }>`
  ${({ theme: { colors }, $disabled }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    max-width: 1224px;
    width: 100%;
    padding-left: 16px;
    padding-right: 20px;
    border-radius: 12px;
    cursor: pointer;
    background-color: ${colors.neutrals[11]};
    opacity: ${$disabled ? 0.5 : 1};

    ${respondToWidth.sm`
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  `}
  `};
`;

const StyledColorTeg = styled(ColorTeg)`
  min-width: 158px;

  ${respondToWidth.sm`
    width: 100%;
  `}
`;

const Title = styled(StyledText)`
  text-align: left;
  overflow: hidden;
  ${({ theme: { typography } }) => typography.body_large_bold_16};
`;

const Indicator = styled.div<{ $result?: number }>`
  flex-shrink: 0;
  margin: 20px 0;
  width: 4px;
  height: 32px;
  border-radius: 2px;
  background-color: ${({ theme: { colors }, $result }) => {
    if ($result === 0) {
      return colors.neutrals[4];
    }
    if ($result && $result > 0 && $result < 1) {
      return colors.system.blue;
    }
    if ($result === 1) {
      return colors.system.green;
    } else {
      return colors.primary[1];
    }
  }};

  ${respondToWidth.sm`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 10px 0 0 0;
  `}
`;

const InnerContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 12px;

  ${respondToWidth.sm`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  `}
`;
