import { AsyncThunk } from '@reduxjs/toolkit';
import { useAsyncAction } from 'hooks';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { DefaultRejectValue, ExtraParamsThunkType } from 'types';

import ToggleButton from '../ToggleButton';

const SwitchSettingButton = <Returned, ThunkArg, RejectValue = DefaultRejectValue>({
  title,
  isEnabled,
  action,
}: {
  title: string;
  isEnabled: boolean;
  action: AsyncThunk<Returned, ThunkArg, ExtraParamsThunkType<RejectValue>>;
}) => {
  const [toggleHandler, isLoading] = useAsyncAction(action);

  const handleToggleStreak = () => {
    toggleHandler();
  };

  return (
    <StreakSettingsWrapper>
      <p>{title}</p>
      <ToggleButton checked={isEnabled} onChange={handleToggleStreak} disabled={isLoading} />
    </StreakSettingsWrapper>
  );
};

export default SwitchSettingButton;

const StreakSettingsWrapper = styled.div`
  ${({ theme: { typography, colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 8px 20px;
    border-radius: 12px;
    background-color: ${colors.neutrals[11]};

    & p {
      color: ${colors.neutrals[1]};
      ${typography.body_basic_medium_14};
    }

    ${respondToWidth.sm`
      min-height: 52px;
      background-color: ${colors.neutrals[10]};
      & p {
        ${typography.body_large_medium_16};
      };
    `}
  `};
`;
