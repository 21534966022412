import { BreakpointsType, OptionType } from 'types';

export const stringCircumcision = (str: string, count: number) => {
  return str?.length > count ? `${str.substring(0, count)}...` : str;
};

export const getUpFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const getBreadcrumbsString = (options: Array<OptionType>, symbol = '·') => {
  let breadcrumbsString = '';

  const createBreadcrumbsString = (option: OptionType, index: number, options: Array<OptionType>) => {
    const isLastOption = index >= options.length - 1;
    const isValue = option.value || typeof option.value === 'number';

    const point = isLastOption ? '' : ` ${symbol} `;

    breadcrumbsString += isValue ? `${option.value} ${option.label}${point}` : '';
  };

  options.map(createBreadcrumbsString);

  return breadcrumbsString;
};

/**
 * @example
 * handleWordsDeclination(
 *   count: 3,
 *   declination: [гость, гостя, гостей]
 *   для русской локализации последовательность в соответствии с падежами [И.П(ед.ч) Р.П(ед.ч) Р.П(мн.ч)]
 *   для английской локализации соответственно [ед.ч, мн.ч, мн.ч] например [guest, guests, guests]
 * ) // гостя
 */

export const handleWordsDeclination = (count: number, declinations: Array<string>) => {
  const variants = [2, 0, 1, 1, 1, 2];
  return declinations[count % 100 > 4 && count % 100 < 20 ? 2 : variants[count % 10 < 5 ? count % 10 : 5]];
};

export const replaceHtmlEntities = (function () {
  const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  const translate = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
  };

  return (string: string) =>
    string.replace(translate_re, (_match, entity) => translate[entity as keyof typeof translate]);
})();

export const getCircumcisionCountForTestSubject = (breakPoint: BreakpointsType | null) => {
  switch (breakPoint) {
    case 'xl':
      return 25;
    case 'xm':
      return 45;
    case 'x':
      return 40;
    case 'l':
      return 35;
    case 'lg':
      return 32;
    case 'ls':
      return 50;
    case 'sl':
      return 30;
    case 'sm':
      return 25;
    case 's':
      return 50;
    default:
      return 25;
  }
};

export const getTestAnswerLetter = (index: number) => {
  return String.fromCharCode(97 + index).toUpperCase();
};
