import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import React, { FC, ReactNode } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

export type ConfirmModalProps = {
  title?: string;
  text?: string;
  titleCSS?: any;
  description?: ReactNode;
  isButtonCSS?: boolean;
  textColor?: string;
  leftButtonTitle?: string;
  rightButtonTitle?: string;
  alsoJSX?: ReactNode;
  onLeftButtonClick?: () => void | Promise<void>;
  onRightButtonClick?: () => void;
  showCloseButton?: boolean;
  changeCloseButton?: boolean;
  isLeftButtonLoading?: boolean;
  isRightButtonLoading?: boolean;
  JSXElem?: ReactNode;
  secondJSXElem?: ReactNode;
} & BaseModalProps;

const ConfirmModal: FC<ConfirmModalProps> = ({
  onClose,
  title,
  titleCSS,
  text,
  description,
  textColor: propsTextColor,
  leftButtonTitle,
  rightButtonTitle,
  onLeftButtonClick,
  onRightButtonClick,
  showCloseButton = false,
  changeCloseButton = false,
  isLeftButtonLoading,
  isRightButtonLoading,
  alsoJSX,
  JSXElem,
  secondJSXElem,
  ...props
}) => {
  const { colors } = useTheme();

  const textColor = propsTextColor || colors.neutrals[4];
  return (
    <DefaultModal
      onRequestClose={onClose}
      title={title}
      titleCSS={titleCSS || TitleCSS}
      containerCSS={ContainerCSS}
      showCloseButton={showCloseButton}
      changeCloseButton={changeCloseButton}
      withTitleMargin={false}
      modalCSS={ModalCSS}
      {...props}>
      {text && <Text $color={textColor}>{text}</Text>}
      {JSXElem}
      {description && <Description $color={textColor}>{description}</Description>}
      {secondJSXElem}
      <Buttons $withText={Boolean(text)} isButtonCSS={Boolean(description)}>
        <StyledButtonRight
          variant="primary"
          size="middle"
          onClick={onLeftButtonClick}
          isButtonCSS={Boolean(description)}
          $defaultPadding={!leftButtonTitle}
          disabled={isRightButtonLoading}
          isLoading={isLeftButtonLoading}>
          {leftButtonTitle || 'Yes'}
        </StyledButtonRight>
        <StyledButtonLeft
          variant="secondary"
          size="middle"
          onClick={onRightButtonClick ? onRightButtonClick : onClose}
          isButtonCSS={Boolean(description)}
          $defaultPadding={!rightButtonTitle}
          disabled={isLeftButtonLoading}
          isLoading={isRightButtonLoading}>
          {rightButtonTitle || 'No'}
        </StyledButtonLeft>
      </Buttons>
      {alsoJSX}
    </DefaultModal>
  );
};

export default ConfirmModal;

const ContainerCSS = css`
  width: 614px;
  padding: 40px 84px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    padding:28px 16px; 
    border-radius: 20px 20px 0 0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const TitleCSS = css`
  margin-bottom: 0;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.title_4_bold_24};
  `}

  ${respondToWidth.s`
    text-align: center;
  `}
`;

const Text = styled(StyledText)<{ $color: string; description?: string }>`
  text-align: center;
  margin-bottom: 40px;
  margin-top: 24px;
  ${({ theme: { typography, colors }, $color, description }) => css`
    color: ${$color ? $color : colors.neutrals[4]};
    ${typography.body_large_semibold_16}

    ${respondToWidth.sm`
    margin-bottom:${description ? '40px' : '20px'};
    ${typography.body_basic_medium_14};
  `}
  `};
`;

const Description = styled(StyledText)<{ $color: string }>`
  text-align: center;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_large_semibold_16}

    ${respondToWidth.sm`
    ${typography.body_basic_medium_14};
  `}

    ${respondToWidth.s`
    margin-bottom: 0px;
  `}
  `};
`;

const Buttons = styled.div<{ $withText?: boolean; isButtonCSS?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ $withText }) => ` ${!$withText && '42px'}`};

  ${respondToWidth.s`
    margin-top: 32px;
    justify-content: ${(props: any) => (props.isButtonCSS ? 'center' : 'space-between')};
    gap: ${(props: any) => (props.isButtonCSS ? '10px' : '15px')};
  `}
`;

const StyledButtonRight = styled(Button)<{ $defaultPadding: boolean; isButtonCSS?: boolean }>`
  white-space: nowrap;
  ${({ $defaultPadding, isButtonCSS }) => css`
    width: ${isButtonCSS ? '250px' : $defaultPadding ? '144px' : '150px'};
    padding-left: ${isButtonCSS ? '50px' : !$defaultPadding ? '31px' : 'initial'};
    padding-right: ${isButtonCSS ? '50px' : !$defaultPadding ? '31px' : 'initial'};
  `}

  &:nth-child(1) {
    margin-right: 24px;
  }

  ${respondToWidth.s`
    width: ${(props: any) => (props.isButtonCSS ? '52%' : '100%')};
    font-size: ${(props: any) => (props.isButtonCSS ? '14px' : 'unset')};
    &:nth-child(1) {
    margin-right: 0;
  }
  `}
`;

const StyledButtonLeft = styled(Button)<{ $defaultPadding: boolean; isButtonCSS?: boolean }>`
  white-space: nowrap;
  ${({ $defaultPadding, isButtonCSS }) => css`
    width: ${isButtonCSS ? '250px' : $defaultPadding ? '144px' : '150px'};
    padding-left: ${isButtonCSS ? '50px' : !$defaultPadding ? '31px' : 'initial'};
    padding-right: ${isButtonCSS ? '50px' : !$defaultPadding ? '31px' : 'initial'};
  `}

  &:nth-child(1) {
    margin-right: 24px;
  }

  ${respondToWidth.s`
    width: ${(props: any) => (props.isButtonCSS ? '50%' : '100%')};
    font-size: ${(props: any) => (props.isButtonCSS ? '14px' : 'unset')};
    &:nth-child(1) {
      margin-right: 0;
    }
  `}
`;
