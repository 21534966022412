import { ScrollbarContainer, StyledText } from 'UI';
import {
  useAsyncAction,
  useClientSize,
  useGetIsMobile,
  useInfinityScroll,
  useIsShowEmptyColumn,
  useNotifications,
  useOnClickOutside,
} from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import EmptyColumn from '../EmptyColumn';
import { Legend, StatisticColumns } from './components';

const EMPTY_COLUMN_TEXT = 'There are no more chapters';

const FlashcardContent = () => {
  const { getIsBreakpoint } = useClientSize();
  const { errorToast } = useNotifications();
  const dispatch = useAppDispatch();

  const [isShowNameTooltip, setIsShowNameTooltip] = useState(false);
  const [columnName, setIsColumnName] = useState('');
  const columnRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useGetIsMobile();

  const flashcardsPacksStatistic = useAppSelector(selectors.performance.selectFlashcardsPacksStatistic);
  const flashcardsPacksStatisticMeta = useAppSelector(selectors.performance.selectFlashcardsPacksStatisticMeta);
  const flashcardsStatistics = useAppSelector(selectors.performance.selectFlashcardsStatistics);

  const [getFlashcardsPacksStatisticAction, isFlashcardsPacksStatisticLoading] = useAsyncAction(
    actions.performance.getFlashcardsPacksStatistic,
  );

  const getFlashcardsPacksStatistic = async (page = 1, limit?: number) => {
    try {
      await getFlashcardsPacksStatisticAction({ page, limit });
    } catch {
      errorToast('Something went wrong');
    }
  };

  const resetTestId = () => {
    dispatch(actions.performance.setCurrentTestId(null));
  };

  useEffect(() => {
    getFlashcardsPacksStatistic();
    resetTestId();
  }, []);

  const nextPage = (flashcardsPacksStatisticMeta?.currentPage || 0) + 1;
  const hasPackFlashcardsMore =
    (flashcardsPacksStatisticMeta?.currentPage || 0) < (flashcardsPacksStatisticMeta?.totalPages || 0);
  const limit = 20;

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getFlashcardsPacksStatistic(nextPage, limit),
    hasAfterMore: hasPackFlashcardsMore,
    isLoading: isFlashcardsPacksStatisticLoading,
    isScrollToInitialPosition: false,
  });

  const isWidthSm = getIsBreakpoint('sm');

  const columnWidth = isWidthSm ? 85 : 112;
  const gapSize = isWidthSm ? 16 : 28;

  const { containerRef, isShowEmptyColumn } = useIsShowEmptyColumn(
    flashcardsPacksStatistic || [],
    columnWidth,
    gapSize,
  );

  const { colors } = useTheme();
  useOnClickOutside(columnRef, () => setIsShowNameTooltip(false));

  const isShowFullName = isShowNameTooltip && isMobile;

  const onColumnPress = (name: string) => {
    setIsColumnName(name);
    setIsShowNameTooltip(true);
  };

  return (
    <Container ref={containerRef}>
      <Wrapper ref={listRef} onScroll={handleScroll}>
        <Cover $gap={gapSize}>
          {flashcardsPacksStatistic?.map((column) => (
            <StatisticColumns
              ref={columnRef}
              onPress={onColumnPress}
              key={column.packId}
              data={column}
              $width={columnWidth}
            />
          ))}
          {isShowEmptyColumn && !isFlashcardsPacksStatisticLoading && <EmptyColumn text={EMPTY_COLUMN_TEXT} />}
          {isFlashcardsPacksStatisticLoading && (
            <LoaderContainer $width={columnWidth}>
              <ThreeDots color={colors.primary[3]} />
            </LoaderContainer>
          )}
        </Cover>
      </Wrapper>
      <LegendWrapper>
        <Legend data={flashcardsStatistics} />
        {isShowFullName && <FullTitle font="footnote_semibold_12">{columnName}</FullTitle>}
      </LegendWrapper>
    </Container>
  );
};

export default FlashcardContent;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 20px;
  gap: 24px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  padding: 8px 24px 32px;
  border-radius: 12px;
  overflow-x: hidden;

  ${respondToWidth.ls`
    grid-template-rows: 1fr 60px;
  `}

  ${respondToWidth.sm`
    width:100%;
    max-height: 471px;
    grid-template-rows: 1fr 140px;
    padding:8px 16px 24px;
  `}
`;

const Cover = styled.div<{ $gap: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  gap: ${({ $gap }) => `${$gap}px`};
  transform: rotateX(180deg);
  padding-top: 12px;
`;

const Wrapper = styled(ScrollbarContainer)`
  width: 100%;
  height: 100%;
  overflow-y: initial;
  overflow-x: scroll;
  transform: rotateX(180deg);

  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    height: 4px;
  }
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: ${({ theme: { colors } }) => colors.primary[1]};
  }
  ::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: ${({ theme: { colors } }) => colors.primary[1]};
  }
  ::-webkit-scrollbar-thumb:horizontal:active {
    background-color: ${({ theme: { colors } }) => colors.primary[1]};
  }
`;

const LoaderContainer = styled.div<{ $width: number }>`
  width: ${({ $width }) => `${$width}px`};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullTitle = styled(StyledText)`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.neutrals[9]};
  border-color: ${({ theme: { colors } }) => colors.neutrals[9]};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  padding: 12px 16px;
  border-radius: 12px;
  position: absolute;
  left: 0;
  top: -50px;
  z-index: 1;
`;

const LegendWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
