import { Button, DefaultModal } from 'UI';
import { PaginationMetaDto } from 'api/generated';
import { useClientSize } from 'hooks';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { FC, useRef } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import ModalContent from './components/ModalContent';

export type DataType = {
  id?: string;
  packId?: string;
  name?: string;
  packName?: string;
  percent: number;
  count: number;
};

type StatisticModalProps = {
  data: DataType[] | null;
  title: string;
  isLeftPosition?: boolean;
  meta: PaginationMetaDto | null;
  isLoading: boolean;
  isColorReverse?: boolean;
  getMoreStatistics: (page: number) => Promise<void>;
  subtitlePath: string | null;
} & BaseModalProps;

const StatisticModal: FC<StatisticModalProps> = ({
  data,
  title,
  onClose,
  isLeftPosition,
  meta,
  isLoading,
  isColorReverse,
  getMoreStatistics,
  subtitlePath,
  ...props
}) => {
  const { getIsBreakpoint } = useClientSize();

  const isWidthLs = getIsBreakpoint('ls');
  const modalRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(modalRef, onClose);

  return (
    <>
      {isWidthLs ? (
        <DefaultModal
          onRequestClose={onClose}
          containerCSS={ContainerCSS}
          showCloseButton={false}
          headerContainerCSS={HeaderContainer}
          modalCSS={ModalCSS}
          {...props}>
          <ModalContent
            meta={meta}
            isLoading={isLoading}
            getMoreStatistics={getMoreStatistics}
            title={title}
            data={data}
            isColorReverse={isColorReverse}
            subtitlePath={subtitlePath}
          />
          <ButtonContainer>
            <Button variant="tertiary" size="small" onClick={onClose}>
              Close
            </Button>
          </ButtonContainer>
        </DefaultModal>
      ) : (
        <Container ref={modalRef} $isLeftPosition={isLeftPosition}>
          <ModalContent
            meta={meta}
            isLoading={isLoading}
            getMoreStatistics={getMoreStatistics}
            title={title}
            data={data}
            isColorReverse={isColorReverse}
            subtitlePath={subtitlePath}
          />
        </Container>
      )}
    </>
  );
};

export default StatisticModal;

const ModalCSS = css`
  width: 100%;
  top: auto;
  bottom: 0;
  transform: translateX(-50%);
`;

const ContainerCSS = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 132px);
  padding: 24px 4px 40px 24px;
  border-radius: 12px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);

  ${respondToWidth.sm`
    border-radius: 12px 12px 0 0;
  `}
`;

const HeaderContainer = css`
  display: none;
`;

const Container = styled.div<{ $isLeftPosition?: boolean }>`
  ${({ $isLeftPosition }) => css`
    width: 400px;
    height: 100%;
    max-height: 480px;

    position: absolute;
    top: 40px;
    z-index: 10;

    ${$isLeftPosition ? 'transform: translate(-160px);' : 'transform: translate(160px);'}
    transition: transform .1s linear;

    display: flex;
    flex-direction: column;
    padding: 24px;
    padding-right: 4px;

    border-radius: 12px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
    background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

    ${respondToWidth.ls`
    display: none;
  `};
  `}
`;

const ButtonContainer = styled.div`
  padding-right: 20px;
  margin-top: 20px;
`;
