import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgLayoutBottomIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.333 4a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H5.667a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h20.666Zm-1 18.333a1 1 0 0 0-1-1H7.667a1 1 0 0 0-1 1V23a1 1 0 0 0 1 1h16.666a1 1 0 0 0 1-1v-.667Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgLayoutBottomIcon);
export default Memo;
