import { ChartLegendItem, StyledText } from 'UI';
import { FlashcardAnswerStatsDto } from 'api/generated';
import React, { FC, useMemo } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type TestsStatisticsProps = {
  className?: string;
};

enum StatisticNameEnum {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
}

type Statistics = { correct: null | FlashcardAnswerStatsDto; incorrect: null | FlashcardAnswerStatsDto };

const TestsStatistics: FC<TestsStatisticsProps> = ({ className }) => {
  const { colors } = useTheme();
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const testsStatistics = useAppSelector(selectors.performance.selectTestsStatistics);

  const statistics = useMemo(() => {
    if (testsStatistics) {
      const statistics: Statistics = {
        correct: testsStatistics.correct,
        incorrect: testsStatistics.incorrect,
      };
      return statistics;
    }
  }, [testsStatistics]);

  return (
    <Container className={className}>
      <Title>Tests</Title>
      <Indicator $isDarkMode={isDarkMode}>
        <Correct $percent={statistics?.[StatisticNameEnum.CORRECT]?.percent || 0} />
        <Incorrect
          $percent={statistics?.[StatisticNameEnum.INCORRECT]?.percent || 0}
          $onlyIncorrect={statistics?.[StatisticNameEnum.CORRECT]?.percent === 0}
        />
      </Indicator>
      <LegendContainer>
        <ChartLegendItem
          title="Correct"
          color={colors.system.green}
          percent={Math.round(statistics?.[StatisticNameEnum.CORRECT]?.percent || 0)}
          amount={Math.round(statistics?.[StatisticNameEnum.CORRECT]?.count || 0)}
        />
        <ChartLegendItem
          title="Incorrect"
          color={colors.system.red}
          percent={Math.round(statistics?.[StatisticNameEnum.INCORRECT]?.percent || 0)}
          amount={Math.round(statistics?.[StatisticNameEnum.INCORRECT]?.count || 0)}
        />
      </LegendContainer>
    </Container>
  );
};

export default TestsStatistics;

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => css`
    ${typography.headline_semibold_18}

    ${respondToWidth.s`
      ${typography.body_large_semibold_16}
    `}
  `}
`;

const Indicator = styled.div<{ $isDarkMode?: boolean }>`
  ${({ theme: { colors }, $isDarkMode }) => css`
    width: 100%;
    height: 24px;
    margin-block: 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: ${$isDarkMode ? colors.neutrals[6] : colors.neutrals[4]};

    ${respondToWidth.s`
      margin-block:10px;
    `};
  `};
`;

const Correct = styled.div<{ $percent: number }>`
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  height: 26px;
  border-radius: 10px/17px;
  position: relative;
  z-index: 2;
  ${({ $percent }) =>
    css`
      display: ${!$percent && 'none'};
      width: ${$percent ? `calc(${$percent}% + 6px)` : 0};
      min-width: ${$percent ? '30px' : 0};
    `};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: calc(100% - 6px);
    border-radius: 13px;
    background-color: ${({ theme: { colors } }) => colors.system.green};
    z-index: 1;
  }
`;
const Incorrect = styled.div<{ $percent: number; $onlyIncorrect: boolean }>`
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  height: 26px;
  border-top-right-radius: 10px 17px;
  border-bottom-right-radius: 10px 17px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  position: relative;

  ${({ $percent, $onlyIncorrect }) =>
    css`
      display: ${!$percent && 'none'};
      width: ${$percent ? `calc(${$percent}% ${$onlyIncorrect ? '+' : '-'} 6px)` : 0};
      min-width: ${$onlyIncorrect ? '30px' : $percent ? '20px' : 0};
      right: ${$onlyIncorrect ? 0 : ' -6px'};
    `};

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    border-radius: 0 13px 13px 0;
    background-color: ${({ theme: { colors } }) => colors.system.red};
    ${({ $onlyIncorrect }) => css`
      left: ${$onlyIncorrect ? 0 : ' -15px'};
      width: ${$onlyIncorrect ? 'calc(100% - 6px)' : 'calc(100% + 9px)'};
      border-radius: ${$onlyIncorrect ? '13px' : '0 13px 13px 0'};
    `}
  }
`;

const LegendContainer = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
  justify-content: space-between;
`;
