import { OutputData } from '@editorjs/editorjs';
import { Button, NoteModal } from 'UI';
import { CreateNoteRequestDto, CustomerChapterNoteResponseDto, NoteResponseDto } from 'api/generated';
import { Loader } from 'components';
import {
  useAsyncAction,
  useGetIsMobile,
  useNoteModalPosition,
  useNoteOnClick,
  useNotifications,
  useTextSelecting,
  useToggle,
} from 'hooks';
import { FC, MouseEvent, useCallback, useState } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { getErrorMessage } from 'utils';

import { Unit } from './components';
import { UNIT_CONTENT_EDITOR_ID } from './components/Unit/Unit';

type BodyProps = {
  isLoading: boolean;
};

export const MODAL_HEIGHT = 296;
export const MODAL_HEIGHT_WITH_PADDING = MODAL_HEIGHT + 80;
const CONTENT_EDITOR_ID = 'chapter-content';
export const BODY_ID = 'body';

const Body: FC<BodyProps> = ({ isLoading }) => {
  const [currentUnitId, setCurrentUnitId] = useState<undefined | string>();
  const [isFetching, setIsFetching] = useState(false);
  const [isShowSelectButton, setIsShowSelectButton] = useState(false);
  const [currentNote, setCurrentNote] = useState<null | NoteResponseDto>(null);

  const { isAndroid } = useGetIsMobile();
  const { errorToast, undoToast } = useNotifications();
  const { isOpen: isNoteModalOpen, close: closeNoteModal, open: openNoteModal } = useToggle();

  const [updateNoteAction, isNoteUpdating] = useAsyncAction(actions.studyGuide.updateNote);
  const [createNoteAction, isNoteCreating] = useAsyncAction(actions.studyGuide.createNote);
  const [deleteNoteAction, isNoteDeleting] = useAsyncAction(actions.studyGuide.deleteNote);
  const notes = useAppSelector(selectors.studyGuide.selectNotes);
  const currentChapter = useAppSelector(selectors.studyGuide.selectCurrentChapter);
  const isSelectingMode = useAppSelector(selectors.studyGuide.selectIsSelectingMode);

  const hasChapterAudio = Boolean(currentChapter?.audioUrl);

  const { onSelectText, selectedTextIndex, blockIndex, clearSelectedTextMeta, selectedText } = useTextSelecting();
  const { calculateModalPosition, isModalAbove, modalPositionY, onMouseDown, setModalPositionY, setIsModalAbove } =
    useNoteModalPosition();

  const handleSelectText = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, unitId?: string) => {
      setIsShowSelectButton(false);

      const clickedElement = e.target as any;

      if (clickedElement.classList.contains('image-tool__image')) {
        return;
      }

      if (!isNoteModalOpen) {
        e.stopPropagation();
        e.preventDefault();
        const containerId = unitId ? `${UNIT_CONTENT_EDITOR_ID}${unitId}` : CONTENT_EDITOR_ID;

        const { isCollapsed, results } = onSelectText(containerId);

        if (results) {
          setCurrentUnitId(unitId);
          calculateModalPosition(isCollapsed, e);
          openNoteModal();
        }
      }
    },
    [onSelectText, isNoteModalOpen],
  );

  const handleDeleteButtonClick = () => {
    if (currentNote) {
      const timeout = setTimeout(() => deleteNote(currentNote.id), 5000);
      undoToast({
        onUndo: () => {
          clearTimeout(timeout);
        },
        timeout: 5000,
        text: 'Note deleted',
      });
    }
    closeModalAndClearNoteMeta();
  };

  const closeModalAndClearNoteMeta = () => {
    setIsShowSelectButton(false);
    closeNoteModal();
    setCurrentNote(null);
    setCurrentUnitId(undefined);
    clearSelectedTextMeta();
  };

  const handleMouseDown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (!isNoteModalOpen) {
      onMouseDown(e);
    }
  };

  const onSubmitNote = async ({ noteText }: { noteText: string }) => {
    setIsFetching(true);
    const body = document.getElementById(BODY_ID);
    const scrollTop = body?.scrollTop || 0;

    if (currentNote && currentNote.noteText !== noteText) {
      await updateNote(currentNote.id, {
        selectedText: currentNote.selectedText,
        noteText: noteText || '',
      } as CreateNoteRequestDto);
    } else if (selectedTextIndex && currentChapter) {
      const currentBlock = getCurrentContentBlock();

      await checkNestedNotes(currentBlock?.id || '');

      await createNote({
        startIndex: selectedTextIndex.startIndex || 0,
        endIndex: selectedTextIndex.endIndex || 0,
        noteText: noteText?.trim() || '',
        selectedText,
        chapterId: currentChapter.id,
        unitId: currentUnitId || '',
        blockId: currentBlock?.id || '',
      });
    }

    setTimeout(() => {
      if (body) body.scrollTop = scrollTop;

      closeModalAndClearNoteMeta();
      setIsFetching(false);
    }, 1000);
  };

  const getCurrentContentBlock = () => {
    const index = blockIndex || 0;

    if (currentUnitId) {
      return (currentChapter?.units?.find((unit) => unit.id === currentUnitId)?.content as OutputData)?.blocks?.[index];
    }
  };

  const checkNestedNotes = async (blockId: string) => {
    const unitNotes = currentChapter?.units.map((unit) => unit.notes || []).flat() || [];
    const notes = currentChapter?.notes?.concat(unitNotes) || [];
    const blockNotes = notes.filter((note) => note.blockId === blockId);

    await deleteNestedNotes(blockNotes);
  };

  const deleteNestedNotes = async (notes: Array<CustomerChapterNoteResponseDto>) => {
    const nestedNotes = notes?.filter(checkIsNodeNested);
    await Promise.all(nestedNotes.map(async (note) => await deleteNote(note.id)));
  };

  const checkIsNodeNested = (note: CustomerChapterNoteResponseDto) => {
    if (selectedTextIndex) {
      const isStartIndexInNoteArea =
        note.startIndex >= selectedTextIndex.startIndex && note.endIndex <= selectedTextIndex.endIndex;

      const isEndIndexInNoteArea =
        note.endIndex >= selectedTextIndex.startIndex && note.endIndex <= selectedTextIndex.endIndex;

      return isStartIndexInNoteArea || isEndIndexInNoteArea;
    } else {
      return false;
    }
  };

  const handleNoteClick = (id: string, isModalAbove: boolean, modalPositionY: number) => {
    const note =
      notes?.find((chapter) => chapter.id === currentChapter?.id)?.notes.find((note) => note.id === id) || null;

    if (note) {
      setCurrentNote(note);

      setIsModalAbove(isModalAbove);
      setModalPositionY(modalPositionY);
      setTimeout(openNoteModal, 100);
    }
  };

  const handleContextMenu = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, unitId?: string) => {
    if (isSelectingMode) {
      e.stopPropagation();
      e.preventDefault();
      setIsShowSelectButton(true);
      setCurrentUnitId(unitId);

      return false;
    }
  };

  const handleTouchEnd = (unitId?: string) => {
    if (!isAndroid) {
      const selection = window?.getSelection?.();
      const text = selection?.toString() || '';

      if (text) {
        setIsShowSelectButton(true);
        setCurrentUnitId(unitId);
      }
    }
  };

  const updateNote = async (noteId: string, params: CreateNoteRequestDto) => {
    try {
      await updateNoteAction({ noteId, body: params });
    } catch (error) {
      errorToast(getErrorMessage(error));
    }
  };

  const createNote = async (params: CreateNoteRequestDto) => {
    try {
      await createNoteAction(params);
    } catch (error) {
      errorToast(getErrorMessage(error));
    }
  };

  const deleteNote = async (noteId: string) => {
    try {
      await deleteNoteAction({ noteId });
    } catch (error) {
      errorToast(getErrorMessage(error));
    }
  };

  useNoteOnClick({ handleNoteClick });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Root>
      {isNoteModalOpen && (
        <NoteModal
          onSubmit={onSubmitNote}
          defaultValue={currentNote?.noteText}
          close={closeModalAndClearNoteMeta}
          onDeleteButtonClick={handleDeleteButtonClick}
          isModalAbove={isModalAbove}
          isSaving={isNoteUpdating || isNoteCreating || isFetching}
          isDeleting={isNoteDeleting}
          style={{ top: modalPositionY }}
        />
      )}
      {isShowSelectButton && isSelectingMode && (
        <SelectButtonContiner $isHightest={isAndroid || hasChapterAudio}>
          <Button
            onTouchStart={(e) =>
              !isAndroid && handleSelectText(e as unknown as MouseEvent<HTMLDivElement>, currentUnitId)
            }
            onClick={(e) => {
              isAndroid && handleSelectText(e as unknown as MouseEvent<HTMLDivElement>, currentUnitId);
            }}
            variant="primary"
            size="middle">
            Add a note
          </Button>
        </SelectButtonContiner>
      )}
      <Wrapper id={currentChapter?.id} $isSelectingMode={isSelectingMode}>
        {currentChapter?.units?.map(
          (unit) =>
            unit && (
              <Unit
                key={unit.id}
                id={unit.id}
                notes={unit.notes}
                content={unit.content}
                onMouseDown={handleMouseDown}
                onContextMenu={handleContextMenu}
                onTouchEnd={handleTouchEnd}
                handleSelectText={handleSelectText}
              />
            ),
        )}
      </Wrapper>
    </Root>
  );
};

export default Body;

const Root = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InheritCSS = css`
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
`;

const NotesCSS = css`
  ${({ theme: { colors } }) => css`
    .selectedText {
      background-color: ${colors.primary[5]};
    }

    .inheritText {
      ${InheritCSS}
    }

    .note {
      display: inline;
      padding: 0 8px;
      background-color: ${colors.primary[5]};
      border-radius: 12px;
      cursor: pointer;
      user-select: none !important;
      ${InheritCSS}
    }

    .note-has-text {
      border: 1px dashed ${colors.primary[2]};
    }
  `}
`;

const Wrapper = styled.div<{ $isSelectingMode: boolean }>`
  justify-self: flex-start;
  padding: 24px;

  ${NotesCSS}

  ${({ theme: { colors }, $isSelectingMode }) =>
    $isSelectingMode
      ? css`
          .ce-block--selected {
            user-select: all !important;
          }
          *:-moz-selection {
            background-color: ${colors.primary[5]};
          }
          *::selection {
            background-color: ${colors.primary[5]};
          }
        `
      : css`
          * {
            user-select: none;
          }
        `}
`;

const SelectButtonContiner = styled.div<{ $isHightest: boolean }>`
  ${({ $isHightest }) => css`
    position: fixed;
    width: 100%;
    padding: 0 16px;
    bottom: ${$isHightest ? 80 : 32}px;
    z-index: 10;
  `}
`;
