import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { selectors, useAppSelector } from 'store';
import { useTheme } from 'styled-components';

import { ButtonSize, IconContainer, StyledBaseButton, StyledLoader, VariantButtonStyle } from './styled';

export type ButtonProps = {
  variant?: VariantButtonStyle;
  isLoading?: boolean;
  iconComponent?: ReactNode;
  size?: ButtonSize;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<ButtonProps> = ({
  children,
  variant = 'primary',
  isLoading = false,
  iconComponent,
  size = 'middle',
  type = 'button',
  ...props
}) => {
  const { colors } = useTheme();
  const { isMobile } = useGetIsMobile();
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  return (
    <StyledBaseButton
      $variant={variant}
      $size={size}
      $isLoading={isLoading}
      $withIcon={Boolean(iconComponent)}
      $isMobile={isMobile}
      $isDarkMode={isDarkMode}
      type={type}
      {...props}>
      {isLoading ? (
        <StyledLoader size={24} color={variant === 'primary' ? colors.neutrals[11] : colors.primary[1]} />
      ) : (
        <>
          {iconComponent && (
            <IconContainer $size={size} $variant={variant} $isDisabled={props.disabled}>
              {iconComponent}
            </IconContainer>
          )}
          {children}
        </>
      )}
    </StyledBaseButton>
  );
};

export default Button;
