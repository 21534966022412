import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getMnemonicCardsPackThunk } from './actions';
import { MnemonicCardsPackSlice, PracticeStorePayload } from './types';

const initialState: MnemonicCardsPackSlice = {
  mnemonicCardsPack: [],
  meta: null,
  currentMnemonicCardsPackId: null,
  packName: null,
};

export const mnemonicCardsSlice = createSlice({
  name: 'mnemonicCards',
  initialState,
  reducers: {
    setMnemonicCardPracticeStore(state, action: PayloadAction<PracticeStorePayload>) {
      const { id, formState } = action.payload;
      state.mnemonicCardsPack = state.mnemonicCardsPack.map((pack) =>
        pack.id === id ? { ...pack, inputState: formState } : pack,
      );
    },
    setMnemonicCardPackId(state, action: PayloadAction<string | null>) {
      state.currentMnemonicCardsPackId = action.payload;
    },
    clearMnemonicCardsPracticeStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getMnemonicCardsPackThunk.fulfilled, (state, { payload }) => {
      const mnemonicCardPack = state.mnemonicCardsPack || [];
      state.currentMnemonicCardsPackId = payload.packId;
      state.mnemonicCardsPack = payload.isFirstFetch
        ? payload.data.items
        : [...mnemonicCardPack, ...payload.data.items];
      state.meta = payload.data.meta;
      state.packName = payload.data.packName;
    });
  },
});

export const actions = {
  ...mnemonicCardsSlice.actions,
  getMnemonicCardsPack: getMnemonicCardsPackThunk,
};

export const { reducer } = mnemonicCardsSlice;
