import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgDonutChartIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.573 2.888c.582-.107 1.094.358 1.094.95v12.495a1 1 0 0 0 1 1h12.496c.59 0 1.056.512.95 1.094C27.971 24.63 22.534 29.333 16 29.333 8.636 29.333 2.667 23.364 2.667 16c0-6.535 4.702-11.972 10.906-13.112Zm3.76-1.074c0-.586.504-1.05 1.082-.958C24.95 1.888 30.11 7.05 31.145 13.584c.09.58-.373 1.083-.959 1.083H18.333a1 1 0 0 1-1-1V1.814Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgDonutChartIcon);
export default Memo;
