import { TechnicalWorksImg } from 'assets/icons';
import React from 'react';
import styled from 'styled-components';

const TechnicalWorks = () => {
  return (
    <Container>
      <Main>
        <TechnicalWorksImg width={'50px'} height={'50px'} />
        <Title>We are in scheduled maintenance.</Title>
        <SubTitle>Sorry for the inconvenience. We expect the maintenance to happen from 01:00AM to 02:30AM</SubTitle>
      </Main>
    </Container>
  );
};

export default TechnicalWorks;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  justify-items: center;
  display: flex;
  background-color: 'black';
`;

export const Main = styled.div`
  width: 55%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
  border: 1px solid black;
  border-radius: 10px;
`;

const Title = styled.h1`
  width: 320px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 30px;
  margin-top: 20px;
`;

const SubTitle = styled.p`
  margin-top: 20px;
  flex-wrap: wrap;
  text-align: center;
  width: 350px;
`;
