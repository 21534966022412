export const widthBreakpoints = {
  xl: 1439,
  xm: 1360,
  x: 1320,
  l: 1279,
  lg: 1212,
  ls: 1023,
  sl: 850,
  sm: 743,
  s: 479,
  xs: 320,
};

export const heightBreakpoints = {
  l: 900,
  lg: 860,
  ls: 835,
  sm: 800,
  s: 770,
  xs: 700,
};
