import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgFullscreenIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20 3h2v6h-2V5h-4V3h4ZM4 3h4v2H4v4H2V3h2Zm16 16v-4h2v6h-6v-2h4ZM4 19h4v2H2v-6h2v4Z" fill={color} />
    </svg>
  );
};

const Memo = memo(SvgFullscreenIcon);
export default Memo;
