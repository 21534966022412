import Button from 'UI/Button';
import api from 'api';
import { useNotifications } from 'hooks';
import { FC, useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import { selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type DeleteAccountModalProps = {
  isLoading?: boolean;
  deleteAccount?: () => Promise<void>;
} & BaseModalProps;

const DeleteAccountModal: FC<DeleteAccountModalProps> = ({ onClose, deleteAccount, isLoading, ...props }) => {
  const closeModal = () => {
    onClose();
  };

  const { boot, show } = useIntercom();
  const { colors } = useTheme();
  const { errorToast } = useNotifications();
  const user = useAppSelector(selectors.user.selectUser);
  const direction = useAppSelector(selectors.direction.selectDirection);

  const openSupport = useCallback(async () => {
    try {
      const { data: userHash } = await api.Customer.customersControllerCreateUserIntercomHash();

      boot({
        // eslint-disable-next-line camelcase
        customAttributes: { custom_attribute_key: 'hi there' },
        company: { companyId: 'trainer', name: 'trainer' },
        actionColor: colors.primary[1],
        backgroundColor: colors.primary[1],
        name: user?.name,
        email: user?.email,
        userId: user?.id,
        userHash,
        utmSource: direction?.name,
        hideDefaultLauncher: true,
      });
      show();
    } catch {
      errorToast('Something went wrong');
    }
  }, [colors, user, direction]);

  const onOpenSupport = () => {
    openSupport();
    onClose();
  };

  return (
    <DefaultModal
      {...props}
      onRequestClose={closeModal}
      title="To delete your account, please contact support at TrainerAcademy.org"
      titleCSS={TitleCSS}
      showCloseButton={true}
      containerCSS={ContainerCSS}
      withTitleMargin={false}
      modalCSS={ModalCSS}
      headerContainerCSS={HeaderContainerCSS}>
      <StyledButton variant="primary" size="middle" type="submit" onClick={onOpenSupport}>
        Contact Support
      </StyledButton>
    </DefaultModal>
  );
};

export default DeleteAccountModal;

const ContainerCSS = css`
  width: 636px;
  padding: 40px 84px;

  display: flex;
  flex-direction: column;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const HeaderContainerCSS = css`
  ${respondToWidth.s`
    justify-content: start;
    `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const TitleCSS = css`
  margin-bottom: 24px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.title_4_bold_24};

  ${respondToWidth.s`
    text-align: start;
    margin-bottom: 26px;
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  ${respondToWidth.s`
    margin-top: auto;
  `}
`;
