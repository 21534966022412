import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgMasterCardV2Icon: FC<SVGIconProps> = (props) => {
  return (
    <svg width={32} height={20} viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M31.691 16.197v-.389h-.1l-.116.267-.115-.267h-.1v.389h.071v-.293l.108.252h.074l.108-.253v.293h.07Zm-.634 0v-.322h.128v-.066h-.327v.066h.128v.322h.07Z"
        fill="#F79410"
      />
      <path d="M20.327 17.862h-8.653V2.138h8.653v15.724Z" fill="#FF5F00" />
      <path
        d="M12.223 10c0-3.19 1.476-6.03 3.776-7.862A9.777 9.777 0 0 0 9.89 0C4.426 0 0 4.477 0 10s4.427 10 9.888 10A9.777 9.777 0 0 0 16 17.862 10.024 10.024 0 0 1 12.222 10Z"
        fill="#EB001B"
      />
      <path
        d="M32 10c0 5.523-4.427 10-9.888 10A9.78 9.78 0 0 1 16 17.862 10.022 10.022 0 0 0 19.777 10c0-3.19-1.477-6.03-3.777-7.862A9.78 9.78 0 0 1 22.112 0C27.572 0 32 4.477 32 10"
        fill="#F79E1B"
      />
    </svg>
  );
};

const Memo = memo(SvgMasterCardV2Icon);
export default Memo;
