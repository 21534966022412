import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgCloseNotificationIcon: FC<SVGIconProps> = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_274_17472)">
        <path
          d="m12 10.586 4.95-4.95 1.415 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636l4.95 4.95Z"
          fill="#9B9B9B"
        />
      </g>
      <defs>
        <clipPath id="clip0_274_17472">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgCloseNotificationIcon);
export default Memo;
