import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgArrowBackIcon: FC<SVGIconProps> = ({ color: propsColor, size = 32, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.437 14.667h15.896v2.666H10.437l6.21 6.21-1.886 1.885L5.333 16l9.428-9.428 1.886 1.885-6.21 6.21Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgArrowBackIcon);
export default Memo;
