import { Button, StyledText } from 'UI';
import { ManualTypeEnum, PaymentDtoTypeEnum } from 'api/generated';
import { UpgradeSubscriptionModal } from 'components';
import React, { FC, useState } from 'react';
import { dayjs } from 'services';
import { selectors, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type Bonuses = {
  className?: string;
};

const Bonuses: FC<Bonuses> = ({ className }) => {
  const [isShowUpgradeModal, setIsShowUpgradeModal] = useState(false);
  const paymentType = useAppSelector(selectors.courses.selectCurrentPaymentType);
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const courseLevel = activeCourse?.currentPayment?.level;
  const notMvpSubscribe = courseLevel && (courseLevel === 'bronze' || courseLevel === 'silver');
  const isTrial = paymentType === PaymentDtoTypeEnum.Free;
  const today = dayjs();
  const day = today.date();

  const handleCheatSheetClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsShowUpgradeModal(true);
  };

  const getOrdinal = (day: number) => {
    if (day % 10 === 1 && day % 100 !== 11) return 'st';
    if (day % 10 === 2 && day % 100 !== 12) return 'nd';
    if (day % 10 === 3 && day % 100 !== 13) return 'rd';
    return 'th';
  };

  const bonusTextBeforePurchase = `Click to see MVP bonuses for ${today.format('MMMM')} ${day}${getOrdinal(day)}`;
  const bonusTextAfterPurchase = 'Click to see your MVP bonuses';
  const bonusText = isTrial || notMvpSubscribe ? bonusTextBeforePurchase : bonusTextAfterPurchase;

  return (
    <Container className={className}>
      <Text onClick={handleCheatSheetClick}>{bonusText}</Text>

      <LinkContainer>
        <Link>
          <StyledButton variant="tertiary" size="small" onClick={handleCheatSheetClick}>
            {isTrial || notMvpSubscribe ? 'Get bonuses' : 'See bonuses'}
          </StyledButton>
        </Link>
      </LinkContainer>
      <UpgradeSubscriptionModal
        isOpen={isShowUpgradeModal}
        isBonusModal={isTrial || notMvpSubscribe ? false : true}
        onClose={() => setIsShowUpgradeModal(false)}
        manualType={
          isTrial || notMvpSubscribe ? ManualTypeEnum.BonusSectionDefault : ManualTypeEnum.BonusSectionUpgrade
        }
      />
    </Container>
  );
};

export default Bonuses;

const Container = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  user-select: none;

  flex-wrap: wrap;
`;

const Text = styled(StyledText)`
  width: 350px;
  ${({ theme: { typography } }) => typography.body_large_bold_16}
  color: ${({ theme: { colors } }) => colors.primary[1]};
  cursor: pointer;
  margin-top: 20px;
  margin-left: 19px;

  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 5px;
  }

  @media (min-width: 1025px) and (max-width: 1140px) {
    width: 220px !important;
    font-size: 14px;
    line-height: 13px;
  }

  @media (max-width: 1025px) {
    ${({ theme: { typography } }) => typography.body_large_bold_16}
  }

  @media (max-width: 810px) {
    font-size: 14px;
  }

  @media (max-width: 743px) {
    ${({ theme: { typography } }) => typography.body_large_bold_16}
  }

  @media (max-width: 620px) {
    font-size: 14px;
  }

  @media (max-width: 540px) {
    font-size: 12px;
    line-height: 10px;
  }

  @media (max-width: 470px) {
    font-size: 12px;
    width: 210px;
    line-height: 13px;
  }
`;

const StyledButton = styled(Button)`
  width: 36%;
  padding-inline: 0;
  padding: 8px 32px;
  opacity: 1 !important;
  background: ${({ theme: { colors } }) => colors.primary[1]} !important;
  color: ${({ theme: { colors } }) => colors.neutrals[11]} !important;

  white-space: nowrap;
  text-transform: capitalize;

  @media (min-width: 1025px) and (max-width: 1140px) {
    padding: 1px 32px;
    font-size: 11px;
  }

  @media (max-width: 810px) and (min-width: 743px) {
    padding: 5px 32px;
  }

  @media (max-width: 743px) {
    padding: 8px 32px;
  }

  @media (max-width: 620px) {
    padding: 5px 32px;
  }

  @media (max-width: 500px) {
    padding: 2px 32px;
    font-size: 11px;
  }

  @media (max-width: 470px) {
    font-size: 10px;
    padding: 0px 32px;
  }
`;

const LinkContainer = styled.div`
  width: 50%;
  margin-top: 25px;
  margin-left: 19px;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px; */

  @media (max-width: 1346px) {
    margin-top: 15px;
  }
`;

const Link = styled.div`
  width: 100%;
  ${respondToWidth.sm`
    max-width: 100%;
  `}
`;
