import { AxiosError } from 'axios';

export const getErrorMessage = (err: unknown, defaultMessage = 'Something went wrong') => {
  const error = err as AxiosError;

  if (Array.isArray(error.message)) {
    return error.message[0];
  } else if (error?.message) {
    return error.message;
  } else {
    return defaultMessage;
  }
};
