import { Button, NavButton, StyledText } from 'UI';
import { routes } from 'constant/routes';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useIsLandscapeOrientation } from 'hooks/useIsLandscapeOrientation';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

export type ControlBarProps = {
  activeIndex: number;
  onNextClick: () => void;
  onBackClick: () => void;
  amount: number;
  className?: string;
};

const ControlBar: FC<ControlBarProps> = ({ activeIndex, onNextClick, onBackClick, amount, className }) => {
  const isLastSlide = activeIndex === amount - 1;
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLandscapeOrientation } = useIsLandscapeOrientation();
  const { isMobile } = useGetIsMobile();
  const isMobileLandscape = isLandscapeOrientation && isMobile;
  const isShowLabel = isLastSlide && !isMobile;

  return (
    <Cover className={className} $isMobileLandscape={isMobileLandscape}>
      <NavButton variant="back" onClick={onBackClick} disabled={activeIndex === 0} isHideText />
      <Counter $isMobileLandscape={isMobileLandscape}>
        <CurrentSlide>{activeIndex + 1}</CurrentSlide>/{amount}
      </Counter>
      <NavButton variant="next" onClick={onNextClick} disabled={isLastSlide} isHideText />
      {isShowLabel && (
        <Label>
          This mnemonic is the last one in the chapter
          <StyledButton
            variant="tertiary"
            size="small"
            onClick={() => navigate(`${routes.mnemonicCardPractice}/${id}`)}>
            Start practice
          </StyledButton>
        </Label>
      )}
    </Cover>
  );
};

export default ControlBar;

const Cover = styled.div<{ $isMobileLandscape?: boolean }>`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-block: 19px;
  gap: 65px;
  margin-top: 20px;
  height: 70px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.sm`
    position: absolute;
    z-index:1;
    bottom:0;
    justify-content: space-between;
    margin-top: 0;
    height: auto;
    padding: 18px 16px;
  `}

  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      height: 40px;
      padding: 4px 16px;
      position: absolute;
      z-index: 1;
      bottom: 0;
      justify-content: space-between;
      margin-top: 0;
    `}
`;

const Counter = styled(StyledText)<{ $isMobileLandscape?: boolean }>`
  position: absolute;
  display: inline;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  ${respondToWidth.sm`
    left:50%;
    transform: translate(-50%, -50%);
  `}

  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      left: 50%;
      transform: translate(-50%, -50%);
    `}

  ${({ theme: { typography } }) => typography.title_4_bold_24}
`;

const CurrentSlide = styled(StyledText)`
  display: inline;
  color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const Label = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  max-width: 490px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: -28px;
  right: 8px;
  transform: translateY(-100%);
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
  padding: 10px 12px 10px 20px;
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[10]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${respondToWidth.sm`
    display:none;
  `}
`;

const StyledButton = styled(Button)`
  width: fit-content;
`;
