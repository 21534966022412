import { Breadcrumbs, StyledText } from 'UI';
import { routes } from 'constant/routes';
import { useGetIsMobile, useIsLandscapeOrientation } from 'hooks';
import { LayoutStyledProps } from 'pages/MnemonicCardPractice/MnemonicCardPractice';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type HeaderProps = {
  name: string;
  total: number;
  activeIndex: number;
};

const Header: FC<HeaderProps> = ({ name, total, activeIndex }) => {
  const { id } = useParams();
  const { isLandscapeOrientation } = useIsLandscapeOrientation();
  const { isMobile } = useGetIsMobile();
  const isMobileLandscape = isLandscapeOrientation && isMobile;

  return (
    <Root $isMobileLandscape={isMobileLandscape}>
      <Breadcrumbs
        title={name}
        pageName="Mnemonic cards"
        routeName={`${routes.mnemonicCards}/${id}`}
        isMnemonic
        isPractice
      />
      <Counter>
        <CurrentSlide>{activeIndex + 1}</CurrentSlide>/{total}
      </Counter>
    </Root>
  );
};

export default Header;

const Root = styled.header<LayoutStyledProps>`
  ${({ theme: { colors }, $isMobileLandscape }) =>
    css`
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline: ${$isMobileLandscape ? '0 16px' : '40px'};
      background-color: ${colors.neutrals[11]};

      ${respondToWidth.sm`
        border-bottom: 1px solid ${colors.neutrals[9]};
        padding-inline: 0 16px;
      `}
    `};
`;

const Counter = styled(StyledText)`
  ${({ theme: { typography } }) => typography.title_4_bold_24};
`;

const CurrentSlide = styled(StyledText)`
  display: inline;
  color: ${({ theme: { colors } }) => colors.primary[1]};
`;
