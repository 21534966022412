import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CustomerDto, PaymentMethodDto } from 'api/generated';

import { actions as AuthActions } from '../auth';
import {
  changeEmailThunk,
  changePasswordThunk,
  confirmEmailThunk,
  deleteAccountThunk,
  deletePaymentMethodThunk,
  getMeThunk,
  patchProfileThunk,
  updateForgottenPasswordThunk,
} from './actions';

interface UserSlice {
  user: CustomerDto | null;
  newEmail: string | null;
}

const initialState: UserSlice = {
  user: null,
  newEmail: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<CustomerDto>) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeThunk.fulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addCase(patchProfileThunk.fulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addCase(AuthActions.signOut, () => initialState)
      .addCase(changeEmailThunk.fulfilled, (state, { payload }) => {
        state.newEmail = payload.email;
      })
      .addCase(confirmEmailThunk.fulfilled, (state, { payload }) => {
        if (state.user) {
          state.user.email = payload.email;
        }
      })
      .addCase(deletePaymentMethodThunk.fulfilled, (state) => {
        if (state.user) {
          state.user.paymentMethod = null as unknown as PaymentMethodDto;
        }
      });
  },
});

export const actions = {
  ...userSlice.actions,
  getMe: getMeThunk,
  patchProfile: patchProfileThunk,
  changePassword: changePasswordThunk,
  updateForgottenPassword: updateForgottenPasswordThunk,
  deleteAccount: deleteAccountThunk,
  changeEmail: changeEmailThunk,
  confirmEmail: confirmEmailThunk,
  deletePaymentMethod: deletePaymentMethodThunk,
};

export const { reducer } = userSlice;
