import { Elements, ExpressCheckoutElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';
import { StripeElement } from 'components';
import { AppConfig } from 'config';
import { useStripeProviderOptions } from 'hooks';
import React, { FC, ReactNode, useEffect } from 'react';

const stripe = loadStripe(AppConfig.STRIPE_PUBLISHABLE_KEY || '');

type StripeProviderProps = {
  children: ReactNode;
  showEmptyState?: boolean;
  setStripe?: (stripe: Stripe | null) => void;
  setElements?: (elements: StripeElements | null) => void;
  amount?: number;
  mode?: 'payment' | 'setup' | 'subscription';
  onConfirmExpressCheckout?: any;
  clientSecret?: string;
};

const StripeProvider: FC<StripeProviderProps> = ({
  children,
  showEmptyState = false,
  setStripe,
  setElements,
  onConfirmExpressCheckout,
  clientSecret,
}) => {
  useEffect(() => {
    const handleMount = async () => {
      setStripe?.(await stripe);
    };

    handleMount();
  }, [stripe]);

  const { options } = useStripeProviderOptions(Boolean(clientSecret));
  const { appearance, fonts, locale } = options;

  if (!clientSecret && !showEmptyState) {
    return null;
  }

  if (!clientSecret) {
    return (
      <Elements
        stripe={stripe}
        key="elements without client secret"
        options={{
          ...{
            mode: 'setup',
            currency: 'usd',
            appearance,
            fonts,
            locale,
          },
        }}>
        {children}
      </Elements>
    );
  }

  const ExpressCheckout = !!onConfirmExpressCheckout && (
    <ExpressCheckoutElement
      onConfirm={onConfirmExpressCheckout}
      options={{
        wallets: { applePay: 'always', googlePay: 'always' },
        layout: { maxColumns: 1, overflow: 'never' },
      }}
      onClick={({ resolve }) => {
        resolve({
          emailRequired: true,
        });
      }}
    />
  );

  return (
    <>
      <Elements stripe={stripe} key={clientSecret} options={{ appearance, locale, fonts, clientSecret }}>
        {ExpressCheckout}
        <StripeElement setElements={setElements}>{children}</StripeElement>
      </Elements>
    </>
  );
};

export default StripeProvider;
