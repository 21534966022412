import { StyledText, Tag } from 'UI';
import { TestModeModal } from 'UI/Modals';
import { ManualTypeEnum } from 'api/generated';
import { InformationIcon } from 'assets/icons';
import { UpgradeSubscriptionModal } from 'components';
import { accessLevels } from 'constant';
import { useClientSize } from 'hooks';
import React, { FC, useState } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { Tooltip } from '../Tooltip';

type TestModeProps = {
  isTutorMode: boolean;
  setIsTutorMode: (data: boolean) => void;
};

enum TestTypeEnum {
  TUTOR = 'Tutor',
  TIMED = 'Timed',
}

const TestMode: FC<TestModeProps> = ({ isTutorMode, setIsTutorMode }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowUpgradeModal, setIsShowUpgradeModal] = useState(false);
  const { getIsBreakpoint } = useClientSize();
  const { colors } = useTheme();

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);

  const { hasAllQuestionsInTest } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  const openModal = () => {
    if (isWidthSm) return setIsShowModal(true);
  };

  const isWidthSm = getIsBreakpoint('sm');

  const handlePressTimedMode = () => {
    if (hasAllQuestionsInTest) {
      setIsTutorMode(false);
    } else {
      setIsShowUpgradeModal(true);
    }
  };

  const tags = [
    { text: TestTypeEnum.TUTOR, selected: isTutorMode, onclick: () => setIsTutorMode(true) },
    { text: TestTypeEnum.TIMED, selected: !isTutorMode, onclick: handlePressTimedMode },
  ];

  return (
    <Root>
      <TitleContainer>
        <Title>Test mode</Title>
        <Tooltip>
          <Button onClick={openModal} type="button">
            <StyledInformationIcon color={colors.neutrals[4]} />
          </Button>
        </Tooltip>
      </TitleContainer>
      <ButtonsContainer>
        {tags.map((tag) => (
          <StyledTag key={tag.text} text={tag.text} selected={tag.selected} onClick={tag.onclick} />
        ))}
      </ButtonsContainer>
      <TestModeModal isOpen={isShowModal} onClose={() => setIsShowModal(false)} />

      <UpgradeSubscriptionModal
        // title="To get access to all questions, purchase silver or gold subscription" //todo
        isOpen={isShowUpgradeModal}
        onClose={() => setIsShowUpgradeModal(false)}
        manualType={ManualTypeEnum.TimedTestUpgrade}
      />
    </Root>
  );
};

export default TestMode;

const StyledInformationIcon = styled(InformationIcon)`
  cursor: pointer;
`;

const StyledTag = styled(Tag)`
  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.body_basic_semibold_14};
    padding: 5px 11px;
  `};
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_large_bold_16};
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 24px 20px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;

  ${respondToWidth.sm`
    border-radius: 0;
    padding:0;
    gap: 16px;
  `}
`;

const Button = styled.button`
  height: 20px;
`;
