import { createSlice } from '@reduxjs/toolkit';
import { CourseShortInfoResponseDto, DirectionDto } from 'api/generated';

import { getCoursesByDirection, getDirectionByUrlThunk } from './actions';

interface UserSlice {
  direction: DirectionDto | null;
  courses: CourseShortInfoResponseDto[] | null;
}

const initialState: UserSlice = {
  direction: null,
  courses: null,
};

export const directionSlice = createSlice({
  name: 'direction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDirectionByUrlThunk.fulfilled, (state, { payload }) => {
        state.direction = payload;
      })
      .addCase(getCoursesByDirection.fulfilled, (state, { payload }) => {
        state.courses = payload;
      });
  },
});

export const actions = {
  ...directionSlice.actions,
  getDirectionByUrl: getDirectionByUrlThunk,
  getCoursesByDirection,
};

export const { reducer } = directionSlice;
