import { ReportModal as ReportModalUI, ResultModal } from 'UI/Modals';
import { CreateReportDtoResourceEnum } from 'api/generated';
import { useAsyncAction } from 'hooks';
import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { actions } from 'store';
import { BaseModalProps } from 'types';

type FormType = {
  content: string;
};

type ReportModalProps = {
  resourceId: string;
  resource: CreateReportDtoResourceEnum;
} & BaseModalProps;

const ReportModal: FC<ReportModalProps> = ({ resource, resourceId, onClose, ...props }) => {
  const [isShowResultModal, setIsShowResultModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);

  const [setReportAction, isLoading] = useAsyncAction(actions.report.setReport);

  const setReport = async (content: string) => {
    if (content.trim())
      try {
        await setReportAction({ content: content.trim(), resource, resourceId });
        setIsSuccess(true);
      } catch (error) {
        setIsSuccess(false);
      } finally {
        methods.reset();
        onClose();
        setIsShowResultModal(true);
      }
  };

  const onSubmit = (data: FormType) => {
    setReport(data.content);
  };

  const methods = useForm<FormType>({
    defaultValues: { content: '' },
    mode: 'onChange',
  });

  return (
    <FormProvider {...methods}>
      <ReportModalUI {...props} onClose={onClose} onSubmit={onSubmit} isLoading={isLoading} />
      <ResultModal
        isOpen={isShowResultModal}
        onClose={() => setIsShowResultModal(false)}
        variant={isSuccess ? 'success' : 'error'}
        text={isSuccess ? '' : 'Try again later'}
        title={isSuccess ? 'The report has been sent successfully' : 'Sending error'}
      />
    </FormProvider>
  );
};

export default ReportModal;
