import React, { FC } from 'react';
import styled from 'styled-components';

import StyledText from '../../../../../../UI/StyledText';
import { respondToWidth } from '../../../../../../styles/general/respondTo';

interface SuccessSendFormProps {
  courseName: string;
}

const SuccessSendForm: FC<SuccessSendFormProps> = ({ courseName }) => {
  return (
    <div>
      <Title>Your Free Trial of the {courseName} Study System is on its way!</Title>
      <Description>
        Make sure to check your email within the next minute. Good luck with all the studying. 🤓
      </Description>
    </div>
  );
};

export default SuccessSendForm;

const Title = styled(StyledText)`
  text-align: center;
  margin-top: 12px;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  ${({ theme: { typography } }) => typography.title_2_bold_32};

  ${({ theme: { typography } }) => respondToWidth.sm`
  ${typography.title_5_bold_20};
  margin-top: 0px;
  `};
`;
const Description = styled(StyledText)`
  text-align: center;
  margin-top: 24px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_large_medium_16};
`;
