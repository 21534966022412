import { BreadcrumbsProps } from 'UI/Breadcrumbs/Breadcrumbs';
import Sidebar from 'components/Sidebar';
import { useClientSize } from 'hooks';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { Header } from './components';

type LightLayoutProps = {
  children?: ReactNode;
  className?: string;
  breadcrumbsProps: BreadcrumbsProps;
};

const LightLayout: FC<LightLayoutProps> = ({ children, className, breadcrumbsProps }) => {
  const { getIsBreakpoint } = useClientSize();

  const isWidthSm = getIsBreakpoint('sm');

  return (
    <Root>
      {isWidthSm && <StyledSidebar />}
      <Header breadcrumbsProps={breadcrumbsProps} />
      <Main className={className}>{children}</Main>
    </Root>
  );
};

export default LightLayout;

const Root = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: grid;
  overflow: hidden;

  grid-template-rows: auto 1fr;

  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};

  ${respondToWidth.sm`
    grid-template-rows: auto auto 1fr;
  `}
`;

const StyledSidebar = styled(Sidebar)`
  height: fit-content;
  z-index: 10;
`;

const Main = styled.main`
  width: calc(100% + 4px);
  max-width: calc(1440px);
  height: 100%;

  margin-right: -4px;
  overflow: hidden;
  padding: 32px 40px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  ${respondToWidth.ls`
      padding: 24px;
  `}

  ${respondToWidth.sm`
      padding: 0 16px 16px;
  `}
`;
