import StyledText from 'UI/StyledText';
import { ArrowDown } from 'assets/icons';
import React, { ButtonHTMLAttributes, FC } from 'react';
import styled, { useTheme } from 'styled-components';

type MoreButtonProps = {
  text: string;
  isShowAll: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const MoreButton: FC<MoreButtonProps> = ({ text, isShowAll, ...props }) => {
  const { colors } = useTheme();

  return (
    <Root {...props}>
      <ArrowIcon color={colors.primary[1]} width={24} height={24} $isActive={isShowAll} />
      <Text font="body_large_bold_16">{text}</Text>
    </Root>
  );
};

export default MoreButton;

const Root = styled.button`
  width: 100%;

  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  background: none !important;
  border: none !important;
  outline: none !important;
`;

const ArrowIcon = styled(ArrowDown)<{ $isActive: boolean }>`
  transform: rotate(${({ $isActive }) => ($isActive ? 180 : 0)}deg);
`;

const Text = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.primary[1]};
`;
