import axios, { CancelToken as AxiosCancelToken, AxiosError, AxiosRequestConfig } from 'axios';
import { AppConfig } from 'config';
import { actions, store } from 'store';

export type CancelToken = AxiosCancelToken;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RequestError<T = { message: string; statusCode: number; error: string }> = AxiosError<T>;
export type RequestConfig = AxiosRequestConfig & { useToken?: boolean };

const httpClient = axios.create({
  baseURL: AppConfig.BASE_API_URL,
  responseType: 'json',
  validateStatus: (status) => status <= 500,
  timeout: 20000,
});

httpClient.interceptors.request.use(async (config: RequestConfig) => {
  const accessToken = await store.getState().auth.accessToken;

  if (config.useToken === false || !accessToken) {
    return config;
  } else {
    const headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return { ...config, headers };
  }
});

httpClient.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      store.dispatch(actions.auth.signOut());
    }

    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch(actions.auth.signOut());
      } else {
        throw error;
      }

      throw error.response.data;
    }

    if (error.data) {
      throw error.data;
    }

    throw error;
  },
);

export const createSourceCancelToken = () => {
  return axios.CancelToken.source();
};

export default httpClient;
