import { Button } from 'UI';
import { ManualTypeEnum } from 'api/generated';
import { Loader, UpgradeSubscriptionModal } from 'components';
import { accessLevels } from 'constant';
import { useToggle } from 'hooks';
import React, { FC } from 'react';
import { selectors, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { Header, SubjectsList, TabsHeader } from './components';

type SubjectsType = {
  isShowContainer: boolean;
  isDomainsQuestionsLoading: boolean;
  setIsShowContainer: (prev: boolean) => void;
  isTutorMode: boolean;
};

const Subjects: FC<SubjectsType> = ({
  isShowContainer,
  isDomainsQuestionsLoading,
  setIsShowContainer,
  isTutorMode,
}) => {
  const {
    isOpen: isUpgradeSubscriptionModalOpen,
    close: closeUpgradeSubscriptionModal,
    open: openUpgradeSubscriptionModal,
  } = useToggle();

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);

  const { hasAllQuestionsInTest } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  return (
    <Root>
      <Header
        openUpgradeSubscriptionModal={openUpgradeSubscriptionModal}
        isShowContainer={isShowContainer}
        onIconClick={() => setIsShowContainer(!isShowContainer)}
      />

      <TabsHeader isTutorMode={isTutorMode} openUpgradeSubscriptionModal={openUpgradeSubscriptionModal} />

      <SubjectsContainer $isShow={!isShowContainer}>
        <ButtonsContainer>
          {!hasAllQuestionsInTest && (
            <Button size="small" onClick={openUpgradeSubscriptionModal} type="button">
              Upgrade
            </Button>
          )}
        </ButtonsContainer>

        {isDomainsQuestionsLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <SubjectsList isTutor={isTutorMode} onOpenUpgradeModal={openUpgradeSubscriptionModal} />
        )}
      </SubjectsContainer>

      <UpgradeSubscriptionModal
        isOpen={isUpgradeSubscriptionModalOpen}
        onClose={closeUpgradeSubscriptionModal}
        manualType={ManualTypeEnum.TestsPageUpgrade}
      />
    </Root>
  );
};

export default Subjects;

const Root = styled.div`
  width: 100%;
  height: calc(100vh - 31px);
  padding-block: 24px;
  background: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
  overflow: hidden;
  flex-grow: 1;

  ${respondToWidth.sm`
    border-radius: 0;
    padding-block: 0;
  `}
`;

const SubjectsContainer = styled.div<{ $isShow: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100% - 100px);
  overflow-y: scroll;
  padding-inline: 24px;

  &::-webkit-scrollbar {
    width: 8px;

    ${respondToWidth.sm`
      width: 4px;
  `}
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { colors } }) => colors.neutrals[8]};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  ${({ $isShow }) => respondToWidth.sm` 
      display:${$isShow ? 'flex' : 'none'}
  `};

  ${respondToWidth.sm`
    height: calc(100vh - 267px);
    flex-direction: column;
    padding-top: 20px;
    padding-inline: 16px 12px;
  `}
`;

const LoaderContainer = styled.div`
  width: 100%;
  margin-top: 48px;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: none;
  gap: 16px;
  margin-bottom: 16px;

  ${respondToWidth.sm`
      display: flex;
  `}
  button {
    width: fit-content;
    white-space: nowrap;
  }
`;
