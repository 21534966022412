import { Button, StyledText } from 'UI';
import { useGetIsMobile, useIsLandscapeOrientation } from 'hooks';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { Content } from '../Content';

type QuestionProps = {
  image?: { url: string; alt: string } | null;
  text: string;
  callback: (data: boolean) => void;
};

const Question: FC<QuestionProps> = ({ image, text, callback }) => {
  const [isShow, setIsShow] = useState(false);
  const questionRef = useRef<HTMLDivElement | null>(null);
  const [rootHeight, setRootHeight] = useState('auto');

  useEffect(() => {
    const countParagraphs = () => {
      return text.split(/\n+/).length;
    };

    const updateRootHeightBasedOnText = () => {
      const paragraphsCount = countParagraphs();
      if (paragraphsCount >= 8 || text?.length >= 450) {
        setRootHeight('100%');
      } else {
        setRootHeight('auto');
      }
    };

    updateRootHeightBasedOnText();
  }, [text]);

  const scrollToQuestion = () => {
    questionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(scrollToQuestion, []);

  useEffect(() => setIsShow(true), []);

  const { isMobile } = useGetIsMobile();
  const { isLandscapeOrientation } = useIsLandscapeOrientation();

  const isMobileLandscape = isMobile && isLandscapeOrientation;

  return (
    <Root style={{ height: rootHeight }} $isMobileLandscape={isMobileLandscape} $isShow={isShow} ref={questionRef}>
      <Title>Question</Title>
      <ContentContainer $isShow={isShow}>
        <Content text={text} image={image} />
      </ContentContainer>
      <StyledButton variant="primary" onClick={() => callback(true)}>
        Show answer
      </StyledButton>
    </Root>
  );
};

export default Question;

const StyledButton = styled(Button)`
  width: 154px;
  padding: 8px 32px;
  white-space: nowrap;
  margin-top: 20px;
  ${({ theme: { typography } }) => typography.body_basic_bold_14};
`;

const ContentContainer = styled.div<{ $isShow: boolean }>`
  margin: 34px 0;
  ${({ $isShow }) =>
    $isShow
      ? css`
          min-height: auto;
          opacity: 1;
        `
      : css`
          width: 0;
          min-height: 0;
          opacity: 0;
        `};

  transition: all 1.5s ease;

  ${respondToWidth.sm`
    min-height: auto;
    width:100%;
    margin: 24px 0 40px 0;
  `}
`;

const Title = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.body_basic_semibold_14};
  `}
`;

const Root = styled.div<{ $isShow: boolean; $isMobileLandscape: boolean }>`
  display: flex;
  padding: 52px;
  width: 0;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      position: absolute;
      top: 0;
    `}

  ${({ $isShow }) =>
    $isShow
      ? css`
          min-width: 590px;
          min-height: 480px;
          opacity: 1;
          transform: scale(1);
        `
      : css`
          width: 0;
          min-height: 0;
          opacity: 0;
          transform: scale(0);
        `};

  transition: transform 1s ease;

  ${({ $isShow }) => respondToWidth.sm`
  min-width: ${$isShow ? 'calc(100vw - 32px)' : '0'};
  min-height: ${$isShow ? 'auto' : '0'};
  padding: 24px 24px 32px;
`}

  ${({ $isShow }) => respondToWidth.s`
    width: ${$isShow ? 'calc(100vw - 32px)' : '0'};
  `}
`;
