import { Button, StyledText } from 'UI';
import React, { FC } from 'react';
import styled from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';

import logo from '../../../../assets/images/performancePlug.png';

type PerformancePlugProps = {
  onClick: () => void;
  className?: string;
};

const PerformancePlug: FC<PerformancePlugProps> = ({ onClick, className }) => {
  return (
    <Container className={className}>
      <ImageContainer>
        <img src={logo} alt="MVP subscription benefits" />
      </ImageContainer>
      <Title>Progress and performance</Title>
      <Description>
        Progress and performance are not available in Rookie and All-Star subscription. You can upgrade your
        subscription to MVP. Also, on a MVP subscription, you will get a cheatsheet for more effectively learning!
      </Description>
      <StyledButton variant="primary" onClick={onClick}>
        Upgrade to MVP
      </StyledButton>
    </Container>
  );
};

export default PerformancePlug;

const Container = styled.div`
  width: 100%;
  max-width: 799px;
  height: 100%;
  max-height: calc(100vh - 336px);
  border-radius: 12px;
  padding: 18px 70px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.lg`
   padding: 18px 30px; 
  `}

  ${respondToHeight.lg`
    max-height: 564px;
    margin-top: 16px;
  `}

  ${respondToWidth.sm`
    padding: 32px 24px;
    margin-block: 16px;
    max-height: initial;
  `}
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 660px;
  height: 100%;
  max-height: 336px;
  display: grid;
  place-items: center;
  margin-bottom: 24px;
  position: relative;

  ${respondToWidth.lg`
    justify-content: space-between;
    margin-bottom: 0px;
  `};

  ${respondToWidth.sm`
      margin-bottom: 20px;
      width: calc(100% + 48px);
      padding-inline: 6px;
      height:auto;
   `}
`;

const Title = styled(StyledText)`
  margin-bottom: 8px;
  ${({ theme: { typography } }) => typography.title_4_medium_24};

  ${respondToWidth.sm`
      margin-bottom: 16px;
   `}
`;

const Description = styled(StyledText)`
  width: 100%;
  max-width: 473px;
  margin-bottom: 16px;
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${respondToWidth.sm`
      margin-bottom: 24px;
   `}
`;

const StyledButton = styled(Button)`
  width: 205px;

  ${respondToWidth.s`
    width:100%;
  `}
`;
