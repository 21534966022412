import { DefaultCalendar } from 'UI';
import { dateFormats } from 'constant';
import { useAsyncAction, useOnClickOutside } from 'hooks';
import React, { FC, ReactNode, useRef, useState } from 'react';
import { dayjs } from 'services';
import { actions } from 'store';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

const yearsCountToShow = 9;

type RenderProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type CalendarInvokeButtonProps = {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  children: (props: RenderProps) => ReactNode;
};

const CalendarInvokeButton: FC<CalendarInvokeButtonProps> = ({ children, startDate, setStartDate }) => {
  const [setExamDate] = useAsyncAction(actions.courses.setActiveCourseExamDate);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const today = dayjs();
  const currentYear = dayjs().year();
  const calendarRef = useRef<HTMLDivElement>(null);
  /**
   * The additional ref needed, because of an incorrect show/hide behaviour
   * with the useOnClickOutside hook
   */
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const years: string[] = [];

  /**
   * Show only 10 years include current year in the years droplist
   */
  for (let i = 0; i <= yearsCountToShow; i++) {
    years.push(String(currentYear + i));
  }

  const handleCalendarToggle = () => {
    setCalendarOpen((prev) => !prev);
  };

  const handleCalendarClose = () => {
    setCalendarOpen(false);
  };

  const handleChangeDate = (date: Date | null) => {
    const targetDate = dayjs(date);
    const diff = targetDate.startOf('day').diff(today.startOf('day'), 'day');
    const hasDiff = diff && diff > 0;

    if (hasDiff) {
      const formattedDate = dayjs(date).format(dateFormats.examDate);

      setExamDate({ examDate: formattedDate });
    } else {
      /**
       * This is the way to reset the exam day
       * */
      setExamDate({ examDate: today.format(dateFormats.examDate) });
    }

    setStartDate(date);
  };

  useOnClickOutside(calendarRef, handleCalendarClose, buttonContainerRef);

  /**
   * Uses the custom invoke calendar button instead of a date input. By this reason
   * the customInput is an empty fragment
   */
  return (
    <Container ref={buttonContainerRef}>
      {children({
        onClick: handleCalendarToggle,
      })}
      {isCalendarOpen && (
        <div ref={calendarRef}>
          <DefaultCalendar
            years={years}
            open={isCalendarOpen}
            selected={startDate}
            onChange={handleChangeDate}
            customInput={<></>}
            showPopperArrow={false}
            startOpen
          />
        </div>
      )}
    </Container>
  );
};

export default CalendarInvokeButton;

const Container = styled.div`
  position: relative;
  width: 270px;
  max-width: 270px;
  height: 38px;

  ${respondToWidth.sm`
    width: 100%;
    max-width: 100%;
    margin-top: 16px;
  `};
`;
