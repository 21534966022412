import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { CustomerAppSettingsResponseDto } from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType } from 'types';

export const getCustomerSettingsThunk = createAsyncThunk<
  CustomerAppSettingsResponseDto,
  undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('settings/getCustomerSettings', async (_, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Settings.customerAppSettingsControllerGetSettings();

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const toggleCustomerStreakThunk = createAsyncThunk<undefined, void, ExtraParamsThunkType<DefaultRejectValue>>(
  'settings/patchCustomerStreak',
  async (_, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Settings.customerAppSettingsControllerToggleShowStreaks();

      if (status > 399) {
        throw data;
      }

      return undefined;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const toggleCustomerSoundThunk = createAsyncThunk<undefined, void, ExtraParamsThunkType<DefaultRejectValue>>(
  'settings/patchCustomerSound',
  async (_, { rejectWithValue }) => {
    try {
      const { data, status } = await api.Settings.customerAppSettingsControllerToggleTurnOnSoundsInApp();

      if (status > 399) {
        throw data;
      }

      return undefined;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);
