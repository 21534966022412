import { Stripe, StripeElements } from '@stripe/stripe-js';
import api from 'api';
import { AppConfig } from 'config';
import { routes } from 'constant/routes';
import useAsyncAction from 'hooks/useAsyncAction';
import { useStripeSocket } from 'hooks/useStripeSocket';
import { FormEvent, useEffect, useState } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import { setErrorStripe } from 'utils';

interface CustomFormEvent<T = Element> extends FormEvent<T> {
  elementType?: string;
}

const returnUrl = new URL(routes.addNewCourse, AppConfig.BASE_API_URL || '');

export const useStripeSetupPaymentMethodForm = () => {
  const [elements, setElements] = useState<StripeElements | null>(null);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState('');
  const [getMe] = useAsyncAction(actions.user.getMe);

  const token = useAppSelector(selectors.auth.selectAccessToken);
  const user = useAppSelector(selectors.user.selectUser);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { onPaymentMethodChange } = useStripeSocket({
    errorMessage,
  });

  const onGetClientSecret = async () => {
    const { data } = await api.Stripe.stripeControllerCustomerSetupIntentSecret();

    setClientSecret(data.clientSecret || '');
  };

  const getUserInfo = async () => {
    try {
      await getMe();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e?: CustomFormEvent<HTMLFormElement>) => {
    if (e?.elementType !== 'expressCheckout') {
      e?.preventDefault();
    }

    setIsLoading(true);

    if (!stripe || !elements || !clientSecret) return;

    const params = {
      elements,
      clientSecret: clientSecret,
      redirect: 'if_required' as const,
      confirmParams: {
        return_url: returnUrl.href,
      },
    };

    try {
      setIsLoading(true);

      await elements.submit();

      await stripe
        .confirmSetup(params)
        .then(async (result) => {
          const cashAppError = result?.setupIntent?.last_setup_error?.message;
          const stripeError = result.error;

          if (cashAppError) {
            setErrorStripe({
              cashAppError: cashAppError,
              setErrorMessage,
            });

            setIsSuccess(true);
          } else if (stripeError) {
            setErrorStripe({
              error: stripeError,
              setErrorMessage,
            });

            setIsSuccess(true);
          } else {
            setIsSuccess(true);
          }

          if (token && !cashAppError && result.error) onPaymentMethodChange();
        })
        .catch((error) => {
          setErrorStripe({
            error: error,
            setErrorMessage,
          });

          setIsSuccess(false);
        });
      if (!user?.paymentMethod) {
        getUserInfo();
      }

      setIsLoading(false);
    } catch (error: any) {
      setErrorStripe({
        error: error,
        setErrorMessage,
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetClientSecret();
  }, []);

  const isDisabled = !stripe || !clientSecret;

  return {
    elements,
    setElements,
    stripe,
    setStripe,
    clientSecret,
    handleSubmit,
    errorMessage,
    isLoading,
    isSuccess,
    isDisabled,
  };
};

export default useStripeSetupPaymentMethodForm;
