import { Button } from 'UI';
import { NotFoundPageIcon } from 'assets/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

const NotFoundPage = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  return (
    <Root>
      <StyledNotFoundPageIcon color={colors.primary[1]} />
      <StyledButton onClick={() => navigate(-1)} variant="secondary">
        Go to previous page
      </StyledButton>
    </Root>
  );
};

export default NotFoundPage;

const Root = styled.div`
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 78px;
  padding: 0 16px;

  ${respondToWidth.sm`
    gap: 40px;
  `}
  ${respondToWidth.s`
    gap: 24px;
  `}
`;

const StyledNotFoundPageIcon = styled(NotFoundPageIcon)`
  width: 100%;
  max-width: 630px;
  height: fit-content;
`;

const StyledButton = styled(Button)`
  ${({ theme: { typography } }) => css`
    max-width: 627px;

    padding-block: 28px;

    border-width: 3px;
    border-radius: 32px;

    ${typography.title_2_bold_32}
    font-weight: 600;

    ${respondToWidth.sm`
    
    border-width: 1px;
    padding-block: 12px;
    border-radius: 12px;

    ${typography.body_large_bold_16};
  `}
  `}
`;
