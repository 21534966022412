import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const CalendarIcon: FC<SVGIconProps> = ({ color: propsColor, size = 20, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];

  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.1667 1.72947H16.5001C16.7211 1.72947 16.9331 1.81727 17.0893 1.97355C17.2456 2.12983 17.3334 2.34179 17.3334 2.5628V15.8961C17.3334 16.1171 17.2456 16.3291 17.0893 16.4854C16.9331 16.6417 16.7211 16.7295 16.5001 16.7295H1.50008C1.27907 16.7295 1.06711 16.6417 0.910826 16.4854C0.754545 16.3291 0.666748 16.1171 0.666748 15.8961V2.5628C0.666748 2.34179 0.754545 2.12983 0.910826 1.97355C1.06711 1.81727 1.27907 1.72947 1.50008 1.72947H4.83341V0.0628052H6.50008V1.72947H11.5001V0.0628052H13.1667V1.72947ZM11.5001 3.39614H6.50008V5.0628H4.83341V3.39614H2.33341V6.72947H15.6667V3.39614H13.1667V5.0628H11.5001V3.39614ZM15.6667 8.39614H2.33341V15.0628H15.6667V8.39614Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(CalendarIcon);
export default Memo;
