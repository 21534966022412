import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import { ErrorBigIcon, SuccessIcon } from 'assets/icons';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type VariantResultModal = 'success' | 'error';

type ResultModalProps = {
  title: string;
  text?: string;
  variant: VariantResultModal;
  leftButton?: boolean;
  onLeftButtonClick?: () => void;
} & BaseModalProps;

const ResultModal: FC<ResultModalProps> = ({
  title,
  text,
  variant,
  leftButton = false,
  onClose,
  onLeftButtonClick,
  ...props
}) => {
  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      showCloseButton={false}
      modalCSS={ModalCSS}
      headerContainerCSS={HeaderContainerCSS}>
      <Wrapper $withUnderText={Boolean(text)}>
        {variant === 'success' ? <SuccessIcon /> : <ErrorBigIcon />}
        <div>
          <Title $withUnderText={Boolean(text)}>{title}</Title>
          {text && <Text>{text}</Text>}
        </div>
      </Wrapper>
      <Buttons>
        {leftButton && (
          <StyledButton variant="primary" size="middle" onClick={onLeftButtonClick}>
            Try again
          </StyledButton>
        )}
        <CloseButton variant="secondary" size="middle" onClick={onClose}>
          Close
        </CloseButton>
      </Buttons>
    </DefaultModal>
  );
};

export default ResultModal;

const ContainerCSS = css`
  width: 628px;
  padding: 40px 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    padding:32px 16px; 
    border-radius: 20px 20px 0 0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const HeaderContainerCSS = css`
  display: none;
`;

const Wrapper = styled.div<{ $withUnderText: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${({ $withUnderText }) => !$withUnderText && '12px'};

  ${respondToWidth.s`
    flex-direction: column-reverse;
    margin-bottom: 4px;
  `}
`;

const Title = styled(StyledText)<{ $withUnderText: boolean }>`
  margin-top: 12px;
  text-align: center;

  ${({ theme: { typography, colors }, $withUnderText }) => css`
    margin-bottom: ${$withUnderText ? '24px' : '40px'};
    color: ${colors.neutrals[1]};
    ${typography.title_4_bold_24};

    ${respondToWidth.s`
    margin-bottom: ${$withUnderText ? '12px' : '4px'};
    margin-top: 0;
    ${typography.title_5_bold_20};
  `}
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 40px;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_large_semibold_16}
  `};

  ${respondToWidth.s`
    margin-bottom: 4px;
  `}
`;

const StyledButton = styled(Button)`
  width: 144px;

  margin-right: 24px;
  padding: 11px 35px;
  height: auto;

  ${respondToWidth.s`
    margin-right: 15px;
  `}

  ${respondToWidth.s`
    width:100%;
  `}
`;

const CloseButton = styled(Button)`
  width: 144px;

  ${respondToWidth.s`
    width:100%;
  `}
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
