import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import { CouponDiscountType, CouponSubscriptionDurationType, SubscribeDtoTypeEnum } from 'api/generated';

export type PaginationMetaType = {
  count: number;
  total: number;
  page: number;
  pageCount: number;
  pageSize: number;
};

export type CourseOptionsForPayment = {
  courseId: string;
  subscribeType: SubscribeDtoTypeEnum;
  term: TermEnum;
  amount: number;
  oldAmount?: number;
  isUpdate: boolean;
  isDowngrade?: boolean;
  couponCode?: string;
  discount?: number | undefined;
  discountType?: CouponDiscountType | undefined;
  duration?: CouponSubscriptionDurationType | undefined;
  durationInMonth?: number | undefined;
};

export enum UnsubscribeSteps {
  STEP_1,
  STEP_2,
  STEP_3,
  STEP_4,
  STEP_5,
  STEP_6,
  STEP_7,
}
