import { StyledText, Tag } from 'UI';
import { PerformanceEllipse } from 'assets/icons';
import { useCircleChartSize } from 'hooks';
import React, { FC, useEffect, useMemo } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { selectors, useAppSelector } from 'store';
import { AnswerEnum, StatisticsVariants } from 'store/ducks/performance/types';
import styled, { css, useTheme } from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';
import { StatisticValue } from 'types';

import Label from './components/Label';
import Legend from './components/Legend';

type CircleChartProps = {
  data: StatisticValue[];
  type: StatisticsVariants;
  title: string;
  total: number;
  isInactiveAll: boolean;
  isOpenStatisticsModal: boolean;
  answerType: AnswerEnum | null;
  setAnswerType: (path: AnswerEnum | null) => void;
  setIsInactiveAll: (value: boolean) => void;
  openStatisticsModal: (type: StatisticsVariants, answerType: AnswerEnum) => void;
  tags?: { text: string; onSelect: () => void; isSelected: boolean }[];
};

const CircleChart: FC<CircleChartProps> = ({
  data,
  type,
  isInactiveAll,
  title,
  tags,
  total,
  isOpenStatisticsModal,
  setIsInactiveAll,
  openStatisticsModal,
  setAnswerType,
  answerType,
}) => {
  const { colors } = useTheme();
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  const colorMapping = useMemo(
    () => ({
      [AnswerEnum.CORRECT]: colors.system.green,
      [AnswerEnum.INCORRECT]: colors.system.red,
      [AnswerEnum.UNUSED]: isDarkMode ? colors.neutrals[9] : colors.neutrals[4],
      [AnswerEnum.YES]: colors.system.green,
      [AnswerEnum.NO]: colors.system.red,
      [AnswerEnum.KINDA]: colors.system.blue,
      [AnswerEnum.INACTIVE]: colors.neutrals[7],
    }),
    [isDarkMode, colors],
  );

  const labelColorMapping = useMemo(
    () => ({
      [AnswerEnum.CORRECT]: colors.system.green,
      [AnswerEnum.INCORRECT]: colors.system.red,
      [AnswerEnum.UNUSED]: isDarkMode ? colors.neutrals[6] : colors.neutrals[4],
      [AnswerEnum.YES]: colors.system.green,
      [AnswerEnum.NO]: colors.system.red,
      [AnswerEnum.KINDA]: colors.system.blue,
      [AnswerEnum.INACTIVE]: colors.neutrals[7],
    }),
    [isDarkMode, colors],
  );

  const isTest = type === StatisticsVariants.TESTS;

  const handleClick = (name: AnswerEnum) => {
    setTimeout(() => {
      setAnswerType(name);
      openStatisticsModal(type, name);
      setIsInactiveAll(true);
    }, 0);
  };

  const onCloseModal = () => {
    if (!isOpenStatisticsModal) {
      setIsInactiveAll(false);
      setAnswerType(null);
    }
  };

  const getColor = (name: AnswerEnum) => {
    if (answerType === name) {
      return colorMapping[name];
    }
    if (isInactiveAll) {
      return colorMapping[AnswerEnum.INACTIVE];
    }
    return colorMapping[name];
  };

  const { containerSize, innerRadiusSize, outerRadiusSize } = useCircleChartSize();

  const handleCellMouseDown = (e: unknown, name: AnswerEnum) => {
    if (isOpenStatisticsModal) {
      (e as Event).stopPropagation();
    }
    handleClick(name);
  };

  useEffect(onCloseModal, [isOpenStatisticsModal]);

  const isOneValue = useMemo(() => data.filter((value) => Boolean(value.percent)).length === 1, [data]);
  const paddingAngle = isOneValue ? 0 : 5;

  return (
    <Container>
      <Wrapper>
        <Ellipse color={isDarkMode ? colors.neutrals[8] : colors.neutrals[10]} />
        <StyledPieChart width={containerSize} height={containerSize}>
          <Pie
            data={data}
            innerRadius={innerRadiusSize}
            outerRadius={outerRadiusSize}
            cornerRadius={20}
            paddingAngle={paddingAngle}
            blendStroke={isOneValue}
            strokeLinejoin="round"
            isAnimationActive={false}
            dataKey="count"
            valueKey="percent"
            labelLine={false}
            minAngle={30}
            label={(e) =>
              Label({
                ...e,
                colorMapping: labelColorMapping,
                isInactiveAll,
                answerType,
                isOpenStatisticsModal,
                onClick: handleClick,
                textFill: colors.neutrals[1],
                circleFill: colors.neutrals[11],
              })
            }>
            {data.map((item, index) => (
              <Cell
                key={`cell-${index}`}
                style={{ cursor: 'pointer' }}
                onMouseDown={(e) => handleCellMouseDown(e, item.name as AnswerEnum)}
                onTouchStart={(e) => handleCellMouseDown(e, item.name as AnswerEnum)}
                fill={getColor(item.name as AnswerEnum)}
                stroke={getColor(item.name as AnswerEnum)}
              />
            ))}
          </Pie>
        </StyledPieChart>
        <TextContainer>
          <Text>{title}</Text>
          <Total>{total} total</Total>
        </TextContainer>
      </Wrapper>
      <Legend data={data} isTest={isTest} getColor={getColor} />
      <TagsContainer>
        {tags ? (
          tags.map((tag, index) => (
            <StyledTag key={index} text={tag.text} selected={tag.isSelected} onClick={tag.onSelect} size="small" />
          ))
        ) : (
          <DefaultTag>Only by chapters</DefaultTag>
        )}
      </TagsContainer>
    </Container>
  );
};

export default CircleChart;

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    position: relative;
    background-color: ${colors.neutrals[11]};
  `};
`;

const Wrapper = styled.div`
  position: relative;
  width: 480px;
  height: 480px;
  flex-grow: 1;
  margin-block: 74px 46px;

  ${respondToHeight.ls`
    margin-block:0;
    width: 380px;
    height:380px;
  `}

  ${respondToHeight.sm`
    margin-block: 44px 16px;
  `}

  ${respondToWidth.l`
    width: 380px;
    height:380px;
  `};

  ${respondToWidth.ls`
    width: 400px;
    height: 400px;
    margin-block: 74px 46px;
  `};

  ${respondToWidth.sm`
    width:310px;
    height:310px;
    margin-block: 72px 24px;
  `};
`;

const Ellipse = styled(PerformanceEllipse)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const StyledPieChart = styled(PieChart)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
`;

const Text = styled(StyledText)`
  ${({ theme: { typography, colors } }) => css`
    margin-bottom: 4px;
    color: ${colors.neutrals[5]};

    ${typography.body_large_semibold_16};
  `};
`;

const Total = styled(StyledText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_4_medium_24};

    ${respondToWidth.sm`
      ${typography.title_5_medium_20};
    `}
  `}
`;
const TagsContainer = styled.div`
  position: absolute;
  top: 32px;
  left: 32px;
  display: grid;
  gap: 8px;
  z-index: 2;

  ${respondToHeight.sm`
    top: 10px;
    left: 10px;
    grid-template-columns: 1fr 1fr;
  `}

  ${respondToWidth.lg`
    top:10px;
    left:10px;
  `}

  ${respondToWidth.ls`
    top: 16px;
    left: 16px;
    grid-template-columns: 1fr 1fr;
  `}
`;

const DefaultTag = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_semibold_14};
`;

const StyledTag = styled(Tag)`
  width: 103px;
`;
