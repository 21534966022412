import Button from 'UI/Button';
import CardWithIcon from 'UI/CardWithIcon/CardWithIcon';
import { QuestionsCardsIcon, TimerFillIcon } from 'assets/icons';
import React, { FC, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';
import { getQuestionPluralForm } from 'utils';
import formateSecondsToTime, { renderTimedTestTime } from 'utils/dateHelpers';

import DefaultModal from '../DefaultModal';

type TimedTestStartModalProps = {
  questionsCount: number;
  durationSec: number;
  startTest: () => void;
  isLoading: boolean;
} & BaseModalProps;

const TimedTestStartModal: FC<TimedTestStartModalProps> = ({
  isOpen,
  isLoading,
  onClose,
  startTest,
  durationSec,
  questionsCount,
}) => {
  const { colors } = useTheme();
  const {
    time,
    formatted: { formattedDateString },
  } = useMemo(
    () => ({
      time: renderTimedTestTime(durationSec),
      formatted: formateSecondsToTime(durationSec),
    }),
    [durationSec],
  );
  const questions = useMemo(() => getQuestionPluralForm(questionsCount), [questionsCount]);

  return (
    <DefaultModal
      title={`You will have ${time} for ${questions}. Start the test?`}
      showCloseButton
      isOpen={isOpen}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      headerContainerCSS={HeaderCSS}
      titleCSS={TitleCSS}
      modalCSS={ModalCSS}
      closeButtonCSS={CloseButtonCSS}>
      <Body>
        <CardWithIcon
          Icon={<QuestionsCardsIcon color={colors.primary[1]} />}
          text={questionsCount}
          cardCSS={LeftCardCSS}
        />
        <CardWithIcon
          Icon={<TimerFillIcon color={colors.primary[1]} />}
          text={formattedDateString}
          cardCSS={RightCardCSS}
        />
      </Body>
      <ButtonsContainer>
        <Button isLoading={isLoading} onClick={startTest} variant="primary" size="middle" type="submit">
          Start
        </Button>
      </ButtonsContainer>
    </DefaultModal>
  );
};

export default TimedTestStartModal;

const ContainerCSS = css`
  padding: 40px 80px 60px;
  width: 100%;

  ${respondToWidth.sm`
    padding: 28px 16px;
  `}
`;

const ModalCSS = css`
  max-width: 610px;
  width: 100%;

  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const CloseButtonCSS = css`
  ${respondToWidth.s`
        display:none;
    `}
`;

const TitleCSS = css`
  ${({ theme: { typography } }) => typography.title_3_bold_28}

  ${respondToWidth.sm`
       padding-right: 52px;
  `}

  ${respondToWidth.s`
       padding-right: 0;
  `}
`;

const HeaderCSS = css`
  margin-bottom: 28px;

  ${respondToWidth.s`
    justify-content: center;
    margin-bottom: 32px;
  `}
`;

const LeftCardCSS = css`
  width: 182px;

  ${respondToWidth.sm`
    width: 100%;
  `}
`;

const RightCardCSS = css`
  width: 252px;

  ${respondToWidth.sm`
    width: 100%;
  `}
`;

const Body = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  gap: 16px;

  margin-bottom: 28px;

  ${respondToWidth.sm`
    flex-wrap: wrap;
  `}

  ${respondToWidth.s`
    padding-inline:7px;
  `}
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${respondToWidth.s`
    padding-inline:7px;
  `}
`;
