import { AppConfig } from 'config';
import { CourseProvider, DirectionThemeProvider, UserProvider } from 'providers';
import { useEffect } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import Routes from 'routes';
import TechnicalRoutes from 'routes/technicalWork';
import { GlobalFont } from 'styles/general/globalFont';
import { GlobalStyle, calculateViewportHeight } from 'styles/general/globalStyles';

const startMaintenanceTimestamp = new Date(1714032000 * 1000);
const endMaintenanceTimestamp = new Date(1714037400 * 1000);

const currentTimestamp = new Date();

const isUnderMaintenance = currentTimestamp >= startMaintenanceTimestamp && currentTimestamp <= endMaintenanceTimestamp;

function App() {
  useEffect(() => {
    if (!isUnderMaintenance) {
      calculateViewportHeight();
      window.addEventListener('resize', calculateViewportHeight);
      return () => window.removeEventListener('resize', calculateViewportHeight);
    }
  }, [isUnderMaintenance]);

  if (isUnderMaintenance) {
    return <TechnicalRoutes />;
  } else {
    return (
      <UserProvider>
        <DirectionThemeProvider>
          <IntercomProvider appId={AppConfig.REACT_APP_INTERCOM_APP_ID} shouldInitialize>
            <GlobalStyle />
            <GlobalFont />
            <CourseProvider>
              <Routes />
            </CourseProvider>
          </IntercomProvider>
        </DirectionThemeProvider>
      </UserProvider>
    );
  }
}

export default App;
