import { Button, DefaultModal } from 'UI';
import { useClientSize } from 'hooks';
import React, { Dispatch, FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type CancelModalProps = {
  isModalVisible: boolean;
  setModalVisible: Dispatch<React.SetStateAction<boolean>>;
  className?: string;
};

const CancelModal: FC<CancelModalProps> = ({ isModalVisible, setModalVisible, className }) => {
  const { getIsBreakpoint } = useClientSize();
  const isBreakpointSm = getIsBreakpoint('sm');

  const handleCancelModalClose = () => {
    setModalVisible(false);
  };

  return (
    <DefaultModal
      title="Weakest Exam Areas"
      containerCSS={ContainerCSS}
      titleCSS={TitleCSS}
      modalCSS={ModalCSS}
      className={className}
      isOpen={isModalVisible}
      onRequestClose={handleCancelModalClose}
      showBackButton={false}
      showCloseButton={!isBreakpointSm}
      withTitleMargin={false}>
      <Container>
        <Subtitle>
          You have not studied enough in order for us to calculate your weakest areas. Keep Studying 🤓
        </Subtitle>

        <ButtonsContainer>
          <CancelButton
            key="cancel"
            onClick={handleCancelModalClose}
            className={className}
            variant={isBreakpointSm ? 'secondary' : 'primary'}
            size="middle">
            Cancel
          </CancelButton>
        </ButtonsContainer>
      </Container>
    </DefaultModal>
  );
};

export default CancelModal;

const ModalCSS = css`
  ${respondToWidth.sm`
    top: unset;
    bottom: 0;
    width: 100%;
    transform: translate(-50%, 0);
  `}
`;

const ContainerCSS = css`
  padding: 40px 80px;
  width: 628px;
  height: 274px;

  ${respondToWidth.sm`
    height: 216px;
    width: 100%;
    padding: 32px 16px;
  `}
`;

const TitleCSS = css`
  ${({ theme: { colors } }) => css`
    margin-bottom: 20px;
    color: ${colors.primary[1]} !important;
  `}

  ${respondToWidth.sm`
    margin-bottom: 4px;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 40px;
`;

const Subtitle = styled.div`
  ${({ theme: { typography, colors } }) => css`
    margin-bottom: 40px;
    color: ${colors.neutrals[1]};

    ${typography.body_basic_medium_14}
  `}

  ${respondToWidth.sm`
    margin-bottom: 24px;
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const CancelButton = styled(Button)`
  display: flex !important;
  width: 100% !important;
  padding-right: 23px;
  white-space: nowrap;
`;
