import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgBookIcon: FC<SVGIconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#F4DD45"
        d="M21.855 0v19.698l-17.56 1.916-2.15.235V2.151c0-.594.24-1.132.63-1.521.389-.39.927-.63 1.52-.63h17.56Z"
      />
      <path fill="#D1B600" d="M4.296 0v21.614l-2.151.235V2.151c0-.594.24-1.132.63-1.521.389-.39.927-.63 1.52-.63Z" />
      <path fill="#D1B600" d="M21.855 19.698H4.295a2.15 2.15 0 1 0 0 4.302h17.56v-1.207l-.875-.944.875-.943v-1.207Z" />
      <path fill="#DFEAEF" d="M4.296 22.793a.945.945 0 0 1 0-1.887h17.56v1.887H4.295Z" />
      <path fill="#DA4A54" d="M9.89 4.663 8.326 3.058 6.763 4.663V0H9.89v4.663Z" />
      <path
        fill="#DFEAEF"
        d="M16.707 6.011H8.37c-.836 0-1.513.678-1.513 1.514v4.618c0 .836.677 1.514 1.513 1.514h8.338c.836 0 1.514-.678 1.514-1.514V7.525c0-.836-.678-1.514-1.514-1.514Z"
      />
      <path
        fill="#665E66"
        d="M15.94 9.237H9.136a.362.362 0 0 1 0-.724h6.804a.362.362 0 1 1 0 .724Zm0 1.919H9.136a.362.362 0 0 1 0-.724h6.804a.362.362 0 1 1 0 .724Z"
      />
    </svg>
  );
};
const Memo = memo(SvgBookIcon);
export default Memo;
