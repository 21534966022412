import { FullScreenModal } from 'UI';
import React, { Dispatch, FC, useState } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type ExpandImageProps = {
  image: {
    url: string;
    alt: string;
  };
  className?: string;
  fullscreenCSS?: CSSProp;
  setIsImageLoaded?: Dispatch<React.SetStateAction<boolean>>;
};

const ExpandImage: FC<ExpandImageProps> = ({ image, className, fullscreenCSS, setIsImageLoaded }) => {
  const [isShowModal, setIsShowModal] = useState(false);

  const onLoaded = () => {
    setIsImageLoaded?.(true);
  };

  return (
    <>
      <ImageContainer onClick={() => setIsShowModal(true)} className={className} type="button">
        <img src={image.url} alt={image.alt} onLoad={onLoaded} />
      </ImageContainer>
      <FullScreenModal
        isOpen={isShowModal}
        onRequestClose={() => setIsShowModal(false)}
        containerCSS={FullscreenContainerCSS}>
        <FullscreenImageContainer $CSS={fullscreenCSS}>
          <img src={image.url} alt={image.alt} />
        </FullscreenImageContainer>
      </FullScreenModal>
    </>
  );
};

export default ExpandImage;

const FullscreenImageContainer = styled.div<{ $CSS?: CSSProp }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    object-fit: contain;
    height: 100%;
  }

  ${({ $CSS }) => $CSS}
`;

const FullscreenContainerCSS = css`
  padding: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  ${respondToWidth.sm`
    padding:16px;
  `};

  @media (orientation: landscape) and (max-width: 1024px) {
    height: 100%;
    padding: 0;
  }
`;

const ImageContainer = styled.button`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
`;
