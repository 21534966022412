import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Input } from '../../../../../../UI';
import { CourseShortInfoResponseDto } from '../../../../../../api/generated';
import { CoursesGroup } from '../../index';

type SelectCourseFormProps = {
  onSelectedAnswerChange: (selectedAnswer: CourseShortInfoResponseDto) => void;
};

const SelectCourseForm: FC<SelectCourseFormProps> = ({ onSelectedAnswerChange }) => {
  const { register } = useFormContext();
  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <>
      <Input
        variant={'search'}
        {...register('search')}
        onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
      />
      <StyledCoursesGroup>
        <CoursesGroup onSelectedAnswerChange={onSelectedAnswerChange} searchTerm={searchTerm} />
      </StyledCoursesGroup>
    </>
  );
};

const StyledCoursesGroup = styled.div`
  margin-top: 18px;
  margin-bottom: 40px;
  height: 100%;
`;
export default SelectCourseForm;
