import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgMarkPenIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m15.243 4.515-6.738 6.737-.707 2.121-1.04 1.041 2.828 2.829 1.04-1.041 2.122-.707 6.737-6.738-4.242-4.242Zm6.364 3.535a1 1 0 0 1 0 1.414l-7.779 7.779-2.12.707-1.415 1.414a1 1 0 0 1-1.414 0l-4.243-4.243a1 1 0 0 1 0-1.414l1.414-1.414.707-2.121 7.779-7.779a1 1 0 0 1 1.414 0l5.657 5.657Zm-6.364-.707 1.414 1.414-4.95 4.95-1.414-1.414 4.95-4.95ZM4.283 16.89l2.828 2.829-1.414 1.414-4.243-1.414 2.828-2.829h.001Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgMarkPenIcon);
export default Memo;
