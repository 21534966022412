import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgTechnicalWorks: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.2084 54.9896C10.3638 54.8342 12.2282 52.8144 15.1802 49.707C19.5305 45.2013 38.4855 25.0034 38.7963 24.6927C40.1946 23.2944 46.8754 28.4215 52.4687 22.8283C56.0422 19.2548 57.2851 14.7491 55.7314 9.31119L48.5845 16.4581C48.5845 16.4581 45.9432 16.4581 43.3019 13.9722C40.816 11.331 40.816 8.68972 40.816 8.68972L47.963 1.69813C42.8358 0.144442 37.864 1.38739 34.2906 4.96087C28.8527 10.3988 33.6691 17.235 32.2708 18.6333C31.96 18.7887 5.392 44.1138 5.08126 44.4245C2.43999 46.9104 2.28462 47.0658 2.12926 47.0658C-0.0459056 49.3963 -0.201274 53.1251 1.97389 55.3003C4.14905 57.4755 7.87789 57.3201 10.2084 54.9896ZM6.63495 48.3087C7.87789 48.3087 8.96548 49.3963 8.96548 50.6392C8.96548 51.8822 7.87789 52.9698 6.63495 52.9698C5.392 52.9698 4.30442 51.8822 4.30442 50.6392C4.30442 49.3963 5.23663 48.3087 6.63495 48.3087Z"
        fill="#92A5BC"
      />
      <path
        d="M53.2448 49.087L13.6258 9.77874L9.74158 4.0301L2.59463 0.922729L0.419464 3.09789L3.52684 10.2448L9.27548 14.1291L48.8945 53.4373L53.2448 49.087Z"
        fill="#CFD9E4"
      />
      <path
        d="M32.2707 23.9173C29.008 16.1489 27.1436 20.3439 23.4147 23.9173C19.8412 27.4908 15.6463 29.3553 23.4147 32.7734C31.0278 36.0361 39.4177 50.9515 42.6804 54.3696C45.9432 57.6323 49.9828 57.9431 53.7116 54.3696C57.2851 50.7961 56.9743 46.7565 53.7116 43.3384C50.4489 40.0757 35.5335 31.6858 32.2707 23.9173Z"
        fill="#00A3FA"
      />
      <path
        d="M23.4147 23.9173C26.9881 20.3439 28.8526 16.1489 32.2707 23.9173L23.4147 32.7734C15.8016 29.5106 19.8412 27.6462 23.4147 23.9173Z"
        fill="#0486E3"
      />
    </svg>
  );
};

const Memo = memo(SvgTechnicalWorks);
export default Memo;
