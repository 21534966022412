import { useAsyncAction } from 'hooks';
import React, { useEffect, useState } from 'react';
import { dayjs } from 'services';
import { actions, selectors, useAppSelector } from 'store';
import styled from 'styled-components';

import { ButtonContent, CalendarInvokeButton, DaysToExamText } from './components';

const CalendarButton = () => {
  const [getExamDate] = useAsyncAction(actions.courses.getActiveCourseExamDate);
  const examDate = useAppSelector(selectors.courses.selectActiveCourseExamDate);
  const [daysDiff, setDaysDiff] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const today = dayjs();
  const hasDays = daysDiff && daysDiff > 0;

  /**
   * This effect is needed to get the difference between today and the picked day
   */
  useEffect(() => {
    const targetDate = dayjs(startDate);

    setDaysDiff(targetDate.startOf('day').diff(today.startOf('day'), 'day'));
  }, [startDate]);

  useEffect(() => {
    if (examDate) {
      setStartDate(dayjs(examDate).toDate());
    }
  }, [examDate]);

  useEffect(() => {
    getExamDate();
  }, []);

  return (
    <CalendarInvokeButton startDate={startDate} setStartDate={setStartDate}>
      {(props) => (
        <StyledButton {...props}>
          <ButtonContent>
            {hasDays ? <DaysToExamText daysToExam={daysDiff} /> : <Text>When are you taking the exam?</Text>}
          </ButtonContent>
        </StyledButton>
      )}
    </CalendarInvokeButton>
  );
};

export default CalendarButton;

const StyledButton = styled.button`
  position: relative;
  text-align: left;
  width: 100%;
  padding: 8px 16px;
  border-radius: 12px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  :hover {
    background-color: ${({ theme: { colors } }) => colors.neutrals[8]};
  }

  :active:enabled {
    border-color: ${({ theme: { colors } }) => colors.neutrals[1]};
  }
`;

const Text = styled.p`
  white-space: nowrap;
  color: ${({ theme: { colors } }) => colors.neutrals[5]};

  ${({ theme: { typography } }) => typography.body_basic_bold_14};
`;
