import { useGetIsMobile } from 'hooks/useGetIsMobile';
import React, { ButtonHTMLAttributes, FC, ReactNode, memo } from 'react';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type SidebarItemProps = {
  isSelect?: boolean;
  iconComponent: ReactNode;
  title: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const SidebarItem: FC<SidebarItemProps> = ({ isSelect = false, iconComponent, title, ...props }) => {
  const { isMobile } = useGetIsMobile();
  return (
    <Container $isSelect={isSelect} $isMobile={isMobile} {...props}>
      <IconContainer>{iconComponent}</IconContainer>
      {title}
    </Container>
  );
};

export default memo(SidebarItem);

type ContainerProps = {
  $isSelect: boolean;
  $isMobile: boolean;
};

const Container = styled.button<ContainerProps>`
  width: 108px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 16px 0;
  border-radius: 20px;
  background-color: inherit;

  color: ${({ theme: { colors }, $isSelect }) => ($isSelect ? colors.primary[1] : colors.neutrals[4])};
  ${({ theme: { typography } }) => typography.footnote_bold_12};
  text-transform: capitalize;
  user-select: none;

  path {
    fill: ${({ theme: { colors }, $isSelect }) => ($isSelect ? colors.primary[1] : colors.neutrals[4])};
  }

  &:hover {
    background-color: ${({ theme: { colors }, $isMobile }) => !$isMobile && colors.primary[6]};
    color: ${({ theme: { colors }, $isMobile }) => !$isMobile && colors.primary[3]};

    path {
      fill: ${({ theme: { colors }, $isMobile }) => !$isMobile && colors.primary[3]};
    }
  }

  ${({ theme: { typography } }) => respondToWidth.sm`
    flex-direction: row;
    width:fit-content;
    padding:0;
    justify-content: start;
    ${typography.subhead_semibold_13};
  `}
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  ${respondToWidth.sm`
    width: 24px;
    height: 24px;
    margin-bottom: 0px;
    margin-right: 12px;
  `}
`;
