import StyledText from 'UI/StyledText';
import TextButton from 'UI/TextButton';
import { ArrowBackIcon } from 'assets/icons';
import { useClientSize } from 'hooks';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { stringCircumcision } from 'utils/textHelpers';

export type BreadcrumbsProps = {
  pageName?: string;
  routeName: string;
  title: string;
};

type BreadcrumbsType = {
  isMnemonic?: boolean;
  className?: string;
  isPractice?: boolean;
  isAlwaysBigIcon?: boolean;
  onClick?: () => void;
} & BreadcrumbsProps;

const MAX_LENGTH_TITLE = 54;

const Breadcrumbs: FC<BreadcrumbsType> = ({
  title,
  pageName,
  routeName,
  className,
  isAlwaysBigIcon = false,
  isMnemonic = false,
  isPractice = false,
  onClick,
}) => {
  const { getIsBreakpoint } = useClientSize();
  const navigate = useNavigate();

  const handleClick = onClick || (() => navigate(routeName));

  const maxLengthResponsiveTitle = isMnemonic ? 12 : 22;

  const isWidthXl = getIsBreakpoint('xl');
  const isWidthLs = getIsBreakpoint('ls');
  const isWidthSm = getIsBreakpoint('sm');
  const formattedTitle = isWidthXl
    ? stringCircumcision(title, maxLengthResponsiveTitle)
    : stringCircumcision(title, MAX_LENGTH_TITLE);
  const iconSize = isWidthLs && !isAlwaysBigIcon ? 24 : 32;
  const heading = isPractice ? 'Practice' : formattedTitle;
  const shortBreadcrumb = `${pageName} / `;
  const longBreadcrumb = `${pageName} / ${isPractice ? `${formattedTitle} /` : ''}`;
  const breadcrumb = isWidthSm ? shortBreadcrumb : longBreadcrumb;

  const { isMobile } = useGetIsMobile();
  return (
    <Root className={className} $isMobile={isMobile}>
      <InnerContainer onClick={handleClick}>
        <StyledArrowBackIcon size={iconSize} />
        {pageName && <StyledTextButton text={breadcrumb} />}
      </InnerContainer>
      <Title>{heading}</Title>
    </Root>
  );
};

export default Breadcrumbs;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_5_bold_20}

    ${respondToWidth.ls`
   ${typography.body_large_bold_16};
  `}
  `}
`;

const StyledTextButton = styled(TextButton)`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[4]};
    ${typography.title_5_bold_20}

    ${respondToWidth.ls`
   ${typography.body_large_bold_16};
  `}
  `}
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  margin-right: 16px;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;

  ${respondToWidth.ls`
     margin-right: 4px;
  `}
`;

const Root = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;

  ${respondToWidth.sm`
    padding: 24px 16px;
    width: 100%;
  `}

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding: 24px 16px;
      width: 100%;
    `}
`;
