import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { CSSProp } from 'styled-components';

import { IconContainer, IconPlacementType, StyledButton, TextButtonVariant } from './styled';

type TextButtonProps = {
  text: string;
  customTextCSS?: CSSProp;
  variant?: TextButtonVariant;
  iconComponent?: ReactNode;
  iconPlacement?: IconPlacementType;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const TextButton: FC<TextButtonProps> = ({
  text,
  customTextCSS,
  variant = 'textButton',
  iconComponent,
  iconPlacement = 'left',
  ...props
}) => {
  return (
    <StyledButton {...props} type="button" $variant={variant} $customTextCSS={customTextCSS}>
      {iconPlacement === 'left' && iconComponent && (
        <IconContainer $iconPlacement={iconPlacement} $variant={variant}>
          {iconComponent}
        </IconContainer>
      )}
      {text}
      {iconPlacement === 'right' && iconComponent && (
        <IconContainer $iconPlacement={iconPlacement} $variant={variant}>
          {iconComponent}
        </IconContainer>
      )}
    </StyledButton>
  );
};

export default TextButton;
