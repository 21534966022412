import Button from 'UI/Button';
import Input from 'UI/Input';
import StyledText from 'UI/StyledText';
import { useNotifications } from 'hooks';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps, DefaultRejectValue } from 'types';
import { validations } from 'utils';

import DefaultModal from '../DefaultModal';

export type FormState = {
  newName: string;
};

type ChangeFullNameModalProps = {
  name?: string;
  isLoading?: boolean;
  patchProfile?: (values: FormState) => Promise<void>;
} & BaseModalProps;

const ChangeFullNameModal: FC<ChangeFullNameModalProps> = ({ name, isLoading, onClose, patchProfile, ...props }) => {
  const { successToast, errorToast } = useNotifications();
  const { handleSubmit, control, setError, reset } = useForm<FormState>();

  const description = name ? 'Your current full name' : 'You have not set the full name';

  const onSubmit = async (values: FormState) => {
    try {
      await patchProfile?.(values);

      successToast('Full name changed successfully');

      closeModal();
      reset();
    } catch (error) {
      const errorMessage = (error as DefaultRejectValue)?.message?.[0] as string;
      errorToast('Something went wrong');

      setError('newName', { message: errorMessage });
    }
  };

  const closeModal = () => {
    onClose();
    reset();
  };

  return (
    <DefaultModal
      {...props}
      onRequestClose={closeModal}
      title="Change your full name"
      containerCSS={ContainerCSS}
      changeCloseButton
      modalCSS={ModalCSS}
      isMobileOverlayStyles>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Text>{description}</Text>
        <ExampleText>{name}</ExampleText>
        <Controller
          control={control}
          name="newName"
          rules={{
            validate: validations.fullName,
            required: 'Required field',
          }}
          render={({ field, fieldState: { error } }) => (
            <Input label="New full name" error={error?.message} {...field} />
          )}
        />
        <StyledButton isLoading={isLoading} variant="primary" size="middle" type="submit">
          Save
        </StyledButton>
      </Form>
    </DefaultModal>
  );
};

export default ChangeFullNameModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height: 100%;
    padding: 16px; 
    border-radius: 0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const Text = styled(StyledText)`
  margin-bottom: 4px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${respondToWidth.s`
    text-align: start;
  `}
`;

const ExampleText = styled(Text)`
  color: ${({ theme: { colors } }) => colors.primary[1]};
  margin-bottom: 40px;

  ${respondToWidth.s`
    margin-bottom: 24px;
  `}
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledButton = styled(Button)`
  margin-top: 40px;

  ${respondToWidth.s`
    margin-top: auto;
  `}
`;
