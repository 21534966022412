import { StyledText } from 'UI';
import { useAsyncAction, useNotifications, useQuery } from 'hooks';
import { useTimer } from 'hooks/useTimer';
import React, { FC, useMemo } from 'react';
import { actions } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { FlashcardsPackType } from 'types';

type WithoutCardProps = {
  addedTime: number;
  packType: FlashcardsPackType;
};

const WithoutCards: FC<WithoutCardProps> = ({ addedTime, packType }) => {
  const query = useQuery();
  const packId = query.get('packId');

  const { errorToast } = useNotifications();

  const currentDate = useMemo(() => new Date(Date.now() + addedTime * 1000), [addedTime]);

  const [getFlashcardsAction] = useAsyncAction(actions.flashcards.getFlashcards);

  const getNewFlashcard = async () => {
    try {
      await getFlashcardsAction({ packId: packId || undefined, packType, page: 0, onlyAvailable: true });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const { formattedDateString } = useTimer(currentDate.toISOString(), getNewFlashcard);

  return (
    <Container>
      <Title as="h1">There are no cards to study yet. They will appear in</Title>
      <TimerContainer>
        <TimerItem as="span">{formattedDateString}</TimerItem>
      </TimerContainer>
    </Container>
  );
};

export default WithoutCards;

const Container = styled.div`
  width: 100%;
  max-width: 664px;
  height: 312px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 20px;
  text-align: center;

  ${respondToWidth.sm`
    height: 100%;
    max-height: 500px;
    width: calc(100vw - 32px);
  `};
`;

const Title = styled(StyledText)`
  margin-bottom: 20px;
  ${({ theme: { typography } }) => typography.title_5_medium_20};

  ${respondToWidth.sm`
    max-width: 297px;
  `}
`;

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
`;

const TimerItem = styled(StyledText)`
  ${({ theme: { typography, colors } }) =>
    css`
      color: ${colors.primary[1]};
      ${typography.title_2_bold_32};
    `};
`;
