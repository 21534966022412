import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgErrorIcon: FC<SVGIconProps> = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={24} height={24} rx={4} fill="#EB4040" />
      <g clipPath="url(#clip0_408_42628)">
        <path
          d="m12 10.822 4.126-4.125 1.178 1.178L13.179 12l4.125 4.125-1.178 1.178L12 13.178l-4.125 4.125-1.179-1.178L10.822 12 6.697 7.875l1.179-1.178L12 10.822Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_408_42628">
          <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgErrorIcon);
export default Memo;
