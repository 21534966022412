import { Button } from 'UI';
import { PlusIcon } from 'assets/icons';
import { TestMenuButton } from 'components/TestFooter/TestFooter';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { actions } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type AddToFlashcardButtonProps = {
  isAddedToFlashcardInitialValue: boolean;
  questionId: string;
};

const QuestionAddToFlashcardButton: FC<AddToFlashcardButtonProps> = ({
  isAddedToFlashcardInitialValue,
  questionId,
}) => {
  const [isAddedToFlashcard, setIsAddedToFlashcard] = useState(isAddedToFlashcardInitialValue);
  const { errorToast } = useNotifications();

  useEffect(() => {
    setIsAddedToFlashcard(isAddedToFlashcardInitialValue);
  }, [isAddedToFlashcardInitialValue]);

  const [addQuestionToFlashcardAction, isAddLoading] = useAsyncAction(actions.flashcardsPacks.addToFlashcards);
  const [removeQuestionFromFlashcardAction, isRemoveLoading] = useAsyncAction(
    actions.flashcardsPacks.removeFromFlashcards,
  );

  const isLoading = isAddLoading || isRemoveLoading;

  const addQuestionToFlashcard = async () => {
    try {
      await addQuestionToFlashcardAction({ questionId });
      setIsAddedToFlashcard(true);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const removeQuestionFromFlashcard = async () => {
    try {
      await removeQuestionFromFlashcardAction({ questionId });
      setIsAddedToFlashcard(false);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };
  const { colors } = useTheme();

  return (
    <>
      {isAddedToFlashcard ? (
        <StyledButton
          variant="tertiary"
          size="small"
          onClick={removeQuestionFromFlashcard}
          isLoading={isLoading}
          $isAddedToFlashcard={isAddedToFlashcard}>
          {TestMenuButton.REMOVE_FLASHCARD}
        </StyledButton>
      ) : (
        <StyledButton
          iconComponent={<PlusIcon width={24} height={24} color={colors.primary[1]} />}
          variant="secondary"
          size="small"
          onClick={addQuestionToFlashcard}
          isLoading={isLoading}
          $isAddedToFlashcard={isAddedToFlashcard}>
          {TestMenuButton.ADD_FLASHCARD}
        </StyledButton>
      )}
    </>
  );
};

export default QuestionAddToFlashcardButton;

const StyledButton = styled(Button)<{ $isAddedToFlashcard?: boolean }>`
  white-space: nowrap;
  width: ${({ $isAddedToFlashcard }) => ($isAddedToFlashcard ? '238px' : '236px')};

  ${respondToWidth.sm`
    width:100%;
  `}
`;
