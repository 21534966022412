import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgQuestionsCardsIcon: FC<SVGIconProps> = (props) => {
  return (
    <svg width={61} height={61} viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        y={33.59}
        width={43.541}
        height={26.125}
        rx={6}
        transform="rotate(-38.93 0 33.59)"
        fill="#FCEFEB"
        stroke="#fff"
        strokeWidth={2}
      />
      <rect
        x={5.725}
        y={29.346}
        width={43.541}
        height={26.125}
        rx={6}
        transform="rotate(-24.767 5.725 29.346)"
        fill={props.color}
        stroke="#fff"
        strokeWidth={2}
      />
      <rect
        x={13.871}
        y={27.789}
        width={43.541}
        height={26.125}
        rx={6}
        transform="rotate(-6.257 13.87 27.789)"
        fill={props.color}
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        d="M35.743 42.15a6.985 6.985 0 0 1-.016-1.402c.048-.462.158-.896.332-1.302.147-.389.323-.704.529-.944.205-.248.438-.495.7-.741.26-.254.543-.584.85-.99.251-.344.405-.674.462-.99a3.61 3.61 0 0 0 .025-1.015 2.763 2.763 0 0 0-.144-.638 1.237 1.237 0 0 0-.317-.493 1.556 1.556 0 0 0-.81-.46 2.86 2.86 0 0 0-.998-.059c-.285.031-.555.1-.811.205-.25.098-.456.233-.62.406-.19.19-.32.411-.391.665-.07.254-.087.526-.048.817l-2.647.29a5.333 5.333 0 0 1 .351-1.99 3.945 3.945 0 0 1 1.074-1.562 4.295 4.295 0 0 1 1.262-.803 5.37 5.37 0 0 1 1.476-.383 6.516 6.516 0 0 1 2.197.117 3.803 3.803 0 0 1 1.827.981c.351.32.632.704.843 1.152.218.448.353.91.406 1.39a4.147 4.147 0 0 1-.254 2.011 7.237 7.237 0 0 1-.988 1.765c-.196.26-.385.492-.567.695-.182.203-.35.4-.505.593a2.717 2.717 0 0 0-.358.577c-.159.348-.243.639-.253.872-.01.226.007.54.051.944l-2.658.291Zm.468 4.072-.303-2.762 2.637-.289.303 2.762-2.637.29Z"
        fill="#FFF7F4"
      />
    </svg>
  );
};

const Memo = memo(SvgQuestionsCardsIcon);
export default Memo;
