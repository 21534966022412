import { StripeError } from '@stripe/stripe-js';
import { SetStateAction } from 'react';

interface Props {
  cashAppError?: string;
  otherErrorMessage?: string;
  error?: StripeError;
  setErrorMessage: (value: SetStateAction<string | null>) => void;
}

export const setErrorStripe = ({ error, setErrorMessage, cashAppError, otherErrorMessage }: Props) => {
  if (error?.type === 'card_error' || error?.type === 'validation_error' || cashAppError || otherErrorMessage) {
    setErrorMessage(error?.message || cashAppError || otherErrorMessage || '');
  } else if (error) {
    setErrorMessage('An unexpected error occurred.');
  }
};
