import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CourseDto, PaginationMetaDto, PublicCourseDto } from 'api/generated';

import { pauseSubscriptionThunk, unpauseSubscriptionThunk } from '../payments/actions';
import {
  cancelSubscriptionDowngradeThunk,
  cancelSubscriptionTrialDowngradeThunk,
  changeCourseSubscribeThunk,
  checkPromoCodeThunk,
  getActiveCourseExamDateThunk,
  getActiveCourseThunk,
  getCheatSheetThunk,
  getCheckPurchaseThunk,
  getCourseByIdPublicThunk,
  getCourseByIdThunk,
  getCoursesThunk,
  getMyCoursesThunk,
  getTextBookThunk,
  newSubscribeCourseThunk,
  purchaseCourseFromTrialThunk,
  purchaseCourseThunk,
  resetCourseResultsThunk,
  setActiveCourseExamDateThunk,
  setActiveCourseThunk,
  subscribeCourseFromTrialThunk,
  subscribeCourseThunk,
  unsubscribeCourseThunk,
} from './actions';
import { CourseOptionsForPayment, UnsubscribeSteps } from './types';

type CourseDtoExtended = {
  domainButtonName?: string | null;
  domainStatsButtonName?: string | null;
  chapterButtonName?: string | null;
  chapterStatsButtonName?: string | null;
} & CourseDto;

interface CoursesSlice {
  courses: CourseDto[] | null;
  idCourse: string | null;
  meta: PaginationMetaDto | null;
  activeCourse: CourseDtoExtended | null;
  inviteCourse: CourseDto | null;
  course: CourseDto | null;
  publicCourse: PublicCourseDto | null;
  cheatSheetUrl: string | null;
  textBookUrl: string | null;
  isChooseProductModalOpen: boolean;
  isPaymentModalOpen: boolean;
  courseOptionsForPayment: CourseOptionsForPayment | null;
  isPaymentErrorModalOpen: boolean;
  examDate: string | null;
  isPurchased: boolean;
  promocode: boolean;
  unsubscribeStep: UnsubscribeSteps;
  isPauseSubscription: boolean;
  setIsSuccessfulUnsubscribe: boolean;
  isDisabledPaymentButton: boolean;
}

const initialState: CoursesSlice = {
  courses: null,
  course: null,
  idCourse: null,
  publicCourse: null,
  meta: null,
  activeCourse: null,
  inviteCourse: null,
  isChooseProductModalOpen: false,
  isPaymentModalOpen: false,
  isPaymentErrorModalOpen: false,
  courseOptionsForPayment: null,
  cheatSheetUrl: null,
  textBookUrl: null,
  examDate: null,
  isPurchased: false,
  promocode: false,
  unsubscribeStep: UnsubscribeSteps.STEP_1,
  isPauseSubscription: false,
  setIsSuccessfulUnsubscribe: false,
  isDisabledPaymentButton: false,
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    selectCourse: (state, { payload }: PayloadAction<CourseDto | null>) => ({ ...state, course: payload }),
    inviteCourse: (state, { payload }: PayloadAction<CourseDto | null>) => ({ ...state, inviteCourse: payload }),
    openChooseProductModalOpen: (state) => ({ ...state, isChooseProductModalOpen: true }),
    closeChooseProductModalOpen: (state) => ({ ...state, isChooseProductModalOpen: false }),
    setIdCourse: (state, { payload }) => {
      state.idCourse = payload;
    },
    selectCourseOptionsForPayment: (state, { payload }: PayloadAction<CourseOptionsForPayment | null>) => ({
      ...state,
      courseOptionsForPayment: payload,
    }),
    openPaymentModalOpen: (state) => ({
      ...state,
      isPaymentModalOpen: true,
    }),
    closePaymentModalOpen: (state) => ({
      ...state,
      isPaymentModalOpen: false,
    }),
    closePaymentErrorModal: (state) => ({
      ...state,
      isPaymentErrorModalOpen: false,
    }),
    setUnsubscribeStep: (state, { payload }: PayloadAction<UnsubscribeSteps>) => {
      state.unsubscribeStep = payload;
    },
    setPauseSubscription: (state, { payload }) => {
      state.isPauseSubscription = payload;
    },
    setIsSuccessfulUnsubscribe: (state, { payload }) => {
      state.setIsSuccessfulUnsubscribe = payload;
    },
    setDisabledStatus: (state, { payload }) => {
      state.isDisabledPaymentButton = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyCoursesThunk.pending, (state) => {
        state.courses = null;
      })
      .addCase(getMyCoursesThunk.fulfilled, (state, { payload }) => {
        state.courses = payload;
      })
      .addCase(getCourseByIdThunk.fulfilled, (state, { payload }) => {
        state.course = payload;
      })
      .addCase(getCourseByIdPublicThunk.fulfilled, (state, { payload }) => {
        state.publicCourse = payload;
      })
      .addCase(getActiveCourseThunk.fulfilled, (state, { payload }) => {
        state.activeCourse = { ...state.activeCourse, ...payload };
      })
      .addCase(setActiveCourseThunk.fulfilled, (state, { payload }) => {
        state.activeCourse = payload;
        state.courses =
          state.courses?.map((course) =>
            course.id === payload.id
              ? payload
              : {
                  ...course,
                  isActive: false,
                },
          ) || null;
      })
      .addCase(getCheatSheetThunk.fulfilled, (state, { payload }) => {
        state.cheatSheetUrl = payload.url || '';
      })
      .addCase(checkPromoCodeThunk.fulfilled, (state, { payload }) => {
        state.promocode = payload.valid;
      })
      .addCase(getTextBookThunk.fulfilled, (state, { payload }) => {
        state.textBookUrl = payload.url || '';
      })
      .addCase(subscribeCourseThunk.fulfilled, (state, { payload }) => {
        state.activeCourse = payload;
        state.course = payload;

        state.courses =
          state.courses?.map((course) =>
            course.id === payload.id
              ? payload
              : {
                  ...course,
                  isActive: false,
                },
          ) || null;
      })
      .addCase(subscribeCourseFromTrialThunk.fulfilled, (state, { payload }) => {
        state.activeCourse = payload;
        state.course = payload;

        state.courses =
          state.courses?.map((course) =>
            course.id === payload.id
              ? payload
              : {
                  ...course,
                  isActive: false,
                },
          ) || null;
      })
      .addCase(changeCourseSubscribeThunk.fulfilled, (state, { payload }) => {
        state.activeCourse = payload;
        state.course = payload;

        state.courses =
          state.courses?.map((course) =>
            course.id === payload.id
              ? payload
              : {
                  ...course,
                  isActive: false,
                },
          ) || null;
      })
      .addCase(purchaseCourseThunk.fulfilled, (state, { payload }) => {
        state.activeCourse = payload;
        state.course = payload;

        state.courses =
          state.courses?.map((course) =>
            course.id === payload.id
              ? payload
              : {
                  ...course,
                  isActive: false,
                },
          ) || null;
      })
      .addCase(purchaseCourseFromTrialThunk.fulfilled, (state, { payload }) => {
        state.activeCourse = payload;
        state.course = payload;

        state.courses =
          state.courses?.map((course) =>
            course.id === payload.id
              ? payload
              : {
                  ...course,
                  isActive: false,
                },
          ) || null;
      })
      .addCase(unsubscribeCourseThunk.fulfilled, (state, { payload }) => {
        if (state.course?.id === payload.id) {
          state.course = payload;
        }
        if (state.activeCourse?.id === payload.id) {
          state.activeCourse = payload;
        }

        state.courses = state.courses?.map((course) => (course.id === payload.id ? payload : course)) || null;
      })
      .addCase(getCoursesThunk.pending, (state, { meta }) => {
        const isFirstPage = !meta.arg.page || meta.arg.page === 1;

        if (isFirstPage) {
          state.courses = null;
          state.meta = null;
        }
      })
      .addCase(getCoursesThunk.fulfilled, (state, { payload }) => {
        const { items, meta } = payload.data;
        const courses = state.courses || [];

        state.courses = payload.isFirstFetch ? items : [...courses, ...items];
        state.meta = meta;
      })
      .addCase(getActiveCourseExamDateThunk.fulfilled, (state, { payload }) => {
        state.examDate = payload;
      })
      .addCase(setActiveCourseExamDateThunk.fulfilled, (state, { payload: { examDate } }) => {
        state.examDate = examDate;
      })
      .addCase(getCheckPurchaseThunk.fulfilled, (state, { payload }) => {
        state.isPurchased = payload.isPurchased;
      })
      .addCase(pauseSubscriptionThunk.fulfilled, (state, { payload }) => {
        const course = state?.courses?.find((course) => course.id === state.idCourse);

        if (course && course.currentPayment) {
          course.currentPayment.onPauseUntil = payload.onPauseUntil;
        }
      })
      .addCase(unpauseSubscriptionThunk.fulfilled, (state, { payload }) => {
        const course = state?.courses?.find((course) => course.id === state.idCourse);
        if (course && course.currentPayment) {
          course.currentPayment.onPauseUntil = payload.onPauseUntil;
        }
      })
      .addMatcher(
        isAnyOf(cancelSubscriptionDowngradeThunk.fulfilled, cancelSubscriptionTrialDowngradeThunk.fulfilled),
        (state, { payload }) => {
          state.activeCourse = payload;
          state.course = payload;

          state.courses = state.courses?.map((course) => (course.id === payload.id ? payload : course)) || null;
        },
      );
  },
});

export const actions = {
  ...coursesSlice.actions,
  getMyCourses: getMyCoursesThunk,
  subscribeCourse: subscribeCourseThunk,
  subscribeCourseFromTrial: subscribeCourseFromTrialThunk,
  purchaseCourse: purchaseCourseThunk,
  purchaseCourseFromTrial: purchaseCourseFromTrialThunk,
  getCourseById: getCourseByIdThunk,
  getCourseByIdPublic: getCourseByIdPublicThunk,
  getActiveCourse: getActiveCourseThunk,
  setActiveCourse: setActiveCourseThunk,
  resetCourseResults: resetCourseResultsThunk,
  getCheatSheet: getCheatSheetThunk,
  getTextBook: getTextBookThunk,
  getCourses: getCoursesThunk,
  unsubscribeCourse: unsubscribeCourseThunk,
  changeCourseSubscribe: changeCourseSubscribeThunk,
  cancelSubscriptionDowngrade: cancelSubscriptionDowngradeThunk,
  cancelSubscriptionTrialDowngrade: cancelSubscriptionTrialDowngradeThunk,
  getActiveCourseExamDate: getActiveCourseExamDateThunk,
  setActiveCourseExamDate: setActiveCourseExamDateThunk,
  getCheckPurchase: getCheckPurchaseThunk,
  checkPromoCodeThunk: checkPromoCodeThunk,
  newSubscribeCourse: newSubscribeCourseThunk,
};

export const { reducer } = coursesSlice;
