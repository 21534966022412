import { useClientSize } from 'hooks';
import { useMemo } from 'react';
import { selectors, useAppSelector } from 'store';
import { css } from 'styled-components';
import { widthBreakpoints } from 'styles/general/variables';

const MINIMUM_COLUMN_ITEMS = 10;

/**
 * This hook calculates all the domains or chapters (items)
 * and decides how many columns will be on the page (1, 2 or 3).
 * Then hook splits items into columns in order.
 * Also, it depends on the page width (breakpoints). The count of
 * columns might be 3, 2 or 1 only.
 * A minimum count of items in the column is 10. When column overflowed
 * the items are moved to the next column.
 * If count of items more than 10 in each column - behavior changes.
 * @return columns
 */
const useGetColumns = () => {
  const domainsAndChapters = useAppSelector((state) => selectors.tests.selectDomainsAndBanks(state));
  const activeTab = useAppSelector(selectors.tests.selectActiveTab);
  const { getIsBreakpoint, width: appWidth } = useClientSize();

  const length = domainsAndChapters?.length;
  const width = appWidth || 0;
  const isWidthXl = getIsBreakpoint('xl');
  const isWidthLg = getIsBreakpoint('lg');
  const isWidthHigherXl = width > widthBreakpoints['xl'];
  const isWidthHigherLg = width > widthBreakpoints['lg'];

  const rootCSS = useMemo(() => {
    if (isWidthHigherXl) {
      return css`
        grid-template-columns: repeat(3, 1fr);
      `;
    } else if (isWidthHigherLg) {
      return css`
        grid-template-columns: repeat(2, 1fr);
      `;
    }

    return css`
      grid-template-columns: 1fr;
    `;
  }, [isWidthHigherXl, isWidthHigherLg]);

  const columns = useMemo(() => {
    if (!length) {
      return {
        firstColumn: [],
        secondColumn: [],
        thirdColumn: [],
      };
    }

    if (isWidthLg || length <= MINIMUM_COLUMN_ITEMS) {
      return {
        firstColumn: domainsAndChapters,
        secondColumn: [],
        thirdColumn: [],
      };
    } else if (length <= MINIMUM_COLUMN_ITEMS * 2) {
      return {
        firstColumn: domainsAndChapters.slice(0, MINIMUM_COLUMN_ITEMS),
        secondColumn: domainsAndChapters.slice(MINIMUM_COLUMN_ITEMS),
        thirdColumn: [],
      };
    } else if (length <= MINIMUM_COLUMN_ITEMS * 3 && !isWidthXl) {
      const firstColumn = domainsAndChapters.slice(0, MINIMUM_COLUMN_ITEMS);
      const secondColumn = domainsAndChapters.slice(MINIMUM_COLUMN_ITEMS, MINIMUM_COLUMN_ITEMS * 2);
      const thirdColumn = domainsAndChapters.slice(MINIMUM_COLUMN_ITEMS * 2);

      return { firstColumn, secondColumn, thirdColumn };
    } else {
      const index = isWidthXl ? Math.ceil(length / 2) : Math.ceil(length / 3);
      const firstColumn = domainsAndChapters.slice(0, index);
      const secondColumn = isWidthXl ? domainsAndChapters.slice(index) : domainsAndChapters.slice(index, index * 2);
      const thirdColumn = isWidthXl ? [] : domainsAndChapters.slice(index * 2);

      return { firstColumn, secondColumn, thirdColumn };
    }
  }, [isWidthXl, isWidthLg, activeTab]);

  return { columns, isWidthHigherXl, isWidthHigherLg, rootCSS, activeTab };
};

export default useGetColumns;
