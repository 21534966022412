import Button from 'UI/Button';
import Input from 'UI/Input';
import { DefaultModal } from 'UI/Modals';
import StyledText from 'UI/StyledText';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps, DefaultRejectValue } from 'types';
import { validations } from 'utils';

export type FormState = {
  email: string;
};

type ForgotPasswordModalProps = {
  goBack?: () => void;
  isLoading?: boolean;
  restorePassword?: (values: FormState) => Promise<void>;
} & BaseModalProps;

const ForgotPasswordModal: FC<ForgotPasswordModalProps> = ({ restorePassword, goBack, isLoading, ...props }) => {
  const { handleSubmit, control, setError, reset } = useForm<FormState>({
    mode: 'onChange',
    defaultValues: { email: '' },
  });

  const onSubmit = async (values: FormState) => {
    try {
      await restorePassword?.(values);
      reset();
    } catch (error) {
      const errorMessage = (error as DefaultRejectValue).message as string;
      setError('email', { message: errorMessage });
    }
  };

  const closeModal = () => {
    goBack?.();
    reset();
  };

  return (
    <DefaultModal
      {...props}
      onRequestClose={closeModal}
      title="Forgot your password?"
      containerCSS={ContainerCSS}
      changeCloseButton
      modalCSS={ModalCSS}
      isMobileOverlayStyles>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Text>Enter your email, and we will send you a new password</Text>
        <Controller
          control={control}
          name="email"
          rules={{
            validate: validations.email,
            required: 'Required field',
          }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              label="Email"
              error={fieldState.error?.message}
              onChange={(e) => field.onChange(e.target.value.trim())}
            />
          )}
        />
        <StyledButton variant="primary" size="middle" type="submit" isLoading={isLoading}>
          Confirm
        </StyledButton>
      </Form>
    </DefaultModal>
  );
};

export default ForgotPasswordModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  margin-bottom: 44px;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[4]};
    ${typography.body_basic_medium_14}

    ${respondToWidth.sm`
    text-align: start;
    margin-bottom: 24px;
    color: ${colors.neutrals[1]};
`}
  `};
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledButton = styled(Button)`
  margin-top: 40px;

  ${respondToWidth.s`
    margin-top: auto;
  `}
`;
