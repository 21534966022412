import { CardButton, StyledText } from 'UI';
import { CustomerFlashcardAnswerType, FlashcardNextIntervals } from 'api/generated';
import { useAsyncAction, useGetIsMobile, useIsLandscapeOrientation, useNotifications } from 'hooks';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';
import { FlashcardsPackType } from 'types';
import { renderIntervalText } from 'utils/dateHelpers';

import { Content } from '../Content';

type QuestionProps = {
  questionImage?: { url: string; alt: string } | null;
  questionText?: string;
  answerImage?: { url: string; alt: string } | null;
  answerText?: string;
  id: string;
  showNextQuestion: () => void;
  nextIntervals?: FlashcardNextIntervals;
  isLoading: boolean;
  completePack: () => void;
  packType: FlashcardsPackType;
};

const QuestionAndAnswer: FC<QuestionProps> = ({
  questionImage,
  questionText,
  answerImage,
  answerText,
  id,
  showNextQuestion,
  nextIntervals,
  isLoading,
  completePack,
  packType,
}) => {
  const { errorToast } = useNotifications();
  const [isHidden, setIsHidden] = useState(false);
  const isFullWidthContainer = !(answerImage && answerText);
  const answerRef = useRef<HTMLDivElement | null>(null);
  const [activeCardButton, setActiveCardButton] = useState<number | undefined>(undefined);

  const [answerFlashcardAction, isFlashcardsLoading] = useAsyncAction(actions.flashcards.answerFlashcard);
  const flashcardsMeta = useAppSelector(selectors.flashcards.selectFlashcardsMeta);
  const currentIndex = useAppSelector(selectors.flashcards.selectCurrentFlashcardIndex);

  const isCurrentIndex = typeof currentIndex === 'number';

  const canSwitchToNext = isCurrentIndex && currentIndex < (flashcardsMeta?.totalItems || 0) - 1;

  const switchToNext = () => {
    if (canSwitchToNext) {
      setIsHidden(true);
      showNextQuestion();
    }
  };

  const answerFlashcard = async (answer: CustomerFlashcardAnswerType) => {
    switchToNext();

    try {
      await answerFlashcardAction({ flashcardId: id, answer: { packType, answer } });
      if (!canSwitchToNext) {
        completePack();
      }
    } catch (error) {
      errorToast('Something went wrong');
    } finally {
      setActiveCardButton(undefined);
    }
  };

  const buttonVariants = useMemo(
    () => [
      {
        variant: ButtonVariants.negative,
        text: renderIntervalText(nextIntervals?.no),
        answer: () => answerFlashcard(CustomerFlashcardAnswerType.No),
        isLoading: activeCardButton === 0,
      },
      {
        variant: ButtonVariants.neutral,
        text: renderIntervalText(nextIntervals?.kinda),
        answer: () => answerFlashcard(CustomerFlashcardAnswerType.Kinda),
        isLoading: activeCardButton === 1,
      },
      {
        variant: ButtonVariants.positive,
        text: renderIntervalText(nextIntervals?.yes),
        answer: () => answerFlashcard(CustomerFlashcardAnswerType.Yes),
        isLoading: activeCardButton === 2,
      },
    ],
    [activeCardButton],
  );

  const scrollToAnswer = () => {
    answerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(scrollToAnswer, []);

  const { isMobile } = useGetIsMobile();
  const { isLandscapeOrientation } = useIsLandscapeOrientation();

  const isMobileLandscape = isMobile && isLandscapeOrientation;

  return (
    <Root $isMobileLandscape={isMobileLandscape} $isHidden={isHidden}>
      <QuestionContainer>
        <Title>Question</Title>
        <ContentContainer>
          <Content image={questionImage} text={questionText} isQuestion />
        </ContentContainer>
        <HelperBlock />
      </QuestionContainer>
      <AnswerContainer ref={answerRef}>
        <Title>Answer</Title>
        <AnswerContentContainer $isFullWidthContainer={isFullWidthContainer}>
          <Content image={answerImage} text={answerText} isAnswer />
        </AnswerContentContainer>
        <ButtonsContainer>
          {buttonVariants.map((item, index) => (
            <ButtonContainer key={index}>
              <StyledCardButton
                disabled={isLoading || isFlashcardsLoading}
                variant={item.variant}
                onClick={() => {
                  setActiveCardButton(index);
                  item.answer();
                }}
                isLoading={item.isLoading}
              />
              <ButtonSubtitle>{item.text}</ButtonSubtitle>
            </ButtonContainer>
          ))}
        </ButtonsContainer>
      </AnswerContainer>
    </Root>
  );
};

export default QuestionAndAnswer;

enum ButtonVariants {
  positive = 'positive',
  negative = 'negative',
  neutral = 'neutral',
}

const StyledCardButton = styled(CardButton)`
  ${({ theme: { typography } }) => typography.body_basic_bold_14};
`;

const ButtonSubtitle = styled(StyledText)`
  ${({ theme: { typography } }) => typography.footnote_semibold_12};
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 190px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  ${respondToWidth.lg`
    width: 90px;
  `}
`;

const ButtonsContainer = styled.div`
  max-width: 600px;
  display: flex;
  gap: 16px;

  ${respondToWidth.sm`
    width: 295px;
  `}
`;

const AnswerContentContainer = styled.div<{ $isFullWidthContainer: boolean }>`
  width: ${({ $isFullWidthContainer }) => ($isFullWidthContainer ? '100%' : '376px')};
  flex-grow: 1;
  max-height: 400px;
  overflow-y: auto;

  ${respondToWidth.ls`
    width: 100%;
    min-height: fit-content;
  `}
`;

const ContentContainer = styled.div`
  width: 376px;
  flex-grow: 1;
  max-height: 400px;
  overflow: auto;

  ${respondToWidth.ls`
    width: 100%;
    min-height: fit-content;
  `}
`;

const Title = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.body_basic_semibold_14};
  `}
`;

const QuestionContainer = styled.div`
  display: flex;
  padding: 25px 52px;
  padding-bottom: 0;
  width: 479px;
  gap: 34px;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${({ theme: { colors } }) => respondToWidth.ls`
    border-radius: 20px 20px 0 0;
    border-right: none;
    border-bottom: 1px solid ${colors.neutrals[9]};
    width: 100%;
    padding: 0;
    padding-block: 24px 40px;
    gap: 24px;
  `}

  ${respondToWidth.sm`
    width: 100%;
  `}
`;

const AnswerContainer = styled.div`
  display: flex;
  padding: 25px 52px;
  padding-bottom: 0;
  width: 100%;
  gap: 34px;
  max-width: 704px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.lg`
    width: 590px;
  `}

  ${respondToWidth.ls`
    border-radius: 0 0 20px 20px;
    width: 100%;
    padding: 40px 0 32px;
  `}

  ${respondToWidth.sm`
    width: 100%;
    padding-block: 40px 32px;
    gap: 24px;
  `}
`;

const Root = styled.div<{ $isHidden: boolean; $isMobileLandscape: boolean }>`
  display: flex;
  padding-block: 27px;
  width: 1184px;
  min-height: 480px;
  border-radius: 20px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      position: absolute;
      top: 0;
    `}

  margin-left: ${({ $isHidden }) => ($isHidden ? '120%' : '0')};

  transform: unset;
  transition: all 0.5s ease;

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      transform: rotate(45deg);

      ${respondToWidth.sm`
        transform: rotate(25deg);
        opacity: 0;
        transition: all 0.3s ease;
      `};
    `}

  ${respondToHeight.xs`
    min-height:auto;
  `}

  ${respondToWidth.lg`
    width: 958px;
  `}

  ${respondToWidth.ls`
    flex-direction: column;
    width: 590px;
    min-height:auto;
    height: auto;
    padding: 0 24px;
  `}

  ${respondToWidth.sm`
    width: calc(100vw - 32px);
    min-height: fit-content;
  `}
`;

const HelperBlock = styled.div`
  height: 64px;
  width: 100%;
  ${respondToWidth.ls`
    display:none;
      `}
`;
