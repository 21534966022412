import { useGetMe } from 'hooks';
import React, { FC, ReactNode } from 'react';

type UserProviderProps = {
  children: ReactNode;
};

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  useGetMe();

  return <>{children}</>;
};

export default UserProvider;
