import { ScrollbarContainer } from 'UI';
import { Loader } from 'components';
import Sidebar from 'components/Sidebar';
import React, { FC, ReactNode, RefObject, UIEventHandler } from 'react';
import styled, { CSSProp } from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';

type MainLayoutProps = {
  children?: ReactNode;
  className?: string;
  isLoading?: boolean;
  scrollContainerRef?: RefObject<HTMLDivElement>;
  onScroll?: UIEventHandler<HTMLDivElement>;
  mainCSS?: CSSProp;
};

const MainLayout: FC<MainLayoutProps> = ({ children, isLoading, className, scrollContainerRef, mainCSS, onScroll }) => {
  return (
    <Root>
      <Sidebar />
      <Main mainCSS={mainCSS} onScroll={onScroll} ref={scrollContainerRef}>
        <Container className={className}>{isLoading ? <Loader /> : children}</Container>
      </Main>
    </Root>
  );
};

export default MainLayout;

const Root = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);

  overflow: hidden;
  margin: 0;
  padding: 0;
  display: grid;
  place-items: center;
  gap: 28px;
  grid-template-columns: 148px 1fr;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
  ${respondToWidth.sm`
    grid-template-columns: 1fr;
    grid-template-rows: 47px 1fr;
    gap: 0;
    max-height: none;
  `}
`;

const Main = styled(ScrollbarContainer)`
  width: 100%;
  height: 100%;

  display: grid;
  place-items: center;

  ${respondToHeight.s`
    place-items: initial;
  `}
`;

const Container = styled.div`
  width: 100%;
  max-width: calc(100vw - 176px);
  height: 100%;

  ${respondToWidth.sm`
    max-width: 100%;
  `}
`;
