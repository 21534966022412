import React from 'react';

function Error() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Error screen</p>
      </header>
    </div>
  );
}

export default Error;
