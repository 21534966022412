import styled, { CSSProp, css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const Label = css<{ $color: string }>`
  + label {
    ::after {
      content: '';
      position: absolute;
      left: 7px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${({ $color }) => $color};
    }
  }
`;

export const HiddenCheckbox = styled.input<{
  $color: string;
  $isCorrect: boolean;
}>`
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  ${({ $isCorrect }) => $isCorrect && Label}
  &:checked {
    ${Label}
  }
`;

export const LabelInput = styled.label<{
  $CSS: CSSProp;
  $isDisabled?: boolean;
  $isDarkMode?: boolean;
}>`
  ${({ theme: { colors, typography }, $CSS, $isDisabled, $isDarkMode }) => css`
    display: flex;
    padding-left: 36px;
    user-select: none;
    color: ${colors.neutrals[1]};
    cursor: ${$isDisabled ? 'not-allowed' : 'pointer'};

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: ${$isDarkMode ? colors.neutrals[8] : colors.neutrals[10]};
    }

    ${typography.body_large_bold_16};
    ${$CSS};
  `};
`;
