import { Button, ScrollbarContainer, StyledText } from 'UI';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useIsLandscapeOrientation } from 'hooks/useIsLandscapeOrientation';
import { LayoutStyledProps, MnemonicCardPracticeFormType } from 'pages/MnemonicCardPractice/MnemonicCardPractice';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { actions, useAppDispatch } from 'store';
import { MnemonicCardType } from 'store/ducks/mnemonicCardsPacks/types';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import ControlBar from '../ControlBar';
import { Container, Main, Wrapper } from '../commonStyles';
import InputsList from './components/InputsList';

type QuestionProps = {
  cards: MnemonicCardType[];
  onClick: () => void;
  onNextClick: () => void;
  onPrevClick: () => void;
  onReportClick: () => void;
  isFirstSlide: boolean;
  isLastSlide: boolean;
  isFlipped: boolean;
  activeIndex: number;
};

export enum FieldName {
  Answer = 'answer',
  Word = 'word',
  None = '',
}

const MnemonicQuestion: FC<QuestionProps> = ({
  onClick,
  onPrevClick,
  onNextClick,
  onReportClick,
  isFirstSlide,
  isLastSlide,
  isFlipped,
  activeIndex,
  cards,
}) => {
  const [isActive, setIsActive] = useState<FieldName>(FieldName.None);

  const dispatch = useAppDispatch();

  const initialValue = cards[activeIndex].inputState || {};

  const methods = useForm<MnemonicCardPracticeFormType>({
    defaultValues: initialValue,
    mode: 'onChange',
  });
  const { watch, handleSubmit } = methods;

  const isAnswerActive = isActive === FieldName.Answer;
  const isWordActive = isActive === FieldName.Word;
  const { isLandscapeOrientation } = useIsLandscapeOrientation();
  const { isMobile } = useGetIsMobile();
  const isMobileLandscape = isLandscapeOrientation && isMobile;

  const onSubmit = (data: MnemonicCardPracticeFormType) => {
    dispatch(actions.mnemonicCards.setMnemonicCardPracticeStore({ id: cards[activeIndex].id, formState: data }));
  };

  const clearInputs = () => {
    const emptyFormState = {} as { [key: string]: string };

    for (const key in initialValue) {
      emptyFormState[key] = '';
    }

    methods.reset(emptyFormState);
  };

  useEffect(() => {
    const subscription = watch(() => {
      handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <FormProvider {...methods}>
      <Container
        $isMobileLandscape={isMobileLandscape}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}>
        <Main $isLastSlide={isLastSlide} $isMobileLandscape={isMobileLandscape}>
          <Wrapper $isMobileLandscape={isMobileLandscape}>
            <ScrollbarContainer>
              <Title $isMobileLandscape={isMobileLandscape} $isLastSlide={isLastSlide}>
                {cards[activeIndex].title}
              </Title>
              <Cover $isMobileLandscape={isMobileLandscape}>
                <Text $isMobileLandscape={isMobileLandscape} $isActive={isAnswerActive}>
                  Answers to question
                </Text>
                <Text $isMobileLandscape={isMobileLandscape} $isActive={isWordActive}>
                  Mnemonic words
                </Text>
              </Cover>
              <InputsList
                amountInputs={cards[activeIndex].answersCount}
                setActiveState={setIsActive}
                clearInputs={clearInputs}
              />
              <ClearButton variant="tertiary" size="small" onClick={clearInputs} $isMobileLandscape={isMobileLandscape}>
                Clear inputs
              </ClearButton>
            </ScrollbarContainer>
          </Wrapper>
        </Main>
        <ControlBar
          onShowClick={onClick}
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
          isFirstSlide={isFirstSlide}
          isLastSlide={isLastSlide}
          isFlipped={isFlipped}
          onReportClick={onReportClick}
        />
      </Container>
    </FormProvider>
  );
};

export default MnemonicQuestion;

const Title = styled(StyledText)<LayoutStyledProps>`
  text-align: start;
  padding-bottom: 24px;
  margin-right: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ theme: { typography }, $isMobileLandscape, $isLastSlide }) => css`
    ${typography.title_3_bold_28};
    ${$isMobileLandscape && typography.body_basic_bold_14};
    ${respondToWidth.x`
    max-width: ${$isLastSlide && '768px'};
  `}
    ${respondToWidth.lg`
    max-width: ${$isLastSlide && '640px'};
  `}
   ${respondToWidth.ls`
    max-width: ${$isLastSlide && '550px'};
    margin-bottom: ${$isLastSlide && '8px'};
  `}
   ${respondToWidth.sl`
    max-width: ${$isLastSlide && '450px'};
  `}

  ${respondToWidth.sm`
      max-width: 100%;
      margin-right: 12px;
      ${typography.body_basic_bold_14}
    `}
  `}
`;

const Cover = styled.div<LayoutStyledProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 24px;
  margin-bottom: ${({ $isMobileLandscape }) => ($isMobileLandscape ? '8px' : '24px')};
`;

const Text = styled(StyledText)<LayoutStyledProps>`
  width: 100%;
  ${({ theme: { typography, colors }, $isActive, $isMobileLandscape }) => css`
    ${typography.body_large_medium_16};

    ${$isMobileLandscape &&
    css`
      color: ${$isActive && colors.primary[1]};
      text-align: center;
      ${typography.footnote_semibold_12}
    `}

    ${respondToWidth.sm`
      color: ${$isActive && colors.primary[1]};
      text-align: center;
      ${typography.footnote_semibold_12}
    `}
  `}
`;

const ClearButton = styled(Button)<LayoutStyledProps>`
  width: 147px;
  display: ${({ $isMobileLandscape }) => $isMobileLandscape && 'none'};
  align-self: end;
  white-space: nowrap;
  margin: 32px 0 40px auto;

  ${respondToWidth.sm`
    display: none;
  `}
`;
