import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgUserIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.938 16.724a7.999 7.999 0 1 0 6.123-14.78 7.999 7.999 0 0 0-6.123 14.78ZM28 27.33a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-.571c0-1.705.79-3.34 2.197-4.546C7.603 21.007 9.51 20.33 11.5 20.33h9c1.99 0 3.897.677 5.303 1.883C27.21 23.419 28 25.053 28 26.759v.571Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgUserIcon);
export default Memo;
