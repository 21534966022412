import { GetQuestionChaptersResponseItem, GetQuestionDomainsResponseItem } from 'api/generated';
import React, { FC } from 'react';
import styled, { CSSProp } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { TestListTabs } from 'types';

import Subject from '../Subject/Subject';
import { useGetColumns } from './hooks';

type SubjectsListProps = {
  isTutor: boolean;
  onOpenUpgradeModal?: () => void;
};

const SubjectsList: FC<SubjectsListProps> = ({ isTutor, onOpenUpgradeModal }) => {
  const {
    columns: { firstColumn, secondColumn, thirdColumn },
    isWidthHigherXl,
    isWidthHigherLg,
    rootCSS,
    activeTab,
  } = useGetColumns();

  const field = activeTab === TestListTabs.DOMAINS ? 'domainsQuestions' : 'chaptersQuestions';

  return (
    <Root $CSS={rootCSS}>
      {/* FIRST COLUMN*/}
      <Column>
        {firstColumn?.map((subject, index) => (
          <SubjectContainer key={subject.id}>
            <Subject
              field={field}
              isAboveTooltip={index !== 0}
              isLeftTooltip={!isWidthHigherLg && !isWidthHigherXl}
              subject={subject}
              isTutor={isTutor}
              onLockClick={onOpenUpgradeModal}
            />
          </SubjectContainer>
        ))}
      </Column>

      {/* SECOND COLUMN*/}
      {isWidthHigherLg && (
        <Column>
          {secondColumn?.map(
            (subject: GetQuestionChaptersResponseItem | GetQuestionDomainsResponseItem, index: number) => (
              <SubjectContainer key={subject.id}>
                <Subject
                  field={field}
                  isAboveTooltip={index !== 0}
                  isLeftTooltip={isWidthHigherLg && !isWidthHigherXl}
                  subject={subject}
                  isTutor={isTutor}
                  onLockClick={onOpenUpgradeModal}
                />
              </SubjectContainer>
            ),
          )}
        </Column>
      )}

      {/* THIRD COLUMN*/}
      {isWidthHigherXl && (
        <Column>
          {thirdColumn?.map((subject, index) => (
            <SubjectContainer key={subject.id}>
              <Subject
                field={field}
                isAboveTooltip={index !== 0}
                isLeftTooltip
                subject={subject}
                isTutor={isTutor}
                onLockClick={onOpenUpgradeModal}
              />
            </SubjectContainer>
          ))}
        </Column>
      )}
    </Root>
  );
};

export default SubjectsList;

const Root = styled.div<{ $CSS: CSSProp }>`
  width: 100%;
  display: grid;
  gap: 20px;
  margin-top: 24px;

  ${({ $CSS }) => $CSS};
`;

const SubjectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;

  ${respondToWidth.ls`
    margin: 0 auto 20px;
  `}

  ${respondToWidth.sm`
    margin: 0 0 16px 0;
  `}
`;

const Column = styled.div`
  width: 100%;
`;
