import { COLORS } from 'constant';
import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgArrowDown: FC<SVGIconProps> = ({ color = COLORS.grayscaleBlack, ...props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_107_2983)">
        <path d="M12 13.95 16.95 9l1.414 1.414L12 16.778l-6.364-6.364L7.05 9 12 13.95Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_107_2983">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgArrowDown);
export default Memo;
