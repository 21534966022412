import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  AnswerQuestionRequestDto,
  AnswerQuestionResponseDto,
  FinishedPassageResponseDto,
  GetPassageResultsResponseDto,
  GetQuestionChaptersResponseItem,
  GetQuestionDomainsResponseItem,
  LeastKnownCategoryResponseDto,
  MarkedQuestionResponseDto,
  QuizTestPassageResponseDto,
  StartTutorTestPassageRequestDto,
  TestPassagesControllerGetLastUncompletedPassage200Response,
  TimedTestPassageResponseDto,
  TimedTestResponseDto,
  TutorTestPassageResponseDto,
} from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType, PaginationType } from 'types';

// export const mockApiRequest = async (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any) => ({
//   status: 400,
//   data: [],
// });

export const getDomainsQuestionsThunk = createAsyncThunk<
  Array<GetQuestionChaptersResponseItem | GetQuestionDomainsResponseItem>,
  { courseId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/getDomainsQuestions', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.QuestionDomains.questionDomainsControllerGetQuestionDomains({ courseId });

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getChaptersQuestionsThunk = createAsyncThunk<
  Array<GetQuestionChaptersResponseItem | GetQuestionDomainsResponseItem>,
  { courseId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/getChaptersQuestions', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.QuestionChapters.questionChaptersControllerGetMany({ courseId });

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const startTutorTestThunk = createAsyncThunk<
  TutorTestPassageResponseDto,
  StartTutorTestPassageRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/startTutorTest', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.TutorTests.tutorTestsControllerStartTutorTest(body);

    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getQuizTestThunk = createAsyncThunk<
  QuizTestPassageResponseDto,
  string,
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/passage/quiz', async (quizId, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Quizzes.quizzesControllerStartQuiz(quizId);

    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const startTimedTestThunk = createAsyncThunk<
  TimedTestPassageResponseDto,
  { timedTestId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/startTimedTest', async ({ timedTestId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.TimedTests.timedTestsControllerStartTimedTest(timedTestId);

    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getUncompletedTestThunk = createAsyncThunk<
  TestPassagesControllerGetLastUncompletedPassage200Response,
  undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/getUncompletedTest', async (_body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Tests.testPassagesControllerGetLastUncompletedPassage();

    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getNextTimedTestThunk = createAsyncThunk<
  TimedTestResponseDto,
  undefined,
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/getNextTimedTest', async (_body, { rejectWithValue, getState }) => {
  try {
    const state = getState();

    if (!state.courses.activeCourse?.id) {
      throw 'Active course is not found';
    }

    const { data, status } = await api.TimedTests.timedTestsControllerGetNextTimedTest(state.courses.activeCourse?.id);

    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const finishTestThunk = createAsyncThunk<
  FinishedPassageResponseDto,
  { passageId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/finishTest', async ({ passageId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Tests.testPassagesControllerFinishPassage(passageId);
    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const restartTestThunk = createAsyncThunk<
  QuizTestPassageResponseDto | TimedTestPassageResponseDto | TutorTestPassageResponseDto,
  { passageId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/restartTest', async ({ passageId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Tests.testPassagesControllerRestartPassage(passageId);
    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const markedQuestionThunk = createAsyncThunk<
  MarkedQuestionResponseDto,
  { questionId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/markQuestion', async ({ questionId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Questions.markedQuestionsControllerCreate(questionId);

    if (status > 399) {
      throw data;
    }
    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const unmarkedQuestionThunk = createAsyncThunk<
  { questionId: string },
  { questionId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/unMarkQuestion', async ({ questionId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Questions.markedQuestionsControllerDelete(questionId);

    if (status > 399) {
      throw data;
    }
    return { questionId };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const saveAnswerThunk = createAsyncThunk<
  { data: AnswerQuestionResponseDto; isTutor: boolean },
  { body: AnswerQuestionRequestDto; isTutor: boolean; passageId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/saveAnswer', async ({ body, isTutor, passageId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Tests.testPassagesControllerAnswerQuestion(passageId, body);

    if (status > 399) {
      throw data;
    }
    return { data, isTutor };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getTestStatisticThunk = createAsyncThunk<
  { data: GetPassageResultsResponseDto; isFirstFetch: boolean },
  { id: string; onlyIncorrect?: boolean } & PaginationType,
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/getStatistic', async ({ page, id, limit = 30, onlyIncorrect = false }, { rejectWithValue }) => {
  try {
    const isFirstFetch = !page || page === 1;
    const { data, status } = await api.Tests.testPassagesControllerGetPassageResults(id, {
      page,
      limit,
      onlyIncorrect,
    });
    if (status > 399) {
      throw data;
    }
    return { data, isFirstFetch };
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getLeastKnowDomainsThunk = createAsyncThunk<
  Array<LeastKnownCategoryResponseDto>,
  { courseId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/getLeastKnowDomains', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.QuestionDomains.questionDomainsControllerGetLeastKnownDomains(courseId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getLeastKnowChaptersThunk = createAsyncThunk<
  Array<LeastKnownCategoryResponseDto>,
  { courseId: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('tests/getLeastKnowChapters', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.QuestionChapters.questionChaptersControllerGetLeastKnownChapters(courseId);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
