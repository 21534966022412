import { useAsyncAction, useInfinityScroll, useNotifications } from 'hooks';
import TestResultLayout from 'layouts/TestResultLayout';
import React, { useDeferredValue, useLayoutEffect, useRef, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Waypoint } from 'react-waypoint';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import Question from './components/Question/Question';

const TestResult = () => {
  const [isShowCorrect, setIsShowCorrect] = useState(false);
  const deferredIsShowCorrect = useDeferredValue(isShowCorrect);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(0);

  const { errorToast } = useNotifications();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const currentTestId = useAppSelector(selectors.tests.selectCurrentTestId);
  const statisticTestMeta = useAppSelector(selectors.tests.selectCurrentTestStatisticMeta);
  const statisticTestQuestion = useAppSelector(selectors.tests.selectCurrentTestStatisticQuestions);
  const isTestResultsFetching = useAppSelector(selectors.tests.selectIsTestResultsFetching);
  const [getTestStatisticAction] = useAsyncAction(actions.tests.getStatistic);

  const isEmpty = !statisticTestQuestion?.length;
  const nextPage = (statisticTestMeta?.currentPage || 0) + 1;
  const hasAfterMore = (statisticTestMeta?.currentPage || 0) < (statisticTestMeta?.totalPages || 0);

  const getTestStatistic = async (id: string, onlyIncorrect?: boolean, page?: number) => {
    try {
      await getTestStatisticAction({ id, onlyIncorrect, page });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  useLayoutEffect(() => {
    getTestStatistic(currentTestId || '', isShowCorrect, 1);
  }, [deferredIsShowCorrect]);

  const toggleIsShowCorrect = () => {
    setIsShowCorrect((prev) => !prev);
  };

  const selectAnswer = (index: number) => {
    setSelectedAnswerIndex(index);
    listRef.current?.children[index].scrollIntoView({ behavior: 'auto', block: 'start' });
  };

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getTestStatistic(currentTestId || '', isShowCorrect, nextPage),
    hasAfterMore: hasAfterMore,
    isLoading: isTestResultsFetching,
    outerRef: containerRef,
  });

  const { colors } = useTheme();

  return (
    <TestResultLayout
      isShowCorrect={isShowCorrect}
      toggleIsShowCorrect={toggleIsShowCorrect}
      onAnswerClick={selectAnswer}
      selectedIndex={selectedAnswerIndex}
      onScroll={handleScroll}
      isEmpty={isEmpty}>
      <AnswersList ref={listRef}>
        {statisticTestQuestion?.map((question, index) => (
          <Waypoint
            key={question.id}
            topOffset="10%"
            bottomOffset="80%"
            onEnter={() => setSelectedAnswerIndex(index)}
            fireOnRapidScroll={false}>
            <Question index={index} question={question} />
          </Waypoint>
        ))}
        {isTestResultsFetching && (
          <LoaderContainer>
            <ThreeDots color={colors.primary[3]} />
          </LoaderContainer>
        )}
        {isEmpty && !isTestResultsFetching && <Empty>You have not answered any question yet</Empty>}
      </AnswersList>
    </TestResultLayout>
  );
};

export default TestResult;

const AnswersList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 24px;

  ${respondToWidth.sm`
  gap:16px;
`};
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
`;

const Empty = styled.div`
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[5]};
    ${typography.body_large_semibold_16};
  `}
`;
