import { StyledText } from 'UI';
import { ListCheckIcon, MarkPenIcon, TestIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { routes } from 'constant/routes';
import { useAsyncAction, useClientSize, useNotifications } from 'hooks';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { hideText } from 'utils';

type HeaderProps = {
  toggleContentModal: () => void;
  chapterIndex: number;
};

const Header: FC<HeaderProps> = ({ toggleContentModal, chapterIndex }) => {
  const { colors } = useTheme();
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const { getIsBreakpoint } = useClientSize();
  const { errorToast } = useNotifications();
  const dispatch = useAppDispatch();

  const isWidthSm = getIsBreakpoint('sm');
  const iconSize = isWidthSm ? 24 : 18;
  const navigate = useNavigate();

  const [getQuizTestAction, isQuizTestFetching] = useAsyncAction(actions.tests.getQuizTest);

  const currentChapter = useAppSelector(selectors.studyGuide.selectCurrentChapter);
  const isSelectingMode = useAppSelector(selectors.studyGuide.selectIsSelectingMode);

  const title = currentChapter?.name || '';

  const toggleIsSelectingMode = () => dispatch(actions.studyGuide.setIsSelectingMode(!isSelectingMode));

  const createQuizTest = async (quizId: string) => {
    try {
      await getQuizTestAction(quizId);
      navigate(`${routes.tutorTest}?chapterIndex=${chapterIndex}&quizId=${quizId}`);
    } catch (error) {
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    }
  };

  const iconButtons = [
    { Icon: isWidthSm ? ListCheckIcon : null, onClick: toggleContentModal },
    {
      Icon: TestIcon,
      onClick: () => currentChapter?.quizId && createQuizTest(currentChapter?.quizId),
      disabled: !currentChapter?.quizId,
      isLoading: isQuizTestFetching,
    },
    { Icon: MarkPenIcon, onClick: toggleIsSelectingMode, isActive: isSelectingMode, isMarkPen: true },
  ];

  const markPenIconColor = useMemo(
    () => (isSelectingMode ? colors.primary[1] : colors.neutrals[11]),
    [isDarkMode, isSelectingMode],
  );

  const iconColor = isDarkMode ? colors.neutrals[1] : colors.neutrals[11];

  return (
    <Root>
      <Title as="h3" $isDarkMode={isDarkMode}>
        {title}
      </Title>
      <ButtonsContainer>
        {iconButtons.map(
          ({ Icon, onClick, isLoading, disabled, isActive, isMarkPen }, index) =>
            Icon && (
              <Button
                $isActive={Boolean(isActive)}
                $isLoading={Boolean(isLoading)}
                $isMarkPen={Boolean(isMarkPen)}
                disabled={disabled || isLoading}
                key={index}
                onClick={onClick}>
                <Icon color={isMarkPen ? markPenIconColor : iconColor} width={iconSize} height={iconSize} />
              </Button>
            ),
        )}
      </ButtonsContainer>
    </Root>
  );
};

export default Header;

type ButtonProps = { $isLoading: boolean; $isActive: boolean; $isMarkPen: boolean };

const Root = styled.div`
  width: 100%;

  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme: { colors } }) => colors.primary[1]};

  ${respondToWidth.sm`
        padding: 18px 16px;
        gap: 28px;
  `}
`;

const Title = styled(StyledText)<{ $isDarkMode?: boolean }>`
  ${({ theme: { colors, typography }, $isDarkMode }) => css`
    text-align: start;
    user-select: none !important;
    color: ${$isDarkMode ? colors.neutrals[1] : colors.neutrals[11]};

    ${hideText(1)};
    ${typography.body_basic_bold_14}

    ${respondToWidth.sm`
        ${typography.body_large_bold_16}
    `}
  `};
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  button {
    display: flex;
  }

  ${respondToWidth.sm`
        gap: 28px;
   `}
`;

const Button = styled.button<ButtonProps>`
  ${({ theme: { colors }, $isLoading, $isActive, $isMarkPen }) => {
    const isMarkedPenBg = $isActive && $isMarkPen ? colors.neutrals[11] : 'none';

    return $isLoading
      ? css`
          cursor: wait !important;
        `
      : $isActive
      ? css`
          margin: -3px;
          padding: 3px;
          background-color: ${isMarkedPenBg};
          border-radius: 4px;
        `
      : '';
  }}
`;
