import { useClientSize } from 'hooks';

export const useCircleChartSize = () => {
  const { getBreakpoint, getIsHeightBreakpoint } = useClientSize();
  const isHeightLs = getIsHeightBreakpoint('ls');
  const breakpoint = getBreakpoint();

  const chartSizeMapping = {
    xl: 480,
    xm: 480,
    x: 480,
    l: isHeightLs ? 400 : 480,
    lg: 400,
    ls: 380,
    sl: 380,
    sm: 380,
    s: 310,
    xs: 310,
  };

  const innerRadiusMapping = {
    xl: 175,
    xm: 175,
    x: 175,
    l: isHeightLs ? 135 : 175,
    lg: 135,
    ls: 135,
    sl: 145,
    sm: 145,
    s: 110,
    xs: 110,
  };

  const outerRadiusMapping = {
    xl: 200,
    xm: 200,
    x: 200,
    l: isHeightLs ? 160 : 200,
    lg: 160,
    ls: 160,
    sl: 170,
    sm: 170,
    s: 130,
    xs: 130,
  };

  const containerSize = breakpoint ? chartSizeMapping[breakpoint] : 0;
  const innerRadiusSize = breakpoint ? innerRadiusMapping[breakpoint] : 0;
  const outerRadiusSize = breakpoint ? outerRadiusMapping[breakpoint] : 0;

  return { containerSize, innerRadiusSize, outerRadiusSize };
};
