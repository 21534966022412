import { Button, StyledText } from 'UI';
import { routes } from 'constant/routes';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useIsLandscapeOrientation } from 'hooks/useIsLandscapeOrientation';
import { LayoutStyledProps } from 'pages/MnemonicCardPractice/MnemonicCardPractice';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

const CompleteButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(routes.mnemonicCardList);
  };

  const { isLandscapeOrientation } = useIsLandscapeOrientation();
  const { isMobile } = useGetIsMobile();

  const isMobileLandscape = isLandscapeOrientation && isMobile;

  return (
    <Container $isMobileLandscape={isMobileLandscape}>
      <Title>This mnemonic is the last one in the chapter</Title>
      <StyledButton variant="tertiary" size="small" onClick={handleClick}>
        Complete practice
      </StyledButton>
    </Container>
  );
};

export default CompleteButton;

const Container = styled.div<LayoutStyledProps>`
  width: 216px;
  padding: 16px;
  border-radius: 12px;
  text-align: start;
  display: grid;
  gap: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  position: absolute;
  top: 24px;
  right: 40px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      grid-template-columns: 1fr 1fr;
      width: 100%;
      padding: 6px;
      position: initial;
      margin-bottom: 12px;
      place-items: center;
      box-shadow: none;
    `}

  ${respondToWidth.sm`
    grid-template-columns: 1fr 1fr;
    width:100%;
    position: initial;
    margin-bottom: 12px;
    place-items: center;
    box-shadow: none;
  `}
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

const StyledButton = styled(Button)`
  padding-inline: 0;
`;
