import Input from 'UI/Input';
import { TestsListFormType } from 'pages/Performance/components/Header/Header';
import React, { FC, InputHTMLAttributes } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { validations } from 'utils';

type SearchBarProps = {
  onSubmit: (value: TestsListFormType) => void;
};

const SearchBar: FC<SearchBarProps> = ({ onSubmit }) => {
  const { control, handleSubmit, getValues } = useFormContext<TestsListFormType>();

  return (
    <Form>
      <Controller
        name="startDate"
        control={control}
        rules={{ validate: validations.date, required: false }}
        render={({ field, fieldState: { error } }) => (
          <InputMask
            mask="99.99.9999"
            {...field}
            placeholder="MM.DD.YYYY"
            label="From"
            showIcon={Boolean(field.value?.length)}
            onIconClick={() => {
              field.onChange('');
              onSubmit(getValues());
            }}
            onBlur={handleSubmit(onSubmit)}>
            {(inputProps: InputHTMLAttributes<HTMLInputElement>) => (
              <StyledInput {...inputProps} error={error?.message} iconButtonType="submit" />
            )}
          </InputMask>
        )}
      />
      <Controller
        name="endDate"
        control={control}
        rules={{ validate: validations.date, required: false }}
        render={({ field, fieldState: { error } }) => (
          <InputMask
            mask="99.99.9999"
            {...field}
            placeholder="MM.DD.YYYY"
            label="To"
            showIcon={Boolean(field.value?.length)}
            onIconClick={() => {
              field.onChange('');
              onSubmit(getValues());
            }}
            onBlur={handleSubmit(onSubmit)}>
            {(inputProps: InputHTMLAttributes<HTMLInputElement>) => (
              <StyledInput {...inputProps} error={error?.message} iconButtonType="submit" />
            )}
          </InputMask>
        )}
      />
    </Form>
  );
};

export default SearchBar;
const StyledInput = styled(Input)`
  margin-top: 4px;
  margin-bottom: 24px;
`;

const Form = styled.div`
  display: flex;
  gap: 12px;

  ${respondToWidth.sm`
    gap: 9px;
  `}
`;
