import { MarkIcon } from 'assets/icons';
import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import { selectors, useAppSelector } from 'store';
import { useTheme } from 'styled-components';

import { HiddenCheckbox, InputContainer, LabelInput, StyledCheckBox, Wrapper } from './styled';

export enum CheckboxVariant {
  default = 'default',
  white = 'white',
}

type CheckboxProps = {
  label?: string;
  className?: string;
  isLoading?: boolean;
  variant?: CheckboxVariant;
} & InputHTMLAttributes<HTMLInputElement>;

const CheckBox: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (props, ref) => {
  const { colors } = useTheme();
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  const { checked, disabled, label, className, variant, isLoading = false, onClick, ...inputProps } = props;

  return (
    <Wrapper className={className}>
      <LabelInput $isLoading={isLoading} $isDisabled={disabled} $checked={Boolean(checked)}>
        <InputContainer>
          <StyledCheckBox
            $checked={Boolean(checked)}
            $isLoading={isLoading}
            $isDisabled={disabled}
            $variant={variant}
            $isDarkMode={isDarkMode}>
            {checked && <MarkIcon color={disabled ? colors.neutrals[6] : colors.primary[1]} />}
          </StyledCheckBox>
        </InputContainer>
        <HiddenCheckbox
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onClick={isLoading ? undefined : onClick}
          {...inputProps}
          ref={ref}
        />
        {label}
      </LabelInput>
    </Wrapper>
  );
};

export default React.forwardRef<HTMLInputElement, CheckboxProps>(CheckBox);
