import { StartTutorTestPassageRequestDto, StartTutorTestPassageRequestDtoModesEnum } from 'api/generated';
import { routes } from 'constant/routes';
import { useAsyncAction, useClientSize, useNotifications } from 'hooks';
import React, { Dispatch, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch } from 'store';

import useGetCategory from './useGetCategory';

type UseCategoryProps = {
  setModalVisible: Dispatch<React.SetStateAction<boolean>>;
};

const useCategory = ({ setModalVisible }: UseCategoryProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getIsBreakpoint } = useClientSize();
  const { errorToast } = useNotifications();
  const isBreakpointSm = getIsBreakpoint('sm');
  const [isLoading, setIsLoading] = useState(false);
  const { register, watch, resetField, handleSubmit } = useForm<FieldValues>();
  const [startTutorTestAction, isTutorTestLoading] = useAsyncAction(actions.tests.startTutorTest);
  const { leastKnowCategory, activeCourse, currentCategory } = useGetCategory();

  const handleCategoryModalClose = () => {
    resetField('id');
    setModalVisible(false);
  };

  const handleStartTest = async (data: FieldValues) => {
    const categories = Object.entries(data.id)
      ?.filter(([_, value]) => Boolean(value))
      ?.map(([key, _]) => key);

    const filteredCategories = currentCategory?.filter((category) => categories?.includes(category.id));
    const questionsAmount = filteredCategories?.reduce((acc, category) => acc + (Number(category?.incorrect) || 0), 0);

    const startTutorTestParams: StartTutorTestPassageRequestDto = {
      questionsAmount,
      modes: [StartTutorTestPassageRequestDtoModesEnum.Incorrect],
    };

    if (leastKnowCategory === 'domain') {
      startTutorTestParams.domainIds = categories;
    }

    if (leastKnowCategory === 'chapter') {
      startTutorTestParams.chapterIds = categories;
    }

    try {
      await startTutorTestAction(startTutorTestParams);
      dispatch(actions.tests.setTutorTestParams(startTutorTestParams));
      navigate(routes.tutorTest);
    } catch {
      errorToast('Something went wrong');
    }
  };

  const handleLoadKnowCategory = async () => {
    if (leastKnowCategory === 'domain' && activeCourse?.id) {
      setIsLoading(true);

      try {
        await dispatch(actions.tests.getLeastKnowDomains({ courseId: activeCourse.id }));
      } catch {
        errorToast('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    }

    if (leastKnowCategory === 'chapter' && activeCourse?.id) {
      setIsLoading(true);

      try {
        await dispatch(actions.tests.getLeastKnowChapters({ courseId: activeCourse.id }));
      } catch {
        errorToast('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (leastKnowCategory) {
      handleLoadKnowCategory();
    }
  }, []);

  return {
    isBreakpointSm,
    handleCategoryModalClose,
    isLoading,
    register,
    watch,
    handleSubmit,
    handleStartTest,
    isTutorTestLoading,
  };
};

export default useCategory;
