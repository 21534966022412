import { PaymentElement } from '@stripe/react-stripe-js';
import { BankCard, DefaultModal, StyledText } from 'UI';
import Button from 'UI/Button';
import { PaymentsSystemsEnums } from 'constant';
import { useNotifications, useStripeSetupPaymentMethodForm } from 'hooks';
import { StripeProvider } from 'providers';
import React, { FC, useEffect } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

type ChangePaymentMethodModalProps = { openDeletePaymentMethodModal: () => void } & BaseModalProps;

const ChangePaymentMethodModal: FC<ChangePaymentMethodModalProps> = ({
  onClose,
  openDeletePaymentMethodModal,
  ...props
}) => {
  const { successToast } = useNotifications();

  const { setElements, setStripe, clientSecret, handleSubmit, errorMessage, isLoading, isSuccess, isDisabled } =
    useStripeSetupPaymentMethodForm();

  const user = useAppSelector(selectors.user.selectUser);
  const paymentMethod = user?.paymentMethod;

  const onSuccess = async () => {
    onClose();
    successToast('Payment method changed successfully');
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  const onCloseModal = () => {
    if (!isLoading) {
      onClose();
    }
  };

  // const onOpenDeletePaymentMethodModal = () => {
  //   onCloseModal();
  //   openDeletePaymentMethodModal();
  // };

  return (
    <DefaultModal
      {...props}
      onRequestClose={onCloseModal}
      title="Change payment method"
      containerCSS={ContainerCSS}
      changeCloseButton
      modalCSS={ModalCSS}
      isMobileOverlayStyles>
      <Form onSubmit={handleSubmit}>
        <FormWrapper>
          <Description font="body_basic_medium_14">You can only save one card</Description>
          <BankCard
            label="Current card"
            onClick={onCloseModal}
            card={paymentMethod?.card?.last4 || ''}
            paymentSystem={paymentMethod?.card?.brand as PaymentsSystemsEnums}
          />
          <PaymentFieldsWrapper>
            <StripeProvider setStripe={setStripe} setElements={setElements} clientSecret={clientSecret}>
              <PaymentElement
                options={{
                  terms: { card: 'never' },
                }}
              />
            </StripeProvider>
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
          </PaymentFieldsWrapper>
        </FormWrapper>
        <ButtonsContainer>
          <StyledButton isLoading={isLoading} variant="primary" size="middle" type="submit" disabled={isDisabled}>
            Change card
          </StyledButton>
          {/* <TextButton disabled={isLoading} onClick={onOpenDeletePaymentMethodModal} text="Delete card" /> */}
        </ButtonsContainer>
      </Form>
    </DefaultModal>
  );
};

export default ChangePaymentMethodModal;

const ContainerCSS = css`
  width: 668px;
  height: fit-content;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 0 60px;

  ${respondToWidth.sm`
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height: 100%;
    padding: 16px 0;
    border-radius: 0;
  `}
`;

const ModalCSS = css`
  height: 100%;
  padding: 22px 0;
  overflow: hidden;

  display: flex;
  align-items: center;

  ${respondToWidth.sm`
    width: 90%;
  `}

  ${respondToWidth.s`
    padding: 0;
    width: 100%;
    height: 100%;
  `}
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  gap: 40px;
  overflow-y: auto;
  padding-inline: 100px;
  overflow-x: hidden;

  ${respondToWidth.sm`
      padding-inline: 16px;
  `}
`;

const Description = styled(StyledText)`
  width: 100%;

  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const PaymentFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ErrorText = styled(StyledText)`
  text-align: start;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.system.red};
    ${typography.body_basic_medium_14}

    ${respondToWidth.s`
      ${typography.footnote_semibold_12}
  `}
  `};
`;

const StyledButton = styled(Button)`
  ${respondToWidth.s`
    margin-top: auto;
  `}
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
