import { ColorTeg, StyledText } from 'UI';
import { LockIcon } from 'assets/icons';
import { useClientSize } from 'hooks';
import { ButtonHTMLAttributes, FC, memo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { stringCircumcision } from 'utils/textHelpers';

type MnemonicCardProps = {
  name: string;
  id: string;
  total: number;
  onClick: ((id: string) => void) | (() => void);
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

const MAX_LENGTH_TITLE = 253;

const MnemonicCard: FC<MnemonicCardProps> = ({ name, total, id, disabled, onClick, ...props }) => {
  const { getIsBreakpoint } = useClientSize();

  const isWidthSm = getIsBreakpoint('sm');
  const displayText = isWidthSm ? '' : 'cards';
  const formattedTitle = stringCircumcision(name, MAX_LENGTH_TITLE);

  const { colors } = useTheme();

  return (
    <Root $isDisabled={disabled} onClick={() => onClick(id)} {...props}>
      <Title>{formattedTitle}</Title>
      <StyledColorTeg
        count={total}
        text={displayText}
        showIcon={disabled}
        icon={<LockIcon size={18} color={colors.neutrals[4]} />}
      />
    </Root>
  );
};

export default memo(MnemonicCard);

const StyledColorTeg = styled(ColorTeg)`
  min-width: 158px;

  ${respondToWidth.sm`
   min-width: auto;
  `}
`;

const Title = styled(StyledText)`
  text-align: left;
  overflow: hidden;
  ${({ theme: { typography } }) => typography.body_large_bold_16};
`;

const Root = styled.button<{ $isDisabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  max-width: 1224px;
  width: 100%;
  padding: 16px 16px 16px 20px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 50%;
    `}

  ${respondToWidth.sm`
    padding: 8px 16px 8px 20px;
    gap: 16px;
  `}
`;
