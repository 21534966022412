import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgSuccessNotificationIcon: FC<SVGIconProps> = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={24} height={24} rx={4} fill="#55B47D" />
      <g clipPath="url(#clip0_408_42289)">
        <path d="m10.333 14.643 7.66-7.66 1.179 1.178L10.332 17 5.03 11.697l1.179-1.179 4.125 4.125Z" fill="#fff" />
      </g>
      <defs>
        <clipPath id="clip0_408_42289">
          <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgSuccessNotificationIcon);
export default Memo;
