import { routes } from 'constant/routes';
import { TechnicalWorks } from 'pages';
import { FC } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

const TechnicalRoutes: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={routes.technicalWorks} />} />
        <Route path={routes.technicalWorks} element={<TechnicalWorks />} />
      </Routes>
    </Router>
  );
};

export default TechnicalRoutes;
