import { TextButton } from 'UI';
import { PaymentDtoLevelEnum } from 'api/generated';
import { useFormWatch } from 'hooks';
import { SubjectFieldType, TestFormType } from 'pages/TestList/TestList';
import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { selectors, useAppSelector } from 'store';
import { useTheme } from 'styled-components';
import { TestListTabs } from 'types';
import { ModeType } from 'types';

type ToggleSelectedAllButtonProps = {
  isDisabled?: boolean;
};

enum Mode {
  all = 'all',
  unused = 'unused',
  incorrect = 'incorrect',
  marked = 'marked',
}

const modeAdapter: Record<ModeType, Mode> = {
  ALL: Mode.all,
  UNUSED: Mode.unused,
  INCORRECT: Mode.incorrect,
  MARKED: Mode.marked,
};

const ToggleSelectedAllButton: FC<ToggleSelectedAllButtonProps> = ({ isDisabled = false }) => {
  const { colors } = useTheme();
  const paymentLevel = useAppSelector(selectors.courses.selectCurrentPaymentLevel);
  const isGoldOrSilver = paymentLevel !== PaymentDtoLevelEnum.None && paymentLevel !== PaymentDtoLevelEnum.Bronze;
  const [isSelectAllDomainsQuestion, setIsSelectAllDomainsQuestion] = useState(false);
  const [isSelectAllChaptersQuestions, setIsSelectAllChaptersQuestions] = useState(false);
  const { setValue, setError } = useFormContext<TestFormType>();
  const activeTab = useAppSelector(selectors.tests.selectActiveTab);
  const domainsAndChapters = useAppSelector(selectors.tests.selectDomainsAndBanks);

  const { watch } = useFormContext<TestFormType>();
  const watchField = useFormWatch(watch);
  const modes = watchField('mode');
  const mode = modeAdapter[modes[0]];

  const getAvailableSubjectIds = () => {
    const availableSubjectIds = [];

    for (const data of domainsAndChapters) {
      const total = modes?.length === 1 ? data[mode].total : data.unused.total + data.incorrect.total;
      const free = modes?.length === 1 ? data[mode].free : data.unused.free + data.incorrect.free;
      const isDisabled = (!isGoldOrSilver && free === total) || (!isGoldOrSilver && free === 0);

      if (!isDisabled) {
        availableSubjectIds.push(data.id);
      }
    }

    return availableSubjectIds;
  };

  const selectAllBanks = () => {
    const subjectIds = getAvailableSubjectIds();
    setValue(field, subjectIds);
    setError(field, { message: '' });
  };

  const unselectAllBanks = () => {
    setValue(field, []);
  };

  const isDomainTab = activeTab === TestListTabs.DOMAINS;
  const field: SubjectFieldType = isDomainTab ? 'domainsQuestions' : 'chaptersQuestions';
  const isSelectedDomains = isDisabled ? true : isSelectAllDomainsQuestion;
  const isSelectedChapters = isDisabled ? true : isSelectAllChaptersQuestions;

  let customTextCSS;

  if (isDisabled) {
    customTextCSS = {
      color: colors.neutrals[5],
    };
  }

  const toggleSelectedAllDomainsQuestion = () => {
    setIsSelectAllDomainsQuestion((prev) => !prev);
    isSelectAllDomainsQuestion ? unselectAllBanks() : selectAllBanks();
  };

  const toggleSelectedAllChaptersQuestions = () => {
    setIsSelectAllChaptersQuestions((prev) => !prev);
    isSelectAllChaptersQuestions ? unselectAllBanks() : selectAllBanks();
  };

  if (isDomainTab) {
    return (
      <TextButton
        text={isSelectedDomains ? 'Unselect all' : 'Select all'}
        onClick={toggleSelectedAllDomainsQuestion}
        type="button"
        disabled={isDisabled}
        customTextCSS={customTextCSS}
      />
    );
  }

  return (
    <TextButton
      text={isSelectedChapters ? 'Unselect all' : 'Select all'}
      onClick={toggleSelectedAllChaptersQuestions}
      type="button"
      disabled={isDisabled}
      customTextCSS={customTextCSS}
    />
  );
};

export default ToggleSelectedAllButton;
