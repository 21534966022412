import { useAsyncAction, useClientSize, useGuideSearch, useToggle } from 'hooks';
import { FooterAudio } from 'pages/StudyGuide/components/Chapter/components';
import React, { FC, useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import Chapters from '../Chapters';
import Header from '../Header';
import Notes from '../Notes';
import SearchContent from '../SearchContent';

export enum SidebarTabs {
  CONTENT,
  NOTES,
}

type BodyProps = {
  isLoading: boolean;
  onClose?: () => void;
  openAudioUpgradeModal?: () => void;
};

const Body: FC<BodyProps> = ({ isLoading, onClose, openAudioUpgradeModal }) => {
  const { getIsBreakpoint } = useClientSize();
  const [activeTab, setActiveTab] = useState(SidebarTabs.CONTENT);
  const { isOpen: isShowOnlyChapters, toggle: toggleShowOnlyChapters } = useToggle();
  const { colors } = useTheme();
  const isWidthSm = getIsBreakpoint('sm');
  const currentChapter = useAppSelector(selectors.studyGuide.selectCurrentChapter);
  const tableOfGuideContent = useAppSelector(selectors.studyGuide.selectTableOfGuideContent);

  const notes = useAppSelector(selectors.studyGuide.selectNotes);
  const { formMethods } = useGuideSearch();
  const searchValue = formMethods.watch('search');

  const studyGuide = useAppSelector(selectors.studyGuide.selectStudyGuide);
  const foundGuideChapterBySearchText = useAppSelector(selectors.studyGuide.foundGuideText);

  const [getGuideSearchText, isLoadingSearch] = useAsyncAction(actions.studyGuide.getGuideSearchText);

  const getSearch = async (guideId: string, text: string) => {
    try {
      await getGuideSearchText({ guideId, text });
    } catch (error) {
      console.log(error);
    }
  };

  const tabs = {
    [SidebarTabs.CONTENT]: (
      <Chapters
        onClose={onClose}
        isShowOnlyChapters={isShowOnlyChapters}
        chapters={tableOfGuideContent?.chapters || null}
      />
    ),
    [SidebarTabs.NOTES]: <Notes onClose={onClose} chapters={notes} />,
  };

  const currentTab = tabs[activeTab];

  useEffect(() => {
    if (searchValue && searchValue.length > 2) {
      getSearch(studyGuide?.id as string, searchValue);
    }
  }, [studyGuide, searchValue]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <ThreeDots color={colors.primary[3]} />
      </LoaderContainer>
    );
  }

  return (
    <Root $hasAudio={Boolean(currentChapter?.audioUrl)}>
      <Header
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isShowOnlyChapters={isShowOnlyChapters}
        toggleShowOnlyChapters={toggleShowOnlyChapters}
        formMethods={formMethods}
      />
      <Content>
        {!searchValue ? (
          currentTab
        ) : (
          <SearchContent data={foundGuideChapterBySearchText?.chapters} isLoading={isLoadingSearch} />
        )}
      </Content>
      {!isWidthSm && !searchValue && <FooterAudio openUpgradeModal={openAudioUpgradeModal} />}
    </Root>
  );
};

export default Body;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`;

const Root = styled.div<{ $hasAudio: boolean }>`
  width: 100%;
  height: 100%;

  display: grid;

  ${({ $hasAudio }) =>
    $hasAudio
      ? css`
          grid-template-rows: auto 1fr 60px;
        `
      : css`
          grid-template-rows: auto 1fr;
        `}

  ${respondToWidth.sm`
      grid-template-rows: auto 1fr;
  `}
`;

const Content = styled.div`
  height: 100%;

  padding-right: 8px;
  display: grid;
  overflow: hidden;
`;
