import React, { ButtonHTMLAttributes, FC } from 'react';
import { useTheme } from 'styled-components';

import { StyledButton, StyledLoader, VariantCardButton } from './styled';

type CardButtonProps = {
  variant: VariantCardButton;
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const CardButton: FC<CardButtonProps> = ({ variant, isLoading = false, ...props }) => {
  const { colors } = useTheme();

  return (
    <StyledButton $variant={variant} $isLoading={isLoading} {...props}>
      {isLoading ? <StyledLoader size={18} color={colors.neutrals[11]} /> : TextButtonMapping[variant]}
    </StyledButton>
  );
};

export default CardButton;

const TextButtonMapping = {
  negative: 'No',
  neutral: 'Kinda',
  positive: 'Yes',
};
