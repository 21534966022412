import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import api from 'api';
import { SuccessIcon } from 'assets/icons';
import { useNotifications } from 'hooks';
import React, { FC, useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import { selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type ResultModalProps = {
  title: string;
  text: string;
  openSupport?: () => void;
} & BaseModalProps;

const ResultModal: FC<ResultModalProps> = ({ title, text, onClose, ...props }) => {
  const { boot, show } = useIntercom();
  const { colors } = useTheme();
  const { errorToast } = useNotifications();
  const user = useAppSelector(selectors.user.selectUser);
  const direction = useAppSelector(selectors.direction.selectDirection);

  const openSupport = useCallback(async () => {
    try {
      const { data: userHash } = await api.Customer.customersControllerCreateUserIntercomHash();

      boot({
        // eslint-disable-next-line camelcase
        customAttributes: { custom_attribute_key: 'hi there' },
        company: { companyId: 'trainer', name: 'trainer' },
        actionColor: colors.primary[1],
        backgroundColor: colors.primary[1],
        name: user?.name,
        email: user?.email,
        userId: user?.id,
        userHash,
        utmSource: direction?.name,
        hideDefaultLauncher: true,
      });
      show();
    } catch {
      errorToast('Something went wrong');
    }
  }, [colors, user, direction]);

  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      showCloseButton={false}
      modalCSS={ModalCSS}
      headerContainerCSS={HeaderContainerCSS}>
      <Wrapper>
        <SuccessIcon />
        <div>
          <Title>{title}</Title>
          <Text customStyles={{ fontWeight: 600 }}>{text}</Text>
          <Text customStyles={{ fontWeight: 600 }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            If you have any problems <Link onClick={openSupport}>write to support</Link>
          </Text>
        </div>
      </Wrapper>
      <StyledButton variant="secondary" size="middle" onClick={onClose}>
        Close
      </StyledButton>
    </DefaultModal>
  );
};

export default ResultModal;

const ContainerCSS = css`
  width: 628px;
  padding: 40px 80px;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    padding:32px 16px; 
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const HeaderContainerCSS = css`
  display: none;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  ${respondToWidth.s`
    flex-direction: column-reverse;
    margin-bottom: 4px;
  `}
`;

const Title = styled(StyledText)`
  text-align: center;
  margin-top: 12px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.title_4_bold_24};

  ${({ theme: { typography } }) => respondToWidth.sm`
  ${typography.title_5_bold_20};
  margin-top: 0px;
  `};
`;

const Text = styled(StyledText)`
  text-align: center;
  margin-top: 24px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};
  font-weight: 600 !important;

  ${({ theme: { typography } }) => respondToWidth.sm`
  ${typography.body_basic_semibold_14};
  margin-top: 20px;
  `};
`;

const StyledButton = styled(Button)`
  width: 144px;

  ${respondToWidth.s`
    width:100%;
  `}
`;

const Link = styled.a`
  display: inline;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  text-decoration: none;
  margin-left: 4px;
  cursor: pointer;
  padding-bottom: 1px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.primary[1]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  ${({ theme: { typography } }) => respondToWidth.sm`
  ${typography.body_basic_semibold_14};
  `};
`;
