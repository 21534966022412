import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { TestListTabs } from 'types';

const selectQuestions = (state: RootState) =>
  state.tests.activeTab === TestListTabs.DOMAINS ? state.tests.domainsQuestions : state.tests.chaptersQuestions;

export const selectors = {
  selectDomainsAndBanks: createSelector([selectQuestions], (questions) => questions),
  selectNextTimedTestPreview: (state: RootState) => state.tests.nextTimedTestPreview,

  selectTutorTest: (state: RootState) => state.tests.tutorTest,
  selectTimedTest: (state: RootState) => state.tests.timedTest,

  selectTestResult: (state: RootState) => state.tests.testClosedResult,
  selectCurrentTestId: (state: RootState) => state.tests.testClosedResult?.id,
  selectCurrentTestType: (state: RootState) => state.tests.testClosedResult?.type,
  selectCurrentTestPointsEarned: (state: RootState) => state.tests.testClosedResult?.pointsEarned,
  selectCurrentQuestionIndex: (state: RootState) => state.tests.currentQuestionIndex,
  selectTutorTestAnswers: (state: RootState) => state.tests.tutorTestAnswers,
  selectTimedTestAnswers: (state: RootState) => state.tests.timedTestAnswers,
  selectCurrentTestStatisticMeta: (state: RootState) => state.tests.currentTestStatisticMeta,
  selectCurrentTestResultsStatistics: (state: RootState) => state.tests.testResultsStatistics,
  selectCurrentTestStatisticQuestions: (state: RootState) => state.tests.currentTestStatisticQuestions,
  selectIsTestResultsFetching: (state: RootState) => state.tests.isTestResultsFetching,

  selectActiveTab: (state: RootState) => state.tests.activeTab,
  selectStartTutorTestParams: (state: RootState) => state.tests.startTutorTestParams,

  selectLeastKnowDomains: (state: RootState) => state.tests.leastKnowDomains,
  selectLeastKnowChapters: (state: RootState) => state.tests.leastKnowChapters,
};
