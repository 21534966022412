import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgDefaultIcon: FC<SVGIconProps> = (props) => {
  return (
    <svg width={32} height={20} viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_9025_220425)">
        <path
          d="M8.5 2.5h15a.833.833 0 0 1 .833.833v13.334a.833.833 0 0 1-.833.833h-15a.833.833 0 0 1-.833-.833V3.333A.833.833 0 0 1 8.5 2.5ZM22.667 10H9.333v5.833h13.334V10Zm0-3.333v-2.5H9.333v2.5h13.334Z"
          fill="#DF6438"
        />
      </g>
      <defs>
        <clipPath id="clip0_9025_220425">
          <path fill="#fff" transform="translate(6)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgDefaultIcon);
export default Memo;
