import { ChangePasswordModal as ChangePasswordModalUI } from 'UI';
import { useAsyncAction } from 'hooks';
import React, { FC } from 'react';
import { actions } from 'store';
import { BaseModalProps } from 'types';

type FormState = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

type ChangePasswordModalProps = {
  openForgotPasswordModal?: () => void;
} & BaseModalProps;

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({ ...props }) => {
  const [userChangePassword, isLoading] = useAsyncAction(actions.user.changePassword);

  const changePassword = async (values: FormState) => {
    await userChangePassword({
      oldPassword: values.currentPassword,
      password: values.newPassword,
    });
  };

  return <ChangePasswordModalUI {...props} changePassword={changePassword} isLoading={isLoading} />;
};

export default ChangePasswordModal;
