import Button from 'UI/Button';
import CardWithIcon from 'UI/CardWithIcon/CardWithIcon';
import { QuestionsCardsIcon } from 'assets/icons';
import React, { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';
import { CardWithTimer } from './components';

type UncompletedTestModalProps = {
  questionsCount: number;
  willCloseAt?: string;
  startTest: () => void;
  cancelTest: () => void;
  isStarting: boolean;
  isFinishing: boolean;
} & Omit<BaseModalProps, 'onClose'>;

const UncompletedTestModal: FC<UncompletedTestModalProps> = ({
  isOpen,
  isStarting,
  isFinishing,
  startTest,
  cancelTest,
  willCloseAt,
  questionsCount,
}) => {
  const { colors } = useTheme();

  return (
    <DefaultModal
      title="You have unfinished test. Do you want to continue?"
      isOpen={isOpen}
      onRequestClose={() => {}}
      containerCSS={ContainerCSS}
      headerContainerCSS={HeaderCSS}
      titleCSS={TitleCSS}
      modalCSS={ModalCSS}
      closeButtonCSS={CloseButtonCSS}>
      <Body>
        <CardWithIcon
          label="Questions left"
          Icon={<QuestionsCardsIcon color={colors.primary[1]} />}
          text={questionsCount}
        />
        {willCloseAt && <CardWithTimer cancelTest={cancelTest} willCloseAt={willCloseAt} />}
      </Body>
      <ButtonsContainer>
        <Button
          isLoading={isFinishing}
          disabled={isStarting}
          onClick={cancelTest}
          variant="secondary"
          size="middle"
          type="submit">
          Cancel
        </Button>
        <Button
          isLoading={isStarting}
          disabled={isFinishing}
          onClick={startTest}
          variant="primary"
          size="middle"
          type="submit">
          Continue
        </Button>
      </ButtonsContainer>
    </DefaultModal>
  );
};

export default UncompletedTestModal;

const ContainerCSS = css`
  width: 100%;
  padding: 40px 80px 60px;

  ${respondToWidth.sm`
    padding: 28px 16px;
  `}
`;

const ModalCSS = css`
  max-width: 610px;
  width: 100%;

  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const CloseButtonCSS = css`
  ${respondToWidth.s`
        display:none;
    `}
`;

const TitleCSS = css`
  ${({ theme: { typography } }) => css`
    max-width: 370px;
    ${typography.title_3_bold_28}
  `}

  ${respondToWidth.sm`
       padding-right: 52px;
  `}

  ${respondToWidth.s`
       padding-right: 0;
  `}
`;

const HeaderCSS = css`
  margin-bottom: 28px;

  ${respondToWidth.s`
    justify-content: center;
    margin-bottom: 32px;
  `}
`;

const Body = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  gap: 16px;

  margin-bottom: 28px;

  ${respondToWidth.sm`
    flex-wrap: wrap;
  `}

  ${respondToWidth.s`
    padding-inline:7px;
  `}
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  gap: 16px;
  ${respondToWidth.s`
    padding-inline:7px;
  `};
`;
