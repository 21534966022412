import { Button, IconButton, ThreeDotsMenu } from 'UI';
import { CloseIcon, ListIcon, ResetIcon, SpamIcon } from 'assets/icons';
import { useClientSize } from 'hooks';
import React, { FC, useState } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type FooterProps = {
  showModal: () => void;
  showConfirmModal: () => void;
  isStartedTest: boolean;
  onResetClick: () => void;
  onReportClick: () => void;
};

const Footer: FC<FooterProps> = ({ showModal, isStartedTest, showConfirmModal, onResetClick, onReportClick }) => {
  const { colors } = useTheme();

  const { getIsBreakpoint } = useClientSize();
  const isWidthSm = getIsBreakpoint('sm');

  const [isOpenActionsMenu, setIsOpenActionsMenu] = useState<boolean>(false);

  const flashcardPackResult = useAppSelector(selectors.flashcards.selectPackFlashcardsResult);

  const isShowReset =
    flashcardPackResult?.yes !== 0 || flashcardPackResult?.no !== 0 || flashcardPackResult?.kinda !== 0;

  const options = [
    {
      title: 'Reset results',
      icon: <ResetIcon size={24} color={colors.system.red} />,
      onClick: onResetClick,
      isShow: isShowReset,
    },
    {
      title: 'Report a problem',
      icon: <SpamIcon width={24} height={24} color={colors.system.red} />,
      onClick: onReportClick,
      isShow: true,
    },
    {
      title: 'End',
      icon: <CloseIcon size={24} color={colors.system.red} />,
      onClick: showConfirmModal,
      isShow: true,
    },
  ];

  return (
    <Root>
      <ShowButton
        iconComponent={<ListIcon size={24} color={colors.primary[1]} />}
        text="Show all flashcards"
        onClick={showModal}
      />
      {isWidthSm ? (
        <ThreeDotsMenu
          isOpen={isOpenActionsMenu}
          onClose={() => setIsOpenActionsMenu(false)}
          onOpen={() => setIsOpenActionsMenu(true)}
          options={options}
          position="right-top"
        />
      ) : (
        <ActionsWrapper>
          {isStartedTest && (
            <>
              <ReportButton
                iconComponent={<SpamIcon width={24} height={24} color={colors.system.red} />}
                variant="secondary"
                size="small"
                onClick={onReportClick}>
                Report a problem
              </ReportButton>
              {isShowReset && (
                <ResetButton
                  iconComponent={<ResetIcon size={24} color={colors.system.red} />}
                  onClick={onResetClick}
                  text="Reset results"
                />
              )}
            </>
          )}
        </ActionsWrapper>
      )}
    </Root>
  );
};

export default Footer;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-left: auto;
`;

const ResetButton = styled(IconButton)`
  display: flex;
  width: 177px;
  gap: 6px;

  ${respondToWidth.sm`
    width: 147px;
  `}
`;

const ShowButton = styled(IconButton)`
  display: flex;
  width: 217px;
  gap: 6px;
  ${respondToWidth.sm`
    width: 188px;
  `}
`;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 40px;
  background: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.sm`
    padding: 16px;
    margin-top: 24px;
  `}
`;

const ReportButton = styled(Button)`
  display: flex;
  width: 203px;

  ${respondToWidth.sm`
    display: none;
  `}
`;
