import { PaymentElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { BankCard, DefaultModal, StyledText } from 'UI';
import Button from 'UI/Button';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import { SubscribeDtoPeriodEnum, SubscribeDtoTypeEnum } from 'api/generated';
import { PaymentsSystemsEnums, levelMapping } from 'constant';
import { useAsyncAction } from 'hooks';
import { useCoupon } from 'hooks';
import { useStripeDefault } from 'hooks/useStripeDefault';
import { StripeProvider } from 'providers';
import { FC, useEffect, useMemo, useState } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import { Coupon, Price, Secure } from './components';

declare const dataLayer: any;

type PaymentModalProps = {
  courseId: string;
  subscribeType: SubscribeDtoTypeEnum;
  term: TermEnum;
  amount: number;
  isActivate: boolean | undefined;
  oldAmount?: number;
  isUpdate: boolean;
  isTrial: boolean;
  couponCode: string | undefined;
  openSuccessModal: () => void;
} & BaseModalProps;

const PaymentModal: FC<PaymentModalProps> = ({
  onClose,
  openSuccessModal,
  courseId,
  subscribeType,
  isActivate,
  term,
  amount,
  oldAmount,
  isUpdate,
  isTrial,
  couponCode,
  ...props
}) => {
  const [getCourseByIdAction] = useAsyncAction(actions.courses.getCourseById);
  const [transactionId, setTransactionId] = useState<string | undefined>(undefined);
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [invoiceId, setInvoiceId] = useState('');
  const [elements, setElements] = useState<StripeElements | null>(null);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [courseName, setCourseName] = useState<string | undefined>('');
  const user = useAppSelector(selectors.user.selectUser);
  const paymentMethod = useMemo(() => user?.paymentMethod, []);
  const [getMe] = useAsyncAction(actions.user.getMe);

  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const direction = useAppSelector(selectors.direction.selectDirection);
  const logo = isDarkMode ? direction?.darkCheckoutLogoUrl : direction?.checkoutLogoUrl;
  const {
    couponState,
    isInvalidCoupon,
    removeCoupon,
    onCouponSubmit,
    setIsInvalidCoupon,
    isDefaultCoupon,
    onChangePromoCode,
    isLoading,
    isChangePromoCodeLoading,
  } = useCoupon({
    courseId,
    subscribeType,
    term,
    amount,
    defaultCouponCode: couponCode || '',
    invoiceId,
    setClientSecret,
    setInvoiceId,
    isUpdate,
    isPurchase: term === TermEnum.LIFE_TIME,
  });

  const { couponErrorMessage, isSuccessCoupon, discountAmount, discountValue, promoCode } = couponState;

  const {
    clientSecretStripe,
    handleSubmitStripe,
    isLoadingStripe,
    errorMessageStripe,
    isSuccessStripe,
    isDisabledStripe,
  } = useStripeDefault({
    stripe,
    elements,
    courseId,
    promocode: promoCode,
    period: term as SubscribeDtoPeriodEnum,
    level: subscribeType,
    source: 'application',
    isTrial,
    isUpdate,
    isPurchase: term === TermEnum.LIFE_TIME,
    courseName,
    amount,
    subscribeType,
    clientSecret,
    setInvoiceId,
    setClientSecret,
    setTransactionId,
    isActivate,
  });

  const getUserInfo = async () => {
    try {
      await getMe();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    await handleSubmitStripe(e);
    if (!user?.paymentMethod) {
      getUserInfo();
    }
  };

  const finalPrice = discountValue && amount ? amount - discountValue : amount;

  const closeModal = () => {
    if (!isLoadingStripe) {
      onClose();
    }
  };

  const getCourseById = async () => {
    if (courseId) {
      try {
        const res = await getCourseByIdAction(courseId);
        setCourseName(res.name);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const pushDataLayer = async () => {
    if (!clientSecret) {
      return;
    }

    dataLayer.push({
      event: 'successful_purchase',
      transactionId: transactionId,
      course_name: courseName,
      promo_code: promoCode,
      item_name: subscribeType,
      item_id: courseId,
      price: finalPrice,
      term: term,
      enhanced_conversion_data: {
        email: user?.email,
      },
    });
  };

  useEffect(() => {
    if (isSuccessStripe) {
      openSuccessModal();
      pushDataLayer();
    }
  }, [isSuccessStripe]);

  useEffect(() => {
    getCourseById();
  }, [courseId]);

  return (
    <DefaultModal
      {...props}
      onRequestClose={closeModal}
      containerCSS={ContainerCSS}
      closeButtonCSS={CloseButton}
      modalCSS={ModalCSS}>
      <PaymentWrapper>
        <LogoContainer>
          <LogoImg src={logo} />
        </LogoContainer>
        <Price amount={amount} term={term} discountValue={discountValue} isDefaultCoupon={isDefaultCoupon} />
        <NameContainer>
          <CourseNameText>{courseName}</CourseNameText>
          <CourseSubNameText>{levelMapping[subscribeType]}</CourseSubNameText>
        </NameContainer>
        <Coupon
          subscribeType={levelMapping[subscribeType]}
          isDefaultCoupon={isDefaultCoupon}
          defaultCouponCode={couponCode}
          isSuccessCoupon={isSuccessCoupon}
          courseName={courseName || ''}
          onCouponSubmit={onCouponSubmit}
          removeCoupon={removeCoupon}
          amount={amount}
          oldAmount={oldAmount}
          promoCode={promoCode}
          discountAmount={discountAmount}
          discountValue={discountValue}
          couponErrorMessage={couponErrorMessage}
          invalidCoupon={isInvalidCoupon}
          setClearCoupon={() => setIsInvalidCoupon(false)}
          handleChangePromoCode={onChangePromoCode}
          disable={isLoading || isChangePromoCodeLoading}
        />
      </PaymentWrapper>
      <Form onSubmit={handleSubmit}>
        <FormWrapper>
          {paymentMethod && finalPrice >= 1 && (
            <>
              <BankCard
                onClick={() => handleSubmitStripe(undefined, paymentMethod)}
                isLoading={isLoadingStripe || !clientSecret || isLoading || isChangePromoCodeLoading}
                card={paymentMethod?.card?.last4 ?? ''}
                paymentSystem={paymentMethod?.card?.brand as PaymentsSystemsEnums}
              />
              <Text>Or with new bank card</Text>
            </>
          )}
          <PaymentFieldsWrapper>
            <StripeProvider
              setStripe={setStripe}
              setElements={(elements: StripeElements | null) => setElements(elements)}
              onConfirmExpressCheckout={handleSubmitStripe}
              amount={finalPrice * 100}
              mode={term === TermEnum.LIFE_TIME ? 'payment' : 'subscription'}
              clientSecret={clientSecretStripe}>
              <PaymentElement
                options={{
                  wallets: { applePay: 'never', googlePay: 'never' },
                  terms: { card: 'never' },
                }}
              />
            </StripeProvider>
            {errorMessageStripe && <ErrorText>{errorMessageStripe}</ErrorText>}
          </PaymentFieldsWrapper>
        </FormWrapper>
        <Secure />
        <StyledButton
          disabled={finalPrice < 1 || isDisabledStripe}
          isLoading={isLoadingStripe || !clientSecret || isLoading || isChangePromoCodeLoading}
          variant="primary"
          size="middle"
          type="submit">
          {`Enroll Now: $${finalPrice} USD`}
        </StyledButton>
        <LinkContainer>
          <Link as="a" href="https://traineracademy.org/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy
          </Link>
          <Link
            as="a"
            href="https://traineracademy.org/terms-and-conditions/"
            target="_blank"
            rel="noopener noreferrer">
            Terms
          </Link>
        </LinkContainer>
        <SecureTextContainer>
          {term !== TermEnum.LIFE_TIME && (
            <SecureText font="subhead_medium_13">Renews automatically. Cancel anytime.</SecureText>
          )}
        </SecureTextContainer>
      </Form>
    </DefaultModal>
  );
};

export default PaymentModal;

const ContainerCSS = css`
  width: 100%;
  overflow: scroll;
  height: fit-content;
  max-height: 100%;

  display: flex;
  flex-direction: column;
  padding: 0px 0 40px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const CloseButton = css`
  top: 20px;
  border: 1px solid;
  border-color: #ffffff;
  background-color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const ModalCSS = css`
  width: 100%;
  max-width: 688px;

  height: 100%;
  padding: 22px 0;
  overflow: hidden;

  display: flex;
  align-items: center;

  ${respondToWidth.sm`
    max-width: none;
    width: 90%;
  `}

  ${respondToWidth.s`
    width: 100%;
    height: 100%;
    padding: 0;

  `}
`;

const SecureText = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  margin-left: 6px;
`;

const SecureTextContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  gap: 15px;

  padding-inline: 95px 100px;

  ${respondToWidth.sm`
    padding-inline: 16px;
  `}
`;

const NameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const CourseNameText = styled.span`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
  cursor: pointer;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[11]};
    ${typography.title_5_bold_20}
  `};
`;

const CourseSubNameText = styled.span`
  display: flex;
  height: 28px;
  padding: 0px 5px;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: -10px;
  text-align: center;
  padding-bottom: 10px;
  cursor: default;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.primary[1]};
    background-color: ${colors.neutrals[11]};
    ${typography.title_5_bold_20}
  `};
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${respondToWidth.sm`
      gap: 24px;
  `}
`;

const PaymentFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PaymentWrapper = styled.div`
  margin-top: -32px;
  margin-bottom: 35px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: ${({ theme: { colors } }) => colors.primary[1]};

  ${respondToWidth.sm`
  margin-top: -42px;
  margin-left: -25px;
  margin-right: -25px;
`}
`;

const ErrorText = styled(StyledText)`
  text-align: start;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.system.red};
    ${typography.body_basic_medium_14}

    ${respondToWidth.s`
      ${typography.footnote_semibold_12}
  `}
  `};
`;

const LogoImg = styled.img`
  max-width: 100%;
  max-height: 40px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 35px;
  cursor: pointer;
  padding-right: 8px;

  ${respondToWidth.s`
  margin-top: 20px;
  margin-bottom: 23px;
`}
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  ${respondToWidth.s`
    margin-top: auto;
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Link = styled(StyledText)`
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;

  color: ${({ theme: { colors } }) => colors.primary[1]};
`;
