import { ExpandImage, StyledText } from 'UI';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type ContentProps = {
  image?: { url: string; alt: string };
  text?: string;
};

const MAX_COUNT_FOR_SHORT_TEXT = 20;

const Content: FC<ContentProps> = ({ image, text }) => {
  const isShortText = Boolean(text && text?.length < MAX_COUNT_FOR_SHORT_TEXT);
  const isOneTypeContent = !image || !text;

  return (
    <Root $isOneTypeContent={isOneTypeContent} $isShortText={isShortText}>
      {image && <ImageContainer $hasText={!!text} image={image} fullscreenCSS={FullScreenStyle} />}
      <Text $hasImage={Boolean(image)}>{text}</Text>
    </Root>
  );
};

export default Content;

const FullScreenStyle = css`
  img {
    height: 100%;
  }
`;

type RootProps = {
  $isOneTypeContent: boolean;
  $isShortText: boolean;
};

const Root = styled.div<RootProps>`
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 90px;
  width: 425.5px;
  gap: 12px;
  ${({ $isOneTypeContent, $isShortText }) =>
    $isOneTypeContent
      ? css`
          gap: 0;
          justify-content: center;
          ${respondToWidth.ls`
            min-height: ${$isShortText ? '22px' : '128px'};
            justify-content: start;
  `}
          ${respondToWidth.sm`
            min-height: auto;
    
  `}
        `
      : css`
          gap: 12px;

          ${respondToWidth.ls`
            gap: 10px;
          `}
        `}

  ${respondToWidth.lg`
    width: 350px;
  `}
  ${respondToWidth.ls`
    width: 285px;
  `}
  ${respondToWidth.sm`
    width: 400px;
  `}
  ${respondToWidth.s`
    width: 319px;
  `}
`;

const ImageContainer = styled(ExpandImage)<{ $hasText: boolean }>`
  min-width: 145px;
  width: 100%;
  height: 90px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
  border-radius: 20px;
  overflow: hidden;

  max-width: ${({ $hasText }) => ($hasText ? '145px' : 'none')};

  ${respondToWidth.sm`
    height: 128px;
  `}

  img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
`;

const Text = styled(StyledText)<{ $hasImage: boolean }>`
  text-align: ${({ $hasImage }) => !$hasImage && 'center'};
  ${({ theme: { typography } }) => typography.footnote_semibold_12};
`;
