import { ApplePayIcon, GooglePayIcon } from 'assets/icons';
import React, { ButtonHTMLAttributes, FC } from 'react';
import styled, { css } from 'styled-components';

type VariantSocialButton = 'google' | 'apple';

type SocialButtonProps = {
  variant: VariantSocialButton;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const SocialButton: FC<SocialButtonProps> = ({ variant = 'google', ...props }) => {
  return <Button {...props}>{variant === 'google' ? <GooglePayIcon /> : <ApplePayIcon />}</Button>;
};

export default SocialButton;

const Button = styled.button`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    ${({ theme: { colors } }) => css`
      border-color: transparent;
      background-color: ${colors.neutrals[10]};
    `}

    &:focus {
      ${({ theme: { colors } }) => css`
        border-color: ${colors.neutrals[4]};
        background-color: ${colors.neutrals[10]};
      `}
    }
  }
`;
