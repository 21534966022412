import StyledText from 'UI/StyledText';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

export enum TermEnum {
  MONTHLY = 'monthly',
  YEARLY = 'annual',
  LIFE_TIME = 'lifeTime',
}

type PriceLabelProps = {
  oldPrice?: number | undefined;
  currentPrice: number;
  className?: string;
  isOnePriceLabelView?: boolean;
  goldCount?: number;
  countPlans?: number;
  termCount?: number;
  isMaxHeight?: boolean;
  term: TermEnum;
};

const PriceLabel: FC<PriceLabelProps> = ({ oldPrice, currentPrice, term, isMaxHeight, goldCount, ...props }) => {
  const termMapping = {
    [TermEnum.MONTHLY]: 'month',
    [TermEnum.YEARLY]: 'year',
    [TermEnum.LIFE_TIME]: 'lifetime',
  };

  const oldPriceString = `$${oldPrice}`;
  const currentPriceString = ` $${currentPrice} / ${termMapping[term]}`;

  return (
    <Text {...props}>
      {Boolean(oldPrice) && (
        <StrikethroughText isMaxHeight={isMaxHeight} goldCount={goldCount} as="span">
          {oldPriceString}
        </StrikethroughText>
      )}
      {currentPriceString}
    </Text>
  );
};

export default PriceLabel;

const Text = styled(StyledText)`
  display: flex;
  gap: 4px;

  color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const StrikethroughText = styled(Text)<{ isMaxHeight?: boolean; goldCount?: number }>`
  ${({ theme: { colors } }) => css`
    width: fit-content;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: line-through;
    color: ${colors.neutrals[1]};

    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      transform: translateY(1px);
      background-color: ${colors.neutrals[11]};
    }
  `}
  margin: ${({ isMaxHeight, goldCount }) => (isMaxHeight && goldCount === 1 ? '0 auto' : 'unset')};
`;
