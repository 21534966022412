import { StyledText } from 'UI';
import { EditIcon } from 'assets/icons';
import { useClientSize } from 'hooks';
import React, { FC, Fragment, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { hideText } from 'utils';

type InfoCardProps = {
  title: string;
  fields: Array<FieldType>;
  isShowEditIcon: boolean;
};

export type FieldType = {
  label: string;
  value: string | null;
  openEditModal?: () => void;
};

const InfoCard: FC<InfoCardProps> = ({ title, fields, isShowEditIcon }) => {
  const { getIsBreakpoint } = useClientSize();
  const isWidthSm = getIsBreakpoint('sm');

  const Fields = useMemo(() => {
    const lastFieldsIndex = fields.length - 1;

    return fields.map((field, index) => (
      <Fragment key={index}>
        <InfoField>
          <FieldHeader>
            <FieldLabel>{field.label}</FieldLabel>
            {isShowEditIcon && <StyledEditIcon onClick={field.openEditModal} />}
          </FieldHeader>
          {field && <FieldValue>{field.value}</FieldValue>}
        </InfoField>
        {index < lastFieldsIndex && !isWidthSm && <Hr />}
      </Fragment>
    ));
  }, [fields, isWidthSm]);

  return (
    <Root>
      <Title as="h3">{title}</Title>
      {Fields}
    </Root>
  );
};

export default InfoCard;

const Root = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 20px;

    border-radius: 12px;
    background-color: ${colors.neutrals[11]};

    ${respondToWidth.sm`
      gap: 16px;
      padding: 0;
      padding-bottom: 32px;

      border-radius: 0;
      border-bottom: 1px solid ${colors.neutrals[9]};
    `};
  `}
`;

const Title = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_large_bold_16}

    user-select: none;

    ${respondToWidth.sm`
      ${typography.title_5_bold_20}
    `};
  `};
`;

const InfoField = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FieldHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FieldLabel = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[4]};
    ${typography.footnote_regular_12}

    ${respondToWidth.sm`
      ${typography.body_basic_medium_14}
    `};
  `};
`;

const StyledEditIcon = styled(EditIcon)`
  ${({ theme: { colors } }) => css`
    width: 16px;
    height: 16px;

    cursor: pointer;

    path {
      fill: ${colors.neutrals[4]};
    }

    :hover {
      path {
        fill: ${colors.primary[1]};
      }
    }

    ${respondToWidth.sm`
      width: 20px;
      height: 20px;
      `};
  `};
`;

const Hr = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.neutrals[9]};
`;

const FieldValue = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_basic_medium_14}

    ${hideText(1)}

    ${respondToWidth.sm`
      ${typography.body_large_medium_16}
    `};
  `};
`;
