import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgPlusIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.167 9.167v-5h1.666v5h5v1.666h-5v5H9.167v-5h-5V9.167h5Z" fill={color} />
    </svg>
  );
};

const Memo = memo(SvgPlusIcon);
export default Memo;
