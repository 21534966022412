import { Checkbox, StyledText } from 'UI';
import { CheckboxVariant } from 'UI/Checkbox/Checkbox';
import { ListCheckIcon } from 'assets/icons';
import { useClientSize, useGetProgressColor } from 'hooks';
import React, { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type HeaderProps = {
  correctPercent: number | null;
  correctAmount: number | null;
  total: number | null;
  isShowCorrect: boolean;
  toggleIsShowCorrect: () => void;
  onIconClick: () => void;
};

const Header: FC<HeaderProps> = ({
  correctPercent,
  correctAmount,
  total,
  isShowCorrect,
  toggleIsShowCorrect,
  onIconClick,
}) => {
  const color = useGetProgressColor(correctPercent || 0);
  const { colors } = useTheme();
  const { getIsBreakpoint } = useClientSize();

  const isWidthLs = getIsBreakpoint('ls');

  const isStatistics =
    (typeof correctPercent === 'number' || typeof correctAmount === 'number') && typeof total === 'number';

  const counter = `${correctAmount || 0}/${total || 0}`;

  if (!isStatistics) {
    return null;
  }

  return (
    <Container>
      <Title>
        Test completed! You answered <Percent $color={color}>{correctPercent}%</Percent> of the questions correctly (
        {counter})
      </Title>
      <Wrapper>
        {correctAmount && (
          <StyledCheckbox
            label="Show only incorrect"
            checked={isShowCorrect}
            onChange={toggleIsShowCorrect}
            variant={isWidthLs ? CheckboxVariant.default : CheckboxVariant.white}
          />
        )}
        <IconContainer onClick={onIconClick}>
          <ListCheckIcon width={24} height={24} color={colors.primary[1]} />
        </IconContainer>
      </Wrapper>
    </Container>
  );
};

export default Header;

const Container = styled.header`
  width: 100%;
  padding: 40px 0 24px 64px;

  ${({ theme: { colors } }) => css`
    ${respondToWidth.ls`
    padding-right: 24px;
    background-color: ${colors.neutrals[11]};
  `};

    ${respondToWidth.ls`
    padding-left: 40px;
  `}

    ${respondToWidth.sl`
    padding-inline:16px;
  `}

    ${respondToWidth.sm`
    padding: 20px 16px 16px;
    border-bottom:1px solid ${colors.neutrals[9]};
  `}
  `}
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_3_bold_28}

    ${respondToWidth.ls`
      ${typography.title_4_bold_24};
    `}

    ${respondToWidth.sm`
        ${typography.title_5_bold_20}
        margin-bottom: 20px;
    `}
  `};
`;

const Percent = styled(StyledText)<{ $color: string }>`
  display: inline;
  color: ${({ $color }) => $color};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconContainer = styled.button`
  display: none;
  width: 24px;
  height: 24px;

  ${respondToWidth.ls`
    display:block;
  `}
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 24px;

  ${({ theme: { typography } }) => typography.body_basic_semibold_14}
`;
