import React, { ForwardRefRenderFunction, HTMLAttributes, UIEventHandler, forwardRef } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type BackgroundType = 'white' | 'gray';

type ScrollbarContainerProps = {
  backgroundType?: BackgroundType;
  onScroll?: UIEventHandler<HTMLDivElement>;
  mainCSS?: CSSProp;
  isHorisontal?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const ScrollbarContainer: ForwardRefRenderFunction<HTMLDivElement, ScrollbarContainerProps> = (
  { backgroundType = 'white', mainCSS, children, isHorisontal = false, ...props },
  ref,
) => {
  return (
    <Container {...props} $mainCSS={mainCSS} $isHorisontal={isHorisontal} $backgroundType={backgroundType} ref={ref}>
      {children}
    </Container>
  );
};

export default forwardRef<HTMLDivElement, ScrollbarContainerProps>(ScrollbarContainer);

const Container = styled.div<{ $backgroundType: BackgroundType; $mainCSS?: CSSProp; $isHorisontal?: boolean }>`
  ${({ $isHorisontal }) => css`
    ${$isHorisontal ? 'overflow-x: scroll;' : 'overflow-y: scroll;'}
  `};

  ::-webkit-scrollbar {
    width: 8px;

    ${respondToWidth.sm`
      width: 4px;
    `}
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: inherit;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 4px;
    background: ${({ theme: { colors }, $backgroundType }) =>
      $backgroundType === 'white' ? colors.neutrals[8] : colors.neutrals[7]};

    ${respondToWidth.sm`
      width:4px;
    `}
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme: { colors }, $backgroundType }) =>
      $backgroundType === 'white' ? colors.neutrals[6] : colors.neutrals[5]};
  }

  /* Handle on active */
  ::-webkit-scrollbar-thumb:active:enabled {
    background: ${({ theme: { colors }, $backgroundType }) =>
      $backgroundType === 'white' ? colors.neutrals[2] : colors.neutrals[1]};
  }

  ${({ $mainCSS }) => $mainCSS}
`;
