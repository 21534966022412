import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import { SuccessIcon } from 'assets/icons';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

import DefaultModal from '../DefaultModal';

type ResultModalProps = {
  email: string;
  courseName?: string;
  courseId?: string;
  openSupport?: () => void;
} & BaseModalProps;

const ResultModal: FC<ResultModalProps> = ({ email, onClose, openSupport, ...props }) => {
  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      showCloseButton={false}
      modalCSS={ModalCSS}
      headerContainerCSS={HeaderContainerCSS}>
      <Wrapper>
        <SuccessIcon />
        <div>
          <Title>Your purchase was successful</Title>
          <Text customStyles={{ fontWeight: 600 }}>
            If you have a trial with {<Email>{email}</Email>}, <br /> please
            <Link href="https://study.traineracademy.org/"> sign in</Link> to access your upgraded account access <br />
            <br /> If you don&apos;t have a trial with {<Email>{email}</Email>}, please check that email for login
            instructions
          </Text>
          <Text customStyles={{ fontWeight: 600 }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            If you have any problems, contact support <Link onClick={openSupport}>here</Link>
          </Text>
        </div>
      </Wrapper>
      <StyledButton variant="secondary" size="middle" onClick={onClose}>
        Close
      </StyledButton>
    </DefaultModal>
  );
};

export default ResultModal;

const ContainerCSS = css`
  width: 628px;
  padding: 40px 80px;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    padding:32px 16px; 
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const HeaderContainerCSS = css`
  display: none;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  ${respondToWidth.s`
    flex-direction: column-reverse;
    margin-bottom: 4px;
  `}
`;

const Title = styled(StyledText)`
  text-align: center;
  margin-top: 12px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.title_4_bold_24};

  ${({ theme: { typography } }) => respondToWidth.sm`
  ${typography.title_5_bold_20};
  margin-top: 0px;
  `};
`;

const Text = styled(StyledText)`
  text-align: center;
  margin-top: 24px;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};
  font-weight: 600 !important;

  ${({ theme: { typography } }) => respondToWidth.sm`
  ${typography.body_basic_semibold_14};
  margin-top: 20px;
  `};
`;

const StyledButton = styled(Button)`
  width: 144px;

  ${respondToWidth.s`
    width:100%;
  `}
`;

const Email = styled(Text)`
  display: inline;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  margin-top: 0;
  margin-left: 4px;
`;

const Link = styled.a`
  display: inline;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  text-decoration: none;
  margin-left: 4px;
  cursor: pointer;
  padding-bottom: 1px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.primary[1]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  ${({ theme: { typography } }) => respondToWidth.sm`
  ${typography.body_basic_semibold_14};
  `};
`;
