import { createGlobalStyle } from 'styled-components';

import { typography } from './typography';

export const calculateViewportHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const GlobalStyle = createGlobalStyle`
 body {
    margin:0;
    padding:0;
    font-family: "Manrope", Verdana, sans-serif;
    font-style: normal;
    color: ${({ theme: { colors } }) => colors.neutrals[1]};
    overflow-x: hidden;

    ${() => typography.body_basic_regular_14};

    min-height: calc(var(--vh, 1vh) * 100);
 }

 *:not(font) {
    font-family: "Manrope", sans-serif;
  }

  html {
    height: 100%;
    height: -webkit-fill-available;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    outline: none;
  }

  img {
    max-width: 100%;
    height: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
hr,
mark,
audio,
button,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  list-style: none;
  background: none;
  border: none;
  text-decoration: none;
}
  
  button {
    cursor: pointer;
  }

  button:disabled {
    cursor: not-allowed;
  }
`;
