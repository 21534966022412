import { Button, NavButton } from 'UI';
import { SpamIcon } from 'assets/icons';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useIsLandscapeOrientation } from 'hooks/useIsLandscapeOrientation';
import { LayoutStyledProps } from 'pages/MnemonicCardPractice/MnemonicCardPractice';
import React, { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type ControlBarProps = {
  onShowClick: () => void;
  onNextClick: () => void;
  onPrevClick: () => void;
  onReportClick: () => void;
  isFirstSlide: boolean;
  isLastSlide: boolean;
  isFlipped: boolean;
};

const ControlBar: FC<ControlBarProps> = ({
  onShowClick,
  onNextClick,
  onPrevClick,
  onReportClick,
  isFirstSlide,
  isLastSlide,
  isFlipped,
}) => {
  const { isLandscapeOrientation } = useIsLandscapeOrientation();
  const { isMobile } = useGetIsMobile();
  const { colors } = useTheme();

  const isMobileLandscape = isLandscapeOrientation && isMobile;

  return (
    <Container $isMobileLandscape={isMobileLandscape}>
      <Buttons>
        <ShowCardButton variant="primary" size="small" onClick={onShowClick}>
          {isFlipped ? 'Show question' : 'Show mnemonic card'}
        </ShowCardButton>
      </Buttons>
      <NavButtonContainer>
        <NavButton isHideText variant="back" onClick={onPrevClick} disabled={isFirstSlide} />
        <NavButton isHideText variant="next" onClick={onNextClick} disabled={isLastSlide} />
      </NavButtonContainer>
      <ReportButton
        iconComponent={<SpamIcon width={24} height={24} color={colors.system.red} />}
        variant="secondary"
        size="small"
        onClick={onReportClick}>
        Report a problem
      </ReportButton>
    </Container>
  );
};

export default ControlBar;

const Container = styled.div<LayoutStyledProps>`
  display: flex;
  width: 100%;
  min-height: 72px;
  height: 72px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-top: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 0 0 20px 20px;
  padding-inline: 32px;

  ${({ $isMobileLandscape }) =>
    $isMobileLandscape &&
    css`
      width: calc(100% - 4px);
    `}

  ${respondToWidth.sm`
  display:none;
`};
`;

const NavButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 44px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

const ShowCardButton = styled(Button)`
  width: 182px;
  padding-inline: 0;
  margin-right: 32px;
`;

const ReportButton = styled(Button)`
  max-width: 203px;
`;
