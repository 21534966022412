import { Button, StyledText } from 'UI';
import { AxiosError } from 'axios';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type TestFooterProps = {
  showNavigation: () => void;
  chapterIndex: number;
};

const TestFooter: FC<TestFooterProps> = ({ showNavigation, chapterIndex }) => {
  const navigate = useNavigate();
  const { errorToast } = useNotifications();

  const currentChapter = useAppSelector(selectors.studyGuide.selectCurrentChapter);
  const [getQuizTestAction, isQuizTestFetching] = useAsyncAction(actions.tests.getQuizTest);

  const createQuizTest = async (quizId: string) => {
    try {
      await getQuizTestAction(quizId);
      navigate(`${routes.tutorTest}?chapterIndex=${chapterIndex}&quizId=${quizId}`);
    } catch (error) {
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    }
  };

  return (
    <Root>
      <Text>This is the end of this chapter. Do you want to test your knowledge and take a test?</Text>
      <ButtonsContainer>
        <Button
          isLoading={isQuizTestFetching}
          onClick={() => currentChapter?.quizId && createQuizTest(currentChapter.quizId)}
          size="small">
          Yes
        </Button>
        <Button disabled={isQuizTestFetching} onClick={showNavigation} size="small" variant="secondary">
          No
        </Button>
      </ButtonsContainer>
    </Root>
  );
};

export default TestFooter;

const Root = styled.div`
  width: 100%;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  border-top: 1px solid;
  border-color: ${({ theme: { colors } }) => colors.neutrals[9]};

  ${respondToWidth.l`
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    `}

  ${respondToWidth.sm`
      display: flex;
      min-height: 114px;
      flex-direction: column;

      padding: 10px 16px 16px;
    `}
`;

const Text = styled(StyledText)`
  text-align: start;
  user-select: none !important;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_basic_bold_14}

    ${respondToWidth.sm`
        width: 100%;
        ${typography.body_basic_medium_14}
    `}
  `};
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  button {
    white-space: nowrap;
  }

  ${respondToWidth.sm`
      width: 100%;
      gap: 12px;

      button {
        width: 100%;
        padding-inline: 0;
      }
  `}
`;
