import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import parseDate from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(parseDate);
dayjs.extend(isBetween);
dayjs.locale(en);

export default dayjs;
