import { ModeTag } from 'UI';
import { ToggleSelectedAllButton } from 'components';
import { TestFormType } from 'pages/TestList/TestList';
import { FC, useLayoutEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { TestListTabs } from 'types';

type TabsHeaderProps = {
  isTutorMode: boolean;
  openUpgradeSubscriptionModal: () => void;
};

type Tab = {
  value: TestListTabs;
  text: string;
};

const tabs = [
  {
    value: TestListTabs.DOMAINS,
    text: TestListTabs.DOMAINS,
  },
  {
    value: TestListTabs.CHAPTERS,
    text: TestListTabs.CHAPTERS,
  },
];

const TabsHeader: FC<TabsHeaderProps> = ({ isTutorMode }) => {
  const dispatch = useAppDispatch();
  const { clearErrors } = useFormContext<TestFormType>();

  const activeTab = useAppSelector(selectors.tests.selectActiveTab);
  const currentActiveCourse = useAppSelector(selectors.courses.selectActiveCourse);

  const [tabNames, setTabNames] = useState<Tab[]>(tabs);

  const setActiveTab = (tab: Tab) => {
    clearErrors('chaptersQuestions');
    clearErrors('domainsQuestions');
    dispatch(actions.tests.changeTab(tab.value));
  };

  useLayoutEffect(() => {
    if (currentActiveCourse?.domainButtonName && currentActiveCourse?.chapterButtonName) {
      setTabNames([
        {
          value: TestListTabs.DOMAINS,
          text: currentActiveCourse?.domainButtonName,
        },
        {
          value: TestListTabs.CHAPTERS,
          text: currentActiveCourse?.chapterButtonName,
        },
      ]);
    }
  }, [currentActiveCourse?.domainButtonName, currentActiveCourse?.chapterButtonName]);

  return (
    <Root>
      <Container>
        {tabNames.map((tab) => (
          <ModeTag
            checked={tab.value === activeTab}
            onClick={() => setActiveTab(tab)}
            key={tab.value}
            size="small"
            text={tab.text}
          />
        ))}
      </Container>

      <ToggleSelectedAllButton isDisabled={!isTutorMode} />
    </Root>
  );
};

export default TabsHeader;

const Root = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding-inline: 24px;
  padding-block: 24px;
  gap: 8px;

  ${respondToWidth.sm`
    padding: 16px;
  `}
`;

const Container = styled.div`
  display: flex;
  gap: 8px;
`;
