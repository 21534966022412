import { Dispatch, SetStateAction } from 'react';
import styled, { CSSProp } from 'styled-components';
import { Controller, Swiper as SwiperCommon, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

type GallerySwiperProps<T> = {
  data: T[];
  renderElement: (el: T, index?: number) => JSX.Element;
  swiperOptions: SwiperOptions;
  setSwiper: Dispatch<SetStateAction<SwiperCommon | undefined>>;
  containerCSS?: CSSProp;
};

const GallerySwiper = <T,>({ data, renderElement, swiperOptions, setSwiper, containerCSS }: GallerySwiperProps<T>) => {
  return (
    <StyledSwiper
      {...swiperOptions}
      modules={[Controller]}
      onSwiper={setSwiper}
      simulateTouch={false}
      $CSS={containerCSS}>
      {data.map((item, index) => (
        <SwiperSlide key={index}>{renderElement(item, index)}</SwiperSlide>
      ))}
    </StyledSwiper>
  );
};

export default GallerySwiper;

const StyledSwiper = styled(Swiper)<{ $CSS?: CSSProp }>`
  width: 100%;
  ${({ $CSS }) => $CSS}
`;
