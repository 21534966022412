import { OutputData } from '@editorjs/editorjs';
import { ScrollbarContainer, StyledText } from 'UI';
import { DefaultModal } from 'UI/Modals';
import { ManualTypeEnum } from 'api/generated';
import { Loader } from 'components';
import { useGetIsMobile } from 'hooks';
import { useGetManual } from 'hooks';
import { FC, useRef } from 'react';
import { Editor } from 'services';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

const InstructionManualModal: FC<BaseModalProps> = ({ onClose, ...props }) => {
  const editorRef = useRef(null);

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const { isMobile } = useGetIsMobile();

  const manualType = isMobile ? ManualTypeEnum.InstructionManualMobile : ManualTypeEnum.InstructionManual;

  const { content, isManualLoading } = useGetManual(manualType, activeCourse?.id);

  return (
    <DefaultModal
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      titleCSS={TitleCSS}
      // title="Instruction manual" //todo
      modalCSS={ModalCSS}
      headerContainerCSS={HeaderContainer}
      changeCloseButton
      {...props}>
      <StyledScrollbar>
        {isManualLoading ? (
          <Loader />
        ) : content ? (
          <StyledEditor defaultData={content.content as OutputData} holder="manual" editorRef={editorRef} />
        ) : (
          <Text>Sorry... An error has occurred, please try again.</Text>
        )}
      </StyledScrollbar>
    </DefaultModal>
  );
};

export default InstructionManualModal;

const ContainerCSS = css`
  width: 984px;
  padding: 40px 10px 60px 60px;
  max-height: 760px;
  height: calc(100vh - 80px);
  overflow: hidden;

  ${respondToWidth.ls`
    width:100%;
    height: 100vh;
    padding: 40px 6px 40px 16px;
  `}

  ${respondToWidth.s`
    border-radius: 0;
  `}
`;

const HeaderContainer = css`
  padding-right: 50px;

  ${respondToWidth.ls`
      padding-right: 10px;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.ls`
    width:90%;
  `};

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `};
`;

const StyledScrollbar = styled(ScrollbarContainer)`
  width: 100%;
  height: 100%;
  padding-right: 50px;
  padding-bottom: 60px;

  ${respondToWidth.ls`
    padding-right: 10px;
    padding-bottom: 40px;
  `}
`;

const TitleCSS = css`
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.title_3_bold_28};
`;

const Text = styled(StyledText)`
  width: 100%;
  white-space: pre-wrap;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.subhead_regular_13};  
  `}
`;

const StyledEditor = styled(Editor)`
  div div {
    margin: 0 auto !important;
  }
`;
