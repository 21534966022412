import { DefaultModal, StyledText } from 'UI';
import Button from 'UI/Button';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

type AddPaymentMethodModalProps = BaseModalProps;

const SuccessfulUnsubscribeModal: FC<AddPaymentMethodModalProps> = ({ onClose, ...props }) => {
  return (
    <DefaultModal
      {...props}
      onRequestClose={() => onClose()}
      showCloseButton={false}
      title="The subscription is successfully cancelled"
      titleCSS={TitleCSS}
      containerCSS={ContainerCSS}
      modalCSS={ModalCSS}>
      <Text>{'It will be active until the end of the billing period'}</Text>
      <StyledButton variant="primary" size="middle" type="submit" onClick={onClose}>
        Okay
      </StyledButton>
    </DefaultModal>
  );
};

export default SuccessfulUnsubscribeModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;

  ${respondToWidth.sm`
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    padding: 16px;
    border-radius:0;
  `}
`;

const TitleCSS = css`
  margin-bottom: 0;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.title_4_bold_24};
  `}

  ${respondToWidth.s`
    text-align: center;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const StyledButton = styled(Button)`
  ${respondToWidth.s`
    margin-top: auto;
  `}
`;

const Text = styled(StyledText)`
  text-align: center;
  margin-bottom: 40px;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[4]};
    ${typography.body_large_semibold_16}

    ${respondToWidth.sm`
    ${typography.body_basic_medium_14};
  `}
  `};
`;
