import { Button, ScrollbarContainer, StyledText, Tag } from 'UI';
import { accessLevels } from 'constant';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { ForwardRefRenderFunction, HTMLAttributes, forwardRef, useEffect } from 'react';
import { Oval as Loader } from 'react-loader-spinner';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';

import { Phases } from './components';

export type TestType = {
  label: string;
  checked: boolean;
  id: string;
};

type StudyPlanProps = {
  openInstructionModal: () => void;
} & HTMLAttributes<HTMLDivElement>;

const StudyPlan: ForwardRefRenderFunction<HTMLDivElement, StudyPlanProps> = ({ openInstructionModal }, ref) => {
  const { colors } = useTheme();
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const { errorToast } = useNotifications();
  const dispatch = useAppDispatch();

  const [changeCurrentPlan, isPlanSelecting] = useAsyncAction(actions.studyPlans.changeCurrentPlan);
  const [getPlansAction, isPlansLoading] = useAsyncAction(actions.studyPlans.getPlans);
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const [getCourseStatisticAction] = useAsyncAction(actions.performance.getCourseStatistic);

  const { plans, currentPlanId } = useAppSelector(selectors.studyPlans.selectPlans) || {};
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);

  const { hasPerformance } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  const clearPointIncreased = () => {
    dispatch(actions.performance.clearPointIncreased());
  };

  const selectPlan = async (newPlanId: string) => {
    try {
      await changeCurrentPlan({ newPlanId });
      if (hasPerformance) {
        clearPointIncreased();
        if (!isPause) {
          await getCourseStatisticAction();
        }
      }
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const getPlans = async () => {
    try {
      await getPlansAction();
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <Container ref={ref}>
      {isPlansLoading ? (
        <LoaderContainer>
          <Loader color={colors.primary[1]} secondaryColor="transparent" strokeWidth={4} />
        </LoaderContainer>
      ) : (
        <Cover>
          <Title>Please adhere to the study plan for optimal results</Title>
          <GrayLine>
            <Description>
              Please select the time until your planned exam date to receive the ideal study plan recommendation.
            </Description>
            <Wrapper>
              <TagsContainer isHorisontal>
                {plans?.map((plan) => {
                  const selected = currentPlanId === plan.id;
                  return (
                    <StyledTag
                      size="small"
                      key={plan.id}
                      text={plan.name}
                      selected={selected}
                      onClick={() => !selected && selectPlan(plan.id)}
                      $isLongText={plan.name.length > 10}
                      $isDarkMode={isDarkMode}
                    />
                  );
                })}
              </TagsContainer>
            </Wrapper>
          </GrayLine>
          <Phases isPlanSelecting={isPlanSelecting} />
        </Cover>
      )}
      <ButtonContainer>
        <StyledButton variant="primary" onClick={openInstructionModal}>
          Instruction manual
        </StyledButton>
      </ButtonContainer>
    </Container>
  );
};

export default forwardRef<HTMLDivElement, StudyPlanProps>(StudyPlan);

const Container = styled.div`
  width: 100%;
  max-width: 401px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  border-radius: 12px;
  display: grid;
  grid-template-rows: 1fr 84px;
  min-height: 637px;
  height: calc(100vh - 158px);

  ${respondToHeight.lg`
    height: auto;
  `}

  ${respondToWidth.lg`
    height: auto;
  `}
  ${respondToWidth.ls`
    max-width: 100%;
    max-height:538px;
    min-height: 538px;
  `}
  ${respondToWidth.sm`
    position: relative;
    width: 100%;
    max-height: 774px;
    grid-template-rows: 1fr;
    margin-top: 20px;
    margin-bottom: 80px;
  `}
`;
const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Cover = styled.div`
  width: 100%;
  padding: 24px 24px 0 24px;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${respondToWidth.sm`
  padding-bottom:24px;
  `}
`;
const ButtonContainer = styled.div`
  width: 100%;
  height: 84px;
  padding: 16px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 0 0 12px 12px;
  position: sticky;
  bottom: 0;

  ${respondToWidth.sm`
    display:none;
  `}
`;

const Title = styled(StyledText)`
  max-width: 286px;
  ${({ theme: { typography } }) => typography.body_large_bold_16};

  ${respondToWidth.lg`
    max-width:100%;
  `}
`;

const GrayLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-block: 20px;
  ${({ theme: { colors } }) => css`
    border-top: 1px solid ${colors.neutrals[9]};
    border-bottom: 1px solid ${colors.neutrals[9]};
  `}
  ${respondToWidth.sm`
    padding-block: 16px;
  `}
`;

const Description = styled(StyledText)`
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

const TagsContainer = styled(ScrollbarContainer)`
  position: absolute;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  padding-bottom: 10px;

  &::-webkit-scrollbar {
    height: 4px;
  }
`;

const StyledTag = styled(Tag)<{ selected: boolean; $isLongText: boolean; $isDarkMode: boolean }>`
  ${({ theme: { colors }, selected, $isLongText, $isDarkMode }) => css`
    text-align: center;
    padding: 6px 8px;
    border: none;
    width: ${!$isLongText && '100%'};
    color: ${selected ? colors.primary[1] : colors.neutrals[1]};
    background-color: ${selected ? colors.primary[6] : $isDarkMode ? colors.neutrals[7] : colors.neutrals[10]};
  `}
`;

const StyledButton = styled(Button)`
  padding-inline: 0;
  text-transform: capitalize;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 42px;
`;
