import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { LayoutStyledProps } from '../MnemonicCardPractice';

export const Container = styled.form<LayoutStyledProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  will-change: transform, opacity;
  justify-content: ${({ $isMobileLandscape }) => $isMobileLandscape && 'space-between'};

  ${respondToWidth.sm`
    justify-content: space-between;
  `};
`;

export const Main = styled.div<LayoutStyledProps>`
  width: 100%;
  max-width: 880px;
  height: calc(100% - 72px);
  flex-grow: 1;
  height: ${({ $isCard }) => $isCard && '100%'};
  border-radius: 20px 20px 0 0;
  display: ${({ $isCard }) => $isCard && 'flex'};
  flex-direction: column;

  ${({ $isMobileLandscape, $isLastSlide }) =>
    $isMobileLandscape &&
    css`
      flex-grow: 0;
      border-radius: 20px;
      height: calc(100vh - ${$isLastSlide ? '220px' : '184px'});
      padding-right: 4px;
      width: 100%;
    `}

  ${({ $isLastSlide }) => respondToWidth.sm`
  flex-grow: 0;
  border-radius: 20px;
  height: calc(100vh - ${$isLastSlide ? '340px' : '256px'});
  padding-right: 4px;
  width: 100%;
`};
`;

export const Wrapper = styled.div<LayoutStyledProps>`
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 20px 20px 0 0;
  padding: 32px 32px 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  ${({ $isMobileLandscape, $isCard }) =>
    $isMobileLandscape &&
    css`
      padding: ${$isCard ? '28px 16px' : '28px 4px 28px 16px'};
      height: ${$isCard && '100%'};
      min-height: ${$isCard && '300px'};
    `}

  ${({ $isCard }) => respondToWidth.sm`
  padding: ${$isCard ? '28px 16px' : '28px 4px 28px 16px'};
  border-radius: 20px;
  height:${$isCard && '100%'};
  min-height:${$isCard && '300px'}
`}
`;
