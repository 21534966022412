import React, { FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { selectors, useAppSelector } from 'store';

import { DatePickerWrapperDarkStyles, DatePickerWrapperStyles } from './DatePickerWrapperStyles';
import { CustomHeader } from './components';

type CalendarProps = {
  years: string[];
} & ReactDatePickerProps;

const Calendar: FC<CalendarProps> = (props) => {
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  /**
   * This one is needed to mark today
   */
  const highlightToday = [
    {
      date: new Date(),
    },
  ];

  return (
    <>
      <ReactDatePicker
        wrapperClassName="date_picker_wrapper"
        highlightDates={highlightToday.map((highlight) => highlight.date)}
        renderCustomHeader={(headerProps) => <CustomHeader years={props.years} {...headerProps} />}
        {...props}
      />

      {isDarkMode ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
    </>
  );
};

export default Calendar;
