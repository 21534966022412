import { LoaderIcon } from 'assets/icons';
import styled, { css, keyframes } from 'styled-components';

export type VariantCardButton = 'positive' | 'neutral' | 'negative';

export const StyledButton = styled.button<{
  $variant: VariantCardButton;
  $isLoading: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  outline: none;
  border: 1px solid transparent;
  border-radius: 12px;
  background-color: inherit;
  user-select: none;
  cursor: ${({ $isLoading }) => ($isLoading ? 'wait' : 'pointer')};

  &:hover {
    border-color: ${({ theme: { colors } }) => colors.neutrals[9]};
  }

  ${({ $variant, $isLoading }) => getStyledCardButton($variant, $isLoading)};
`;

const getStyledCardButton = (variant: VariantCardButton, isLoading: boolean) => {
  switch (variant) {
    case 'negative':
      return css`
        ${({ theme: { colors } }) => css`
          color: ${colors.system.red};
          background-color: ${isLoading ? colors.system.red : colors.system.lightRed};
        `}

        &:hover {
          background-color: ${!isLoading && 'inherit'};
        }
        &:active:enabled {
          ${({ theme: { colors } }) => css`
            background-color: ${colors.system.red};
          `}
          color: ${({ theme: { colors } }) => colors.neutrals[11]};
        }
      `;
    case 'neutral':
      return css`
        ${({ theme: { colors } }) => css`
          color: ${colors.system.blue};
          background-color: ${isLoading ? colors.system.blue : colors.system.lightBlue};
        `}

        &:hover {
          background-color: ${!isLoading && 'inherit'};
        }

        &:active:enabled {
          ${({ theme: { colors } }) => css`
            background-color: ${!isLoading && colors.system.blue};
            color: ${colors.neutrals[11]};
          `}
        }
      `;
    case 'positive':
      return css`
        ${({ theme: { colors } }) => css`
          color: ${colors.system.green};
          background-color: ${isLoading ? colors.system.green : colors.system.lightGreen};
        `}

        &:hover {
          background-color: ${!isLoading && 'inherit'};
        }
        &:active:enabled {
          ${({ theme: { colors } }) => css`
            background-color: ${!isLoading && colors.system.green};
            color: ${colors.neutrals[11]};
          `}
        }
      `;
  }
};

const rotate = keyframes`
  from {
      transform: rotate(0deg);
    }
  
  to {
      transform: rotate(360deg);
    }
  `;

export const StyledLoader = styled(LoaderIcon)`
  animation: ${rotate} 2s linear infinite;
`;
