import { useLayoutEffect, useRef, useState } from 'react';

const additionalSize = 5;
const EMPTY_COLUMN_COUNT = 2;

export const useIsShowEmptyColumn = <T>(data: Array<T>, columnWidth: number, gapSize: number) => {
  const [isShowEmptyColumn, setIsShowEmptyColumn] = useState(true);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const columnsWidth = (columnWidth + gapSize) * data.length;
  const emptyWidth = (containerRef.current?.scrollWidth || 0) - columnsWidth;
  const minWidthForShowEmptyColumn = EMPTY_COLUMN_COUNT * columnWidth + additionalSize;
  const isShow = emptyWidth > minWidthForShowEmptyColumn;

  useLayoutEffect(() => {
    setIsShowEmptyColumn(isShow);
  }, [isShow]);

  return { containerRef, isShowEmptyColumn };
};
