import { Button, StyledText } from 'UI';
import React, { FC, memo } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type NavigationFooterProps = {
  hasTest: boolean;
  numberOfChapters: number;
  navigateToChapter: (index: number) => void;
};

const NavigationFooter: FC<NavigationFooterProps> = ({ navigateToChapter, hasTest, numberOfChapters }) => {
  const text = hasTest ? 'Where to go?' : 'This is the end of this chapter and there is no test. Where to go?  ';
  const currentChapterIndex = useAppSelector(selectors.studyGuide.selectCurrentChapterIndex) || 0;

  return (
    <Root $hasTest={hasTest}>
      <Text as="p">{text}</Text>
      <ButtonsContainer>
        <Button
          disabled={!currentChapterIndex}
          onClick={() => navigateToChapter(currentChapterIndex - 1)}
          size="small"
          variant="secondary">
          Previous chapter
        </Button>
        <Button
          disabled={numberOfChapters === currentChapterIndex}
          onClick={() => navigateToChapter(currentChapterIndex + 1)}
          size="small">
          Next chapter
        </Button>
      </ButtonsContainer>
    </Root>
  );
};

export default memo(NavigationFooter);

const Root = styled.div<{ $hasTest: boolean }>`
  ${({ $hasTest, theme: { colors } }) => css`
    width: 100%;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    border-top: 1px solid;
    border-color: ${colors.neutrals[9]};

    ${!$hasTest &&
    respondToWidth.l`
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    `}

    ${respondToWidth.sm`
      display: flex;
      min-height: 114px;
      flex-direction: column;

      padding: 10px 16px 16px;
    `}
  `}
`;

const Text = styled(StyledText)`
  text-align: start;
  user-select: none !important;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_basic_bold_14}

    ${respondToWidth.sm`
        width: 100%;
        ${typography.body_basic_medium_14}
    `}
  `};
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  button {
    white-space: nowrap;
  }

  ${respondToWidth.sm`
      width: 100%;
      gap: 12px;

      button {
        width: 100%;
        padding-inline: 0;
      }
  `}
`;
