import Button from 'UI/Button';
import StyledText from 'UI/StyledText';
import Textarea from 'UI/Textarea';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';
import { validations } from 'utils';

import DefaultModal from '../DefaultModal';

type FormType = {
  content: string;
};

type ReportModalProps = {
  isLoading: boolean;
  onSubmit: (data: FormType) => void;
} & BaseModalProps;

const ReportModal: FC<ReportModalProps> = ({ isOpen, onClose, isLoading, onSubmit }) => {
  const { handleSubmit, control } = useFormContext<FormType>();

  return (
    <DefaultModal
      title="Report a problem"
      showCloseButton
      isOpen={isOpen}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      headerContainerCSS={HeaderCSS}
      modalCSS={ModalCSS}
      closeButtonCSS={CloseButtonCSS}>
      <Text>Describe the problem</Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="content"
          rules={{ required: 'Field not be empty', validate: validations.textWithSymbols }}
          render={({ field, fieldState: { error } }) => (
            <StyledTextarea maxLength={1000} {...field} error={error?.message} />
          )}
        />
        <ButtonsContainer>
          <Button variant="primary" size="middle" type="submit" isLoading={isLoading}>
            Send
          </Button>
          <CloseButton variant="secondary" size="middle" onClick={onClose}>
            Close
          </CloseButton>
        </ButtonsContainer>
      </Form>
    </DefaultModal>
  );
};

export default ReportModal;

const ContainerCSS = css`
  padding-bottom: 60px;
  width: 100%;

  ${respondToWidth.sm`
    padding:28px 16px;
    border-radius:20px 20px 0 0;
  `}
`;

const ModalCSS = css`
  max-width: 668px;
  width: 100%;

  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    bottom:0;
    top:auto;
    transform: translate(-50%, 0);
  `}
`;

const CloseButtonCSS = css`
  ${respondToWidth.s`
        display:none;
    `}
`;

const HeaderCSS = css`
  margin-bottom: 40px;

  ${respondToWidth.s`
    justify-content: center;
    margin-bottom: 32px;
  `}
`;

const Text = styled(StyledText)`
  ${({ theme: { typography, colors } }) => css`
    padding-left: 12px;
    margin-bottom: 4px;
    color: ${colors.neutrals[4]};
    ${typography.footnote_regular_12};
  `}
`;

const Form = styled.form`
  display: grid;
  gap: 40px;

  ${respondToWidth.s`
    gap:32px;
  `}
`;

const StyledTextarea = styled(Textarea)`
  height: 124px;
  max-width: 100%;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 11px;

  ${respondToWidth.s`
    padding-inline:7px;
  `}
`;

const CloseButton = styled(Button)`
  display: none;
  ${respondToWidth.s`
    display:flex;
  `}
`;
