import StyledText from 'UI/StyledText';
import styled, { CSSProp, css } from 'styled-components';
import { widthBreakpoints } from 'styles/general/variables';
import { VariantTypography } from 'types';

export type VariantInput = 'default' | 'search';

type StyledInputProps = {
  $paddingButton?: string;
  $isError: boolean;
  $variant: VariantInput;
  $isCorrect?: boolean;
  $variantTypography?: VariantTypography;
  $withIcon?: boolean;
  $isDarkMode?: boolean;
  $CSS?: CSSProp;
};

export const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  width: 100%;
  position: relative;

  ${({ $CSS }) => $CSS};
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledInput = styled.input<StyledInputProps>`
  ${({ theme: { colors, typography }, $paddingButton, $isError, $isCorrect, $variant, $isDarkMode }) => css`
    width: 100%;
    border-radius: 8px;
    color: ${colors.neutrals[1]};

    background-color: ${() => {
      if ($isCorrect) {
        return colors.system.lightGreen;
      }
      if ($isError) {
        return colors.system.lightRed;
      } else {
        if ($isDarkMode) {
          return colors.neutrals[11];
        } else {
          return colors.neutrals[10];
        }
      }
    }};

    border: 1px solid
      ${() => {
        if ($isCorrect) {
          return colors.system.green;
        }
        if ($isError) {
          return colors.system.red;
        } else {
          return colors.neutrals[8];
        }
      }};

    padding: ${$variant === 'default' ? '11px' : '11px 11px 11px 47px'};
    padding-right: ${({ theme: $withIcon }) => ($withIcon ? $paddingButton : '47px')};

    ${typography.body_basic_medium_14};

    &::placeholder {
      color: ${colors.neutrals[4]};
    }

    &:focus {
      border-color: ${colors.primary[1]};
      background-color: ${$isDarkMode ? colors.neutrals[11] : colors.neutrals[10]};

      @media (max-width: ${widthBreakpoints.sm}px) {
        font-size: 16px !important;
      }
    }

    &:disabled {
      cursor: not-allowed;
      color: ${colors.neutrals[6]};
    }

    /*To change the color of an input on autocomplete*/

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px ${$isError ? colors.system.lightRed : colors.neutrals[10]} inset !important;
    }

    &:-webkit-autofill:hover {
      box-shadow: 0 0 0 30px ${$isError ? colors.system.lightRed : colors.neutrals[10]} inset !important;
    }

    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 30px ${$isError ? colors.system.lightRed : colors.neutrals[10]} inset !important;
    }
  `}
`;

export const Label = styled(StyledText)<{ $disabled?: boolean }>`
  margin-left: 12px;
  margin-bottom: 4px;
  text-align: start;
  color: ${({ theme: { colors }, $disabled }) => ($disabled ? colors.neutrals[6] : colors.neutrals[4])};
  ${({ theme: { typography } }) => typography.footnote_regular_12};
`;

export const ErrorField = styled(Label)`
  position: absolute;
  margin-bottom: 0;
  margin-top: 4px;
  color: ${({ theme: { colors } }) => colors.system.red};
`;

export const IconContainer = styled.button`
  position: absolute;
  height: 24px;
  right: 12px;

  top: 50%;
  z-index: 1;

  display: flex;
  align-items: center;

  transform: translateY(-50%);
  cursor: pointer;
`;

export const SearchIconContainer = styled.div`
  position: absolute;
  height: 24px;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;
