import StyledText from 'UI/StyledText';
import React, { FC } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';

export type TagSize = 'small' | 'medium';

type TagProps = {
  text: string;
  size?: TagSize;
  count?: number;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  color?: string;
  isAllCards?: boolean;
};

const Tag: FC<TagProps> = ({
  size = 'medium',
  text,
  count,
  selected = false,
  disabled = false,
  onClick,
  className,
  color,
  isAllCards,
}) => {
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  return (
    <Container
      $size={size}
      $color={color}
      $selected={selected}
      className={className}
      $disabled={disabled}
      $isAllCards={isAllCards}
      $isDarkMode={isDarkMode}
      onClick={onClick}
      type="button">
      {text}
      {count && <Count $selected={selected}>{count}</Count>}
    </Container>
  );
};

export default Tag;

const Container = styled.button<{
  $size: TagSize;
  $selected: boolean;
  $disabled: boolean;
  $color?: string;
  $isAllCards?: boolean;
  $isDarkMode?: boolean;
}>`
  ${({ theme: { colors, typography }, $size, $selected, $disabled, $color, $isAllCards, $isDarkMode }) => css`
    width: fit-content;
    user-select: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 1px solid transparent !important;
    border-radius: 8px;
    padding: ${$size === 'medium' ? '7px 11px' : '5px 7px'};
    color: ${$isDarkMode ? colors.neutrals[1] : $color};
    background-color: ${$isDarkMode ? colors.neutrals[7] : colors.neutrals[10]};
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};

    ${() =>
      $selected
        ? $isAllCards
          ? css`
              background-color: ${$color};
              color: #fff;
            `
          : css`
              background-color: ${colors.primary[6]};
              color: ${colors.primary[1]};
            `
        : null};

    ${$size === 'medium' ? typography.body_large_semibold_16 : typography.body_basic_semibold_14};

    &:hover {
      ${() =>
        !$selected &&
        css`
          border: 1px solid ${$color} !important;
        `};
    }
  `};
`;

const Count = styled(StyledText)<{ $selected: boolean }>`
  ${({ theme: { colors, typography }, $selected }) => css`
    display: inline;
    margin-left: 8px;
    color: ${$selected ? colors.primary[1] : colors.neutrals[4]};
    ${typography.body_basic_regular_14}
  `};
`;
