import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SuccessResponseDto } from 'api/generated';

import { subscribeToMarketingEmailsThunk } from './actions';

interface ActiveCampaignSlice {
  success: boolean | null;
  message: string | null | undefined;
}

const initialState: ActiveCampaignSlice = {
  success: null,
  message: null,
};

export const activeCampaignSlice = createSlice({
  name: 'activeCampaign',
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = null;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subscribeToMarketingEmailsThunk.fulfilled, (state, action: PayloadAction<SuccessResponseDto>) => {
      state.success = action.payload.success;
      state.message = action.payload.message;
    });
  },
});

export const actions = {
  ...activeCampaignSlice.actions,
  subscribeActiveCampaign: subscribeToMarketingEmailsThunk,
};

export const { reducer } = activeCampaignSlice;
