import { DeleteAccountModal as DeleteAccountModalUI } from 'UI';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch } from 'store';
import { BaseModalProps } from 'types';

type DeleteAccountModal = {
  onDelete?: () => void;
} & BaseModalProps;

const DeleteAccountModal: FC<DeleteAccountModal> = ({ onDelete, ...props }) => {
  const navigate = useNavigate();
  const [userDeleteAccount, isLoading] = useAsyncAction(actions.user.deleteAccount);
  const dispatch = useAppDispatch();
  const { successToast } = useNotifications();

  const deleteAccount = async () => {
    await userDeleteAccount();

    onDelete?.();
    dispatch(actions.auth.signOut());

    navigate(routes.signIn);
    successToast('Your account successfully deleted');
  };

  return <DeleteAccountModalUI {...props} isLoading={isLoading} deleteAccount={deleteAccount} />;
};

export default DeleteAccountModal;
