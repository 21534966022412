import { useInterval } from 'hooks/useInterval';
import { useState } from 'react';
import { dayjs } from 'services';
import dateHelpers from 'utils/dateHelpers';

export const useTimer = (date: string, callBack: () => void) => {
  const currentDate = new Date();
  const toDate = new Date(date);
  const [time, setTime] = useState<number>(-dayjs(currentDate).diff(toDate, 'seconds'));
  const { formattedDateString, daysString, hoursString, minutesString, secondsString } = dateHelpers(time);

  useInterval(() => {
    if (time > 0) {
      setTime((t) => t - 1);
    } else {
      callBack();
    }
  }, 1000);

  return {
    formattedDateString,
    daysString,
    hoursString,
    minutesString,
    secondsString,
  };
};
