import { StyledText } from 'UI';
import { DefaultModal } from 'UI/Modals';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

type CreatePasswordModalProps = {
  onBackIconClick?: () => void;
  confirmRestorePassword?: (password: string) => Promise<void>;
  signIn?: (password: string) => Promise<void>;
  restorePassword?: () => Promise<void>;
  email: string;
  isLoading?: boolean;
} & BaseModalProps;

const CreatePasswordModal: FC<CreatePasswordModalProps> = ({ onBackIconClick, onClose, email, ...props }) => {
  return (
    <DefaultModal
      {...props}
      onRequestClose={() => onClose()}
      title="Restore password"
      containerCSS={ContainerCSS}
      changeCloseButton
      modalCSS={ModalCSS}
      showBackButton
      onBackIconClick={onBackIconClick}
      onMobileCloseButtonClick={onBackIconClick}
      isMobileOverlayStyles>
      <Form>
        <TextContainer>
          <Text>
            An email has been sent to your email address (<Example>{email}</Example>) with instructions on how to reset
            your password. Please check your email and follow the instructions provided in the email. If you haven`t
            received the email, please check your spam or junk folder. If you encounter any issues, please contact our
            support team.
          </Text>
        </TextContainer>
      </Form>
    </DefaultModal>
  );
};

export default CreatePasswordModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
`;

const Text = styled(StyledText)`
  text-align: justify;
  ${({ theme: { typography, colors } }) => css`
    color: ${colors.neutrals[4]};
    ${typography.body_large_semibold_16}

    ${respondToWidth.sm`
      display: inline;
      color: ${colors.neutrals[1]};
  `}
  `};
`;

const TextContainer = styled.div`
  margin-bottom: 44px;
  text-align: center;

  ${respondToWidth.s`
    text-align: start;
    margin-bottom: 24px;
  `}
`;

const Example = styled(Text)`
  display: contents;
  margin-top: 4px;
  color: ${({ theme: { colors } }) => colors.primary[2]};

  ${respondToWidth.sm`
      display: inline;
      margin-top: 0px;
  `}
`;
