import { SVGProps } from 'react';
import { Props as ReactModalProps } from 'react-modal';
import { RootState } from 'store';
import { typography } from 'styles/general/typography';
import { heightBreakpoints, widthBreakpoints } from 'styles/general/variables';

export interface SVGIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: number;
}

export enum TestListTabs {
  DOMAINS = 'Domains',
  CHAPTERS = 'Chapters',
}

export type VariantTypography = keyof typeof typography;

export type BaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
} & ReactModalProps;

export type ExtraParamsThunkType<T = DefaultRejectValue> = {
  rejectValue: T;
  state: RootState;
};

export interface DefaultRejectValue {
  message?: string[] | string;
  statusCode?: number;
  error?: string;
}

export type ImageType = {
  url: string;
  alt: string;
};

export type PaginationType = {
  page?: number;
  limit?: number;
};

export type OptionType = {
  label: string;
  value?: string | number;
};

export type ModeType = 'MARKED' | 'UNUSED' | 'INCORRECT' | 'ALL';

export enum AnswerEnum {
  UNANSWERED = 'UNANSWERED',
  NO = 'NO',
  KINDA = 'KINDA',
  YES = 'YES',
  ALL = 'ALL',
}

export type AnswerType = 'UNANSWERED' | 'NO' | 'KINDA' | 'YES' | undefined;

export type FlashcardsPackType = 'questions' | 'cards';

export enum StatisticColumnType {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  UNUSED = 'unused',
  NO = 'no',
  KINDA = 'kinda',
  YES = 'yes',
}

export type ManualPaymentsDescriptions = {
  startPhrase: string;
  endPhrase: string;
};

//TODO: replace type from api (27.07.2023 - does not exist)
export type StatisticValue = {
  name: string;
  count: number;
  percent: number;
};

export type BreakpointsType = keyof typeof widthBreakpoints;
export type HeightBreakpointsType = keyof typeof heightBreakpoints;
