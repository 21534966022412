import { Answer } from 'UI';
import { Button, ExpandImage, ScrollbarContainer, StyledText } from 'UI';
import { CreateReportDtoResourceEnum, QuestionResponseDto } from 'api/generated';
import { SpamIcon } from 'assets/icons';
import { Loader, ReportModal } from 'components';
import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';
import styled, { css } from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';

type TestContentProps = {
  question?: QuestionResponseDto;
  select?: string;
  buttonText: string;
  isLoading: boolean;
};

const TimedTestContent: FC<TestContentProps> = ({ question, select, buttonText, isLoading }) => {
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const [isSomeImageLoaded, setIsSomeImageLoaded] = useState(false);

  const {
    control,
    formState: { isValid },
  } = useFormContext();

  const { colors } = useTheme();

  const hasImage = Boolean(question?.imagesUrls.length);

  return (
    <MainContainer>
      <Container>
        <Wrapper $withImage={hasImage}>
          <Title $withImage={hasImage}>{question?.subject}</Title>
          {hasImage ? (
            <ImagesContainer>
              {question?.imagesUrls.map((imageUrl, index) => (
                <ImageContainer
                  key={index}
                  image={{ url: imageUrl, alt: question.subject }}
                  setIsImageLoaded={setIsSomeImageLoaded}
                />
              ))}
              {!isSomeImageLoaded && (
                <EmptyContainer>
                  <Loader />
                </EmptyContainer>
              )}
            </ImagesContainer>
          ) : null}
          <Cover>
            <AnswersContainer $withImage={hasImage}>
              {question?.options.map((option) => (
                <Controller
                  key={option.id}
                  control={control}
                  name="id"
                  defaultValue={''}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Answer
                      {...field}
                      id={option.id}
                      label={option.title}
                      value={option.id}
                      initialChecked={option.id === select}
                      type="radio"
                    />
                  )}
                />
              ))}
            </AnswersContainer>
            <ReportButton
              iconComponent={<SpamIcon width={24} height={24} color={colors.system.red} />}
              variant="secondary"
              size="small"
              onClick={() => setIsShowReportModal(true)}>
              Report a problem
            </ReportButton>
            <StyledButton
              type="submit"
              variant="primary"
              size="middle"
              disabled={!isValid && !isLoading}
              isLoading={isLoading}>
              {buttonText}
            </StyledButton>
          </Cover>
        </Wrapper>
        <ReportModal
          resource={CreateReportDtoResourceEnum.TestQuestion}
          resourceId={question?.id || ''}
          isOpen={isShowReportModal}
          onClose={() => setIsShowReportModal(false)}
        />
      </Container>
    </MainContainer>
  );
};

export default TimedTestContent;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 8px;

  ${respondToWidth.sm`
    padding-right: 4px;
  `}
`;

const Container = styled(ScrollbarContainer)`
  height: calc(100vh - 184px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${respondToWidth.sm`
    padding-inline: 16px 8px;
    height: calc(100vh - 152px);
  `}
`;

const Wrapper = styled.div<{ $withImage: boolean }>`
  height: 100%;
  width: 100%;
  max-width: 668px;
  display: flex;
  flex-direction: column;
  padding-block: 60px 32px;

  ${({ $withImage }) => respondToHeight.ls`
    padding-block: ${$withImage && '30px'};
  `}

  ${respondToWidth.ls`
    padding-block: 24px 20px;
  `}
`;

const Title = styled(StyledText)<{ $withImage: boolean }>`
  margin-bottom: 40px;
  ${({ theme: { typography } }) => css`
    ${typography.title_3_bold_28};

    ${respondToWidth.sm`
        ${typography.title_5_bold_20};
    `}
  `}
  ${({ $withImage }) => respondToHeight.ls`
    margin-bottom: ${$withImage && '20px'};
  `}
`;

const AnswersContainer = styled.div<{ $withImage: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 20px;

  ${({ $withImage }) => respondToHeight.ls`
    gap:${$withImage && '10px'};
  `}

  ${respondToWidth.sm`
    gap:16px;
  `}
`;

const Cover = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 28px;
  gap: 28px;

  ${respondToWidth.sm`
    justify-content: start;
    gap:0;
  `}
`;

const StyledButton = styled(Button)`
  width: fit-content;

  ${respondToWidth.sm`
    display:none;
  `}
`;

const ReportButton = styled(Button)`
  display: none;
  margin-top: 32px;
  margin-bottom: 10px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.sm`
    display: flex;
  `}
`;

const ImagesContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  gap: 14px;
  align-items: center;

  ${respondToHeight.ls`
    margin-bottom: 20px;
    gap:7px;
  `}
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 161px;

  ${respondToWidth.sm`
    height:82px;
  `}
`;

const ImageContainer = styled(ExpandImage)`
  width: 100%;
  max-width: 32%;
  height: 121px;
  border-radius: 16px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;

    ${respondToWidth.sm`
        border-radius: 8px;
    `}
  }

  ${respondToWidth.sm`
    height:62px;
  `}
`;
