import { createSlice } from '@reduxjs/toolkit';
import { FlashcardsPackDto, PaginationMetaDto } from 'api/generated';

import {
  addToFlashcardsThunk,
  getMarkedForReCheckingCountThunk,
  getPacksFlashcardsThunk,
  removeFromFlashcardsThunk,
} from './actions';

interface FlashcardsPacksSlice {
  flashcardsPacks: FlashcardsPackDto[] | null;
  markedForReCheckingCount: number | null;
  meta: PaginationMetaDto | null;
}

const initialState: FlashcardsPacksSlice = {
  flashcardsPacks: null,
  markedForReCheckingCount: null,
  meta: null,
};

export const flashcardsPacksSlice = createSlice({
  name: 'flashcardsPacks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPacksFlashcardsThunk.fulfilled, (state, { payload }) => {
        const flashcardsPacks = state.flashcardsPacks || [];

        state.flashcardsPacks = payload.isFirstFetch ? payload.data.items : [...flashcardsPacks, ...payload.data.items];
        state.meta = payload.data.meta;
      })
      .addCase(getMarkedForReCheckingCountThunk.fulfilled, (state, { payload }) => {
        state.markedForReCheckingCount = payload.count;
      });
  },
});

export const actions = {
  ...flashcardsPacksSlice.actions,
  addToFlashcards: addToFlashcardsThunk,
  removeFromFlashcards: removeFromFlashcardsThunk,
  getPacksFlashcards: getPacksFlashcardsThunk,
  getMarkedForReCheckingCount: getMarkedForReCheckingCountThunk,
};

export const { reducer } = flashcardsPacksSlice;
