import { AppConfig } from 'config';

import {
  ActiveCampaignApi,
  AuthApi,
  CoursesV1Api,
  CustomerAppSettingsApi,
  CustomersV1Api,
  DefaultApi,
  DirectionsV1Api,
  FlashcardsV1Api,
  GuidesApi,
  MnemonicCardsV1Api,
  PaymentsV1Api,
  PlansV1Api,
  QuestionChaptersApi,
  QuestionDomainsApi,
  QuestionsApi,
  QuizzesApi,
  ReportsApi,
  StatisticsV2Api,
  StripeApi,
  SuggestionsApi,
  TasksV1Api,
  TestPassagesApi,
  TimedTestsApi,
  TutorTestsApi,
} from './generated/api';
import httpClient from './https-client';

export default {
  Default: new DefaultApi(undefined, AppConfig.BASE_API_URL, httpClient),
  Auth: new AuthApi(undefined, AppConfig.BASE_API_URL, httpClient),
  ActiveCampaign: new ActiveCampaignApi(undefined, AppConfig.BASE_API_URL, httpClient),
  Customer: new CustomersV1Api(undefined, AppConfig.BASE_API_URL, httpClient),
  Directions: new DirectionsV1Api(undefined, AppConfig.BASE_API_URL, httpClient),
  Payments: new PaymentsV1Api(undefined, AppConfig.BASE_API_URL, httpClient),
  Courses: new CoursesV1Api(undefined, AppConfig.BASE_API_URL, httpClient),
  Tests: new TestPassagesApi(undefined, AppConfig.BASE_API_URL, httpClient),
  Flashcards: new FlashcardsV1Api(undefined, AppConfig.BASE_API_URL, httpClient),
  MnemonicCards: new MnemonicCardsV1Api(undefined, AppConfig.BASE_API_URL, httpClient),
  Plans: new PlansV1Api(undefined, AppConfig.BASE_API_URL, httpClient),
  Tasks: new TasksV1Api(undefined, AppConfig.BASE_API_URL, httpClient),
  Stripe: new StripeApi(undefined, AppConfig.BASE_API_URL, httpClient),
  Statistics: new StatisticsV2Api(undefined, AppConfig.BASE_API_URL, httpClient),
  Report: new ReportsApi(undefined, AppConfig.BASE_API_URL, httpClient),
  Guides: new GuidesApi(undefined, AppConfig.BASE_API_URL, httpClient),
  QuestionDomains: new QuestionDomainsApi(undefined, AppConfig.BASE_API_URL, httpClient),
  QuestionChapters: new QuestionChaptersApi(undefined, AppConfig.BASE_API_URL, httpClient),
  TutorTests: new TutorTestsApi(undefined, AppConfig.BASE_API_URL, httpClient),
  TimedTests: new TimedTestsApi(undefined, AppConfig.BASE_API_URL, httpClient),
  Questions: new QuestionsApi(undefined, AppConfig.BASE_API_URL, httpClient),
  Quizzes: new QuizzesApi(undefined, AppConfig.BASE_API_URL, httpClient),
  Settings: new CustomerAppSettingsApi(undefined, AppConfig.BASE_API_URL, httpClient),
  Suggestions: new SuggestionsApi(undefined, AppConfig.BASE_API_URL, httpClient),
};
