import { ToggleButton } from 'UI';
import { useNotifications } from 'hooks';
import { FC, useState } from 'react';
import { useAppDispatch } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { SettingOption } from '../../SettingButtonsList';

const Option: FC<SettingOption> = (option) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { errorToast } = useNotifications();

  const handleChange = async () => {
    setIsLoading(true);
    try {
      await dispatch(option.action());
    } catch (err) {
      errorToast('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper key={option.title}>
      <p>{option.title}</p>
      <ToggleButton checked={option.isEnabled} onChange={handleChange} disabled={isLoading} />
    </Wrapper>
  );
};

export default Option;

const Wrapper = styled.div`
  ${({ theme: { colors, typography } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    padding: 8px 20px;
    border-bottom: 1px solid ${colors.neutrals[9]};

    :first-of-type {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    & p {
      color: ${colors.neutrals[1]};
      ${typography.body_basic_medium_14};
    }

    ${respondToWidth.sm`
      min-height: 52px;
      background-color: ${colors.neutrals[10]};
      
      & p {
        ${typography.body_large_medium_16};
      };
    `}
  `};
`;
