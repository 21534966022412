import IconButton from 'UI/IconButton';
import { CloseIcon } from 'assets/icons';
import { useGetIsMobile } from 'hooks/useGetIsMobile';
import { useIsLandscapeOrientation } from 'hooks/useIsLandscapeOrientation';
import ControlBar from 'pages/MnemonicCards/components/ControlBar';
import { ControlBarProps } from 'pages/MnemonicCards/components/ControlBar/ControlBar';
import React, { FC } from 'react';
import Modal, { Props } from 'react-modal';
import styled, { CSSProp, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

type FullScreenModalProps = {
  containerCSS?: CSSProp;
  modalCSS?: CSSProp;
  controlBarProps?: ControlBarProps;
} & Props;

const FullScreenModal: FC<FullScreenModalProps> = ({
  containerCSS = {},
  modalCSS = {},
  onRequestClose,
  controlBarProps,
  children,
  ...props
}) => {
  const { colors } = useTheme();
  const { isLandscapeOrientation } = useIsLandscapeOrientation();
  const { isMobile } = useGetIsMobile();
  const isShowControl = !!controlBarProps;

  return (
    <StyledModal
      style={{
        overlay: {
          backgroundColor: isLandscapeOrientation && isMobile ? colors.neutrals[1] : 'rgba(51, 51, 51, 0.8)',
          zIndex: 9999,
        },
      }}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      $CSS={modalCSS}
      {...props}>
      <Wrapper>
        <CloseButton iconComponent={<CloseIcon size={24} />} onClick={onRequestClose} $isMobile={isMobile} />
        <Container $CSS={containerCSS}>{children}</Container>
        {isShowControl && <ControlBar {...controlBarProps} />}
      </Wrapper>
    </StyledModal>
  );
};

export default FullScreenModal;

type StyledModalType = {
  $CSS: CSSProp;
};

const StyledModal = styled(Modal)<StyledModalType>`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  ${({ $CSS }) => $CSS};
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  padding-top: 100px;

  @media (orientation: landscape) {
    padding-block: 0;
  }
`;

const Container = styled.div<{ $CSS: CSSProp }>`
  ${({ $CSS }) => $CSS};
  height: 100%;

  @media (orientation: landscape) {
    padding-inline: 76px;
  }
`;

const CloseButton = styled(IconButton)<{ $isMobile: boolean }>`
  position: absolute;
  border-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  top: 32px;
  right: ${({ $isMobile }) => ($isMobile ? '16px' : '40px')};
  z-index: 2;

  svg path {
    fill: ${({ theme: { colors } }) => colors.neutrals[11]};
  }

  ${respondToWidth.sm`
    right: 16px;
  `}
`;
