import { OutputData } from '@editorjs/editorjs';
import { CustomerChapterNoteResponseDto } from 'api/generated';
import { useContentWithNote } from 'hooks';
import { FC, MouseEvent, useRef } from 'react';
import { Editor } from 'services';
import styled from 'styled-components';

type UnitProps = {
  id: string;
  content: object;
  notes?: CustomerChapterNoteResponseDto[] | null;
  handleSelectText: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, unitId?: string) => void;
  onMouseDown: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
  onContextMenu: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, unitId?: string) => void;
  onTouchEnd: (unitId?: string) => void;
};

export const UNIT_CONTENT_EDITOR_ID = 'chapter-unit-';

const Unit: FC<UnitProps> = ({
  id,
  handleSelectText,
  onMouseDown,
  onContextMenu,
  onTouchEnd,
  content,
  notes,
  ...props
}) => {
  const editorRef = useRef(null);

  const contentWithNotes = useContentWithNote((content as OutputData) || null, notes || null);

  return (
    <Root id={id}>
      {contentWithNotes && (
        <Editor
          {...props}
          defaultData={contentWithNotes}
          holder={`${UNIT_CONTENT_EDITOR_ID}${id}`}
          editorRef={editorRef}
          onMouseDown={onMouseDown}
          onTouchEnd={() => onTouchEnd(id)}
          onContextMenu={(e) => onContextMenu(e, id)}
          onMouseUp={(e) => handleSelectText(e, id)}
        />
      )}
    </Root>
  );
};

export default Unit;

const Root = styled.div`
  display: grid;
  gap: 8px;
  margin-bottom: 24px;
`;
