import StyledText from 'UI/StyledText/StyledText';
import { FC, ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

type CardWithIconProps = {
  Icon: ReactNode;
  text: string | number;
  label?: string;
  cardCSS?: CSSProp;
};

const CardWithIcon: FC<CardWithIconProps> = ({ Icon, text, label, cardCSS }) => {
  return (
    <Root>
      {Boolean(label) && <StyledText font="body_basic_semibold_14">{label}</StyledText>}
      <Card $cardCSS={cardCSS}>
        {Icon}
        <StyledText font="title_2_bold_32">{text}</StyledText>
      </Card>
    </Root>
  );
};

export default CardWithIcon;

const Root = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  gap: 8px;
  justify-content: center;
`;

const Card = styled.div<{ $cardCSS?: CSSProp }>`
  display: flex;
  height: 120px;
  width: 100%;

  justify-content: center;
  align-items: center;
  padding-inline: 32px;
  gap: 16px;

  border: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  border-radius: 12px;

  ${({ $cardCSS }) => $cardCSS};
`;
