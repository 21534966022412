import { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SVGIconProps } from 'types';

const SvgBookFillIcon: FC<SVGIconProps> = ({ color: propsColor, ...props }) => {
  const { colors } = useTheme();
  const color = propsColor || colors.neutrals[1];
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28 5.333H9.333a2.667 2.667 0 1 0 0 5.334H28V28a1.333 1.333 0 0 1-1.333 1.333H9.333A5.334 5.334 0 0 1 4 24V8a5.333 5.333 0 0 1 5.333-5.333h17.334A1.333 1.333 0 0 1 28 4v1.333Zm-1.333 4H9.333a1.333 1.333 0 1 1 0-2.666h17.334v2.666Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgBookFillIcon);
export default Memo;
