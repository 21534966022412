import { StyledText } from 'UI';
import { useClientSize } from 'hooks';
import { FC, MouseEventHandler, ReactNode, useState } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { TooltipModal } from './components';

type TooltipProps = {
  children: ReactNode;
  text: string;
  title: string;
  isAbove?: boolean;
  windowCSS?: CSSProp;
  shouldPreventDefault?: boolean;
  isLeftTooltip?: boolean;
  isShowOverride?: boolean;
};

const Tooltip: FC<TooltipProps> = ({
  children,
  text,
  title,
  isAbove = false,
  windowCSS,
  shouldPreventDefault = true,
  isLeftTooltip = false,
  isShowOverride,
  ...props
}) => {
  const { getIsBreakpoint } = useClientSize();
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
  const isWidthSm = getIsBreakpoint('sm');

  const handleClick: MouseEventHandler = (e) => {
    shouldPreventDefault && e.preventDefault();
    e.stopPropagation();

    setIsVisibleTooltip(true);
  };

  return (
    <Root {...props}>
      <Wrapper
        onClick={handleClick}
        onMouseEnter={() => !isWidthSm && setIsVisibleTooltip(true)}
        onMouseLeave={() => !isWidthSm && setIsVisibleTooltip(false)}
        type="button">
        {children}
      </Wrapper>
      {isVisibleTooltip && !isShowOverride && (
        <TooltipWindow $isAbove={isAbove} $CSS={windowCSS} $isLeftTooltip={isLeftTooltip}>
          <Text>{text}</Text>
        </TooltipWindow>
      )}
      <TooltipModal title={title} text={text} isOpen={isVisibleTooltip} onClose={() => setIsVisibleTooltip(false)} />
    </Root>
  );
};

export default Tooltip;

const Text = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[11]};
  ${({ theme: { typography } }) => typography.footnote_semibold_12};
`;

const Root = styled.div`
  position: relative;
  display: flex;
  height: fit-content;

  &::after {
    content: ' ';
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    top: -12px;
    left: -12px;
    z-index: 1;

    position: absolute;
  }
`;

const Wrapper = styled.button`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

const TooltipWindow = styled.div<{ $isAbove: boolean; $CSS?: CSSProp; $isLeftTooltip: boolean }>`
  max-width: 468px;
  width: 10000%;

  position: absolute;
  right: 0;
  z-index: 1;
  ${({ $isAbove, $isLeftTooltip }) => {
    const translateX = $isLeftTooltip ? '-70%' : '0';

    return $isAbove
      ? css`
          top: -12px;
          transform: translate(${translateX}, -100%);
        `
      : css`
          top: -6px;
          transform: translate(${translateX}, 100%);
        `;
  }};

  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  border-radius: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[1]};

  ${respondToWidth.sm`
    display: none;
  `}

  ${({ $CSS }) => $CSS}
`;
