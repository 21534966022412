import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  CustomerAuthResponseDto,
  CustomerRestorePasswordDto,
  CustomerRestorePasswordResponseDto,
  CustomerSignInDto,
  CustomerSignUpDto,
  CustomerSignUpPromoDto,
  CustomerSignUpResponseDto,
  ForgottenPasswordDto,
  StripeCustomerInfoRequestDto,
  StripeCustomerInfoResponseDto,
} from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType } from 'types';

export const signInThunk = createAsyncThunk<
  CustomerAuthResponseDto,
  CustomerSignInDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('auth/signIn', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Auth.customerAuthControllerSignIn(body);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const restorePasswordThunk = createAsyncThunk<
  CustomerRestorePasswordResponseDto,
  CustomerRestorePasswordDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('auth/restorePassword', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Auth.customerAuthControllerRestorePassword(body);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const confirmRestorePasswordThunk = createAsyncThunk<
  CustomerSignUpResponseDto,
  ForgottenPasswordDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('auth/confirmRestorePassword', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Auth.customerAuthControllerUpdateForgottenPassword(body);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const signUpByEmailThunk = createAsyncThunk<
  CustomerSignUpResponseDto,
  CustomerSignUpDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('auth/signUpByEmail', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Auth.customerAuthControllerSignUpByEmail(body);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const signUpPromoThunk = createAsyncThunk<
  CustomerSignUpResponseDto,
  CustomerSignUpPromoDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('auth/signUpPromo', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Auth.customerAuthControllerSignUpPromo(body);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const getCustomerByEmailThunk = createAsyncThunk<
  StripeCustomerInfoResponseDto,
  StripeCustomerInfoRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('stripe/customer', async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Stripe.stripeControllerGetCustomer(body);

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});
