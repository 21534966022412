import { routes } from 'constant/routes';
import React, { ComponentType, FC } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { selectors, useAppSelector } from 'store';

type PrivateRouteType = FC<{ component: ComponentType } & RouteProps>;

const PrivateRoute: PrivateRouteType = ({ component: Component }) => {
  const isAuth = useAppSelector(selectors.auth.selectAccessToken);

  return isAuth ? <Component /> : <Navigate to={routes.signIn} replace />;
};

export default PrivateRoute;
