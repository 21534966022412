import { ScrollbarContainer, StyledText } from 'UI';
import { GetChaptersWithNotesResponseDto } from 'api/generated';
import { AxiosError } from 'axios';
import { queryConstants, routes } from 'constant/routes';
import { useAsyncAction, useNotifications } from 'hooks';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { hideText } from 'utils';

import { NotesList } from './components';

type NotesProps = {
  chapters: Array<GetChaptersWithNotesResponseDto> | null;
  onClose?: () => void;
};

const Notes: FC<NotesProps> = ({ chapters, onClose }) => {
  const navigate = useNavigate();
  const { errorToast } = useNotifications();
  const [getChapterByIdAction] = useAsyncAction(actions.studyGuide.getChapterById);
  const currentChapter = useAppSelector(selectors.studyGuide.selectCurrentChapter);
  const tableOfGuideContent = useAppSelector(selectors.studyGuide.selectTableOfGuideContent);
  const studyGuide = useAppSelector(selectors.studyGuide.selectStudyGuide);

  const handleNoteClick = async (chapterId: string, noteId: string) => {
    onClose?.();

    if (currentChapter?.id !== chapterId) {
      const index = tableOfGuideContent?.chapters.findIndex((chapter) => chapter.id === chapterId);
      navigate(`${routes.studyGuide}?${queryConstants.chapterIndex}=${index}`);

      await getChapterById(chapterId);

      setTimeout(() => scrollToNote(noteId), 2000);
    } else {
      scrollToNote(noteId);
    }
  };

  const scrollToNote = async (noteId: string) => {
    const note = document.getElementById(noteId);
    note?.scrollIntoView({ behavior: 'auto', block: 'center' });
  };

  const getChapterById = async (chapterId: string) => {
    try {
      if (studyGuide) {
        await getChapterByIdAction({ chapterId, guideId: studyGuide?.id });
      }
    } catch (error) {
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    }
  };

  if (!chapters?.length) {
    return (
      <Container>
        <Caption font="footnote_regular_12">You don{"'"}t have notes and highlights yet</Caption>
      </Container>
    );
  }

  return (
    <Root>
      {chapters?.map(
        (chapter) =>
          chapter && (
            <Content key={chapter.id}>
              <ChapterTitle>{chapter.name}</ChapterTitle>
              <NotesList notes={chapter.notes} onNoteClick={(noteId) => handleNoteClick(chapter.id, noteId)} />
            </Content>
          ),
      )}
    </Root>
  );
};

export default Notes;

const Root = styled(ScrollbarContainer)`
  display: grid;
  padding: 16px;
  padding-left: 24px;
  grid-gap: 24px;
  align-content: flex-start;

  overflow-x: hidden;
`;

const Content = styled.div`
  display: grid;
  grid-gap: 24px;

  ${respondToWidth.sm`
      grid-gap: 28px;
  `}
`;

const ChapterTitle = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    position: relative;

    display: flex;
    align-items: center;
    width: fit-content;

    color: ${colors.neutrals[1]};
    ${typography.body_basic_bold_14}
    ${hideText(1)}
  `};
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  justify-content: center;
  align-items: center;
`;

const Caption = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
`;
