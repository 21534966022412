import { Button, Course, MoreButton, ScrollbarContainer, StyledText } from 'UI';
import { CourseDto } from 'api/generated';
import { PlusIcon } from 'assets/icons';
import { routes } from 'constant/routes';
import { useAsyncAction, useClientSize, useNotifications } from 'hooks';
import { FC, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';

const MAX_PREVIEW_COUNT = 3;

type CoursesProps = {
  isLoading: boolean;
  isShowChangePlanButton?: boolean;
};

const Courses: FC<CoursesProps> = ({ isLoading, isShowChangePlanButton }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  const { successToast, errorToast } = useNotifications();
  const { getIsBreakpoint } = useClientSize();
  const { colors } = useTheme();

  const [isShowAll, setIsShowAll] = useState(false);

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const [setActiveCourse, isSetActiveCourseLoading] = useAsyncAction(actions.courses.setActiveCourse);
  const courses = useAppSelector(selectors.courses.selectCourses);

  const selectCourse = (course: CourseDto) => dispatch(actions.courses.selectCourse(course));
  const openChooseProductModalAction = () => dispatch(actions.courses.openChooseProductModalOpen());

  const openChooseProductModal = async (course: CourseDto) => {
    selectCourse(course);
    openChooseProductModalAction();
  };

  const isWidthSm = getIsBreakpoint('sm');
  const isWidthLs = getIsBreakpoint('ls');

  const isShowGetMoreButton = (courses?.length || 0) > MAX_PREVIEW_COUNT && isWidthSm;
  const sortedCourses = courses
    ?.slice()
    .sort((a, b) => (a.id === activeCourse?.id ? -1 : b.id === activeCourse?.id ? 1 : 0));
  const visibleCourses = isShowAll || !isShowGetMoreButton ? sortedCourses : sortedCourses?.slice(0, MAX_PREVIEW_COUNT);

  const fetchSetActiveCourse = async (courseId: string) => {
    try {
      if (activeCourse?.id !== courseId) {
        await setActiveCourse({ courseId });
        successToast('Your active course successfully set');
      }
    } catch (error) {
      errorToast('Error');
    }
  };

  return (
    <Root>
      <Header>
        <StyledText as="h3" font={isWidthLs ? 'title_5_bold_20' : 'title_4_bold_24'}>
          Your Courses
        </StyledText>
        {!isWidthSm && (
          <StyledButton
            size="small"
            onClick={() => navigate(routes.addNewCourse)}
            iconComponent={
              <PlusIcon color={isDarkMode ? colors.neutrals[1] : colors.neutrals[11]} width={24} height={24} />
            }>
            Add a new course
          </StyledButton>
        )}
      </Header>
      <List>
        {isLoading ? (
          <ThreeDots width={48} height={48} color={colors.primary[3]} />
        ) : (
          visibleCourses?.map((course, index, courses) => (
            <Course
              key={course.id}
              course={course}
              isShowChangePlanButton={isShowChangePlanButton}
              isLastItem={!!index && index === courses.length - 1}
              onClick={() => fetchSetActiveCourse(course.id)}
              disabled={isSetActiveCourseLoading}
              onStatusClick={() => openChooseProductModal(course)}
            />
          ))
        )}
        {isShowGetMoreButton && (
          <MoreButton
            text={isShowAll ? 'Less courses' : 'More courses'}
            isShowAll={isShowAll}
            onClick={() => setIsShowAll((prevValue) => !prevValue)}
          />
        )}
      </List>
      {isWidthSm && (
        <Footer>
          <Button
            size="middle"
            onClick={() => navigate(routes.addNewCourse)}
            iconComponent={<PlusIcon color={colors.neutrals[11]} width={24} height={24} />}>
            Add a new course
          </Button>
        </Footer>
      )}
    </Root>
  );
};

export default Courses;

const Root = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100%;
    height: 100%;
    min-height: 500px;
    /* max-height: calc(100vh - 68px); */
    overflow: hidden;

    display: grid;
    grid-template-rows: min-content 1fr;

    padding: 24px;
    padding-right: 8px;

    background-color: ${colors.neutrals[11]};
    border-radius: 12px;

    ${respondToHeight.s`
      min-height: 160px;
    `}

    ${respondToWidth.ls`
      min-height: 160px;
    `}

    ${respondToWidth.sm`
      max-height: none;
      padding: 0;
      padding-bottom: 32px;

      border-radius: 0px;
      border-bottom: 1px solid ${colors.neutrals[9]};
    `}
  `}
`;

const Header = styled.div`
  width: 100%;
  padding-right: 16px;
  padding-bottom: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${respondToWidth.sm`
      padding-right: 0;
      padding-bottom: 16px;
  `}
`;

const StyledButton = styled(Button)`
  width: fit-content;
`;

const List = styled(ScrollbarContainer)`
  height: 100%;

  padding-right: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  ${respondToWidth.sm`
    overflow-y: hidden;
    padding-right: 0;
    gap: 16px;
  `}
`;

const Footer = styled.div`
  ${({ theme: { colors } }) => css`
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;

    padding: 16px;

    z-index: 1;

    background-color: ${colors.neutrals[11]};
    border-top: 1px solid ${colors.neutrals[9]};
  `}
`;
