import Button from 'UI/Button';
import Input from 'UI/Input';
import StyledText from 'UI/StyledText';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps, DefaultRejectValue } from 'types';
import { validations } from 'utils';

import DefaultModal from '../DefaultModal';

type ChangeEmailProps = {
  isLoading?: boolean;
  changeEmail?: (value: ChangeEmailFormState) => void;
  email?: string;
} & BaseModalProps;

export type ChangeEmailFormState = {
  newEmail: string;
};

const ChangeEmailModal: FC<ChangeEmailProps> = ({ onClose, isLoading, changeEmail, email, ...props }) => {
  const { handleSubmit, control, setError } = useForm<ChangeEmailFormState>({ defaultValues: { newEmail: '' } });

  const onSubmit = async (data: ChangeEmailFormState) => {
    try {
      await changeEmail?.(data);
    } catch (error) {
      const errorMessage = (error as DefaultRejectValue)?.message as string;
      setError('newEmail', { message: errorMessage });
    }
  };

  return (
    <DefaultModal
      {...props}
      onRequestClose={onClose}
      title="Change your email"
      containerCSS={ContainerCSS}
      changeCloseButton
      modalCSS={ModalCSS}
      isMobileOverlayStyles>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Text>Your current email</Text>
        <ExampleText>{email}</ExampleText>
        <Controller
          control={control}
          name="newEmail"
          rules={{ required: 'Required field', validate: validations.email }}
          render={({ field, formState: { errors } }) => (
            <StyledInput label="New email" error={errors.newEmail?.message} {...field} />
          )}
        />
        <StyledButton variant="primary" size="middle" type="submit" isLoading={isLoading}>
          Save
        </StyledButton>
      </Form>
    </DefaultModal>
  );
};

export default ChangeEmailModal;

const ContainerCSS = css`
  width: 668px;
  display: flex;
  flex-direction: column;
  padding: 40px 100px 60px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    border-radius:0;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    width:90%;
  `}

  ${respondToWidth.s`
    width:100%;
    height: 100%;
  `}
`;

const Text = styled(StyledText)`
  margin-bottom: 4px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};

  ${respondToWidth.s`
    text-align: start;
  `}
`;

const ExampleText = styled(Text)`
  color: ${({ theme: { colors } }) => colors.primary[1]};
  margin-bottom: 40px;

  ${respondToWidth.s`
    margin-bottom: 24px;
  `}
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledButton = styled(Button)`
  margin-top: 40px;

  ${respondToWidth.s`
    margin-top: auto;
  `}
`;

const StyledInput = styled(Input)`
  input {
    padding-right: 12px;
  }
`;
