import { PointsResultModal } from 'UI';
import { useClientSize } from 'hooks';
import { FC, memo } from 'react';
import { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { BaseModalProps } from 'types';

type MaximumNumberPointsModalProps = {
  points: number;
} & BaseModalProps;

const MaximumNumberPointsModal: FC<MaximumNumberPointsModalProps> = ({ points, ...props }) => {
  const { getIsBreakpoint } = useClientSize();
  const isWidthSm = getIsBreakpoint('sm');
  return (
    <PointsResultModal
      {...props}
      isEarned={true}
      title="You have earned"
      titlePoints={Math.round(points)}
      subtitle={`points ${isWidthSm ? '' : '\n'} and reached the maximum ${
        isWidthSm ? '' : '\n'
      } that is possible. You are fully ready for your exam. Congratulations!`}
      points={10000}
      isMaxPoints
      titleContainerCSS={TitleCSS}
      wrapperCSS={WrapperCSS}
    />
  );
};

export default memo(MaximumNumberPointsModal);

const TitleCSS = css`
  top: 15%;
  white-space: pre-line;
  text-align: center;

  ${respondToWidth.sm`
    top:12%;
  `}
`;

const WrapperCSS = css`
  max-width: 472px;
`;
