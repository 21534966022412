import { useState } from 'react';
import { Swiper } from 'swiper';

type SwiperControlType = {
  swipers: (Swiper | undefined)[];
  slideRef?: React.RefObject<HTMLDivElement>;
  sliderRef?: React.RefObject<HTMLDivElement>;
  isScroll?: boolean;
};

const SLIDES_GAP = 16;

export const useSwiperControl = ({ swipers, slideRef, sliderRef, isScroll = true }: SwiperControlType) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const slideHeight = (slideRef?.current?.clientHeight || 0) + SLIDES_GAP;
  const isSliderRef = sliderRef?.current;

  const getNextSlide = () => {
    swipers.map((swiper) => swiper?.slideNext());

    if (isSliderRef && isScroll) {
      const activeSlideNumber = activeIndex + 1;
      const clientHeightWithScrollTop = sliderRef.current?.clientHeight + sliderRef.current.scrollTop;
      const possibleSlides = Math.floor(clientHeightWithScrollTop / slideHeight);

      if (activeSlideNumber >= possibleSlides) {
        sliderRef.current?.scrollTo({ top: slideHeight * activeSlideNumber });
      }
    }
    setActiveIndex((prev) => prev + 1);
  };

  const getPrevSlide = () => {
    swipers.map((swiper) => {
      swiper?.slidePrev();
    });

    if (isSliderRef && isScroll) {
      const activeSlideNumber = activeIndex - 1;
      const clientHeightWithScrollTop = sliderRef.current.scrollTop;
      const possibleSlides = Math.floor(clientHeightWithScrollTop / slideHeight);
      if (activeSlideNumber <= possibleSlides) {
        sliderRef.current.scrollTo({ top: slideHeight * activeSlideNumber });
      }
    }
    setActiveIndex((prev) => prev - 1);
  };

  const onClickSlide = (index?: number) => {
    if (index || index === 0) {
      setActiveIndex(index);
      swipers.map((swiper) => swiper && swiper.slideTo(index, 500));
    }
  };

  return {
    getNextSlide,
    getPrevSlide,
    onClickSlide,
    activeIndex,
    setActiveIndex,
  };
};
