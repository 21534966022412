import RadioButton from 'UI/RadioButton';
import { RadioButtonProps } from 'UI/RadioButton/RadioButton';
import { CloseIcon, LoaderIcon, MarkIcon } from 'assets/icons';
import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { ThemeType } from 'styles/themes';

type AnswerProps = {
  correct?: boolean;
  showIcon?: boolean;
  changeColor?: boolean;
  customChecked?: boolean;
  className?: string;
  isLoading?: boolean;
  initialChecked?: boolean;
} & RadioButtonProps;

const Answer: ForwardRefRenderFunction<HTMLDivElement, AnswerProps> = (
  {
    correct = false,
    showIcon = false,
    changeColor = false,
    customChecked,
    className,
    isLoading,
    checked,
    initialChecked,
    ...props
  },
  ref,
) => {
  const { colors } = useTheme();

  return (
    <Container $isDisabled={props.disabled} className={className} ref={ref}>
      <StyledRadio
        color={getRadioColor(changeColor, correct, colors)}
        labelCSS={LabelCSS}
        {...props}
        checked={initialChecked || checked}
        isCorrect={customChecked}
      />
      {showIcon && (
        <IconContainer>
          {isLoading ? (
            <StyledLoader size={24} color={colors.primary[1]} />
          ) : correct ? (
            <MarkIcon size={24} color={colors.system.green} />
          ) : (
            <CloseIcon size={24} color={colors.system.red} />
          )}
        </IconContainer>
      )}
    </Container>
  );
};

export default forwardRef<HTMLDivElement, AnswerProps>(Answer);

const Container = styled.div<{ $isDisabled?: boolean }>`
  width: 100%;
  position: relative;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;
  padding-left: 20px;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`;

const StyledRadio = styled(RadioButton)`
  width: 100%;
`;

const LabelCSS = css`
  white-space: pre-wrap;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 48px;
  width: 100%;

  ${({ theme: { typography } }) => respondToWidth.s`
    ${typography.body_basic_bold_14}
  `}
`;

const IconContainer = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;

const getRadioColor = (changeColor: boolean, correct: boolean, colors: ThemeType['colors']) => {
  if (changeColor && correct) {
    return colors.system.green;
  }
  if (changeColor && !correct) {
    return colors.system.red;
  } else {
    colors.primary[1];
  }
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledLoader = styled(LoaderIcon)`
  animation: ${rotate} 2s linear infinite;
`;
