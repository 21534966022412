import { PaymentDtoLevelEnum } from 'api/generated';

export const accessLevels = {
  // Api gives Trial as null
  null: {
    hasPerformance: true,
    hasAllChaptersInStudyGuide: false,
    hasAllChaptersAudioInStudyGuide: false,
    hasAllFlashcardsDecks: false,
    hasAllMnemonicDecks: false,
    hasAllQuestionsInTest: false,
  },
  // Api gives Trial as none
  none: {
    hasPerformance: true,
    hasAllChaptersInStudyGuide: false,
    hasAllChaptersAudioInStudyGuide: false,
    hasAllFlashcardsDecks: false,
    hasAllMnemonicDecks: false,
    hasAllQuestionsInTest: false,
  },
  [PaymentDtoLevelEnum.Bronze]: {
    hasPerformance: false,
    hasAllChaptersInStudyGuide: true,
    hasAllChaptersAudioInStudyGuide: false,
    hasAllFlashcardsDecks: false,
    hasAllMnemonicDecks: false,
    hasAllQuestionsInTest: false,
  },
  [PaymentDtoLevelEnum.Silver]: {
    hasPerformance: false,
    hasAllChaptersInStudyGuide: true,
    hasAllChaptersAudioInStudyGuide: false,
    hasAllFlashcardsDecks: true,
    hasAllMnemonicDecks: false,
    hasAllQuestionsInTest: true,
  },
  [PaymentDtoLevelEnum.Gold]: {
    hasPerformance: true,
    hasAllChaptersInStudyGuide: true,
    hasAllChaptersAudioInStudyGuide: true,
    hasAllFlashcardsDecks: true,
    hasAllMnemonicDecks: true,
    hasAllQuestionsInTest: true,
  },
};
